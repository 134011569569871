import {ofType} from 'redux-observable'
import {DOCK_COMPANY_LIST_GET, DOCK_LIST_GET, DOCK_OWNER_LIST_GET} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {dockService} from '../../services/dockService'
import {
  dockCompanyListGetFAction,
  dockCompanyListGetRAction,
  dockListGetFAction,
  dockListGetRAction,
  dockOwnerListGetFAction,
  dockOwnerListGetRAction
} from '../action/dockActions'
 
export const getDocksEpic = (action$ : any) => {
    return action$.pipe(
        ofType(DOCK_LIST_GET),
        switchMap((action:any) => {
            return dockService.getDocks(action.payload).pipe(
                map(res => {
                    const {data} = res
                    const {content, total_page, total_element} = data
                    return dockListGetFAction({content, total_page, total_element})
                }),
                catchError(err => of(dockListGetRAction(err)))
            )
        })
    )
}

export const getDockCompaniesEpic = (action$ : any) => {
    return action$.pipe(
        ofType(DOCK_COMPANY_LIST_GET),
        switchMap((action : any) => {
            return dockService.getDockCompanies(action.payload).pipe(
                map(res => {
                    // console.log(res,'response in dock company epis')
                    // console.log(action.payload,'payload for sock company')
                    const {data} = res
                    // console.log(res.data,'response in docepics')
                    const { content, total_page, total_element } = res.data
                    // console.log(content,'ccontent')
                    return dockCompanyListGetFAction({content, total_page, total_element})
                }),
                catchError(err => of(dockCompanyListGetRAction(err)))
            )
        })
    )
}

export const getDockOwnersEpic = (action$ : any) => {
    return action$.pipe(
        ofType(DOCK_OWNER_LIST_GET),
        switchMap((action : any) => {
            return dockService.getDockOwners(action.payload).pipe(
                map(res => {
                    // console.log(res,'response in dock company epis')
                    // console.log(action.payload,'payload for sock company')
                    const {data} = res
                    // console.log(res.data,'response in docepics')
                    const { content, total_page, total_element } = res.data
                    // console.log(content,'ccontent')
                    return dockOwnerListGetFAction({content, total_page, total_element})
                }),
                catchError(err => of(dockOwnerListGetRAction(err)))
            )
        })
    )
}