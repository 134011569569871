import React, { useState } from 'react'

 const ManufactCmpFilter = {
    status: '',
    country: '',
    countryNames:'',
    city: '',
    cmp: '',
    page:0,
    size:10,
    searchBoxInputManufactCmp:'',
    type:'',
    direction:''
}
const Manufactlistfilter={
   searchBoxInputManufactlist:'',
   searchBoxInputManufactEmail:'',
   searchBoxInputManufactStatus:'',
   findbyIdname:'',
   type:'',
   direction:'' 
}

export const ManufactFilterState = {
    ManufactCmpFilter,
    Manufactlistfilter
}