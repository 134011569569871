import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import { connect } from 'react-redux'
import swal from 'sweetalert';
import { dataPropulsionSetSelectedAction } from '../../../../store/action/propulsionAction'
import propulsionService from '../../../../services/propulsionSevice'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner';      
  

// Blue print of the class component.  
interface shipEditProps {
    id: string | number;
    name: string;
    code: string;
}

interface FormData {
    txt_name: string;
    txt_code: string;
}
const PropulsionEdit = (props: any) => {
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
      });
    const [propulsionTypeeditflag, setpropulsionTypeeditFlag] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)
    const [display, setDisplay] = React.useState<shipEditProps>({
        id: '',
        name: '',
        code:'',
    })
    const [ships, setShip] = useState<any>({
        isLoading: false,
        isSubmitting: false,
        name: '',
        code: '',
    },
    )


    // Used to get perticular ship detail and store to variable.   
    const _getPropulsionDetail = ((id: any) => {
        setShip({ ...ships, isLoading: true })
        return propulsionService.getPropulsionDetail(id).subscribe({
            next: r => {
                console.log(r, 'response')
                // console.log(r.data, 'data within response')
                const value = r.data
                // console.log(value, 'data within response using value')
                setDisplay({
                    id: Number(r.data.id),
                    name: r.data.name,
                    code: r.data.code
                })
                props.dataPropulsionSetSelectedAction(value)
                setShip({ ...ships, isLoading: false, })
            },
            error: err => {
                console.error(err)
                setShip({ ...ships, isLoading: false })
            }
        })
    })
    React.useEffect(() => {
        const { match } = props
        const { id } = match.params
        _getPropulsionDetail(id)
    }, [])

    // After update all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call.  
    const _didShipTypeEdit = () => {
        // e.preventDefault()
        const updatedForm = {
            id: display.id,
            name: display.name,
            code: display.code
        }

        // API call for updateship with data, updated into list page.  
        if (propulsionTypeeditflag === true) {
            setLoading(true)
            propulsionService.updatePropulsionType(updatedForm).subscribe({
                next: () => {
                    setLoading(false)
                    swal("Great", "Propulsion Type Updated Successfully", "success")
                    history.push('/propulsion/list')
                },
                error: err => {
                    setLoading(false)
                    swal("OOPS", String(err), "error")
                    console.error(err)
                }
            })
        }
        else {
            console.log(propulsionTypeeditflag)
        }
    }

    onsubmit = (e: any) => {
        // e.preventDefault()
        console.log(Object.keys(errors).length, 'error length')
        if (Object.keys(errors).length === 0) {
            _didShipTypeEdit()
            setpropulsionTypeeditFlag(true)
        }
        else {
            setpropulsionTypeeditFlag(false)
        }
    }
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }
    return (
        <Main>
            <Text color='black' variant="title">Edit Propulsion Type Information</Text>
            <Space divheight={25} />
            {/* Edit form, having input box, select box.   */}
            <Grid templateCols='75% auto' className='add_page'>
                <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='editForms'>
                    <Card title="Fill All the Information Below">
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Propulsion Type</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_name"
                                            value={display.name}
                                            onChange={(e) => setDisplay({ ...display, name: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="Propulsion Type"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_name?.type === 'required' && 'Please enter Ship Type'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Code</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_code"
                                            value={display.code}
                                            onChange={(e) => setDisplay({ ...display, code: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="Propulsion Type"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_code?.type === 'required' && 'Please enter Code'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                    <Actions>
                        {/* If you click the back button its is redirect to ships list page.   */}
                        <Link to='/propulsion/list'>
                            <Button type="button" className='bttn bttn-transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the save button its is call the function, in that data updated to list and redirect to list page.   */}
                        <Button className='bttn bttn-transparent' type="submit">
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                            SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
        </Main>
    )
}


export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style.  
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-ship-add');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}

// Button style.  
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    ship: state.shipType.selectedShipType
})
//Return the selected ship details.  
export default connect(
    mapStateToProps,
    {
        dataPropulsionSetSelectedAction
    }
)(PropulsionEdit)
