import React, { Component } from 'react';
import XLSX from 'xlsx';
import { Button } from '../../../../common/components/button/Button';
import { Col, Form, Row } from 'react-bootstrap'
import { Text } from '../../../../common/components/text/Text'
import { Flex } from '../../../../common/components/flex/Flex';
import Icons from '../../../../common/components/icon/Icon';
import Modal from '../../../../common/components/modal/Modal';
import { Space } from '../../../../common/components/space/Space';
import { ModalBody } from '../../../login/pagecontainer/ForgotPassWord';
import { dockService } from '../../../../services/dockService'
import { make_cols } from './MakeColumns';
import swal from 'sweetalert';
import downloadicon from '../../../../assets/images/XL.png';
import FilterModal from '../pageContainer/FilterModal'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Attributes } from '../../../../common/components/attribute/Attribute';
import { Table } from '../../../../common/components/table/Table'
import '../DryDock.scss'
import Loader from 'react-loader-spinner'
// import ImportConformation from 'ImportConformation'
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';


class ImportExcel extends Component<any, any> {

    constructor(props: any) {
        super(props);
        const { onClose } = props

        this.state = {
            file: {},
            data: [],
            cols: [],
            shows: false,
            files: false,
            number_of_dockType: 0,
            number_of_port: 0,
            number_of_city: 0,
            name: [],
            loading: false
        }
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlestatic = this.handlestatic.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.overWriteFile=this.overWriteFile.bind(this);
    }

    handleChange(e: any) {
        const files = e.target.files;
        if (files && files[0]) this.setState({ file: files[0], files: true });
    };

    handlestatic() {
        dockService.addDockExcel([{ "dock_name": "Dock11", "dock_type": "Slipway", "length": 23, "width": 16, "draft": 25, "dwt_capacity": 1, "lat": 2.4342, "lon": 23.43423, "cranes": 1, "crane_capacity": 1, "company_name": "CARIBBEAN DRYDOCK COMPANY S.A. (CDC S.A) HAVANA", "port_name": "Port of Abidjan", "unit": "II", "ship_type": "kargo", "max_ship_length": 15, "max_ship_width": 10, "max_ship_draft": 10 }]
            , false,false).subscribe({
                next: () => {
                    // console.log(pages,'pages')
                    swal("Great", "DryDock Added Successfully", "success")
                    this.props.onClose()
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    console.error(err)
                }
            })
    }

    handleShow(dock: any, res: any) {
        // setShow(true);
        // setImportDock(dock,res)
        // console.log(deleteAnode, 'deleted anode id')
    }
    // const [check, setCheck] = useState(false);
    //const [showModal, setModal] = React.useState(false)
    // const [showImportModal, setImportModal] = React.useState(false)
    // const [displayDocks, setDocks] = React.useState<any>([])
    // const [showDelete, setShow] = React.useState(false);
    // const [importDock, setImportDock] = React.useState(0)

    // const _willCloseModal = () => {
    //     setModal(false)
    //   }

    handleFile() {
        if(this.state.files){
            /* Boilerplate to set up FileReader */
            const reader = new FileReader();
            this.setState({ shows: false })
            const rABS = !!reader.readAsBinaryString;

            reader.onload = (e: any) => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws);
                this.setState({ loading: true })
                console.error(this.state.loading+"-this set before true")
                dockService.addDockExcel(data, false, false).subscribe({
                    next: (res) => {
                        // console.log(pages,'pages')
                        if (res.msg === 'validation success') {
                            //                        swal("Great", "DryDock Added Successfully", "success")
                            // alert('if')
                            this.setState({ loading: false })
                            console.error(this.state.loading+"-this set before false")
                           
                            if(res.data[0].new_updated_docks === 0){
                                //swal("Great", "No New Records To Import Data", "success")
                                this.props.onClose('no_new')
                            } else {
                                //swal("Great", "DryDock Imported Successfully", "success")
                                this.props.onClose('success')
                            }
                            
                            // dockService.addDockExcel(data, true).subscribe({
                            //     next: (res) => {
                            //         // console.log(pages,'pages')
                            //         swal("Great", "DryDock Added Successfully", "success")
                            //         this.props.onClose()
                            //     },
                            //     error: err => {
                            //         swal("OOPS", String(err), "error")
                            //         console.error(err)
                            //     }
                            // })
                        } else {
                            // alert('else')
                            console.log(res.data, 'print')
                            this.setState({
                                loading: false,
                                shows: true,
                                number_of_dockType: res.data.new_dock_type_count,
                                number_of_port: res.data.new_port_count,
                                number_of_city: res.data.new_city_count,
                                data: res.data
                                // name:[]
                            })
                            console.error(this.state.loading+"-this set before false")
                            // swal("OOPS", "Unable upload", "error")
                            // console.error(err)
                            //    this.handleShow(data,res)
                        }
                    },
                    error: err => {
                        this.setState({ loading: false })
                        swal("OOPS", String(err), "error")
                        console.error(err)
                    }
                })
                /* Update state */
                // console.log(JSON.stringify(data), 'data')
                this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
                    // console.log(JSON.stringify(this.state.data));
                });

            };
            if (rABS) {
                reader.readAsBinaryString(this.state.file);
            } else {
                reader.readAsArrayBuffer(this.state.file);
            };
        } else {
            swal("Warning", "Please Select File", "warning")
        }
        
        

    }

    uploadFile() {
            console.log(true)
            /* Boilerplate to set up FileReader */
            const reader = new FileReader();
            //this.setState({ shows: false })
            this.setState({ loading: true })
            const rABS = !!reader.readAsBinaryString;

            reader.onload = (e: any) => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws);
                this.setState({ loading: true })
                dockService.addDockExcel(data, true,false).subscribe({
                    next: (res) => {
                        // console.log(pages,'pages')
                        this.setState({ loading: false })
                        //swal("Great", "DryDock Imported Successfully", "success")
                        this.props.onClose('success')
                        
                        
                    },
                    error: err => {
                        this.setState({ loading: false })
                        swal("OOPS", String(err), "error")
                        console.error(err)
                        this.props.onClose()
                    }
                })
                /* Update state */
                // console.log(JSON.stringify(data), 'data')
                this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
                    // console.log(JSON.stringify(this.state.data));
                });

            };
            if (rABS) {
                reader.readAsBinaryString(this.state.file);
            } else {
                reader.readAsArrayBuffer(this.state.file);
            };       

    }

    overWriteFile() {
        console.log(true)
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        //this.setState({ shows: false })
        this.setState({ loading: true })
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e: any) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            this.setState({ loading: true })
            dockService.addDockExcel(data, true,true).subscribe({
                next: (res) => {
                    // console.log(pages,'pages')
                    this.setState({ loading: false })
                    this.props.onClose()
                    swal("Great", "DryDock Imported Successfully", "success")
                    
                },
                error: err => {
                    this.setState({ loading: false })
                    swal("OOPS", String(err), "error")
                    console.error(err)
                    this.props.onClose()
                }
            })
            /* Update state */
            // console.log(JSON.stringify(data), 'data')
            this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
                // console.log(JSON.stringify(this.state.data));
            });

        };
        if (rABS) {
            reader.readAsBinaryString(this.state.file);
        } else {
            reader.readAsArrayBuffer(this.state.file);
        };       

}

    _willCloseModal = () => {
        this.setState({ shows: false })
    }

    _willCloseImport = () => {
        // setImportModal(false)
    }

    _willDelete = () => {
        // console.log(deleteAnode, 'deleted anode id')
        // this._deleteDock(importDock)
    }

    //   _deleteDock = (data: any) => {
    //     return dockService.addDockExcel(data, false).subscribe({
    //         next: (res) => {
    //             // console.log(pages,'pages')
    //                    swal("Great", "DryDock Added Successfully", "success")
    //                 this.props.onClose()
    //         },
    //         error: err => {
    //           swal("OOPS", String(err), "error")
    //         }
    //     })
    //   }

    //   handleClose = () => setShow(false);

    

    render() {

        if (this.state.loading) {
            console.error(this.state.loading+"-loading started")
            console.log(true,'1')
            return <Text center className="loader">
                <Loader
                    type="Rings"
                    color="#233269"
                    height={100}
                    width={100}
                    timeout={30000}
                />
            </Text>
        }
        return (
            <div>
                <div>
                    <Modal
                        onClose={() => this.props.onClose()}
                        title={'Import Dock Details As Excel'}
                        className='import-mode'>
                        <ModalBody>
                            <Attributes className='singleFrame'>
                                {/* <div className='text-center'> */}
                                {/* <Row> */}
                                {/* <Text color='tablehead' variant='subtitle' bold>Download Excel Template</Text> */}
                                {/* <Col xs={4} md={8}> */}
                                <a href="https://navidok-staging-asset.s3.ap-southeast-1.amazonaws.com/v1/quotations/15_12_2021/dock_template.xlsx"><img src={downloadicon}></img></a>
                                {/* </Col> */}
                                {/* <Col xs={4} md={4}> */}
                                {/* <> */}
                                <Text color='tablehead' variant='subtitle' bold>Dock Template as .xls</Text>
                                {/* </> */}
                                {/* </Col> */}
                                {/* <Col xs={4} md={4}> */}
                                <input type="file" id="file" className="new_input" accept={SheetJSFT}
                                    onChange={this.handleChange} />
                                {/* </Col> */}
                                {/* </Row> */}
                                {/* </div> */}
                            </Attributes>
                            <Text color='danger' variant='subtitle' bold>Note: Navidok will not import repeated and null records.</Text>
                            <Space divheight={20} />
                            <Flex jc='jc-center'>
                                <Button
                                    block
                                    type="button"
                                    onClick={() => this.props.onClose()}
                                    variant='transparent'>
                                    <Flex>
                                        <Icons icon="clear" />
                                        Cancel
                                    </Flex>
                                </Button>
                                <Button variant='transparent' block type="submit" onClick={this.handleFile}>
                                    <Flex>
                                        <Icons color="#ffffff" icon="publish" />
                                        Upload File
                                    </Flex>
                                </Button>
                            </Flex>
                        </ModalBody>
                    </Modal>
                </div>
                {console.log(this.state.shows, 'shows value')}
                {this.state.shows === true &&
                    <div>
                        <Modal onClose={() => this.props.onClose()} title={'Import Confirmation'} className='import-new-mode'>
                            <ModalBody>
                                <Text bold variant='confirmation' color='danger' values='Want to import the dock details ' />
                                {this.state.data[0].new_dock_type_count !== 0 &&
                                    <>
                                        <p>Total new dock Type - {this.state.data[0].new_dock_type_count}</p>
                                    </>
                                }
                                {this.state.data[0].new_port_count !== 0 &&
                                    <>
                                        <p>Total new Ports - {this.state.data[0].new_port_count}</p>
                                    </>
                                }
                                {this.state.data[0].new_city_count !== 0 &&
                                    <>
                                        <p>Total new City - {this.state.data[0].new_city_count}</p>
                                    </>
                                }
                                 {this.state.data[0].new_company_count !== 0 &&
                                    <>
                                        <p>Total new Company - {this.state.data[0].new_company_count}</p>
                                    </>
                                }
                                 {this.state.data[0].new_country_count !== 0 &&
                                    <>
                                        <p>Total new Country - {this.state.data[0].new_country_count}</p>
                                    </>
                                }
                                <Table>
                                    <thead>
                                        <tr key={0}>
                                            <th>Dock Name</th>
                                            <th>
                                               Company Name &nbsp;
                                            </th>
                                            <th>
                                                Port &nbsp;
                                            </th>
                                            <th>
                                                Country
                                            </th>
                                            <th>City &nbsp; </th>
                                            <th>
                                                Dock Type &nbsp;
                                            </th>
                                            <th>
                                               Latitude
                                            </th>
                                            <th>
                                                Longitude
                                            </th>
                                            <th>Draft </th>
                                            <th>
                                                Length 
                                            </th>
                                            <th>
                                                Width 
                                            </th>
                                            <th>
                                                Unit Name 
                                            </th>                                          
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.map((dock: any, i: any) => [
                                            <tr key={dock.sno}>
                                                <td data-label='Dock Name'>{dock.dock_name}
                                                </td>
                                                <td data-label='Company Name'>{dock.company_name}</td>
                                                <td data-label='Port'>{dock.port_name}</td>
                                                <td data-label='Country'>{dock.country_name}</td>
                                                <td data-label='City'>{dock.city_name}</td>
                                                <td data-label='Dock Type'>{dock.dock_type}</td>
                                                <td data-label='Latitude'>{dock.lat}</td>
                                                <td data-label='Longitude'>{dock.lon}</td>
                                                <td data-label='Draft'>{dock.draft}</td>
                                                <td data-label='Length'>{dock.length}</td>
                                                <td data-label='Width'>{dock.width}</td>
                                                <td data-label='Unit Name'>{
                                                    dock.unit_name === null ? (
                                                        <>
                                                        -
                                                        </>
                                                    ) : (
                                                        <>
                                                        {dock.unit_name}
                                                        </>
                                                        
                                                    )
                                                    }</td>
                                            </tr>
                                        ])}
                                    </tbody>
                                </Table>

                                <Flex jc='jc-center'>
                                    <Button
                                        block
                                        type="button"
                                        onClick={() => this.props.onClose()}
                                        variant='transparent'>
                                        <Flex>
                                            <Icons icon="clear" />
                                            Cancel
                                        </Flex>
                                    </Button>
                                    <Button variant='transparent' block type="submit"
                                    onClick={this.uploadFile}
                                     //onClick={this.dockService.addDockExcel(data, true)}
                                     >
                                        <Flex>
                                            <Icons color="#ffffff" icon="publish" />
                                            Upload File
                                        </Flex>
                                    </Button>
                                    {/* <Button variant='transparent' block type="submit"
                                    onClick={this.overWriteFile}
                                     //onClick={this.dockService.addDockExcel(data, true)}
                                     >
                                        <Flex>
                                            <Icons color="#ffffff" icon="publish" />
                                             Over Write Data
                                        </Flex>
                                    </Button> */}
                                </Flex>
                            </ModalBody>
                        </Modal>
                    </div>
                }
            </div>

        )


    }
}


export default ImportExcel;

const SheetJSFT = [
    "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function (x) { return "." + x; }).join(",");
