import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-bootstrap';
import Icons from '../icon/Icon';
import './Control.scss';

//Interface for Input component.  Here declare properties we are going to use inside Input component
export interface Inputproperties {
    className?: string;
    children?: React.ReactNode;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    type?: 'text' | 'password' | 'email' | 'number' | 'checkbox' | 'date';
    name?: string;
    id?: string;
    required?: boolean;
    value?: string | number;
    placeholder?: string;
    min?: number;
    max?: number;
    accept?: string;
    step ? : string;
    ref ? : ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined;
}

//Interface for TextArea component.  Here declare properties we are going to use inside TextArea component
export interface TextAreaProps {
    rows?: number;
    cols?: number;
    className?: string;
    children?: React.ReactNode;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    name?: string;
    required?: boolean;
    value?: string | number;
}

//Interface for SelectInPut component.  Here declare properties we are going to use inside SelectInput component
export interface SelectProps {
    className?: string;
    placeholder?: string;
    name?: any;
    values?: string | number | any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent) => void;
    children?: React.ReactNode;
    required?: boolean;
}

/* Interface for select box layout  component.  Here declare properties we are going to use inside select layout
and its used to design select box with icon component */

export interface layoutSelectProps {
    children?: React.ReactNode;
    className?: string;
}

/* Interface for Input layout  component.  Here declare properties we are going to use inside Input layout
and its used to design Input box with icon component */

export interface layoutInputProps {
    children?: React.ReactNode;
    className?: string;
}

//Interface for Select component.  Here declare properties we are going to use inside Select component
export interface SltProps {
    className?: string;
    placeholder?: string;
    name?: any;
    values?: string | number | any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent) => void;
    children?: React.ReactNode;
    required?: boolean;
}

//Interface for Input With Icon component.  Here declare properties we are going to use inside Select component
export interface InputWithIconPorps {
    className?: string;
    children?: React.ReactNode;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    icon: string;
    type?: 'text' | 'password' | 'email' | 'number' | 'checkbox';
    name?: string;
    id?: string;
    required?: boolean;
    value?: string | number;
    placeholder?: string;
}

//Interface for File Props.  here we declare properties which all are going to use inside File Component
interface FileProps {
    children?: React.ReactNode;
    className?: string;
    id?: string;
    required?: boolean;
    label?: string;
}

//set interface for Radio Button
interface RadioProps{
    children? : React.ReactNode;
    className? : string;
    id? : string;
    required? : boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    name? : string;
    value? : string  | boolean;
    type? : any;
    label? : any;
    checked? :any;
    defaultValue ? : any;
    defaultChecked ? : any;
}

//Radio button component 

const Radio = ({children,className,id,required,onChange,name,defaultChecked,value,type,label,defaultValue,checked, ...props}:RadioProps) => {
    const radioclass = classNames('RadioButton',className)
    return(
        <Form.Check type={type}
            id={id} required={required} 
            onChange={onChange}
            className={radioclass} 
            name={name} label = {label}
            checked={checked}
            defaultValue = {defaultValue}
            defaultChecked = {defaultChecked}
        />
    )
}

//File component using for get any type of file as input 
const File = ({ children, className, id, required, ...props }: FileProps) => {
    const flieclass = classNames('chooseFileContainer', className);

    return (
        <Form.File id={id} required={required} className={flieclass}>

        </Form.File>
    )
}
 
// Input component(input box) use bootstrap <Form.control> as Input and get input form the user
const Input = ({ className, children, type, onChange, name, id, required, min,ref, max,step, value,  placeholder,...props }: Inputproperties) => {
    const basectrl = classNames('basecontrol', 'custome-input', className);

    return (
        <Form.Control
            type={type ? type : 'text'}
            className={basectrl}
            onChange={onChange}
            name={name}
            placeholder={placeholder}
            id={id}
            required={required}
            value={value}
            min={min}
            max={max}
            step={step}
            ref = {ref}
        >
            {children}
        </Form.Control>
    );
};

//Textarea Component use to get multiple line input from user
const Textarea = ({ className, rows, cols, onChange, children, value, required, ...props }: TextAreaProps) => {
    const basectrl = classNames('basecontrol', 'custome-textarea', className);

    return (
        <textarea rows={rows} cols={cols} className={basectrl} value={value} required={required} onChange={onChange}>
            {children}
        </textarea>
    );
};

//InputLayout component act as container for inputbox with icon
const InputLayout = ({ children, className, ...props }: layoutInputProps) => {
    const layoutinput = classNames('layout-input', className);

    return <div className={layoutinput}>{children}</div>;
};

//Input with icon component merge icon component and input component to create input box with icon
const InputWithIcon = (props: InputWithIconPorps) => {
    const { icon, ...restProps } = props;
    return (
        <InputLayout>
            <Icons icon={icon} />
            <Input {...restProps} />
        </InputLayout>
    );
};

//selectInput component to create select box. use bootstrap <Form.control> to create select box
const SelectInput = ({ className, placeholder, name, values, onChange,onClick, children, required, ...props }: SelectProps) => {
    const basectrl = classNames('basecontrol', 'cutome-selectInput', className);

    return (
        <Form.Control
            as='select'
            placeholder={placeholder}
            name={name}
            value={values}
            onChange={onChange}
            onClick={onClick}
            className={basectrl}
            required={required}
        >
            {children}
        </Form.Control>
    );
};

//SelectLayout component act as container for Select component with icon
const SelectLayout = ({ children, className, ...props }: layoutSelectProps) => {
    const layoutselect = classNames('layout-select', className);

    return <div className={layoutselect}>{children}</div>;
};

/* Select component which is actualy used in other pages. 
it merge icons and selectInput component to create selectbox with icon */
const Select = ({ children, ...props }: SltProps) => {
    const { ...restProps } = props;
    return (
        <SelectLayout>
            <SelectInput {...restProps}>{children}</SelectInput>
            <Icons icon='expand_more' />
        </SelectLayout>
    );
};

export { Input, Textarea, Select, InputWithIcon, File, Radio };

