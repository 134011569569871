import {
    MANUFACTURER_LIST_GET,
    MANUFACTURER_LIST_GET_F,
    MANUFACTURER_LIST_GET_R,
    MANUFACTURER_SELECTED_SET,
    MANUFACTURER_CMP_LIST_GET,
    MANUFACTURER_CMP_LIST_GET_F,
    MANUFACTURER_CMP_LIST_GET_R,
    MANUFACTURER_CMP_SELECTED_SET,
    PAYPERVIEW_LIST_GET,
    PAYPERVIEW_LIST_GET_F,
    PAYPERVIEW_LIST_GET_R,
    PAYPERVIEW_SELECTED_SET,
    TRANSACTION_LIST_GET,
    TRANSACTION_LIST_GET_F,
    TRANSACTION_LIST_GET_R,
    TRANSACTION_SELECTED_SET,
    QUOTATION_LIST_GET,
    QUOTATION_LIST_GET_F,
    QUOTATION_LIST_GET_R,
    QUOTATION_SELECTED_SET
} from '../actionType'

const initialState = {
    manufacturer: [],
    manufacCmp:[],
    payperView:[],
    transaction:[],
    quotation:[],
    isLoading: false,
    error: null,
    totalPage: 0,
    totalElement: 0,
    selectedManufacturer: null,
    selectedManufactCmp: null,
    selectedPerView:null,
    selectedTransaction:null,
    selectedQuotation:null,
}

export default function manufacturerReducer (state = initialState, action : any)  {
    switch (action.type) {
        case MANUFACTURER_LIST_GET:
            return {
            ...state,
            isLoading: true,
            error: null
            }
        case MANUFACTURER_LIST_GET_F:
            // console.log('action called')
            // console.log(action.payload.content,'content')
            var totalmanucount = 0
           
            if(action.payload.content === undefined || '')
            {
                totalmanucount = 0
            }
            else{
                action.payload.content.map((data:any) => {
                    totalmanucount = data.totalrow
                })
            }
            return {
                ...state,
                isLoading: false,
                manufacturer: action.payload.content,
                totalPage: action.payload.total_page,
                // totalElement: action.payload.total_element
                totalElement: totalmanucount
            }
        case MANUFACTURER_LIST_GET_R:
            
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MANUFACTURER_SELECTED_SET:
            return {
                ...state,
                selectedManufacturer: action.payload
            }
        case MANUFACTURER_CMP_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case MANUFACTURER_CMP_LIST_GET_F:
            // console.log('action called')
            console.log(action.payload.content,'content')
            var totalmanuCmpcount = 0

            if (action.payload.content === undefined || '') {
                totalmanuCmpcount = 0
            }
            else {
                action.payload.content.map((data: any) => {
                    totalmanuCmpcount = data.total_row
                })
            }
            return {
                ...state,
                isLoading: false,
                manufacCmp: action.payload.content,
                totalPage: action.payload.total_page,
                // totalElement: action.payload.total_element
                totalElement: totalmanuCmpcount
            }
        case MANUFACTURER_CMP_LIST_GET_R:

            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MANUFACTURER_CMP_SELECTED_SET:
            return {
                ...state,
                selectedManufactCmp: action.payload
            }
        case PAYPERVIEW_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case PAYPERVIEW_LIST_GET_F:
            // console.log('action called')
            // console.log(action.payload.content,'content')
            var totalviewCount = 0

            if (action.payload.content === undefined || '') {
                totalviewCount = 0
            }
            else {
                action.payload.content.map((data: any) => {
                    totalviewCount = data.total_row
                })
            }
            return {
                ...state,
                isLoading: false,
                payperView: action.payload.content,
                totalPage: action.payload.total_page,
                // totalElement: action.payload.total_element
                totalElement: totalviewCount
            }
        case PAYPERVIEW_LIST_GET_R:

            return {
                ...state,
                isLoading: false,
                error: action.payload
        }
        case PAYPERVIEW_SELECTED_SET:
            return {
                ...state,
                selectedPerView: action.payload
            }
        case TRANSACTION_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case TRANSACTION_LIST_GET_F:
            // console.log('action called')
            // console.log(action.payload.content,'content')
            var totaltrasactionCount = 0

            if (action.payload.content === undefined || '') {
                totaltrasactionCount = 0
            }
            else {
                action.payload.content.map((data: any) => {
                    totaltrasactionCount = data.totalrow
                })
            }
            return {
                ...state,
                isLoading: false,
                transaction: action.payload.content,
                totalPage: action.payload.total_page,
                // totalElement: action.payload.total_element
                totalElement: totaltrasactionCount
            }
        case TRANSACTION_LIST_GET_R:

            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case TRANSACTION_SELECTED_SET:
            return {
                ...state,
                selectedTransaction: action.payload
            }
        case QUOTATION_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case QUOTATION_LIST_GET_F:
            // console.log('action called')
            // console.log(action.payload.content,'content')
            var totalquotationcount = 0

            if (action.payload.content === undefined || '') {
                totalquotationcount = 0
            }
            else {
                action.payload.content.map((data: any) => {
                    totalquotationcount = data.total_row
                })
            }
            return {
                ...state,
                isLoading: false,
                quotation: action.payload.content,
                totalPage: action.payload.total_page,
                // totalElement: action.payload.total_element
                totalElement: totalquotationcount
            }
        case QUOTATION_LIST_GET_R:

            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case QUOTATION_SELECTED_SET:
            return {
                ...state,
                selectedQuotation: action.payload
            }
        default:
            return state
    }
}