import React, { ReactNode, useCallback } from 'react'
import { Text } from '../../../common/components/text/Text'
import { Main } from '../../../common/components/main/Main'
import { Button } from '../../../common/components/button/Button'
import Card from '../../../common/components/card/Card'
import Icons from '../../../common/components/icon/Icon'
import { Flex } from '../../../common/components/flex/Flex'
import { Space } from '../../../common/components/space/Space'
import { Table } from '../../../common/components/table/Table'
import { Modal, OverlayTrigger, Pagination, Tooltip } from 'react-bootstrap'
import '../../../common/components/pagination/Pagination.scss'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import {
  shipSubTypeListGetAction,
  shipSubTypeSetSelectedAction,
} from '../../../store/action/shipSubTypeAction'
import AddShipSubType from './pageContainer/AddShipSubType'
import { useState } from 'react'
import typeService from '../../../services/typeService'
import swal from 'sweetalert'
import Loader from 'react-loader-spinner'

const ShipSubTypeList = (props: any) => {
  const { subType, isLoading, totalElement } = props
  const [displaySubType, setSubType] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [showModal, setModal] = React.useState(false)
  const [pagination, setPagination] = React.useState([{}])
  const [isEdit, setIsEdit] = useState(false)
  const [isview, setIsView] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [high, setHigh] = useState(9)
  const [low, setLow] = useState(1)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)

  // const headerName = 'ROLE DETAIL'
  // const filename ="role__" + new Date()
  //const [searchInput, setSearchInput] = React.useState('')
  // const [searchQuery , setQuery] = useState('')
  const [showDelete, setShow] = React.useState(false);
  const [deletesubType, setDeletesubType] = React.useState(0)

  React.useEffect(() => {
    //const { totalPage } = props
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  React.useEffect(() => {
    props.shipSubTypeListGetAction({ id: 0, size: 10, page })
  }, [totalElement, page])


  React.useEffect(() => {
    if (subType && subType.length > 0) {
      // console.log(subType, 'subType')
      setSubType(() => {
        return subType.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (subType && subType.length === 0) {
      setSubType([])
    }
  }, [subType, page])


  const _willViewEdit = (detail: string | number | any) => {
    props.shipSubTypeSetSelectedAction(detail)
    setModal(true)
    setIsView(false)
    setIsEdit(true)
  }

  const _willViewDetail = (detail: string | number | any) => {
    props.shipSubTypeSetSelectedAction(detail)
    setModal(true)
    setIsView(true)
    setIsEdit(false)
  }

  // Delete the pericular list.
  const handleClose = () => setShow(false);
  const handleShow = (cmpType: any) => {
    setShow(true);
    setDeletesubType(cmpType.id)
    // console.log(deleteAnode, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deleteShipSubType(deletesubType)
  }

  const _deleteShipSubType = (id: any) => {
    return typeService.deleteShipSubType(id).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.shipSubTypeListGetAction({ id: 0, size: 10, page })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }

  const _willAddNewShipSubType = () => {
    props.shipSubTypeSetSelectedAction(null)
    setModal(true)
    setIsAdd(true)
    setIsEdit(false)
    setIsView(false)
  }
  const _willCloseModal = () => {
    props.shipSubTypeListGetAction({ id: 0, size: 10, page: 0 })
    setModal(false)
    setPage(0)
  }


  const _goPrevPage = () => {
    setPage(prev => {
      return (prev -= 1)
    })
  }

  const _goNextPage = () => {
    setPage(prev => {
      return (prev += 1)
    })
  }

  const _goToPage = (page: any) => {
    setPage(page)
  }

  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 10)
  }

  const _goToFirstPage = () => {
    setPage(0)
    setHigh(10)
    setLow(1)
  }

  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  const nextBtn = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  const ListItem = (page: any) => {
    return page.value + 1
  }
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0) || (p === 1 && page === 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })


  return (
    <Main>
      <Text variant="title">Ship Sub Type List</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Flex jc='jc-start' className='searchArea'>
            {/* <InputWithIcon
              type="text"
              // value = {searchInput}
              // onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search by email or name.."
              icon="search"
              id="search-input"
            /> */}
            <Button variant='transparent' onClick={() => _willAddNewShipSubType()}>
              <Flex>
                <Icons icon="add" color="#ffffff" />
                {/* <Text variant="subtitle"/> */}
                ADD SHIP SUB TYPE
              </Flex>
            </Button>
          </Flex>
        </SearchForm>
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>}
        {!isLoading && displaySubType.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No Ship Sub Type found</Text>
            <Space divheight={20} />
          </>
        )}
        {!isLoading && displaySubType.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Created Date</th>
                  <th>Ship Sub Type</th>
                  <th>Ship Type</th>
                  <th>System Access</th>
                  <th colSpan={2} className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {displaySubType.map((sub: any, i: number) => (
                  <tr key={sub.id}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='Created Date'>
                      {sub.created_at === null ? (
                        <Text className='txt-table-date' color='tabledata'> - </Text>
                      ) :
                        (
                          <Text className='txt-table-date' color='tabledata'>
                            {format(parseISO(sub.created_at), 'dd/MM/yyyy')}
                          </Text>
                        )}

                    </td>
                    <td data-label='Ship Sub Type'>
                      {sub.shipsubtype_name}
                    </td>
                    <td data-label='Ship Type'>
                      {sub.shiptype_name}
                    </td>
                    <td data-label='System Access'>{sub.isactive === true ? (
                      <Text className='txt-table' color='success'>Active</Text>
                    ) : (
                      <Text className='txt-table' color='danger'>In Active</Text>
                    )}
                    </td>
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="subType-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(sub)}>
                            <Icons color="#52575a" icon="remove_red_eye" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={
                          <Tooltip id="subType-edit"> Edit
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewEdit(sub)}>
                            <Icons color="#52575a" icon="edit" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={
                          <Tooltip id="subType-delete"> Delete
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => handleShow(sub)}>
                            <Icons color="#52575a" icon="delete" />
                          </Button>
                        </OverlayTrigger>
                      </TableActions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text>
                {displaySubType.length === 1 && (
                  <>
                    <b>
                      {displaySubType.length}
                      {/* {((page)*10)+1} - {(displaySubType.length+(page)*10)} */}
                    </b> of {totalElement} Ship Sub Type                 </>
                )}
                {displaySubType.length > 1 && (
                  <>
                    <b>
                      {displaySubType.length}
                      {/* {((page)*10)+1} - {(displaySubType.length+(page)*10)} */}
                    </b> of {totalElement} Ship Sub Types
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()}
                {prevBtn()}
                {PageNumber}
                {nextBtn()}
                {lastPage()}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {/* {console.log(showModal, 'showmodal')} */}
      {showModal && <AddShipSubType onClose={() => _willCloseModal()} edit={isEdit} view={isview} user_id={1} />}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form Ship Sub Type list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Main>
  )
}

export interface TablefooterProps {
  children?: React.ReactNode
}

const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

const mapStateToProps = (state: any) => ({
  subType: state.subType.ship_sub_types,
  isLoading: state.subType.isLoading,
  error: state.subType.error,
  totalElement: state.subType.totalElement,
  totalPage: state.subType.totalPage,
})

export default connect(
  mapStateToProps,
  {
    shipSubTypeListGetAction,
    shipSubTypeSetSelectedAction,
  }
)(ShipSubTypeList)
