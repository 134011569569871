import {
    CMP_TYPE_LIST_GET,
    CMP_TYPE_LIST_GET_F,
    CMP_TYPE_LIST_GET_R,
    CMP_TYPE_SELECTED_SET
} from '../actionType'

const initialState = {
    cmpTypes: [],
    isLoading: false,
    error: null,
    totalPage: 0,
    totalElement: 0,
    selectedCmpTypes: null,
}

export default function cmpTypeReducer (state = initialState, action : any)  {
    switch (action.type) {
        case CMP_TYPE_LIST_GET:
            return {
            ...state,
            isLoading: true,
            error: null
            }
        case CMP_TYPE_LIST_GET_F:
            console.log('action called')
            console.log(action.payload.content,'content')
            return {
                ...state,
                isLoading: false,
                cmpTypes: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
            }
        case CMP_TYPE_LIST_GET_R:
            
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case CMP_TYPE_SELECTED_SET:
            return {
                ...state,
                selectedCmpTypes: action.payload
            }
        default:
            return state
    }
}