import { ofType } from 'redux-observable'
import {
  ANODE_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  anodeListGetFAction,
  anodeListGetRAction

} from '../action/anodeAction'
import anodeService from '../../services/anodeService'

export const getAnodeEpic = (action$ : any) => {
  return action$.pipe(
    ofType(ANODE_LIST_GET),
    switchMap((action : any) => {
      return anodeService.getAnode(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in shipTypeEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return anodeListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(anodeListGetRAction(err)))
      )
    })
  )
}