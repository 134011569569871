import classNames from 'classnames';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { Grid } from '../../common/components/grid/Grid';
import Navbar from '../../common/containers/navbar/Navbar';
import { Sidebar } from '../../common/containers/sidebar/Sidebar';
import authService from '../../services/authService';
import { accountGetAction } from '../../store/action/accountActions';
import { AnodeAdd } from '../master/anodes/pageContainer/AnodeAdd'
import AnodeList from '../master/anodes/AnodeList'
import AnodeEdit from '../master/anodes/pageContainer/AnodeEdit'
import AnodeDetail from '../master/anodes/pageContainer/AnodeDetail'
import { ShipAdd } from '../ship/ships/pageContainer/ShipAdd';
import ShipList from '../ship/ships/ShipList';
import ShipDetail from '../ship/ships/pageContainer/ShipDetail';
import ShipEdit from '../ship/ships/pageContainer/ShipEdit';
import ShipCompany from '../ship/shipCompanies/ShipcompaniesList'
import ShipCompanyView from '../ship/shipCompanies/pageContainer/shipCompanyView'
import UserList from '../listOfUsers/UserList';
import SystemUserList from '../listOfUsers/SystemUserList';
import RoleList from '../master/roles/RoleList';
import CountryList from '../master/countries/CountryList'
import CitiesList from '../master/cities/CitiesList'
import CompanyTypesList from '../master/company/CompanyTypesList'
import { DryDockAdd } from '../docks/dryDock/pageContainer/DryDockAdd';
import DockEdit from '../docks/dryDock/pageContainer/DryDockEdit';
import DockRequest from '../docks/dryDockCompany/pageContainer/DockRequest'
import DockDetail from '../docks/dryDock/pageContainer/DryDockDetail';
import DockCompanyDetail from '../docks/dryDockCompany/pageContainer/DryDockCompanyDetail';
import DockCompanyEdit from '../docks/dryDockCompany/pageContainer/DryDockCompanyEdit';
import { DockCompanyAdd } from '../docks/dryDockCompany/pageContainer/DryDockCompanyAdd';
import DockList from '../docks/dryDock/DryDockList';
import DryDockCompanies from '../docks/dryDockCompany/DryDockCompanies';
import { ShipCompanyAdd } from '../ship/shipCompanies/pageContainer/shipCompanyAdd';
import ShipCompanyEdit from '../ship/shipCompanies/pageContainer/shipCompanyEdit';
import shipTypeList from '../ship/shipType/ShipTypeList'
import { ShipTypeAdd } from '../ship/shipType/pageContainer/ShipTypeAdd'
import ShipTypeEdit from '../ship/shipType/pageContainer/ShipTypeEdit'
import ShipTypeDetail from '../ship/shipType/pageContainer/ShipTypeDetail'
import ShipMaterialList from '../ship/shipMaterial/ShipMaterialList'
import { ShipMaterialAdd } from '../ship/shipMaterial/pageContainer/ShipMaterialAdd'
import ShipMaterialDetail from '../ship/shipMaterial/pageContainer/ShipMaterialDetail'
import ShipMaterialEdit from '../ship/shipMaterial/pageContainer/ShipMaterialEdit'
import ShipClassificationList from '../ship/shipClassification/ShipClassificationList'
import { ClassificationAdd } from '../ship/shipClassification/pageContainer/ClassificationAdd'
import ClassificationEdit from '../ship/shipClassification/pageContainer/ClassificationEdit'
import ClassificationDetail from '../ship/shipClassification/pageContainer/ClassificationDetail'
import DockTypeList from '../docks/dockType/DockTypeList'
import { DockTypeAdd } from '../docks/dockType/pageContainer/DockTypeAdd'
import DockTypeDetail from '../docks/dockType/pageContainer/DockTypeDetail'
import DockTypeEdit from '../docks/dockType/pageContainer/DockTypeEdit'
import ProvincesList from '../master/provinces/ProvincesList'
import Login from '../login/Login'
import ThicknessList from '../master/thickness/ThicknessList'
import { ThicknessAdd } from '../master/thickness/pageContainer/ThicknessAdd'
import ThicknessDetail from '../master/thickness/pageContainer/ThicknessDetail'
import ThicknessEdit from '../master/thickness/pageContainer/ThicknessEdit'
import UnitsList from '../master/units/UnitsList'
import { UnitAdd } from '../master/units/pageContainer/UnitAdd'
import UnitDetail from '../master/units/pageContainer/UnitDetail'
import UnitEdit from '../master/units/pageContainer/UnitEdit'
import PortList from '../master/ports/PortList'
import PaintList from '../master/paint/PaintList'
import { PaintAdd } from '../master/paint/pageContainer/PaintAdd'
import PaintDetail from '../master/paint/pageContainer/PaintDetail'
import PaintEdit from '../master/paint/pageContainer/PaintEdit'
import DockOwnerList from '../docks/dockOwner/DockOwnerList'
import DockOwnerDetail from '../docks/dockOwner/pageContainer/dockOwnerDetail'
import SubscriptionList from '../master/subscription/SubscriptionList'
import './Home.scss';
import SubscriptionEdit from '../master/subscription/pageContainer/SubscriptionEdit.tsx';
import { SubscriptionAdd } from '../master/subscription/pageContainer/SubscriptionAdd.tsx'
import SubscriptionDetail from '../master/subscription/pageContainer/SubscriptionDetail.tsx'
import ManufacturerList from '../manufacturer/manageManufacturer/ManufacturerList'
import { ManufacturerAdd } from '../manufacturer/manageManufacturer/pageContainer/ManufacturerAdd.tsx'
import ManufacturerEdit from '../manufacturer/manageManufacturer/pageContainer/ManufacturerEdit.tsx'
import ManufacturerDetail from '../manufacturer/manageManufacturer/pageContainer/ManufacturerDetail.tsx'
import ManufacturerAdsDetail from '../manufacturer/manageManufacturer/pageContainer/ManufacturerAdsDetail'
import { DockAddInsideCmp } from '../docks/dryDockCompany/pageContainer/DockAddInsideCmp'
import ShipOwnerList from '../ship/shipOwner/ShipOwnerList'
import ShipOwnerDetail from '../ship/shipOwner/pageContainer/ShipOwnerDetail'
import { ShipAddinsideCmp } from '../ship/shipCompanies/pageContainer/ShipAddinsideCmp'
// import ProductTypeList from '../adspace/productType/ProductTypeList'
import ManufacturerCompanies from '../manufacturer/manufacturerCmp/ManufacturerCompanies'
import { ManufacturerCmpAdd } from '../manufacturer/manufacturerCmp/pageContainer/ManufacturerCmpAdd'
import ManufacturerCmpEdit from '../manufacturer/manufacturerCmp/pageContainer/ManufacturerCmpEdit'
import ManufacturerCmpDetail from '../manufacturer/manufacturerCmp/pageContainer/ManufacturerCmpDetail'
import MapCompany from '../listOfUsers/adduser/MapCompany'
import { UserAdd } from '../listOfUsers/adduser/UserAdd'
import { UserRoleAdd } from '../master/roles/RoleAdd'
import UserRoleView from '../master/roles/pageContainer/RolesView'
import UserRoleEdit from '../master/roles/pageContainer/RolesEdit'
import UserEdit from '../listOfUsers/adduser/UserEdit'
import UserView from '../listOfUsers/adduser/UserView'
import SystemUserEdit from '../listOfUsers/adduser/SystemUserEdit'
import SystemUserView from '../listOfUsers/adduser/SystemUserView'
// import SlotList from '../adspace/slot/SlotList'
// import AdsList from '../adspace/advertisement/AdsList'
// import {AdsAdd} from '../adspace/advertisement/pageContainer/AdsAdd'
// import AdsEdit from '../adspace/advertisement/pageContainer/AdsEdit'
// import AdsDetail from '../adspace/advertisement/pageContainer/AdsDetail'
// import SubscribedUserDetail from '../docks/dockOwner/pageContainer/SubscribedUserDetail'
// import DockPendingReq from '../docks/dryDockCompany/pageContainer/DockPendingReq'
// import {ShipTypeAdd} from '../ship/shipType/pageContainer/ShipTypeAdd'
// import ShipTypeDetail from '../ship/shipType/pageContainer/ShipTypeDetail'
import { SettingEdit } from '../settings/settingEdit'
import ShipSubTypeList from '../master/shipSubType/ShipSubTypesList'
import PricePerViewList from '../manufacturer/manageManufacturer/PricePerViewList'
import TransactionDetail from '../manufacturer/manageManufacturer/TransactionDetail'
import AdsSeenUserDetail from '../manufacturer/manageManufacturer/pageContainer/AdsSeenUserDetail'
import ManufacturerAdsEdit from '../manufacturer/manageManufacturer/pageContainer/ManufacturerAdsEdit'
import QuotationList from '../manufacturer/manageManufacturer/QuotationList'
import QuotationDetail from '../manufacturer/manageManufacturer/pageContainer/QuoteDetail'
import Dashboard from '../dashboard/dashboard';
import DatalasticShipCmpList from '../datalastics/datalasticShipCompanies/DatalasticShipCmpList'
import DatalasticExcel from '../datalastics/datalasticShipCompanies/ImportShipCmp'
import ReportList from '../social/report/ReportList'

import DatalasticShipDemoList from '../datalastics/datalasticShipDemolation/DatalasticShipDemoList'
import DatalasticShipClassList from '../datalastics/datalasticShipClassification/DatalasticShipClassList'
import DatalasticShipEngList from '../datalastics/datalasticShipEngine/DatalasticShipEngList'
import DatalasticShipEngExcel from '../datalastics/datalasticShipEngine/ImportShipEng'
import PropulsionList from '../ship/propulsionType/PropulsionTypeList'
import PropulsionDetail from '../ship/propulsionType/pageContainer/PropulsionTypeDetail'
import { PropulsionAdd } from '../ship/propulsionType/pageContainer/PropulsionTypeAdd'
import PropulsionEdit from '../ship/propulsionType/pageContainer/PropulsionTypeEdit'
import DatalasticShipClassExcel from '../datalastics/datalasticShipClassification/ImportShipClass'
import DatalasticShipDemoExcel from '../datalastics/datalasticShipDemolation/ImportShipDemo'
 
interface HomeProps {
    path?: string,
    component?: any,
    exact?: any,
}
const ProtectedRoute = (props: HomeProps) => {
    // console.log(authService.isAuthenticated(),'auth')
    if (authService.isAuthenticated() === false) {
        return <Redirect to='/login' />
    }
    else {

        return <Route exact path={props.path} component={props.component} />
    }
}
const _redirect = () => {
    return <Redirect to='/login' />
}

const Home = (props: any) => {
    React.useEffect(() => {
        props.accountGetAction()
    }, [])
    return (
        <Grid className='home_page' templateCols="230px auto">
            <Sidebar />
            <SaveView>
                <Navbar />
                <Switch>
                    <ProtectedRoute exact path='/anode/list' component={AnodeList} />
                    <ProtectedRoute exact path='/anode/add' component={AnodeAdd} />
                    <ProtectedRoute exact path='/anode/edit/:id/:cmpId/:supId/:supName' component={AnodeEdit} />
                    <ProtectedRoute exact path='/anode/:id/:cmpId/:supId' component={AnodeDetail} />
                    {/* <ProtectedRoute exact path="/docks/list/:pgnum" component={DockList} /> */}
                    <ProtectedRoute exact path="/ships/list" component={ShipList} />
                    <ProtectedRoute exact path="/ships/list/:pgnum" component={ShipList} />
                    <ProtectedRoute exact path='/ships/add' component={ShipAdd} />
                    <ProtectedRoute exact path='/ships/edit/:id' component={ShipEdit} />
                    <ProtectedRoute exact path='/ships/edit/:id/:pgnum' component={ShipEdit} />
                    <ProtectedRoute exact path="/ships/:id" component={ShipDetail} />
                    <ProtectedRoute exact path="/ships/:id/:pgnum" component={ShipDetail} />
                    <ProtectedRoute exact path="/companies/company-type" component={ShipCompany} />
                    {/* <ProtectedRoute exact path="/ships-company/list" component={ShipCompany} /> */}
                    <ProtectedRoute exact path="/ships-company/add" component={ShipCompanyAdd} />
                    <ProtectedRoute exact path='/ship-companies/:id' component={ShipCompanyView} />
                    <ProtectedRoute exact path='/ship-companies/edit/:id' component={ShipCompanyEdit} />
                    <ProtectedRoute exact path='/shipType/list' component={shipTypeList} />
                    <ProtectedRoute exact path='/shipType/add' component={ShipTypeAdd} />
                    <ProtectedRoute exact path='/shipType/edit/:id' component={ShipTypeEdit} />
                    <ProtectedRoute exact path='/shipType/:id' component={ShipTypeDetail} />
                    <ProtectedRoute exact path='/shipMaterial/list' component={ShipMaterialList} />
                    <ProtectedRoute exact path='/shipMaterial/add' component={ShipMaterialAdd} />
                    <ProtectedRoute exact path='/shipMaterial/edit/:id' component={ShipMaterialEdit} />
                    <ProtectedRoute exact path='/shipMaterial/:id' component={ShipMaterialDetail} />
                    <ProtectedRoute exact path='/thickness/list' component={ThicknessList} />
                    <ProtectedRoute exact path='/thickness/add' component={ThicknessAdd} />
                    <ProtectedRoute exact path='/thickness/edit/:id' component={ThicknessEdit} />
                    <ProtectedRoute exact path='/thickness/:id' component={ThicknessDetail} />
                    <ProtectedRoute exact path='/users' component={UserList} />
                    <ProtectedRoute exact path='/roles' component={RoleList} />
                    <ProtectedRoute exact path='/roles/add' component={UserRoleAdd} />
                    <ProtectedRoute exact path='/roles/add/:type' component={UserRoleAdd} />
                    <ProtectedRoute exact path='/roles/:id' component={UserRoleView} />
                    <ProtectedRoute exact path='/roles/edit/:id' component={UserRoleEdit} />
                    <ProtectedRoute exact path='/countries' component={CountryList} />
                    <ProtectedRoute exact path='/cities' component={CitiesList} />
                    <ProtectedRoute exact path='/provinces' component={ProvincesList} />
                    <ProtectedRoute exact path='/companyType' component={CompanyTypesList} />
                    <ProtectedRoute exact path='/shipClassification/list' component={ShipClassificationList} />
                    <ProtectedRoute exact path='/shipClassification/add' component={ClassificationAdd} />
                    <ProtectedRoute exact path='/shipClassification/edit/:id' component={ClassificationEdit} />
                    <ProtectedRoute exact path='/shipClassification/:id' component={ClassificationDetail} />
                    <ProtectedRoute exact path='/login' component={Login} />
                    <ProtectedRoute exact path='/users' component={UserList} />
                    <ProtectedRoute exact path='/system-users' component={SystemUserList} />
                    {/* <ProtectedRoute exact path='/login' component = {ShipList} /> */}
                    <ProtectedRoute exact path='/' component={Dashboard} />
                    <ProtectedRoute exact path='/dashboard' component={Dashboard} />
                    <ProtectedRoute exact path="/docks/list" component={DockList} />
                    <ProtectedRoute exact path="/docks/list/:pgnum" component={DockList} />
                    <ProtectedRoute exact path="/docks/add" component={DryDockAdd} />
                    <ProtectedRoute exact path="/docks/:id" component={DockDetail} />
                    <ProtectedRoute exact path="/docks/:id/:pgnum" component={DockDetail} />
                    <ProtectedRoute exact path="/docks/edit/:id" component={DockEdit} />
                    <ProtectedRoute exact path="/docks/edit/:id/:pgnum" component={DockEdit} />
                    <ProtectedRoute exact path="/docks/clim/:id" component={DockRequest} />
                    {/* <ProtectedRoute exact path="/docks/pending/:id" component={DockPendingReq} /> */}
                    <ProtectedRoute exact path="/comapanies/company-type" component={DryDockCompanies} />
                    <ProtectedRoute exact path="/comapanies/company-type/:pgnum" component={DryDockCompanies} />
                    <ProtectedRoute exact path="/dock-companies/:id" component={DockCompanyDetail} />
                    <ProtectedRoute exact path="/dock-companies/:id/:pgnum" component={DockCompanyDetail} />
                    <ProtectedRoute exact path="/dock-companies/edit/:id/:pgnum" component={DockCompanyEdit} />
                    <ProtectedRoute exact path="/dock-companies/edit/:id" component={DockCompanyEdit} />
                    <ProtectedRoute exact path='/docks-company/add' component={DockCompanyAdd} />
                    <ProtectedRoute exact path='/dockTypeList' component={DockTypeList} />
                    <ProtectedRoute exact path='/dockType/add' component={DockTypeAdd} />
                    <ProtectedRoute exact path='/dockType/edit/:id' component={DockTypeEdit} />
                    <ProtectedRoute exact path='/dockType/:id' component={DockTypeDetail} />
                    <ProtectedRoute exact path='/unit/list' component={UnitsList} />
                    <ProtectedRoute exact path='/unit/add' component={UnitAdd} />
                    <ProtectedRoute exact path='/units/edit/:id' component={UnitEdit} />
                    <ProtectedRoute exact path='/units/:id' component={UnitDetail} />
                    <ProtectedRoute exact path='/paint/list' component={PaintList} />
                    <ProtectedRoute exact path='/paint/add' component={PaintAdd} />
                    <ProtectedRoute exact path='/paint/edit/:id/:cmpid/:supid/:supname' component={PaintEdit} />
                    <ProtectedRoute exact path='/paint/:id/:cmpid/:supid' component={PaintDetail} />
                    <ProtectedRoute exact path='/ports' component={PortList} />
                    <ProtectedRoute exact path='/dock-owners/list' component={DockOwnerList} />
                    <ProtectedRoute exact path='/docks-owner/:id' component={DockOwnerDetail} />
                    {/* <ProtectedRoute exact path='/dock-owners/subscribedDetailList/:id' component = {SubscribedUserDetail} /> */}
                    <ProtectedRoute exact path='/subscription/list' component={SubscriptionList} />
                    <ProtectedRoute exact path='/subscription/edit/:id' component={SubscriptionEdit} />
                    <ProtectedRoute exact path='/subscription/add' component={SubscriptionAdd} />
                    <ProtectedRoute exact path='/subscription/:id' component={SubscriptionDetail} />
                    <ProtectedRoute exact path="/dock-companies/dockadd/:id" component={DockAddInsideCmp} />
                    <ProtectedRoute exact path='/ship-owners/list' component={ShipOwnerList} />
                    <ProtectedRoute exact path='/ship-owner/:id' component={ShipOwnerDetail} />
                    <ProtectedRoute exact path="/ship-companies/shipadd/:id" component={ShipAddinsideCmp} />
                    {/* <ProtectedRoute exact path='/productType/list' component={ProductTypeList}/>
                    <ProtectedRoute exact path='/slot/list' component={SlotList}/>
                    <ProtectedRoute exact path="/ads/list" component={AdsList} />
                    <ProtectedRoute exact path='/ads/add' component={AdsAdd} /> 
                    <ProtectedRoute exact path='/ads/edit/:id' component={AdsEdit} /> 
                    <ProtectedRoute exact path='/ads/:id' component={AdsDetail} /> */}
                    <ProtectedRoute exact path='/settings' component={SettingEdit} />
                    <ProtectedRoute exact path='/manufacturer/list' component={ManufacturerList} />
                    <ProtectedRoute exact path='/manufacturer/edit/:id' component={ManufacturerEdit} />
                    <ProtectedRoute exact path='/manufacturer/add' component={ManufacturerAdd} />
                    <ProtectedRoute exact path='/manufacturer/:id' component={ManufacturerDetail} />
                    <ProtectedRoute exact path='/manufacturer/ads/:id' component={ManufacturerAdsDetail} />
                    <ProtectedRoute exact path='/manufacturer/comapanies/list' component={ManufacturerCompanies} />
                    <ProtectedRoute exact path='/manufacturer-company/add' component={ManufacturerCmpAdd} />
                    <ProtectedRoute exact path='/manufacturer-companies/edit/:id' component={ManufacturerCmpEdit} />
                    <ProtectedRoute exact path='/manufacturer-companies/:id' component={ManufacturerCmpDetail} />
                    <ProtectedRoute exact path='/mapCompany/:id' component={MapCompany} />
                    <ProtectedRoute exact path='/mapCompany/:id/:type' component={MapCompany} />
                    <ProtectedRoute exact path='/mapCompany/:id/:edit' component={MapCompany} />
                    <ProtectedRoute exact path='/mapCompany/:id/:type/:addCmpId' component={MapCompany} />
                    <ProtectedRoute exact path='/mapCompany/:id' component={MapCompany} />
                    <ProtectedRoute exact path='/manufacturer-company-add/:type/:userid' component={ManufacturerCmpAdd} />
                    <ProtectedRoute exact path='/dock-company-add/:type/:userid' component={DockCompanyAdd} />
                    <ProtectedRoute exact path='/ship-company-add/:type/:userid' component={ShipCompanyAdd} />
                    <ProtectedRoute exact path='/users/add' component={UserAdd} />
                    <ProtectedRoute exact path='/users/edit/:id' component={UserEdit} />
                    <ProtectedRoute exact path='/users/edit/:id/:edit' component={UserEdit} />
                    <ProtectedRoute exact path='/users/:id' component={UserView} />
                    <ProtectedRoute exact path='/system-users/edit/:id' component={SystemUserEdit} />
                    <ProtectedRoute exact path='/system-users/:id' component={SystemUserView} />
                    <ProtectedRoute exact path='/shipSubType/list' component={ShipSubTypeList} />
                    <ProtectedRoute exact path='/perViewList/list/:id' component={PricePerViewList} />
                    <ProtectedRoute exact path='/transaction/list/:id' component={TransactionDetail} />
                    {/* <ProtectedRoute exact path ='/perViewList/list' component={PricePerViewList} />
                    <ProtectedRoute exact path ='/perClickList/list' component={PricePerClickList} /> */}
                    <ProtectedRoute exact path='/quotation/user/:type/:id/:traverse' component={AdsSeenUserDetail} />
                    <ProtectedRoute exact path='/viewedUser/:type/:id/:traverse' component={AdsSeenUserDetail} />
                    <ProtectedRoute exact path='/manufacturer/ads/edit/:type/:id' component={ManufacturerAdsEdit} />
                    <ProtectedRoute exact path='/Quotation/list/:id' component={QuotationList} />
                    <ProtectedRoute exact path='/detailed/quotation/:id/:traverse' component={QuotationDetail} />
                    <ProtectedRoute exact path="/datalastic/shipCmp" component={DatalasticShipCmpList} />
                    <ProtectedRoute exact path="/datalastic/shipClass" component={DatalasticShipClassList} />
                    <ProtectedRoute exact path="/datalastic/shipEng" component={DatalasticShipEngList} />
                    <ProtectedRoute exact path="/datalastic/shipDemo" component={DatalasticShipDemoList} />
                    <ProtectedRoute exact path="/import/shipCompanies" component={DatalasticExcel} />
                    <ProtectedRoute exact path="/social/report" component={ReportList}/>
                    <ProtectedRoute exact path="/import/shipEngine" component={DatalasticShipEngExcel} />
                    <ProtectedRoute exact path="/propulsion/list" component={PropulsionList} />
                    <ProtectedRoute exact path='/propulsion/add' component={PropulsionAdd} />
                    <ProtectedRoute exact path='/propulsion/:id' component={PropulsionDetail} />
                    <ProtectedRoute exact path='/propulsion/edit/:id' component={PropulsionEdit} />
                    <ProtectedRoute exact path="/import/shipClassification" component={DatalasticShipClassExcel} />
                    <ProtectedRoute exact path="/import/shipDemolition" component={DatalasticShipDemoExcel} />
                </Switch>
            </SaveView>
        </Grid>
    )
}

export interface SaveViewProps {
    children?: React.ReactNode
}

export const SaveView = ({ children, ...props }: SaveViewProps) => {
    const saveview = classNames('saveview-container');
    return (
        <div className={saveview}>
            {children}
        </div>
    )
}

export default connect(
    null,
    { accountGetAction }
)(Home)
