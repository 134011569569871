import React, { useRef } from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import { Input, Select } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import codeService from '../../../../services/codeService'
import Icons from '../../../../common/components/icon/Icon'
import { WEIGHT_UNIT_DEFAULT, LENGTH_UNIT_DEFAULT, BEAM_UNIT_DEFAULT, DRAFT_UNIT_DEFAULT } from '../../../../constants/units'
import shipService from '../../../../services/shipService'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import swal from 'sweetalert';
import '../../../../styles/variables/Common.scss'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner'

interface ShipAddProps {
  id?: number,
  shipName: string,
  shipType: any[],
  shipTypes: string,
  countryFlag: any[],
  countryFlags: string,
  classification: any[],
  classifications: string,
  IMONumber: string,
  shipCompanies: any[],
  shipCompany: string,
  Weight: string,
  Length: string,
  Beam: string,
  Draft: string,
  status: string,
  companyID: string,
}
interface FormData {
  txt_shipAddName: string;
  txt_shipAddType: string;
  txt_shipAddCountry: string;
  txt_shipAddClassification: string;
  txt_shipAddImo: string;
  txt_shipAddCompany: string;
  txt_shipAddWeight: string;
  txt_shipAddLength: string;
  txt_shipAddBeam: string;
  txt_shipAddDraft: string;
  radio_shipAddStatus: string;
}

interface bvalueProps {
  string_image: string | ArrayBuffer | null
}
export const ShipAddinsideCmp = (props: any) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
});
  const [isLoading, setLoading] = React.useState(false)
  const [shipAddflag, setShipAddFlag] = React.useState(false)
  const upload = useRef(null);
  const {match} = props
  const {id} = match.params
  var base64: string | ArrayBuffer | null
  const [bvalue, setBValue] = React.useState<bvalueProps>({
    string_image: ''
  })
  const getBase64 = (event: any) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      base64 = reader.result
      // console.log(base64,'base 64 value')
      setBValue(
        {
          string_image: reader.result
        })
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const [ship, setShip] = useState<ShipAddProps>({
    shipName: '',
    shipType: [],
    shipTypes: '',
    countryFlag: [],
    countryFlags: '',
    classification: [],
    classifications: '',
    IMONumber: '',
    shipCompanies: [],
    shipCompany: '',
    Weight: '',
    Length: '',
    Beam: '',
    Draft: '',
    status: '',
    companyID: '',
  },
  )
  // Used to get ship classifications list and store to classifications variable. on 04-Mar-2021
  const _getClassification = (() => {
    return codeService.getShipClassification().subscribe({
      next: r => {

        // console.log(r.data,"ship classification")
        setShip({ ...ship, classification: r.data })
        // console.log(ship.classification ,'ship classification')
        r.data.map((data: any, i: any) => {
          return ship.classification.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  // Used to get ship types list and store to types variable. on 04-Mar-2021
  const _getShipType = (() => {
    return codeService.getShipTypes().subscribe({
      next: r => {

        // console.log(r.data,"ship type")
        setShip({ ...ship, shipType: r.data })
        // console.log(ship.shipType ,'ship type data in ship add page')
        r.data.map((data: any, i: any) => {
          return ship.shipType.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  const _getCountryFlag = (() => {
    return codeService.getCountries().subscribe({
      next: r => {
        setShip({ ...ship, countryFlag: r.data })
        r.data.map((data: any, i: any) => {
          return ship.countryFlag.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  // Used to get ship companies list and store to companies variable. on 04-Mar-2021
  const _getCompany = (() => {
    return codeService.getCompanies({ company_type: 'SHIP' }).subscribe({
      next: r => {
        // console.log(r.data,'companies in ship add page')
        setShip({ ...ship, shipCompanies: r.data })
        r.data.map((data: any, i: any) => {
          return ship.shipCompanies.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  // Call classification, company, shiptype and country functions at the stage of page loading. on 04-Mar-2021
  React.useEffect(() => {
    _getClassification()
    _getCompany()
    _getShipType()
    _getCountryFlag()
  }, [])

  // After enter all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call. on 04-Mar-2021
  const _didShipAdd = () => {
    // e.preventDefault()
    ship.shipCompanies.map((data, i) => {
      if (ship.shipCompany === data.name) {
        setShip({ ...ship, companyID: String(data.id) })
      }
    })
    console.log(bvalue.string_image, 'base 64')
    const updatedForm = {
      shipTypeCode: ship.shipTypes,
      shipWeight: Number(ship.Weight),
      weightUnitCode: WEIGHT_UNIT_DEFAULT.code,
      length: Number(ship.Length),
      lengthUnitCode: LENGTH_UNIT_DEFAULT.code,
      maximumBeam: Number(ship.Beam),
      beamUnitCode: BEAM_UNIT_DEFAULT.code,
      draft: Number(ship.Draft),
      draftUnitCode: DRAFT_UNIT_DEFAULT.code,
      countryFlagCode: ship.countryFlags,
      companyCode: ship.companyID,
      status: ship.status,
      classificationCode: ship.classifications,
      imoNumber: ship.IMONumber,
      shipName: ship.shipName,
      image: bvalue.string_image,
    }

    // API call for addship with data, added to list. on 04-Mar-2021
    // console.log(updatedForm,"updatedform")

    if (shipAddflag === true) {
      shipService.addShip(updatedForm).subscribe({
        next: (r) => {
          // console.log(r,'response')
          setLoading(true)
          swal("Great", "Ship Added Successfully", "success")
          history.push('/ships/list')
        },
        error: err => {
          swal("OOPS", String(err), "error")
          setLoading(false)
        }
      })
    }
    else {
      console.log(shipAddflag, 'flag')
    }
  }
  onsubmit = (e: any) => {
    // e.preventDefault()
    if (Object.keys(errors).length === 0) {
      _didShipAdd()
      setShipAddFlag(true)
    }
    else {
      setShipAddFlag(false)
    }
  }

  if (isLoading) {
    return <Text center>
      <Loader
        type="Rings"
        color="#233269"
        height={100}
        width={100}
        timeout={30000}
      />
    </Text>
  }

  return (
    <Main>
      <Text variant="title" color='black'>Add Ship Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='ShipAddForms'>
          <Card title="Fill All the Information Below">
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Ship Name</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_shipAddName"
                      id='txt_shipAddName'
                      value={ship.shipName}
                      onChange={(e) => setShip({ ...ship, shipName: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Ship Name"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddName?.type === 'required' && 'Please enter Ship name'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Ship Type</Text>
                    <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                      value={ship.shipTypes} name='txt_shipAddType' onChange={(e) => setShip({ ...ship, shipTypes: e.target.value })} ref={register({ required: true, })}>
                      <option value="">-- Select --</option>
                      {/* Map the data's from shiptype array and used as a option value. on 04-Mar-2021 */}
                      {ship.shipType.map((data: any, i: any) => {
                        return <option key={i} value={data.id}>{data.name}</option>
                      })}
                    </Form.Control>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddType?.type === 'required' && 'Please select Ship type'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Country Flag</Text>
                    <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                      value={ship.countryFlags} name='txt_shipAddCountry' onChange={(e) => setShip({ ...ship, countryFlags: e.target.value })} ref={register({ required: true, })}>
                      <option value="">-- Select --</option>
                      {/* Map the data's from countryflag array and used as a option value. on 04-Mar-2021 */}
                      {ship.countryFlag.map((data, i) => {
                        // console.log(data.id,'id in ship country')
                        return <option key={i} value={data.id}>{data.name}</option>
                      })}
                    </Form.Control>
                    {/* {console.log(ship.countryFlags,'country flag')} */}
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddCountry?.type === 'required' && 'Please select Country name'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Classification</Text>
                    <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                      value={ship.classifications} name='txt_shipAddClassification'
                      onChange={e => setShip({ ...ship, classifications: e.target.value })} ref={register({ required: true, })}>
                      <option value="">-- Select --</option>
                      {/* Map the data's from classification array and used as a option value. on 04-Mar-2021 */}
                      {ship.classification.map((data: any, i: any) => {
                        return <option key={i} value={data.id}>{data.name}</option>
                      })}
                    </Form.Control>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddClassification?.type === 'required' && 'Please select Ship Classification'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>IMO Number</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipAddImo"
                      value={ship.IMONumber}
                      ref={register({ required: true, pattern: /^IMO [1-9]{7}/ })}
                      // step='0.01'
                      type='text'
                      // min={0}
                      // max={99999}
                      placeholder="IMO Number"
                      onChange={e => setShip({ ...ship, IMONumber: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>
                        {errors.txt_shipAddImo?.type === 'required' && 'Please enter IMO Number'}
                        {errors.txt_shipAddImo?.type === 'pattern' && 'IMO number should be IMO followed by 7 digits'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes >
                  <div>
                    <Text color='tablehead' variant='subtitle'>Weight (DWT)</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipAddWeight"
                      value={ship.Weight}
                      ref={register({ required: true, })}
                      type='number'
                      // min={0.00}
                      // max={99999.00}
                      placeholder="Weight"
                      onChange={e => setShip({ ...ship, Weight: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddWeight?.type === 'required' && 'Please enter Ship weight'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Length Overall (m)</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipAddLength"
                      value={ship.Length}
                      ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="Length"
                      onChange={e => setShip({ ...ship, Length: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddLength?.type === 'required' && 'Please enter Ship Length'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Maximum Beam (m)</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipAddBeam"
                      value={ship.Beam}
                      ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="Beam"
                      onChange={e => setShip({ ...ship, Beam: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddBeam?.type === 'required' && 'Please enter Ship beam'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Draft (m)</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipAddDraft"
                      value={ship.Draft}
                      ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="Draft"
                      onChange={e => setShip({ ...ship, Draft: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddDraft?.type === 'required' && 'Please enter Ship Draft'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
            </Row>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  {/* <div>
                      <Text color='tablehead' variant='subtitle'>Company</Text>
                      <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                       value={ship.shipCompany} name="txt_shipAddCompany"
                       ref={register({ required: true, })}
                        onChange={(e) => setShip({ ...ship, shipCompany: e.target.value })}>
                        <option value=''>-- Select Company --</option>
                        {ship.shipCompanies.map((data,i)=>{
                            console.log(data.id,'company id')
                            return <option key={i} value={data.id}>{data.name}</option>
                        })}
                      </Form.Control>
                    </div> */}
                  <div>
                    <Text color='tablehead' variant='subtitle'>Company</Text>
                    <input className='basecontrol cutome-selectInputnxt'
                      value={ship.shipCompany}
                      name="txt_shipAddCompany"
                      id='txt_shipAddCompany'
                      list='shipcompanydatalist'
                      ref={register({ required: true, })}
                      placeholder='-- Select Company --'
                      onChange={(e) => setShip({ ...ship, shipCompany: e.target.value })}>
                      {/* <option value=''></option> */}
                    </input>
                    <datalist id='shipcompanydatalist'>
                      {ship.shipCompanies.map((data, i) => {
                        // console.log(data.id,'company id')
                        return <option key={i} value={data.name}></option>
                      })}
                    </datalist>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddCompany?.type === 'required' && 'Please enter Ship Company'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Status</Text>
                    <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                      value={ship.status} name='radio_shipAddStatus' onChange={e => setShip({ ...ship, status: e.target.value })} ref={register({ required: true, })}>
                      <option value="">-- Select --</option>
                      <option value='VERIFIED'>Verified</option>
                      <option value='UNVERIFIED'>Not Verified</option>
                    </Form.Control>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.radio_shipAddStatus?.type === 'required' && 'Please select Ship status'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
            </Row>
            <Row className='m-0 p-0'>
              <Col xs='12' sm='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                <Attributes>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Ship's Image</Text>
                    <input
                      ref={upload}
                      type="file"
                      onChange={getBase64}
                    />
                  </div>
                </Attributes>
              </Col>
            </Row>
          </Card>
          <Actions>
            {/* If you click the back button its is redirect to ships list page. on 04-Mar-2021 */}
            <Link to={`/ship-companies/${id}`}>
              <Button className='bttn bttn-transparent' block={true}>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page. on 04-Mar-2021 */}
            <Button className='bttn bttn-transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </Form>
      </Grid>
      {/* Add form, having input box, select box. on 04-Mar-2021 */}
    </Main>
  )
}


export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. on 04-Mar-2021
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-ship-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. on 04-Mar-2021
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}

