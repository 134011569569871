import {
    SLOT_LIST_GET,
    SLOT_LIST_GET_F,
    SLOT_LIST_GET_R,
    SLOT_SELECTED_SET
}  from '../actionType'

export const slotListGetAction = ( params : any) => ({
    type: SLOT_LIST_GET,
    payload: params
})
  
export const slotListGetFAction = ( data : any) => ({
    type: SLOT_LIST_GET_F,
    payload: data
})
  
export const slotListGetRAction = ( error : any) => ({
    type: SLOT_LIST_GET_R,
    payload: error
})
export const slotSetSelectedAction = (slot : any) => ({
    type: SLOT_SELECTED_SET,
    payload: slot
})