import React from 'react';
import {Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import './App.scss';
import ErrorBoundary from './common/containers/error/ErrorBoundary'
import Login from './pages/login/Login'
import Home from './pages/home/Home';
import { history } from './store';

interface AppProps {}

const App = (_: AppProps) => {
    return (
        <ErrorBoundary>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route exact path='/login' component={Login} /> 
                    <Route path='/' component={Home}/>
                </Switch>
            </ConnectedRouter>
        </ErrorBoundary>
    ); 
};

export default App;
