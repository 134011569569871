import { connect } from "react-redux";
import { unitsSetSelectedAction } from '../../../../store/action/unitAction'
import React from "react";
import { Col, Form, Row } from 'react-bootstrap'
import { Text } from '../../../../common/components/text/Text'
import { Main } from "../../../../common/components/main/Main";
import { Space } from "../../../../common/components/space/Space";
import Card from "../../../../common/components/card/Card";
import { Grid } from "../../../../common/components/grid/Grid";
import { Attributes } from "../../../../common/components/attribute/Attribute";
import classNames from "classnames";
import { Flex } from "../../../../common/components/flex/Flex";
import { Link } from "react-router-dom";
import { Button } from "../../../../common/components/button/Button";
import Icons from "../../../../common/components/icon/Icon";
import unitService from '../../../../services/unitService'
import { Label } from "../../../../common/components/label/Label";
import { format, parseISO } from 'date-fns'
import Loader from "react-loader-spinner";


const UnitDetail = (props: any) => {
    const { match } = props
    const [isLoading, setLoading] = React.useState(true)
    const [display, setDisplay] = React.useState<any>({
        name: '',
        type_of_unit: '',
        representation: '',
        description: '',
        name_english: '',
        description_english: '',
    })

    //Call perticular ship detail function.  
    React.useEffect(() => {
        const { id } = match.params
        _getUnitDetail(id)
    }, [])

    //Get perticular ship detail using match.params. 
    const _getUnitDetail = (id: any) => {
        console.log(id, 'id')
        setLoading(true)
        return unitService.getUnitDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.unitsSetSelectedAction(r.data)
                console.log(r.data, 'r.data')
                setDisplay(r.data)
                // r.map((data: any, i: any) => {
                //     return display.push(data.data)
                // })
                console.log(display, 'selected ship list page in ship detail using display')
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    // If isloading is true print a text as loading. 
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    // If isloading is false print the details of perticular ship. 
    return (
        <Main>
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                <Col sm='12' md='12' lg='12' xl='8' className='m-0 p-0 align-top'>
                    <Card title="Unit Detail">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Unit</Text>
                                        {display.name === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >{display.name}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Representation</Text>
                                        {display.representation === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.representation}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Type of Unit</Text>
                                        {display.type_of_unit === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.type_of_unit}</Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Unit in English</Text>
                                        {display.name_english === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >{display.name_english}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Description</Text>
                                        {display.description === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.description}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Description in English</Text>
                                        {display.description_english === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.description_english}</Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='12' xl='8'>
                    <Actions>
                        {/* If you click the back button its is redirect to ships list page.  */}
                        <Link to='/unit/list'>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the edit button its is redirect to ships edit page.  */}
                        <Link to={`/units/edit/${display.id}`}>
                            <Button variant='transparent' type="submit">
                                <Flex>
                                    <Icons color="#ffffff" icon="edit" />
                                    EDIT DATA
                                </Flex>
                            </Button>
                        </Link>
                    </Actions>
                </Col>
            </Row>
        </Main>
    )
}
export interface ActionProps {
    children?: React.ReactNode
}

const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}


const mapStateToProps = (state: any) => ({
    unit: state.units.selectedUnits
})

// connect to unitsSetSelectedAction and get the one perticular ship data. 
export default connect(
    mapStateToProps,
    {
        unitsSetSelectedAction
    }
)(UnitDetail)