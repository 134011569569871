import React from 'react';
//set interface for space component
export interface SpaceProps {
    divheight?: string | number;
}

//space component used to set height or empty space
export const Space = ({divheight, ...props}:SpaceProps) => {
    const divHeight = {
        height: divheight
    }
    
    return (
        <div style={divHeight}>

        </div>
    )
}