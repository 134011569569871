import { connect } from "react-redux";
import { shipCompanySetSelectedAction } from '../../../../store/action/shipActions'
import shipService from '../../../../services/shipService'
import React, { useRef } from "react";
import { Col, Image, Row } from 'react-bootstrap'
import { Text } from '../../../../common/components/text/Text'
import { Main } from "../../../../common/components/main/Main";
import { Space } from "../../../../common/components/space/Space";
import Card from "../../../../common/components/card/Card";
import { Grid } from "../../../../common/components/grid/Grid";
import editicon from '../../../../assets/images/editone.png';
import swal from 'sweetalert';
import removeicon from '../../../../assets/images/Remove.png'
import { Attributes } from "../../../../common/components/attribute/Attribute";
import classNames from "classnames";
import { Flex } from "../../../../common/components/flex/Flex";
import { Link } from "react-router-dom";
import { Button } from "../../../../common/components/button/Button";
import Icons from "../../../../common/components/icon/Icon";
import { Label } from "../../../../common/components/label/Label";
import { format, parseISO } from 'date-fns'
import Loader from "react-loader-spinner";
import noimg from '../../../../assets/images/NoImg.jpg';

interface ViewProps {
    ship: any[]
}
const ShipCompanyView = (props: any) => {
    const { match } = props
    const [isLoading, setLoading] = React.useState(true)
    const [display, setCompany] = React.useState<any>({})
    const [form, setForm] = React.useState<ViewProps>({
        ship: []
    })

    //Call perticular ship detail function.   
    React.useEffect(() => {
        const { id } = match.params
        _getShipDetail(id)
        _getNumOfShipDetail(id)
    }, [])


    const _getNumOfShipDetail = ((id: number) => {
        return shipService.getShipViewDetail(id).subscribe({
            next: r => {
                setForm({ ...form, ship: r.data })
                r.data.map((data: any, i: any) => {
                    return form.ship.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const role = localStorage.getItem("role")

    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<any>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageUserAPI(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const _removeShipCompanyLogo = (id: any) => {
        return shipService.removeShipCompanyLogo(id).subscribe({
            next: (r) => {
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
            },
            error: err => {
                swal("OOPS", String(err), "error")
            }
        })
    }
    const ImageUserAPI = (imageurl: any) => {
        const { id } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        shipService.updateShipCompanyImage(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                window.location.reload();
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }

    //Get perticular ship detail using match.params.  
    const _getShipDetail = (id: any) => {
        setLoading(true)
        // console.log('ship company view called')
        return shipService.getShipCompanyDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.shipCompanySetSelectedAction(r.data)
                // console.log(r.data,'r.data')
                setCompany(r.data)
                // r.map((data: any, i: any) => {
                //     return display.push(data.data)
                // })
                // console.log(display,'selected ship company page in ship detail using display')
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    // If isloading is true print a text as loading.  
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    // If isloading is false print the details of perticular ship.  
    return (
        <Main>
            {display === null && (
                <>
                    <Space divheight={15} />
                    <Text color='main' variant='subtitle' center bold> There is No data for Requested id</Text>
                    <Space divheight={15} />
                </>
            )}
            {display !== null && (
                <>
                    <div className='title-name-block'>
                        <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                            {display.company_logo === null && (
                                <Image src={noimg} alt='logo' roundedCircle />
                            )}
                            {display.company_logo === '' && (
                                <Image src={noimg} alt='logo' roundedCircle />
                            )}
                            {display.company_logo !== null && display.company_logo !== '' && (
                                <Image src={display.company_logo} alt='logo' roundedCircle />
                            )}
                        </div>
                        {role === 'VISITOR' && (
                            <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                                <label className="custom-file-upload">
                                    <Image src={editicon} alt='logo' />
                                    <input
                                        ref={upload}
                                        type="file"
                                        onChange={getBase64}
                                    />
                                </label>
                            </div>
                        )}
                        <Text variant='title' color='main' className='txt-capitalize'>
                            {/* {String(display.email).substring(0,String(display.email).indexOf('@'))} */}
                            {display.name}
                        </Text>
                        <Label className={display.status === 'UNVERIFIED' ? 'danger' : 'warning'} >
                            {display.isactive === false ? 'Not Verified' : 'Verified'}
                        </Label>
                    </div>
                    <Space divheight={15} />
                    <Row className='m-0 p-0'>
                        <Col sm='12' md='12' lg='8' xl='8' className='m-0 p-0 align-top'>
                            <Card title="Ship Company Detail">
                                <Row className='m-0 p-0'>
                                    <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='singleFrame'>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Name</Text>
                                                <Text color='main' variant='subtitle' bold>
                                                    {display.name}
                                                </Text>
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Email</Text>
                                                {display.email === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {display.email}
                                                    </Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Website</Text>
                                                {display.website === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {display.website}
                                                    </Text>
                                                )}

                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Company Contact Number</Text>
                                                {display.phone_code === '' || null ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <>
                                                        {display.phone === '' || null ? (
                                                            <Text color='main' bold variant='subtitle'>{display.phone_code}
                                                                <span>
                                                                    <Text color='main' bold variant='subtitle'>{display.phone}</Text>
                                                                </span>
                                                            </Text>
                                                        ) : (
                                                            <>
                                                                <Text color='main' bold variant='subtitle'>{display.phone_code} &nbsp;
                                                                    <span>
                                                                        <Text color='main' bold variant='subtitle'>{display.phone}</Text>
                                                                    </span>
                                                                </Text>
                                                            </>
                                                        )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Address</Text>

                                                {display.address === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {display.address}
                                                    </Text>
                                                )}
                                            </div>
                                            
                                        </Attributes>
                                    </Col>
                                    <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='view_Page'>
                                            {/* <div>
                                                <Text color='main' variant='subtitle'>Company Contact Number</Text>
                                                <Text color='main' variant='subtitle' bold>
                                                    {display.phone}
                                                </Text>
                                            </div> */}
                                            <div>
                                                <Text color='main' variant='subtitle'>Country</Text>
                                                {display.companyCity === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                   display.companyCity.country === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                    -
                                                    </Text>
                                                   ) : (
                                                    display.companyCity.country.name === null ? (
                                                        <Text color='main' variant='subtitle' bold>
                                                        -
                                                        </Text>
                                                       ) : (
                                                        <Text color='main' variant='subtitle' bold>
                                                            {display.companyCity.country.name}
                                                        </Text>
                                                       )
                                                   )
                                                )}

                                            </div>
                                            <div>
                                            <Text color='main' variant='subtitle'>City</Text>
                                                {display.companyCity === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                   display.companyCity.name === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                    -
                                                    </Text>
                                                   ) : (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {display.companyCity.name}
                                                    </Text>
                                                   )
                                                )}

                                            </div>
                                            {display.user === null ? (
                                                 <Text color='danger' variant='subtitle' className="no_user" bold>
                                                 No user is mapped to this company
                                             </Text>
                                            ) : (
                                                <>
                                                <div>
                                                <Text color='main' variant='subtitle'>CEO</Text>
                                                {display.user === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                   display.user.ceo === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                    -
                                                    </Text>
                                                   ) : (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {display.user.ceo}
                                                    </Text>
                                                   )
                                                )}

                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Chairman</Text>
                                                {display.user === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                   display.user.chairman === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                    -
                                                    </Text>
                                                   ) : (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {display.user.chairman}
                                                    </Text>
                                                   )
                                                )}

                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Director</Text>
                                                {display.user === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                   display.user.directors === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                    -
                                                    </Text>
                                                   ) : (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {display.user.directors}
                                                    </Text>
                                                   )
                                                )}

                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Year of Establishment</Text>
                                                {display.user === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                   display.user.year_of_establishment === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                    -
                                                    </Text>
                                                   ) : (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {display.user.year_of_establishment}
                                                    </Text>
                                                   )
                                                )}

                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Services</Text>
                                                {display.user === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                   display.user.services === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                    -
                                                    </Text>
                                                   ) : (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {display.user.services}
                                                    </Text>
                                                   )
                                                )}

                                            </div>
                                                </>
                                            )}
                                            
                                        </Attributes>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='12'>
                                        <Card title="Ship Detail">
                                            <div className='dock-add'>
                                                {role === 'VISITOR' ? (
                                                    //                                                <Link to={`/ship-companies/shipadd/${display.id}`} >
                                                    <Button variant='transparent' disabled>
                                                        <Flex>
                                                            <Icons color="#ffffff" icon="add" />
                                                            ADD SHIP
                                                        </Flex>
                                                    </Button>
                                                    //                                               </Link>
                                                ) : (
                                                    <Link to={`/ship-companies/shipadd/${display.id}`} >
                                                        <Button variant='transparent'>
                                                            <Flex>
                                                                <Icons color="#ffffff" icon="add" />
                                                                ADD SHIP
                                                            </Flex>
                                                        </Button>
                                                    </Link>
                                                )}
                                            </div>
                                            {form.ship.length === 0 && (
                                                <>
                                                    <Space divheight={20} />
                                                    <Text variant='subtitle' center color="main" bold>No Ship Associated with this company</Text>
                                                    <Space divheight={20} />
                                                </>
                                            )}
                                            <Row className='m-0 p-0'>
                                                <Col sm='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    {form.ship.map((data: any, i: any) => [
                                                        <Attributes id={data.id} className='view_Page d-block d-md-block d-sm-block d-lg-inline-block d-xl-inline-block w-50'>
                                                            <div>
                                                                <Text color='main' variant='subtitle'>Ship Name</Text>
                                                                <Text variant='subtitle' color="main" bold>{data.ship_name}</Text>
                                                            </div>
                                                            <div>
                                                                <Text color='main' variant='subtitle'>Country </Text>
                                                                <Text color="main" variant='subtitle' bold> {data.country_name} </Text>
                                                            </div>

                                                        </Attributes>
                                                    ])}

                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col sm='12' md='12' lg='4' xl='4' className='m-0 p-0 align-top'>
                            <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                                <Card title="Company Logo">
                                    <div className='user-mainBlock'>
                                        {display.company_logo === null && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {display.company_logo === '' && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {display.company_logo !== null && display.company_logo !== '' && (
                                            <div className='userCompany-block'>
                                                <img src={display.company_logo} alt='logo' />
                                            </div>
                                        )}
                                    </div>
                                    {((display.company_logo === null)||(display.company_logo === '')) && role === 'VISITOR' && (
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    {/* <Icons color="#ffffff" icon="edit" /> */}
                                                    <Image src={editicon} alt='logo' />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    onChange={getBase64}
                                                />
                                            </label>
                                        </div>
                                    )}
                                    {display.company_logo !== null && display.company_logo !== '' && role === 'VISITOR' && (
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    <Image src={editicon} alt='logo' className="editIconRemove" />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    onChange={getBase64}
                                                />
                                            </label>
    
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    {/* <Icons color="#ffffff" icon="edit" /> */}
                                                    <Image src={removeicon} alt='logo' onClick={() => _removeShipCompanyLogo(display.id)} />
                                                </Flex>
                                            </label>
                                        </div>
                                    )}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='12' md='12' lg='12' xl='8'>
                            <Actions>
                                {/* If you click the back button its is redirect to ships list page. */}
                                <Link to='/companies/company-type'>
                                    <Button type="button" variant='transparent'>
                                        <Flex jc='jc-around'>
                                            <Icons icon="arrow_back" />
                                            BACK
                                        </Flex>
                                    </Button>
                                </Link>
                                {/* If you click the edit button its is redirect to ships edit page. */}
                                {role === 'VISITOR' ? (
                                    //                                <Link to={`/ship-companies/edit/${display.id}`} >
                                    <Button variant='transparent' type="submit" disabled>
                                        <Flex>
                                            <Icons color="#ffffff" icon="edit" />
                                            EDIT DATA
                                        </Flex>
                                    </Button>
                                    //                              </Link>
                                ) : (
                                    <Link to={`/ship-companies/edit/${display.id}`}>
                                        <Button variant='transparent' type="submit">
                                            <Flex>
                                                <Icons color="#ffffff" icon="edit" />
                                                EDIT DATA
                                            </Flex>
                                        </Button>
                                    </Link>
                                )}
                            </Actions>
                        </Col>
                    </Row>
                </>
            )}
        </Main>
    )
}
export interface ActionProps {
    children?: React.ReactNode
}

const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}


const mapStateToProps = (state: any) => ({
    ship: state.ship.selectedShip
})

// connect to shipSetSelectedaction and get the one perticular ship data.  
export default connect(
    mapStateToProps,
    {
        shipCompanySetSelectedAction
    }
)(ShipCompanyView)