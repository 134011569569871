import React, { ReactNode, useCallback } from 'react'
import { Text } from '../../common/components/text/Text'
import { Main } from '../../common/components/main/Main'
import { InputWithIcon, Select } from '../../common/components/control/Control'
import UserImg from '../../assets/images/User.png'
import UserImg1 from '../../assets/images/UserGrey.png'
import { Button } from '../../common/components/button/Button'
import Card from '../../common/components/card/Card'
import Icons from '../../common/components/icon/Icon'
import { Flex } from '../../common/components/flex/Flex'
import { Space } from '../../common/components/space/Space'
import { Table } from '../../common/components/table/Table'
import { Col, Modal, OverlayTrigger, Pagination, Popover, Row, Tooltip } from 'react-bootstrap'
import '../../common/components/pagination/Pagination.scss'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import { history } from '../../store'
import {
  userListGetAction,
  userSetSelectedAction,
  selectAllUserAction
} from '../../store/action/userActions'
import { useState } from 'react'
import userService from '../../services/userService'
import swal from 'sweetalert'
import codeService from '../../services/codeService'
import Loader from 'react-loader-spinner'
import { UserFilterState } from '../listOfUsers/filter/UserFilterState';
import { Link } from 'react-router-dom';

interface addUserProps {
  roles: any[];
}

const UserList = (props: any) => {
  const { user, isLoading, totalElement } = props
  const [displayUsers, setUsers] = React.useState<any>([])
  var totalPage = Math.ceil(totalElement / 10)
  // const [allUser, setAllUser] = useState<any>([])
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [high, setHigh] = useState(9)
  const [low, setLow] = useState(1)
  const perPage = 9
  const [findBy, setFindBy] = React.useState(UserFilterState.Userlistfilter.findbyIdName)
  // const headerName = 'USER DETAIL'
  // const filename = "UserDetail__" + new Date()
  const [searchInput, setSearchInput] = React.useState(UserFilterState.Userlistfilter.searchBoxInputName)
  const [searchEmail, setSearchEmail] = React.useState(UserFilterState.Userlistfilter.searchBoxInputEmail)
  const [searchMobile, setSearchMobile] = React.useState(UserFilterState.Userlistfilter.searchBoxInputMobile)
  const [rolelist, setRoleList] = useState(UserFilterState.Userlistfilter.searchBoxInputRole)
  const [statuslist, setStatusList] = useState(UserFilterState.Userlistfilter.searchBoxInputStatus)
  const [form, setForm] = useState<addUserProps>({
    roles: [],
  })
  const [showDelete, setShow] = React.useState(false);
  const [showUserDelete, setShowUser] = React.useState(false);
  const [deleteUser, setDeleteUser] = React.useState(0)
  // var usersname = '';
  // var removed_after = 0;
  // const [searchQuery, setQuery] = useState('')

  React.useEffect(() => {
    //const { totalPage } = props
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  React.useEffect(() => {
    props.userListGetAction({
      size: 10, page, type: 'home', username: searchInput||UserFilterState.Userlistfilter.searchBoxInputName,
      status: statuslist||UserFilterState.Userlistfilter.searchBoxInputStatus, role: rolelist||UserFilterState.Userlistfilter.searchBoxInputRole, phone: searchMobile||UserFilterState.Userlistfilter.searchBoxInputMobile, email: searchEmail||UserFilterState.Userlistfilter.searchBoxInputEmail
    })
  }, [totalElement, page])

  const get_Role = (() => {
    return codeService.getUserRoles('home').subscribe({
      next: r => {
        console.log(r.data,"Role")
        // console.log(form.roles ,'Form Role')
        r.data.map((data: any, i: any) => {
          return form.roles.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  React.useEffect(() => {
    get_Role();
  }, [])

  React.useEffect(() => {
    if (user && user.length > 0) {
      // console.log(user, 'user')
      setUsers(() => {
        return user.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (user && user.length === 0) {
      setUsers([])
    }
  }, [user, page])

  const _getStatus = (val: string) => {
    setStatusList(val)
    UserFilterState.Userlistfilter.searchBoxInputStatus=val;
    props.userListGetAction({
      size: 10, page: 0,type: 'home', username: searchInput,
      status: val, role: rolelist, phone: searchMobile, email: searchEmail
    })
    setPage(0)
  }

  const _getRole = (val: string) => {
    setRoleList(val)
    UserFilterState.Userlistfilter.searchBoxInputRole=val;
    props.userListGetAction({
      size: 10, page: 0,type: 'home', username: searchInput,
      phone: searchMobile, email: searchEmail, status: statuslist, role: val
    })
    setPage(0)
  }

  const _searchFuntion = (value: string) => {
    setSearchInput(value)
    UserFilterState.Userlistfilter.searchBoxInputName=value;
    props.userListGetAction({
      size: 10, page: 0,type: 'home', username: value,
      status: statuslist, role: rolelist, phone: searchMobile, email: searchEmail
    })
    setPage(0)
  }

  const _searchMailFuntion = (value: string) => {
    setSearchEmail(value)
    UserFilterState.Userlistfilter.searchBoxInputEmail=value;
    props.userListGetAction({
      size: 10, page: 0,type: 'home', username: searchInput, status: statuslist,
      phone: searchMobile, email: value, role: rolelist
    })
    setPage(0)
  }

  const _searchMobileFuntion = (value: string) => {
    setSearchMobile(value)
    UserFilterState.Userlistfilter.searchBoxInputMobile=value;
    props.userListGetAction({
      size: 10, page: 0,type: 'home', username: searchInput,
      phone: value, email: searchEmail, status: statuslist, role: rolelist
    })
    setPage(0)
  }
  const _showAll = () => {
    props.userListGetAction({ size: 10, page: 0,type: 'home', })
    setSearchInput('')
    setSearchMobile('')
    setSearchEmail('')
    setStatusList('')
    setRoleList('')
    UserFilterState.Userlistfilter.searchBoxInputName='';
    UserFilterState.Userlistfilter.searchBoxInputMobile='';
    UserFilterState.Userlistfilter.searchBoxInputEmail='';
    UserFilterState.Userlistfilter.searchBoxInputRole='';
    UserFilterState.Userlistfilter.searchBoxInputStatus='';
  }
  const _willViewEdit = (detail: string | number | any) => {
    props.userSetSelectedAction(detail)
    history.push(`/users/edit/${detail.user_id}`)
  }

  const _willViewDetail = (detail: string | number | any) => {
    props.userSetSelectedAction(detail)
    history.push(`/users/${detail.user_id}`)
  }

  const _willMap = (user: any) => {
    props.userSetSelectedAction(user)
    history.push(`/mapCompany/${user.user_id}`)
  }

  const _willRevokeUser = (user: any) => {
    return userService.revokeUser({ id: Number(user.user_id) }).subscribe({
      next: r => {
        // console.log(r,'r in revoke user')
        props.userListGetAction({ size: 10, page,type: 'home', username: searchInput, status: statuslist, role: rolelist })
        swal("Great", r.msg, "success")
      },
      error: err => {
        console.error(err)
        swal("OOPS", String(err), "error")
      }
    })
  }

  const _willMapWithEdit = (user: any) => {
    props.userSetSelectedAction(user)
    var edit = true
    // history.push(`/mapCompany/${user.user_id}/${edit}`)
    history.push(`/users/edit/${user.user_id}/${edit}`)
  }

  const handleClose = () => setShow(false);
  const handleShow = (user: any) => {
    if(user.user_role === 'Individual'){
      setShow(true);
      setDeleteUser(user.user_id)
    } else {
      setShowUser(true)
    }
    
    // console.log(deleteAnode, 'deleted anode id')
  }

  const handleCloseUser = () => {
    setShowUser(false)
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deleteUser(deleteUser)
  }

  const _deleteUser = (id: any) => {
    return userService.deleteUser(id).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.userListGetAction({ size: 10, page })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }

  const _getSearchValue = (val: string) => {
    setFindBy(val)
    UserFilterState.Userlistfilter.findbyIdName=val;
  }

  const _goPrevPage = () => {
    setPage(prev => {
      return (prev -= 1)
    })
  }

  const role = localStorage.getItem("role")

  const _goNextPage = () => {
    setPage(prev => {
      return (prev += 1)
    })
  }

  const _goToPage = (page: any) => {
    setPage(page)
  }

  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 10)
  }

  const _goToFirstPage = () => {
    setPage(0)
    setHigh(10)
    setLow(1)
  }

  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  const nextBtn = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  const ListItem = (page: any) => {
    return page.value + 1
  }
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0) || (p === 1 && page === 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })


  return (
    <Main>
      <Text variant="title">User Management</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-2 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    {findBy === 'name' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Name.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === '' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Name.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === 'status' && (
                      <Select values={statuslist} name="status" onChange={(e) => _getStatus(e.target.value)}>
                        <option value=''>Search by status</option>
                        <option value="VERIFIED"> Verified</option>
                        <option value="UNVERIFIED"> Not Verified</option>
                        <option value=''>ALL</option>
                      </Select>
                    )}
                    {findBy === 'role' && (
                      <Select values={rolelist} name="role" onChange={(e) => _getRole(e.target.value)}>
                        <option value=''>Search by Role</option>
                        {form.roles.map((data: any, i: any) => {
                          return <option key={i} value={data.name}>{data.name}</option>
                        })}
                        <option value=''>ALL</option>
                      </Select>
                    )}
                    {findBy === 'email' && (
                      <InputWithIcon
                        type="text"
                        value={searchEmail}
                        onChange={(e) => _searchMailFuntion(e.target.value)}
                        placeholder="Search by Email..."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === 'mobile' && (
                      <InputWithIcon
                        type="text"
                        value={searchMobile}
                        onChange={(e) => _searchMobileFuntion(e.target.value)}
                        placeholder="Search by Mobile..."
                        icon="search"
                        id="search-input"
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <Row className='m-0 p-0'>
                <Col xs='6' sm='4' md='4' lg='5' xl='4' className='m-0 p-0 mr-2'>
                  <Select values={findBy} name="" onChange={(e) => _getSearchValue(e.target.value)} required>
                    <option value=''>-- Search by --</option>
                    <option value="name"> By Name </option>
                    <option value="status"> By Status </option>
                    <option value="role"> By Role </option>
                    <option value="email"> By Email </option>
                    <option value="mobile"> By Mobile </option>
                  </Select>
                </Col>
              
                <Col xs='5' sm='4' md='4' lg='3' xl='3' className='m-0 p-0 '>
                
                  <div>
                    <Button variant='transparent' block={true} onClick={() => _showAll()}>
                      Show All
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SearchForm>
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>}
        {!isLoading && displayUsers.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No users found</Text>
            <Space divheight={20} />
          </>
        )}
        {!isLoading && displayUsers.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Created Date</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Company Name</th>
                  <th>Role</th>
                  <th> System Acess </th>
                  <th>Status</th>
                  {/* <th>Map</th> */}
                  <th colSpan={2} className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {displayUsers.map((user: any, i: any) => (
                  <tr key={user.user_id}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    {user.created_date === null && (
                      <td data-label='Created Date'>-</td>
                    )}
                    {user.created_date !== null && (
                      <td data-label='Created Date'>
                        {format(parseISO(user.created_date), 'dd/MM/yyyy')}
                      </td>
                    )}
                    {String(user.user_name).includes('@') && (
                      <td data-label='User Name'>
                        {String(user.user_name).substring(0, String(user.user_name).indexOf('@'))}
                      </td>
                    )}
                    {!String(user.user_name).includes('@') && (
                      <td data-label='User Name'>
                        {user.user_name}
                      </td>
                    )
                    }

                    {((user.user_email === null) || (user.user_email)) === '' &&(
                      <td data-label='Email'>-</td>
                    )}
                    {user.user_email !== null && user.user_email !== '' &&(
                      <td data-label='Email'>
                        {user.user_email}
                      </td>
                    )}
                    {((user.phone === null)|| (user.phone === '')) && (
                      <td data-label='Mobile'>-</td>
                    )}
                    {user.phone !== null && user.phone !== '' && (
                      <td data-label='Mobile'>
                        {user.phone}
                      </td>
                    )}
                    {((user.company_name === null) || (user.company_name==='')) && (
                      <td data-label='Company Name'>-</td>
                    )}
                    {user.company_name !== null && user.company_name !== '' &&(
                      <td data-label='Company Name'>
                        {user.company_name}
                      </td>
                    )}
                    {((user.user_role === null) || (user.user_role === '' ))&& (
                      <td data-label='Role'>-</td>
                    )}
                    {user.user_role !== null && user.user_role !== '' && (
                      <td data-label='Role'>
                        {user.user_role}
                      </td>
                    )}
                    <td data-label='System Acess'>
                      {user.isactive === true ? (
                        <Text color='success' variant='subtitle'>Active</Text>
                      ) : (
                        <Text color='danger' variant='subtitle'>Inactive</Text>
                      )}
                    </td>
                    <td data-label='Status'>
                      {user.status === 'VERIFIED' ? (
                        <Text color='warning' variant='subtitle'>Verified</Text>
                      ) : (
                        <Text color='danger' variant='subtitle'>Not Verified</Text>
                      )}
                    </td>
                    <td className='text-lg-center' data-label='Map'>
                      {user.invite_status === false && user.invite_codeused === false && user.company_name === null && role !== 'VISITOR' &&(
                        <OverlayTrigger overlay={
                          <Tooltip id="tooltip-disabled"> The user does not have any company, so you could not
                            Map Company. If you want to Map go to 'edit' page, Add company Detail then Map.
                          </Tooltip>
                        }>
                          <div className="d-inline-block">
                            <Button className='link_button disabled-btn'
                              disabled
                            >
                              <Icons color="#52575a" icon="restore" />
                            </Button>
                          </div>
                        </OverlayTrigger>
                      )}
                      {user.invite_status === true && user.company_name !== null && user.invite_codeused === false && role !== 'VISITOR' &&(
                        <OverlayTrigger overlay={
                          <Tooltip id="tooltip-wait"> Sent Invite code.
                          </Tooltip>
                        }>
                          <div className="d-inline-block">
                            <Icons color="#52575a" icon="hourglass_empty" />
                          </div>
                        </OverlayTrigger>
                      )}
                      {role === 'VISITOR' &&(
                        <OverlayTrigger overlay={
                          <Tooltip id="tooltip-wait-visitor" > Invite code process is not available
                          </Tooltip>
                        }>
                          <div className="d-inline-block">
                            <Icons color="#52575a" icon="hourglass_empty" />
                          </div>
                        </OverlayTrigger>
                      )}
                      {user.invite_codeused === true && user.company_name !== null && role !== 'VISITOR' &&(
                        <OverlayTrigger overlay={
                          <Tooltip id="tooltip-sent"> Company Mapped
                          </Tooltip>
                        }>
                          <div className="d-inline-block">
                            <Icons color="#52575a" icon="check" />
                          </div>
                        </OverlayTrigger>
                      )}
                      {user.company_name !== null && user.invite_status === false && user.invite_codeused === false && role !== 'VISITOR' &&(
                        <OverlayTrigger overlay={
                          <Tooltip id="tooltip-map"> Map
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willMap(user)}>
                            <Icons color="#52575a" icon="restore" />
                          </Button>
                        </OverlayTrigger>

                      )}

                    </td> 
                    <td>
                      <TableActions>
                        {user.invite_status === false && user.invite_codeused === false && role !== 'VISITOR' && (
                          <OverlayTrigger overlay={
                            <Tooltip id="tooltip-disabled-revoke"> User have not mapped with any company
                            </Tooltip>
                          }>
                            <div className="d-inline-block">
                              <Button className='link_button disabled-btn'
                                disabled
                              >
                                <img src={UserImg} alt='revoke' />
                              </Button>
                            </div>
                          </OverlayTrigger>
                        )}
                        {user.invite_status === true && user.user_role === "Individual" && user.invite_codeused === false && role !== 'VISITOR' &&(
                          <OverlayTrigger overlay={
                            <Tooltip id="user-revok_one"> Revoke User
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willRevokeUser(user)}>
                              <img className='grey-img' src={UserImg1} alt='revoke' />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {user.invite_codeused === true && user.company_name !== null && user.user_role !== "Individual" && role !== 'VISITOR' &&(
                          <OverlayTrigger overlay={
                            <Tooltip id="user-revok_two"> Revoke User
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willRevokeUser(user)}>
                              <img src={UserImg1} alt='revoke' />
                            </Button>
                          </OverlayTrigger>
                        )} 
                        <OverlayTrigger overlay={
                          <Tooltip id="user-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(user)}>
                            <Icons color="#52575a" icon="remove_red_eye" />
                          </Button>
                        </OverlayTrigger>
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="user-edit-visitor"> Edit option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(user)} disabled>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="user-edit"> Edit
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(user)}>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="user-delete-visitor"> Delete option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(user)} disabled>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="user-delete-visitor"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(user)}>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </TableActions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text>
                {displayUsers.length === 1 && (
                  <>
                    <b>
                      {displayUsers.length}
                      {/* {((page) * 10) + 1} - {(displayUsers.length + (page) * 10)} */}
                    </b> of {totalElement} User
                  </>
                )}
                {displayUsers.length > 1 && (
                  <>
                    <b>
                      {displayUsers.length}
                      {/* {((page) * 10) + 1} - {(displayUsers.length + (page) * 10)} */}
                    </b> of {totalElement} Users
                  </>
                )}

              </Text>
              <Pagination size="sm">
                {firstPage()}
                {prevBtn()}
                {PageNumber}
                {nextBtn()}
                {lastPage()}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {/* {console.log(showModal, 'showmodal')} */}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form user list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showUserDelete} onHide={handleCloseUser}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Warning
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='This User is mapped with company. So Please delete the corresponding records or Revoke the User' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleCloseUser}>
            <Icons icon="close" />
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </Main>
  )
}

export interface TablefooterProps {
  children?: React.ReactNode
}

const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

const mapStateToProps = (state: any) => ({
  user: state.user.users,
  isLoading: state.user.isLoading,
  error: state.user.error,
  totalElement: state.user.totalElement,
  totalPage: state.user.totalPage,
  alluser: state.user.allusers

})

export default connect(
  mapStateToProps,
  {
    userListGetAction,
    userSetSelectedAction,
    selectAllUserAction
  }
)(UserList)


// <Popover id="popover-contained">
                          //   {/* <Popover.Title as="h3">The user does not have any company, so you could not
                          // Map Comapny. If you want to Map go to 'edit' page Add compay Detail then Map.</Popover.Title> */}
                          //   <Popover.Content>
                          //     The user does not have any company, so you could not
                          //   Map Comapny. If you want to Map go to 'edit' page Add compay Detail then Map.
                          //   </Popover.Content>
                          // </Popover>                       
                        // <Button className='link_button' 
                        //   disabled                        
                        //   // onClick={() => _willMapWithEdit(user)}
                        // >
                        //   <Icons color="#52575a" icon="restore" />
                        // </Button>