import React, {ReactNode } from 'react'
import classNames from 'classnames'
import {Text} from '../text/Text'
import {Space} from '../space/Space'
import Icons from '../icon/Icon'
import './Modal.scss'

//set interface for Modal component
export interface ModalProps{
    title? : string;
    children? : React.ReactNode;
    onClose ? : any;
    className ? :string;
}
//set interface for onClose component
export interface onCloseProps{
    children? : React.ReactNode;
    onClick?: (e: React.MouseEvent) => any;
}
//set interface for backdrop and modalContent component
export interface basicProps{
    children? : ReactNode;
    className? : string;
}

//Modal Component used for display the modal box.
const Modal = ({title,children,className, ...props} : ModalProps) => {
    //const btnClassNames = classNames();

    return (
       <Backdrop>
            <ModalContent className = {className}>
                <CloseIcon 
                  onClick={() => props.onClose()}
                 >
                    <Icons icon="close" /> 
                </CloseIcon>
                <Text variant='title-sm' color='main' bold center className='modal-title'>
                    {title}
                </Text>
                <Space divheight ={10} />
                <div>{children}</div>
            </ModalContent>
        </Backdrop> 
    )
}

//Backdrop component screen behind the modal box
const Backdrop = (props:basicProps) => {
    const backdrop = classNames('backdrop-container',props.className)
    
    return(
        <div className={backdrop}>
            {props.children}
        </div>
    )
}

//ModalContent component container for content written inside the modal box 
const ModalContent = (props:basicProps) => {
   console.log(props.className)
    const modalContent = classNames('content-container',props.className)
    
    return(
        <div className={modalContent}>
            {props.children}
        </div>
    )
}

//CloseIcon component for Close Icon container 
const CloseIcon = (props:onCloseProps) => {
    const closeicon = classNames('icon-container',)
    
    return(
        <div className={closeicon} onClick={props.onClick}>
            {props.children}
        </div>
    )
}
export default Modal
