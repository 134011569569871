import React from 'react';
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap';
import '../ships/Ship.scss'
import '../../../common/components/pagination/Pagination.scss'
import { Text } from '../../../common/components/text/Text'
import { Space } from '../../../common/components/space/Space';
import { Main } from '../../../common/components/main/Main';
import { Button } from '../../../common/components/button/Button';
import Card from '../../../common/components/card/Card';
import { Grid } from '../../../common/components/grid/Grid';
import { Table } from '../../../common/components/table/Table';
import Icons from '../../../common/components/icon/Icon';
import { format, parseISO } from 'date-fns'
import {
  shipCompanyListGetAction,
  shipCompanySetSelectedAction
} from "../../../store/action/shipActions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { history } from '../../../store'
import { Flex } from '../../../common/components/flex/Flex';
import { ReactNode } from 'react'
import shipService from '../../../services/shipService';
import swal from 'sweetalert'
import { InputWithIcon } from '../../../common/components/control/Control';
import ShipCmpFilter from './pageContainer/ShipCmpFilter'
import { ShipFilterState } from '../filter/ShipFilterState'
import ShipView from './pageContainer/ShipView'
import Loader from 'react-loader-spinner';
import Filterimg from '../../../assets/images/Filter 2.png'
import sortup from '../../../assets/images/sortup_icon.png'
import sortdown from '../../../assets/images/sortdown_icon.png'
import sortimage from '../../../assets/images/sorting.png'


const ShipcompaniesList = (props: any) => {
  const { companies, isLoading, totalElement } = props
  const [displayCompanies, setCompanies] = React.useState<any>([])
  const [searchInput, setSearchInput] = React.useState(ShipFilterState.shipCmpFilter.serachBoxInputCompany)
  const [showModal, setModal] = React.useState(false)
  const [showShip, shipModal] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [searchQuery, setQuery] = React.useState('')
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  const [showDelete, setShow] = React.useState(false);
  const [deleteShipCmp, setDeleteShipCmp] = React.useState('') 
  const [sort, setSorting]=React.useState({values:ShipFilterState.shipCmpFilter.type,direction:ShipFilterState.shipCmpFilter.direction})

  const role = localStorage.getItem("role")
  // Used to get type and payload, call action and get list.    
  React.useEffect(() => {
    _getCompanies({ page })
  }, [page])

  // console.log(props, 'props in ships company')
  // Check ships(list) length, add checked false in that array.   
  React.useEffect(() => {

    if (companies && companies.length > 0) {
      setCompanies(() => {
        return companies.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (companies && companies.length === 0) {
      setCompanies([])
    }
    // console.log(displayCompanies, 'companies list in shipcompany')
  }, [companies])

  // Get totalPage in props and push into the pagination variable.    
  React.useEffect(() => {
    if(localStorage.getItem(searchInput)!=null||localStorage.getItem(searchInput)!='')
    {
      const user = localStorage.getItem(searchInput)!=null ? localStorage.getItem('user') : '';
      ShipFilterState.shipCmpFilter.serachBoxInputCompany=user!=null?user:'';
    }
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  const _getCompanies = (params: any) => {
    if(localStorage.getItem("searchInput")!=null||localStorage.getItem("searchInput")!='')
    {
      const user = localStorage.getItem("searchInput")!=null ? localStorage.getItem("searchInput") : '';
      ShipFilterState.shipCmpFilter.serachBoxInputCompany=user!=null?user:'';
      setSearchInput(user != null ? user : '')
      props.shipCompanyListGetAction({
        page, size: 10,
        status: ShipFilterState.shipCmpFilter.shipcmpstatus,
        country: ShipFilterState.shipCmpFilter.shipcmpcountry,
        // shipcmpcountryNames:ShipFilterState.shipCmpFilter.shipcmpcountryNames,
        city: ShipFilterState.shipCmpFilter.city,
        companyname: user,
        type:ShipFilterState.shipCmpFilter.type,
        direction:ShipFilterState.shipCmpFilter.direction
      })
    }else{
      props.shipCompanyListGetAction({
        page, size: 10,
        status: ShipFilterState.shipCmpFilter.shipcmpstatus,
        country: ShipFilterState.shipCmpFilter.shipcmpcountry,
        // shipcmpcountryNames:ShipFilterState.shipCmpFilter.shipcmpcountryNames,
        city: ShipFilterState.shipCmpFilter.city,
        companyname: ShipFilterState.shipCmpFilter.cmpName || searchInput ||ShipFilterState.shipCmpFilter.serachBoxInputCompany,
        type:ShipFilterState.shipCmpFilter.type,
        direction:ShipFilterState.shipCmpFilter.direction
      })
    }

    // console.log('success')
  }

  //Search Function

  const _shipcmpSearchFuntion = (value: string) => {
    setSearchInput(value)
    localStorage.setItem("searchInput",value)
    ShipFilterState.shipCmpFilter.serachBoxInputCompany=value
    props.shipCompanyListGetAction({
      page, size: 10,
      status: ShipFilterState.shipCmpFilter.shipcmpstatus,
      country: ShipFilterState.shipCmpFilter.shipcmpcountry,
      // shipcmpcountryNames:ShipFilterState.shipCmpFilter.shipcmpcountryNames,
      city: ShipFilterState.shipCmpFilter.city,
      companyname: value || ShipFilterState.shipCmpFilter.cmpName,
      type:ShipFilterState.shipCmpFilter.type,
      direction:ShipFilterState.shipCmpFilter.direction
    })
    setPage(0)
    // setLow(1)
    // setHigh(10)
  }

  //Show all

  const _showAllShipcmp = () => {
    props.shipCompanyListGetAction({ page, size: 10 })
    setSearchInput('')
    ShipFilterState.shipCmpFilter.shipcmpstatus = ''
    ShipFilterState.shipCmpFilter.shipcmpcountry = ''
    ShipFilterState.shipCmpFilter.shipcmpcountryNames = ''
    ShipFilterState.shipCmpFilter.city = ''
    ShipFilterState.shipCmpFilter.cmpName = ''
    ShipFilterState.shipCmpFilter.page = 0
    ShipFilterState.shipCmpFilter.size = 10
    ShipFilterState.shipCmpFilter.serachBoxInputCompany=''
    localStorage.removeItem("searchInput")
    setSorting({values:'',direction:''})
  }

  //will colose modal function

  const _willCloseModal = () => {
    setModal(false)
  }

  //will show filter modal
  const _willShipcmpFilter = () => {
    setModal(true)
  }

  //will show ship detail modal
  const _willviewShip = (ship: any) => {
    props.shipCompanySetSelectedAction(ship)
    shipModal(true)
  }

  //close ship Modal

  const _willCloseShip = () => {
    shipModal(false)
  }

  // Redirect to ship detail page.   
  const _willViewDetail = (ship: any) => {
    props.shipCompanySetSelectedAction(ship)
    history.push(`/ship-companies/${ship.company_id}`)
  }

  //Redirect to ship edit page.   
  const _willViewEdit = (ship: any) => {
    props.shipCompanySetSelectedAction(ship)
    history.push(`/ship-companies/edit/${ship.company_id}`)
  }

  // Delete the pericular list.   
  const handleClose = () => setShow(false);
  const handleShow = (shipcmp: any) => {
    setShow(true);
    setDeleteShipCmp(shipcmp.company_id)
    // console.log(deleteShipCmp, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteShipCmp, 'deleted anode id')
    _deleteShipCmp(deleteShipCmp)
  }

  const _deleteShipCmp = (id: any) => {
    return shipService.deleteShipCompany(id).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.shipCompanyListGetAction({ company_type: 'SHIP', page, size: 10 })
      },
      error: err => {
        setShow(false)
        swal("OOPS", String(err), "error")
      }
    })
  }

  // Prints first page pagination.   
  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  // While click the last page its move to last page.   
  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 9)
  }

  // While click the first button its move to first page.   
  const _goToFirstPage = () => {
    setPage(0)
    setHigh(9)
    setLow(1)
  }

  // Prints last page pagination button.   
  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  // Prints prev button.   
  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  // While click prev button go to the previous page of current page.   
  const _goPrevPage = () => {
    setPage(prev => {
      const newValue = prev - 1
      return newValue
    })
  }

  // Print next button.   
  const nextBtn = () => {
    if (page < totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  // While click next page button go to the next page of the current page.   
  const _goNextPage = () => {
    setPage(prev => {
      const newValue = prev + 1
      return newValue
    })
  }

  // While click one page number its move to that page.   
  const _goToPage = (page: any) => {
    setPage(page)
  }

  // Return the page number
  const ListItem = (page: any) => {
    return page.value + 1
  }

  // Prints the totalpages between prev, next button.   
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })

  const sortByFieldn=(value:String,directions:String)=>{
    if(directions==='asc')
    {
      setSorting({values:String(value),direction:String(directions)});
      ShipFilterState.shipCmpFilter.type=String(value);
      ShipFilterState.shipCmpFilter.direction=String(directions);
      props.shipCompanyListGetAction({
        page, size: 10,
        status: ShipFilterState.shipCmpFilter.shipcmpstatus,
        country: ShipFilterState.shipCmpFilter.shipcmpcountry,
        // shipcmpcountryNames:ShipFilterState.shipCmpFilter.shipcmpcountryNames,
        city: ShipFilterState.shipCmpFilter.city,
        companyname: ShipFilterState.shipCmpFilter.cmpName,
        type:value,
        direction:directions

      })
      setPage(0)
      
    }
    else if(directions==='desc')
    {
      setSorting({values:String(value),direction:String(directions)});
      ShipFilterState.shipCmpFilter.type=String(value);
      ShipFilterState.shipCmpFilter.direction=String(directions);
      props.shipCompanyListGetAction({
        page, size: 10,
        status: ShipFilterState.shipCmpFilter.shipcmpstatus,
        country: ShipFilterState.shipCmpFilter.shipcmpcountry,
        // shipcmpcountryNames:ShipFilterState.shipCmpFilter.shipcmpcountryNames,
        city: ShipFilterState.shipCmpFilter.city,
        companyname: ShipFilterState.shipCmpFilter.cmpName,
        type:value,
        direction:directions
      })
      setPage(0)

    }
    else if(directions==='')
    {
      setSorting({values:String(value),direction:String(directions)});
      ShipFilterState.shipCmpFilter.type=String(value);
      ShipFilterState.shipCmpFilter.direction=String(directions);
      props.shipCompanyListGetAction({
        page, size: 10,
        status: ShipFilterState.shipCmpFilter.shipcmpstatus,
        country: ShipFilterState.shipCmpFilter.shipcmpcountry,
        // shipcmpcountryNames:ShipFilterState.shipCmpFilter.shipcmpcountryNames,
        city: ShipFilterState.shipCmpFilter.city,
        companyname: ShipFilterState.shipCmpFilter.cmpName,
        type:value,
        direction:directions
      })
      setPage(0)
    }
    };


  return (
    <Main>
      <Text variant="title" color='black'>Ship Company List</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-3 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    <InputWithIcon
                      type="text"
                      value={searchInput}
                      onChange={(e) => _shipcmpSearchFuntion(e.target.value)}
                      placeholder="Search by Company Name.."
                      icon="search"
                      id="search-input"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <div className='search-btn-container'>
                <div className='search-btn-add'>
                  {role === 'VISITOR' ? (
                    //   <Link to='/ships-company/add'>   {/* Button add new ship, if you click the button it shows modal box.    */}
                    <Button variant='transparent' block={true} disabled>
                      <Icons color="#ffffff" icon="add" />
                      ADD NEW COMPANY
                    </Button>
                    //  </Link>
                  ) : (<Link to='/ships-company/add'>   {/* Button add new ship, if you click the button it shows modal box.    */}
                    <Button variant='transparent' block={true}>
                      <Icons color="#ffffff" icon="add" />
                      ADD NEW COMPANY
                    </Button>
                  </Link>)}
                </div>
                <div className='search-btn-filter'>
                  <Button className='link_button filter' onClick={() => _willShipcmpFilter()}>
                    <img src={Filterimg} alt='filterimg' />
                  </Button>
                </div>
                <div className='search-btn-showall'>
                  <Button variant='transparent' block={true} onClick={() => _showAllShipcmp()}>
                    Show All
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </SearchForm>
        <Space divheight={30} />
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>} {/* Check the list is loading, isloading is true its shows loading text.   */}
        {!isLoading && displayCompanies.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No ship Companies found</Text>
            <Space divheight={20} />
          </>
        )} {/* If isloading is false and also the list array length is 0 its shows no discount fount text.    */}
        {!isLoading && displayCompanies.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Created Date</th>
                  <th>Company Name
                  &nbsp;
                    {sort.values==='companyname' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('companyname','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='companyname' ? (
                     <img onClick={() => sortByFieldn('companyname','asc')} src={sortimage}/>):(null)}
                     {sort.values==='companyname' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Owner                    
                  &nbsp;
                    {sort.values==='username' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('username','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='username' ? (
                     <img onClick={() => sortByFieldn('username','asc')} src={sortimage}/>):(null)}
                     {sort.values==='username' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Country                    
                  &nbsp;
                    {sort.values==='countryname' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('countryname','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='countryname' ? (
                     <img onClick={() => sortByFieldn('countryname','asc')} src={sortimage}/>):(null)}
                     {sort.values==='countryname' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>City                    
                  &nbsp;
                    {sort.values==='cityname' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('cityname','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='cityname' ? (
                     <img onClick={() => sortByFieldn('cityname','asc')} src={sortimage}/>):(null)}
                     {sort.values==='cityname' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th className='text-lg-center'>Total Ships</th>
                  <th>Ship Detail</th>
                  <th>Status                    
                  &nbsp;
                    {sort.values==='status' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('status','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='status' ? (
                     <img onClick={() => sortByFieldn('status','asc')} src={sortimage}/>):(null)}
                     {sort.values==='status' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th colSpan={2} className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Map the list array and display the datas in a table.    */}
                {displayCompanies.map((data: any, i: any) => (
                  <tr key={data.company_id}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    {data.created_date === null && data.created_date === '' && (
                      <td data-label='Created Date'>-
                      </td>
                    )}
                    {data.created_date !== null && (
                      <td data-label='Join Date'>{format(parseISO(data.created_date), 'dd/MM/yyyy')}
                      </td>
                    )}
                    <td data-label='Company Name'>{data.company_name === null ? (
                      <Text variant='subtitle'> - </Text>
                    ) : (
                      <Text variant='subtitle'>{data.company_name}</Text>
                    )}
                    </td>
                    <td data-label='Owner Name'>{data.owner_name === null ? (
                      <Text variant='subtitle'>-</Text>
                    ) : (
                      <Text variant='subtitle'>{data.owner_name}</Text>
                    )}
                    </td>
                    <td data-label='Country'>{data.country === null ? (
                      <Text variant='subtitle'>- </Text>
                    ) : (
                      <Text variant='subtitle'>{data.country}</Text>
                    )}
                    </td>
                    <td data-label='City'>{data.city === null ? (
                      <Text variant='subtitle'> - </Text>
                    ) : (
                      <Text variant='subtitle'>{data.city}</Text>
                    )}
                    </td>

                    <td className='text-lg-center' data-label='Total Ships'>{data.total_ships}
                    </td>
                    {data.total_ships === 0 && (
                      <td className='text-lg-center' data-label='Ship Detail'>
                        <OverlayTrigger overlay={
                          <Tooltip id="dockCmp-view"> No Ships for this Company
                          </Tooltip>
                        }>
                          <Button className='link_button' disabled>
                            <Icons color="#52575a" icon="info" />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    )}
                    {data.total_ships > 0 && (
                      <td className='text-lg-center' data-label='Ship Detail'>
                        <OverlayTrigger overlay={
                          <Tooltip id="dockCmp-view"> Ship Dettail
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willviewShip(data)}>
                            <Icons color="#52575a" icon="info" />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    )}
                    {/* Check the status, if the status is verified that text color will be a success, else danger color.    */}
                    <td data-label='Status'>{data.status === 'VERIFIED' ? (
                      <Text color='warning' variant='subtitle'>Verified</Text>
                    ) : (
                      <Text color='danger' variant='subtitle'>Not Verified</Text>
                    )}
                    </td>
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="shipCmp-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(data)}> {/* While onclick open view page.    */}
                            <Icons color="#52575a" icon="visibility" /> {/* View icon.   */}
                          </Button>
                        </OverlayTrigger>
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="shipCmp-edit-visitor"> Edit option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button'
                              onClick={() => _willViewEdit(data)} disabled
                            > {/* While onclick open edit page.    */}
                              <Icons color="#52575a" icon="edit" /> {/* Edit icon.   */}
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="shipCmp-edit"> Edit
                            </Tooltip>
                          }>
                            <Button className='link_button'
                              onClick={() => _willViewEdit(data)}
                            > {/* While onclick open edit page.    */}
                              <Icons color="#52575a" icon="edit" /> {/* Edit icon.   */}
                            </Button>
                          </OverlayTrigger>
                        )}
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="shipCmp-delete-visitor"> Delete option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(data)} disabled>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="shipCmp-delete"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(data)}>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </TableActions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text> {/* Display the total elements.   */}
                {displayCompanies.length === 1 && (
                  <>
                    <b>
                      {displayCompanies.length}
                      {/* {((page) * 10) + 1} - {(displayCompanies.length + (page) * 10)} */}
                    </b> of {totalElement} Company
                  </>
                )}
                {displayCompanies.length > 1 && (
                  <>
                    <b>
                      {displayCompanies.length}
                      {/* {((page) * 10) + 1} - {(displayCompanies.length + (page) * 10)} */}
                    </b> of {totalElement} Companies
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()} {/* First page button.    */}
                {prevBtn()} {/* Previous button.    */}
                {PageNumber} {/* Totalpages.    */}
                {nextBtn()} {/* Next button.    */}
                {lastPage()} {/* Last page button.    */}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {/* If modal is true goto the Modal box page with the props of onclose function */}
      {showModal && <ShipCmpFilter onClose={() => _willCloseModal()} />}
      {showShip && <ShipView onClose={() => _willCloseShip()} />}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form ship Companies list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Main>
  )
}
export interface TablefooterProps {
  children?: React.ReactNode
}

// Table footer style.   
const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

// Table body style.   
const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

//Search Form Component  for displaying serarch box, and add ship button
const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

// Connect to the action and get the list, totalpage and total elements.   
const mapStateToProps = (state: any) => ({
  companies: state.ship.companies,
  isLoading: state.ship.isLoading,
  error: state.ship.error,
  totalPage: state.ship.totalPage,
  totalElement: state.ship.totalElement
})

export default connect(
  mapStateToProps,
  {
    shipCompanyListGetAction,
    shipCompanySetSelectedAction
  }
)(ShipcompaniesList)