import React from 'react';
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap';
import '../datalasticShipDemolation/datalastic.scss'
import '../../../common/components/pagination/Pagination.scss';
import { Text } from '../../../common/components/text/Text';
import { Space } from '../../../common/components/space/Space';
import { Main } from '../../../common/components/main/Main';
import { Button } from '../../../common/components/button/Button';
import Card from '../../../common/components/card/Card';
import { Grid } from '../../../common/components/grid/Grid';
import { Table } from '../../../common/components/table/Table';
import Icons from '../../../common/components/icon/Icon';
import { format, parseISO } from 'date-fns'
import {
  datashipDemolitionListGetAction,
  datashipDemolitionSetSelectedAction
} from "../../../store/action/dataShipDemoAction";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { history } from '../../../store';
import { Flex } from '../../../common/components/flex/Flex';
import { ReactNode } from 'react';
import datalasticShipCmpService from '../../../services/datalasticShipCmpService';
import swal from 'sweetalert';
import { InputWithIcon } from '../../../common/components/control/Control';
import ShipCmpFilter from '../pagecontainer/ShipCmpFilter';
import { ShipFilterState } from '../../ship/filter/ShipFilterState'
import ShipView from '../pagecontainer/ShipView'
import Loader from 'react-loader-spinner';
import Filterimg from '../../../assets/images/Filter 2.png';
import sortup from '../../../assets/images/sortup_icon.png';
import sortdown from '../../../assets/images/sortdown_icon.png';
import sortimage from '../../../assets/images/sorting.png';

const DatalasticShipDemoList = (props: any) => {
  const { dock, isLoading, totalElement } = props
  const [displayDock, setDock] = React.useState<any>([])
  const [searchInput, setSearchInput] = React.useState(ShipFilterState.shipCmpFilter.serachBoxInputCompany)
  const [showModal, setModal] = React.useState(false)
  const [showShip, shipModal] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [searchQuery, setQuery] = React.useState('')
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  const [showDelete, setShow] = React.useState(false);
  const [deleteShipDemo, setDeleteShipDemo] = React.useState('')
  const [sort, setSorting] = React.useState({ values: ShipFilterState.shipCmpFilter.type, direction: ShipFilterState.shipCmpFilter.direction })
  const role = localStorage.getItem("role")


  // Used to get type and payload, call action and get list.    
  React.useEffect(() => {
    _getCompanies({ page })
  }, [page])

  console.log(props, 'props in ships company')
  // Check ships(list) length, add checked false in that array.   
  React.useEffect(() => {

    if (dock && dock.length > 0) {
      setDock(() => {
        return dock.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (dock && dock.length === 0) {
      setDock([])
    }
    // console.log(displayCompanies, 'companies list in shipcompany')
  }, [dock])

  // Get totalPage in props and push into the pagination variable.    
  React.useEffect(() => {
    if (localStorage.getItem(searchInput) != null || localStorage.getItem(searchInput) != '') {
      const user = localStorage.getItem(searchInput) != null ? localStorage.getItem('user') : '';
      ShipFilterState.datalasticshipCmpFilter.serachBoxInputCompany = user != null ? user : '';
    }
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  const _getCompanies = (params: any) => {
    if (localStorage.getItem("searchInput") != null || localStorage.getItem("searchInput") != '') {
      const user = localStorage.getItem("searchInput") != null ? localStorage.getItem("searchInput") : '';
      ShipFilterState.datalasticshipCmpFilter.serachBoxInputCompany = user != null ? user : '';
      setSearchInput(user != null ? user : '')
      props.datashipDemolitionListGetAction({
        page, size: 10,
        status: ShipFilterState.datalasticshipCmpFilter.shipcmpstatus,
        country: ShipFilterState.datalasticshipCmpFilter.shipcmpcountry,
        // shipcmpcountryNames:ShipFilterState.datalasticshipCmpFilter.shipcmpcountryNames,
        city: ShipFilterState.datalasticshipCmpFilter.city,
        companyname: user,
        type: ShipFilterState.datalasticshipCmpFilter.type,
        direction: ShipFilterState.datalasticshipCmpFilter.direction
      })
    } else {
      props.datashipDemolitionListGetAction({
        page, size: 10,
        status: ShipFilterState.datalasticshipCmpFilter.shipcmpstatus,
        country: ShipFilterState.datalasticshipCmpFilter.shipcmpcountry,
        // shipcmpcountryNames:ShipFilterState.datalasticshipCmpFilter.shipcmpcountryNames,
        city: ShipFilterState.datalasticshipCmpFilter.city,
        companyname: ShipFilterState.datalasticshipCmpFilter.cmpName || searchInput || ShipFilterState.datalasticshipCmpFilter.serachBoxInputCompany,
        type: ShipFilterState.datalasticshipCmpFilter.type,
        direction: ShipFilterState.datalasticshipCmpFilter.direction
      })
    }

    // console.log('success')
  }

  const _willImport = () => {
    history.push('/import/shipDemolition')
  }

  //Search Function

  const _shipdemoSearchFuntion = (value: string) => {
    setSearchInput(value)
    localStorage.setItem("searchInput", value)
    ShipFilterState.datalasticshipCmpFilter.serachBoxInputCompany = value
    props.datashipDemolitionListGetAction({
      page, size: 10,
      status: ShipFilterState.datalasticshipCmpFilter.shipcmpstatus,
      country: ShipFilterState.datalasticshipCmpFilter.shipcmpcountry,
      // shipcmpcountryNames:ShipFilterState.datalasticshipCmpFilter.shipcmpcountryNames,
      city: ShipFilterState.datalasticshipCmpFilter.city,
      companyname: value || ShipFilterState.datalasticshipCmpFilter.cmpName,
      type: ShipFilterState.datalasticshipCmpFilter.type,
      direction: ShipFilterState.datalasticshipCmpFilter.direction
    })
    setPage(0)
    // setLow(1)
    // setHigh(10)
  }

  //Show all

  const _showAllShipDemo = () => {
    props.datashipDemolitionListGetAction({ page, size: 10 })
    setSearchInput('')
    ShipFilterState.datalasticshipCmpFilter.shipcmpstatus = ''
    ShipFilterState.datalasticshipCmpFilter.shipcmpcountry = ''
    ShipFilterState.datalasticshipCmpFilter.shipcmpcountryNames = ''
    ShipFilterState.datalasticshipCmpFilter.city = ''
    ShipFilterState.datalasticshipCmpFilter.cmpName = ''
    ShipFilterState.datalasticshipCmpFilter.page = 0
    ShipFilterState.datalasticshipCmpFilter.size = 10
    ShipFilterState.datalasticshipCmpFilter.serachBoxInputCompany = ''
    localStorage.removeItem("searchInput")
    setSorting({ values: '', direction: '' })
  }

  //will colose modal function

  const _willCloseModal = () => {
    setModal(false)
  }

  //will show filter modal
  const _willShipdemoFilter = () => {
    setModal(true)
  }

  //will show ship detail modal
  const _willviewShip = (ship: any) => {
    props.datashipDemolitionSetSelectedAction(ship)
    shipModal(true)
  }

  //close ship Modal

  const _willCloseShip = () => {
    shipModal(false)
  }

  // Redirect to ship detail page.   
  const _willViewDetail = (ship: any) => {
    props.datashipDemolitionSetSelectedAction(ship)
    history.push(`/ship-companies/${ship.company_id}`)
  }

  //Redirect to ship edit page.   
  const _willViewEdit = (ship: any) => {
    props.datashipDemolitionSetSelectedAction(ship)
    history.push(`/ship-companies/edit/${ship.company_id}`)
  }

  // Delete the pericular list.   
  const handleClose = () => setShow(false);
  const handleShow = (shipcmp: any) => {
    setShow(true);
    setDeleteShipDemo(shipcmp.company_id)
    // console.log(deleteShipCmp, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteShipCmp, 'deleted anode id')
    _deleteShipDemo(deleteShipDemo)
  }

  const _deleteShipDemo = (id: any) => {
    return datalasticShipCmpService.datalasticdeleteShipCompany(id).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.datashipDemolitionListGetAction({ company_type: 'SHIP', page, size: 10 })
      },
      error: err => {
        setShow(false)
        swal("OOPS", String(err), "error")
      }
    })
  }

  // Prints first page pagination.   
  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  // While click the last page its move to last page.   
  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 9)
  }

  // While click the first button its move to first page.   
  const _goToFirstPage = () => {
    setPage(0)
    setHigh(9)
    setLow(1)
  }

  // Prints last page pagination button.   
  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  // Prints prev button.   
  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  // While click prev button go to the previous page of current page.   
  const _goPrevPage = () => {
    setPage(prev => {
      const newValue = prev - 1
      return newValue
    })
  }

  // Print next button.   
  const nextBtn = () => {
    if (page < totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  // While click next page button go to the next page of the current page.   
  const _goNextPage = () => {
    setPage(prev => {
      const newValue = prev + 1
      return newValue
    })
  }

  // While click one page number its move to that page.   
  const _goToPage = (page: any) => {
    setPage(page)
  }

  // Return the page number
  const ListItem = (page: any) => {
    return page.value + 1
  }

  // Prints the totalpages between prev, next button.   
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })

  const sortByFieldn = (value: String, directions: String) => {
    if (directions === 'asc') {
      setSorting({ values: String(value), direction: String(directions) });
      ShipFilterState.datalasticshipCmpFilter.type = String(value);
      ShipFilterState.datalasticshipCmpFilter.direction = String(directions);
      props.datashipDemolitionListGetAction({
        page, size: 10,
        status: ShipFilterState.datalasticshipCmpFilter.shipcmpstatus,
        country: ShipFilterState.datalasticshipCmpFilter.shipcmpcountry,
        // shipcmpcountryNames:ShipFilterState.datalasticshipCmpFilter.shipcmpcountryNames,
        city: ShipFilterState.datalasticshipCmpFilter.city,
        companyname: ShipFilterState.datalasticshipCmpFilter.cmpName,
        type: value,
        direction: directions

      })
      setPage(0)

    }
    else if (directions === 'desc') {
      setSorting({ values: String(value), direction: String(directions) });
      ShipFilterState.datalasticshipCmpFilter.type = String(value);
      ShipFilterState.datalasticshipCmpFilter.direction = String(directions);
      props.datashipDemolitionListGetAction({
        page, size: 10,
        status: ShipFilterState.datalasticshipCmpFilter.shipcmpstatus,
        country: ShipFilterState.datalasticshipCmpFilter.shipcmpcountry,
        // shipcmpcountryNames:ShipFilterState.datalasticshipCmpFilter.shipcmpcountryNames,
        city: ShipFilterState.datalasticshipCmpFilter.city,
        companyname: ShipFilterState.datalasticshipCmpFilter.cmpName,
        type: value,
        direction: directions
      })
      setPage(0)

    }
    else if (directions === '') {
      setSorting({ values: String(value), direction: String(directions) });
      ShipFilterState.datalasticshipCmpFilter.type = String(value);
      ShipFilterState.datalasticshipCmpFilter.direction = String(directions);
      props.datashipDemolitionListGetAction({
        page, size: 10,
        status: ShipFilterState.datalasticshipCmpFilter.shipcmpstatus,
        country: ShipFilterState.datalasticshipCmpFilter.shipcmpcountry,
        // shipcmpcountryNames:ShipFilterState.datalasticshipCmpFilter.shipcmpcountryNames,
        city: ShipFilterState.datalasticshipCmpFilter.city,
        companyname: ShipFilterState.datalasticshipCmpFilter.cmpName,
        type: value,
        direction: directions
      })
      setPage(0)
    }
  };


  return (
    <Main>
      <Text variant="title" color='black'>Ship Demolition List</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Row className='m-0 p-0'>
            {/* <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-3 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    <InputWithIcon
                      type="text"
                      value={searchInput}
                      onChange={(e) => _shipdemoSearchFuntion(e.target.value)}
                      placeholder="Search by Company Name.."
                      icon="search"
                      id="search-input"
                    />
                  </Col>
                </Row>
              </div> 
            </Col>*/}
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <div className='search-btn-container'>
                <div className='search-btn-add'>
                  {role === 'VISITOR' ? (
                    //   <Link to='/ships-company/add'>   {/* Button add new ship, if you click the button it shows modal box.    */}
                    <Button variant='transparent' className='ml-1' onClick={() => _willImport()} disabled>
                      Import
                    </Button>
                    //  </Link>
                  ) : (
                    /* Button add new ship, if you click the button it shows modal box.    */
                    <Button variant='transparent' className='ml-1' onClick={() => _willImport()}>
                      Import
                    </Button>
                  )}
                </div>
                {/* <div className='search-btn-filter'>
                  <Button className='link_button filter' onClick={() => _willShipdemoFilter()}>
                    <img src={Filterimg} alt='filterimg' />
                  </Button>
                </div>
                <div className='search-btn-showall'>
                  <Button variant='transparent' block={true} onClick={() => _showAllShipDemo()}>
                    Show All
                  </Button>
                </div>*/}
              </div> 
            </Col>
          </Row>
        </SearchForm>
        <Space divheight={30} />
        {
          isLoading && <Text center>
            <Loader
              type="Rings"
              color="#233269"
              height={100}
              width={100}
              timeout={30000}
            />
          </Text>
        } {/* Check the list is loading, isloading is true its shows loading text.   */}
        {
          !isLoading && displayDock.length === 0 && (
            <>
              <Space divheight={20} />
              <Text center>No ship Demolition found</Text>
              <Space divheight={20} />
            </>
          )
        } {/* If isloading is false and also the list array length is 0 its shows no discount fount text.    */}
        {
          !isLoading && displayDock.length > 0 && (
            <>
              <Table>
                <thead>
                  <tr key={0}>
                    <th>S.No</th>
                    <th>Sale Date</th>
                    <th>
                      IMO Number &nbsp;
                    </th>
                    <th>
                      Ship Name &nbsp;
                    </th>
                    {/* <th>
                                                Flag Name
                                            </th> */}
                    <th>Built Year &nbsp;</th>
                    <th>
                      Ship Type &nbsp;
                    </th>
                    {/* <th>
                                                DWT Design &nbsp;
                                            </th>
                                            <th>
                                                gt &nbsp;
                                            </th>
                                            <th>ldt &nbsp;</th> */}
                    <th>Seller &nbsp;</th>
                    <th>Buyer &nbsp;</th>
                    {/* <th>SalesPriceUsd &nbsp;</th> */}
                    <th>Sales Price ($/Ldt) &nbsp;</th>
                    <th>Destination &nbsp;</th>
                    {/* <th>Sale Type &nbsp;</th> */}
                    {/* <th>DryDockDate &nbsp;</th> */}
                    {/* <th>Special Survey Date &nbsp;</th> */}
                    <th>Sales Note &nbsp;</th>
                    {/* <th>Previous Sales Record &nbsp;</th> */}
                    {/* <th>Date Modified &nbsp;</th> */}
                    {/* <th>Source Data &nbsp;</th> */}
                    {/* <th colSpan={2} className="text-center">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {/* Map the list array and display the datas in a table.    */}
                  {displayDock.map((data: any, i: any) => (
                    <tr key={data.id}>
                      <td data-label='S.No'>
                        {page === 0 && (
                          i + 1
                        )}
                        {page >= 1 && (
                          (page * 10) + (i + 1)
                        )}
                      </td>
                      <td data-label='Sale Date'>
                        {data.SalesReportDate === null ? (
                          <Text className='txt-table-date' color='tabledata'>-</Text>
                        ) : (
                          <Text className='txt-table-date' color='tabledata'>{format(data.SalesReportDate, 'dd/MM/yyyy')}</Text>
                        )}
                      </td>
                      <td data-label='IMO Number'>{data.IMONumber}</td>
                      <td data-label='Ship Name'>{data.VesselName}</td>
                      {/* <td data-label='Flag Name'>{data.FlagName}</td> */}
                      <td data-label='Built Year'>{data.BuiltYear}</td>
                      <td data-label='Ship Type'>{data.VesselTypeCode}</td>
                      {/* <td data-label='DWT Design'>{data.DWTDesign}</td>
                                                <td data-label='GT'>{data.GT}</td>
                                                <td data-label='LDT'>{data.LDT}</td> */}
                      <td data-label='Seller'>{data.Seller}</td>
                      <td data-label='Buyer'>{data.Buyer}</td>
                      {/* <td data-label='SalesPrice(USD million)'>{data.SalesPriceUsd}</td> */}
                      <td data-label='Sales Price($/Ldt)'>{data.salesPriceLdt}</td>
                      <td data-label='Destination'>{data.Destination}</td>
                      {/* <td data-label='Sale Type'>{data.SaleType}</td> 
                                                 <td data-label='DryDockDate'>{data.DryDockDate}</td> 
                                                 <td data-label='Special Survey Date'>{data.SpecialSurveyDate}</td> */}
                      <td data-label='Sales Note'>{data.SalesNote}</td>
                      {/* <td data-label='Previous Sales Record'>{data.PreviousSalesRecord}</td> 
                                                 <td data-label='Date Modified'>{data.DateModified}</td> 
                                                 <td data-label='Source Data'>{data.SourceData}</td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <TableFooter>
                <Text> {/* Display the total elements.   */}
                  {displayDock.length === 1 && (
                    <>
                      <b>
                        {displayDock.length}
                        {/* {((page) * 10) + 1} - {(displayCompanies.length + (page) * 10)} */}
                      </b> of {totalElement} Demolition
                    </>
                  )}
                  {displayDock.length > 1 && (
                    <>
                      <b>
                        {displayDock.length}
                        {/* {((page) * 10) + 1} - {(displayCompanies.length + (page) * 10)} */}
                      </b> of {totalElement} Demolition
                    </>
                  )}
                </Text>
                <Pagination size="sm">
                  {firstPage()} {/* First page button.    */}
                  {prevBtn()} {/* Previous button.    */}
                  {PageNumber} {/* Totalpages.    */}
                  {nextBtn()} {/* Next button.    */}
                  {lastPage()} {/* Last page button.    */}
                </Pagination>
              </TableFooter>
            </>
          )
        }
      </Card >
      {/* If modal is true goto the Modal box page with the props of onclose function */}
      {showModal && <ShipCmpFilter onClose={() => _willCloseModal()} />}
      {showShip && <ShipView onClose={() => _willCloseShip()} />}


      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form ship Companies list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Main >
  )
}
export interface TablefooterProps {
  children?: React.ReactNode
}

// Table footer style.   
const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

// Table body style.   
const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

//Search Form Component  for displaying serarch box, and add ship button
const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

// Connect to the action and get the list, totalpage and total elements.   
const mapStateToProps = (state: any) => ({
  dock: state.demoShip.dataLasticDemolition,
  isLoading: state.demoShip.isLoading,
  error: state.demoShip.error,
  totalPage: state.demoShip.totalPage,
  totalElement: state.demoShip.totalElement
})

export default connect(
  mapStateToProps,
  {
    datashipDemolitionListGetAction,
    datashipDemolitionSetSelectedAction
  }
)(DatalasticShipDemoList)