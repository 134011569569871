import{SHIP_TYPE_LIST_GET,
        SHIP_TYPE_LIST_GET_F,
        SHIP_TYPE_LIST_GET_R,
        SHIP_TYPE_SELECTED_SET
} from '../actionType'

export const shipTypeListGetAction = (params : any) => ({
    type: SHIP_TYPE_LIST_GET,
    payload: params
  })
  
  export const shipTypeListGetFAction = (params : any) => ({
    type: SHIP_TYPE_LIST_GET_F,
    payload: params
  })
  
  export const shipTypeListGetRAction = (params : any) => ({
    type: SHIP_TYPE_LIST_GET_R,
    payload: params
  })
  
  export const shipTypeSetSelectedAction = (type : any) => ({
    type: SHIP_TYPE_SELECTED_SET,
    payload: type
  })