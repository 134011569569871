import React, { useRef,useState } from 'react';
import { Main } from '../../../common/components/main/Main'
import { Col, Form, Row, Image } from 'react-bootstrap'
import { Button } from '../../../common/components/button/Button'
import { Text } from '../../../common/components/text/Text'
import noImg from '../../../assets/images/NoImg.jpg';
import Card from '../../../common/components/card/Card'
import editicon from '../../../assets/images/editone.png'
import removeicon from '../../../assets/images/Remove.png'
import swal from 'sweetalert'
// import { Grid } from '../../../common/components/grid/Grid'
import { Flex } from '../../../common/components/flex/Flex'
// import { Select } from '../../../common/components/control/Control'
import { Space } from '../../../common/components/space/Space'
import { Attributes } from '../../../common/components/attribute/Attribute'
import userService from '../../../services/userService'
// import codeService from '../../../services/codeService'
import classNames from 'classnames'
import Icons from '../../../common/components/icon/Icon'
import { JsxChild } from 'typescript'
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns'
import { systemUserListGetAction, systemUserSetSelectedAction } from '../../../store/action/userActions';
import { history } from '../../../store';
// import swal from 'sweetalert'
import './userList.scss'
import Loader from 'react-loader-spinner';
import { Label } from '../../../common/components/label/Label';
import { Link } from 'react-router-dom';

const SystemUserView = (props: any) => {
    const { match } = props
    const { id } = match.params
    const [isLoading, setLoading] = React.useState(false)
    const role = localStorage.getItem("role")
    const [userDetail, setUserDetail] = React.useState<any>({
        userName: '',
        userEmail: '',
        userPhone: '',
        userPhoneCode: '',
        userAddress: '',
        userRole: '',
        userPosition: '',
        userExperience: '',
        userExpertise: '',
        userEducation: '',
        userStatus: '',
        userActiveStatus: false,
        userCompany: '',
        userCreated: '',
        userCmpAddress: '',
        userCmpPhone: '',
        userPhoto: '',
        userCmpPhnCode: '',
    })
    interface bvalueProps {
        string_image: string | ArrayBuffer | null
    }
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<bvalueProps>({
        string_image: ''
    })
    const upload = useRef(null);
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageAPIDryDock(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const ImageAPIDryDock = (imageurl: any) => {
        const { id } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        // setForm({ ...edituserForm, isLoading: true })
        userService.UpdateUserImage(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                //window.location.reload();
                history.push('/system-users')
            },
            error: err => {
                // swal("OOPS", String(err), "error")
                // setForm({ ...edituserForm, isLoading: true })
                console.error(err)
            }
        })
    }
    const _removeUserImg = (id: any) => {
        return userService.removeUserImg(id).subscribe({
            next: (r) => {
                // console.log(r, 'response')
         //       setShow(false);
                // alert(true)
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
                // history.push(`/ships/${id}/`)
            },
            error: err => {
                // alert(false)
                swal("OOPS", String(err), "error")
            }
        })
    }
    const { user } = props
    const _getUserInfoForView = (id: any) => {
        setLoading(true)
        const { user } = props
        return userService.getUserDetail(id).subscribe({
            next: r => {
                // console.log(r.data, 'data in map company')
                var userRoles = ''
                var createdDate = ''
                if (r.data.role.length === 0 || r.data.role === null) {
                    userRoles = ''
                }
                else {

                    userRoles = r.data.role[0].name
                }
                if (r.data.created_at === null || '') {
                    createdDate = ''
                }
                else {
                    createdDate = format(parseISO(r.data.created_at), 'dd/MM/yyyy')
                }
                setUserDetail({
                    userName: r.data.name,
                    userEmail: r.data.email,
                    userPhone: r.data.phone_number,
                    userPhoneCode: r.data.phone_code,
                    userAddress: r.data.address,
                    userRole: userRoles,
                    userPosition: r.data.position,
                    userExperience: r.data.experience,
                    userExpertise: r.data.expertise,
                    userEducation: r.data.education,
                    userStatus: r.data.status,
                    userActiveStatus: r.data.enabled,
                    userCompany: r.data.company_name,
                    userCreated: createdDate,
                    userCmpAddress: r.data.company_address,
                    userCmpPhone: r.data.company_number,
                    userCmpPhnCode: r.data.company_code,
                    userPhoto: r.data.photo,
                })
                setLoading(false)
            },
            error: err => {
                console.error(err)
            }
        })
    }
    React.useEffect(() => {
        const { id } = match.params
        _getUserInfoForView(id)
    }, [])
    const _goback = () => {
        history.push('/system-users')
    }
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    return (
        <Main>
            {/* {console.log(userDetail, 'userDetail')} */}
            {userDetail === null && (
                <>
                    <Space divheight={15} />
                    <Text color='main' variant='subtitle' center bold> There is No data for requested id</Text>
                    <Space divheight={15} />
                </>
            )}
            {userDetail !== null && (
                <>
                    <div className='title-name-block'>
                        {/* <div>
                            <Image src={whiteLogo} alt='logo'  />
                        </div> */}
                        <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                            {userDetail.userPhoto === null && (
                                <Image src={noImg} alt='logo' roundedCircle />
                            )}
                            {userDetail.userPhoto === '' && (
                                <Image src={noImg} alt='logo' roundedCircle />
                            )}
                            {userDetail.userPhoto !== null && userDetail.userPhoto !== '' && (
                                <Image src={userDetail.userPhoto} alt='logo' roundedCircle />
                            )}
                        </div>
                        <Text variant='title' color='main' className='txt-capitalize'>
                            {userDetail.userName}
                        </Text>
                        <Label className={userDetail.userActiveStatus === false ? 'danger' : 'warning'} >
                            {userDetail.userActiveStatus === false ? 'In Active' : 'Active'}
                        </Label>
                    </div>
                    <Space divheight={10} />
                    <Row className='p-0 m-0'>
                        <Col xs='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                            <Card title="User Information">
                                <Row className='m-0 p-0'>
                                    <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='view_Page'>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Name</Text>
                                                {userDetail.userName === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userName}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Mobile</Text>
                                                {userDetail.userPhone === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <>
                                                        {userDetail.userPhoneCode === null || '' ? (
                                                            <Text color='main' bold variant='subtitle'>{userDetail.userPhoneCode}
                                                                <span>
                                                                    <Text color='main' bold variant='subtitle'>{userDetail.userPhone}</Text>
                                                                </span>
                                                            </Text>
                                                        ) : (
                                                            <>
                                                                <Text color='main' bold variant='subtitle'>{userDetail.userPhoneCode} &nbsp;
                                                                    <span>
                                                                        <Text color='main' bold variant='subtitle'>{userDetail.userPhone}</Text>
                                                                    </span>
                                                                </Text>
                                                            </>
                                                        )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Role</Text>
                                                {userDetail.userRole === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>
                                                        {String(userDetail.userRole).replace("_", "  ")}
                                                        {/* {userDetail.userRole} */}
                                                    </Text>
                                                )}
                                            </div>
                                            {/* <div>
                                                <Text color='tablehead' variant='subtitle'>Position</Text>
                                                {userDetail.userPosition === '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>
                                                        {userDetail.userPosition}
                                                    </Text>
                                                )}
                                            </div> */}
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Status</Text>
                                                {userDetail.userStatus === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userStatus}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Email</Text>
                                                {userDetail.userEmail === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle' className='txt-email'>{userDetail.userEmail}</Text>
                                                )}
                                            </div>
                                        </Attributes>
                                    </Col>
                                    <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='view_Page'>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>User Address</Text>
                                                {userDetail.userAddress === '' || null ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userAddress}</Text>
                                                )}
                                            </div>
                                            {/* <div>
                                                <Text color='tablehead' variant='subtitle'>Education</Text>
                                                {userDetail.userEducation === '' || null ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userEducation}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Expertise</Text>
                                                {userDetail.userExpertise === '' || null ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userExpertise}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Experience</Text>
                                                {userDetail.userExperience === '' || null ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userExperience}</Text>
                                                )}
                                            </div> */}
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Created date</Text>
                                                {userDetail.userCreated === '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userCreated}
                                                    </Text>
                                                )}
                                            </div>
                                        </Attributes>
                                    </Col>
                                </Row>
                            </Card>
                            {/* <Space divheight={15} />
                            <Card title="Company Information">
                                <Row className='m-0 p-0'>
                                    <Col xs='12' md='12' lg='10' xl='10' className='p-0 m-0'>
                                        <Attributes className='singleFrame'>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Provided Company</Text>
                                                {userDetail.userCompany === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userCompany}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Company Contact Number</Text>
                                                {userDetail.userCmpPhone === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <>
                                                        {userDetail.userCmpPhnCode === null || '' ? (
                                                            <Text color='main' bold variant='subtitle'>{userDetail.userCmpPhnCode}
                                                                <span>
                                                                    <Text color='main' bold variant='subtitle'>{userDetail.userCmpPhone}</Text>
                                                                </span>
                                                            </Text>
                                                        ) : (
                                                            <>
                                                                <Text color='main' bold variant='subtitle'>{userDetail.userCmpPhnCode} &nbsp;
                                                                    <span>
                                                                        <Text color='main' bold variant='subtitle'>{userDetail.userCmpPhone}</Text>
                                                                    </span>
                                                                </Text>
                                                            </>
                                                        )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Company Address</Text>
                                                {userDetail.userCmpAddress === '' || null ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userCmpAddress}</Text>
                                                )}
                                            </div>

                                        </Attributes>

                                    </Col>
                                </Row>
                            </Card> */}
                        </Col>
                        <Col xs='12' md='12' lg='4' xl='4' className='p-0 m-0'>
                            <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                                <Card title="Profile Picture">
                                    <div className='user-mainBlock'>
                                        {userDetail.userPhoto === null && (
                                            <div className='userProfile-block'>
                                                <Image roundedCircle src={noImg} alt='logo' />
                                            </div>
                                        )}
                                        {userDetail.userPhoto === '' && (
                                            <div className='userProfile-block'>
                                                <Image roundedCircle src={noImg} alt='logo' />
                                            </div>
                                        )}
                                        {userDetail.userPhoto !== null && userDetail.userPhoto !== '' && (
                                            <div className='userProfile-block'>
                                                <Image roundedCircle src={userDetail.userPhoto} alt='logo' />
                                            </div>
                                        )}
                                    </div>
                                    {((userDetail.userPhoto === null)||(userDetail.userPhoto === '')) &&  role === 'VISITOR' && (
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    <Image roundedCircle src={editicon} alt='logo' />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    onChange={getBase64}
                                                />
                                            </label>
                                        </div>
                                    )}
                                    {userDetail.userPhoto !== null && userDetail.userPhoto !== '' && role === 'VISITOR' &&(
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    <Image roundedCircle src={editicon} alt='logo' className="editIconRemove" />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    onChange={getBase64}
                                                />
                                            </label>

                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    {/* <Icons color="#ffffff" icon="edit" /> */}
                                                    <Image src={removeicon} alt='logo' onClick={() => _removeUserImg(userDetail.id)} />
                                                </Flex>
                                            </label>
                                        </div>
                                    )}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row className='p-0 m-0'>
                        <Col xs='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                            <Actions>
                                <Button type="button" variant='transparent' onClick={() => _goback()}>
                                    <Flex jc='jc-around'>
                                        <Icons icon="arrow_back" />
                                        BACK
                                    </Flex>
                                </Button>
                                {role === 'VISITOR' ? (
                                    //    <Link to={`/users/edit/${id}`} >
                                    <Button variant='transparent' disabled>
                                        <Flex>
                                            <Icons color="#ffffff" icon="edit" />
                                            EDIT DATA
                                        </Flex>
                                    </Button>
                                    //</Link>
                                ) : (
                                    // <Link to={`/system-users/edit/${id}`} >
                                        <Button variant='transparent' disabled> {/*disabled need to remove */}
                                            <Flex>
                                                <Icons color="#ffffff" icon="edit" />
                                                EDIT DATA
                                            </Flex>
                                        </Button>
                                    // </Link>
                                )}
                            </Actions>
                        </Col>
                    </Row>

                </>
            )}
        </Main>
    )

}

export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.systemUser.selectedSystemUser
})

export default connect(
    mapStateToProps,
    {
        systemUserSetSelectedAction,
        systemUserListGetAction
    }
)(SystemUserView)