import React, { useRef, useState } from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Form, Row, Col, Button } from 'react-bootstrap'
// import {Button} from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import '../../../../common/components/control/Control.scss'
import '../../../../common/components/button/Button.scss'
// import {Input, Select} from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import codeService from '../../../../services/codeService'
import { dockService } from '../../../../services/dockService'
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import Select from 'react-select';
import { JsxChild } from 'typescript'
import { LENGTH_UNIT_DEFAULT, DRAFT_UNIT_DEFAULT, BEAM_UNIT_DEFAULT } from '../../../../constants/units'
import { history } from '../../../../store';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner';

interface FormData {
    txt_dockName: string;
    txt_dockcity: string;
    txt_dockType: string;
    txt_dockport: string;
    txt_dockCompany: string;
    txt_dockLength: string;
    txt_dockWidth: string;
    txt_dockDraft: string;
    txt_dockDwt: string;
    txt_crane: string;
    txt_craneCapacity: string;
    txt_docklat: string;
    txt_docklon: string;
    txt_dockstatus: string;
    txt_dockunit: string;
    txt_shipTypeInDock: string;
    txt_tolerance: string;
    txt_addShipMaxWidth: string;
};
interface DryDockAddProps {
    name: string;
    docktypes: any[];
    cities: any[];
    ports: any[];
    companies: any[];
    specifications: any[];
    type: string;
    company: string;
    port: string;
    services: any;
    city: string;
    length: string;
    width: string;
    draft: string;
    dwt_capacity: string;
    cranes: string;
    crane_capacity: string;
    latitude: string;
    longitude: string;
    status: string;
    unit: string;
    shipType: string;
    tolerance: string;
    tolerancearray: any[];
    shipTypes: any[];
    companyID: string;
    ship_length: string | number,
    ship_width: string | number,
    ship_draft: string | number,
}
interface bvalueProps {
    string_image: string | ArrayBuffer | null
}
export const DryDockAdd = (props: DryDockAddProps) => {

    const { register, handleSubmit, errors } = useForm<FormData>({
            mode: 'onSubmit',
            reValidateMode: 'onBlur',
          });
    const upload = useRef(null);
    const maxOptions = 5;
    // const [selected, setSelected] = useState([]);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<bvalueProps>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')

        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const [docks, setDocks] = useState<DryDockAddProps>({
        name: '',
        docktypes: [],
        cities: [],
        ports: [],
        companies: [],
        specifications: [],
        type: '',
        dwt_capacity: '',
        length: '',
        width: '',
        draft: '',
        cranes: '',
        crane_capacity: '',
        company: '',
        city: '',
        port: '',
        services: [],
        status: 'UNVERIFIED',
        latitude: '',
        longitude: '',
        unit: '',
        shipType: '',
        shipTypes: [],
        tolerance: '',
        tolerancearray: [],
        companyID: '',
        ship_length: '',
        ship_width: '',
        ship_draft: '',
    })
    const [dockaddflag, setDockAddFlag] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const _getDockTypes = (() => {
        return codeService.getDockTypes().subscribe({
            next: r => {

                // console.log(r.data,"ship type")
                setDocks({ ...docks, docktypes: r.data })
                // console.log(docks.docktypes ,'ship type data in ship add page')
                r.data.map((data: any, i: any) => {
                    return docks.docktypes.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    // Used to get ship companies list and store to companies variable.
    const _getCompany = (() => {
        return codeService.getCompanies({ company_type: 'DOCK' }).subscribe({
            next: r => {
                // console.log(r.data,'companies in ship add page')
                setDocks({ ...docks, companies: r.data })
                r.data.map((data: any, i: any) => {
                    return docks.companies.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getPorts = ((cityId: any) => {
        var city_Code: any = ''
        {
            docks.cities.map((data, i) => {
                if (cityId === data.name) {
                    city_Code = data.id
                    // console.log(city_Code,'city code')
                    codeService.getPorts({ cityCode: city_Code }).subscribe({
                        next: r => {
                            // console.log(r.data)
                            setDocks({ ...docks, ports: r.data, city: cityId })
                            r.data.map((data: any, i: any) => {
                                return docks.ports.push(data)
                            })
                        },
                        error: err => {
                            console.error(err)
                        }
                    })
                }
                else {
                    setDocks({ ...docks, city: cityId })
                }
            })
        }

    })

    const _getCities = (() => {
        return codeService.getCities().subscribe({
            next: r => {

                setDocks({ ...docks, cities: r.data })
                r.data.map((data: any, i: any) => {
                    return docks.cities.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getShipType = (() => {
        return codeService.getShipTypes().subscribe({
            next: r => {

                // console.log(r.data,"ship type")
                setDocks({ ...docks, shipTypes: r.data })
                // console.log(ship.shipType ,'ship type data in ship add page')
                r.data.map((data: any, i: any) => {
                    return docks.shipTypes.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getTolerance = (() => {
        return codeService.getTolerance().subscribe({
            next: r => {

                // console.log(r.data,"ship type")
                setDocks({ ...docks, tolerancearray: r.data })
                // console.log(ship.shipType ,'ship type data in ship add page')
                r.data.map((data: any, i: any) => {
                    return docks.tolerancearray.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    React.useEffect(() => {
        _getCities()
        _getDockTypes()
        // _getSpecifications()
        _getCompany()
        _getShipType()
        _getTolerance()
    }, [])
    // const _setCmpId = (cmpIdVal:string) => {
    //     docks.companies.map((data, i) => {
    //         if(cmpIdVal === data.name){
    //             setDocks({ ...docks, company: data.id })
    //         }
    //         else{
    //             setDocks({ ...docks, company: cmpIdVal})
    //         }
    //     })
    // }
    const _didDockAdd = () => {
        var port_id: string = ''
        var cmp_id
        docks.companies.map((data, i) => {
            if (docks.company === data.name) {
                cmp_id = String(data.id)
                // console.log(data.id, 'company id')
                setDocks({ ...docks, companyID: String(data.id) })
                // console.log(docks.companyID, 'dock company id')
            }
        })
        docks.ports.map((data: any, i: any) => {
            if (docks.port === data.name) {
                port_id = data.id
            }
        })
        var adddockLen: any;
        var adddockWidth: any;
        var adddockDraft: any;
        var addshipMaxLen: any;
        var addshipMaxWidth: any;
        var addshipMaxDraft: any;
        if (docks.length === '') {
            adddockLen = null
        }
        else {
            adddockLen = Number(docks.length)
        }
        if (docks.width === '') {
            adddockWidth = null
        }
        else {
            adddockWidth = Number(docks.width)
        }
        if (docks.draft === '') {
            adddockDraft = null
        }
        else {
            adddockDraft = Number(docks.draft)
        }
        if (docks.ship_length === '') {
            addshipMaxLen = null
        }
        else {
            addshipMaxLen = Number(docks.ship_length)
        }
        if (docks.ship_width === '') {
            addshipMaxWidth = null
        }
        else {
            addshipMaxWidth = Number(docks.ship_width)
        }
        if (docks.ship_draft === '') {
            addshipMaxDraft = null
        }
        else {
            addshipMaxDraft = Number(docks.ship_draft)
        }
        // console.log(bvalue.string_image, 'base 64')
        var shipTypeArray = selectedValue
        // console.log(shipTypeArray)
        const updatedForm = {
            name: docks.name,
            company: docks.companyID,
            type: docks.type,
            status: docks.status,
            port: String(port_id),
            //city: docks.city,
            cranes: Number(docks.cranes),
            craneCapacity: Number(docks.crane_capacity),
            // services: docks.specifications
            //                     .filter(sp => sp.active === true)
            //                     .map(sp => ({ id: sp.id, name: sp.name })),
            length: adddockLen,
            draft: adddockDraft,
            width: adddockWidth,
            dwtCapacity: Number(docks.dwt_capacity),
            lengthUnit: LENGTH_UNIT_DEFAULT.code,
            draftUnit: DRAFT_UNIT_DEFAULT.code,
            widthUnit: BEAM_UNIT_DEFAULT.code,
            latitude: Number(docks.latitude),
            longitude: Number(docks.longitude),
            unit: docks.unit,
            tolerance: docks.tolerance,
            shipType: shipTypeArray,
            image: bvalue.string_image,
            max_ship_length: addshipMaxLen,
            max_ship_length_unit_id: LENGTH_UNIT_DEFAULT.code,
            max_ship_width: addshipMaxWidth,
            max_ship_width_unit_id: BEAM_UNIT_DEFAULT.code,
            max_ship_draft: addshipMaxDraft,
            max_ship_draft_unit_id: DRAFT_UNIT_DEFAULT.code,
        }
        // console.log('updated Form',updatedForm)
        // console.log(Object.keys(errors).length, 'error length')

        if (dockaddflag === true) {
            setLoading(true)
            // console.log(updatedForm, 'updated form')
            dockService.addDock(updatedForm).subscribe({
                next: () => {
                    swal("Great", "DryDock Added Successfully", "success")
                    setLoading(false)
                    history.push('/docks/list')
                },
                error: err => {
                    setLoading(false)
                    swal("OOPS", String(err), "error")
                    console.error(err)
                }
            })
        }
        else {
            console.log("Enter value correctly!")
        }
    }

    onsubmit = (e: any) => {
        // e.preventDefault()
        if (Object.keys(errors).length === 0) {
            _didDockAdd()
            setDockAddFlag(true)
        }
        else {
            setDockAddFlag(false)
        }
    }
    const [selectedValue, setSelectedValue] = useState<any>([]);

    // handle onChange event of the dropdown
    const handleChange = (e: any) => {
        // console.log(e.option.value,'option value')
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
        // console.log(e,'onclick called')
        // e.map((val:any) => {
        //     if(val.value === 'All'){
        //         console.log('all')
        //         setSelectedValue(e.map((x: { value: any; }) => x.value))
        //     }
        //     else{
        //     }
        // })
    }

    const data = docks.shipTypes.map((data: any, i: any) => {
        // console.log(data,'data within options')
        return { label: String(data.name), value: String(data.id) }
    });

    const All = [
        { label: "All", value: 'All' },
        // docks.shipTypes.map((data: any, i: any) => {
        //     // console.log(data,'data within options')
        //     return { label: String(data.name), value: String(data.id) }
        // })
    ];
    // onclick:{handleChange}
    // const [selectedCity, setSelectedCity] = useState<any>([]);
    // const handleCityChange = (e:any) => {
    //     setSelectedCity(e.value)
    // }
    // const citydata = docks.cities.map((data: any, i: any) => {
    //     return { label: String(data.name), value: String(data.id) }
    // });

    if (loading === true) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    return (
        <Main>
            <Text variant="title" color='black'>Add DryDock Information</Text>
            <Space divheight={25} />
            <Grid templateCols='75% auto' className='add_page'>
                <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='dockAddForms'>
                    <Card title="Fill All the Information Below" >
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='dock-add-edit'>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Name</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_dockName"
                                            value={docks.name}
                                            onChange={(e) => setDocks({ ...docks, name: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder='Name'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_dockName?.type === 'required' && 'Please enter Dock name'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >DryDock Type</Text>
                                        <Form.Control className='basecontrol cutome-selectInputnxt' as='select' value={docks.type}
                                            name="txt_dockType"
                                            ref={register({ required: true, })} onChange={(e) => setDocks({ ...docks, type: e.target.value })}>
                                            <option className='option-placeholder' value=''>---select Dock ---</option>
                                            {docks.docktypes.map((data, i) => {
                                                return <option key={i} value={data.id}>{data.name}</option>
                                            })}
                                        </Form.Control>
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_dockType?.type === 'required' && 'Please Select Dock Type'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <div></div>
                                        <Row className='m-0 p-0 mt-2'>
                                            <Col className='m-0 p-0 pr-2' xs='5'>
                                                <Text color='tablehead' variant='subtitle' >Dock Size</Text>
                                            </Col>
                                            <Col className='m-0 p-0' xs='7'>
                                                <Text color='tablehead' variant='subtitle' >Max Ship Dimensions</Text>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Length ({LENGTH_UNIT_DEFAULT.name})</Text>
                                        <Row className='m-0 p-0'>
                                            <Col className='m-0 p-0 pr-2' xs='5'>
                                                <Form.Control
                                                    type="number" className='basecontrol custome-input'
                                                    name="txt_dockLength"
                                                    value={docks.length}
                                                    onChange={(e) => setDocks({ ...docks, length: e.target.value })}
                                                    ref={register({ required: true, })}
                                                    placeholder='Dock Length'
                                                />
                                            </Col>
                                            <Col className='m-0 p-0' xs='7'>
                                                <Form.Control
                                                    type="number" className='basecontrol custome-input'
                                                    name="txt_addShipMaxLength"
                                                    value={docks.ship_length}
                                                    onChange={(e) => setDocks({ ...docks, ship_length: e.target.value })}
                                                    // ref={register({ required: true, })}
                                                    placeholder='Max Ship Dimensions'
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_dockLength?.type === 'required' && 'Please enter Dock length'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Width ({BEAM_UNIT_DEFAULT.name})</Text>
                                        <Row className='m-0 p-0'>
                                            <Col className='m-0 p-0 pr-2' xs='5'>
                                                <Form.Control
                                                    type="number" className='basecontrol custome-input'
                                                    name="txt_dockWidth"
                                                    value={docks.width}
                                                    onChange={(e) => setDocks({ ...docks, width: e.target.value })}
                                                    ref={register({ required: true, })}
                                                    placeholder='Dock Width'
                                                />
                                            </Col>
                                            <Col className='m-0 p-0' xs='7'>
                                                <Form.Control
                                                    type="number" className='basecontrol custome-input'
                                                    name="txt_addShipMaxWidth"
                                                    value={docks.ship_width}
                                                    onChange={(e) => setDocks({ ...docks, ship_width: e.target.value })}
                                                    // ref={register({ max: docks.width, })}
                                                    placeholder='Max Ship Dimension'
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>
                                                {/* {errors.txt_addShipMaxWidth?.type === 'max' && 'Max Ship Dimension shoul be less than Dock Size'} */}
                                                {errors.txt_dockWidth?.type === 'required' && 'Please enter Dock Width'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Draft ({DRAFT_UNIT_DEFAULT.name})</Text>
                                        <Row className='m-0 p-0'>
                                            <Col className='m-0 p-0 pr-2' xs='5'>
                                                <Form.Control
                                                    type="number" className='basecontrol custome-input'
                                                    name="txt_dockDraft"
                                                    value={docks.draft}
                                                    onChange={(e) => setDocks({ ...docks, draft: e.target.value })}
                                                    ref={register({ required: true, })}
                                                    placeholder='Dock Draft'
                                                />
                                            </Col>
                                            <Col className='m-0 p-0' xs='7'>
                                                <Form.Control
                                                    type="number" className='basecontrol custome-input'
                                                    name="txt_addshipDraft"
                                                    value={docks.ship_draft}
                                                    onChange={(e) => setDocks({ ...docks, ship_draft: e.target.value })}
                                                    // ref={register({ required: true, })}
                                                    placeholder='Max Ship Dimension'
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_dockDraft?.type === 'required' && 'Please enter Dock Draft'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >DWT Capacity</Text>
                                        <Form.Control
                                            type="number" className='basecontrol custome-input'
                                            name="txt_dockDwt"
                                            value={docks.dwt_capacity}
                                            onChange={(e) => setDocks({ ...docks, dwt_capacity: e.target.value })}
                                      //      ref={register({ required: true, })}
                                            placeholder='DWT Capacity'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_dockDwt?.type === 'required' && 'Please enter DWT'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Crane</Text>
                                        <Form.Control
                                            type="number" className='basecontrol custome-input'
                                            name="txt_crane"
                                            value={docks.cranes}
                                            onChange={(e) => setDocks({ ...docks, cranes: e.target.value })}
                                        //    ref={register({ required: true, })}
                                            placeholder='Cranes'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_crane?.type === 'required' && 'Please enter Number of Crane'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Crane Capacity</Text>
                                        <Form.Control
                                            type="number" className='basecontrol custome-input'
                                            name="txt_craneCapacity"
                                            value={docks.crane_capacity}
                                            onChange={(e) => setDocks({ ...docks, crane_capacity: e.target.value })}
                                         //   ref={register({ required: true, })}
                                            placeholder='Crane Capacity'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_craneCapacity?.type === 'required' && 'Please enter Crane Capacity'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Latitude</Text>
                                        <Form.Control
                                            type="number" className='basecontrol custome-input'
                                            name="txt_docklat"
                                            value={docks.latitude}
                                            onChange={(e) => setDocks({ ...docks, latitude: e.target.value })}
                                            ref={register({ required: true, min: -90.00, max: +90.00 })}
                                            placeholder='Latitude'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>
                                                {errors.txt_docklat?.type === 'required' && 'Please enter Latitude'}
                                                {errors.txt_docklat?.type === 'min' && 'Minimum Value should be -90'}
                                                {errors.txt_docklat?.type === 'max' && 'Maximum Vlaue should be +90'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Longitude</Text>
                                        <Form.Control
                                            type="number" className='basecontrol custome-input'
                                            name="txt_docklon"
                                            value={docks.longitude}
                                            onChange={(e) => setDocks({ ...docks, longitude: e.target.value })}
                                            ref={register({ required: true, min: -180.00, max: +180.00 })}
                                            placeholder='Longtitude'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>
                                                {errors.txt_docklon?.type === 'required' && 'Please enter Longtitude'}
                                                {errors.txt_docklon?.type === 'min' && 'Minimum Value should be -180'}
                                                {errors.txt_docklon?.type === 'max' && 'Maximum Vlaue should be +180'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                            <Col sm='12' className='m-0 p-0'>
                                <Row className='m-0 p-0'>
                                    <Col xs='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                                        <Attributes className='dock-add-edit'>
                                            {/* <div>
                                                <Text color='tablehead' variant='subtitle' >Company</Text>
                                                <Form.Control className='basecontrol cutome-selectInputnxt'
                                                    as='select' value={docks.company}
                                                    ref={register({ required: true, })}
                                                    name="txt_dockCompany" onChange={(e) => setDocks({ ...docks, company: e.target.value })}>
                                                    <option>-- Select Company --</option>
                                                    {docks.companies.map((data, i) => {
                                                        return <option key={i} value={data.id}>{data.name}</option>
                                                    })}
                                                </Form.Control>
                                            </div> */}
                                            <div>
                                                <Text color='tablehead' variant='subtitle' >Company</Text>
                                                <input className='basecontrol cutome-selectInputnxt'
                                                    value={docks.company}
                                                    list='companydatalist'
                                                    ref={register({ required: true, })}
                                                    placeholder='-- Select Company --'
                                                    name="txt_dockCompany"
                                                    id="txt_dockCompany"
                                                    onChange={(e) => setDocks({ ...docks, company: e.target.value })}
                                                >

                                                </input>
                                                <datalist id="companydatalist">
                                                    {docks.companies.map((data, i) => {
                                                        return <option key={i} value={data.name}></option>
                                                    })}
                                                </datalist>
                                            </div>
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_dockCompany?.type === 'required' && 'Please Select Dock Company'}
                                                    </span>
                                                </Col>
                                            </Row>
                                            {/* <div>
                                                <Text color='tablehead' variant='subtitle' > Port City</Text>
                                                <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                                    value={docks.city}
                                                    name="txt_dockcity" ref={register({ required: true, })}
                                                    onChange={(e) => _getPorts(e.target.value)}>
                                                    <option>-- Select City -- </option>
                                                    {docks.cities.map((data, i) => {
                                                        return <option key={i} value={data.code}>{data.name}</option>
                                                    })}
                                                </Form.Control>
                                            </div> */}
                                            <div>
                                                <Text color='tablehead' variant='subtitle' > Port City</Text>
                                                <input className='basecontrol cutome-selectInputnxt'
                                                    value={docks.city} id="txt_dockcity"
                                                    list='protfindbycity'
                                                    placeholder='-- Select city --'
                                                    name="txt_dockcity" ref={register({ required: true, })}
                                                    onChange={(e) => _getPorts(e.target.value)}>

                                                </input>
                                                <datalist id="protfindbycity">
                                                    {docks.cities.map((data, i) => {
                                                        return <option key={i} value={data.name}></option>
                                                    })}
                                                </datalist>
                                            </div>
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_dockcity?.type === 'required' && 'Please select Port City'}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <div>
                                                <Text color='tablehead' variant='subtitle' >Nearest Port</Text>
                                                <input className='basecontrol cutome-selectInputnxt'
                                                    ref={register({ required: true, })}
                                                    value={docks.port}
                                                    id='txt_dockport' list='port_datalist'
                                                    placeholder='-- Select Port City First --'
                                                    name="txt_dockport" onChange={(e) => setDocks({ ...docks, port: e.target.value })} >
                                                    {/* <option value=''> --Select port city first--</option> */}
                                                </input>
                                                <datalist id="port_datalist">
                                                    {docks.ports.length === 0 && (
                                                        <option className='option-placeholder'> --NO ports for selected city-- </option>
                                                    )}
                                                    {docks.ports.map((data: any, i: any) => {
                                                        return <option key={i} value={data.name}></option>
                                                    })}
                                                </datalist>
                                            </div>
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_dockport?.type === 'required' && 'Please select Port'}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <div>
                                                <Text color='tablehead' variant='subtitle' >Preferred Ship Type</Text>
                                                {/* <Form.Control className='basecontrol cutome-selectInputnxt' as='select' value={docks.shipType}
                                                name="txt_shipTypeInDock"
                                                ref={register({ required: true, })} onChange={(e) => setDocks({ ...docks, shipType: e.target.value })}>
                                                <option className='option-placeholder' value=''>--- Select Ship Type ---</option>
                                                {docks.shipTypes.map((data:any, i:any) => {
                                                    return <option key={i} value={data.id}>{data.name}</option>
                                                })}
                                            </Form.Control> */}
                                                {/* {data.filter(obj => selectedValue.includes(obj.value)) ||
                                                    options={selectedValue.length === maxOptions ? [] : [All, ...data]} */}

                                                <div>
                                                    <Select
                                                        className="dropdown"
                                                        placeholder="Select Ship Type"
                                                        // value={selectedValue}
                                                        value={data.filter(obj => selectedValue.includes(obj.value) || selectedValue.includes('All')) } // set selected values
                                                        // options={data} 
                                                        options={selectedValue.length === maxOptions ? [] : [{ label: 'All', value: 'All' }, ...data]}    noOptionsMessage={() => {
                                                            return selectedValue.length === maxOptions
                                                                ? 'You have to Select only 5 Ship Types'
                                                                : 'No options available';
                                                        }}
                                                        onChange={handleChange} // assign onChange function
                                                        isMulti
                                                        isClearable
                                                        isSearchable
                                                    />
                                                </div>
                                            </div>
                                            {/* <div>
                                            <Text color='tablehead' variant='subtitle' >Ship Type</Text>
                                            <div>
                                            <MultiSelect
                                                options = {options1}
                                                value={selected}
                                                onChange={setSelected}
                                                labelledBy="Select"
                                            />
                                            {console.log(options,'options in multiselect')}
                                            {console.log(selected)}
                                            </div>
                                        </div> */}
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_shipTypeInDock?.type === 'required' && 'Please Select Ship Type from a list'}
                                                    </span>
                                                </Col>
                                            </Row>
                                            {/* <div>
                                                <Text color='tablehead' variant='subtitle' >Tolerance</Text>
                                                <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                                 value={docks.tolerance}
                                                name="txt_tolerance"
                                                id='txt_tolerance'
                                                // ref={register({ required: true, })}
                                                 onChange={(e) => setDocks({ ...docks, tolerance: e.target.value })}>
                                                <option className='option-placeholder' value=''>--- Select Tolerance ---</option>
                                                {docks.tolerancearray.map((data:any, i:any) => {
                                                    return <option key={i} value={data.value}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                                <Form.Control
                                                    type="number" className='basecontrol custome-input'
                                                    name="txt_tolerance"
                                                    id='txt_tolerance'
                                                    value={docks.tolerance}
                                                    onChange={(e) => setDocks({ ...docks, tolerance: e.target.value })}
                                                    placeholder='Tolerance'
                                                />
                                            </div> */}
                                            {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_tolerance?.type === 'required' && 'Please Select Tolerance from a list'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        </Attributes>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='dock-add-edit'>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Unit</Text>
                                        <Form.Control
                                            type="text" className='basecontrol custome-input'
                                            name="txt_dockunit"
                                            // ref={register({ required: true, })}
                                            value={docks.unit}
                                            onChange={(e) => setDocks({ ...docks, unit: e.target.value })}
                                            placeholder='Unit'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_dockunit?.type === 'required' && 'Please enter Unit'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='dock-add-edit'>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' > Status</Text>
                                        <Form.Control className='basecontrol cutome-selectInputnxt'
                                            as='select' value={docks.status}
                                            name="txt_dockstatus" onChange={(e) => setDocks({ ...docks, status: e.target.value })} ref={register({ required: true, })}>
                                            <option className='option-placeholder' value=''>-- Select one --</option>
                                            <option value="VERIFIED"> Verified</option>
                                            <option value="UNVERIFIED"> Not verified</option>
                                        </Form.Control>
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_dockstatus?.type === 'required' && 'Please select Status'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                        </Row>
                        <Row className='m-0 p-0'>
                            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                                <Attributes className='dock-add-edit'>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Dock's Image</Text>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                    <Actions>
                        <Link to="/docks/list">
                            <Button type="button" className='bttn bttn-transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        <Button className='bttn bttn-transparent' type="submit"
                            // onClick={() => _didDockAdd()}
                            name='dockadd' id='dockadd'>
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
        </Main>
    )
}


export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

{/* <div>
<Text color='tablehead' variant='subtitle' >Port</Text>
<Form.Control className='basecontrol cutome-selectInputnxt'
    ref={register({ required: true, })}
    as='select' value={docks.port}
    name="txt_dockport" onChange={(e) => setDocks({ ...docks, port: e.target.value })} >
    <option value=''> --Select port city first--</option>
    {docks.ports.length === 0 && (
        <option> --NO ports for selected city-- </option>
    )}
    {docks.ports.map((data: any, i: any) => {
        return <option key={i} value={data.id}>{data.name}</option>
    })}
</Form.Control>
</div> */}