import {
    PRODUCT_TYPE_LIST_GET,
    PRODUCT_TYPE_LIST_GET_F,
    PRODUCT_TYPE_LIST_GET_R,
    PRODUCT_TYPE_SELECTED_SET
}  from '../actionType'

export const productTypeListGetAction = ( params : any) => ({
    type: PRODUCT_TYPE_LIST_GET,
    payload: params
})
  
export const productTypeListGetFAction = ( data : any) => ({
    type: PRODUCT_TYPE_LIST_GET_F,
    payload: data
})
  
export const productTypeListGetRAction = ( error : any) => ({
    type: PRODUCT_TYPE_LIST_GET_R,
    payload: error
})
export const productTypeSetSelectedAction = (productType : any) => ({
    type: PRODUCT_TYPE_SELECTED_SET,
    payload: productType
})