import {
    SHIP_LIST_GET,
    SHIP_LIST_GET_R,
    SHIP_LIST_GET_F,
    SHIP_SELECTED_SET,
    ALL_SHIP_LIST_GET,
    ALL_SHIP_LIST_GET_R,
    ALL_SHIP_LIST_GET_F,
    SHIP_COMPANY_LIST_GET,
    SHIP_COMPANY_LIST_GET_F,
    SHIP_COMPANY_LIST_GET_R,
    SHIP_COMPANY_SELECTED_SET,
    SHIP_OWNER_LIST_GET,
    SHIP_OWNER_LIST_GET_F,
    SHIP_OWNER_LIST_GET_R,
    SHIP_OWNER_SELECTED_SET,
} from '../actionType';
const initialState = {
    ships: [],
    allShips: [],
    companies: [],
    owners: [],
    isLoading: false,
    error: null,
    totalPage: 0,
    totalElement: 0,
    selectedShip: null,
    selectedCompany: null,
    selectedOwner: null
}
export default function shipReducer(state = initialState, action: any) {
    switch (action.type) {
        case SHIP_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case SHIP_LIST_GET_F:
            var totalshipcount = 0
            if (action.payload.content === undefined || '') {
                totalshipcount = 0
            }
            else {
                action.payload.content.map((data: any) => {
                    totalshipcount = data.totalrow
                })
            }
            return {
                ...state,
                isLoading: false,
                ships: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: totalshipcount
            }
        case SHIP_LIST_GET_R:

            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case ALL_SHIP_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case ALL_SHIP_LIST_GET_F:
            // console.log(action.payload.content,'allships in shipReducer')
            return {
                ...state,
                isLoading: false,
                allShips: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
            }
        case ALL_SHIP_LIST_GET_R:

            return {
                ...state,
                isLoading: false,
                error: action.payload
            }

        case SHIP_SELECTED_SET:
            return {
                ...state,
                selectedShip: action.payload
            }
        case SHIP_COMPANY_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case SHIP_COMPANY_LIST_GET_F:
            var totalshipcmpcount = 0

            if (action.payload.content === undefined || '') {
                totalshipcmpcount = 0
            }
            else {
                action.payload.content.map((data: any) => {
                    totalshipcmpcount = data.total_row
                })
            }
            return {
                ...state,
                isLoading: false,
                companies: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: totalshipcmpcount
            }
        case SHIP_COMPANY_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SHIP_COMPANY_SELECTED_SET:
            return {
                ...state,
                selectedCompany: action.payload
            }
            case SHIP_OWNER_LIST_GET:
                return{
                    ...state,
                    isLoading: true,
                    error: null
                }
            case SHIP_OWNER_LIST_GET_F:
                // console.log(action.payload,'in dockownerlist reducer')
                var totalshipowncount = 0
                if(action.payload.content === undefined || '')
                {
                    totalshipowncount = 0
                }
                else{
                    action.payload.content.map((data:any) => {
                        totalshipowncount = data.totalrow
                    })
                }
                
                return{
                    ...state,
                    isLoading: false,
                    owners: action.payload.content,
                    totalPage: action.payload.total_page,
                    totalElement: totalshipowncount
                }
            case SHIP_OWNER_LIST_GET_R:
                return{
                    ...state,
                    isLoading: false,
                    error: action.payload
                }
            case SHIP_OWNER_SELECTED_SET:
                return{
                    ...state,
                    selectedOwner: action.payload
                }
        default:
            return state
    }
}