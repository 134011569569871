import { map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getUsers = (params:object) => {
//  console.log('called')
//  console.log(params ,'param')
  return http({
    method: 'GET',
    url: `/users/list/search`,
    params
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res, 'res')
        return res
      } else {
        // console.log(res ,'res')
        throw new Error(res.msg)
      }
    })
  )
}

const getSystemUsers = (params:object) => {
  //  console.log('called')
  //  console.log(params ,'param')
    return http({
      method: 'GET',
      url: `/users/list/search`,
      params
    }).pipe(
      map(res => {
        if (res.success) {
          // console.log(res, 'res')
          return res
        } else {
          // console.log(res ,'res')
          throw new Error(res.msg)
        }
      })
    )
  }

const addUser =( body : object) => {
  return http({
    method: 'POST',
    url: `/users/create`,
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const updateUser =(body:object) => {
  return http({
    method: 'PUT',
    url: `/users/update`,
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log('update user response', res)
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}
const UpdateUserImage =(body:object) => {
  return http({
    method: 'POST',
    url: `/users/profilePic`,
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log('update user response', res)
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteUser = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/users/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const getUserDetail = (id:any) => {
  return http({
    method: 'GET',
    url: `/users/${id}`
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res, 'company view data')
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const mapCompanyToUser =( body : object) => {
  return http({
    method: 'POST',
    url: `/invite/sendInvite`,
    body
  }).pipe(
    map(res => {
      if (res.success === true) {
        return res
      } else {
        console.log(res,'res in user service')
        console.log(res.error,'res msg in user service')
        throw new Error(res.error)
      }
    })
  )
}

const revokeUser = (params:object) => {
  //  console.log('called')
  //  console.log(params ,'param')
  return http({
    method: 'GET',
    url: `/invite/revokeInvite`,
    params
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res, 'res')
        return res
      } else {
        // console.log(res ,'res')
        throw new Error(res.msg)
      }
    })
  )
}

const removeUserImg = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/users/removeProfileAll/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const userService = {
  addUser,
  getUsers,
  getSystemUsers,
  getUserDetail,
  updateUser,
  deleteUser,
  mapCompanyToUser,
  revokeUser,
  UpdateUserImage,
  removeUserImg
}

export default userService
