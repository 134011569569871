import React, { useRef, useState } from 'react'
import Modal from '../../../../common/components/modal/Modal'
import { Text } from '../../../../common/components/text/Text'
import { Input, Radio, Select } from '../../../../common/components/control/Control'
import { Button } from '../../../../common/components/button/Button'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import swal from 'sweetalert'
import { connect } from 'react-redux';
import { dockListGetAction } from '../../../../store/action/dockActions';
import codeService from '../../../../services/codeService';
import {FilterState} from '../../filter/filterState'


interface filterProps {
    dockType: string;
    docks: any[];
    status: string;
    length: string | number;
    width: string | number;
    draft: string | number;
    capacity: string | number;
    dockName: string;
    country: string;
    countries: any[];
    city: string;
    cities: any[];
    dockCmp: any[];
    cmp: string;
}
const FilterModal = (props: any) => {
    // const {register} = useForm();
    // const {errors} = useForm();
    const { handleSubmit } = useForm();
    const { view } = props
    const { onClose } = props
    const { docks, isLoading, totalElement, totalPage } = props
    const [display, setDocks] = React.useState<any>([])
    const [form, setForm] = useState<filterProps>({
        dockType: '',
        status: '',
        docks: [],
        length: '',
        width: '',
        draft: '',
        capacity: '',
        dockName: '',
        country: '',
        countries: [],
        city: '',
        cities: [],
        dockCmp: [],
        cmp: '',
    })
    const [editForm, setEditForm] = useState({
        docks: '',
        status: FilterState.dockFilter.status,
        length: FilterState.dockFilter.length,
        width: FilterState.dockFilter.width,
        draft: FilterState.dockFilter.draft,
        capacity: FilterState.dockFilter.capacity,
        dockName: '',
        country: '',
        countries:[''],
        cities:[''],
        countryId:'',
        city: FilterState.dockFilter.cityName,
        cmp: FilterState.dockFilter.companyName,
        cityName:'',
        countryname:FilterState.dockFilter.countryNames,
    })
    const _getDockTypes = (() => {
        return codeService.getDockTypes().subscribe({
            next: r => {

                // console.log(r.data, "dock type")
                setForm({ ...form, dockType: r.data })
                // console.log(form.docks, 'dock type data in ship add page')
                r.data.map((data: any, i: any) => {
                    return form.docks.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    // Used to get ship companies list and store to companies variable.
    const _getCompany = (() => {
        return codeService.getCompanies({ company_type: 'DOCK' }).subscribe({
            next: r => {
                // console.log(r.data, 'companies in ship add page')
                setForm({ ...form, dockCmp: r.data })
                r.data.map((data: any, i: any) => {
                    return form.dockCmp.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getCountryFlag = (() => {
        return codeService.getCountries().subscribe({
            next: r => {
                setEditForm({ ...editForm, countries: r.data })
                r.data.map((data: any, i: any) => {
                    return editForm.countries.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getCities = ((data:any) => {
        var id = data.id;
        var countryName = data.name;
        // console.log(data,'data in countries')
        // console.log(data.id,'city id')
        // console.log(data.name,'country Name')
        return codeService.getCountryCities({id:id}).subscribe({
            next: r => {

                setEditForm({ ...editForm, cities: r.data, country:countryName, countryname:JSON.stringify(data)})
                r.data.map((data: any, i: any) => {
                    return editForm.cities.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    
    React.useEffect(() => {
        _getCountryFlag()
        _getDockTypes()
        _getCompany()
        if(FilterState.dockFilter.countryNames !== ''){
            _getCities(JSON.parse(FilterState.dockFilter.countryNames))
        }
    }, [])

    onsubmit = (e) => {
        // console.log(editForm)
        e.preventDefault()
        const { onClose } = props
        // React.useEffect(() => {
        //     if (docks && docks.length > 0) {
        //       setDocks(() => {
        //         return docks.map((s: any) => ({ ...s, checked: false }))
        //       })
        //     }
        //     if (docks && docks.length === 0) {
        //       setDocks([])
        //     }
        //   }, [docks])
        props.dockListGetAction({
            size: 10, page: 0, companyName: editForm.cmp,
            cityName: String(editForm.city),
            countryName: String(editForm.country),
            length: editForm.length,
            width: editForm.width,
            draft: editForm.draft,
            capacity: editForm.capacity,
            status: editForm.status
        })
        FilterState.dockFilter.companyName=editForm.cmp
        FilterState.dockFilter.cityName=String(editForm.city)
        FilterState.dockFilter.countryName=String(editForm.country)
        FilterState.dockFilter.length=editForm.length
        FilterState.dockFilter.width=editForm.width
        FilterState.dockFilter.draft=editForm.draft
        FilterState.dockFilter.capacity=editForm.capacity
        FilterState.dockFilter.status=editForm.status
        FilterState.dockFilter.countryNames=editForm.countryname
        FilterState.dockFilter.page=0
        FilterState.dockFilter.size=10
        onClose()
    }

    return (
        <Modal
            onClose={() => onClose()}
            title={'Filter'}>
            <ModalBody>
                <form {...handleSubmit(onsubmit)} autoComplete='off'>
                    <Attributes>
                        <div>
                            <Text color='black' variant='subtitle'>Company Name</Text>
                            <Input
                                type="text"
                                name="cmp_name"
                                value={editForm.cmp}
                                onChange={(e) => setEditForm({ ...editForm, cmp: e.target.value })}
                                placeholder='Company Name'
                            />
                        </div>
                        {/* <div>
                            <Text color='black' variant='subtitle'>Dock Type</Text>
                            <Select values={editForm.docks} name='dockType' onChange={(e) => setEditForm({ ...editForm, docks: e.target.value })} >
                                <option value="">-- Select --</option>
                                {form.docks.map((data: any, i: any) => {
                                    return <option key={i} value={data.code}>{data.name}</option>
                                })}
                            </Select>
                        </div> */}
                        <div>
                            <Text color='black' variant='subtitle'>Country</Text>
                            <Select values={editForm.countryname} name='country' 
                                onChange={(e) => _getCities(JSON.parse(e.target.value))}>
                                <option value="">-- Select --</option>
                                {editForm.countries.map((data: any, i: any) => {
                                    return <option key={i} value={JSON.stringify(data)}>{data.name}</option>
                                })}
                            </Select>
                        </div>
                        <div>
                            <Text color='black' variant='subtitle'>City</Text>
                            <Select values={editForm.city} name='city' onChange={(e) => setEditForm({ ...editForm, city: e.target.value })}>
                                <option value="">-- Select Country First --</option>
                                {editForm.cities.length === 0 &&(
                                    <option value="">-- Ther is no cities --</option>
                                )}
                                {editForm.cities.map((data: any, i: any) => {
                                    
                                    return <option key={i} value={data.name}>{data.name}</option>
                                })}
                            </Select>
                        </div>
                        <div>
                            <Text color='tablehead' variant='subtitle' >Length </Text>
                            <Input
                                type="number"
                                name="length"
                                value={editForm.length}
                                onChange={(e) => setEditForm({ ...editForm, length: e.target.value })}
                                placeholder='Length'
                            />
                        </div>
                        <div>
                            <Text color='tablehead' variant='subtitle' > Width </Text>
                            <Input
                                type="number"
                                name="width"
                                value={editForm.width}
                                onChange={(e) => setEditForm({ ...editForm, width: e.target.value })}
                                placeholder='width'
                            />
                        </div>
                        <div>
                            <Text color='tablehead' variant='subtitle' >Draft </Text>
                            <Input
                                type="number"
                                name="draft"
                                value={editForm.draft}
                                onChange={(e) => setEditForm({ ...editForm, draft: e.target.value })}
                                placeholder='Draft'
                            />
                        </div>
                        <div>
                            <Text color='tablehead' variant='subtitle' >DWT Capacity</Text>
                            <Input
                                type="number"
                                name="dwt_capacity"
                                value={editForm.capacity}
                                onChange={(e) => setEditForm({ ...editForm, capacity: e.target.value })}
                                placeholder='DWT Capacity'
                            />
                        </div>
                        <div>
                            <Text color='black' variant='subtitle'>Status</Text>
                            <Select values={editForm.status} name='city' onChange={(e) => setEditForm({ ...editForm, status: e.target.value })}>
                                <option value="">-- Select --</option>
                                <option key={1} value='VERIFIED'>Verified</option>
                                <option key={2} value='UNVERIFIED'>Not Verified</option>
                                <option key={3} value=''>All</option>
                            </Select>
                        </div>
                    </Attributes>
                    <Space divheight={20} />
                    <Flex jc='jc-center'>
                        <Button
                            block
                            type="button"
                            onClick={() => onClose()}
                            variant='transparent'>
                            <Flex>
                                <Icons icon="arrow_back" />
                                BACK
                            </Flex>
                        </Button>
                        {!view && (
                            <Button variant='transparent' block type="submit">
                                <Flex>
                                    <Icons color="#ffffff" icon="check" />
                                    Filter Data
                                </Flex>
                            </Button>
                        )}
                    </Flex>
                </form>
            </ModalBody>
        </Modal>
    )
}

export interface ModalbodyProps {
    children?: React.ReactNode
}

const ModalBody = ({ children, ...props }: ModalbodyProps) => {

    const modal = classNames('modalbody-container')

    return (
        <div className={modal}>
            {children}
        </div>
    )

}
interface AttributeProps {
    children?: React.ReactNode
}
export const Attributes = ({ children, ...props }: AttributeProps) => {
    const attribute = classNames('attribute-container');

    return (
        <div {...props} className={attribute} >
            {children}
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    docks: state.dock.docks,
    isLoading: state.dock.isLoading,
    error: state.dock.error,
    totalElement: state.dock.totalElement,
    totalPage: state.dock.totalPage
})
export default connect(
    mapStateToProps,
    {
        dockListGetAction,
    }
)(FilterModal)
