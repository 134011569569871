import React, { useState } from 'react'
import Modal from '../../../../common/components/modal/Modal'
import { Text } from '../../../../common/components/text/Text'
import { Input, Select } from '../../../../common/components/control/Control'
import { Button } from '../../../../common/components/button/Button'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { Date } from 'prismic-reactjs';
// import { useEffect } from 'react'
// import swal from 'sweetalert'
import { connect } from 'react-redux';
import { dockOwnerListGetAction,dockOwnerSetSelectedAction } from '../../../../store/action/dockActions';
import {FilterState} from '../../filter/filterState'
// import codeService from '../../../../services/codeService'

interface filterProps {
    fromDate:string;
    toDate:string;
    status: string;
    name: string;
}
const DockOwnerFilter = (props: any) => {
    // const {register} = useForm();
    // const {errors} = useForm();
    const { handleSubmit } = useForm();
    const { view } = props
    const { onClose } = props
    const { selecteddock,docks, isLoading, totalElement, totalPage } = props
    // const [display, setDocks] = React.useState<any>([])
    const [form, setForm] = useState<filterProps>({
        fromDate:FilterState.ownerFilter.fromDate,
        toDate:FilterState.ownerFilter.toDate,
        status: FilterState.ownerFilter.Subscriptionstatus,
        name: FilterState.ownerFilter.name,
    })
    
    onsubmit = (e) => {
        // console.log(form)
        e.preventDefault()
        const { onClose } = props
        props.dockOwnerListGetAction({
            size: 10, page: 0, 
            name: form.name, 
            fromDate:String(form.fromDate),
            toDate: String(form.toDate), 
            subscriptionstatus:form.status, 
        })
        FilterState.ownerFilter.page=0
        FilterState.ownerFilter.size=10
        FilterState.ownerFilter.fromDate=String(form.fromDate)
        FilterState.ownerFilter.toDate=String(form.toDate)
        FilterState.ownerFilter.Subscriptionstatus=form.status
        FilterState.ownerFilter.name=form.name
        onClose()
    }

    return (
        <Modal
            onClose={() => onClose()}
            title={'Filter'}>
            <ModalBody>
                <form {...handleSubmit(onsubmit)} autoComplete='off'>
                    <Attributes>
                        <div>
                            <Text color='black' variant='subtitle'>First Name</Text>
                            <Input
                                type="text"
                                name="name"
                                value={form.name}
                                onChange={(e) => setForm({ ...form, name: e.target.value })}
                                placeholder='First Name'
                            />
                        </div>
                        <div>
                            <Text color='black' variant='subtitle'>Subscription Status</Text>
                            <Select values={form.status} name='verified' onChange={(e) => setForm({ ...form, status: e.target.value })}>
                                <option value="">-- Select --</option>
                                <option key={1} value='yes'>Subscribed</option>
                                <option key={2} value='no'>Not Subscribed</option>
                                <option key={3} value=''>All</option>
                            </Select>
                        </div>
                    </Attributes>
                        <div >
                            <Text color='main' variant='subtitle' bold center>Enter Range From-To date</Text>
                        </div>
                    <Attributes>
                        <div>
                            <Text color='black' variant='subtitle'>From Date</Text>
                            <input type="date" id="fromtime" name="fromtime" value={form.fromDate}
                                onChange={(e) => setForm({...form, fromDate: e.target.value}) }>
                            </input>
                        </div>
                        <div>
                            <Text color='black' variant='subtitle'>To Date</Text>
                            <input type="date" id="totime" name="totime" value={form.toDate}
                                onChange={(e) => setForm({...form, toDate: e.target.value}) }>
                            </input>
                        </div>
                    </Attributes>
                    <Space divheight={20} />
                    <Flex jc='jc-center'>
                        <Button
                            block
                            type="button"
                            onClick={() => onClose()}
                            variant='transparent'>
                            <Flex>
                                <Icons icon="arrow_back" />
                                BACK
                            </Flex>
                        </Button>
                        {!view && (
                            <Button variant='transparent' block type="submit">
                                <Flex>
                                    <Icons color="#ffffff" icon="check" />
                                    Filter Data
                                </Flex>
                            </Button>
                        )}
                    </Flex>
                </form>
            </ModalBody>
        </Modal>
    )
}

export interface ModalbodyProps {
    children?: React.ReactNode
}

const ModalBody = ({ children, ...props }: ModalbodyProps) => {

    const modal = classNames('modalbody-container')

    return (
        <div className={modal}>
            {children}
        </div>
    )

}
interface AttributeProps {
    children?: React.ReactNode
}
export const Attributes = ({ children, ...props }: AttributeProps) => {
    const attribute = classNames('attribute-container');

    return (
        <div {...props} className={attribute} >
            {children}
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    docks: state.dock.docks,
    selecteddock:state.dock.selectedDock,
    isLoading: state.dock.isLoading,
    error: state.dock.error,
    totalElement: state.dock.totalElement,
    totalPage: state.dock.totalPage
})
export default connect(
    mapStateToProps,
    {
        dockOwnerListGetAction,
        dockOwnerSetSelectedAction
    }
)(DockOwnerFilter)
