import{SHIP_MATERIAL_LIST_GET,
    SHIP_MATERIAL_LIST_GET_F,
    SHIP_MATERIAL_LIST_GET_R,
    SHIP_MATERIAL_SELECTED_SET
} from '../actionType'

export const shipMaterialListGetAction = (params : any) => ({
    type: SHIP_MATERIAL_LIST_GET,
    payload: params
})

export const shipMaterialListGetFAction = (params : any) => ({
    type: SHIP_MATERIAL_LIST_GET_F,
    payload: params
})

export const shipMaterialListGetRAction = (params : any) => ({
    type: SHIP_MATERIAL_LIST_GET_R,
    payload: params
})

export const shipMaterialSetSelectedAction = (type : any) => ({
    type: SHIP_MATERIAL_SELECTED_SET,
    payload: type
})