import React, { useState } from 'react'
import Modal from '../../../../common/components/modal/Modal'
import { Text } from '../../../../common/components/text/Text'
import { Input,Select } from '../../../../common/components/control/Control'
import { Button } from '../../../../common/components/button/Button'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
// import { Date } from 'prismic-reactjs';
// import { useEffect } from 'react'
// import swal from 'sweetalert'
import { connect } from 'react-redux';
import { shipCompanyListGetAction } from '../../../../store/action/shipActions';
import codeService from '../../../../services/codeService'
import {ShipFilterState} from '../../filter/ShipFilterState'

interface filterProps {
    status: string;
    country: string;
    countries: any[];
    city: string;
    cities: any[];
    shipcompanyName: string;
    countryname:string;
}
const DockCmpFilter = (props: any) => {
    // const {register} = useForm();
    // const {errors} = useForm();

    const { handleSubmit } = useForm();
    const { view } = props
    const { onClose } = props
    const { docks, isLoading, totalElement, totalPage } = props
    const [display, setDocks] = React.useState<any>([])
    const [form, setForm] = useState<filterProps>({
        status: ShipFilterState.shipCmpFilter.shipcmpstatus,
        country: '',
        countryname:ShipFilterState.shipCmpFilter.shipcmpcountryNames,
        countries:[],
        city: ShipFilterState.shipCmpFilter.city,
        cities:[],
        shipcompanyName: ShipFilterState.shipCmpFilter.cmpName,
    })
    
    // console.log(FilterState.cmpFilter,'cmpfilter')
    // Used to get ship companies list and store to companies variable.
    const _getCountryFlag = (() => {
        return codeService.getCountries().subscribe({
            next: r => {
                setForm({ ...form, countries: r.data })
                r.data.map((data: any, i: any) => {
                    return form.countries.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getCities = ((data:any) => {
        var id = data.id;
        var countryName = data.name;
        // console.log(data,'data in countries')
        // console.log(data.id,'city id')
        // console.log(data.name,'country Name')
        return codeService.getCountryCities({id}).subscribe({
            next: r => {
                // console.log(id,'city id')
                // console.log(countryName,'country Name')
                setForm({ ...form, cities: r.data, country:countryName, countryname:JSON.stringify(data) })
                r.data.map((data: any, i: any) => {
                    return form.cities.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    // console.log(form,'Form inside cities')
    React.useEffect(() => {
        _getCountryFlag()
        if(ShipFilterState.shipCmpFilter.shipcmpcountryNames !== ''){
            _getCities(JSON.parse(ShipFilterState.shipCmpFilter.shipcmpcountryNames))
        }
    }, [])

    onsubmit = (e) => {
        // console.log(form)
        e.preventDefault()
        props.shipCompanyListGetAction({
            size: 10, page: 0, 
            companyname: form.shipcompanyName, 
            city: form.city, 
            country: form.country,
            status: form.status, 
        })
        ShipFilterState.shipCmpFilter.shipcmpstatus=form.status
        ShipFilterState.shipCmpFilter.shipcmpcountry=form.country
        ShipFilterState.shipCmpFilter.shipcmpcountryNames=form.countryname
        ShipFilterState.shipCmpFilter.city=form.city
        ShipFilterState.shipCmpFilter.cmpName=form.shipcompanyName
        ShipFilterState.shipCmpFilter.page=0
        ShipFilterState.shipCmpFilter.size=10
        
        // console.log(FilterState.cmpFilter,'cmpfilter after value')
        onClose()
    }

    return (
        <Modal
            onClose={() => onClose()}
            title={'Filter'}>
            <ModalBody>
                <form {...handleSubmit(onsubmit)} autoComplete='off'>
                    <Attributes>
                        <div>
                            <Text color='black' variant='subtitle'>Company Name</Text>
                            <Input
                                type="text"
                                name="company Name"
                                value={form.shipcompanyName}
                                onChange={(e) => setForm({ ...form, shipcompanyName: e.target.value })}
                                placeholder='company Name'
                            />
                        </div>
                        <div>
                            <Text color='black' variant='subtitle'>Country</Text>
                            {/* {console.log(form.countryname,'country name')} */}
                            <Select values={form.countryname} name='country' 
                                onChange={(e) => _getCities(JSON.parse(e.target.value))}>
                                <option value="">-- Select --</option>
                                {form.countries.map((data: any, i: any) => {
                                    // console.log(data,'data in drop down')
                                    // console.log(JSON.stringify(data),'data in drop')
                                    return <option key={i} value={JSON.stringify(data)}>{data.name}</option>
                                })}
                            </Select>
                            {/* {console.log(form.countryname,'country name')} */}
                        </div>
                        <div>
                            <Text color='black' variant='subtitle'>City</Text>
                            <Select values={form.city} name='city' onChange={(e) => setForm({ ...form, city: e.target.value })}>
                                <option value="">-- Select Country First --</option>
                                {form.cities.length === 0 &&(
                                    <option value="">-- Ther is no cities --</option>
                                )}
                                {form.cities.map((data: any, i: any) => {
                                    
                                    return <option key={i} value={data.name}>{data.name}</option>
                                })}
                            </Select>
                        </div>
                        <div>
                            <Text color='black' variant='subtitle'>Status</Text>
                            <Select values={form.status} name='verified' onChange={(e) => setForm({ ...form, status: e.target.value })}>
                                <option value="">-- Select --</option>
                                <option key={1} value='VERIFIED'>Verified</option>
                                <option key={2} value='UNVERIFIED'>Not Verified</option>
                                <option key={3} value=''>All</option>
                            </Select>
                        </div>
                    </Attributes>
                    <Space divheight={20} />
                    <Flex jc='jc-center'>
                        <Button
                            block
                            type="button"
                            onClick={() => onClose()}
                            variant='transparent'>
                            <Flex>
                                <Icons icon="arrow_back" />
                                BACK
                            </Flex>
                        </Button>
                        {!view && (
                            <Button variant='transparent' block type="submit">
                                <Flex>
                                    <Icons color="#ffffff" icon="check" />
                                    Filter Data
                                </Flex>
                            </Button>
                        )}
                    </Flex>
                </form>
            </ModalBody>
        </Modal>
    )
}

export interface ModalbodyProps {
    children?: React.ReactNode
}

const ModalBody = ({ children, ...props }: ModalbodyProps) => {

    const modal = classNames('modalbody-container')

    return (
        <div className={modal}>
            {children}
        </div>
    )

}
interface AttributeProps {
    children?: React.ReactNode
}
export const Attributes = ({ children, ...props }: AttributeProps) => {
    const attribute = classNames('attribute-container');

    return (
        <div {...props} className={attribute} >
            {children}
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    companies: state.dock.docks,
    isLoading: state.dock.isLoading,
    error: state.dock.error,
    totalElement: state.dock.totalElement,
    totalPage: state.dock.totalPage,
})
export default connect(
    mapStateToProps,
    {
        shipCompanyListGetAction,
    }
)(DockCmpFilter)

