import {
    DATA_SOCIAL_REPORT_LIST_GET,
    DATA_SOCIAL_REPORT_LIST_GET_F,
    DATA_SOCIAL_REPORT_LIST_GET_R,
    DATA_SOCIAL_REPORT_SELECTED_SET,
  } from '../actionType'
  
  export const reportListGetAction = (params : any) => ({
    type: DATA_SOCIAL_REPORT_LIST_GET,
    payload: params
  })
  
  export const reportListGetFAction = (data : any) => ({
    type: DATA_SOCIAL_REPORT_LIST_GET_F,
    payload: data
  })
  
  export const reportListGetRAction = ( error : any) => ({
    type: DATA_SOCIAL_REPORT_LIST_GET_R,
    payload: error
  })
  
  export const reportSetSelectedAction = (report : any) => ({
    type: DATA_SOCIAL_REPORT_SELECTED_SET,
    payload: report
  })