import { combineEpics } from 'redux-observable';
import { getShipCompaniesEpic, getShipOwnersEpic, getShipsEpic,getAllShipsEpic } from '../epics/shipEpics';
import {getShipTypesEpic} from '../epics/ShipTypeEpic'
import { getAccountEpic, setAccountEpic } from './accountEpics';
import { getDockCompaniesEpic, getDockOwnersEpic, getDocksEpic } from './dockEpics';
import { getUsersEpic,getAllUsersEpic,getSystemUsersEpic } from './UserEpics';
import {getRoleEpic} from './RoleEpics'
import {getCountryEpic} from './countryEpics'
import {getShipClassificationEpic} from './shipClassificationEpics'
import {getDockTypesEpic} from './dockTypeEpics'
import {getShipMaterialEpic} from './materialEpics'
import {getAnodeEpic} from './anodeEpics'
import {getCityEpics} from './cityEpics'
import {getcmpTypeEpics} from './cmpTypeEpics'
import {getunitsEpic} from './unitEpics'
import {getThicknessEpic} from './thicknessEpics'
import {getPortEpic} from './portEpics'
import {getReportEpic} from './reportEpics'
import { getProvincesEpic } from './provincesEpics'
import { getPaintEpic } from './paintEpics'
import {getSubscriptionEpic} from './subscriptionEpics'
import {getManufacturerEpic} from './manufacturerEpics'
import {getProductTypeEpic} from './productTypeEpics'
import {getSlotEpic} from './slotEpics'
import {getAdsEpic} from './adsEpics'
import {getManufacturerCmpEpic} from './manufacturerEpics'
import {getPayPerViewEpics} from './manufacturerEpics'
import {getTransactionEpics} from './manufacturerEpics'
import {getShipSubTypesEpic} from './shipSubTypeEpics'
import {getQuotationEpics} from './manufacturerEpics'
import {dataShipEpics} from './dataShipEpics'
import {dataShipEngEpics} from './dataEngEpics'
import {dataShipClassEpics} from './dataClassiEpics'
import {dataPropulsionEpics} from './propulsionEpics'
import {dataShipdemolitionEpics} from './dataDemoEpics'

export default combineEpics(
    getAnodeEpic,
    getCityEpics,
    getcmpTypeEpics,
    getDocksEpic,
    getDockTypesEpic,
    getUsersEpic,
    getDockCompaniesEpic,
    getDockOwnersEpic,
    getShipMaterialEpic,
    getShipsEpic,
    getShipCompaniesEpic,
    getShipOwnersEpic,
    setAccountEpic,
    getAccountEpic,
    getAllUsersEpic,
    getUsersEpic,
    getunitsEpic,
    getThicknessEpic,
    getPortEpic,
    getReportEpic,
    getAllShipsEpic,
    getShipTypesEpic,
    getRoleEpic,
    getCountryEpic,
    getShipClassificationEpic,
    getPaintEpic,
    getProvincesEpic,
    getSubscriptionEpic,
    getManufacturerEpic,
    getManufacturerCmpEpic,
    getProductTypeEpic,
    getSlotEpic,
    getAdsEpic,
    getShipSubTypesEpic,
    getPayPerViewEpics,
    getTransactionEpics,
    getQuotationEpics,
    getSystemUsersEpic,
    dataShipEpics,
    dataShipEngEpics,
    dataShipClassEpics,
    dataPropulsionEpics,
    dataShipdemolitionEpics
);
