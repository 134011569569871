import React, { useRef, useState } from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button, Image } from 'react-bootstrap'
import noImg from '../../../../assets/images/NoImg.jpg';
import editicon from '../../../../assets/images/editone.png';
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
import { Text } from '../../../../common/components/text/Text'
import removeicon from '../../../../assets/images/Remove.png'
import Card from '../../../../common/components/card/Card'
import { Flex } from '../../../../common/components/flex/Flex'
// import {Input, Select, Textarea} from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
// import codeService from '../../../services/codeService'
import manufacturerService from '../../../../services/manufacturerService'
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import { JsxChild } from 'typescript'
import { connect } from 'react-redux';
import { manufacturerCmpListGetAction, manufacturerCmpSetSelectedAction } from '../../../../store/action/manufacturerAction';
import { history } from '../../../../store';
import swal from 'sweetalert'
import Loader from 'react-loader-spinner';
import codeService from '../../../../services/codeService';
import { useForm } from 'react-hook-form';
import { Label } from '../../../../common/components/label/Label';


interface FormData {
    txt_supeditcmpName: string;
    txt_supeditcmpPhn: string;
    txt_supeditcmpAddress: string;
    txt_supeditcmpcity: string;
    txt_supeditmpemail: string;
    txt_supeditcmpwebstite: string;
    txt_supeditcmpstatus: string;
    txt_supeditceo: string;
    txt_supeditchairman: string;
    txt_supeditdirector: string;
    txt_supedityear: string;
    txt_supeditservices: string;
    txt_supeditcity: string;
    txt_producttype: string;
}
interface bvalueProps {
    string_image: string | ArrayBuffer | null
}
const ManufacturerCmpEdit = (props: any) => {
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const { match } = props
    const { pages } = match.params
    const [showDelete, setShow] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false)
    const [supcmpeditflag, setSupCmpeditFlag] = React.useState(false)
    const [supCmpEditForm, setSupCmpEditform] = useState<any>({
        mobileCode: [],
        cities: []
    })
    const [display, setDisplay] = useState<any>({
        name: '',
        address: '',
        email: '',
        phone: '',
        website: '',
        status: 'UNVERIFIED',
        owner: '',
        type: '',
        id: '',
        city: '',
        phone_code: '',
        company_logo: '',
        user: '',
        ceo: '',
        chairman: '',
        year: '',
        services: '',
        director: '',
        productType: ''
    })
    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<bvalueProps>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageAPIManufacCmp(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const _removeManufacturerCompanyLogo = (id: any) => {
        return manufacturerService.removeManufacturerCompanyLogo(id).subscribe({
            next: (r) => {
                // console.log(r, 'response')
                setShow(false);
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
                // history.push(`/ships/${id}/`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
            }
        })
    }

    const ImageAPIManufacCmp = (imageurl: any) => {
        const { id } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        manufacturerService.updateManufactCompanyImage(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                //history.push('/manufacturer/comapanies/list')
                window.location.reload()
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }
    const _getPhnCode = () => {
        return codeService.getMobileCode().subscribe({
            next: r => {
                // console.log(r.data, "Role")
                setSupCmpEditform({ ...supCmpEditForm, mobileCode: r.data })
                // console.log(supCmpEditForm.mobileCode, 'mobileCode')
                r.data.map((data: any, i: any) => {
                    return supCmpEditForm.mobileCode.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    }
    const _getCity = (() => {
        // console.log(codeService.getCities())
        return codeService.getCities().subscribe({
            next: r => {
                console.log(r.data, 'owners in Dock add page')

                setSupCmpEditform({ ...supCmpEditForm, cities: r.data })
                r.data.map((data: any, i: any) => {
                    return supCmpEditForm.cities.push(r.data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    React.useEffect(() => {
        const { id } = match.params
        _getCompanyInfo(id)
        _getPhnCode()
        _getCity()
    }, [])

    const _setFromDate = (val:any) => {
        if(val !== null)
        {
            setDisplay({...display, year: val})
        }
        else(
            setDisplay({...display, year:''})
        )
    }
    const _getCompanyInfo = (id: any) => {
        setLoading(true)
        return manufacturerService.getManufactCompanyDetail(id).subscribe({
            next: r => {
                setDisplay(r.data)
                setDisplay({
                    ...display,
                    name: r.data.name,
                    email: r.data.email,
                    user: r.data.user,
                    id: r.data.id,
                    address: r.data.address,
                    status: r.data.status,
                    phone: r.data.phone,
                    website: r.data.website,
                    phone_code: r.data.phone_code,
                    company_logo: r.data.company_logo,
                    company_logos: r.data.company_logos,
                    city: r.data.companyCity.id,
                    type: r.data.companyTypes.id,
                    ceo: (r.data.user === null) ? '' : r.data.user.ceo,
                    chairman: (r.data.user === null) ? '' : r.data.user.chairman,
                    director: (r.data.user === null) ? '' : r.data.user.directors,
                    services: (r.data.user === null) ? '' : r.data.user.services,
                    year: (r.data.user === null) ? '' : r.data.user.year_of_establishment,
                    productType: r.data.vendor_type
                })
                // console.log(r.data,'display edit')
                props.manufacturerCmpSetSelectedAction(r.data)
                // {r.data.map((cmp: any, i: any) => [
                //     setDisplay({
                //         name: cmp.name,
                //         address: cmp.address,
                //         email: cmp.email,
                //         phone: cmp.phone,
                //         website: cmp.website,
                //         status: cmp.status,
                //         owner: '',
                //         type: cmp.companyTypes.id,
                //         id: cmp.id,
                //         city:cmp.companyCity.id
                //     })
                // ])}
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }
    const _goback = () => {
        const { pgnum } = match.params
        const { key } = match.params
        // console.log('go back called')
        // console.log(pgnum,'page')
        // manufacturerCmpListGetAction({ page:pgnum, size: 10,
        //     companyname: FilterState.cmpFilter.cmp || key, 
        //     city: FilterState.cmpFilter.city, 
        //     country: FilterState.cmpFilter.country,
        //     fromDate:String(FilterState.cmpFilter.fromDate),
        //     toDate: String(FilterState.cmpFilter.toDate), 
        //     status: FilterState.cmpFilter.status, 
        //     claimedstatus:FilterState.cmpFilter.claimedStatus,
        // })
        history.push('/manufacturer/comapanies/list')
    }
    const _didSupCmpEdit = () => {
        const { pgnum } = match.params
        const { key } = match.params
        // e.preventDefault()
        var supEditPhonecmp: any;
        if(display.phone === null||display.phone===''||display.phone===' '||display.mobileCode==null||display.mobileCode==''){
            supEditPhonecmp = '';
        }
        else{
            supEditPhonecmp =  String(display.mobileCode).concat(" ",display.phone);
        }
        const updatedForm = {
            id: Number(display.id),
            name: display.name,
            phone: supEditPhonecmp,
            address: display.address,
            email: display.email,
            website: display.website,
            status: display.status,
            ownerUser: '',
            type: String(display.type),
            city: String(display.city),
            year_of_establishment: display.year,
            ceo: display.ceo,
            chairman: display.chairman,
            directors: display.director,
            service: display.services,
            vendor_type: display.productType
        }
        // console.log(updatedForm,'dock company edit updated form')
        if (supcmpeditflag === true) {
            manufacturerService.updateManufactCompany(updatedForm).subscribe({
                next: () => {
                    swal("Great", "Industries Updated Successfully", "success")
                    history.push(`/manufacturer/comapanies/list`)
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    console.error(err)
                }
            })
        }
        else {
            console.log(supcmpeditflag, 'flag')
        }
    }
    onsubmit = () => {
        // e.preventDefault()
        console.log(Object.keys(errors).length, 'error length')
        if (Object.keys(errors).length === 0) {
            setSupCmpeditFlag(true)
            _didSupCmpEdit()
        }
        else {
            setSupCmpeditFlag(false)
        }
    }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    return (
        <Main>
            {display === null && (
                <>
                    <Space divheight={15} />
                    <Text color='main' variant='subtitle' center bold> There is No data for requested id</Text>
                    <Space divheight={15} />
                </>
            )}
            {display !== null && (
                <>
                    <div className='title-name-block'>
                        <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                            {display.company_logo === null && (
                                <Image src={noImg} alt='logo' roundedCircle />
                            )}
                            {display.company_logo === '' && (
                                <Image src={noImg} alt='logo' roundedCircle />
                            )}
                            {display.company_logo !== null && display.company_logo !== '' && (
                                <Image src={display.company_logo} alt='logo' roundedCircle />
                            )}
                        </div>
                        <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                            <label className="custom-file-upload">
                                <Image src={editicon} alt='logo' />
                                <input
                                    ref={upload}
                                    type="file"
                                    onChange={getBase64}
                                />
                            </label>
                        </div>
                        <Text variant='title' color='main' className='txt-capitalize'>
                            {/* {String(display.email).substring(0,String(display.email).indexOf('@'))} */}
                            {display.name}
                        </Text>
                        <Label className={display.status === 'UNVERIFIED' ? 'danger' : 'warning'} >
                            {display.status === 'UNVERIFIED' ? 'Not Verified' : 'Verified'}
                        </Label>
                    </div>
                    <Space divheight={15} />
                    <Row className='p-0 m-0'>
                        <Col xs='12' sm='12' md='12' lg='9' xl='9' className='p-0 m-0'>
                            <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='dockcmpAddForms'>
                                <Card title="Edit Industries Information">
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                            <Attributes>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Name</Text>
                                                    <Form.Control className='basecontrol custome-input'
                                                        type="text"
                                                        name="txt_supeditcmpName"
                                                        value={display.name}
                                                        onChange={(e) => setDisplay({ ...display, name: e.target.value })}
                                                        ref={register({ required: true, })}
                                                        placeholder='Company Name'
                                                    />
                                                </div>
                                                <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>{errors.txt_supeditcmpName?.type === 'required' && 'Please enter Dock Company name'}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Email</Text>
                                                    <Form.Control className='basecontrol custome-input'
                                                        type="text"
                                                        name="txt_supeditmpemail"
                                                        value={display.email}
                                                        onChange={(e) => setDisplay({ ...display, email: e.target.value })}
                                                        ref={register({ pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                        placeholder='Company email'
                                                    />
                                                </div>
                                                <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>
                                                            {/* {errors.txt_supeditmpemail?.type === 'required' && 'Please enter Company Email'} */}
                                                            {errors.txt_supeditmpemail?.type === 'pattern' && 'Please enter Valid Company Email'}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Website </Text>
                                                    <Form.Control className='basecontrol custome-input'
                                                        type="text"
                                                        name="txt_supeditcmpwebstite"
                                                        value={display.website}
                                                        onChange={(e) => setDisplay({ ...display, website: e.target.value })}
                                                        ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                        placeholder='Company Website'
                                                    />
                                                </div>
                                                <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>
                                                            {/* {errors.txt_supeditcmpwebstite?.type === 'required' && 'Please enter Company Website'} */}
                                                            {errors.txt_supeditcmpwebstite?.type === 'pattern' && 'Please enter Valid Company Website'}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                {display.user === null ? (
                                                    <></>
                                                ) : (
                                                    <>


                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>Services </Text>
                                                            <Form.Control className='basecontrol custome-input'
                                                                type="text"
                                                                name="txt_supeditservices"
                                                                value={display.services}
                                                                onChange={(e) => setDisplay({ ...display, services: e.target.value })}
                                                                //ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                                placeholder='Services'
                                                            />
                                                        </div>
                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>Year of Establishment </Text>
                                                            {/* <Form.Control className='basecontrol custome-input'
                                                                type="text"
                                                                name="txt_supedityear"
                                                                value={display.year}
                                                                onChange={(e) => setDisplay({ ...display, year: e.target.value })}
                                                                //ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                                placeholder='Year of Establishment'
                                                            /> */}
                                                            <input type="date" id="fromtime" name="fromtime" value={display.year}
                                                                onChange={(e) => _setFromDate(e.target.value)}>
                                                            </input>
                                                        </div>
                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>Chairman </Text>
                                                            <Form.Control className='basecontrol custome-input'
                                                                type="text"
                                                                name="txt_supeditchairman"
                                                                value={display.chairman}
                                                                onChange={(e) => setDisplay({ ...display, chairman: e.target.value })}
                                                                //ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                                placeholder='Chairman'
                                                            />
                                                        </div>
                                                    </>

                                                )}
                                            </Attributes>
                                        </Col>
                                        <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                            <Attributes>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Contact Number</Text>
                                                    <div className='phnnum-container'>
                                                        <Form.Control as="select" value={display.phone_code}
                                                            name='txt_ediCmpPhnCode'
                                                            className='basecontrol cutome-selectInputnxt phn-dp'
                                                            onChange={(e) => setDisplay({ ...display, phone_code: e.target.value })}
                                                        // ref={register({ required: true, })}
                                                        >
                                                            <option value="">Code</option>
                                                            {supCmpEditForm.mobileCode.map((data: any, i: any) => {
                                                                return <option key={i} value={data.mobile_code}>{data.country_name}</option>
                                                            })}
                                                        </Form.Control>
                                                        <Form.Control className='basecontrol custome-input'
                                                            type="text"
                                                            name="txt_supeditcmpPhn"
                                                            value={display.phone}
                                                            onChange={(e) => setDisplay({ ...display, phone: e.target.value })}
                                                            // ref={register({ required: true, pattern: /^\d{10}$/ })}
                                                            placeholder='Company Contact Number'
                                                        />
                                                    </div>
                                                </div>
                                                {/* <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>
                                                            {errors.txt_supeditcmpPhn?.type === 'required' && 'Please enter Company Contact Number'}
                                                            {errors.txt_supeditcmpPhn?.type === 'pattern' && 'Please enter valid Company Contact Number'}
                                                        </span>
                                                    </Col>
                                                </Row> */}
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Address</Text>
                                                    <Form.Control as="textarea"
                                                        className='basecontrol custome-textarea'
                                                        placeholder='Company Address'
                                                        rows={3}
                                                        cols={50}
                                                        name="txt_supeditcmpAddress"
                                                        value={display.address}
                                                        onChange={(e) => setDisplay({ ...display, address: e.target.value })}
                                                    // ref={register({ required: true, })}

                                                    />
                                                </div>
                                                {/* <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>{errors.txt_supeditcmpAddress?.type === 'required' && 'Please enter Company Address'}
                                                        </span>
                                                    </Col>
                                                </Row> */}
                                                {display.user === null ? (
                                                    <Text color='danger' variant='subtitle' className="no_user" bold>
                                                        No user is mapped to this company
                                                    </Text>
                                                ) : (
                                                    <>
                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>Director </Text>
                                                            <Form.Control className='basecontrol custome-input'
                                                                type="text"
                                                                name="txt_supeditdirector"
                                                                value={display.director}
                                                                onChange={(e) => setDisplay({ ...display, director: e.target.value })}
                                                                //ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                                placeholder='Director'
                                                            />
                                                        </div>
                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>CEO </Text>
                                                            <Form.Control className='basecontrol custome-input'
                                                                type="text"
                                                                name="txt_supeditceo"
                                                                value={display.ceo}
                                                                onChange={(e) => setDisplay({ ...display, ceo: e.target.value })}
                                                                //ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                                placeholder='CEO'
                                                            />
                                                        </div>
                                                    </>

                                                )}
                                            </Attributes>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm='12' md='8' xl='6' lg='6'>
                                            <Attributes>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>City Name</Text>
                                                    <Form.Control as="select" className='basecontrol cutome-selectInputnxt'
                                                        value={display.city} name='txt_supeditcity'
                                                        onChange={e => setDisplay({ ...display, city: e.target.value })}
                                                        ref={register({ required: true, })}>
                                                        {/* {console.log(display.cities)} */}
                                                        <option value="">-- Select --</option>
                                                        {/* Map the data's from company array and used as a option value. By Dharani on 04-Mar-2021 */}
                                                        {/* {console.log(display.cities)} */}
                                                        {supCmpEditForm.cities.map((data: any, i: any) => {
                                                            return <option key={i} value={data.id}>{data.name}</option>
                                                        })}
                                                    </Form.Control>
                                                    {console.log('city', display.city)}
                                                </div>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Status</Text>
                                                    <Form.Control as="select" className='basecontrol cutome-selectInputnxt'
                                                        value={display.status} name="txt_supeditcmpstatus"
                                                        onChange={(e) => setDisplay({ ...display, status: e.target.value })}
                                                        ref={register({ required: true, })}>
                                                        <option className='option-placeholder' value=''>-- Select --</option>
                                                        <option value="VERIFIED"> Verified</option>
                                                        <option value="UNVERIFIED"> Not verified</option>
                                                    </Form.Control>
                                                </div>
                                                <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>{errors.txt_supeditcmpstatus?.type === 'required' && 'Please Select Status'}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <div>
                                                            <Text color='tablehead' variant='subtitle'>Product Type</Text>
                                                            <Form.Control as="select" className='basecontrol cutome-selectInputnxt'
                                                                value={display.productType} name="txt_producttype"
                                                                onChange={(e) => setDisplay({ ...display, productType: e.target.value })}
                                                                ref={register({ required: true, })}>
                                                                <option className='option-placeholder' value=''>-- select --</option>
                                                                <option value="anode"> Anode</option>
                                                                <option value="paint"> Paint</option>
                                                            </Form.Control>
                                                        </div>
                                                        <Row className='m-0 p-0'>
                                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                            </Col>
                                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                                <span className='err-msg text-capitalize'>{errors.txt_producttype?.type === 'required' && 'Please Select Status'}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                            </Attributes>
                                        </Col>
                                    </Row>
                                </Card>
                                <Actions>
                                    <Button type="button" className='bttn bttn-transparent' onClick={() => _goback()}>
                                        <Flex jc='jc-around'>
                                            <Icons icon="arrow_back" />
                                            BACK
                                        </Flex>
                                    </Button>
                                    <Button className='bttn bttn-transparent' type="submit">
                                        <Flex>
                                            <Icons color="#ffffff" icon="check" />
                                            SAVE DATA
                                        </Flex>
                                    </Button>
                                </Actions>
                            </Form>
                        </Col>
                        <Col xs='12' sm='12' md='12' lg='3' xl='3' className='p-0 m-0'>
                            <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                                <Card title="Profile Picture" className='profile-title'>
                                    <div className='user-mainBlock'>
                                        {display.company_logo === null && (
                                            <div className='userEdit-block'>
                                                <Image src={noImg} alt='logo' />
                                            </div>
                                        )}
                                        {display.company_logo === '' && (
                                            <div className='userEdit-block'>
                                                <Image src={noImg} alt='logo' />
                                            </div>
                                        )}
                                        {display.company_logo !== null && display.company_logo !== '' && (
                                            <div className='userEdit-block'>
                                                <Image src={display.company_logo} alt='logo' />
                                            </div>
                                        )}
                                    </div>
                                    {((display.company_logo === null) || (display.company_logo === '')) && (
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    <Image src={editicon} alt='logo' />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    onChange={getBase64}
                                                />
                                            </label>
                                        </div>
                                    )}
                                    {display.company_logo !== null && display.company_logo !== '' && (
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    <Image src={editicon} alt='logo' className="editIconRemove" />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    onChange={getBase64}
                                                />
                                            </label>

                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    {/* <Icons color="#ffffff" icon="edit" /> */}
                                                    <Image src={removeicon} alt='logo' onClick={() => _removeManufacturerCompanyLogo(display.id)} />
                                                </Flex>
                                            </label>
                                        </div>
                                    )}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </Main>
    )

}

export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    company: state.manufacturer.selectedManufactCmp
})

export default connect(
    mapStateToProps,
    {
        manufacturerCmpSetSelectedAction,
        manufacturerCmpListGetAction
    }
)(ManufacturerCmpEdit)