import { map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getManufacturer = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/manufacturer/list/search',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getManufacturerDashboard = (params : any) => {
  // console.log('get ship type called in type service')
  console.log(params)
  return http({
    method: 'GET',
    url: `/manufacturer/list/search?`+params,
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addManufacturer = (body : any) => {
  return http({
    method: 'POST',
    url: '/subscription',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getManufacturerDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/manufacturer/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const updatManufactureAdsEdit = (body : any) => {
  return http({
    method: 'PUT',
    url: '/manufacturer/add_amount_edit',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const getManufacturerAdsDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/manufacturer/add_amount/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteManufacturer = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/userSubscription/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        //throw new Error(res)
      }
    })
  )
}

const updateManufacturer = (body : any) => {
  return http({
    method: 'PUT',
    url: '/manufacturer/update',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const getManufacturerCompany = (id : any) => {
  return http({
    method: 'GET',
    url: `/manufacturer/companies/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getManufacturerProduct = (id : any) => {
  return http({
    method: 'GET',
    url: `/manufacturer/products/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}


//Manufacturer Company  Services

const getManufactCompany = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/manufacturer/companies',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addManufactCompany = (body : any) => {
  return http({
    method: 'POST',
    url: '/companies',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getManufactCompanyDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/companies/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteManufactCompany = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/companies/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        //throw new Error(res)
      }
    })
  )
}

const updateManufactCompany = (body : any) => {
  return http({
    method: 'PUT',
    url: '/companies',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const updateManufactCompanyImage = (body : any) => {
  return http({
    method: 'POST',
    url: '/companies/companylogo',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

//pay per view list
const getPayPerViewList = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/manufacturer/expense_views',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}
//Transaction list
const getTransactionDetail = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/manufacturer/transaction_historys',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

//Quote List
const getQuotelist = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/priceQuotation/list',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in quotation service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const removeManufacturerCompanyLogo = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/companies/CompanyLogoAll/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const getQuoteDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/priceQuotation/view/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}
const manufacturerService = {
  getManufacturer,
  getManufacturerDashboard,
  addManufacturer,
  getManufacturerDetail,
  updateManufacturer,
  deleteManufacturer,
  getManufacturerCompany,
  getManufacturerProduct,
  getManufactCompany,
  addManufactCompany,
  getManufactCompanyDetail,
  deleteManufactCompany,
  updateManufactCompany,
  getManufacturerAdsDetail,
  updatManufactureAdsEdit,
  getPayPerViewList,
  getTransactionDetail,
  getQuoteDetail,
  getQuotelist,
  updateManufactCompanyImage,
  removeManufacturerCompanyLogo
}
  
export default manufacturerService