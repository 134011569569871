import {
  DATA_SHIP_PROPULSION_LIST_GET,
  DATA_SHIP_PROPULSION_LIST_GET_F,
  DATA_SHIP_PROPULSION_LIST_GET_R,
  DATA_SHIP_PROPULSION_SELECTED_SET,
  } from '../actionType'
  
  export const dataPropulsionListGetAction = (params : any) => ({
    type: DATA_SHIP_PROPULSION_LIST_GET,
    payload: params
  })
  
  export const dataPropulsionListGetFAction = (data : any) => ({
    type: DATA_SHIP_PROPULSION_LIST_GET_F,
    payload: data
  })
  
  export const dataPropulsionListGetRAction = ( error : any) => ({
    type: DATA_SHIP_PROPULSION_LIST_GET_R,
    payload: error
  })
  
  export const dataPropulsionSetSelectedAction = (propulsion : any) => ({
    type: DATA_SHIP_PROPULSION_SELECTED_SET,
    payload: propulsion
  })