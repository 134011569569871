import{SHIP_SUBTYPE_LIST_GET,
    SHIP_SUBTYPE_LIST_GET_F,
    SHIP_SUBTYPE_LIST_GET_R,
    SHIP_SUBTYPE_SELECTED_SET
} from '../actionType'

export const shipSubTypeListGetAction = (params : any) => ({
    type: SHIP_SUBTYPE_LIST_GET,
    payload: params
})

export const shipSubTypeListGetFAction = (params : any) => ({
    type: SHIP_SUBTYPE_LIST_GET_F,
    payload: params
})

export const shipSubTypeListGetRAction = (params : any) => ({
    type: SHIP_SUBTYPE_LIST_GET_R,
    payload: params
})

export const shipSubTypeSetSelectedAction = (subType : any) => ({
    type: SHIP_SUBTYPE_SELECTED_SET,
    payload: subType
})