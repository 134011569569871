import React, { useCallback } from 'react';
import './DockOwner.scss'
import * as Observable from 'rxjs'
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  filter,
  map,
  tap
} from 'rxjs/operators'
import { Main } from '../../../common/components/main/Main'
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap'
import { Table } from '../../../common/components/table/Table'
import { Button } from '../../../common/components/button/Button'
import { Text } from '../../../common/components/text/Text'
import Card from '../../../common/components/card/Card'
import { Flex } from '../../../common/components/flex/Flex'
import { Space } from '../../../common/components/space/Space'
import Icons from '../../../common/components/icon/Icon';
import { history } from '../../../store'
import classNames from 'classnames';
import { dockOwnerListGetAction, dockOwnerSetSelectedAction } from '../../../store/action/dockActions';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns'
import { dockService } from '../../../services/dockService';
import { ReactNode } from 'react';
import swal from 'sweetalert'
import { InputWithIcon, Select } from '../../../common/components/control/Control';
import DockOwnerFilter from './pageContainer/dockOwnerFilter'
import { FilterState } from '../filter/filterState'
import Loader from 'react-loader-spinner';
import Filterimg from '../../../assets/images/Filter 2.png'
import sortup from '../../../assets/images/sortup_icon.png'
import sortdown from '../../../assets/images/sortdown_icon.png'
import sortimage from '../../../assets/images/sorting.png'
import dataEngReducer from '../../../store/reducer/dataEngReducer';
import NumberFormat from "react-number-format";

let direction = 'ascending';

const DockOwnerList = (props: any) => {

  const { docks, isLoading, totalElement } = props
  const [displayDocks, setDocks] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [searchQuery, setQuery] = React.useState('')
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const [showModal, setModal] = React.useState(false)
  const [searchInput, setSearchInput] = React.useState(FilterState.ownerFilter.searchBoxInputOwner)
  const [searchDEmail, setSearchDEmail] = React.useState(FilterState.ownerFilter.searchBoxInputEmail)
  const perPage = 9
  const [findBy, setFindBy] = React.useState(FilterState.ownerFilter.DockOwnerListfindbyid)
  const [findBySort, setFindBySort] = React.useState(FilterState.ownerFilter.DockOwnerListfindbysort)
  var totalPage = Math.ceil(totalElement / 10)
  const [showDelete, setShow] = React.useState(false);
  const [deleteDockOwner, setDeleteDockOwner] = React.useState(0)
  const [sortimg, setSortimg] = React.useState(sortup)
  const [sort, setSorting]=React.useState({values:FilterState.ownerFilter.type,direction:FilterState.ownerFilter.direction})
  

  React.useEffect(() => {
    props.dockOwnerListGetAction({
      size: 10, page, email: searchDEmail||FilterState.ownerFilter.searchBoxInputEmail,
      name: FilterState.ownerFilter.name || searchInput ||FilterState.ownerFilter.searchBoxInputOwner,
      fromDate: String(FilterState.ownerFilter.fromDate),
      toDate: String(FilterState.ownerFilter.toDate),
      subscriptionstatus: FilterState.ownerFilter.toDate,
      type:FilterState.ownerFilter.type,
      direction:FilterState.ownerFilter.direction,
    })
  }, [page,sort])

  const filterByValue = (sortvariable: string) => {
    // console.log(sortvariable,'sort by')
    props.dockOwnerListGetAction({ size: 10, page, q: sortvariable })
  }

  const _willFilter = () => {
    setModal(true)
  }

  React.useEffect(() => {
    if (docks && docks.length > 0) {
      setDocks(() => {
        return docks.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (docks && docks.length === 0) {
      setDocks([])
    }
  }, [docks])

  React.useEffect(() => {
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  const _searchFuntion = (value: string) => {
    setSearchInput(value)
    FilterState.ownerFilter.searchBoxInputOwner=value;
    props.dockOwnerListGetAction({
      page: 0, size: 10, name: value, email: searchDEmail,
      fromDate: String(FilterState.ownerFilter.fromDate),
      toDate: String(FilterState.ownerFilter.toDate),
      subscriptionstatus: FilterState.ownerFilter.toDate,
    })
    setPage(0)
  }

  const _searchMailFuntion = (value: string) => {
    setSearchDEmail(value)
    FilterState.ownerFilter.searchBoxInputEmail=value;
    props.dockOwnerListGetAction({
      page: 0, size: 10, name: FilterState.ownerFilter.name || searchInput, email: value,
      fromDate: String(FilterState.ownerFilter.fromDate),
      toDate: String(FilterState.ownerFilter.toDate),
      subscriptionstatus: FilterState.ownerFilter.toDate,
    })
  }
  const _showAll = () => {
    props.dockOwnerListGetAction({ page: 0, size: 10 })
    setSearchInput('')
    setSearchDEmail('')
    FilterState.ownerFilter.searchBoxInputOwner=''
    FilterState.ownerFilter.searchBoxInputEmail=''
    FilterState.ownerFilter.DockOwnerListfindbysort=''
    FilterState.ownerFilter.DockOwnerListfindbyid=''
    FilterState.ownerFilter.page = 0
    FilterState.ownerFilter.size = 10
    FilterState.ownerFilter.fromDate = ''
    FilterState.ownerFilter.toDate = ''
    FilterState.ownerFilter.Subscriptionstatus = ''
    setSorting({values:'',direction:''})

  }

  const _getSearchValue = (val: string) => {
    setFindBy(val)
    FilterState.ownerFilter.DockOwnerListfindbyid=val;

  }
  const _getSortValue = (val: string) => {
    setFindBySort(val)
    FilterState.ownerFilter.DockOwnerListfindbysort=val;
    props.dockOwnerListGetAction({ size: 10, page, q:FilterState.ownerFilter.DockOwnerListfindbysort  })
  }

  const _willViewDetail = (dock: any) => {
    dockOwnerSetSelectedAction(dock)
    history.push(`/docks-owner/${dock.id}`)
  }

  const _willViewEdit = (dock: any) => {
    dockOwnerSetSelectedAction(dock)
    history.push(`/docks/edit/${dock.id}`)
  }

  const _willCloseModal = () => {
    setModal(false)
  }

  // Delete the pericular list.
  const handleClose = () => setShow(false);
  const handleShow = (owner: any) => {
    setShow(true);
    setDeleteDockOwner(owner.id)
    // console.log(deleteAnode, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deleteDockOwner(deleteDockOwner)
  }

  const _deleteDockOwner = (id: any) => {
    return dockService.deleteDockOwner(id).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.dockOwnerListGetAction({ size: 10, page })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }

  const role=localStorage.getItem("role")

  const _addSearchHandlers = useCallback(
    () => {
      // console.log('search called')
      const searchInput: HTMLElement | any = document.getElementById('search-input')
      // console.log(searchInput,'searchinput')
      const searchHandler$ = Observable.fromEvent(searchInput, 'keyup').pipe(
        map((e: any) => e.target.value),
        filter(query => query.length >= 4 || query.length === 0),
        debounceTime(750),
        distinctUntilChanged(),
        tap(q => {
          setQuery(q)
        }),
        switchMap(() => Observable.of(props.dockOwnerListGetAction({ size: 10, page: 0 })))
      )
      return searchHandler$.subscribe()
    }, [page, searchQuery]
  );

  const _goPrevPage = () => {
    setPage(prev => {
      return (prev -= 1)
    })
  }

  const _goNextPage = () => {
    setPage(prev => {
      return (prev += 1)
    })
  }

  const _goToPage = (page: any) => {
    setPage(page)
  }

  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 10)
  }

  const _goToFirstPage = () => {
    setPage(0)
    setHigh(10)
    setLow(1)
  }

  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  const nextBtn = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  const ListItem = (page: any) => {
    return page.value + 1
  }

  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0) || (p === 1 && page === 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })

  const sortByFieldn=(value:String,directions:String)=>{
    if(directions==='asc')
    {
      setSorting({values:String(value),direction:String(directions)});
      FilterState.ownerFilter.type=String(value);
      FilterState.ownerFilter.direction=String(directions);
      props.dockOwnerListGetAction({
        page: 0, size: 10, name: value, email: searchDEmail,
        fromDate: String(FilterState.ownerFilter.fromDate),
        toDate: String(FilterState.ownerFilter.toDate),
        subscriptionstatus: FilterState.ownerFilter.toDate,
        type:value,
        direction:directions,
      })
      setPage(0) 
    }
    else if(directions==='desc')
    {
      setSorting({values:String(value),direction:String(directions)});
      FilterState.ownerFilter.type=String(value);
      FilterState.ownerFilter.direction=String(directions);
      props.dockOwnerListGetAction({
        page: 0, size: 10, name: value, email: searchDEmail,
        fromDate: String(FilterState.ownerFilter.fromDate),
        toDate: String(FilterState.ownerFilter.toDate),
        subscriptionstatus: FilterState.ownerFilter.toDate,
        type:value,
        direction:directions,
      })
      setPage(0)
    }
    else if(directions==='')
    {
      setSorting({values:String(value),direction:String(directions)});
      FilterState.ownerFilter.type=String(value);
      FilterState.ownerFilter.direction=String(directions);
      props.dockOwnerListGetAction({
        page: 0, size: 10, name: value, email: searchDEmail,
        fromDate: String(FilterState.ownerFilter.fromDate),
        toDate: String(FilterState.ownerFilter.toDate),
        subscriptionstatus: FilterState.ownerFilter.toDate,
        type:value,
        direction:directions,
      })
      setPage(0)
    }
    };
  


  return (
    <Main>
      <Text variant="title">DryDock Owner List</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-3 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    {findBy === '' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Name.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === 'name' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Name.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === 'dEmail' && (
                      <InputWithIcon
                        type="text"
                        value={searchDEmail}
                        onChange={(e) => _searchMailFuntion(e.target.value)}
                        placeholder="Search by Email..."
                        icon="search"
                        id="search-input2"
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <div className='searchOwner-btn-container'>
                <div className='searchOwner-btn-add'>
                  <Select values={findBy} name="" onChange={(e) => _getSearchValue(e.target.value)} >
                    <option value=''>-- Search by --</option>
                    <option value="name" > By Name </option>
                    <option value="dEmail"> By Email </option>
                  </Select>
                </div>
               {/*} <div className='searchOwner-btn-radio'>
                  <label>Sort by: </label> &nbsp; 
                  <input type="radio" value="asc" name="sort" /> A - Z &nbsp;
                  <input type="radio" value="desc" name="sort" /> Z - A &nbsp;
                </div>  */}           
                {/* <div className='searchOwner-btn-sortby'>
                  <Select  values={findBySort} name=""  onChange={(e) => _getSortValue(e.target.value)}>
                    <option value=''>-- Sort by --</option>
                    <option value="asc"> Ascending </option>
                    <option value="desc" > Descending </option>
                  </Select>
                </div> */}
                      <div className='searchOwner-btn-filter'>
                  <Button className='link_button filter' onClick={() => _willFilter()}>
                    <img src={Filterimg} alt='filterimg' />
                  </Button>
                </div>
                <div className='searchOwner-btn-showall'>
                  <Button variant='transparent' block={true} onClick={() => _showAll()}>
                    Show All
                  </Button>
                </div>
                
              </div>
            </Col>
          </Row>
        </SearchForm>
        <Space divheight={30} />
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>}
        {!isLoading && displayDocks.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No dock Owner found</Text>
            <Space divheight={20} />
          </>
        )}
        {!isLoading && displayDocks.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>
                    Name   &nbsp;
                    {sort.values==='name' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('name','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='name' ? (
                     <img onClick={() => sortByFieldn('name','asc')} src={sortimage}/>):(null)}
                     {sort.values==='name' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  {/* <th>Join Date</th> */}
                  <th>
                    Email
                    &nbsp;
                    {sort.values==='email' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('email','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='email' ? (
                     <img onClick={() => sortByFieldn('email','asc')} src={sortimage}/>):(null)}
                     {sort.values==='email' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>company Name &nbsp;
                  {sort.values==='company' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('company','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='company' ? (
                     <img onClick={() => sortByFieldn('company','asc')} src={sortimage}/>):(null)}
                     {sort.values==='company' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>
                    NaviCoins
                    &nbsp;
                    </th>
                    {/* {sort.values==='status' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('status','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='status' ? (
                     <img onClick={() => sortByFieldn('status','asc')} src={sortimage}/>):(null)}
                     {sort.values==='status' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
        
                  </th>
                  */}
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {displayDocks.map((dock: any, i: any) => [
                  <tr key={dock.id}>
                   
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='First Name'>{dock.firstname}</td>
                    {/* <td data-label='Join Date'> 22 nd Nov 3.04
                      {format(parseISO(dock.created_at), 'dd MMM yy, hh:mm')}
                    </td> */}
                    <td data-label='Email'>
                      {dock.email === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ):(
                        <Text className='txt-table-date' color='tabledata'>{dock.email}</Text>
                      )}
                    </td>
                    <td data-label='Company Name'>{dock.company_name === null ? (
                      <Text className='txt-table-date' color='tabledata'> - </Text>
                    ) : (
                      <Text className='txt-table-date' color='tabledata'>{dock.company_name}</Text>
                    )}
                    </td>
                    {/* <td data-label='Subscription Status'>{dock.subscriptionStatus === 'Yes' ? (
                      <Text className='txt-table-date' color='warning'>Subscribed</Text>
                    ) : (
                      <Text className='txt-table-date' color='danger'>Not Subscribed</Text>
                    )}
                    </td> */}
                    <td data-label='NaviCoin'>{dock.available_coins === null ? (
                      <Text className='txt-table-date' color='tabledata'> - </Text>
                    ) : (
                      // <Text className='txt-table-date' color='tabledata'>{data.available_coins}</Text>
                      <NumberFormat value={dock.available_coins} displayType={'text'} thousandSeparator={true} />
                    )}
                    </td>
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="dockOwner-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(dock)}>
                            <Icons color="#52575a" icon="visibility" />
                          </Button>
                        </OverlayTrigger>
                        {/* <OverlayTrigger overlay={
                          <Tooltip id="dockOwner-edit"> Edit
                          </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(dock)}>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                        </OverlayTrigger> */}
                         {role === 'VISITOR' ? (
                        <OverlayTrigger overlay={
                          <Tooltip id="dockOwner-delete"> Delete option is not available
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => handleShow(dock)} disabled>
                            <Icons color="#52575a" icon="delete" />
                          </Button>
                        </OverlayTrigger>
                         ):(
                          <OverlayTrigger overlay={
                            <Tooltip id="dockOwner-delete"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(dock)}>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                         )}
                      </TableActions>
                    </td>
                  </tr>
                ])}
              </tbody>
            </Table>
            <TableFooter>
              <Text>
                {displayDocks.length === 1 && (
                  <>
                    <b>
                      {displayDocks.length}
                      {/* {((page) * 10) + 1} - {(displayDocks.length + (page) * 10)} */}
                    </b> of {totalElement} Dock Owner
                  </>
                )}
                {displayDocks.length > 1 && (
                  <>
                    <b>
                      {displayDocks.length}
                      {/* {((page) * 10) + 1} - {(displayDocks.length + (page) * 10)} */}
                    </b> of {totalElement} Dock Owners
                  </>
                )}

              </Text>
              <Pagination size="sm">
                {firstPage()}
                {prevBtn()}
                {PageNumber}
                {nextBtn()}
                {lastPage()}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form Dock Owner list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {showModal && <DockOwnerFilter onClose={() => _willCloseModal()} />}
    </Main>
  )
}

export interface TablefooterProps {
  children?: React.ReactNode
}

const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

//Search Form Component  for displaying serarch box, and add ship button
const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

const mapStateToProps = (state: any) => ({
  docks: state.dock.owners,
  isLoading: state.dock.isLoading,
  error: state.dock.error,
  totalElement: state.dock.totalElement,
  totalPage: state.dock.totalPage
})

export default connect(
  mapStateToProps,
  {
    dockOwnerListGetAction,
    dockOwnerSetSelectedAction
  }
)(DockOwnerList)