import {
    CMP_TYPE_LIST_GET,
}  from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import {
    cmpTypeListGetFAction,
    cmpTypeListGetRAction,
} from '../action/cmpTypeAction'
import typeService from '../../services/typeService'

export const getcmpTypeEpics = (action : any) => {
  // console.log('get role epic called')
  return action.pipe(
    ofType(CMP_TYPE_LIST_GET),
    switchMap((action : any) => {
      return typeService.getCmpType(action.payload).pipe(
        map((res :any) => {
        // console.log(res,'response for country epics')
        const { content, total_page, total_element } = res.data
        // console.log(content,'country content')
        return cmpTypeListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(cmpTypeListGetRAction(err)))
      )
    })
  )
}