import React, { useState } from 'react'

    const Userlistfilter={
        searchBoxInputName:'',
        searchBoxInputEmail:'',
        searchBoxInputMobile:'',
        searchBoxInputRole:'',
        searchBoxInputStatus:'',
        findbyIdName:''
    }

    export const UserFilterState={
        Userlistfilter

    }