import {
    ROLE_LIST_GET,
}  from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import {
    roleListGetFAction,
    roleListGetRAction,
} from '../action/roleAction'
import RoleService from '../../services/roleService'

export const getRoleEpic = (action : any) => {
  // console.log('get role epic called')
  return action.pipe(
    ofType(ROLE_LIST_GET),
    switchMap((action : any) => {
      return RoleService.getRole(action.payload).pipe(
        map((res :any) => {
          const { content, total_page, total_element } = res.data
          return roleListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(roleListGetRAction(err)))
      )
    })
  )
}