import { ofType } from 'redux-observable'
import {
  DATA_SOCIAL_REPORT_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  reportListGetFAction,
  reportListGetRAction

} from '../action/reportAction'
import reportService from '../../services/reportService'

export const getReportEpic = (action$ : any) => {
  return action$.pipe(
    ofType(DATA_SOCIAL_REPORT_LIST_GET),
    switchMap((action : any) => {
      return reportService.getReport(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in shipTypeEpic')
          const { data } = res
          const { content, total_page, total_element } = data
           console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')

          return reportListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(reportListGetRAction(err)))
      )
    })  
  )
}