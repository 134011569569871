import{SHIP_CLS_LIST_GET,
    SHIP_CLS_LIST_GET_F,
    SHIP_CLS_LIST_GET_R,
    SHIP_CLS_SELECTED_SET
} from '../actionType'

export const shipClassificationListGetAction = (params : any) => ({
    type: SHIP_CLS_LIST_GET,
    payload: params
})

export const shipClassificationListGetFAction = (params : any) => ({
    type: SHIP_CLS_LIST_GET_F,
    payload: params
})

export const shipClassificationListGetRAction = (params : any) => ({
    type: SHIP_CLS_LIST_GET_R,
    payload: params
})

export const shipClassificationSetSelectedAction = (classi : any) => ({
    type: SHIP_CLS_SELECTED_SET,
    payload: classi
})