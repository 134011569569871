import { connect } from "react-redux";
import { anodeSetSelectedAction } from '../../../../store/action/anodeAction'
import React from "react";
import { Col, Form, Row } from 'react-bootstrap'
import { Text } from '../../../../common/components/text/Text'
import { Main } from "../../../../common/components/main/Main";
import { Space } from "../../../../common/components/space/Space";
import Card from "../../../../common/components/card/Card";
import { Grid } from "../../../../common/components/grid/Grid";
import { Attributes } from "../../../../common/components/attribute/Attribute";
import classNames from "classnames";
import { Flex } from "../../../../common/components/flex/Flex";
import { Link } from "react-router-dom";
import { Button } from "../../../../common/components/button/Button";
import Icons from "../../../../common/components/icon/Icon";
import anodeService from '../../../../services/anodeService'
import { Label } from "../../../../common/components/label/Label";
import { format, parseISO } from 'date-fns'
import Loader from "react-loader-spinner";
import codeService from "../../../../services/codeService";
import NumberFormat from "react-number-format";

const AnodeDetail = (props: any) => {
    const { match } = props;
    const { id } = match.params;
    const { cmpId } = match.params;
    const { supId } = match.params;
    const { anode } = props;
    const [isLoading, setLoading] = React.useState(true);
    const [display, setDisplay] = React.useState<any>({
        name: '',
        anode_weight: '',
        sea_chest_anode_weight: '',
        price: '',
        electro_chemical: ''
    })
    const [displayCmp, setDisplayCmp] = React.useState<any>([])
    const [displayOwner, setDisplayOwner] = React.useState<any>({
        ownerName: '',
        phone: '',
        phoneCode: '',
        status: '',
        regDate: '',
    })
    //Call perticular ship detail function.  
    React.useEffect(() => {
        // console.log(anode,'selected anode')
        _getAnodeDetail(id, cmpId, supId);
        _getProductCompanyDetail(supId);
        _getProductSupplierDetail(supId);
    }, [])

    //Get perticular ship detail using match.params. 
    const _getAnodeDetail = (id: any, companyId: any, supplierId: any) => {
        // console.log(id,'id')
        setLoading(true)
        return anodeService.getAnodeDetail({ company_id: companyId, anode_id: id, supplier_id: supplierId }).subscribe({
            next: r => {
                const { data } = r
                props.anodeSetSelectedAction(r.data)
                // setDisplay(r.data[0])
                setDisplay({
                    anode_name: r.data[0].anode_name,
                    anode_weights: r.data[0].anode_weights,
                    sea_chest_anodeweight: r.data[0].sea_chest_anodeweight,
                    price: r.data[0].price,
                    electro_chemical: r.data[0].electro_chemical
                })
                setLoading(false)

            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }
    const _getProductCompanyDetail = (id: any) => {
        setLoading(true)
        return codeService.getAllCompanyDetail(id).subscribe({
            next: r => {
                if (r.data && r.data.length > 0) {
                    setDisplayCmp(() => {
                        return r.data.map((s: any) => ({ ...s, checked: false }))
                    })
                }
                if (r.data && r.data.length === 0) {
                    setDisplayCmp([])
                }
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    const role=localStorage.getItem("role")

    const _getProductSupplierDetail = (id: any) => {
        setLoading(true)
        return codeService.getsupplierDetail(id).subscribe({
            next: r => {
                // console.log(r.data, 'supplier detail');
                setDisplayOwner({
                    ownerName: r.data.username,
                    phone: r.data.phone,
                    phoneCode: r.data.phone_code,
                    status: r.data.status,
                    regDate: r.data.register_date,
                })
                setLoading(false)

            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    // If isloading is true print a text as loading. 
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    // If isloading is false print the details of perticular ship. 
    return (
        <Main>
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                <Col sm='12' md='12' lg='7' xl='7' className='m-0 p-0 align-top'>
                    <Card title="Anode Detail">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='7' xl='7' className='p-0 m-0'>
                                <Attributes className='singleFrame'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Anode</Text>
                                        {display.anode_name !== null ? (
                                            <Text color='main' variant='subtitle' bold >{display.anode_name}</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold > - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Anode Weight (Kg)</Text>
                                        {display.anode_weights !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.anode_weights} Kg</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold > - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Sea chest's <br/>Anode Weight (Kg)</Text>
                                        {display.sea_chest_anodeweight !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.sea_chest_anodeweight} Kg</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold > - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Price per Kg (Rp.)</Text>
                                        {display.price !== null ? (
                                            <>
                                                {!String(display.price).includes('.') && (
                                                    <Text color='main' variant='subtitle' bold>                                                        
                                                        <NumberFormat value={String(display.price)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />.00
                                                    </Text>
                                                )}
                                                {String(display.price).includes('.') && (
                                                    <Text color='main' variant='subtitle' bold>
                                                        <NumberFormat value={display.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                                                    </Text>
                                                )
                                                }
                                            </>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold > - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Electrochemical Efficiency</Text>
                                        {display.electro_chemical !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.electro_chemical}</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold > - </Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm='12' md='12' lg='5' xl='5' className='m-0 p-0 align-top'>
                    {/* <div className='ml-xl-3  mt-sm-4 mt-md-4 mt-lg-4 mt-xl-0 view_page_xs_margin'>

                    </div> */}
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Card title="Supporting Owner Detail">
                            <Attributes className='singleFrame'>
                                <div>
                                    <Text color='main' variant='subtitle'>Owner Name</Text>
                                    {displayOwner.ownerName !== null ? (
                                        <Text color='main' variant='subtitle' bold >{displayOwner.ownerName}</Text>
                                    ) : (
                                        <Text color='main' variant='subtitle' bold > - </Text>
                                    )}
                                </div>
                                <div>
                                    <Text color='main' variant='subtitle'>Register Date</Text>
                                    {displayOwner.regDate !== null ? (
                                        <Text color='main' variant='subtitle' bold>
                                            {/* {format(parseISO(displayOwner.regDate), 'dd/MM/yyyy')} */}
                                            {displayOwner.regDate}
                                        </Text>
                                    ) : (
                                        <Text color='main' variant='subtitle' bold > - </Text>
                                    )}
                                </div>
                                <div>
                                    <Text color='main' variant='subtitle'>Mobile</Text>
                                    {displayOwner.phone === '' || null ? (
                                        <Text color='main' bold variant='subtitle'> - </Text>
                                    ) : (
                                        <>
                                            {displayOwner.phoneCode === '' ? (
                                                <Text color='main' bold variant='subtitle'>{displayOwner.phoneCode}
                                                    <span>
                                                        <Text color='main' bold variant='subtitle'>{displayOwner.phone}</Text>
                                                    </span>
                                                </Text>
                                            ) : (
                                                <>
                                                    <Text color='main' bold variant='subtitle'>{displayOwner.phoneCode} &nbsp;
                                                        <span>
                                                            <Text color='main' bold variant='subtitle'>{displayOwner.phone}</Text>
                                                        </span>
                                                    </Text>
                                                </>
                                            )
                                            }
                                        </>
                                    )}
                                </div>
                                <div>
                                    <Text color='main' variant='subtitle'>Status</Text>
                                    {displayOwner.status !== 'VERIFIED' ? (
                                        <Text color='danger' variant='subtitle' bold>Not Verified</Text>
                                    ) : (
                                        <Text color='success' variant='subtitle' bold >Verified</Text>
                                    )}
                                </div>
                            </Attributes>
                        </Card>
                    </div>
                </Col>
            </Row>
            <Row className='m-0 p-0 mt-2 mt-sm-2 mt-md-2 mt-lg-1 mt-xl-1'>
                <Col sm='12' md='12' lg='7' xl='7' className='m-0 p-0 align-top'>
                    <Card title="Industries Information">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                {displayCmp.length === 0 && (
                                    <div>
                                        <Space divheight={20} />
                                        <Text center>  Not Associated with any Company Yet  </Text>
                                        <Space divheight={20} />
                                    </div>
                                )
                                }
                                {displayCmp.map((data: any, i: any) => (
                                    data.length !== 0 && (
                                        <Attributes id={data.company_id} className='singleFrame'>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Name</Text>
                                                {data.company_name !== null ? (
                                                    <Text color='main' variant='subtitle' bold >{data.company_name}</Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold > - </Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>City Name </Text>
                                                {data.city_name !== null ? (
                                                    <Text color="main" variant='subtitle' bold>{data.city_name}</Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold > - </Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Country Name </Text>
                                                {data.country_name !== null ? (
                                                    <Text color="main" variant='subtitle' bold>{data.country_name}</Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold > - </Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Status</Text>
                                                {data.status !== 'VERIFIED' ? (
                                                    <Text color="danger" variant='subtitle' bold>Not Verified</Text>
                                                ) : (
                                                    <Text color='success' variant='subtitle' bold > Verified </Text>
                                                )}
                                            </div>
                                        </Attributes>
                                    )
                                ))}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='7' xl='7'>
                    <Actions>
                        {/* If you click the back button its is redirect to ships list page.  */}
                        <Link to='/anode/list'>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the edit button its is redirect to ships edit page.  */}
                        {role === 'VISITOR' ? (
                    //    <Link to={`/anode/edit/${id}/${cmpId}/${supId}/${displayOwner.ownerName}`}>
                            <Button variant='transparent' type="submit" disabled>
                                <Flex>
                                    <Icons color="#ffffff" icon="edit" />
                                    EDIT DATA
                                </Flex>
                            </Button>
                    //    </Link>
                        ):(
                            //  <Link to={`/anode/edit/${id}/${cmpId}/${supId}/${displayOwner.ownerName}`}>
                            <Button variant='transparent' type="submit" disabled>
                                <Flex>
                                    <Icons color="#ffffff" icon="edit" />
                                    EDIT DATA
                                </Flex>
                            </Button>
                        //</Link>
                        )}
                    </Actions>
                </Col>
            </Row>
        </Main>

    )
}
export interface ActionProps {
    children?: React.ReactNode
}

const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}


const mapStateToProps = (state: any) => ({
    anode: state.anode.selectedAnode
})

// connect to shipTypeSetSelectedAction and get the one perticular ship data. 
export default connect(
    mapStateToProps,
    {
        anodeSetSelectedAction
    }
)(AnodeDetail)