import {
    PRODUCT_TYPE_LIST_GET,
    PRODUCT_TYPE_LIST_GET_F,
    PRODUCT_TYPE_LIST_GET_R,
    PRODUCT_TYPE_SELECTED_SET
}  from '../actionType'

const initialState = {
    productType: [],
    isLoading: false,
    error: null,
    selectedProductType: null,
}

export default function productTypeReducer (state = initialState, action:any)  {
    switch(action.type) {
        case PRODUCT_TYPE_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            } 
        case PRODUCT_TYPE_LIST_GET_F:
            return {
                ...state,
                isLoading: false,
                productType: action.payload.data,
            }
        case PRODUCT_TYPE_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case PRODUCT_TYPE_SELECTED_SET:
            return {
                ...state,
                selectedProductType: action.payload
            }
        default:
            return state
    }
}