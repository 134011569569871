import React, { useState } from 'react';
import { FilterState } from '../../../pages/docks/filter/filterState';
import { UserFilterState } from '../../../pages/listOfUsers/filter/UserFilterState';
import { ManufactFilterState }  from '../../../pages/manufacturer/filter/ManufactFilter';
import { AnodefilterState } from '../../../pages/master/anodes/filter/AnodefilterState';
import { PaintfilterState } from '../../../pages/master/paint/filter/PaintfilterState';
import { ShipFilterState } from '../../../pages/ship/filter/ShipFilterState';

export const  clearfilter =()=>{
FilterState.cmpFilter.fromDate = ''
FilterState.cmpFilter.searchBoxInputCmp=''
FilterState.cmpFilter.toDate = ''
FilterState.cmpFilter.status = ''
FilterState.cmpFilter.claimedStatus = ''
FilterState.cmpFilter.country = ''
FilterState.cmpFilter.city = ''
FilterState.cmpFilter.cmp = ''
FilterState.cmpFilter.countryNames = ''
FilterState.dockFilter.searchBoxInputDock=''
FilterState.ownerFilter.DockOwnerListfindbyid=''
FilterState.ownerFilter.DockOwnerListfindbysort=''
FilterState.ownerFilter.searchBoxInputEmail=''
FilterState.ownerFilter.searchBoxInputOwner=''
UserFilterState.Userlistfilter.findbyIdName=''
UserFilterState.Userlistfilter.searchBoxInputEmail=''
UserFilterState.Userlistfilter.searchBoxInputMobile=''
UserFilterState.Userlistfilter.searchBoxInputName=''
UserFilterState.Userlistfilter.searchBoxInputRole=''
UserFilterState.Userlistfilter.searchBoxInputStatus=''
ManufactFilterState.ManufactCmpFilter.searchBoxInputManufactCmp=''
ManufactFilterState.Manufactlistfilter.findbyIdname=''
ManufactFilterState.Manufactlistfilter.searchBoxInputManufactEmail=''
ManufactFilterState.Manufactlistfilter.searchBoxInputManufactStatus=''
ManufactFilterState.Manufactlistfilter.searchBoxInputManufactlist=''
AnodefilterState.Anodefilter.findbyIdname=''
AnodefilterState.Anodefilter.searchBoxInputCmp=''
AnodefilterState.Anodefilter.searchBoxInputName=''
AnodefilterState.Anodefilter.searchBoxInputOwner=''
PaintfilterState.Paintfilter.findbyIdname=''
PaintfilterState.Paintfilter.searchBoxInputCmp=''
PaintfilterState.Paintfilter.searchBoxInputName=''
PaintfilterState.Paintfilter.searchBoxInputOwner=''
ShipFilterState.shipOwnerFilter.findbyIdname=''
ShipFilterState.shipOwnerFilter.searchBoxInputEmail=''
ShipFilterState.shipOwnerFilter.searchBoxInputName=''
ShipFilterState.shipMaterialfilter.searchBoxInputShipmaterial=''
ShipFilterState.shipFilter.serachBoxInputShip=''
ShipFilterState.shipCmpFilter.serachBoxInputCompany=''
localStorage.removeItem("searchInput")
//localStorage.removeItem("searchInpu")
//window.location.reload()
}