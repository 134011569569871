import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import { Radio } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import swal from 'sweetalert';
import subscriptionService from '../../../../services/subscriptionService'
import '../../../../styles/variables/Common.scss'
import codeService from '../../../../services/codeService'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner'

interface SubscriptionProps {
  name: string;
  description: string,
  duration_in_month: string | number,
  price: string | number,
  isactive: boolean
}

interface FormData {
  txt_subname: string;
  txt_subdescription: string,
  txt_duration_in_month: string,
  txt_subprice: string,
  radio_subisactive: boolean
}

export const SubscriptionAdd = (props: SubscriptionProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const [loading, setLoading] = React.useState(false)
  const [subsaddflag, setsubsAddFlag] = React.useState(false)
  const [subscription, setSubscription] = useState<SubscriptionProps>({
    name: '',
    description: '',
    duration_in_month: '',
    price: '',
    isactive: false
  },
  )

  // After enter all details by user and click submit button values assign to some variable then it is used as input parameter for addsubscription api call. 
  const _didsubscriptionAdd = () => {
    // e.preventDefault()
    const updatedForm = {
      name: subscription.name,
      description: subscription.description,
      duration_in_month: Number(subscription.duration_in_month),
      price: Number(subscription.price),
      isactive: subscription.isactive,
    }

    // API call for addsubscription with data, added to list. 
    // console.log(updatedForm,"updatedform")
    if (subsaddflag === true) {
      setLoading(true)
      subscriptionService.addSubscription(updatedForm).subscribe({
        next: (r) => {
          // console.log(r,'response')
          swal("Great", "Subscription Added Successfully", "success")
          history.push('/subscription/list')
          setLoading(false)
        },
        error: err => {
          swal("OOPS", String(err), "error")
          setLoading(false)
        }
      })
    }
    else {
      console.log('Anode add Flag')
    }
  }

  onsubmit = (e: any) => {
    // e.preventDefault()
    // console.log(Object.keys(errors).length , 'error length')
    if (Object.keys(errors).length === 0) {
      _didsubscriptionAdd()
      setsubsAddFlag(true)
    }
    else {
      setsubsAddFlag(false)
    }
  }

  if (loading) {
    return <Text center>
      <Loader
        type="Rings"
        color="#233269"
        height={100}
        width={100}
        timeout={30000}
      />
    </Text>
  }

  return (
    <Main>
      <Text variant="title" color='black'>Add Subscription Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='subsaddForm'>
          <Card title="Fill All the Information Below">
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                <Attributes>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Plan Name</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_subname"
                      value={subscription.name}
                      onChange={(e) => setSubscription({ ...subscription, name: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Plan Name"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_subname?.type === 'required' && 'Please enter Plan name'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Description</Text>
                    <Form.Control as="textarea"
                      className='basecontrol custome-textarea'
                      name="txt_subdescription"
                      placeholder='Description'
                      rows={3}
                      cols={50}
                      value={subscription.description}
                      onChange={(e) => setSubscription({ ...subscription, description: e.target.value })}
                      ref={register({ required: true, })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_subdescription?.type === 'required' && 'Please enter Description'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Price</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_subprice"
                      value={subscription.price}
                      ref={register({ required: true, })}
                      type='number'
                      // min={0.00}
                      // max={999999.00}
                      placeholder="Amount"
                      onChange={e => setSubscription({ ...subscription, price: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_subprice?.type === 'required' && 'Please enter Price'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Duration in Days</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number" id="durationdate" name="txt_duration_in_month"
                      placeholder='Duration in Days'
                      value={subscription.duration_in_month}
                      onChange={e => setSubscription({ ...subscription, duration_in_month: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_duration_in_month?.type === 'required' && 'Please enter Duration in month'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>System Access</Text>
                    <div>
                      <Radio type='radio' id='active' name='status' value={subscription.isactive}
                        onChange={(e) => setSubscription({ ...subscription, isactive: true })}
                        label='ENABLED'></Radio>
                      <Radio type='radio' id='inactive' name='status' value={subscription.isactive}
                        onChange={(e) => setSubscription({ ...subscription, isactive: false })}
                        label='DISABLED'></Radio>
                    </div>
                  </div>
                </Attributes>
              </Col>
            </Row>
          </Card>
          <Actions>
            {/* If you click the back button its is redirect to subscriptions list page.  */}
            <Link to='/subscription/list'>
              <Button className='bttn bttn-transparent'>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page.  */}
            <Button className='bttn bttn-transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </Form>
      </Grid>
      {/* Add form, having input box, select box.  */}
    </Main>
  )
}


export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-subscription-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. 
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}
