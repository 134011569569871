import {
    PRODUCT_TYPE_LIST_GET,
}  from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import {
    productTypeListGetFAction,
    productTypeListGetRAction,
} from '../action/productTypeAction'
import productTypeService from '../../services/productTypeService'

export const getProductTypeEpic = (action : any) => {
  // console.log('get role epic called')
  return action.pipe(
    ofType(PRODUCT_TYPE_LIST_GET),
    switchMap((action : any) => {
      return productTypeService.getProductType(action.payload).pipe(
        map((res :any) => {
        // console.log(res,'response for product type epics')
        const { data} = res
        // console.log(data,'product type content')
        return productTypeListGetFAction({ data})
        }),
        catchError((err : any) => of(productTypeListGetRAction(err)))
      )
    })
  )
}