import React from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Flex } from '../../../../common/components/flex/Flex'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import manufacturerService from '../../../../services/manufacturerService'
import removeicon from '../../../../assets/images/Remove.png'
import editicon from '../../../../assets/images/editone.png';
import swal from 'sweetalert';
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { Label } from "../../../../common/components/label/Label";
import { manufacturerCmpSetSelectedAction } from '../../../../store/action/manufacturerAction';
// import { history } from '../../../store';
import { connect } from 'react-redux';
import { Col, Row, Image } from 'react-bootstrap';
import '../ManufacturerCompanies.scss'
import Loader from 'react-loader-spinner';
import noimg from '../../../../assets/images/NoImg.jpg';
import { useRef } from 'react';


interface ViewProps {
    dock: any[]
}
const ManufacturerCmpDetail = (props: any) => {
    const { company, match } = props
    const [isLoading, setLoading] = React.useState(true)
    const { id } = match.params
    // const [form, setForm] = React.useState<ViewProps>({
    //     dock: []
    // })
    //const [isDeleting, setDelete] = React.useState(false)
    const role = localStorage.getItem("role");

    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<any>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageUserAPI(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const _removeManufacturerCompanyLogo = (id: any) => {
        return manufacturerService.removeManufacturerCompanyLogo(id).subscribe({
            next: (r) => {
                // console.log(r, 'response')
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
                // history.push(`/ships/${id}/`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
            }
        })
    }

    const ImageUserAPI = (imageurl: any) => {
        const { id } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        manufacturerService.updateManufactCompanyImage(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                window.location.reload();
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }

    React.useEffect(() => {
        const { id } = match.params
        _getCompanyDetail(id)
        // _getdockDetail(id)
    }, [])

    const _getCompanyDetail = (id: any) => {
        setLoading(true)
        return manufacturerService.getManufactCompanyDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.manufacturerCmpSetSelectedAction(data)
                setLoading(false)
            },
            error: err => {
                console.error(err)
            }
        })
    }
    // const _getdockDetail = ((id:number) => {
    //     return dockService.getDockViewDetail(id).subscribe({
    //         next: r => {
    //             setForm({ ...form, dock: r.data })
    //             r.data.map((data: any, i: any) => {
    //                 return form.dock.push(data)
    //             })
    //         },
    //         error: err => {
    //             console.error(err)
    //         }
    //     })
    // })
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    return (
        <Main>
            {company === null && (
                <>
                    <Space divheight={15} />
                    <Text color='main' variant='subtitle' center bold> There is No data for Requested id</Text>
                    <Space divheight={15} />
                </>
            )}
            {company !== null && (
                <>
                    {/* {console.log(company,'company')} */}
                    <div className='title-name-block'>
                        <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                            {company.company_logo === null && (
                                <Image src={noimg} alt='logo' roundedCircle />
                            )}
                            {company.company_logo === '' && (
                                <Image src={noimg} alt='logo' roundedCircle />
                            )}
                            {company.company_logo !== null && company.company_logo !== '' && (
                                <Image src={company.company_logo} alt='logo' roundedCircle />
                            )}
                        </div>
                        {role === 'VISITOR' && (
                            <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                                <label className="custom-file-upload">
                                    <Image src={editicon} alt='logo' />
                                    <input
                                        ref={upload}
                                        type="file"
                                        onChange={getBase64}
                                    />
                                </label>
                            </div>
                        )}

                        <Text variant='title' color='main' className='txt-capitalize'>
                            {/* {String(company.email).substring(0,String(company.email).indexOf('@'))} */}
                            {company.name}
                        </Text>
                        <Label className={company.status === 'UNVERIFIED' ? 'danger' : 'warning'} >
                            {company.status === 'UNVERIFIED' ? 'Not Verified' : 'Verified'}
                        </Label>
                    </div>
                    <Space divheight={15} />
                    {/* {console.log(company,'company')} */}
                    <Row className='m-0 p-0'>
                        <Col sm='12' md='12' lg='8' xl='8' className='m-0 p-0 align-top'>
                            <Card title="Industries Detail">
                                <Row className='m-0 p-0'>
                                    <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='singleFrame'>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Name</Text>
                                                <Text color='main' variant='subtitle' bold>{company.name}</Text>
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Email</Text>
                                                {company.email === null ? (
                                                    <Text color='main' variant='subtitle' className='txt-email' bold>-</Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' className='txt-email' bold>{company.email}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Website</Text>
                                                {company.website === null ? (
                                                    <Text color='main' variant='subtitle' className='txt-email' bold>-</Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold>{company.website}</Text>
                                                )}
                                            </div>
                                            {/* <div>
                                                <Text color='main' variant='subtitle'> Company Contact Number</Text>
                                                <Text color='main' variant='subtitle' bold>{company.phone}</Text>
                                            </div> */}
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Company Contact Number</Text>
                                                {company.phone_code === '' || null ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <>
                                                        {company.phone === '' || null ? (
                                                            <Text color='main' bold variant='subtitle'>{company.phone_code}
                                                                <span>
                                                                    <Text color='main' bold variant='subtitle'>{company.phone}</Text>
                                                                </span>
                                                            </Text>
                                                        ) : (
                                                            <>
                                                                <Text color='main' bold variant='subtitle'>{company.phone_code} &nbsp;
                                                                    <span>
                                                                        <Text color='main' bold variant='subtitle'>{company.phone}</Text>
                                                                    </span>
                                                                </Text>
                                                            </>
                                                        )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Address</Text>
                                                {company.address === null ? (
                                                    <Text color='main' variant='subtitle' className='txt-email' bold>-</Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold>{company.address}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Product Type</Text>
                                                <Text color='main' variant='subtitle' bold>{company.vendor_type}</Text>
                                            </div>
                                        </Attributes>
                                    </Col>
                                    <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='singleFrame'>
                                           
                                        <div>
                                                <Text color='main' variant='subtitle'>Country</Text>
                                                {company.companyCity === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                        company.companyCity.country === null ? (
                                                            <Text color='main' variant='subtitle' bold>
                                                                -
                                                    </Text>
                                                        ) : (
                                                                company.companyCity.country.name === null ? (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        -
                                                        </Text>
                                                                ) : (
                                                                        <Text color='main' variant='subtitle' bold>
                                                                            {company.companyCity.country.name}
                                                                        </Text>
                                                                    )
                                                            )
                                                    )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>City</Text>
                                                {company.companyCity === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                        company.companyCity.name === null ? (
                                                            <Text color='main' variant='subtitle' bold>
                                                                -
                                                    </Text>
                                                        ) : (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    {company.companyCity.name}
                                                                </Text>
                                                            )
                                                    )}
                                            </div>
                                            {/* <div>
                                                <Text color='main' variant='subtitle'> Company Contact Number</Text>
                                                <Text color='main' variant='subtitle' bold>{company.phone}</Text>
                                            </div> */}
                                            {company.user === null ? (
                                                
                                                <Text color='danger' variant='subtitle' className="no_user" bold>
                                                    No user is mapped to this company
                                                </Text>
                                            ) : (
                                                    
                                                <>
                                                <div>
                                                    <Text color='main' variant='subtitle'>CEO</Text>
                                                    {company.user === null ? (
                                                        <Text color='main' variant='subtitle' bold>
                                                            -
                                                    </Text>
                                                    ) : (
                                                            company.user.ceo === null ? (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    -
                                                    </Text>
                                                            ) : (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        {company.user.ceo}
                                                                    </Text>
                                                                )
                                                        )}
                                                </div>
                                                <div>
                                                    <Text color='main' variant='subtitle'>Chairman</Text>
                                                    {company.user === null ? (
                                                        <Text color='main' variant='subtitle' bold>
                                                            -
                                                    </Text>
                                                    ) : (
                                                            company.user.chairman === null ? (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    -
                                                    </Text>
                                                            ) : (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        {company.user.chairman}
                                                                    </Text>
                                                                )
                                                        )}
                                                </div>
                                                <div>
                                                    <Text color='main' variant='subtitle'>Director</Text>
                                                    {company.user === null ? (
                                                        <Text color='main' variant='subtitle' bold>
                                                            -
                                                    </Text>
                                                    ) : (
                                                            company.user.directors === null ? (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    -
                                                    </Text>
                                                            ) : (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        {company.user.directors}
                                                                    </Text>
                                                                )
                                                        )}
                                                </div>
                                                <div>
                                                    <Text color='main' variant='subtitle'>Year of Establishment</Text>
                                                    {company.user === null ? (
                                                        <Text color='main' variant='subtitle' bold>
                                                            -
                                                    </Text>
                                                    ) : (
                                                            company.user.year_of_establishment === null ? (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    -
                                                    </Text>
                                                            ) : (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        {company.user.year_of_establishment}
                                                                    </Text>
                                                                )
                                                        )}
                                                </div>
                                                <div>
                                                    <Text color='main' variant='subtitle'>Services</Text>
                                                    {company.user === null ? (
                                                        <Text color='main' variant='subtitle' bold>
                                                            -
                                                    </Text>
                                                    ) : (
                                                            company.user.services === null ? (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    -
                                                    </Text>
                                                            ) : (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        {company.user.services}
                                                                    </Text>
                                                                )
                                                        )}

                                                </div>
                                            </>
                                                )}
                                        </Attributes>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col sm='12' md='12' lg='4' xl='4' className='p-0 m-0 align-top'>
                            {/* <div className='ml-xl-3  mt-sm-4 mt-md-4 mt-lg-4 mt-xl-0 view_page_xs_margin'  >

                            </div> */}
                            <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                                <Card title="Company Logo">
                                    {role === 'VISITOR' ? (
                                        <div className='user-mainBlock'>
                                            {company.company_logo === null && (
                                                <div className='userEdit-block'>
                                                    <img src={noimg} alt='logo' />
                                                </div>
                                            )}
                                            {company.company_logo === '' && (
                                                <div className='userEdit-block'>
                                                    <img src={noimg} alt='logo' />
                                                </div>
                                            )}
                                            {company.company_logo !== null && company.company_logo !== '' && (
                                                <div className='userEdit-block'>
                                                    <img src={company.company_logo} alt='logo' />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className='user-mainBlock'>
                                            {company.company_logo === null && (
                                                <div className='userCompany-block'>
                                                    <img src={noimg} alt='logo' />
                                                </div>
                                            )}
                                            {company.company_logo === '' && (
                                                <div className='userCompany-block'>
                                                    <img src={noimg} alt='logo' />
                                                </div>
                                            )}
                                            {company.company_logo !== null && company.company_logo !== '' && (
                                                <div className='userCompany-block'>
                                                    <img src={company.company_logo} alt='logo' />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {((company.company_logo === null)||(company.company_logo === '')) && role === 'VISITOR' && (
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    <Image src={editicon} alt='logo' />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    onChange={getBase64}
                                                />
                                            </label>
                                        </div>
                                    )}
                                    {company.company_logo !== null && company.company_logo !== '' && role === 'VISITOR' && (
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    <Image src={editicon} alt='logo' className="editIconRemove" />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    onChange={getBase64}
                                                />
                                            </label>

                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    {/* <Icons color="#ffffff" icon="edit" /> */}
                                                    <Image src={removeicon} alt='logo' onClick={() => _removeManufacturerCompanyLogo(company.id)} />
                                                </Flex>
                                            </label>
                                        </div>
                                    )}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row className='p-0 m-0 mt-2'>
                        <Col xs='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                            <Card title="Supporting Owner Detail">
                                <Row className='p-o m-0'>
                                    <Col sm='12' className='p-0 m-0'>
                                        {company.user === null &&
                                            <>
                                                <Space divheight={20} />
                                                <Text variant='subtitle' center color="main" bold>No Owner Associated with this Company</Text>
                                                <Space divheight={20} />
                                            </>
                                        }
                                        {company.user !== null &&
                                            <Attributes className='view_Page'>
                                                <div>
                                                    <Text color='main' variant='subtitle'> Owner</Text>
                                                    <Text color='main' variant='subtitle' bold> {company.user.name} </Text>
                                                </div>
                                                <div>
                                                    <Text color='main' variant='subtitle'> Created Date</Text>
                                                    {company.user.verified_date === null && (
                                                        <Text color='main' variant='subtitle' bold> -</Text>
                                                    )}
                                                    {company.user.verified_date === null && (
                                                        <Text color='main' variant='subtitle' bold>
                                                            {/* {company.created_at} */}
                                                            {format(parseISO(company.user.verified_date), 'dd/MM/yyyy, hh:mm')}
                                                        </Text>
                                                    )}

                                                </div>
                                                <div>
                                                    <Text color='main' variant='subtitle'>Verified by</Text>
                                                    <Text color='main' variant='subtitle' bold>Admin </Text>
                                                </div>
                                            </Attributes>
                                        }
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='12' md='12' lg='8' xl='8'>
                            <Actions>
                                <Link to={`/manufacturer/comapanies/list`}>
                                    <Button type="button" variant='transparent'>
                                        <Flex jc='jc-around'>
                                            <Icons icon="arrow_back" />
                                            BACK
                                        </Flex>
                                    </Button>
                                </Link>
                                <div>
                                    {role === 'VISITOR' ? (
                                        //                                   <Link to={`/manufacturer-companies/edit/${id}`} >
                                        <Button variant='transparent' disabled>
                                            <Flex>
                                                <Icons color="#ffffff" icon="edit" />
                                                EDIT DATA
                                            </Flex>
                                        </Button>
                                        //                                   </Link>
                                    ) : (
                                        <Link to={`/manufacturer-companies/edit/${id}`} >
                                            <Button variant='transparent'>
                                                <Flex>
                                                    <Icons color="#ffffff" icon="edit" />
                                                    EDIT DATA
                                                </Flex>
                                            </Button>
                                        </Link>
                                    )}
                                </div>
                            </Actions>
                        </Col>
                    </Row>
                </>
            )}
        </Main>
    )

}

export interface CompanyProps {
    children?: React.ReactNode
    className?: string;
}
export const Company = ({ children, className, ...props }: CompanyProps) => {
    const attribute = classNames('company-container', className);

    return (
        <div {...props} className={attribute} >
            {children}
        </div>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

export interface LinkProps {
    children?: React.ReactNode
    color?: string
}
const CompanyLink = ({ color, ...props }: LinkProps) => {
    const actions = classNames('company-link-container', color);
    return (
        <div className={actions}>
            {props.children}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    company: state.manufacturer.selectedManufactCmp
})

export default connect(
    mapStateToProps,
    {
        manufacturerCmpSetSelectedAction
    }
)(ManufacturerCmpDetail)