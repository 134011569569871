import {
    ROLE_LIST_GET,
    ROLE_LIST_GET_F,
    ROLE_LIST_GET_R,
    ROLE_SELECTED_SET
}  from '../actionType'

const initialState = {
    roles: [],
    isLoading: false,
    error: null,
    selectedRoles: null,
    totalPage: 0,
    totalElement: 0,
}

export default function roleReducer (state = initialState, action:any)  {
    switch(action.type) {
        case ROLE_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            } 
        case ROLE_LIST_GET_F:
            return {
                ...state,
                isLoading: false,
                roles: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
            }
        case ROLE_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case ROLE_SELECTED_SET:
            return {
                ...state,
                selectedRoles: action.payload
            }
        default:
            return state
    }
}