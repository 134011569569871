import React from 'react'
import { Main } from '../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
// import { Button } from '../../common/components/button/Button'
import { Text } from '../../common/components/text/Text'
import Card from '../../common/components/card/Card'
import { Grid } from '../../common/components/grid/Grid'
import { Flex } from '../../common/components/flex/Flex'
import noimg from '../../assets/images/NoImg.jpg';
// import { Input, Textarea } from '../../common/components/control/Control'
import { Space } from '../../common/components/space/Space'
import { Attributes } from '../../common/components/attribute/Attribute'
import classNames from 'classnames'
import editicon from '../../../assets/images/editone.png'
import { JsxChild } from 'typescript'
import Icons from '../../common/components/icon/Icon'
import settingService from '../../services/settingService'
import { history } from '../../store'
import swal from 'sweetalert';
import { Card as BootstrapCard } from 'react-bootstrap';
import './setting.scss'
import '../../common/components/button/Button.scss'
import '../../common/components/control/Control.scss'
import Carousel from 'react-bootstrap/Carousel'
import { useForm } from 'react-hook-form'
import codeService from '../../services/codeService'
import Loader from 'react-loader-spinner'

// Blue print of the class component. 
interface settingEditProps {
    id: string | number,
    price_per_view: string,
    price_per_click: string,
    mail_subject: string,
    naviearth_port: string,
    dimension_factor: string,
    mail_template: string,
    Nautical: string,
    adsWaitLimit: string,
    anode_mailSub: string,
    anode_mailContent: string,
    paint_mailSub: string,
    paint_mailContent: string,
    terms_condition:string,
    about:string,
    dockowner_ads:any,
    ads_settings:string,
    ads_length:number,
    dockowner_spc_icon:any,
    spc_icon_ads_settings:string,
    spc_icon_ads_length:number,
    boost_first_ads_settings:string,
    boost_first_ads_length:number,
    boost_first_data:any,
}

interface FormData {
    txt_viewPrice: string;
    txt_clickPrice: string;
    txt_mailSub: string;
    txt_naviearthPort: string;
    txt_dimensionfactor: string;
    txt_mailTemplate: string;
    txt_Nautical: string;
    txt_adsWaitLimit: string;
    txt_anode_mailSub: string,
    txt_anode_mailContent: string,
    txt_paint_mailSub: string,
    txt_paint_mailContent: string,
    txt_about: string,
    txt_term_condition: string,
}

interface settingFormProps {
    isSubmit: boolean;
    ports: any[];
    tolerancearray: any[]
}

export const SettingEdit = (props: any) => {
    const [setting, setSettings] = React.useState<settingEditProps>({
        id: '',
        price_per_view: '',
        price_per_click: '',
        mail_subject: '',
        naviearth_port: '',
        dimension_factor: '',
        mail_template: '',
        Nautical: '',
        adsWaitLimit: '',
        anode_mailSub: '',
        anode_mailContent: '',
        paint_mailSub: '',
        paint_mailContent: '',
        about:'',
        terms_condition:'',
        dockowner_ads:[],
        ads_settings:'',
        ads_length:0,
        dockowner_spc_icon:[],
        spc_icon_ads_settings:'',
        spc_icon_ads_length:0,
        boost_first_ads_settings:'',
        boost_first_ads_length:0,
        boost_first_data:[],
    })

    const [settingForm, setSettingForm] = React.useState<settingFormProps>({
        isSubmit: false,
        ports: [],
        tolerancearray: []
    })
    const { register, handleSubmit, errors } = useForm<FormData>(
        {
            mode: 'onSubmit',
            reValidateMode: 'onBlur',
        }
    );
    const [settingUpdateFlag, setSettingUpdateFlag] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)
    var settings:string;
    var length: any
    var data: any
    var dock_owner_ad: any
    var navi_globe_special: any
    var navi_spl_settings:string;
    var navi_spl_length: any
    var navi_spl_data: any
    var navi_globe_boost: any

    var dock_boost_first_ad: any
    var navi_supp_length: any
    var navi_supp_data: any
    var navi_supp_settings:string;

        //port list
    const _getPortSetting = () => {
        return codeService.getAllPorts().subscribe({
            next: r => {
                // console.log(r.data)
                setSettingForm({ ...settingForm, ports: r.data })
                // setSettings({ ...setting, naviearth_port: value })
                r.data.map((data: any, i: any) => {
                    return settingForm.ports.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    }

    // const upload = useRef(null);
    // var base64: string | ArrayBuffer | null

    // const getBase64 = (event: any) => {
    //     let file = event.target.files[0];
    //     let reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = function () {
    //         // console.log(reader.result);
    //         base64 = reader.result
    //         setBValue(
    //             {
    //                 string_image: reader.result
    //             })
    //         // console.log(base64,'base64')
    //         // console.log(bvalue.string_image,'base 64 value')
    //         ImageAPIDryDock(reader.result);
    //     };
    //     reader.onerror = function (error) {
    //         console.log("Error: ", error);
    //     };
    // };

    // Used to get perticular ads detail and store to variable.  
    const _getSettingDetail = (() => {
        return settingService.getSettingsDetail().subscribe({
            next: r => {
                // console.log(r,'response')
                // console.log(r.data, 'data within response')
                const value = r.data
                // console.log(value, 'data within response using value')
                length = 0;
                navi_spl_length=0;
                navi_supp_length=0;
                // console.log(r.data.admin_dockowner_advertisement)
                if (r.data.admin_dockowner_advertisement !== null) {
                    data = [r.data.admin_dockowner_advertisement.map((data: any, i: any) => {
                        if(length==0){
                            settings=data.name
                        }
                        length = length + 1
                        return String(data.name)
            
                    })];
                    // console.log("Check", settings);
                    // console.log("Check", length);
                } else {
                    data = null;
                    settings='';
                    length=0;
                }
                // console.log(length)
                if (data !== null) {
                    data.map((value: any) => (
                        dock_owner_ad = value
                        //       console.log("inside carosel",value)
                    ))
                    // console.log("ads value", dock_owner_ad);
                }

                if (r.data.admin_dockowner_special_ads !== null) {
                    navi_spl_data = [r.data.admin_dockowner_special_ads.map((data: any, i: any) => {
                        if(navi_spl_length==0){
                            navi_spl_settings=data.name
                            console.log("Check", navi_spl_settings);
                        }
                        navi_spl_length = navi_spl_length + 1
                        return String(data.name)
            
                    })];
                    // console.log("Check", navi_spl_settings);
                    // console.log("Check", navi_spl_length);
                } else {
                    navi_spl_data = null;
                    navi_spl_settings='';
                    navi_spl_length=0;
                }
                // console.log(navi_spl_length)
                if (navi_spl_data !== null) {
                    navi_spl_data.map((value: any) => (
                        dock_owner_ad = value
                        //       console.log("inside carosel",value)
                    ))
                    console.log("ads value", dock_owner_ad);
                }

                if (r.data.admin_supplier_special_ads !== null) {
                    // console.log("Check", r.data.admin_supplier_special_ads);
                    navi_supp_data = [r.data.admin_supplier_special_ads.map((data: any, i: any) => {
                        if(navi_supp_length==0){
                            console.log("if supp len", data.name);
                            navi_supp_settings=data.name
                            console.log("Check", navi_supp_settings);
                        }
                        navi_supp_length = navi_supp_length + 1
                        return String(data.name)
            
                    })];
                } else {
                    navi_supp_data = null;
                    navi_supp_settings='';
                    navi_supp_length=0;
                }
                if (navi_supp_data !== null) {
                    navi_supp_data.map((value: any) => (
                        dock_boost_first_ad = value
                        //       console.log("inside carosel",value)
                    ))
                    console.log("ads value", dock_boost_first_ad);
                }

                // boost_first_ads_settings:'',
                // boost_first_ads_length:0,
                setSettings({
                    id: value.id,
                    price_per_view: value.price_per_view,
                    price_per_click: value.price_per_click,
                    mail_subject: value.mail_subject,
                    naviearth_port: value.naviearth_port,
                    dimension_factor: value.dimension_factor,
                    mail_template: value.mail_template,
                    Nautical: value.subscriber_miles_limit,
                    adsWaitLimit: value.ads_wait_limit,
                    anode_mailSub: value.anode_quotation_subject,
                    anode_mailContent: value.anode_quotation_template,
                    paint_mailSub: value.paint_quotation_subject,
                    paint_mailContent: value.paint_quotation_template,
                    about:value.about,
                    terms_condition:value.terms_condition,
                    dockowner_ads:dock_owner_ad,
                    ads_settings:settings,
                    ads_length:length,
                    dockowner_spc_icon:navi_globe_special,
                    spc_icon_ads_settings:navi_spl_settings,
                    spc_icon_ads_length:navi_spl_length,
                    boost_first_ads_settings:settings,
                    boost_first_ads_length:navi_spl_length,
                    boost_first_data:dock_boost_first_ad,
                })
               
            },
            error: err => {
                console.error(err)
            }
        })
    })
   // console.log("ads value", dockowner_ad);
    const _getSettingTolerance = (() => {
        return codeService.getTolerance().subscribe({
            next: r => {

                // console.log(r.data,"ship type")
                setSettingForm({ ...settingForm, tolerancearray: r.data })
                // console.log(ship.shipType ,'ship type data in ship add page')
                r.data.map((data: any, i: any) => {
                    return settingForm.tolerancearray.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    // Call setting detail functions at the stage of page loading. 
    React.useEffect(() => {
        _getSettingDetail()
        _getSettingTolerance()
        _getPortSetting()
    }, [])


    //if changing editor value

    const handleEditorChange = (value: any, event: any) => {
        // console.log("here is the current model value:", value);
        // console.log(setting.mail_template,'mail template')
        setSettings({ ...setting, mail_template: value })
    }

    // After update all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call. 
    const _didUpdateSetting = () => {
        setSettingForm({ ...settingForm, isSubmit: true })
        // e.preventDefault()
        const updatedForm = {
            id: String(setting.id),
            price_per_view: Number(setting.price_per_view),
            price_per_click: Number(setting.price_per_click),
            mail_subject: setting.mail_subject,
            naviearth_port: setting.naviearth_port,
            dimension_factor: setting.dimension_factor,
            mail_template: setting.mail_template,
            subscriber_miles_limit: setting.Nautical,
            ads_wait_limit: setting.adsWaitLimit,
            anode_quotation_subject: setting.anode_mailSub,
            anode_quotation_template: setting.anode_mailContent,
            paint_quotation_subject: setting.paint_mailSub,
            paint_quotation_template: setting.paint_mailContent,
            about: setting.about,
            terms_condition:setting.terms_condition,
        }
        // API call for updateship with data, updated into list page.
        if (settingUpdateFlag === true) {
            setLoading(true)
            setSettingForm({ ...settingForm, isSubmit: true })
            settingService.updateSetting(updatedForm).subscribe({
                next: () => {
                    setLoading(false)
                    setSettingForm({ ...settingForm, isSubmit: false })
                    swal("Great", "Setting Updated Successfully", "success")
                    history.push('/settings')
                },
                error: err => {
                    setLoading(false)
                    swal("OOPS", String(err), "error")
                    console.error(err)
                }
            })
        }
        else {
            console.log(settingUpdateFlag, 'flag')
        }
    }
    onsubmit = (e: any) => {
        // e.preventDefault()
        console.log(Object.keys(errors).length, 'error length')
        if (Object.keys(errors).length === 0) {
            _didUpdateSetting()
            setSettingUpdateFlag(true)
        }
        else {
            setSettingUpdateFlag(false)
        }
    }

    if (settingForm.isSubmit === true) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }
    return (
        <Main>
            <Text color='black' variant="title">Edit Setting Informations</Text>
            <Space divheight={25} />
            {/* Edit form, having input box, select box.  */}
            <Grid templateCols='75% auto' className='add_page'>
                <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='userEditForms'>
                    <Card title="Fill All the Information Below">
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Price Per View</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            name="txt_viewPrice"
                                            value={setting.price_per_view}
                                            ref={register({ required: true, })}
                                            type='number'
                                            placeholder="Price per view"
                                            onChange={e => setSettings({ ...setting, price_per_view: e.target.value })}
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_viewPrice?.type === 'required' && 'Please enter Price Per view'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Price Per Click</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            name="txt_clickPrice"
                                            value={setting.price_per_click}
                                            ref={register({ required: true, })}
                                            type='number'
                                            placeholder="Price per click"
                                            onChange={e => setSettings({ ...setting, price_per_click: e.target.value })}
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_clickPrice?.type === 'required' && 'Please enter Price Per Click'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Ads Wait Limit (Provinces)</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            name="txt_adsWaitLimit"
                                            value={setting.adsWaitLimit}
                                            ref={register({ required: true, })}
                                            type='number'
                                            placeholder="Ads Wait Limit"
                                            onChange={e => setSettings({ ...setting, adsWaitLimit: e.target.value })}
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_adsWaitLimit?.type === 'required' && 'Please enter Nautical Miles'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Dimension Factor   (Tolerance)</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            name="txt_dimensionfactor"
                                            value={setting.dimension_factor}
                                            ref={register({ required: true, })}
                                            type='number'
                                            placeholder="Dimension Factor"
                                            onChange={e => setSettings({ ...setting, dimension_factor: e.target.value })}
                                        />
                                        {/* <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                            value={setting.dimension_factor}
                                            name="txt_tolerance"
                                            id='txt_tolerance'
                                            // ref={register({ required: true, })}
                                                onChange={(e) => setSettings({ ...setting, dimension_factor: e.target.value })}>
                                            <option className='option-placeholder' value=''>--- Select Tolerance ---</option>
                                            {settingForm.tolerancearray.map((data:any, i:any) => {
                                                return <option key={i} value={data.value}>{data.name}</option>
                                            })}
                                        </Form.Control> */}
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_dimensionfactor?.type === 'required' && 'Please enter Dimension Factor'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Subscriber Miles Limit</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            name="txt_Nautical"
                                            value={setting.Nautical}
                                            ref={register({ required: true, })}
                                            type='number'
                                            placeholder="Nautical Miles"
                                            onChange={e => setSettings({ ...setting, Nautical: e.target.value })}
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_Nautical?.type === 'required' && 'Please enter Nautical Miles'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Navi Earth Default Port</Text>
                                        <input className='basecontrol cutome-selectInputnxt'
                                            name="txt_naviearthPort"
                                            id="txt_naviearthPort"
                                            list='naviearthPorts'
                                            value={setting.naviearth_port}
                                            ref={register({ required: true, })}
                                            placeholder="Naviearth Port"
                                            // onChange={e => _getPortSetting(e.target.value)}
                                            onChange={(e) => setSettings({ ...setting, naviearth_port: e.target.value })}
                                        >
                                        </input>
                                        <datalist id="naviearthPorts">
                                            {/* {console.log(settingForm.ports,'ports')} */}
                                            {settingForm.ports.map((data: any, i: number) => {
                                                return <option key={i}
                                                    value={data.name} />
                                            })}
                                        </datalist>
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_naviearthPort?.type === 'required' && 'Please Select Naviearth port'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                    <Space divheight={20} />
                    <Row className='m-0 p-0'>
                        <Col className='m-0 p-0' sm='12'>
                            <Card title='Invitation Code - Mail Content'>
                                <Row className='m-0 p-0'>
                                    <Col className='m-0 p-0' sm='6'>
                                        <Attributes>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Mail Subject</Text>
                                                <Form.Control className='basecontrol custome-input'
                                                    name="txt_mailSub"
                                                    value={setting.mail_subject}
                                                    ref={register({ required: true, })}
                                                    type='text'
                                                    placeholder="Mail Subject"
                                                    onChange={e => setSettings({ ...setting, mail_subject: e.target.value })}
                                                />
                                            </div>
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_mailSub?.type === 'required' && 'Please enter Mail Subject'}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Attributes>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0'>
                                    <Col className='m-0 p-0' sm='6'>
                                        <Attributes>

                                            <Form.Control as="textarea"
                                                className='basecontrol custome-textarea'
                                                rows={6}
                                                cols={50}
                                                name="txt_mailTemplate"
                                                value={setting.mail_template}
                                                onChange={e => setSettings({ ...setting, mail_template: e.target.value })}
                                                ref={register({ required: true, })}
                                            />
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_mailTemplate?.type === 'required' && 'Please enter Mail Template'}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Attributes>
                                    </Col>
                                    <Col className='m-0 p-0'>
                                        <div className='bootstrap-card'>
                                            <BootstrapCard className='bootstrap-card-content'>
                                                <BootstrapCard.Title>
                                                    Legend
                                                </BootstrapCard.Title>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@user_name@@ -  Name of the User
                                                    </Text>
                                                </BootstrapCard.Text>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@invite_code@@ -  Invite Code
                                                    </Text>
                                                </BootstrapCard.Text>
                                            </BootstrapCard>
                                        </div>

                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Space divheight={20} />
                    <Row className='m-0 p-0'>
                        <Col className='m-0 p-0' sm='12'>
                            <Card title='Anode Quote - Mail Content'>
                                <Row className='m-0 p-0'> 
                                    <Col className='m-0 p-0' xs='12' sm='12' md='12' lg='7' xl='7' >
                                        <Attributes>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Mail Subject</Text>
                                                <Form.Control className='basecontrol custome-input'
                                                    name="txt_anode_mailSub"
                                                    value= {setting.anode_mailSub}
                                                    ref={register({ required: true, })}
                                                    type='text'
                                                    placeholder="Mail Subject"
                                                    onChange={e => setSettings({ ...setting, anode_mailSub: e.target.value })}
                                                />
                                            </div>
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_anode_mailSub?.type === 'required' && 'Please enter Mail Subject'}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Attributes>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0'>
                                    <Col className='m-0 p-0' xs='12' sm='12' md='12' lg='7' xl='7'>
                                        <Attributes>
                                            <Form.Control as="textarea"
                                                className='basecontrol custome-textarea'
                                                rows={10}
                                                cols={50}
                                                name="txt_anode_mailContent"
                                                value={setting.anode_mailContent}
                                                onChange={e => setSettings({ ...setting, anode_mailContent: e.target.value })}
                                                ref={register({ required: true, })}
                                            />
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_anode_mailContent?.type === 'required' && 'Please enter Mail Template'}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Attributes>
                                    </Col>
                                    <Col className='m-0 p-0' xs='12' md='12' lg='5' xl='5'>
                                        <div className='bootstrap-card'>
                                            <BootstrapCard className='bootstrap-card-content'>
                                                <BootstrapCard.Title>
                                                    Legend
                                                </BootstrapCard.Title>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@Supplier_name@@ - Name of the Supplier
                                                    </Text>
                                                </BootstrapCard.Text>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@Rendered_for@@ - User, who asking Quote
                                                    </Text>
                                                </BootstrapCard.Text>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@Surface Area@@ - Surface of Ship
                                                    </Text>
                                                </BootstrapCard.Text>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@Total_Weight@@ - Weight of Anode
                                                    </Text>
                                                </BootstrapCard.Text>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@Anode_Needed@@ - Anode Needed
                                                    </Text>
                                                </BootstrapCard.Text>
                                            </BootstrapCard>
                                        </div>

                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Space divheight={20} /> 
                    <Row className='m-0 p-0'>
                        <Col className='m-0 p-0' sm='12'>
                            <Card title='Paint Quote - Mail Content'>
                                <Row className='m-0 p-0'> 
                                    <Col className='m-0 p-0' xs='12' sm='12' md='12' lg='7' xl='7' >
                                        <Attributes>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Mail Subject</Text>
                                                <Form.Control className='basecontrol custome-input'
                                                    name="txt_paint_mailSub"
                                                    value={setting.paint_mailSub}
                                                    ref={register({ required: true, })}
                                                    type='text'
                                                    placeholder="Mail Subject"
                                                    onChange={e => setSettings({ ...setting, paint_mailSub: e.target.value })}
                                                />
                                            </div>
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_paint_mailSub?.type === 'required' && 'Please enter Mail Subject'}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Attributes>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0'>
                                    <Col className='m-0 p-0' xs='12' sm='12' md='12' lg='7' xl='7'>
                                        <Attributes>
                                            <Form.Control as="textarea"
                                                className='basecontrol custome-textarea'
                                                rows={11}
                                                cols={50}
                                                name='txt_paint_mailContent'
                                                value={setting.paint_mailContent}
                                                onChange={e => setSettings({ ...setting, paint_mailContent: e.target.value })}
                                                ref={register({ required: true, })}
                                            />
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_paint_mailContent?.type === 'required' && 'Please enter Mail Template'}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Attributes>
                                    </Col>
                                    <Col className='m-0 p-0' xs='12' md='12' lg='5' xl='5'>
                                        <div className='bootstrap-card'>
                                            <BootstrapCard className='bootstrap-card-content'>
                                                <BootstrapCard.Title>
                                                    Legend
                                                </BootstrapCard.Title>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@Supplier_name@@ - Name of the Supplier
                                                    </Text>
                                                </BootstrapCard.Text>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@Rendered_for@@ - User, who asing Quote
                                                    </Text>
                                                </BootstrapCard.Text>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@Top_Side@@ - Top Side of a Ship
                                                    </Text>
                                                </BootstrapCard.Text>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@Bottom_Side@@ - Bottom side of a ship
                                                    </Text>
                                                </BootstrapCard.Text>
                                                <BootstrapCard.Text>
                                                    <Text color='main' className='txt-legend' variant='subtitle'>
                                                        @@Estimated_Cost@@ - Estimated Cost
                                                    </Text>
                                                </BootstrapCard.Text>
                                            </BootstrapCard>
                                        </div>

                                    </Col>
                                </Row>
                                
                            </Card>
                        </Col>
                    </Row>
                    <Row className='m-0 p-0'>
                        <Col className='m-0 p-0' sm='12'>
                            <Card title='About'>
                                <Row className='m-0 p-0'>
                                    <Col className='m-0 p-0' xs='12' sm='12' md='12' lg='7' xl='7'>
                                        <Attributes>
                                            <Form.Control as="textarea"
                                                className='basecontrol custome-textarea'
                                                rows={11}
                                                cols={50}
                                                name='txt_about'
                                                value={setting.about}
                                                onChange={e => setSettings({ ...setting, about: e.target.value })}
                                                ref={register({ required: true, })}
                                            />
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_about?.type === 'required' && 'Please enter About'}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Attributes>
                                    </Col>
                                </Row>
                                
                            </Card>
                        </Col>
                    </Row>
                    <Row className='m-0 p-0'>
                        <Col className='m-0 p-0' sm='12'>
                            <Card title='Terms And Conditions'>
                                <Row className='m-0 p-0'>
                                    <Col className='m-0 p-0' xs='12' sm='12' md='12' lg='7' xl='7'>
                                        <Attributes>
                                            <Form.Control as="textarea"
                                                className='basecontrol custome-textarea'
                                                rows={11}
                                                cols={50}
                                                name='txt_term_condition'
                                                value={setting.terms_condition}
                                                onChange={e => setSettings({ ...setting, terms_condition: e.target.value })}
                                                ref={register({ required: true, })}
                                            />
                                            <Row className='m-0 p-0'>
                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                </Col>
                                                <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    <span className='err-msg text-capitalize'>{errors.txt_term_condition?.type === 'required' && 'Please enter the Terms and conditions'}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Attributes>
                                    </Col>
                                </Row>
                                
                            </Card>
                        </Col>
                    </Row>
                    <Row className='m-0 p-0'>
                        <Col className='m-0 p-0' xs='12' md='12' lg='12' xl='12'>
                            <Card title='NaviFIND'>
                            <div className='user-mainBlock'>
                                        {setting.ads_settings === null && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {setting.ads_settings === '' && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {setting.ads_settings !== null && setting.ads_settings!== '' && setting.ads_length === 1 && (
                                            <div className='userCompany-block'>
                                                <img src={setting.ads_settings} alt='logo' />
                                            </div>
                                        )}
                                        {setting.ads_settings !== null && setting.ads_settings !== '' && setting.ads_length > 1 && (
                                            <Carousel slide={true} touch={true}>
                                                {setting.dockowner_ads.map((value: any) => (
                                                    <Carousel.Item interval={5000}>
                                                        <div className='userCompany-block'>
                                                            <img
                                                                className="d-block w-100 slide"
                                                                src={value}
                                                                alt="First slide"
                                                            />
                                                        </div>
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                          )} 
                                    </div>
                                
                            </Card>
                         </Col>
                        <Col className='m-0 p-0' sm='12'>
                            <Card title='NaviGLOBE - Special Icons'>
                            <div className='user-mainBlock'>
                                        {setting.spc_icon_ads_settings === null && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {setting.spc_icon_ads_settings === '' && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {setting.spc_icon_ads_settings !== null && setting.spc_icon_ads_settings!== '' && setting.spc_icon_ads_length === 1 && (
                                            <div className='userCompany-block'>
                                                <img src={setting.spc_icon_ads_settings} alt='logo' />
                                            </div>
                                        )}
                                        {setting.spc_icon_ads_settings !== null && setting.spc_icon_ads_settings !== '' && setting.spc_icon_ads_length > 1 && (
                                            <Carousel slide={true} touch={true}>
                                                {setting.dockowner_ads.map((value: any) => (
                                                    <Carousel.Item interval={5000}>
                                                        <div className='userCompany-block'>
                                                            <img
                                                                className="d-block w-100 slide"
                                                                src={value}
                                                                alt="First slide"
                                                            />
                                                        </div>
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                         )} 
                                    </div>
                                    {/* <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                                        <label className="custom-file-upload">
                                        <Image src={editicon} alt='logo' />
                                        <input
                                        ref={upload}
                                        type="file"
                                        onChange={getBase64}
                                        />
                                    </div> */}
                            </Card>                        
                        </Col>
                        <Col className='m-0 p-0' sm='12'>
                            <Card title='NaviGLOBE - Boost First'>
                            <div className='user-mainBlock'>
                                        {setting.boost_first_ads_settings === null && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {setting.boost_first_ads_settings === '' && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {setting.boost_first_ads_settings !== null && setting.boost_first_ads_settings!== '' && setting.boost_first_ads_length === 1 && (
                                            <div className='userCompany-block'>
                                                <img src={setting.boost_first_ads_settings} alt='logo' />
                                            </div>
                                        )}
                                        {setting.boost_first_ads_settings !== null && setting.boost_first_ads_settings !== '' && setting.boost_first_ads_length > 1 && (
                                            <Carousel slide={true} touch={true}>
                                                {setting.boost_first_data.map((value: any) => (
                                                    <Carousel.Item interval={5000}>
                                                        <div className='userCompany-block'>
                                                            <img
                                                                className="d-block w-100 slide"
                                                                src={value}
                                                                alt="First slide"
                                                            />
                                                        </div>
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                         )} 
                                    </div>
                                
                            </Card>
                            {/* <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                    <label className="custom-file-upload">
                        <Image src={editicon} alt='logo' />
                        <input
                            ref={upload}
                            type="file"
                            onChange={getBase64}
                        /> */}
                        </Col>
                     {/*   <Col className='m-0 p-0' sm='12'>
                            <Card title='NaviGLOBE - Show My Dock First!'>
                            <div className='user-mainBlock'>
                                         {settings === null && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {settings === '' && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {settings !== null && settings!== '' && length === 1 && (
                                            <div className='userCompany-block'>
                                                <img src={settings} alt='logo' />
                                            </div>
                                        )}
                                        {settings !== null && settings !== '' && length > 1 && ( 
                                            <Carousel slide={true} touch={true}>
                                                {setting.dockowner_ads.map((value: any) => (
                                                    <Carousel.Item interval={5000}>
                                                        <div className='userCompany-block'>
                                                            <img
                                                                className="d-block w-100 slide"
                                                                src={value}
                                                                alt="First slide"
                                                            />
                                                        </div>
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        )} 
                                    </div>
                                
                            </Card>
                        </Col> */}
                    </Row>
                   
                    <Actions>
                        {/* If you click the save button its is call the function, in that data updated to list and redirect to list page.  */}
                        <Button className='bttn bttn-transparent' name='btnsettingedit' id='btnsettingedit' type="submit">
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
        </Main>
    )
}


export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-ship-add');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}

// Button style. 
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-center' className={action}>
            {props.children}
        </Flex>
    )

}

{/* <Editor
                                        height="90vh"
                                        defaultLanguage="html"
                                        value={setting.mail_template}
                                        onChange={handleEditorChange}
                                    /> */}
