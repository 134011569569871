import {
    DOCK_COMPANY_LIST_GET, DOCK_COMPANY_LIST_GET_F, DOCK_COMPANY_LIST_GET_R, DOCK_COMPANY_SELECTED_SET,
    DOCK_LIST_GET,
    DOCK_LIST_GET_F,
    DOCK_LIST_GET_R, DOCK_OWNER_LIST_GET, DOCK_OWNER_LIST_GET_F, DOCK_OWNER_LIST_GET_R, DOCK_OWNER_SELECTED_SET,
    DOCK_SELECTED_SET
  } from '../actionType'
  
  export const dockListGetAction = (params: any) => ({
    type: DOCK_LIST_GET,
    payload: params
  })
  
  export const dockListGetFAction = (data: any) => ({
    type: DOCK_LIST_GET_F,
    payload: data
  }) 
  
  export const dockListGetRAction = (error: any) => ({
    type: DOCK_LIST_GET_R,
    payload: error
  })
  
  export const dockSetSelectedAction = (dock: any) => ({
    type: DOCK_SELECTED_SET,
    payload: dock
  })
  
  export const dockCompanyListGetAction = (params: any) => ({
    type: DOCK_COMPANY_LIST_GET,
    payload: params
  })
  
  export const dockCompanyListGetFAction = (data: any) => ({
    type: DOCK_COMPANY_LIST_GET_F,
    payload: data
  })
  
  export const dockCompanyListGetRAction = (error: any) => ({
    type: DOCK_COMPANY_LIST_GET_R,
    payload: error
  }) 
  
  export const dockCompanySetSelectedAction = (company: any) => ({
    type: DOCK_COMPANY_SELECTED_SET,
    payload: company
  })
  
  export const dockOwnerListGetAction = (owner:any) => ({
    type: DOCK_OWNER_LIST_GET,
    payload:owner
  })
  
  export const dockOwnerListGetFAction = (data: any) => ({
    type: DOCK_OWNER_LIST_GET_F,
    payload: data
  })
  
  export const dockOwnerListGetRAction = (error: any) => ({
    type: DOCK_OWNER_LIST_GET_R,
    payload: error
  })
  
  export const dockOwnerSetSelectedAction = (owner: any) => ({
    type: DOCK_OWNER_SELECTED_SET,
    payload: owner
  })
  