import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import swal from 'sweetalert';
import unitService from '../../../../services/unitService'
import '../../../../styles/variables/Common.scss'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner';      

interface UnitAddProps {
  unit: string;
  typeOfUnit: string;
  representation: string;
  description: string;
  unit_english: string;
  name_description: string;
}

interface FormData {
  txt_unitname: string;
  txt_typeOfUnit: string;
  txt_unitrepresentation: string;
  txt_unitdescription: string;
  txt_unit_english: string;
  txt_unitname_description: string;
}
export const UnitAdd = (props: UnitAddProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const [unitaddflag, setunitAddFlag] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const [unit, setUnit] = useState<UnitAddProps>({
    unit: '',
    typeOfUnit: '',
    representation: '',
    description: '',
    unit_english: '',
    name_description: '',
  },
  )

  // After enter all details by user and click submit button values assign to some variable then it is used as input parameter for addunit api call. 
  const _didUnitAdd = () => {
    // e.preventDefault()
    const updatedForm = {
      name: unit.unit,
      description: unit.description,
      name_english: unit.unit_english,
      description_english: unit.name_description,
      type_of_unit: unit.typeOfUnit,
      representation: unit.representation
    }

    // API call for addunit with data, added to list. 
    // console.log(updatedForm,"updatedform")
    if (unitaddflag === true) {
      setLoading(true)
      unitService.addUnit(updatedForm).subscribe({
        next: (r) => {
          setLoading(false)
          console.log(r, 'response')
          swal("Great", "unit Added Successfully", "success")
          history.push('/unit/list')
        },
        error: err => {
          setLoading(false)
          swal("OOPS", String(err), "error")
        }
      })
    }
    else {
      console.log(unitaddflag)
    }
  }

  onsubmit = (e: any) => {
    // e.preventDefault()
    console.log(Object.keys(errors).length, 'error length')
    if (Object.keys(errors).length === 0) {
      _didUnitAdd()
      setunitAddFlag(true)
    }
    else {
      setunitAddFlag(false)
    }
  }

  if (isLoading) {
    return <Text center>
        <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
        />
    </Text>
}

  return (
    <Main>
      <Text variant="title" color='black'>Add Unit Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='unitAddForms'>
          <Card title="Fill All the Information Below">
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Unit</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_unitname"
                      value={unit.unit}
                      onChange={(e) => setUnit({ ...unit, unit: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Unit"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_unitname?.type === 'required' && 'Please enter Unit Name'}
                      </span>
                    </Col>
                  </Row>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Representation</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_unitrepresentation"
                      value={unit.representation}
                      onChange={(e) => setUnit({ ...unit, representation: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Representation"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_unitrepresentation?.type === 'required' && 'Please enter Representation'}
                      </span>
                    </Col>
                  </Row>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Type of Unit</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_typeOfUnit"
                      value={unit.typeOfUnit}
                      onChange={(e) => setUnit({ ...unit, typeOfUnit: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Type of Unit"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_typeOfUnit?.type === 'required' && 'Please enter Type of Unit'}
                      </span>
                    </Col>
                  </Row>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Unit in English</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_unit_english"
                      value={unit.unit_english}
                      onChange={(e) => setUnit({ ...unit, unit_english: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Unit in English"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_unit_english?.type === 'required' && 'Please enter unint in English'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes >
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Description</Text>
                    <Form.Control as='textarea'
                      placeholder='Description'
                      className='basecontrol custome-textarea'
                      name="txt_unitdescription"
                      rows={3}
                      cols={50}
                      value={unit.description}
                      onChange={(e) => setUnit({ ...unit, description: e.target.value })}
                      ref={register({ required: true, })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_unitdescription?.type === 'required' && 'Please enter Description'}
                      </span>
                    </Col>
                  </Row>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Description in English</Text>
                    <Form.Control as='textarea'
                      className='basecontrol custome-textarea'
                      name="txt_unitname_description"
                      placeholder='Description in English'
                      rows={3}
                      cols={50}
                      value={unit.name_description}
                      onChange={(e) => setUnit({ ...unit, name_description: e.target.value })}
                      ref={register({ required: true, })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_unitname_description?.type === 'required' && 'Please enter Description in english'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
            </Row>
          </Card>
          <Actions>
            {/* If you click the back button its is redirect to units list page.  */}
            <Link to='/unit/list'>
              <Button className='bttn bttn-transparent'>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page.  */}
            <Button className='bttn bttn-transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </Form>
      </Grid>
      {/* Add form, having input box, select box.  */}
    </Main>
  )
}

export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-unit-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. 
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}
