import React from 'react';
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap';
import './Manufacturer.scss';
import adImg from '../../../assets/images/ad-view-1.png'
//import adImg from '../../../assets/images/ad-view-1.png'
import quoteImg from '../../../assets/images/quote-view-1.png'
import '../../../common/components/pagination/Pagination.scss'
import { Text } from '../../../common/components/text/Text'
import { Space } from '../../../common/components/space/Space';
import { Main } from '../../../common/components/main/Main';
import { Button } from '../../../common/components/button/Button';
import Card from '../../../common/components/card/Card';
import { Table } from '../../../common/components/table/Table';
import Icons from '../../../common/components/icon/Icon';
import { format, parseISO } from 'date-fns'
import { manufacturerListGetAction, manufacturerSetSelectedAction } from "../../../store/action/manufacturerAction";
import { connect } from 'react-redux';
import classNames from 'classnames';
import { history } from '../../../store';
import { Flex } from '../../../common/components/flex/Flex';
import manufacturerService from '../../../services/manufacturerService'
import swal from 'sweetalert';
import { ReactNode } from 'react'
import { InputWithIcon, Select } from '../../../common/components/control/Control';
import Loader from 'react-loader-spinner';
import { ManufactFilterState } from '../filter/ManufactFilter';
import sortup from '../../../assets/images/sortup_icon.png';
import sortdown from '../../../assets/images/sortdown_icon.png';
import sortimage from '../../../assets/images/sorting.png';
import NumberFormat from "react-number-format";


const ManufacturerList = (props: any) => {
  const { manufacturer, isLoading, totalElement } = props
  const [display, setDisplay] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [searchInput, setSearchInput] = React.useState(ManufactFilterState.Manufactlistfilter.searchBoxInputManufactlist)
  const [searchM_email, setSearchM_email] = React.useState(ManufactFilterState.Manufactlistfilter.searchBoxInputManufactEmail)
  const [statuslist, setStatus] = React.useState(ManufactFilterState.Manufactlistfilter.searchBoxInputManufactStatus)
  const [findBy, setFindBy] = React.useState(ManufactFilterState.Manufactlistfilter.findbyIdname)
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  const [showDelete, setShow] = React.useState(false);
  const [deleteManufacturer, setDeleteManufacturer] = React.useState(0)
  const [sort, setSorting]=React.useState({values:ManufactFilterState.Manufactlistfilter.type,direction:ManufactFilterState.Manufactlistfilter.direction})
  const [retain,setRetain]=React.useState(0);


  // Used to get type and payload, call action and get list.   
  React.useEffect(() => {
    props.manufacturerListGetAction({ size: 10, page, username: searchInput||ManufactFilterState.Manufactlistfilter.searchBoxInputManufactlist, status: statuslist||ManufactFilterState.Manufactlistfilter.searchBoxInputManufactStatus, email: searchM_email||ManufactFilterState.Manufactlistfilter.searchBoxInputManufactEmail,values:ManufactFilterState.Manufactlistfilter.type,direction:ManufactFilterState.Manufactlistfilter.direction })
  }, [page,retain])

  // Check ships(list) length, add checked false in that array.  
  React.useEffect(() => {
    // console.log(manufacturer, 'manufacturer in manufacturer page ')
    if (manufacturer && manufacturer.length > 0) {
      setDisplay(() => {
        return manufacturer.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (manufacturer && manufacturer.length === 0) {
      setDisplay([])
    }
  }, [manufacturer])

  // Get totalPage in props and push into the pagination variable.   
  React.useEffect(() => {
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  //Seaarch Function

  const _searchFuntion = (value: string) => {
    setSearchInput(value)
    ManufactFilterState.Manufactlistfilter.searchBoxInputManufactlist=value;
    props.manufacturerListGetAction({ size: 10, page: 0, username: value, status: statuslist, email: searchM_email ,values:ManufactFilterState.Manufactlistfilter.type,direction:ManufactFilterState.Manufactlistfilter.direction})
    setPage(0)
  }

  //set status filter

  const _getStatus = (val: string) => {
    setStatus(val)
    ManufactFilterState.Manufactlistfilter.searchBoxInputManufactStatus=val;
    props.manufacturerListGetAction({ size: 10, page: 0, username: searchInput, status: val, email: searchM_email,values:ManufactFilterState.Manufactlistfilter.type,direction:ManufactFilterState.Manufactlistfilter.direction })
    setPage(0)
  }

   const role=localStorage.getItem("role")

  //mail function

  const _searchMailFuntion = (val: string) => {
    setSearchM_email(val)
    ManufactFilterState.Manufactlistfilter.searchBoxInputManufactEmail=val;
    props.manufacturerListGetAction({ size: 10, page: 0, username: searchInput, status: statuslist, email: val,values:ManufactFilterState.Manufactlistfilter.type,direction:ManufactFilterState.Manufactlistfilter.direction })
    setPage(0)
  }

  //Show all

  const _showAll = () => {
    props.manufacturerListGetAction({ size: 10, page: 0 })
    setSearchInput('')
    setStatus('')
    setSearchM_email('')
    ManufactFilterState.Manufactlistfilter.searchBoxInputManufactEmail='';
    ManufactFilterState.Manufactlistfilter.searchBoxInputManufactlist='';
    ManufactFilterState.Manufactlistfilter.searchBoxInputManufactStatus='';
    setSorting({values:'',direction:''})
  }

  //search input method

  const _getSearchValue = (val: string) => {
    setFindBy(val)
    ManufactFilterState.Manufactlistfilter.findbyIdname=val;

  }

  // Redirect to supporting owner detail page.  
  const _willViewDetail = (manufacturer: any) => {
    manufacturerSetSelectedAction(manufacturer)
    history.push(`/manufacturer/${manufacturer.id}`)
  }

  // Redirect to supporting owner edit page.  
  const _willViewEdit = (manufacturer: any) => {
    props.manufacturerSetSelectedAction(manufacturer)
    history.push(`/manufacturer/edit/${manufacturer.id}`)
  }

  //Redirect to supporting owners ads page. 
  const _willViewAdsDetail = (manufacturer: any) => {
    props.manufacturerSetSelectedAction(manufacturer)
    history.push(`/manufacturer/ads/${manufacturer.id}`)
  }

  //Redirect to Quotation list page

  const _willViewQuoteDetail = (manufacturer: any) => {
    history.push(`/Quotation/list/${manufacturer.id}`)
  }

  //Redirect to Transaction Page
  const _willViewTransactionDetail = (manufactruer: any) => {
    history.push(`/transaction/list/${manufactruer.id}`)
  }

  // Delete the pericular list.  
  const handleClose = () => setShow(false);
  const handleShow = (Manufacturer: any) => {
    setShow(true);
    setDeleteManufacturer(Manufacturer.id)
    // console.log(deleteAnode, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deleteManufacturer(deleteManufacturer)
  }

  const _deleteManufacturer = (id: any) => {
    return manufacturerService.deleteManufacturer(id).subscribe({
      next: (r) => {
        // console.log(r,'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.manufacturerListGetAction({ size: 10, page })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }

  // Prints first page pagination.  
  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  // While click the last page its move to last page.  
  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 9)
  }

  // While click the first button its move to first page.  
  const _goToFirstPage = () => {
    setPage(0)
    setHigh(9)
    setLow(1)
  }

  // Prints last page pagination button.  
  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  // Prints prev button.  
  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  // While click prev button go to the previous page of current page.  
  const _goPrevPage = () => {
    setPage(prev => {
      const newValue = prev - 1
      return newValue
    })
  }

  // Print next button.  
  const nextBtn = () => {
    if (page < totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  // While click next page button go to the next page of the current page.  
  const _goNextPage = () => {
    setPage(prev => {
      const newValue = prev + 1
      return newValue
    })
  }

  // While click one page number its move to that page.  
  const _goToPage = (page: any) => {
    setPage(page)
  }

  // Return the page number
  const ListItem = (page: any) => {
    return page.value + 1
  }

  // Prints the totalpages between prev, next button.  
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })
  
  const sortByFieldn=(value:String,directions:String)=>{
    if(directions==='asc')
    {
      setSorting({values:String(value),direction:String(directions)});
      ManufactFilterState.Manufactlistfilter.type=String(value);
      ManufactFilterState.Manufactlistfilter.direction=String(directions);
      props.manufacturerListGetAction({ size: 10, page: 0, username:searchInput, status: statuslist, email: searchM_email,type:value,direction:directions })
    setPage(0)
      
    }
    else if(directions==='desc')
    {
      setSorting({values:String(value),direction:String(directions)});
      ManufactFilterState.Manufactlistfilter.type=String(value);
      ManufactFilterState.Manufactlistfilter.direction=String(directions);
      props.manufacturerListGetAction({ size: 10, page: 0, username:searchInput, status: statuslist, email: searchM_email,type:value,direction:directions })
    setPage(0)
      
    }
    else if(directions==='')
    {
      setSorting({values:String(value),direction:String(directions)});
      ManufactFilterState.Manufactlistfilter.type=String(value);
      ManufactFilterState.Manufactlistfilter.direction=String(directions);
      props.manufacturerListGetAction({ size: 10, page: 0, username:searchInput, status: statuslist, email: searchM_email,type:value,direction:directions })
    setPage(0)
      
    }
    };

  return (
    <Main>
      <Text variant="title" color='black'>Supporting Owner List</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-2 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    {findBy === 'name' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Name.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === '' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Name.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === 'status' && (
                      <Select values={statuslist} name="status" onChange={(e) => _getStatus(e.target.value)} required>
                        <option value=''>-- Search by status --</option>
                        <option value="VERIFIED"> Active</option>
                        <option value="UNVERIFIED"> In Active</option>
                        <option value=''>ALL</option>
                      </Select>
                    )}
                    {findBy === 'M-email' && (
                      <InputWithIcon
                        type="text"
                        value={searchM_email}
                        onChange={(e) => _searchMailFuntion(e.target.value)}
                        placeholder="Search by Email..."
                        icon="search"
                        id="search-input"
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <Row className='m-0 p-0'>
                <Col xs='6' sm='4' md='4' lg='5' xl='4' className='m-0 p-0 mr-2'>
                  <Select values={findBy} name="" onChange={(e) => _getSearchValue(e.target.value)} required>
                    <option value=''>-- Search by --</option>
                    <option value="name"> By Name </option>
                    <option value="M-email"> By Email </option>
                    <option value="status"> By Status </option>
                  </Select>
                </Col>
                <Col xs='5' sm='4' md='4' lg='3' xl='3' className='m-0 p-0 '>
                  <div>
                    <Button variant='transparent' block={true} onClick={() => _showAll()}>
                      Show All
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SearchForm>
        {/* <div className='manufacturer-filter'>
            
          </div> */}
        <Space divheight={30} />
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>} {/* Check the list is loading, isloading is true its shows loading text.  */}
        {!isLoading && display.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No Supporting Owner Found</Text>
            <Space divheight={20} />
          </>
        )} {/* If isloading is false and also the list array length is 0 its shows no discount fount text.   */}
        {!isLoading && display.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Created Date</th>
                  <th>Name
                  &nbsp;
                    {sort.values==='username' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('username','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='username' ? (
                     <img onClick={() => sortByFieldn('username','asc')} src={sortimage}/>):(null)}
                     {sort.values==='username' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Email
                  &nbsp;
                    {sort.values==='email' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('email','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='email' ? (
                     <img onClick={() => sortByFieldn('email','asc')} src={sortimage}/>):(null)}
                     {sort.values==='email' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Position</th>
                  <th>Status
                  &nbsp;
                    {sort.values==='status' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('status','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='status' ? (
                     <img onClick={() => sortByFieldn('status','asc')} src={sortimage}/>):(null)}
                     {sort.values==='status' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}

                  </th>
                  <th>NaviCoins</th>
                  <th className='text-xl-center text-lg-center'>Payment History</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {display.map((data: any, i: any) => (
                  <tr key={i}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='Created Date'>
                      {data.created_at === null || '' ? (
                        <Text variant='caption' color='tabledata'>null</Text>
                      ) : (
                        <Text variant='caption' color='tabledata'>
                          {format(parseISO(data.registerdate), 'dd/MM/yyyy')}
                        </Text>
                      )}
                    </td>
                    <td data-label='Name'>
                      {String(data.name).includes('@') && (
                        String(data.name).substring(0, String(data.name).indexOf('@'))
                      )}
                      {!String(data.name).includes('@') && (
                        data.name
                      )
                      }
                    </td>
                    <td data-label='Email'>{data.email}
                    </td>
                    <td data-label='Position'>
                      {((data.position === '')||(data.position === null)) ? (
                        <Text className='txt-table-date' color='main'> - </Text>
                      ) : (
                        <Text className='txt-table-date' color='main'>{data.position}</Text>
                      )}
                    </td>
                    <td data-label='Status'>
                      {data.status === 'UNVERIFIED' || '' ? (
                        <Text className='txt-table-date' color='danger'>Inactive</Text>
                      ) : (
                        <Text className='txt-table-date' color='success'>Active</Text>
                      )}
                    </td>
                    <td data-label='NaviCoin'>{data.available_coins === null ? (
                      <Text className='txt-table-date' color='tabledata'> - </Text>
                    ) : (
                      // <Text className='txt-table-date' color='tabledata'>{data.available_coins}</Text>
                      <NumberFormat value={data.available_coins} displayType={'text'} thousandSeparator={true} />
                    )}
                    </td>
                    <td data-label='Payment History' className='text-xl-center text-lg-center'>
                      {data.payment_status === true && (
                        <OverlayTrigger overlay={
                          <Tooltip id="sOwner-edit"> Transaction Information
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewTransactionDetail(data)} disabled>
                            <Icons color="#52575a" icon="info" />
                          </Button>
                        </OverlayTrigger>
                      )}
                      {data.payment_status === false && (
                        <OverlayTrigger overlay={
                          <Tooltip id="sOwner-edit"> No Transaction for this Owner
                          </Tooltip>
                        }>
                          <Button className='link_button'
                            // onClick={() => _willViewTransactionDetail(data)}
                            disabled
                          >
                            <Icons color="#52575a" icon="info" />
                          </Button>
                        </OverlayTrigger>
                      )}
                    </td>
                    {/* <td data-label='' className='text-xl-center text-lg-center'>
                      
                    </td> */}
                    <td>
                      <TableActions>
                        {data.ad_status === true && (
                          <OverlayTrigger overlay={
                            <Tooltip id="sOwner-Adsview"> ADS Detail
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewAdsDetail(data)} disabled>
                              {/* <Icons color="#52575a" icon="movie" />  */}
                              <img src={adImg} alt='ad-view' />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {data.ad_status === false && (
                          <OverlayTrigger overlay={
                            <Tooltip id="sOwner-AdsviewDisable"> User does not have any ADS
                            </Tooltip>
                          }>
                            <Button disabled className='link_button'
                            // onClick={() => _willViewAdsDetail(data)}
                            >
                              <img src={adImg} alt='ad-view' />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {data.quote_status === true && (
                          <OverlayTrigger overlay={
                            <Tooltip id="sOwner-edit"> Quote Detail
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewQuoteDetail(data)}>
                              <img src={quoteImg} alt='quote-view' />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {data.quote_status === false && (
                          <OverlayTrigger overlay={
                            <Tooltip id="sOwner-edit"> No Quote for this Owner
                            </Tooltip>
                          }>
                            <Button className='link_button'
                              disabled
                            >
                              <img src={quoteImg} alt='quote-view' />
                            </Button>
                          </OverlayTrigger>
                        )}
                        <OverlayTrigger overlay={
                          <Tooltip id="sOwner-edit"> view
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(data)}>
                            <Icons color="#52575a" icon="visibility" />
                          </Button>
                        </OverlayTrigger>
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="sOwner-delete"> Delete option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(data)} disabled>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        ):(
                          <OverlayTrigger overlay={
                            <Tooltip id="sOwner-delete"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(data)}>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </TableActions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text> {/* Display the total elements.  */}
                {display.length === 1 && (
                  <>
                    <b>
                      {display.length}
                      {/* {((page)*10)+1} - {(display.length+(page)*10)} */}
                    </b> of {totalElement} Supporting Owner
                  </>
                )}
                {display.length > 1 && (
                  <>
                    <b>
                      {display.length}
                      {/* {((page)*10)+1} - {(display.length+(page)*10)} */}
                    </b> of {totalElement} Supporting Owners
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()} {/* First page button.   */}
                {prevBtn()} {/* Previous button.   */}
                {PageNumber} {/* Totalpages.   */}
                {nextBtn()} {/* Next button.   */}
                {lastPage()} {/* Last page button.   */}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {/* If modal is true goto delete page with the props of onclose function */}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form Manufacturer list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Main>
  )
}
export interface TablefooterProps {
  children?: React.ReactNode
}

// Table footer style.  
const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

// Table body style.  
const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

//Search Form Component  for displaying serarch box, and add ship button
const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form className={search} autoComplete='off'>
      {props.children}
    </form>
  )
}

// Declare the variable.  
const mapStateToProps = (state: any) => ({
  manufacturer: state.manufacturer.manufacturer,
  isLoading: state.manufacturer.isLoading,
  error: state.manufacturer.error,
  totalElement: state.manufacturer.totalElement,
  totalPage: state.manufacturer.totalPage
})

// Connect to the action and get the list, totalpage and total elements.  
export default connect(
  mapStateToProps,
  {
    manufacturerListGetAction,
    manufacturerSetSelectedAction,
  }

)(ManufacturerList)