import React from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Button } from '../../../../common/components/button/Button'
import noimg from '../../../../assets/images/NoImg.jpg';
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import shipService from '../../../../services/shipService'
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import removeicon from '../../../../assets/images/Remove.png'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { Label } from "../../../../common/components/label/Label";
import { shipOwnerSetSelectedAction } from '../../../../store/action/shipActions';
import { history } from '../../../../store';
import { connect } from 'react-redux';
import { Col, Row, Image } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import userService from '../../../../services/userService';
import swal from 'sweetalert';
import editicon from '../../../../assets/images/editone.png';
import { useRef } from 'react';

interface ShipOwnerViewProps {
    ownedCompany: any[]
}

interface ShipOwnerProps {
    id:string,
    address: string,
    created_date: string,
    dob: string,
    email: string,
    is_active: boolean,
    name: string,
    phone: string,
    phone_code: string,
    profile_img: string,
    status: string,
}

const ShipOwnerDetail = (props: any) => {
    const { match } = props
    const [isLoading, setLoading] = React.useState(true)
    const [form, setForm] = React.useState<ShipOwnerViewProps>({
        ownedCompany: []
    })
    const [shipOwner, setshipOwner] = React.useState<ShipOwnerProps>({
        id:"",
        address: "",
        created_date: "",
        dob: '',
        email: "",
        is_active: false,
        name: "",
        phone: "",
        phone_code: "",
        profile_img: '',
        status: "",
    })
    React.useEffect(() => {
        const { id } = match.params
        _getOwnerDetail(id)
        _getOwnedCmpDetail(id)
    }, [])
    const _getOwnedCmpDetail = (id: any) => {
        setLoading(true)
        return shipService.getOwnedCmpDetail(id).subscribe({
            next: r => {
                // console.log(r.data)
                setForm({ ...form, ownedCompany: r.data })
                r.data.map((data: any, i: any) => {
                    return form.ownedCompany.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    }

    const role = localStorage.getItem("role")

    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<any>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageUserAPI(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const _removeUserImg = (id: any) => {
        return userService.removeUserImg(id).subscribe({
            next: (r) => {
                // console.log(r, 'response')
                // alert(true)
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
                // history.history(`/ships/${id}/`)
            },
            error: err => {
                // alert(false)
                swal("OOPS", String(err), "error")
            }
        })
    }

    const ImageUserAPI = (imageurl: any) => {
        const { id } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        userService.UpdateUserImage(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                //window.location.reload();
                history.push("/ship-owners/list")
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }

    const _getOwnerDetail = (id: any) => {
        setLoading(true)

        return shipService.getShipOwnerDetail(id).subscribe({
            next: r => {
                // console.log(r, 'r')
                // const { data } = r.data
                // console.log(r.data, 'detail view data')
                props.shipOwnerSetSelectedAction(r.data[0])
                var isActiveowner
                var dob_owner
                // if (r.data[0].is_active === true) {
                //     isActiveowner = 'true'
                // }
                // else {
                //     isActiveowner = 'false'
                // }
                // if(r.data[0].dob === null){
                //     dob_owner = 'null'
                // }
                // else{
                //     don_owner = r.data[0].dob
                // }
                setshipOwner({
                    id:r.data[0].id,
                    address: r.data[0].address,
                    created_date: r.data[0].created_date,
                    dob: r.data[0].dob,
                    email: r.data[0].email,
                    is_active: r.data[0].is_active,
                    name: r.data[0].name,
                    phone: r.data[0].phone,
                    phone_code: r.data[0].phone_code,
                    profile_img: r.data[0].profile_img,
                    status: r.data[0].status,
                })
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    // const _willDelete = (id:any) => {
    //     setDelete(true)
    //     return dockService.deleteDock().subscribe({
    //         next: () => {
    //         setDelete(false)
    //         history.goBack()
    //     },
    //     error: err => {
    //         console.error(err)
    //         setDelete(false)
    //         }
    //     })
    // }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    return (
        <Main>
            {/* {console.log(dock, 'dock detail')}
            {console.log(dock.username, 'User name')} */}
            {/* <Text variant="title" >{shipOwner.name}</Text> */}
            <div className='title-name-block'>
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                    {shipOwner.profile_img === null && (
                        <Image src={noimg} alt='logo' roundedCircle />
                    )}
                    {shipOwner.profile_img === '' && (
                        <Image src={noimg} alt='logo' roundedCircle />
                    )}
                    {shipOwner.profile_img !== null && shipOwner.profile_img !== '' && (
                        <Image src={shipOwner.profile_img} alt='logo' roundedCircle />
                    )}
                </div>
                {role === 'VISITOR' && (
                    <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                        <label className="custom-file-upload">
                            <Image src={editicon} alt='logo' />
                            <input
                                ref={upload}
                                type="file"
                                onChange={getBase64}
                            />
                        </label>
                    </div>
                )}
                <Text variant='title' color='main' className='txt-capitalize'>
                    {/* {String(shipOwner.name).substring(0,String(shipOwner.name).indexOf('@'))} */}
                    {shipOwner.name}
                </Text>
                {console.log(shipOwner.is_active, 'is active')}
                <Label className={shipOwner.is_active === false ? 'danger' : 'warning'} >
                    {shipOwner.is_active === false ? 'In Active' : 'Active'}
                </Label>
            </div>
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                <Col sm='12' md='12' lg='8' xl='8' className='m-0 p-0 align-top'>
                    <Card title="Ship Owner Detail">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Name</Text>
                                        <Text variant='subtitle' color="main" bold>{shipOwner.name}</Text>
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'> Mobile</Text>
                                        {shipOwner.phone === '' || null ? (
                                            <Text color='main' bold variant='subtitle'> - </Text>
                                        ) : (
                                            <>
                                                {shipOwner.phone_code === '' ? (
                                                    <Text color='main' bold variant='subtitle'>{shipOwner.phone_code}
                                                        <span>
                                                            <Text color='main' bold variant='subtitle'>{shipOwner.phone}</Text>
                                                        </span>
                                                    </Text>
                                                ) : (
                                                    <>
                                                        <Text color='main' bold variant='subtitle'>{shipOwner.phone_code} &nbsp;
                                                            <span>
                                                                <Text color='main' bold variant='subtitle'>{shipOwner.phone}</Text>
                                                            </span>
                                                        </Text>
                                                    </>
                                                )
                                                }
                                            </>
                                        )}
                                    </div>
                                    {shipOwner.dob === null && (
                                        <div>
                                            <Text color='main' variant='subtitle'>D.O.B</Text>
                                            <Text color="main" variant='subtitle' bold>  Nill</Text>
                                        </div>
                                    )}
                                    {shipOwner.dob !== null && (
                                        <div>
                                            <Text color='main' variant='subtitle'> D.O.B</Text>
                                            <Text color="main" variant='subtitle' bold> {shipOwner.dob}</Text>
                                        </div>
                                    )}
                                    {shipOwner.email === null && (
                                        <div>
                                            <Text color='main' variant='subtitle'> Email</Text>
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        </div>
                                    )}
                                    {shipOwner.email !== null && (
                                        <div>
                                            <Text color='main' variant='subtitle'> Email</Text>
                                            <Text color="main" variant='subtitle' bold> {shipOwner.email}</Text>
                                        </div>
                                    )}
                                    {shipOwner.address === null && (
                                        <div>
                                            <Text color='main' variant='subtitle'> Address</Text>
                                            <Text color="main" variant='subtitle' bold>  Nill</Text>
                                        </div>
                                    )}
                                    {shipOwner.address !== null && (
                                        <div>
                                            <Text color='main' variant='subtitle'> Address</Text>
                                            <Text color="main" variant='subtitle' bold> {shipOwner.address}</Text>
                                        </div>
                                    )}
                                </Attributes>
                            </Col>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>

                                    <div>
                                        <Text color='main' variant='subtitle'>Status</Text>
                                        {shipOwner.status === 'VERIFIED' ? (
                                            <Text color='warning' variant='subtitle' bold>Verified</Text>
                                        ) : (
                                            <Text color='danger' variant='subtitle' bold>Not Verified</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'> Created Date</Text>
                                        {shipOwner.created_date === null && (
                                            <Text color="main" variant='subtitle' bold>
                                                not found
                                            </Text>
                                        )}
                                        {shipOwner.created_date !== null && (
                                            <Text color="main" variant='subtitle' bold>
                                                {format(parseISO(shipOwner.created_date), 'dd/MM/yyyy')}
                                            </Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm='12' md='12' lg='4' xl='4' className='m-0 p-0 align-top'>
                    {/* <div className='ml-xl-3  mt-sm-4 mt-md-4 mt-lg-4 mt-xl-0 view_page_xs_margin'>

                    </div> */}
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Card title="Profile Picture">
                            <div className='user-mainBlock'>
                                {shipOwner.profile_img === null && (
                                    <div className='userProfile-block'>
                                        <Image roundedCircle src={noimg} alt='logo' />
                                    </div>
                                )}
                                {shipOwner.profile_img === '' && (
                                    <div className='userProfile-block'>
                                        <Image roundedCircle src={noimg} alt='logo' />
                                    </div>
                                )}
                                {shipOwner.profile_img !== null && shipOwner.profile_img !== '' && (
                                    <div className='userProfile-block'>
                                        <Image roundedCircle src={shipOwner.profile_img} alt='logo' />
                                    </div>
                                )}
                            </div>
                            {((shipOwner.profile_img === null)||(shipOwner.profile_img === '')) && role === 'VISITOR' &&(
                                <div className='imageEditBlock'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            <Image roundedCircle src={editicon} alt='logo' />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                </div>
                            )}
                            {shipOwner.profile_img !== null && shipOwner.profile_img !== '' && role === 'VISITOR' &&(
                                <div className='imageEditBlock'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            <Image roundedCircle src={editicon} alt='logo' className="editIconRemove" />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>

                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={removeicon} alt='logo' onClick={() => _removeUserImg(shipOwner.id)} />
                                        </Flex>
                                    </label>
                                </div>
                            )}
                        </Card>
                    </div>
                </Col>
            </Row>
            <Row className='m-0 p-0 mt-2'>
                <Col xs='12' md='12' lg='8' xl='8' className='m-0 p-0'>
                    <Card title="Company Information">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                {/* {console.log(form.ownedCompany, 'company in owner view')} */}
                                {form.ownedCompany.length === 0 && (
                                    <>
                                        <Space divheight={20} />
                                        <Text color='main' variant='subtitle' center> Not Associated with any Company Yet </Text>
                                        <Space divheight={20} />
                                    </>
                                )}
                                {form.ownedCompany.map((data: any, i: any) => [
                                    <Attributes className='view_Page'>
                                        <div>
                                            <Text color='main' variant='subtitle'>Company Name</Text>
                                            <Text variant='subtitle' color="main" bold>{data.company_name}</Text>
                                        </div>
                                        <div>
                                            <Text color='main' variant='subtitle'>City</Text>
                                            <Text variant='subtitle' color="main" bold>{data.city_name}</Text>
                                        </div>
                                    </Attributes>
                                ])}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='12' xl='8'>
                    <Actions>
                        <Link to='/ship-owners/list'>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                    </Actions>
                </Col>
            </Row>
        </Main>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-center' className={action}>
            {props.children}
        </Flex>
    )
}
export interface CompanyProps {
    children?: React.ReactNode
    className?: string;
}
export const Company = ({ children, className, ...props }: CompanyProps) => {
    const attribute = classNames('company-container', className);

    return (
        <div {...props} className={attribute} >
            {children}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    shipOwner: state.ship.selectedOwner
})

export default connect(
    mapStateToProps,
    {
        shipOwnerSetSelectedAction
    }
)(ShipOwnerDetail)