import React, { useState } from 'react'
import Modal from '../../../common/components/modal/Modal'
import { Text } from '../../../common/components/text/Text'
import authService from "../../../services/authService";
import { Input } from '../../../common/components/control/Control'
import { Button } from '../../../common/components/button/Button'
import { Flex } from '../../../common/components/flex/Flex'
import { Attributes } from '../../../common/components/attribute/Attribute'
import Icons from '../../../common/components/icon/Icon'
import { Space } from '../../../common/components/space/Space'
import classNames from 'classnames'
import { Form } from 'react-bootstrap'
import swal from 'sweetalert';
import { JsxChild } from 'typescript'
import { history } from '../../../store'
import Loader from 'react-loader-spinner';

export const ResetPassword = (props: any) => {
    const { onClose } = props
    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [isLoading, setLoading] = React.useState(false)

    const _didSubmit = () => {
        // alert(1)
        if(password === confirmPassword){
            // alert(2)
            setLoading(true)
            return authService.resetPassword({
                password,
            }).subscribe({
                next: (res: any) => {
                    // alert('su')
                    setLoading(false) 
                    // console.log(res , 'response in login page')
                    // const { data } = res
                    // props.accountSetAction(data)
                    swal("Great", "Password Changed Successfully", "success")
                    onClose()
                    // alert('su')
                    // authService.persistAccount(String(res.data.access_token))   
                    // history.push('/')
                },
                error: err => {
                    // alert(3)
                    setLoading(false)
                    swal("OOPS", err.message, "error")
                    onClose()
                    // alert('fail')
                    // setError(err.message)
                }
            })
        }
        else{
            // alert(4) 
            setLoading(true)
            swal("OOPS", "Password does not match", "error")
            onClose()
            setLoading(false)
        }
    }
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }
    return (
        <Modal
            title='CHANGE YOUR PASSWORD'
            onClose={() => onClose()}
        >
            <ModalBody>
                <StyledForm 
                //onSubmit={(e: any) => _didSubmit(e)}
                >
                    <Attributes className='forgotPassword_modal'>
                        <div>
                            <Text variant='subtitle'>New Password</Text>
                            <Input
                                name="new_password"
                                value={password}
                                required
                                placeholder="New Password"
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                            />
                        </div>
                        <div>
                            <Text variant='subtitle'>Confirm Password</Text>
                            <Input
                                name="confirm_password"
                                value={confirmPassword}
                                required
                                placeholder="Confirm Password"
                                onChange={(e) => setconfirmPassword(e.target.value)}
                                type="password"
                            />
                        </div>
                    </Attributes>
                    <Space divheight={20} />
                    <Flex jc='jc-center'>
                        <Button
                            block
                            type="button"
                            onClick={() => onClose()}
                            variant='transparent'
                            className='bttn-model-discount'>
                            <Flex>
                                <Icons icon="arrow_back" />
                                BACK
                            </Flex>
                        </Button>
                        <Button block type="submit"
                             onClick={() => _didSubmit()}
                         variant='transparent' className='bttn-model-discount'>
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SUBMIT
                            </Flex>
                        </Button>
                    </Flex>
                </StyledForm>
            </ModalBody>
        </Modal>
    )
}

export interface ModalbodyProps {
    children?: React.ReactNode
}

export const ModalBody = ({ children, ...props }: ModalbodyProps) => {

    const modal = classNames('modalbody-container')

    return (
        <div className={modal}>
            {children}
        </div>
    )

}
export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-discount');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}
// export interface AttributeProps {
//     children?: React.ReactNode
// }
// export const Attributes = ({ children, ...props }: AttributeProps) => {
//     const attribute = classNames('attribute-container');

//     return (
//         <div {...props} className={attribute} >
//             {children}
//         </div>
//     )
// }