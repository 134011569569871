import{PAINT_LIST_GET,
    PAINT_LIST_GET_F,
    PAINT_LIST_GET_R,
    PAINT_SELECTED_SET
} from '../actionType'

export const paintListGetAction = (params : any) => ({
type: PAINT_LIST_GET,
payload: params
})

export const paintListGetFAction = (params : any) => ({
type: PAINT_LIST_GET_F,
payload: params
})

export const paintListGetRAction = (params : any) => ({
type: PAINT_LIST_GET_R,
payload: params
})

export const paintSetSelectedAction = (paint : any) => ({
type: PAINT_SELECTED_SET,
payload: paint
})