import React from 'react';
import './Text.scss'
import classNames from 'classnames'

//set type for Textvariant it comes with defferent font size
export type TextVariant = 'title'| 'title-sm' | 'paragraph' | 'subtitle' | 'caption'  | 'Detail-page-text' | 'capitalize' | 'uppercase' | 'confirmation';

//set type for Colorvariant it comes with defferent font color
export type ColorVariant = 'white' | 'black' |'success' | 'danger' | 'warning' | 'main' |'tablehead'|'tabledata' ;

//set interface for Text component
export interface TextProps {
    id ? : string;
    className?: string;
    values?: string;
    variant?: TextVariant;
    color?:ColorVariant | string;
    bold?:boolean;
    center?:boolean;
    children?:React.ReactNode;
    onClick?: (e: React.MouseEvent) => void;

}
//set class name based on textvariant
export function variantClassName(variant: TextVariant | undefined): string {
    return variant ? `txt-${variant}` : 'txt-paragraph';
}
//set classname based on color variant
export function colorClassName(color: ColorVariant | string | undefined): string {
    return color ? `txt-${color}` : 'txt-main';
}
//Text component used to display any text
export const Text = ({ variant, className, values, color,onClick, id, bold,center,children, ...props } : TextProps) => {
    const txtClassNames = classNames('txt', variantClassName(variant),colorClassName(color), 
    { 'txt-bold': bold === true }, { 'txt-center': center === true },
    className);

    return (
        <span {...props} className={txtClassNames} id={id} onClick={onClick}>
            {values}
            {children}
        </span>
    )
}

