import React, { useEffect } from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Radio, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import swal from 'sweetalert';
import paintService from '../../../../services/paintService'
import '../../../../styles/variables/Common.scss'
import { useForm } from 'react-hook-form'
import codeService from '../../../../services/codeService'
import Loader from 'react-loader-spinner'


interface PaintAddProps {
  Primer: string;
  Mid_coat: string;
  Finish_coat: string;
  Paint_name: string;
  Generic_type: string;
  vs: string | number;
  Price_litre: string | number;
  Description: string;
  Type: string;
  side: string;
  dft: string | number;
  company: string,
  supplier: string,
  companies: any[],
  suppliers: any[],
}

interface FormData {
  radio_Primer: string;
  radio_Mid_coat: string;
  radio_Finish_coat: string;
  txt_Paint_name: string;
  txt_Generic_type: string;
  txt_vs: string;
  txt_Price_litre: string;
  txt_paintDescription: string;
  txt_Type: string;
  txt_Dft: string;
  txt_Side: string;
  txt_supplier: string,
  txt_company: string,
}
export const PaintAdd = (props: PaintAddProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
});
  const [loading, setLoading] = useState(false);
  const [paintaddflag, setPaintAddFlag] = React.useState(false)
  const [paint, setPaint] = useState<PaintAddProps>({
    Primer: 'false',
    Mid_coat: 'false',
    Finish_coat: 'false',
    Paint_name: '',
    Generic_type: '',
    vs: '',
    Price_litre: '',
    Description: '',
    Type: '',
    dft: '',
    side: '',
    supplier: '',
    company: '',
    companies: [],
    suppliers: [],
  },
  )
  const _getSupplierDetail = () => {
    return codeService.getSupplierDD().subscribe({
      next: r => {

        setPaint({ ...paint, suppliers: r.data })
        r.data.map((data: any, i: any) => {
          return paint.suppliers.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  }
  const _getCompanyDetail = (value: any) => {
    // setPaint({...paint, supplier:value})
    var supplier_id: any = ''
    {
      paint.suppliers.map((data, i) => {
        if (value === data.name) {
          supplier_id = data.id
          // console.log(city_Code,'city code')
          codeService.getSupplierCompanyDD(supplier_id).subscribe({
            next: r => {
              // console.log(r.data)
              setPaint({ ...paint, companies: r.data, supplier: value })
              r.data.map((data: any, i: any) => {
                return paint.companies.push(data)
              })
            },
            error: err => {
              console.error(err)
            }
          })
        }
        else {
          setPaint({ ...paint, supplier: value, company: '', companies: [] })
        }
      })
    }
  }
  useEffect(() => {
    _getSupplierDetail();
  }, [])
  // After enter all details by user and click submit button values assign to some variable then it is used as input parameter for addpaint api call. 
  const _didPaintAdd = () => {
    // e.preventDefault()
    var primerCoat: boolean = false
    var midCoat: boolean = false
    var finishCoat: boolean = false
    if (paint.Primer === 'true') {
      primerCoat = true
    }
    else {
      primerCoat = false
    }
    if (paint.Mid_coat === 'true') {
      midCoat = true
    }
    else {
      midCoat = false
    }
    if (paint.Finish_coat === 'true') {
      finishCoat = true
    }
    else {
      finishCoat = false
    }
    var supId: string = ''
    var cmp_id
    paint.companies.map((data, i) => {
      if (paint.company === data.name) {
        cmp_id = data.id;
      }
    })
    paint.suppliers.map((data: any, i: any) => {
      if (paint.supplier === data.name) {
        supId = data.id;
      }
    })
    const updatedForm = {
      supplier_id: Number(supId),
      company_id: Number(cmp_id),
      primer: primerCoat,
      mid_coat: midCoat,
      generic_type: paint.Generic_type,
      finish_coat: finishCoat,
      paint_name: paint.Paint_name,
      vs: Number(paint.vs),
      price_litre: Number(paint.Price_litre),
      description: paint.Description,
      paint_type: paint.Type,
      side: [paint.side],
      dft: Number(paint.dft),
      paint_id: 0
    }

    // API call for addpaint with data, added to list. 
    // console.log(updatedForm, "updatedform")
    if (paintaddflag === true) {
      setLoading(true)
      paintService.addPaint(updatedForm).subscribe({
        next: (r) => {
          // console.log(r, 'response')
          swal("Great", "Paint  Added Successfully", "success");
          history.push('/paint/list');
          setLoading(false);
        },
        error: err => {
          swal("OOPS", String(err), "error");
          setLoading(false);
        }
      })
    }
    else {
      console.log(paintaddflag)
    }
  }

  onsubmit = (e: any) => {
    // e.preventDefault()
    console.log(Object.keys(errors).length, 'error length')
    if (Object.keys(errors).length === 0) {
      _didPaintAdd()
      setPaintAddFlag(true)
    }
    else {
      setPaintAddFlag(false)
    }
  }

  if (loading) {
    return <Text center>
      <Loader
        type="Rings"
        color="#233269"
        height={100}
        width={100}
        timeout={30000}
      />
    </Text>
  }


  return (
    <Main>
      <Text variant="title" color='black'>Add Paint Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='paintAddForms'>
          <Card title="Fill All the Information Below">
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Paint Name</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_Paint_name"
                      value={paint.Paint_name}
                      onChange={(e) => setPaint({ ...paint, Paint_name: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Paint Name"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_Paint_name?.type === 'required' && 'Please enter Paint Name'}
                      </span>
                    </Col>
                  </Row>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Paint Type</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_Type"
                      value={paint.Type}
                      onChange={(e) => setPaint({ ...paint, Type: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Paint Type"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_Type?.type === 'required' && 'Please enter Type'}
                      </span>
                    </Col>
                  </Row>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Generic Type</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_Generic_type"
                      value={paint.Generic_type}
                      onChange={(e) => setPaint({ ...paint, Generic_type: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Generic Type"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_Generic_type?.type === 'required' && 'Please enter Generic Type'}
                      </span>
                    </Col>
                  </Row>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Price Per Litre (Rp.)</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_Price_litre"
                      value={paint.Price_litre}
                      onChange={(e) => setPaint({ ...paint, Price_litre: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Price"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_Price_litre?.type === 'required' && 'Please enter Price'}
                      </span>
                    </Col>
                  </Row>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Side</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_Side"
                      value={paint.side}
                      onChange={(e) => setPaint({ ...paint, side: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Side"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_Side?.type === 'required' && 'Please enter Side'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes >
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >DFT</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_Dft"
                      value={paint.dft}
                      onChange={(e) => setPaint({ ...paint, dft: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="DFT"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_Dft?.type === 'required' && 'Please enter DFT'}
                      </span>
                    </Col>
                  </Row>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >VS %</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_vs"
                      value={paint.vs}
                      onChange={(e) => setPaint({ ...paint, vs: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="VS %"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_vs?.type === 'required' && 'Please enter VS %'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Description</Text>
                    <Form.Control as="textarea"
                      className='basecontrol custome-textarea'
                      placeholder='Description'
                      rows={3}
                      cols={50}
                      name="txt_paintDescription"
                      value={paint.Description}
                      onChange={(e) => setPaint({ ...paint, Description: e.target.value })}
                      ref={register({ required: true, })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_paintDescription?.type === 'required' && 'Please enter Description'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
            </Row>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='7' xl='7' className='p-0 m-0'>
                <Attributes>
                  <div>
                    <Text color='tablehead' variant='subtitle' >Supporting Owner</Text>
                    <input className='basecontrol cutome-selectInputnxt'
                      value={paint.supplier} id="txt_supplier"
                      list='anodesupplier'
                      placeholder='-- Select Owner --'
                      name="txt_supplier" ref={register({ required: true, })}
                      onChange={(e) => _getCompanyDetail(e.target.value)}>
                    </input>
                    <datalist id="anodesupplier">
                      {paint.suppliers.map((data, i) => {
                        return <option key={i} value={data.name}></option>
                      })}
                    </datalist>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_supplier?.type === 'required' && 'Please Select Owner'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle' >Company Name</Text>
                    <input className='basecontrol cutome-selectInputnxt'
                      ref={register({ required: true, })}
                      value={paint.company}
                      id='txt_company' list='company-details'
                      placeholder='-- Select Owner First --'
                      name="txt_company" onChange={(e) => setPaint({ ...paint, company: e.target.value })} >
                      {/* <option value=''> --Select port city first--</option> */}
                    </input>
                    <datalist id="company-details">
                      {paint.companies.length === 0 && (
                        <option className='option-placeholder'> --NO Company for selected Owner-- </option>
                      )}
                      {paint.companies.map((data: any, i: any) => {
                        return <option key={i} value={data.name}></option>
                      })}
                    </datalist>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_company?.type === 'required' && 'Please select Company'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
            </Row>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <Attributes>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle'>Primer</Text>
                    <div>
                      <Form.Check type='radio' className='RadioButton'
                        id='primer' name='primer' value={paint.Primer}
                        onChange={(e) => setPaint({ ...paint, Primer: 'true' })}
                        label='Yes'></Form.Check>
                      <Form.Check type='radio' className='RadioButton'
                        id='nonPrimer' name='primer' value={paint.Primer}
                        onChange={(e) => setPaint({ ...paint, Primer: 'false' })}
                        label='No'></Form.Check>
                    </div>
                  </div>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Mid Coat</Text>
                    <div>
                      <Form.Check type='radio' className='RadioButton'
                        id='midCoat' name='midCoat' value={paint.Mid_coat}
                        onChange={(e) => setPaint({ ...paint, Mid_coat: 'true' })}
                        label='Yes'></Form.Check>
                      <Form.Check type='radio' className='RadioButton'
                        id='nonMidCoat' name='midCoat' value={paint.Mid_coat}
                        onChange={(e) => setPaint({ ...paint, Mid_coat: 'false' })}
                        label='No'></Form.Check>
                    </div>
                  </div>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Finishing Coat</Text>
                    <div>
                      <Form.Check type='radio' className='RadioButton'
                        id='finish' name='finishCoat' value={paint.Finish_coat}
                        onChange={(e) => setPaint({ ...paint, Finish_coat: 'true' })}
                        label='Yes'></Form.Check>
                      <Form.Check type='radio' className='RadioButton'
                        id='nonfinish' name='finishCoat' value={paint.Finish_coat}
                        onChange={(e) => setPaint({ ...paint, Finish_coat: 'false' })}
                        label='No'></Form.Check>
                    </div>
                  </div>
                </Attributes>
              </Col>
            </Row>
          </Card>
          <Actions>
            {/* If you click the back button its is redirect to paints list page.  */}
            <Link to='/paint/list'>
              <Button className='bttn bttn-transparent'>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page.  */}
            <Button className='bttn bttn-transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </Form>
      </Grid>
      {/* Add form, having input box, select box.  */}
    </Main>
  )
}


export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-paint-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. 
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}
