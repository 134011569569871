import React from 'react'
import PropTypes from 'prop-types'

//set interface(state) for ErrorBoundary
interface ErrorBoundaryState{
    hasError ?: boolean;
}
//Error boundary component
class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  static propTypes: { children: PropTypes.Validator<PropTypes.ReactNodeLike> }
  constructor(props:any) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }
  render() {    
    if (this.state.hasError) {
      return <p>Something went wrong.</p>
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
}

export default ErrorBoundary
