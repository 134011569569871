export const WEIGHT_UNIT_DEFAULT = {
    code: '1',
    name: 'DWT'
  }
  
  export const LENGTH_UNIT_DEFAULT = {
    code: '4',
    name: 'm'
  }
  
  export const DRAFT_UNIT_DEFAULT = {
    code: '4',
    name: 'm'
  }
  
  export const BEAM_UNIT_DEFAULT = {
    code: '4',
    name: 'm'
  }

  export const DEPTH_UNIT_DEFAULT = {
    code: '4',
    name: 'm'
  }
  