import React, { useState } from 'react'
import Modal from '../../../../common/components/modal/Modal'
import { Text } from '../../../../common/components/text/Text'
import {Attributes} from '../../../../common/components/attribute/Attribute'
// import { Input, Radio, Select } from '../../../../common/components/control/Control'
import { Button } from '../../../../common/components/button/Button'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import swal from 'sweetalert'
import { connect } from 'react-redux';
import { shipCompanySetSelectedAction } from '../../../../store/action/shipActions';
import codeService from '../../../../services/codeService'
import shipService from '../../../../services/shipService'

interface ViewProps{
    shipTotal: any[]
}

const ShipView = (props: any) => { 
    const { onClose } = props
    const { handleSubmit } = useForm();
    // const { cmpid } = props
    const { ship } = props
    const [display, setDocks] = React.useState<any>([])
    const [form, setForm] = useState<ViewProps>({
        shipTotal: []
    })
    const _getShipDetail = (() => {
        // console.log(ship,'ship in shipView page')
        return shipService.getShipViewDetail(ship.company_id).subscribe({
            next: r => {
                // console.log(r.data,'data inside ship view')
                setForm({ ...form, shipTotal: r.data })
                r.data.map((data: any, i: any) => {
                    return form.shipTotal.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })


    React.useEffect(() => {
        _getShipDetail()
    }, [])

    onsubmit = (e) => {
        // console.log(form)
        e.preventDefault()
        const { onClose } = props
        onClose()
    }
    // console.log(form.dock, 'form.dock')
    return (
        <Modal
            onClose={() => onClose()}
            title={'Ship Detail'}
            className="dock-new-mode"
            >
            <ModalBody>
                    {form.shipTotal.length === 0 && (
                <>
                    <Space divheight={20} />
                    <Text variant='subtitle'center color="main" bold>No Ship Associated with this Company</Text>
                    <Space divheight={20} />
                </>
                )}
                {form.shipTotal.map((ship: any, i: any) => [
                    <Attributes id={ship.ship_id} className ='dock-view'>
                        <div>
                            <Text variant='subtitle' color="main" bold>{ship.ship_name}</Text>
                            <Text color="main" variant='subtitle' bold> {ship.country_name} </Text>
                        </div>
                    </Attributes>
                ])}
                <Space divheight={5} />
                <Flex jc='jc-center'>
                    <Button
                        block
                        type="button"
                        onClick={() => onClose()}
                        variant='transparent'>
                        <Flex>
                            <Icons icon="arrow_back" />
                                BACK
                            </Flex>
                    </Button>
                </Flex>
            </ModalBody>
        </Modal>
    )
}

export interface ModalbodyProps {
    children?: React.ReactNode
}

const ModalBody = ({ children, ...props }: ModalbodyProps) => {

    const modal = classNames('modalbody-container')

    return (
        <div className={modal}>
            {children}
        </div>
    )

}

const mapStateToProps = (state: any) => ({
    ship: state.ship.selectedCompany,

})
export default connect(
    mapStateToProps,
    {
        shipCompanySetSelectedAction
    }
)(ShipView)
