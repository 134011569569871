import{UNITS_LIST_GET,
    UNITS_LIST_GET_F,
    UNITS_LIST_GET_R,
    UNITS_SELECTED_SET
} from '../actionType'

export const unitsListGetAction = (params : any) => ({
    type: UNITS_LIST_GET,
    payload: params
})

export const unitsListGetFAction = (params : any) => ({
    type: UNITS_LIST_GET_F,
    payload: params
})

export const unitsListGetRAction = (params : any) => ({
    type: UNITS_LIST_GET_R,
    payload: params
})

export const unitsSetSelectedAction = (units : any) => ({
    type: UNITS_SELECTED_SET,
    payload: units
})