import React from 'react'
import './Main.scss'

//set interface for main props
export interface MainProps {

    children ? : any;

}

//Main component return section with basic syles
export  const Main = ({...props}:MainProps) => {
    return (
        <section className='main-section'>
            {props.children}
        </section>
    )
}
