import { connect } from "react-redux";
import { subscriptionSetSelectedAction } from '../../../../store/action/subscriptionAction'
import React from "react";
import { Col, Form, Row } from 'react-bootstrap'
import { Text } from '../../../../common/components/text/Text'
import { Main } from "../../../../common/components/main/Main";
import { Space } from "../../../../common/components/space/Space";
import Card from "../../../../common/components/card/Card";
import { Grid } from "../../../../common/components/grid/Grid";
import { Attributes } from "../../../../common/components/attribute/Attribute";
import classNames from "classnames";
import { Flex } from "../../../../common/components/flex/Flex";
import { Link } from "react-router-dom";
import { Button } from "../../../../common/components/button/Button";
import Icons from "../../../../common/components/icon/Icon";
import subscriptionService from '../../../../services/subscriptionService'
import { Label } from "../../../../common/components/label/Label";
import { format, parseISO } from 'date-fns'
import Loader from "react-loader-spinner";
import NumberFormat from "react-number-format";

const SubscriptionDetail = (props: any) => {
    const { match } = props
    const [isLoading, setLoading] = React.useState(true)
    const [display, setDisplay] = React.useState<any>({
        name: '',
        description: '',
        name_english: '',
        name_description: ''
    })

    //Call perticular ship detail function.  
    React.useEffect(() => {
        const { id } = match.params
        _getShipDetail(id)
    }, [])

    //Get perticular ship detail using match.params. 
    const _getShipDetail = (id: any) => {
        console.log(id, 'id')
        setLoading(true)
        return subscriptionService.getSubscriptionDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.subscriptionSetSelectedAction(r.data)
                console.log(r.data, 'r.data')
                setDisplay(r.data)
                // r.map((data: any, i: any) => {
                //     return display.push(data.data)
                // })
                console.log(display, 'subscription detail')
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    // If isloading is true print a text as loading. 
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    // If isloading is false print the details of perticular ship. 
    return (
        <Main>
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                <Col sm='12' md='12' lg='12' xl='8' className='m-0 p-0 align-top'>
                    <Card title="Subscription Detail">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Plan Name</Text>
                                        <Text color='main' variant='subtitle' bold >{display.name}</Text>
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Created Date</Text>
                                        {display.created_at === null || '' ? (
                                            <Text color='main' variant='subtitle' bold>--</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold>{format(parseISO(display.createdAt), 'dd/MM/yyyy')}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Description</Text>
                                        {display.description === null ? (
                                            <Text color='main' variant='subtitle' bold>-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold>{display.description}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Amount</Text>
                                        {display.price === null ? (
                                            <Text color='main' variant='subtitle' bold>-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold>
                                                <NumberFormat value={display.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                                            </Text>
                                            // <>
                                            //     {!String(display.price).includes('.') && (
                                            //         <Text color='main' variant='subtitle' bold>
                                            //             <NumberFormat value={String(display.price)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />.00
                                            //         </Text>
                                            //     )}
                                            //     {String(display.price).includes('.') && (
                                            //         <Text color='main' variant='subtitle' bold>
                                            //             <NumberFormat value={display.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                                            //         </Text>
                                            //     )
                                            //     }
                                            // </>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Duration in Days</Text>
                                        {display.durationInMonth === null || '' ? (
                                            <Text color='main' variant='subtitle' bold>--</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold>{display.durationInMonth} day(s)</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Status</Text>
                                        {display.isactive === false || '' ? (
                                            <Text color='danger' variant='subtitle' bold>Inactive</Text>
                                        ) : (
                                            <Text color='success' variant='subtitle' bold>Active</Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='8' xl='8'>
                    <Actions>
                        {/* If you click the back button its is redirect to ships list page.  */}
                        <Link to='/subscription/list'>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the edit button its is redirect to ships edit page.  */}
                        <Link to={`/subscription/edit/${display.id}`}>
                            <Button variant='transparent' type="submit">
                                <Flex>
                                    <Icons color="#ffffff" icon="edit" />
                                    EDIT DATA
                                </Flex>
                            </Button>
                        </Link>
                    </Actions>
                </Col>
            </Row>
        </Main>
    )
}
export interface ActionProps {
    children?: React.ReactNode
}

const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}


const mapStateToProps = (state: any) => ({
    subscription: state.subscription.selectedSubscription
})

// connect to subscriptionSetSelectedAction and get the one perticular ship data. 
export default connect(
    mapStateToProps,
    {
        subscriptionSetSelectedAction
    }
)(SubscriptionDetail)