import * as Observable from 'rxjs'
import { timeout, map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getShipMaterial = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/shipMaterial/search',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addShipMaterial = (body : any) => {
  return http({
    method: 'POST',
    url: '/shipMaterial',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getShipMaterialDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/shipMaterial/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteShipMaterial = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/shipMaterial/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const updateShipMaterial = (body : any) => {
  return http({
    method: 'PUT',
    url: '/shipMaterial',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const materialService = {
  getShipMaterial,
  addShipMaterial,
  getShipMaterialDetail,
  updateShipMaterial,
  deleteShipMaterial
}
  
export default materialService
  

