import { Main } from "../../common/components/main/Main";
import { Space } from "../../common/components/space/Space";
import { Text } from '../../common/components/text/Text';
import { Row, Col, Form } from "react-bootstrap";

import React from 'react';
import './dashboard.scss'
import { format, parseISO } from 'date-fns'
import * as Observable from 'rxjs'
import { connect } from 'react-redux';
import dashboardService from "../../services/dashboardService";
import { dockService } from "../../services/dockService";
import manufacturerService from "../../services/manufacturerService";
import { dockOwnerListGetAction, dockOwnerSetSelectedAction } from '../../store/action/dockActions';
import NumberFormat from "react-number-format";

interface dashboardData {
    active_suppliers: number,
    anode_spend: number,
    anode_total_ads: number,
    clicked: number,
    expired: number,
    paint_total_ads: number,
    paint_spend: number,
    subscribed: number,
    total_ads: number,
    total_available_amount: number,
    total_budget: number,
    unsubscribed: number,
    viewed: number,
    subscription_total_amount: number
}

const Dashboard = (props: any) => {

    let drydockOwner: string;
    const [drydockValue, setDrydockValue] = React.useState('all');
    const [supplierValue, setSupplierValue] = React.useState('all');
    const [showDrydockOwnerAll, setShowDrydockOwnerAll] = React.useState(true);
    const [showSuppliersAll, setShowSuppliersAll] = React.useState(true);
    const [dockOwner, setDockOwner] = React.useState([]);
    const [manufacturer, setManufacturer] = React.useState([]);
    const [designClass, setDesignClass] = React.useState('mr-0 mt-0 mb-0 p-0 dashboard dashboard_col');
    const [dockOwnerDashboard, setDockOwnerDashboard] = React.useState<dashboardData>({
        active_suppliers: 0,
        anode_spend: 0,
        anode_total_ads: 0,
        clicked: 0,
        expired: 0,
        paint_spend: 0,
        paint_total_ads: 0,
        subscribed: 0,
        total_ads: 0,
        total_available_amount: 0,
        total_budget: 0,
        subscription_total_amount: 0,
        unsubscribed: 0,
        viewed: 0
    });

    const [manufacturerDashboard, setManufacturerDashboard] = React.useState<dashboardData>({
        active_suppliers: 0,
        anode_spend: 0,
        anode_total_ads: 0,
        clicked: 0,
        expired: 0,
        paint_spend: 0,
        paint_total_ads: 0,
        subscribed: 0,
        total_ads: 0,
        total_available_amount: 0,
        total_budget: 0,
        unsubscribed: 0,
        subscription_total_amount: 0,
        viewed: 0
    });
    const [allDashboard, setAllDashboard] = React.useState<dashboardData>({
        active_suppliers: 0,
        anode_spend: 0,
        anode_total_ads: 0,
        clicked: 0,
        expired: 0,
        paint_spend: 0,
        paint_total_ads: 0,
        subscribed: 0,
        total_ads: 0,
        total_available_amount: 0,
        total_budget: 0,
        unsubscribed: 0,
        subscription_total_amount: 0,
        viewed: 0
    });

    const [dock, setDock] = React.useState<any>([{
        address: "",
        amount: '',
        city_name: '',
        company_id: '',
        company_name: '',
        created_at: '',
        dob: '',
        dock_id: '',
        dock_name: '',
        durationinmonth: "",
        email: "",
        end_date: '',
        is_active: true,
        phone: "",
        phone_code: "",
        plan_name: "",
        profile_img: '',
        start_date: '',
        status: '',
        username: ''
    }])

    const supplierChange = (event: any) => {
        setSupplierValue(event.target.value);
        if (event.target.value === "all") {
            setDesignClass('mr-0 mt-0 mb-0 p-0 dashboard_col dashboard');
            setShowSuppliersAll(true);
            getDashboardDetails(0);
        }
        else {
            setDesignClass('mr-0 mt-0 mb-0 p-0 dashboard_false dashboard');
            setShowSuppliersAll(false);
            getDashboardDetails(event.target.value, 'manufacturer');
        }
    };
    const drydockChange = (event: any) => {
        setDrydockValue(event.target.value);
        if (event.target.value === "all") {
            setShowDrydockOwnerAll(true);
            getDashboardDetails(0);
        }
        else {
            setShowDrydockOwnerAll(false);
            _getDockDetail(event.target.value)
            getDashboardDetails(event.target.value, 'drydockowner');
        }
    };

    const getDashboardDetails = (id: any, user?: string) => {
        dashboardService.getDashboardDetails(id).subscribe((result: any) => {
            if (id == 0) {
                setAllDashboard({
                    active_suppliers: result.data.active_suppliers,
                    anode_spend: result.data.anode_spend,
                    anode_total_ads: result.data.anode_total_ads,
                    clicked: result.data.clicked,
                    expired: result.data.expired,
                    paint_spend: result.data.paint_spend,
                    paint_total_ads: result.data.paint_total_ads,
                    subscribed: result.data.subscribed,
                    total_ads: result.data.total_ads,
                    total_available_amount: result.data.total_available_amount,
                    total_budget: result.data.total_budget,
                    unsubscribed: result.data.unsubscribed,
                    subscription_total_amount: result.data.subscription_total_amount,
                    viewed: result.data.viewed
                });
                setAllDashboard(result.data)
            }
            else if (user === 'drydockowner') {
                setDockOwnerDashboard({
                    active_suppliers: result.data.active_suppliers,
                    anode_spend: result.data.anode_spend,
                    anode_total_ads: result.data.anode_total_ads,
                    clicked: result.data.clicked,
                    expired: result.data.expired,
                    paint_spend: result.data.paint_spend,
                    paint_total_ads: result.data.paint_total_ads,
                    subscribed: result.data.subscribed,
                    total_ads: result.data.total_ads,
                    total_available_amount: result.data.total_available_amount,
                    total_budget: result.data.total_budget,
                    unsubscribed: result.data.unsubscribed,
                    viewed: result.data.viewed,
                    subscription_total_amount: result.data.subscription_total_amount
                });
                setDockOwnerDashboard(result.data)
            }
            else if (user === 'manufacturer') {
                setManufacturerDashboard({
                    active_suppliers: 0,
                    anode_spend: 0,
                    anode_total_ads: 0,
                    clicked: 0,
                    expired: 0,
                    paint_spend: 0,
                    paint_total_ads: 0,
                    subscribed: 0,
                    total_ads: 0,
                    total_available_amount: 0,
                    subscription_total_amount: 0,
                    total_budget: 0,
                    unsubscribed: 0,
                    viewed: 0
                });
                setManufacturerDashboard({
                    active_suppliers: result.data.active_suppliers,
                    anode_spend: result.data.anode_spend,
                    anode_total_ads: result.data.anode_total_ads,
                    clicked: result.data.clicked,
                    expired: result.data.expired,
                    paint_spend: result.data.paint_spend,
                    paint_total_ads: result.data.paint_total_ads,
                    subscribed: result.data.subscribed,
                    total_ads: result.data.total_ads,
                    total_available_amount: result.data.total_available_amount,
                    subscription_total_amount: result.data.subscription_total_amount,
                    total_budget: result.data.total_budget,
                    unsubscribed: result.data.unsubscribed,
                    viewed: result.data.viewed
                });
                setManufacturerDashboard(result.data)
            }
        })
    }

    const getDrydockOwners = () => {
        let size = 100;
        let page = 0;
        setDockOwner([]);
        dockService.getDockOwnersDashboard(`size=${size}&page=${page}`).subscribe((result: any) => {
            setDockOwner(result.data.content);
        })
    }

    var dashboard: any;
    dashboard = localStorage.getItem('Dashboard')

    const getManufacturers = () => {
        let size = 100;
        let page = 0;
        setManufacturer([]);
        manufacturerService.getManufacturerDashboard(`size=${size}&page=${page}&isactive=true`).subscribe((result: any) => {
            setManufacturer(result.data.content);
            console.log(manufacturer);
        })
    }

    const _getDockDetail = (id: any) => {
        return dockService.getDockOwnerDetail(id).subscribe({
            next: r => {
                // console.log(r, 'r')
                // const { data } = r.data
                // console.log(r.data, 'detail view data')
                props.dockOwnerSetSelectedAction(r.data)
                setDock({
                    address: r.data.address,
                    amount: r.data.amount,
                    city_name: r.data.city_name,
                    company_id: r.data.company_id,
                    company_name: r.data.company_name,
                    created_at: r.data.created_at,
                    dob: r.data.dob,
                    dock_id: r.data.dock_id,
                    dock_name: r.data.dock_name,
                    durationinmonth: r.data.durationinmonth,
                    email: r.data.email,
                    end_date: r.data.end_date,
                    is_active: r.data.is_active,
                    phone: r.data.phone,
                    phone_code: r.data.phone_code,
                    plan_name: r.data.plan_name,
                    profile_img: r.data.profile_img,
                    start_date: r.data.start_date,
                    status: r.data.status,
                    username: r.data.username
                })
            },
            error: err => {
                // console.error(err)
            }
        })
    }

    React.useEffect(() => {
        getDrydockOwners();
        getManufacturers();
        getDashboardDetails(0);
        // _getDockDetail();
    }, [])

    return (
        <Main>
           
            {dashboard !== 'true' ? (
                <div  className="dashboard_heading">
                    <Text variant="title" color='black' className='dashboard_heading'>Welcome to Navidok Admin Dashboard!!!</Text>
                </div>
                
            ) : (
                <>
                <Text variant="title" color='black'>Dashboard</Text>
                <Space divheight={15} />
                <Row className='m-0 p-0 dashboard_row mt-5'>
                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className={designClass}>
                        <Row className='m-0 p-0 mb-2' xs='12' sm='12' md='12' lg='12' xl='12'>
                            <Col xs='12' sm='12' md='12' lg='12' xl='12' className='m-0 p-0 heading'>
                                <Text variant="title" color='black' className='dashboard_subtitile'>Drydock Owner</Text>
                            </Col>
                            <Col xs='12' sm='12' md='12' lg='12' xl='12' className='m-0 p-0 heading'>
                                <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                    name='drydock'
                                    value={drydockValue}
                                    onChange={drydockChange}
                                    disabled={dockOwner.length == 0}
                                >
                                    <option value="all">All</option>
                                    {dockOwner.map((data: any, i: any) => {
                                        return <option key={i} value={data.id}>{data.firstname}</option>
                                    })}
                                </Form.Control>
                            </Col>
                        </Row>
                        {showDrydockOwnerAll && (
                            <div>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Dock Owners</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text variant="title" color='black' className='dashboard_value'>{allDashboard.subscribed + allDashboard.expired + allDashboard.unsubscribed}</Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Subscribed</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text variant="title" color='black' className='dashboard_value'>{allDashboard.subscribed}</Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>UnSubscribed</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text variant="title" color='black' className='dashboard_value'>{allDashboard.unsubscribed}</Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Expired</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text variant="title" color='black' className='dashboard_value'>{allDashboard.expired}</Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Subscribed amount</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text color='main' variant='subtitle' bold>
                                            <NumberFormat value={allDashboard.subscription_total_amount} displayType={'text'} thousandSeparator={true} />.00
                                        </Text>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {!showDrydockOwnerAll && (
                            <div>
                                {/* <Row className='m-0 p-0 mb-2'>
                            <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12'  xl='5' lg={{ span: 5, offset: 1 }}>
                                <Text variant="title" color='black' className='dashboard_value'>Subscribed</Text>
                            </Col>
                            <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                <Text variant="title" color='black' className='dashboard_value'>{dockOwnerDashboard.subscribed}</Text>
                            </Col>
                        </Row> */}
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Subscribed amount</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text color='main' variant='subtitle' bold>
                                            <NumberFormat value={dock.amount} displayType={'text'} thousandSeparator={true} />.00
                                        </Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Subscription Plan</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text variant="title" color='black' className='dashboard_value'>{dock.plan_name}</Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>End Date</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        {console.log(dock.end_date)}
                                        {dock.end_date !== null && dock.end_date !== undefined && (
                                            <Text variant="title" color='black' className='dashboard_value'>{format(parseISO(dock.end_date), 'dd/MM/yyyy, hh:mm')}</Text>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Col>
                    <Col xs='12' sm='12' md='12' lg={{ span: 5, offset: 1 }} xl='5' className='mr-0 mt-0 mb-0 p-0 dashboard'>
                        <Row className='m-0 p-0 mb-2' xs='12' sm='12' md='12' lg='12' xl='12'>
                            <Col xs='12' sm='12' md='12' lg='12' xl='12' className='m-0 p-0 heading'>
                                <Text variant="title" color='black' className='dashboard_subtitile'>Supporting Industries</Text>
                            </Col>
                            <Col xs='12' sm='12' md='12' lg='12' xl='12' className='m-0 p-0 heading'>
                                <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                    name='supplier'
                                    value={supplierValue}
                                    onChange={supplierChange}
                                    disabled={manufacturer.length == 0}
                                >
                                    <option value="all">All</option>
                                    {/* Map the data's from shiptype array and used as a option value. By Dharani on 04-Mar-2021 */}
                                    {manufacturer.map((data: any, i: any) => {
                                        return <option key={i} value={data.id}>{data.name}</option>
                                    })}
                                </Form.Control>
                            </Col>
                        </Row>
                        {showSuppliersAll && (
                            <div>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Industrialists</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text variant="title" color='black' className='dashboard_value'>{allDashboard.active_suppliers}</Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Total ads</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text variant="title" color='black' className='dashboard_value'>{allDashboard.total_ads}</Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Anode spend</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text color='main' variant='subtitle' bold>
                                            <NumberFormat value={allDashboard.anode_spend} displayType={'text'} thousandSeparator={true} />.00
                                        </Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Paint spend</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text color='main' variant='subtitle' bold>
                                            <NumberFormat value={allDashboard.paint_spend} displayType={'text'} thousandSeparator={true} />.00
                                        </Text>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {!showSuppliersAll && (
                            <div>
                                {/* <Row className='m-0 p-0 mb-2'>
                            <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12'  xl='5' lg={{ span: 5, offset: 1 }}>
                                <Text variant="title" color='black' className='dashboard_value'>Suppliers</Text>
                            </Col>
                            <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                <Text variant="title" color='black' className='dashboard_value'>{manufacturerDashboard.active_suppliers}</Text>
                            </Col>
                        </Row> */}
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Total ads</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text variant="title" color='black' className='dashboard_value'>{manufacturerDashboard.total_ads}</Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Anode spend</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text color='main' variant='subtitle' bold>
                                            <NumberFormat value={manufacturerDashboard.anode_spend} displayType={'text'} thousandSeparator={true}/>.00
                                        </Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Paint spend</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text color='main' variant='subtitle' bold>
                                            <NumberFormat value={manufacturerDashboard.paint_spend} displayType={'text'} thousandSeparator={true}/>.00
                                        </Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Views</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text variant="title" color='black' className='dashboard_value'>{manufacturerDashboard.viewed}</Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' xl='5' lg={{ span: 5, offset: 1 }}>
                                        <Text variant="title" color='black' className='dashboard_value'>Clicks</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0 value'>
                                        <Text variant="title" color='black' className='dashboard_value'>{manufacturerDashboard.clicked}</Text>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0 mb-2'>
                                    <Col className='mr-0 mt-0 mb-0 p-0' xs='12' sm='12' md='12' lg={{ span: 5, offset: 1 }} xl='5'>
                                        <Text variant="title" color='black' className='dashboard_value'>Balance / Budget</Text>
                                    </Col>
                                    <Col xs='12' sm='12' md='12' lg='6' xl='6' className='m-0 p-0 value'>
                                        {manufacturerDashboard.total_available_amount !== null && (
                                            <Text color='main' variant='subtitle' bold>
                                                <NumberFormat value={manufacturerDashboard.total_available_amount} displayType={'text'} thousandSeparator={true}/><span>.00 &nbsp;/&nbsp;</span>
                                            </Text>
                                        )}
                                        {manufacturerDashboard.total_budget !== null && (
                                            <Text color='main' variant='subtitle' bold>
                                                <NumberFormat value={manufacturerDashboard.total_budget} displayType={'text'} thousandSeparator={true}/><span>.00</span>
                                            </Text>
                                        )}
                                        {manufacturerDashboard.total_available_amount === null || manufacturerDashboard.total_budget === null && (
                                            <Text color='main' variant='subtitle' bold> - </Text>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        
                        )}
                    </Col>
                </Row>
                </>
            )}

        </Main>
    )
}

const mapStateToProps = (state: any) => ({
    docks: state.dock.owners,
    isLoading: state.dock.isLoading,
    error: state.dock.error,
    totalElement: state.dock.totalElement,
    totalPage: state.dock.totalPage
})

export default connect(
    mapStateToProps,
    {
        dockOwnerListGetAction,
        dockOwnerSetSelectedAction,
    }
)(Dashboard)