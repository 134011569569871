import {
    SUBSCRIPTION_LIST_GET,
    SUBSCRIPTION_LIST_GET_F,
    SUBSCRIPTION_LIST_GET_R,
    SUBSCRIPTION_SELECTED_SET
} from '../actionType'

const initialState = {
    subscription: [],
    isLoading: false,
    error: null,
    totalPage: 0,
    totalElement: 0,
    selectedSubscription: null,
}

export default function subscriptionReducer (state = initialState, action : any)  {
    switch (action.type) {
        case SUBSCRIPTION_LIST_GET:
            return {
            ...state,
            isLoading: true,
            error: null
            }
        case SUBSCRIPTION_LIST_GET_F:
            // console.log('action called')
            // console.log(action.payload.content,'content')
            // var totalsubscount = 0
           
            // if(action.payload.content === undefined || '')
            // {
            //     totalsubscount = 0
            // }
            // else{
            //     action.payload.content.map((data:any) => {
            //         totalsubscount = data.totalrow
            //     })
            // }
            return {
                ...state,
                isLoading: false,
                subscription: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
                // totalElement: totalsubscount
            }
        case SUBSCRIPTION_LIST_GET_R:
            
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SUBSCRIPTION_SELECTED_SET:
            return {
                ...state,
                selectedSubscription: action.payload
            }
        default:
            return state
    }
}