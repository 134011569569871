import * as Observable from 'rxjs'
import { timeout, map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getSubscription = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/subscription/list',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addSubscription = (body : any) => {
  return http({
    method: 'POST',
    url: '/subscription',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getSubscriptionDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/subscription/view/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteSubscription = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/subscription/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        //throw new Error(res)
      }
    })
  )
}

const updateSubscription = (body : any) => {
  return http({
    method: 'PUT',
    url: '/subscription',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}


const subscriptionService = {
  getSubscription,
  addSubscription,
  getSubscriptionDetail,
  updateSubscription,
  deleteSubscription,
}
  
export default subscriptionService
  

