import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
// import whiteLogo from '../../../assets/images/logo-white.png';
// import whiteLogo from '../../../assets/images/icon.jpg';
import whiteLogo from '../../../assets/images/navidok_txt.png';
import Manufacturer from '../../../assets/images/Manufacturer.png';
import MasterTable from '../../../assets/images/Mastertable.png';
import Product from '../../../assets/images/Product.png';
import subscription from '../../../assets/images/Subscription 2.png';
import Advertisement from '../../../assets/images/Advertisement.png'
import Collapse from '../../components/collapse/Collapse';
import Icons from '../../components/icon/Icon';
import { Text } from '../../components/text/Text';
import { MenuItem, MenuList, Root, SideLogo, Toggle } from '../sidebar/SidebarStyle';
import './Sidebar.scss';
import classNames from 'classnames';
import authService from '../../../services/authService';
import { ShipFilterState } from '../../../pages/ship/filter/ShipFilterState'
import codeService from '../../../services/codeService'
import { clearfilter } from '../clear_filter/ClearFilter';

interface MenuProps {
    dashboard: any,
    ships: any,
    drydocks: any,
    manufacturer: any,
    products: any,
    location: any,
    master: any,
    roles: any,
    system_user: any,
    user: any,
    plans: any,
    datalastic: any,
    social:any
}

export const Sidebar = () => {

    const [openShip, setOpenShip] = React.useState(false)
    const [openDock, setOpenDock] = React.useState(false)
    const [openManufact, setOpenManufac] = React.useState(false)
    const [openMaster, setOpenMaster] = React.useState(false)
    const [openProduct, setOpenProduct] = React.useState(false)
    const [opendatalastic, setOpenDatalastic] = React.useState(false)
    const [openLocation, setOpenLocation] = React.useState(false)
    const [openSocial, setOpenSocial] = React.useState(false)
    const logOut = () => {
        authService.logout()
        window.location.reload()
    }

    const [addForm, setForm] = useState<any>({
        pages: []
    })
    const [menu, setMenu] = useState<MenuProps>({
        dashboard: false,
        ships: false,
        drydocks: false,
        manufacturer: false,
        products: false,
        location: false,
        master: false,
        roles: false,
        system_user: false,
        user: false,
        plans: false,
        datalastic: false,
        social: false
    })
    const get_Page = (() => {
        return codeService.getAdminPage().subscribe({
            next: r => {
                //console.log(r.data)
                setForm({ ...addForm, pages: r.data })
                // r.data.map((data: any, i: any) => {
                //     return addForm.pages.push(data)
                // })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    const access_page = (() => {
        var page: any;
        page = localStorage.getItem('pages')
        console.log(page, 'pages from admin')
        if (page) {
            var sam: any;
            sam = page.split(',')
            sam.map((data: any) => {
                if (data === 'Dashboard') {
                    localStorage.setItem('Dashboard', "true")
                    setMenu({ ...menu, dashboard: true })
                    menu.dashboard = true
                } else if (data === 'Ships') {
                    setMenu({ ...menu, ships: true })
                    menu.ships = true
                } else if (data === 'Drydocks') {
                    setMenu({ ...menu, drydocks: true })
                    menu.drydocks = true
                } else if (data === 'Supporting Industries') {
                    setMenu({ ...menu, manufacturer: true })
                    menu.manufacturer = true
                } else if (data === 'Products') {
                    setMenu({ ...menu, products: true })
                    menu.products = true
                } else if (data === 'Location') {
                    setMenu({ ...menu, location: true })
                    menu.location = true
                } else if (data === 'Master') {
                    setMenu({ ...menu, master: true })
                    menu.master = true
                } else if (data === 'Roles') {
                    setMenu({ ...menu, roles: true })
                    menu.roles = true
                } else if (data === 'System user') {
                    setMenu({ ...menu, system_user: true })
                    menu.system_user = true
                } else if (data === 'User Management') {
                    setMenu({ ...menu, user: true })
                    menu.user = true
                } else if (data === 'Subscription Plans') {
                    setMenu({ ...menu, plans: true })
                    menu.plans = true
                } else if (data === 'Datalastic') {
                    setMenu({ ...menu, datalastic: true })
                    menu.datalastic = true
                }
                else if (data === 'Social') {
                    setMenu({ ...menu, social: true })
                    menu.social = true
                }

            })
        }
    })
    React.useEffect(() => {
        //get_Page();
        access_page();

    }, [])

    // const showall = () => {
    //         ShipFilterState.shipFilter.serachBoxInputShip=''
    // }\

    const _handleClickSocial = () => {
        clearfilter()
        if (openSocial) {
            return setOpenSocial(false)
        }
        else {
            setOpenShip(false)
            setOpenDock(false)
            setOpenMaster(false)
            setOpenProduct(false)
            setOpenLocation(false)
            setOpenManufac(false)
            setOpenDatalastic(false)
            setOpenSocial(true)
        }
    }

    const _handleClickDatalastic = () => {
        clearfilter()
        if (opendatalastic) {
            return setOpenDatalastic(false)
        }
        else {
            setOpenShip(false)
            setOpenDock(false)
            setOpenMaster(false)
            setOpenProduct(false)
            setOpenLocation(false)
            setOpenManufac(false)
            setOpenDatalastic(true)
            setOpenSocial(false)
        }
    }

    const _handleClickShip = () => {
        if (openShip) {
            clearfilter()
            return setOpenShip(false)
        }
        else {
            clearfilter()
            setOpenShip(true)
            setOpenDock(false)
            setOpenMaster(false)
            setOpenProduct(false)
            setOpenLocation(false)
            setOpenDatalastic(false)
            setOpenManufac(false)
            setOpenSocial(false)
        }
    }
    // const _handleClickAds = () => {
    //     if (openAds) {
    //         return setOpenAds(false)
    //     }
    //     else {
    //         setOpenAds(true)
    //         setOpenShip(false)
    //         setOpenDock(false)
    //         setOpenMaster(false)
    //         setOpenProduct(false)
    //         setOpenLocation(false)
    //         setOpenManufac(false)
    //     }
    // }
    const _handleClickManufact = () => {
        clearfilter()
        if (openManufact) {
            return setOpenManufac(false)
        }
        else {
            setOpenManufac(true)
            setOpenDatalastic(false)
            setOpenShip(false)
            setOpenDock(false)
            setOpenMaster(false)
            setOpenProduct(false)
            setOpenLocation(false)
            setOpenSocial(false)
        }
    }
    const _handleClickDock = () => {
        clearfilter()
        if (openDock) {
            return setOpenDock(false)
        }
        else {
            setOpenDock(true)
            setOpenMaster(false)
            setOpenShip(false)
            setOpenProduct(false)
            setOpenLocation(false)
            setOpenDatalastic(false)
            setOpenManufac(false)
            setOpenSocial(false)
        }
    }
    const _handleClickMaster = () => {
        clearfilter()
        if (openMaster) {
            return setOpenMaster(false)
        }
        else {
            setOpenMaster(true)
            setOpenShip(false)
            setOpenDock(false)
            setOpenProduct(false)
            setOpenDatalastic(false)
            setOpenLocation(false)
            setOpenManufac(false)
            setOpenSocial(false)
        }
    }
    const role = localStorage.getItem("role")
    const _handleClickProduct = () => {
        clearfilter()
        if (openProduct) {
            return setOpenProduct(false)
        }
        else {
            setOpenProduct(true)
            setOpenMaster(false)
            setOpenShip(false)
            setOpenDock(false)
            setOpenLocation(false)
            setOpenDatalastic(false)
            setOpenManufac(false)
            setOpenSocial(false)
        }
    }
    const _handleClickLocation = () => {
        clearfilter()
        if (openLocation) {
            return setOpenLocation(false)
        }
        else {
            setOpenLocation(true)
            setOpenMaster(false)
            setOpenShip(false)
            setOpenDock(false)
            setOpenDatalastic(false)
            setOpenProduct(false)
            setOpenManufac(false)
            setOpenSocial(false)
        }
    }
    //console.log(menu)
    return (
        <div>
            <Root>
                <SideLogo>
                    <img src={whiteLogo} alt='Logo-white' />
                </SideLogo>
                <MenuList>
                    {menu.dashboard === true ? (
                        <MenuItem>
                            <Link to='/dashboard'>
                                <Toggle>
                                    <Icons color='#ffffff' icon='dashboard' />{' '}
                                    <Text variant='caption'>
                                        Dashboard
                                    </Text>
                                </Toggle>
                            </Link>
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {menu.ships === true ? (
                        <MenuItem>
                            <Collapse open={openShip}>
                                <Toggle className='hover_list' onClick={() => _handleClickShip()}>
                                    <Icons icon='directions_boat' />
                                    <Text variant='caption'  >
                                        Ships
                                    </Text>
                                </Toggle>
                                <ul>
                                    <li>
                                        <Link to='/ships/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Ship List
                                            </Text>
                                        </Link>
                                    </li>
                                    <li>
                                        <NavLink to='/companies/company-type' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Ship Companies
                                            </Text>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/ship-owners/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Ship Owners
                                            </Text>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {menu.drydocks === true ? (
                        <MenuItem>
                            <Collapse open={openDock}>
                                <Toggle className='hover_list' onClick={() => _handleClickDock()}>
                                    <Icons color='#ffffff' icon='account_balance' />{' '}
                                    <Text variant='caption' color='white' >
                                        Drydocks
                                    </Text>
                                </Toggle>
                                <ul>
                                    <li>
                                        <Link to='/docks/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Drydock List
                                            </Text>
                                        </Link>
                                    </li>
                                    <li>
                                        <NavLink to='/comapanies/company-type' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Drydock Companies
                                            </Text>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/dock-owners/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Drydock Owners
                                            </Text>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {menu.manufacturer === true ? (
                        <MenuItem>
                            <Collapse open={openManufact}>
                                <Toggle className='hover_list' onClick={() => _handleClickManufact()}>
                                    <img src={Manufacturer} alt='manufac' />
                                    <Text variant='caption' color='white'>
                                        Supporting Industries
                                    </Text>
                                </Toggle>
                                <ul>
                                    <li>
                                        <NavLink to='/manufacturer/comapanies/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Industries Detail
                                            </Text>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/manufacturer/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Owners Detail
                                            </Text>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {menu.products === true ? (
                        <MenuItem>
                            <Collapse open={openProduct}>
                                <Toggle className='hover_list' onClick={() => _handleClickProduct()}>
                                    <img src={Product} alt='product' />
                                    <Text variant='caption' color='white' >
                                        Products
                                    </Text>
                                </Toggle>
                                <ul>
                                    <li>
                                        <Link to='/anode/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Anodes
                                            </Text>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/paint/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Paints
                                            </Text>
                                        </Link>
                                    </li>
                                </ul>
                            </Collapse>
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {menu.datalastic === true ? (
                        <MenuItem>
                            <Collapse open={opendatalastic}>
                                <Toggle className='hover_list' onClick={() => _handleClickDatalastic()}>
                                    <Icons icon='upload_fileicon' />
                                    <Text variant='caption'  >
                                        Datalastic
                                    </Text>
                                </Toggle>
                                <ul>
                                    <li>
                                        <Link to='/datalastic/shipCmp' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Ship Companies
                                            </Text>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/datalastic/shipClass' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Ship And Classification
                                            </Text>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/datalastic/shipEng' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Ship Engine
                                            </Text>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/datalastic/shipDemo' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Demolition
                                            </Text>
                                        </Link>
                                    </li>
                                </ul>
                            </Collapse>
                        </MenuItem>

                        
                    ) : (
                        <></>
                    )}

                    {menu.location === true ? (
                        <MenuItem>
                            <Collapse open={openLocation}>
                                <Toggle className='hover_list' onClick={() => _handleClickLocation()}>
                                    <Icons color='#ffffff' icon='place_icon' />{' '}
                                    <Text variant='caption' color='white' >
                                        Location
                                    </Text>
                                </Toggle>
                                <ul>
                                    <li>
                                        <Link to='/countries' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Countries
                                            </Text>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/provinces' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Provinces
                                            </Text>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/cities' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Cities
                                            </Text>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/ports' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Ports
                                            </Text>
                                        </Link>
                                    </li>
                                </ul>
                            </Collapse>
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {menu.master === true ? (
                        <MenuItem>
                            {/* {role !== 'VISITOR' && ( */}
                            <Collapse open={openMaster}>
                                <Toggle className='hover_list' onClick={() => _handleClickMaster()}>
                                    <img src={MasterTable} alt='manufac' />
                                    <Text variant='caption' color='white' >
                                        Master
                                    </Text>
                                </Toggle>
                                <ul>
                                    <li>
                                        <NavLink to='/shipMaterial/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Ship Materials
                                            </Text>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <Link to='/unit/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Units
                                            </Text>
                                        </Link>
                                    </li>
                                    {/* <li>
                                    <Link to='/thickness/list'  onClick={clearfilter}>
                                        <Text variant='caption' color='white'>
                                            Thickness
                                        </Text>
                                    </Link>
                                </li>
                                 */}
                                    <li>
                                        <NavLink to='/shipClassification/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Ship Classifications
                                            </Text>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <Link to='/companyType' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Company Types
                                            </Text>
                                        </Link>
                                    </li>
                                    <li>
                                        <NavLink to='/shipType/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Ship Types
                                            </Text>
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                    <Link to='/shipSubType/list'  onClick={clearfilter}>
                                        <Text variant='caption' color='white'>
                                            Ship Sub Type
                                        </Text>
                                    </Link>
                                </li> */}
                                    <li>
                                        <NavLink to='/dockTypeList' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Drydock Types
                                            </Text>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/settings' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Settings
                                            </Text>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/propulsion/list' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Propulsion Type
                                            </Text>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                            {/* )} */}
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {menu.roles === true ? (
                        <MenuItem>
                            {/* {role !== 'VISITOR' && ( */}
                            <Link to='/roles'>
                                <Toggle>
                                    <Icons color='#ffffff' icon='person_add' />{' '}
                                    <Text variant='caption'>
                                        Roles
                                    </Text>
                                </Toggle>
                            </Link>
                            {/* )} */}
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {menu.plans === true ? (
                        <MenuItem>
                            {/* {role !== 'VISITOR' && ( */}
                            <Link to='/subscription/list' onClick={clearfilter}>
                                <Toggle>
                                    <img src={subscription} alt='manufac' />
                                    <Text variant='caption'>
                                        Subscription Plans
                                    </Text>
                                </Toggle>
                            </Link>
                            {/* )} */}
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {menu.system_user === true ? (
                        <MenuItem>
                            <Link to='/system-users' onClick={clearfilter}>
                                <Toggle>
                                    <Icons color='#ffffff' icon='computer' />{' '}
                                    <Text variant='caption'>
                                        System User
                                    </Text>
                                </Toggle>
                            </Link>
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {menu.user === true ? (
                        <MenuItem>
                            <Link to='/users' onClick={clearfilter}>
                                <Toggle>
                                    <Icons color='#ffffff' icon='account_circle' />{' '}
                                    <Text variant='caption'>
                                        User Management
                                    </Text>
                                </Toggle>
                            </Link>
                        </MenuItem>
                    ) : (
                        <></>
                    )}

                    {menu.social === true ? (
                        <MenuItem>
                         <Collapse open={openSocial}>
                                <Toggle className='hover_list' onClick={() => _handleClickSocial()}>
                                <Icons color='#ffffff' icon='connect_without_contact_two_tone' />{' '}
                                    <Text variant='caption' color='white' >
                                    Social
                                    </Text>
                                </Toggle>
                                <ul>
                                    <li>
                                        <NavLink to='/social/report' onClick={clearfilter}>
                                            <Text variant='caption' color='white'>
                                                Report
                                            </Text>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </MenuItem>
                    ) : (
                        <></>
                    )}
    	            

                    {/* <MenuItem>
                        <Link to='/manufacturer/list' onClick={clearfilter}>
                            <Toggle>
                                <img src={Manufacturer} alt='manufac' />
                                <Text variant='caption'>
                                    Manufacturer
                                </Text>
                            </Toggle>
                        </Link>
                    </MenuItem> */}
                    {/* <MenuItem>
                        <Collapse open={openAds}>
                            <Toggle className='hover_list' onClick={() => _handleClickAds()}>
                                <img src={Advertisement} alt='ads' />
                                <Text variant='caption'  >
                                    Ad Space
                                </Text>
                            </Toggle>
                            <ul>
                                <li>
                                    <Link to='/productType/list' onClick={clearfilter}>
                                        <Text variant='caption' color='white'>
                                            Product Type
                                        </Text>
                                    </Link>
                                </li>
                                <li>
                                    <NavLink to='/slot/list'  onClick={clearfilter}>
                                        <Text variant='caption' color='white'>
                                            Slot
                                        </Text>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/ads/list'  onClick={clearfilter}>
                                        <Text variant='caption' color='white'>
                                            Advertisement
                                        </Text>
                                    </NavLink>
                                </li>
                            </ul>
                        </Collapse>
                    </MenuItem> */}





                    {/* <MenuItem>
                        <Link to='/settings'>
                            <Toggle>
                                <Icons color='#ffffff' icon='settings' />{' '}
                                <Text variant='caption'>
                                    Settings
                                </Text>
                            </Toggle>
                        </Link>
                    </MenuItem> */}



                    <MenuItem>
                        <div className='hover_list' onClick={() => logOut()}>
                            <Toggle >
                                <Icons color='#ffffff' icon='exit_to_app_icon' />
                                <Text variant='caption' color='white'>
                                    LogOut
                                </Text>
                            </Toggle>
                        </div>
                    </MenuItem>
                </MenuList>
            </Root>
        </div>
    );
};
