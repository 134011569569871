import React, { useRef, useState } from 'react'
import { Text } from '../../../common/components/text/Text'
import { Main } from '../../../common/components/main/Main'
import { Attributes } from '../../../common/components/attribute/Attribute'
import Card from '../../../common/components/card/Card'
// import { JsxChild } from 'typescript'
import { Row, Form, Col, Button, Image } from 'react-bootstrap'
import noImg from '../../../assets/images/NoImg.jpg';
import editicon from '../../../assets/images/editone.png'
import '../../../common/components/control/Control.scss'
import '../../../common/components/button/Button.scss'
import removeicon from '../../../assets/images/Remove.png'
// import { Input, Radio, Select, Textarea } from '../../../common/components/control/Control'
// import { Button } from '../../../common/components/button/Button'
import { Flex } from '../../../common/components/flex/Flex'
import Icons from '../../../common/components/icon/Icon'
import { Space } from '../../../common/components/space/Space'
import { connect } from 'react-redux'
import classNames from 'classnames'
import userService from '../../../services/userService'
import { useForm } from 'react-hook-form'
import './userList.scss'
import { history } from '../../../store';
// import { useEffect } from 'react'
import swal from 'sweetalert'
import codeService from '../../../services/codeService'
import {
    userSetSelectedAction,
} from '../../../store/action/userActions'
import Loader from 'react-loader-spinner'
import { Label } from '../../../common/components/label/Label'
// import { Link } from 'react-router-dom'

interface FormData {
    txt_editusername: string;
    txt_edituseremail: string;
    txt_editposition: string;
    txt_editphnUser: string;
    txt_editeducation: string;
    txt_editroles: string;
    txt_editexperience: string;
    txt_editexpertise: string;
    txt_edituseraddress: string;
    txt_editusercmpaddress: string;
    txt_editusercmpName: string;
    txt_editindividualgroup:string;
    txt_edituserStatus: string;
    radio_Activestatus: string;
    txt_editphnCmpCode: string;
    txt_editphnCmp: string;
    txt_editphnCode: string;
};
interface bvalueProps {
    string_image: string | ArrayBuffer | null
}
const UserEdit = (props: any) => {
    //const {dock} = props
    const { match } = props
    const { edit } = match.params
    const { id } = match.params
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const [userEditflag, setUserEditFlag] = React.useState(false)
    const [edituserForm, setForm] = useState<any>({
        name: '',
        email: '',
        username: '',
        password: '',
        roles: [],
        types: [],
        mbl_code: [],
        cmpMbl_code: [],
        role: '',
        status: '',
        individualGroup:'',
        isLoading: false,
        isSaving: false
    })
    const [editUser, setUserEdit] = useState<any>({
        id: '',
        editUserName: '',
        editUserPassword: '',
        editUserEmail: '',
        editUserPhone: '',
        editUserMblCode: '',
        editUserAddress: '',
        editUserRole: '',
        editUserPosition: '',
        editUserExperience: '',
        editUserExpertise: '',
        editUserEducation: '',
        editUserStatus: '',
        editUserActiveStatus: '',
        editUserIndividualGroup:'',
        editUserCompany: '',
        editUserCmpAddress: '',
        editUserCmpPhone: '',
        editUserCmpPhnCode: '',
        editUserCmpType: '',
        editUserImage: '',
    })
    const [showDelete, setShow] = React.useState(false);
    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<bvalueProps>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageAPIDryDock(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const ImageAPIDryDock = (imageurl: any) => {
        const { id } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setForm({ ...edituserForm, isLoading: true })
        userService.UpdateUserImage(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                //window.location.reload();
                history.push('/users')
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setForm({ ...edituserForm, isLoading: true })
                console.error(err)
            }
        })
    }

    const _removeUserImg = (id: any) => {
        return userService.removeUserImg(id).subscribe({
            next: (r) => {
                // console.log(r, 'response')
                setShow(false);
                // alert(true)
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
                // history.push(`/ships/${id}/`)
            },
            error: err => {
                // alert(false)
                swal("OOPS", String(err), "error")
            }
        })
    }

    const _getDetail = (id: any) => {
        setForm({ ...edituserForm, isLoading: true })
        return userService.getUserDetail(id).subscribe({
            next: r => {
                const { data } = r.data
                var userRoles = ''
                var userPhone = ''
                var CompanyPhone = ''
                if (r.data.role.length === 0 || r.data.role === null) {
                    userRoles = ''
                }
                else {

                    userRoles = r.data.role[0].code
                }
                // console.log(r,'data to be displayed in setDisplay')
                // console.log('userRoles',userRoles)
                if (r.data.phone_number === null || r.data.phone_number === 'null') {
                    userPhone = ''
                }
                else {

                    userPhone = r.data.phone_number
                }
                if (r.data.company_number === null || r.data.company_number === 'null') {
                    CompanyPhone = ''
                }
                else {

                    CompanyPhone = r.data.company_number
                }
                setUserEdit({
                    id: r.data.id,
                    editUserName: r.data.name,
                    editUserEmail: r.data.email,
                    editUserPhone: userPhone,
                    editUserMblCode: r.data.phone_code,
                    editUserAddress: r.data.address,
                    editUserRole: userRoles,
                    editUserPosition: r.data.position,
                    editUserExperience: r.data.experience,
                    editUserExpertise: r.data.expertise,
                    editUserEducation: r.data.education,
                    editUserStatus: r.data.status,
                    editUserIndvidualGroup:null,
                    editUserActiveStatus: r.data.enabled,
                    editUserCompany: r.data.company_name,
                    editUserCreated: r.data.created_at,
                    editUserCmpAddress: r.data.company_address,
                    editUserCmpPhone: CompanyPhone,
                    editUserCmpPhnCode: r.data.company_code,
                    editUserImage: r.data.photo
                })
                props.userSetSelectedAction(r.data)
                setForm({ ...edituserForm, isLoading: false })
            },
            error: err => {
                console.error(err)
                setForm({ ...edituserForm, isLoading: false })
            }
        })
    }

    const get_Role = (() => {
        return codeService.getUserRoles('home').subscribe({
            next: r => {
                // console.log(r.data, "Role")
                setForm({ ...edituserForm, email: r.username, roles: r.data })
                // console.log(edituserForm.roles, 'Form Role')
                r.data.map((data: any, i: any) => {
                    return edituserForm.roles.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    const _getMobileCode = (() => {
        return codeService.getMobileCode().subscribe({
            next: r => {
                // console.log(r.data, "Role")
                setForm({ ...edituserForm, mbl_code: r.data, cmpMbl_code: r.data })
                // console.log(edituserForm.mbl_code, 'mbl_code')
                r.data.map((data: any, i: any) => {
                    return edituserForm.mbl_code.push(data)
                })
                r.data.map((data: any, i: any) => {
                    return edituserForm.cmpMbl_code.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    const _getCompanyTypeUser = (() => {
        return codeService.getCompanyTypedd().subscribe({
            next: r => {
                // console.log(r.data,'owners in Dock add page')
                setForm({ ...edituserForm, types: r.data })
                r.data.map((data: any, i: any) => {
                    return edituserForm.types.push(r.data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    React.useEffect(() => {
        const { id } = match.params
        get_Role();
        _getCompanyTypeUser()
        _getDetail(id)
        _getMobileCode()
    }, [])

    const _goback = () => {
        if (edit) {
            history.push(`/mapCompany/${id}`)
        }
        else {
            history.push('/users')
        }
    }

    const _didUserEdit = () => {
        // e.preventDefault()
        var editActiveStatus
        var editCmpLocation
        var editUserLocation
        if (editUser.editUserActiveStatus === 'true' || true) {
            editActiveStatus = true
        }
        else {
            editActiveStatus = false
        }
        if (editCmpLocation === null) {
            editCmpLocation = ''
        }
        else {
            editCmpLocation = editUser.editUserCmpAddress
        }
        if (editUserLocation === null) {
            editUserLocation = ''
        }
        else {
            editUserLocation = editUser.editUserAddress
        }
        console.log("updated the form editUserIndividualGroup value", editUser.editUserIndividualGroup);
        const updatedForm = {

            id: Number(editUser.id),
            name: editUser.editUserName,
            email: editUser.editUserEmail,
            phone: (editUser.editUserMblCode == null) ? '' : String(editUser.editUserMblCode).concat(" ", editUser.editUserPhone),
            address: editUserLocation,
            roles: [
                editUser.editUserRole
            ],
            position: editUser.editUserPosition,
            experience: editUser.editUserExperience,
            expertise: editUser.editUserExpertise,
            education: editUser.editUserEducation,
            status: editUser.editUserStatus,
            isactive: editActiveStatus,
            company_name: editUser.editUserCompany,
            company_address: editCmpLocation,
            individual_group: editUser.editUserIndividualGroup,
            company_mobile: String(editUser.editUserCmpPhnCode).concat(" ", editUser.editUserCmpPhone)
            // editUserCmpType:editUser.editUserCmpType
        }
        if (userEditflag === true) {
            setForm({ ...edituserForm, isSaving: true })
            console.log("updated the form", updatedForm);
            userService.updateUser(updatedForm).subscribe({
                next: () => {
                    // console.log(pages,'pages')
                    setForm({ ...edituserForm, isSaving: false })
                    swal("Great", "User Updated Successfully", "success")
                    if (edit) {
                        history.push(`/mapCompany/${id}`)
                    }
                    else {
                        history.push('/users')
                    }
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    console.error(err)
                }
            })
        }
        else {
            console.log(userEditflag, 'edit flag')
        }
    }

    onsubmit = () => {
        // e.preventDefault()
        // console.log(Object.keys(errors).length , 'error length')
        if (Object.keys(errors).length === 0) {
            setUserEditFlag(true)
            _didUserEdit()
        }
        else {
            setUserEditFlag(false)
        }
    }
    // const _gopage = () => {
    //     props.dockListGetAction({ size: 10, page: pages })
    // }
    if (edituserForm.isLoading || edituserForm.isSaving) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }
    return (
        <Main>
            {/* <Text color='black' variant="title">Edit User Information</Text> */}
            <div className='title-name-block'>
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                    {editUser.editUserImage === null && (
                        <Image src={noImg} alt='logo' roundedCircle />
                    )}
                    {editUser.editUserImage === '' && (
                        <Image src={noImg} alt='logo' roundedCircle />
                    )}
                    {editUser.editUserImage !== null && editUser.editUserImage !== '' && (
                        <Image src={editUser.editUserImage} alt='logo' roundedCircle />
                    )}
                </div>
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                    <label className="custom-file-upload">
                        <Image src={editicon} alt='logo' />
                        <input
                            ref={upload}
                            type="file"
                            onChange={getBase64}
                        />
                    </label>
                </div>
                <Text variant='title' color='main' className='txt-capitalize'>
                    {editUser.editUserName}
                </Text>
                <Label className={editUser.editUserActiveStatus === false ? 'danger' : 'warning'} >
                    {editUser.editUserActiveStatus === false ? 'In Active' : 'Active'}
                </Label>
            </div>
            <Space divheight={25} />
            <Row className='p-0 m-0'>
                <Col xs='12' sm='12' md='12' lg='9' xl='9' className='p-0 m-0'>
                    <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='userEditForms'>
                        <Card title="Edit User Information">
                            <Row className='m-0 p-0'>
                                <Col xs='12' sm='10' md='10' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Name</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                type="text"
                                                name="txt_editusername"
                                                id='txt_editusername'
                                                value={editUser.editUserName}
                                                onChange={(e) => setUserEdit({ ...editUser, editUserName: e.target.value })}
                                                placeholder='User Name'
                                                ref={register({ required: true, })}
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editusername?.type === 'required' && 'Please enter name'}
                                                    {/* {errors.txt_editusername?.type === 'pattern' && 'Invalid userName'} */}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>E-mail</Text>
                                            <Form.Control
                                                type="text"
                                                className='basecontrol custome-input'
                                                name="txt_edituseremail"
                                                id='txt_edituseremail'
                                                value={editUser.editUserEmail}
                                                onChange={(e) => setUserEdit({ ...editUser, editUserEmail: e.target.value })}
                                                ref={register({ required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                placeholder='Email'
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_edituseremail?.type === 'required' && 'Please Enter e-mail'}
                                                    {errors.txt_edituseremail?.type === 'pattern' && 'Please enter Valid e-mail'}</span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Mobile</Text>
                                            <div className='phnnum-container'>
                                                <Form.Control as="select"
                                                    value={editUser.editUserMblCode}
                                                    // value={String(editUser.editUserMblCode).substring(0,String(editUser.editUserMblCode).indexOf(''))}
                                                    name='txt_editphnCode'
                                                    className='basecontrol cutome-selectInputnxt phn-dp'
                                                    onChange={(e) => setUserEdit({ ...editUser, editUserMblCode: e.target.value })}
                                                // ref={register({ required: true, })}
                                                >
                                                    <option value="">Code</option>
                                                    {edituserForm.mbl_code.map((data: any, i: any) => {
                                                        return <option key={i} value={data.mobile_code}>{data.country_name}</option>
                                                    })}
                                                </Form.Control>
                                                <Form.Control
                                                    name="txt_editphnUser"
                                                    id='txt_editphnUser'
                                                    className='basecontrol custome-input'
                                                    value={editUser.editUserPhone}
                                                    placeholder='Phone'
                                                    ref={register({ pattern: /^[0-9]+$/i, maxLength: 12 })}
                                                    onChange={(e) => setUserEdit({ ...editUser, editUserPhone: e.target.value })}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editphnUser?.type === 'required' && 'Please enter Phone Number'}
                                                    {errors.txt_editphnUser?.type === 'maxLength' && 'Below 12 digit only accepted'}
                                                    {errors.txt_editphnUser?.type === 'minLength' && 'Above 7 digit only accepted'}
                                                    {errors.txt_editphnUser?.type === 'pattern' && 'Please Enter Only Number'}
                                                    {/* {errors.txt_editphnCode?.type === 'required' && 'please Slect Code'} */}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Role</Text>
                                            <Form.Control as="select" value={editUser.editUserRole} name='txt_editroles'
                                                className='basecontrol cutome-selectInputnxt'
                                                onChange={(e) => setUserEdit({ ...editUser, editUserRole: e.target.value })}
                                            // ref={register({ required: true, })}
                                            >
                                                <option value="">-- Select --</option>
                                                {edituserForm.roles.map((data: any, i: any) => {
                                                    return <option key={i} value={data.code}>{data.name}</option>
                                                })}
                                            </Form.Control>

                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editroles?.type === 'required' && 'Please Select any Role from list'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Position</Text>
                                            <Form.Control
                                                name="txt_editposition"
                                                className='basecontrol custome-input'
                                                value={editUser.editUserPosition}
                                                placeholder='position'
                                                // ref={register({ required: true, })}
                                                onChange={(e) => setUserEdit({ ...editUser, editUserPosition: e.target.value })}
                                                type="text"
                                            />

                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editposition?.type === 'required' && 'Please enter Position'}
                                                </span>
                                            </Col>
                                        </Row> */}

                                    </Attributes>
                                </Col>
                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Education</Text>
                                            <Form.Control
                                                className='basecontrol custome-input'
                                                name="txt_editeducation"
                                                id='txt_editeducation'
                                                value={editUser.editUserEducation}
                                                placeholder='Education'
                                                // ref={register({ required: true, })}
                                                onChange={(e) => setUserEdit({ ...editUser, editUserEducation: e.target.value })}
                                                type="text"
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editeducation?.type === 'required' && 'Please enter Education'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Experience</Text>
                                            <Form.Control
                                                name="txt_editexperience"
                                                className='basecontrol custome-input'
                                                id='txt_editexperience'
                                                value={editUser.editUserExperience}
                                                placeholder='Experience'
                                                // ref={register({ required: true, })}
                                                onChange={(e) => setUserEdit({ ...editUser, editUserExperience: e.target.value })}
                                                type="text"
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editexperience?.type === 'required' && 'Please Experience'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Expertise</Text>
                                            <Form.Control
                                                name="txt_editexpertise"
                                                className='basecontrol custome-input'
                                                id='txt_editexpertise'
                                                value={editUser.editUserExpertise}
                                                placeholder='Expertise'
                                                // ref={register({ required: true, })}
                                                onChange={(e) => setUserEdit({ ...editUser, editUserExpertise: e.target.value })}
                                                type="text"
                                            />

                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editexpertise?.type === 'required' && 'Please enter your Expertise'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>User Address</Text>
                                            <Form.Control as="textarea"
                                                className='basecontrol custome-textarea'
                                                placeholder='User Address'
                                                rows={3}
                                                cols={30}
                                                name="txt_edituseraddress"
                                                value={editUser.editUserAddress}
                                                // ref={register({ required: true, })}
                                                onChange={(e) => setUserEdit({ ...editUser, editUserAddress: e.target.value })}
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_edituseraddress?.type === 'required' && 'Please enter your company Address'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editusercmpaddress?.type === 'required' && 'Please enter your company Address'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                    </Attributes>
                                </Col>
                                <Col sm='12' className='m-0 p-0'>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='9' xl='9' className='p-0 m-0'>
                                            <Attributes className='singleFrame'>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Name</Text>
                                                    {edit && (
                                                        <>
                                                            <Form.Control
                                                                name="txt_editusercmpName"
                                                                id='txt_editusercmpName'
                                                                className='basecontrol custome-input'
                                                                value={editUser.editUserCompany}
                                                                placeholder='Company Name'
                                                                // ref={register({ required: true, })}
                                                                onChange={(e) => setUserEdit({ ...editUser, editUserCompany: e.target.value })}
                                                                type="text"
                                                            />
                                                        </>
                                                    )}
                                                    {!edit && (
                                                        <>
                                                            <Form.Control
                                                                name="txt_editusercmpName"
                                                                id='txt_editusercmpName'
                                                                className='basecontrol custome-input'
                                                                value={editUser.editUserCompany}
                                                                placeholder='Company Name'
                                                                // ref={register({ required: true, })}
                                                                onChange={(e) => setUserEdit({ ...editUser, editUserCompany: e.target.value })}
                                                                type="text"
                                                            />

                                                        </>
                                                    )}

                                                </div>
                                                {/* <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>{errors.txt_editusercmpName?.type === 'required' && 'Please enter your company Address'}
                                                        </span>
                                                    </Col>
                                                </Row> */}
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Address</Text>
                                                    <Form.Control as="textarea"
                                                        className='basecontrol custome-textarea'
                                                        placeholder='Company Address'
                                                        rows={3}
                                                        cols={30}
                                                        name="txt_editusercmpaddress"
                                                        value={editUser.editUserCmpAddress}
                                                        onChange={(e) => setUserEdit({ ...editUser, editUserCmpAddress: e.target.value })}
                                                    // ref={register({ required: true, })}
                                                    />

                                                </div>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Contact Number</Text>
                                                    <div className='phnnum-container-company'>
                                                        <Form.Control as="select" value={editUser.editUserCmpPhnCode}
                                                            name='txt_ediCmpPhnCode'
                                                            placeholder='Code'
                                                            className='basecontrol cutome-selectInputnxt phn-dp'
                                                            onChange={(e) => setUserEdit({ ...editUser, editUserCmpPhnCode: e.target.value })}
                                                        // ref={register({ required: true, })}
                                                        >
                                                            <option value="">Code</option>
                                                            {edituserForm.mbl_code.map((data: any, i: any) => {
                                                                return <option key={i} value={data.mobile_code}>{data.country_name}</option>
                                                            })}
                                                        </Form.Control>
                                                        <Form.Control
                                                            name="txt_editphnCmp"
                                                            id='txt_editphnCmp'
                                                            className='basecontrol custome-input'
                                                            value={editUser.editUserCmpPhone}
                                                            placeholder='Company Contact Number'
                                                            ref={register({ pattern: /^[0-9]+$/i, maxLength: 12 })}
                                                            onChange={(e) => setUserEdit({ ...editUser, editUserCmpPhone: e.target.value })}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>{errors.txt_editphnCmp?.type === 'required' && 'Please enter Phone Number'}
                                                            {errors.txt_editphnCmp?.type === 'maxLength' && 'Below 12 digit only accepted'}
                                                            {/* {errors.txt_editphnCmp?.type === 'minLength' && 'Above 7 digit only accepted'} */}
                                                            {errors.txt_editphnCmp?.type === 'pattern' && 'Please Enter Only Number'}
                                                            {/* {errors.txt_editphnCmpCode?.type === 'required' && 'please Slect Code'} */}

                                                        </span>
                                                    </Col>
                                                </Row>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Status</Text>
                                                    <Form.Control as="select"
                                                        name="txt_edituserStatus"
                                                        className='basecontrol cutome-selectInputnxt'
                                                        value={editUser.editUserStatus}
                                                        // ref={register({ required: true, })}
                                                        onChange={(e) => setUserEdit({ ...editUser, editUserStatus: e.target.value })}
                                                    >
                                                        <option value="">-- Select status --</option>
                                                        <option value="VERIFIED">Verified</option>
                                                        <option value="UNVERIFIED">Not verified</option>
                                                    </Form.Control>

                                                </div>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Individual Group</Text>
                                                    <Form.Control as="select"
                                                        name="txt_editindividualgroup"
                                                        className='basecontrol cutome-selectInputnxt'
                                                        value={editUser.editUserIndividualGroup}
                                                        // ref={register({ required: true, })}
                                                        onChange={(e) => setUserEdit({ ...editUser, editUserIndividualGroup: e.target.value })}
                                                    >
                                                        <option value="">-- Select group type --</option>
                                                        <option value="DOCK">DOCK</option>
                                                        <option value="SHIP">SHIP</option>
                                                        <option value="ANODE">ANODE</option>
                                                        <option value="PAINT">PAINT</option>
                                                    </Form.Control>

                                                </div>
                                                {/* <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>{errors.txt_edituserStatus?.type === 'required' && 'Please Select Status from the list'}
                                                        </span>
                                                    </Col>
                                                </Row> */}
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>System Access</Text>
                                                    <div>
                                                        <Form.Check type='radio' className='RadioButton' id='active' name='radio_Activestatus' value={editUser.editUserActiveStatus}
                                                            onChange={(e) => setUserEdit({ ...editUser, editUserActiveStatus: 'true' })}
                                                            checked={editUser.editUserActiveStatus === 'true' || true}
                                                            label='ACTIVE'>
                                                        </Form.Check>
                                                        <Form.Check type='radio' id='inactive' name='radio_Activestatus' value={editUser.editUserActiveStatus}
                                                            className='RadioButton'
                                                            onChange={(e) => setUserEdit({ ...editUser, editUserActiveStatus: 'false' })}
                                                            checked={editUser.editUserActiveStatus === 'false' || false}
                                                            label='IN ACTIVE'>
                                                        </Form.Check>
                                                    </div>
                                                </div>
                                            </Attributes>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                        <Actions>
                            {/* <Link to="/users"> */}
                            <Button onClick={() => _goback()} type="button" className='bttn bttn-transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                            {/* </Link> */}
                            <Button className='bttn bttn-transparent' name='btnEditUser' id='btnEditUser' type="submit">
                                <Flex>
                                    <Icons color="#ffffff" icon="check" />
                                    SAVE DATA
                                </Flex>
                            </Button>
                        </Actions>
                    </Form>
                </Col>
                <Col xs='12' sm='12' md='12' lg='3' xl='3' className='p-0 m-0'>
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Card title="Profile Picture" className='profile-title'>
                            <div className='user-mainBlock'>
                                {editUser.editUserImage === null && (
                                    <div className='userEdit-block'>
                                        <Image roundedCircle src={noImg} alt='logo' />
                                    </div>
                                )}
                                {editUser.editUserImage === '' && (
                                    <div className='userEdit-block'>
                                        <Image roundedCircle src={noImg} alt='logo' />
                                    </div>
                                )}
                                {editUser.editUserImage !== null && editUser.editUserImage !== '' && (
                                    <div className='userEdit-block'>
                                        <Image roundedCircle src={editUser.editUserImage} alt='logo' />
                                    </div>
                                )}
                            </div>
                            {((editUser.editUserImage === null) ||(editUser.editUserImage === '') )&& (
                                <div className='imageEditBlock'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            <Image roundedCircle src={editicon} alt='logo' />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                </div>
                             )} 
                            {editUser.editUserImage !== null && editUser.editUserImage !== '' && (
                                <div className='imageEditBlock'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            <Image roundedCircle src={editicon} alt='logo' className="editIconRemove" />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>

                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={removeicon} alt='logo' onClick={() => _removeUserImg(editUser.id)} />
                                        </Flex>
                                    </label>
                                </div>
                            )}
                        </Card>
                    </div>
                </Col>
            </Row>
        </Main>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.user.selectedUser
})

export default connect(
    mapStateToProps,
    {
        userSetSelectedAction
    }
)(UserEdit)