import { useState } from 'react'
import React from 'react';
import authService from '../../../services/authService';
import classNames from 'classnames';
import { Text } from '../../components/text/Text';
import { connect } from 'react-redux';
import Icons from '../../components/icon/Icon'
import './Navbar.scss'
import '../sidebar/Sidebar'
import {SidebarMobile} from '../sidebar/SidebarMobile'
import Collapse from '../../components/collapse/Collapse'
import { ResetPassword } from '../../../pages/login/pagecontainer/ResetPassWord'

const Navbar = (props:any) => {
    const [username, setUserName] = useState('');
    const [openMenu, setOpenMenu] = React.useState(false)
    const [showModal, setModal] = useState(false)

        // While click modal box close icon, its close the modal box.  
        const onClose = () => {
            setModal(false)
        }

        const willShowModal = () => {
            setModal(true)
        }

    React.useEffect(() => {
      const {account} = props

      if(account){
        setUserName(account.name)
      }
    }, [props])
       
    const logOut = () => {
        authService.logout()
        window.location.reload()
    }

    const _handleClickMenu = () => {
        if(openMenu){
            return setOpenMenu(false)
        }
        else{
            return setOpenMenu(true)
        }
    }

    return(
        <Root>
            <div className='menuontainer'>
                <Collapse className='mobileview'  open={openMenu}>
                    <Icons onClick ={() => _handleClickMenu()} className='menuicon' icon='menu'/>
                    <div className='menubox'>
                        <SidebarMobile/>
                        <Icons onClick ={() => _handleClickMenu()} className='closeicon' icon='close'/>
                    </div>
                </Collapse>
            </div> 
            <User>
                <Toggle >
                    <Text color="main"> Hello, {username} </Text>
                    <Icons icon="expand_more" color="#52575a" />
                        <DropdownItem onClick={() => willShowModal()}>
                            <Text color="white">Change Password</Text>
                        </DropdownItem>
                        <DropdownItem onClick={() => logOut()}>
                            <Text color="white">Logout</Text>
                        </DropdownItem>
                </Toggle>
            </User>
            {showModal && <ResetPassword onClose={() => onClose()} />}
        </Root>       
    )
}

interface ToggleProps{
    children ? : React.ReactNode
    onClick?: (e: React.MouseEvent) => void;
} 

const Toggle = ({children, onClick, ...props}:ToggleProps) =>{

    const toggle = classNames('toggle');
    return(
        <div className= {toggle} onClick={onClick}>
            {children}
        </div>
    )
}

interface UserProps{
    children ? : React.ReactNode
} 

const User = ({children, ...props}:UserProps) =>{

    const user = classNames('user');
    return(
        <div className= {user}>
            {children}
        </div>
    )
}

interface RootProps{
    children ? : React.ReactNode
} 

const Root = ({children, ...props}:RootProps) =>{

    const root = classNames('Root');
    return(
        <div className= {root}>
            {children}
        </div>
    )
}

interface DropItemProps{
    children ? : React.ReactNode
    onClick?: (e: React.MouseEvent) => void;
} 

const DropdownItem = ({children,onClick, ...props}:DropItemProps) =>{

    const dropdown = classNames('dropdown-item');
    return(
        <div className= {dropdown} onClick={onClick}>
            {children}
        </div>
    )
}

const mapStateToProps = (state:any) => ({
  account: state.account.account
})

export default connect(mapStateToProps)(Navbar)