import { ofType } from 'redux-observable'
import { USER_LIST_GET, USER_LIST_GET_All,SYSTEM_USER_LIST_GET } from '../actionType'
import { catchError, map, switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import userService from '../../services/userService'
import {
  userListGetFAction,
  userListGetRAction,
  allUserListGetFAction,
  allUserListGetRAction,
  systemUserListGetFAction,
  systemUserListGetRAction,
  allSystemUserListGetFAction,
  allSystemUserListGetRAction

} from '../action/userActions'

export const getUsersEpic = (action:any) => {
  return action.pipe(
    ofType(USER_LIST_GET),
    switchMap((action:any) => {
      // console.log(action.payload, 'action payload')
      return userService.getUsers(action.payload).pipe(
        map(res => {
          // console.log('res get 10 user ' , res)
          const { data } = res
          const { content, total_page, total_element } = data
          // console.log(content ,'content')
          return userListGetFAction({ content, total_page, total_element })
        }),
        catchError(err => of(userListGetRAction(err)))
      )
    })
  )
}

export const getSystemUsersEpic = (action:any) => {
  return action.pipe(
    ofType(SYSTEM_USER_LIST_GET),
    switchMap((action:any) => {
      // console.log(action.payload, 'action payload')
      return userService.getSystemUsers(action.payload).pipe(
        map(res => {
          // console.log('res get 10 user ' , res)
          const { data } = res
          const { content, total_page, total_element } = data
          // console.log(content ,'content')
          return systemUserListGetFAction({ content, total_page, total_element })
        }),
        catchError(err => of(systemUserListGetRAction(err)))
      )
    })
  )
}

export const getAllUsersEpic = (action:any) => {
  return action.pipe(
    ofType(USER_LIST_GET_All),
    switchMap((action:any) => {
      // console.log(action.payload, 'action payload')
      return userService.getUsers(action.payload).pipe(
        map(res => {
          // console.log('get all user res' , res)
          const { data } = res
          const { content, total_page, total_element } = data
          // console.log(content ,'content')
          // console.log(total_element,'total Element user epics')
          return allUserListGetFAction({ content, total_page, total_element })
        }),
        catchError(err => of(allUserListGetRAction(err)))
      )
    })
  )
}

