import { map } from 'rxjs/operators'
import http from '../helpers/http'

const getSettingsDetail = () => {
  return http({
    method: 'GET',
    url: `/setting/view`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const updateSetting = (body : any) => {
  return http({
    method: 'PUT',
    url: '/setting/update',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}


const settingService = {
  getSettingsDetail,
  updateSetting,
}

export default settingService
