import {ACCOUNT_SET, ACCOUNT_GET, ACCOUNT_SET_F, ACCOUNT_GET_F} from '../actionType';
export const accountSetAction = (data : any) => ({
    type: ACCOUNT_SET,
    payload: data
  })
  export const accountGetAction = () => ({
    type: ACCOUNT_GET
  })
  export const accountGetFAction = (data : any) => ({
    type: ACCOUNT_GET_F,
    payload: data
  })
  export const accountSetFAction = () => ({
    type: ACCOUNT_SET_F
  })