import React from 'react';
import './Grid.scss';
import classNames from 'classnames'

//set interface for Grid component
export interface GridProps {
    className ?:string;
    autoCols?:string | number;
    templateCols? : string | number;
    templateRows? : string | number;
    gp? : string | number;
    children?: React.ReactNode;
}

//grid component return div with basic grid style
export const Grid = ({autoCols, templateCols, templateRows,gp,children,className, ...props } : GridProps) => {
    const gridClassNames = classNames('Grid-basic',className );
    const gridStyle = {
        gridAutoColumns : autoCols,
        gridTemplateColumns : templateCols,
        gridTemplateRows:templateRows,
        gap:gp
    }
    return (
        <div className={gridClassNames} style={gridStyle}>
            {children}
        </div>
    )
}