import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import {  } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { unitsSetSelectedAction } from '../../../../store/action/unitAction'
import unitService from '../../../../services/unitService'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner';      


// Blue print of the class component.  
interface UnitEditProps {
    id: string | number;
    unit: string;
    typeOfUnit: string;
    representation: string;
    description: string;
    unit_english: string;
    name_description: string;
}
interface FormData {
    txt_unitname: string;
    txt_typeOfUnit: string;
    txt_unitrepresentation: string;
    txt_unitdescription: string;
    txt_unit_english: string;
    txt_unitname_description: string;
}
const UnitEdit = (props: any) => {
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const [uniteditflag, setuniteditFlag] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)
    const [display, setDisplay] = React.useState<UnitEditProps>({
        id: '',
        unit: '',
        typeOfUnit: '',
        representation: '',
        description: '',
        unit_english: '',
        name_description: '',
    })
    const [units, setUnits] = useState<any>({
        isLoading: false,
        isSubmitting: false,
        unit: '',
        typeOfUnit: '',
        representation: '',
        description: '',
        unit_english: '',
        name_description: '',
    },
    )


    // Used to get perticular ship detail and store to variable.   
    const _getUnitDetail = ((id: any) => {
        setUnits({ ...units, isLoading: true })
        return unitService.getUnitDetail(id).subscribe({
            next: r => {
                console.log(r, 'response')
                console.log(r.data, 'data within response')
                const value = r.data
                console.log(value, 'data within response using value')
                setDisplay({
                    id: Number(r.data.id),
                    unit: r.data.name,
                    description: r.data.description,
                    unit_english: r.data.name_english,
                    name_description: r.data.description_english,
                    typeOfUnit: r.data.type_of_unit,
                    representation: r.data.representation
                })
                props.unitsSetSelectedAction(value)
                setUnits({ ...units, isLoading: false, })
            },
            error: err => {
                console.error(err)
                setUnits({ ...units, isLoading: false })
            }
        })
    })
    React.useEffect(() => {
        const { match } = props
        const { id } = match.params
        _getUnitDetail(id)
    }, [])

    // After update all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call.  
    const _didUnitEdit = () => {
        // e.preventDefault()
        const updatedForm = {
            id: display.id,
            name: display.unit,
            description: display.description,
            name_english: display.unit_english,
            description_english: display.name_description,
            type_of_unit: display.typeOfUnit,
            representation: display.representation
        }

        // API call for updateship with data, updated into list page. 
        if (uniteditflag === true) {
            setLoading(true)
            unitService.updateUnit(updatedForm).subscribe({
                next: () => {
                    setLoading(false)
                    swal("Great", "Unit Updated Successfully", "success")
                    history.push('/unit/list')
                },
                error: err => {
                    setLoading(false)
                    swal("OOPS", String(err), "error")
                    console.error(err)
                }
            })
        }
        else {
            console.log(uniteditflag)
        }
    }

    onsubmit = (e: any) => {
        // e.preventDefault()
        console.log(Object.keys(errors).length, 'error length')
        if (Object.keys(errors).length === 0) {
            _didUnitEdit()
            setuniteditFlag(true)
        }
        else {
            setuniteditFlag(false)
        }
    }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }
    
    return (
        <Main>
            <Text color='black' variant="title">Edit Unit Information</Text>
            <Space divheight={25} />
            {/* Edit form, having input box, select box.   */}
            <Grid templateCols='75% auto' className='add_page'>
                <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='unitEditForms'>
                    <Card title="Fill All the Information Below">
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Unit</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_unitname"
                                            value={display.unit}
                                            onChange={(e) => setDisplay({ ...display, unit: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="Unit"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_unitname?.type === 'required' && 'Please enter Unit Name'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Representation</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_unitrepresentation"
                                            value={display.representation}
                                            onChange={(e) => setDisplay({ ...display, representation: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="Representation"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_unitrepresentation?.type === 'required' && 'Please enter Representation'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Type of Unit</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_typeOfUnit"
                                            value={display.typeOfUnit}
                                            onChange={(e) => setDisplay({ ...display, typeOfUnit: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="Type of Unit"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_typeOfUnit?.type === 'required' && 'Please enter Type of Unit'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Unit in English</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_unit_english"
                                            value={display.unit_english}
                                            onChange={(e) => setDisplay({ ...display, unit_english: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="Unit in English"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_unit_english?.type === 'required' && 'Please enter unint in English'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes >
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Description</Text>
                                        <Form.Control as='textarea'
                                            className='basecontrol custome-textarea'
                                            name="txt_unitdescription"
                                            placeholder='Description'
                                            rows={3}
                                            cols={50}
                                            value={display.description}
                                            onChange={(e) => setDisplay({ ...display, description: e.target.value })}
                                            ref={register({ required: true, })}
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_unitdescription?.type === 'required' && 'Please enter Description'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Description in English</Text>
                                        <Form.Control as='textarea'
                                            className='basecontrol custome-textarea'
                                            name="txt_unitname_description"
                                            placeholder='Description in English'
                                            rows={3}
                                            cols={50}
                                            value={display.name_description}
                                            onChange={(e) => setDisplay({ ...display, name_description: e.target.value })}
                                            ref={register({ required: true, })}
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_unitname_description?.type === 'required' && 'Please enter Description in english'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                    <Actions>
                        {/* If you click the back button its is redirect to units list page.   */}
                        <Link to='/unit/list'>
                            <Button type="button" className='bttn bttn-transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the save button its is call the function, in that data updated to list and redirect to list page.   */}
                        <Button className='bttn bttn-transparent' type="submit">
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
        </Main>
    )
}


export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style.  
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-ship-add');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}

// Button style.  
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    units: state.units.selectedUnits
})
//Return the selected ship details.  
export default connect(
    mapStateToProps,
    {
        unitsSetSelectedAction
    }
)(UnitEdit)
