import { connect } from "react-redux";
import { shipSetSelectedAction } from '../../../../store/action/shipActions';
import shipService from '../../../../services/shipService'
import React from "react";
import { Col, Image, Row } from 'react-bootstrap'
import { Text } from '../../../../common/components/text/Text'
import { Main } from "../../../../common/components/main/Main";
import { Space } from "../../../../common/components/space/Space";
import Card from "../../../../common/components/card/Card";
import removeicon from '../../../../assets/images/Remove.png';
import { Grid } from "../../../../common/components/grid/Grid";
import editicon from '../../../../assets/images/editone.png';
import { Attributes } from "../../../../common/components/attribute/Attribute";
import classNames from "classnames";
import swal from 'sweetalert';
import { Flex } from "../../../../common/components/flex/Flex";
import { Link } from "react-router-dom";
import { Button } from "../../../../common/components/button/Button";
import Icons from "../../../../common/components/icon/Icon";
import { Label } from "../../../../common/components/label/Label";
import { format, parseISO } from 'date-fns'
import Loader from "react-loader-spinner";
import noimg from '../../../../assets/images/NoImg.jpg';
import { useRef } from "react";
import { history } from '../../../../store';
import Carousel from 'react-bootstrap/Carousel'

const ShipDetail = (props: any) => {
    const { match } = props
    const [isLoading, setLoading] = React.useState(true)
    const [display, setShip] = React.useState<any>({})
    const [showDelete, setShow] = React.useState(false);
    var [removeShipImage, setRemoveShipImage] = React.useState(0)
    var data: any
    var length: any
    var example = ["https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1711.jpg",
        "https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1749.jpg",
        "https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1369.jpg"]


    //Call perticular ship detail function.  By Dharani on 04-Mar-2021
    React.useEffect(() => {
        const { id } = match.params
        console.log(id)
        console.log("inside react user effect")
        _getShipDetail(id)
    }, [])

    const role = localStorage.getItem("role")

    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<any>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageUserAPI(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    // Delete the pericular list.  
    const handleClose = () => setShow(false);
    const handleShow = (ship: any) => {
        setShow(true);
        setRemoveShipImage(ship.ship_id);
        // console.log(deleteShip,'deleted ship id')
    }

    const _removeShipLogo = (id: any) => {
        return shipService.removeShipLogo(id).subscribe({
            next: (r) => {
                // console.log(r, 'response')
                setShow(false);
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
                // history.push(`/ships/${id}/`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
            }
        })
    }
    const ImageUserAPI = (imageurl: any) => {
        const { id } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        shipService.updateShipLogo(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                window.location.reload();
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }

    //Get perticular ship detail using match.params. By Dharani on 04-Mar-2021
    const _getShipDetail = (id: any) => {
        setLoading(true)

        return shipService.getShipDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.shipSetSelectedAction(r.data)
                console.log(r.data, 'r.data inside if')
                setShip(r.data)
                // r.map((data: any, i: any) => {
                //     return display.push(data.data)
                // })
                // console.log(display,'selected ship list page in ship detail using display')
                setLoading(false)
            },
            error: err => {
                console.error(err)
                //     swal("OOPS", err, "error")
                //    history.push(`/ships/list`)
                console.log('r.data inside err')
                setLoading(false)

            }
        })
    }
    // If isloading is true print a text as loading. By Dharani on 04-Mar-2021
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    const _goback = () => {
        const { pgnum } = match.params
        const { key } = match.params
        console.log('go back called')
        console.log(pgnum, 'page')
        history.push(`/ships/list/${pgnum}`)
    }

    length = 0
        if (display.shipLogo !== null) {
        data = [display.shipLogo.map((data: any, i: any) => {
            length = length + 1
            return String(data.name)

        })];
        console.log("Check", data);
    } else {
        data = null;
    }
    console.log(length)
    if (display.shipLogo !== null) {
        data.map((value: any) => (
            example = value
            //       console.log("inside carosel",value)
        ))
    }

    // If isloading is false print the details of perticular ship. By Dharani on 04-Mar-2021
    return (
        <Main>
            {console.log(display, "inside main")}
            <div className='title-name-block'>
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                    {display.ship_image === null && (
                        <Image src={noimg} alt='logo' roundedCircle />
                    )}
                    {display.ship_image === '' && (
                        <Image src={noimg} alt='logo' roundedCircle />
                    )}
                    {display.ship_image !== null && display.ship_image !== '' && (
                        <Image src={display.ship_image} alt='logo' roundedCircle />
                    )}
                </div>

                {role === 'VISITOR' && (
                    <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                        <label className="custom-file-upload">
                            <Image src={editicon} alt='logo' />
                            <input
                                ref={upload}
                                type="file"
                                onChange={getBase64}
                            />
                        </label>
                    </div>
                )}
                <Text variant='title' color='main' className='txt-capitalize'>
                    {display.name}
                </Text>
                <Label className={display.status === 'UNVERIFIED' ? 'danger' : 'warning'} >
                    {display.status === 'UNVERIFIED' ? 'Not Verified' : 'Verified'}
                </Label>
            </div>
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                <Col sm='12' md='12' lg='12' xl='8' className='m-0 p-0 align-top'>
                    <Card title="Ship Detail">
                        {display.length === 0 && (
                            <div>
                                <Text color='main' variant='subtitle'>Data not Availble.. We will connect you soon.</Text>
                            </div>
                        )}
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>

                                    <div>
                                        <Text color='main' variant='subtitle'>Ship Name</Text>
                                        {display.name !== null ? (
                                            <Text color='main' variant='subtitle' bold >{display.name}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>IMO Number</Text>
                                        {display.imo_number !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.imo_number}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Ship Type</Text>
                                        {display.shipTypes !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.shipTypes.name}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Country Flag</Text>
                                        {display.shipCountry !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.shipCountry.name}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Hull Material</Text>
                                        {display.shipMaterial !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.shipMaterial.name}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Length Overall</Text>
                                        {display.length !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.length} {display.shipUnitLength.name}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Length Between Perpendiculars</Text>
                                        {display.lbp !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.lbp}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Maximum Beam</Text>
                                        {display.beam !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.beam} {display.shipUnitBeam.name}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Depth</Text>
                                        {display.depth !== null && display.shipUnitDepth !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.depth} {display.shipUnitDepth.name}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Draft</Text>
                                        {display.draft !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.draft} {display.shipUnitDraft.name}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Ship Weight </Text>
                                        {display.weight !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.weight} {display.shipUnitWeight.representation}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Gross Register Tonnage (GRT)</Text>
                                        {display.grt !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.grt}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Net Register Tonnage (NRT)</Text>
                                        {display.nrt !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.nrt}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Deadweight tonnage</Text>
                                        {display.dwt !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.dwt}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>

                                    <div>
                                        <Text color='main' variant='subtitle'>Status</Text>
                                        {display.status !== 'VERIFIED' ? (
                                            <Text color='danger' variant='subtitle' bold>
                                                Not Verified
                                            </Text>
                                        ) : (
                                            <Text color='success' variant='subtitle' bold>
                                                Verified
                                            </Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'> inService</Text>
                                        {display.inService !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.inService}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Ship Call</Text>
                                        {display.shipCall !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.shipCall}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Ship Owner</Text>
                                        {display.shipCompany !== null ? (
                                            <>{display.shipCompany.shipOwner !== null ? (
                                                <Text color='main' variant='subtitle' bold>
                                                    {display.shipCompany.shipOwner.name}</Text>
                                                    ) : (
                                                    <Text variant='subtitle' color="main" bold> - </Text>
                                                    )}</>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Year Built</Text>
                                        {display.yearBuilt !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.yearBuilt}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Builder Name</Text>
                                        {display.builderName !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.builderName}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>

                                    <div>
                                        <Text color='main' variant='subtitle'>Builder Address</Text>
                                        {display.builderAddress !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.builderAddress}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Ship Operator</Text>
                                        {display.shipOperator !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.shipOperator}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>

                                    <div>
                                        <Text color='main' variant='subtitle'>Ship Operator Address</Text>
                                        {display.shipOperatorAddress !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.shipOperatorAddress}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>

                                    <div>
                                        <Text color='main' variant='subtitle'>Classification</Text>
                                        {display.shipClassification !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.shipClassification.name}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'> Ship Classification2</Text>
                                        {display.shipClassification2 !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.shipClassification2.name}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Main Engine</Text>
                                        {display.mainEngine !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.mainEngine}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Aux Engine</Text>
                                        {display.auxEngine !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.auxEngine}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Pic Credit</Text>
                                        {display.picCredit !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.picCredit}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Source Data</Text>
                                        {display.source_data !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.source_data}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>
                                    {/* source_data */}

                                    {display.source_data !== 'BKI' ? (
                                        <>
                                            <div>
                                                <Text color='main' variant='subtitle'>Special Survey Date</Text>
                                                {display.specialSurveyDate !== null ? (
                                                    <Text color='main' variant='subtitle' bold> {format(parseISO(display.specialSurveyDate), 'dd/MM/yyyy')} </Text>
                                                ) : (
                                                    <Text variant='subtitle' bold> - </Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Dry Dock Date</Text>
                                                {display.dryDockDate === null ? (
                                                    <Text variant='subtitle' bold> - </Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold> {format(parseISO(display.dryDockDate), 'dd/MM/yyyy')} </Text>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <Text color='main' variant='subtitle'>Last Docking Date</Text>
                                                {display.dryDockDate === null ? (
                                                    <Text variant='subtitle' bold> - </Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold> {format(parseISO(display.dryDockDate), 'dd/MM/yyyy')} </Text>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div>
                                        <Text color='main' variant='subtitle'>Mmsi</Text>
                                        {display.mmsi !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.mmsi}</Text>
                                        ) : (
                                            <Text variant='subtitle' color="main" bold> - </Text>
                                        )}
                                    </div>

                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm='12' md='12' lg='4' xl='4' className='m-0 p-0 align-top'>
                    {/* <div className='ml-xl-3  mt-sm-4 mt-md-4 mt-lg-4 mt-xl-0 view_page_xs_margin'  >

                    </div> */}
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Card title="Ship Image">
                            {/* {role === 'VISITOR' ? (
                                <div className='user-mainBlock'>
                                    {display.ship_image === null && (
                                        <div className='userEdit-block'>
                                            <img src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {display.ship_image === '' && (
                                        <div className='userEdit-block'>
                                            <img src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {display.ship_image !== null && display.ship_image !== '' && (
                                        <div className='userEdit-block'>
                                            <img src={display.ship_image} alt='logo' />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='user-mainBlock'>
                                    {display.ship_image === null && (
                                        <div className='userCompany-block'>
                                            <img src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {display.ship_image === '' && (
                                        <div className='userCompany-block'>
                                            <img src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {display.ship_image !== null && display.ship_image !== '' && (
                                        <div className='userCompany-block'>
                                            <img src={display.ship_image} alt='logo' />
                                        </div>
                                    )}
                                </div>
                            )} */}
                            <div className='user-mainBlock'>
                                {display.ship_image === null && (
                                    <div className='userCompany-block'>
                                        <img src={noimg} alt='logo' />
                                    </div>
                                )}
                                {display.ship_image === '' && (
                                    <div className='userCompany-block'>
                                        <img src={noimg} alt='logo' />
                                    </div>
                                )}
                                {display.ship_image !== null && display.ship_image !== '' && length === 1 && (
                                    <div className='userCompany-block'>
                                        <img src={display.ship_image} alt='logo' />
                                    </div>
                                )}
                                {display.ship_image !== null && display.ship_image !== '' && length > 1 && (
                                    <Carousel slide={true} touch={true}>
                                        {example.map((value: any) => (
                                            <Carousel.Item interval={5000}>
                                                <div className='userCompany-block'>
                                                    <img
                                                        className="d-block w-100 slide"
                                                        src={value}
                                                        alt="First slide"
                                                    />
                                                </div>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                )}
                            </div>
                            {((display.ship_image === null) || (display.ship_image === '')) && role === 'VISITOR' && (
                                <div className='imageEditBlock pt-0'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={editicon} alt='logo' />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                </div>
                            )}
                            {display.ship_image !== null && display.ship_image !== '' && role === 'VISITOR' && (
                                <div className='imageEditBlock'>
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={editicon} alt='logo' className="editIconRemove" />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={removeicon} alt='logo' onClick={() => _removeShipLogo(display.id)} />
                                        </Flex>
                                    </label>
                                </div>
                            )}
                        </Card>
                    </div>
                </Col>
            </Row>
            <Row className='p-0 m-0 mt-2'>
                <Col className='m-0 p-0' xs='12' md='12' lg='8' xl='8'>
                    <Card title="Company Information">
                        <Row className='p-o m-0'>
                            <Col sm='12' className='p-0 m-0'>
                                {display.shipCompany === null && (
                                    <>
                                        <Space divheight={20} />
                                        <Text variant='subtitle' center color="main" bold>No Company Associated with this Ship</Text>
                                        <Space divheight={20} />
                                    </>
                                )}
                                {display.shipCompany !== null && (
                                    <>
                                        <Attributes className='singleFrame'>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Name</Text>
                                                {display.shipCompany === null || '' && (
                                                    <Text color='main' variant='subtitle' bold> - </Text>
                                                )}
                                                {display.shipCompany !== null && (
                                                    <Text color='main' variant='subtitle' bold>{display.shipCompany.name}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Created Date</Text>
                                                {display.shipCompany === null || '' && (
                                                    <Text color='main' variant='subtitle' bold>
                                                        --
                                                    </Text>
                                                )
                                                }
                                                {display.shipCompany !== null && (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {format(parseISO(display.shipCompany.createdAt), 'dd/MM/yyyy, hh:mm')}
                                                    </Text>
                                                )
                                                }
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Status</Text>
                                                {display.shipCompany === null && (
                                                    <Text color='danger' variant='subtitle' bold> - </Text>
                                                )}
                                                {display.shipCompany !== null && (
                                                    display.shipCompany.status === 'UNVERIFIED' ? (
                                                        <Text color='danger' variant='subtitle' bold>
                                                            Not Verified
                                                        </Text>
                                                    ) : (
                                                        <Text color='main' variant='subtitle' bold>
                                                            Verified
                                                            {/* {format(parseISO(display.verified_at), 'dd/MM/yyyy, hh:mm')} */}
                                                        </Text>
                                                    )
                                                )}
                                            </div>
                                        </Attributes>
                                    </>
                                )}

                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='8' xl='8'>
                    <Actions>
                        {/* If you click the back button its is redirect to ships list page. By Dharani on 04-Mar-2021 */}
                        <Button type="button" className='bttn bttn-transparent' onClick={() => _goback()}>
                            <Flex jc='jc-around'>
                                <Icons icon="arrow_back" />
                                BACK
                            </Flex>
                        </Button>
                        {/* If you click the edit button its is redirect to ships edit page. By Dharani on 04-Mar-2021 */}
                        {role === 'VISITOR' ? (
                            //<Link to={`/ships/edit/${display.id}`}>
                            <Button variant='transparent' type="submit" disabled>
                                <Flex>
                                    <Icons color="#ffffff" icon="edit" />
                                    EDIT DATA
                                </Flex>
                            </Button>
                            // </Link>
                        ) : (
                            <Link to={`/ships/edit/${display.id}/${0}`}>
                                <Button variant='transparent' type="submit">
                                    <Flex>
                                        <Icons color="#ffffff" icon="edit" />
                                        EDIT DATA
                                    </Flex>
                                </Button>
                            </Link>
                        )}
                    </Actions>
                </Col>
            </Row>
        </Main>
    )
}
export interface ActionProps {
    children?: React.ReactNode
}

const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}


const mapStateToProps = (state: any) => ({
    ship: state.ship.selectedShip
})

// connect to shipSetSelectedaction and get the one perticular ship data. By Dharani on 04-Mar-2021
export default connect(
    mapStateToProps,
    {
        shipSetSelectedAction
    }
)(ShipDetail)