import {
    COUNTRY_LIST_GET,
    COUNTRY_LIST_GET_F,
    COUNTRY_LIST_GET_R,
    COUNTRY_SELECTED_SET
}  from '../actionType'

export const countryListGetAction = ( params : any) => ({
    type: COUNTRY_LIST_GET,
    payload: params
})
  
export const countryListGetFAction = ( data : any) => ({
    type: COUNTRY_LIST_GET_F,
    payload: data
})
  
export const countryListGetRAction = ( error : any) => ({
    type: COUNTRY_LIST_GET_R,
    payload: error
})
export const countrySetSelectedAction = (country : any) => ({
    type: COUNTRY_SELECTED_SET,
    payload: country
})