import {
    SYSTEM_USER_LIST_GET_All,
    SYSTEM_USER_LIST_GET,
    SYSTEM_USER_LIST_GET_F,
    SYSTEM_USER_LIST_GET_R,
    SYSTEM_USER_SELECTED_SET,
    SYSTEM_USER_LIST_GET_ALL_F,
    SYSTEM_USER_LIST_GET_ALL_R
  } from '../actionType'


const initialUserState = {
    allsystemUsers:[],
    systemUsers: [],
    isLoading: false,
    error: null,
    selectedSystemUser: null,
    totalPage: 0,
    totalElement: 0,
}
 
export default function systemUserReducer (state = initialUserState, action:any)  {
    switch(action.type) {
        case SYSTEM_USER_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case SYSTEM_USER_LIST_GET_All:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case SYSTEM_USER_LIST_GET_F:
            var totalrow = 0
           
            if(action.payload.content === undefined || '')
            {
                totalrow = 0
            }
            else{
                action.payload.content.map((data:any) => {
                    totalrow = data.totalrow
                })
            }
            return {
                ...state,
                isLoading: false,
                systemUsers: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: totalrow
            }
        case SYSTEM_USER_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SYSTEM_USER_LIST_GET_ALL_F:
            return {
                ...state,
                isLoading: false,
                allsystemUsers:action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
            }
        case SYSTEM_USER_LIST_GET_ALL_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SYSTEM_USER_SELECTED_SET:
            return {
                ...state,
                selectedSystemUser: action.payload
            }
        default:
            return state
    }
}