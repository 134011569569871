import {
    
    ADS_LIST_GET,
    ADS_LIST_GET_F,
    ADS_LIST_GET_R,
    ADS_SELECTED_SET,
  } from '../actionType'
  
  export const adsListGetAction = ( params : any) => ({
    type: ADS_LIST_GET,
    payload: params
  })
  
  export const adsListGetFAction = ( data : any) => ({
    type: ADS_LIST_GET_F,
    payload: data
  })
  
  export const adsListGetRAction = ( error : any) => ({
    type: ADS_LIST_GET_R,
    payload: error
  })
  
  export const adsSetSelectedAction = (ads: any) => ({
    type: ADS_SELECTED_SET,
    payload: ads
  })  