import {
    ADS_LIST_GET,
    ADS_LIST_GET_R,
    ADS_LIST_GET_F,
    ADS_SELECTED_SET,
} from '../actionType';
const initialState = {
    ads: [],
    isLoading: false,
    error: null,
    totalPage: 0,
    totalElement: 0,
    selectedads: null,
}
export default function adsReducer(state = initialState, action: any) {
    switch (action.type) {
        case ADS_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case ADS_LIST_GET_F:
            var totaladscount = 0
            if (action.payload.content === undefined || '') {
                totaladscount = 0
            }
            else {
                action.payload.content.map((data: any) => {
                    totaladscount = data.totalrow
                })
            }
            return {
                ...state,
                isLoading: false,
                ads: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: totaladscount
            }
        case ADS_LIST_GET_R:

            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case ADS_SELECTED_SET:
            return{
                ...state,
                isLoading: false,
                selectedads: action.payload
            }
        default:
            return state
    }
}