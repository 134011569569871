import { ofType } from 'redux-observable'
import {
  UNITS_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  unitsListGetFAction,
  unitsListGetRAction

} from '../action/unitAction'
import unitService from '../../services/unitService'

export const getunitsEpic = (action$ : any) => {
  return action$.pipe(
    ofType(UNITS_LIST_GET),
    switchMap((action : any) => {
      return unitService.getUnit(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in unitsEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return unitsListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(unitsListGetRAction(err)))
      )
    })
  )
}