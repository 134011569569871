import React from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import { dockService } from '../../../../services/dockService'
import removeicon from '../../../../assets/images/Remove.png';
import editicon from '../../../../assets/images/editone.png';
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import swal from 'sweetalert';
import { Label } from "../../../../common/components/label/Label";
import { dockSetSelectedAction } from '../../../../store/action/dockActions';
import { history } from '../../../../store';
import { connect } from 'react-redux';
import noimg from '../../../../assets/images/NoImg.jpg';
import { Col, Row, Image } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { useRef } from 'react';

const DockDetail = (props: any) => {
    const { match } = props
    const [isLoading, setLoading] = React.useState(true)
    const [dock, setDock] = React.useState<any>({})
    const [showDelete, setShow] = React.useState(false);
    var [removeDockImage, setRemoveDockImage] = React.useState(0)

    //const [isDeleting, setDelete] = React.useState(false)

    React.useEffect(() => {
        const { id } = match.params
        _getDockDetail(id)
    }, [])

    const role = localStorage.getItem("role")
    const { pgnum } = match.params

    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<any>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageUserAPI(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const ImageUserAPI = (imageurl: any) => {
        const { id } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        dockService.updateDockImage(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                window.location.reload();
                //                history.push(`/docks/${id}/${pgnum}`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }

                // Delete the pericular list.  
                const handleClose = () => setShow(false);
                const handleShow = (dock: any) => {
                setShow(true);
                setRemoveDockImage(dock.id);
                // console.log(deleteShip,'deleted ship id')
                }
            
                    const _removeDockLogo = (id: any) => {
                    return dockService.deleteDockAllImage(id).subscribe({
                      next: (r) => {
                        // console.log(r, 'response')
                        setShow(false);
                        // swal("Great", " Image Removed Successfully", "success")
                        window.location.reload();
                        // history.push(`/ships/${id}/`)
                      },
                      error: err => {
                        swal("OOPS", String(err), "error")
                      }
                    })
                  }

    const _getDockDetail = (id: any) => {
        setLoading(true)

        return dockService.getDockDetail(id).subscribe({
            next: r => {
                const { data } = r.data
                // console.log(r.data, 'detail view data')
                props.dockSetSelectedAction(r.data)
                setDock(r.data)
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    const _goback = () => {
        const { pgnum } = match.params
        const { key } = match.params
        console.log('go back called')
        console.log(pgnum, 'page')
        // dockCompanyListGetAction({ page:pgnum, size: 10,
        //     companyname: FilterState.cmpFilter.cmp || key, 
        //     city: FilterState.cmpFilter.city, 
        //     country: FilterState.cmpFilter.country,
        //     fromDate:String(FilterState.cmpFilter.fromDate),
        //     toDate: String(FilterState.cmpFilter.toDate), 
        //     status: FilterState.cmpFilter.status, 
        //     claimedstatus:FilterState.cmpFilter.claimedStatus,
        // })
        //        window.location.reload()
        history.push(`/docks/list/${pgnum}`)
    }

    // const _willDelete = (id:any) => {
    //     setDelete(true)
    //     return dockService.deleteDock().subscribe({
    //         next: () => {
    //         setDelete(false)
    //         history.goBack()
    //     },
    //     error: err => {
    //         console.error(err)
    //         setDelete(false)
    //         }
    //     })
    // }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    return (
        <Main>
            <div className='title-name-block'>
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                    {dock.dock_image === null && (
                        <Image src={noimg} alt='logo' roundedCircle />
                    )}
                    {dock.dock_image === '' && (
                        <Image src={noimg} alt='logo' roundedCircle />
                    )}
                    {dock.dock_image !== null && dock.dock_image !== '' && (
                        <Image src={dock.dock_image} alt='logo' roundedCircle />
                    )}
                </div>
                {role === 'VISITOR' && (
                    <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                        <label className="custom-file-upload">
                            <Image src={editicon} alt='logo' />
                            <input
                                ref={upload}
                                type="file"
                                onChange={getBase64}
                            />
                        </label>
                    </div>
                )}
                <Text variant='title' color='main' className='txt-capitalize'>
                    {/* {String(dock.email).substring(0,String(dock.email).indexOf('@'))} */}
                    {dock.name}
                </Text>
                <Label className={dock.status === 'UNVERIFIED' ? 'danger' : 'warning'} >
                    {dock.status === 'UNVERIFIED' ? 'Not Verified' : 'Verified'}
                </Label>
            </div>
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                <Col sm='12' md='12' lg='8' xl='8' className='m-0 p-0 align-top'>
                    <Card title="DryDock Detail">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>DryDock Name</Text>
                                        {dock.name !== null ? (
                                            <Text variant='subtitle' color='main' bold>
                                                {dock.name}
                                            </Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>DryDock Type</Text>
                                        {dock.dockType !== null ? (
                                            <Text variant='subtitle' color="main" bold>{dock.dockType.name}</Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'> Latitude</Text>
                                        {dock.lat !== null ? (
                                            <Text color="main" variant='subtitle' bold> {dock.lat} </Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'> Longitude</Text>
                                        {dock.lon !== null ? (
                                            <Text color="main" variant='subtitle' bold> {dock.lon} </Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'> Unit</Text>
                                        {dock.unit === null ? (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> {dock.unit} </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'> DWT Capacity</Text>
                                        {dock.dwt_capacity === null ? (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> {dock.dwt_capacity} </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Preferred Ship Type</Text>
                                        {dock.shipType === null ? (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> {dock.testshipType} </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Area Coordinates</Text>
                                        {dock.area_coordinates === null ? (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> {dock.area_coordinates} </Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Dock Length</Text>
                                        {dock.length !== null ? (
                                            <Text color="main" variant='subtitle' bold> {dock.length} {dock.dockLengthUnit.name}</Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Dock Width</Text>
                                        {dock.width !== null ? (
                                            <Text color="main" variant='subtitle' bold> {dock.width} {dock.dockWidthUnit.name}</Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Dock Draft</Text>
                                        {dock.draft !== null ? (
                                            <Text color="main" variant='subtitle' bold> {dock.draft} {dock.dockDratUnit.name}</Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Max Ship Length</Text>
                                        {dock.max_ship_length !== null ? (
                                            <Text color="main" variant='subtitle' bold> {dock.max_ship_length} {dock.max_ship_length_unit_id.name}</Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Max Ship Width</Text>
                                        {dock.max_ship_width !== null ? (
                                            <Text color="main" variant='subtitle' bold> {dock.max_ship_width} {dock.max_ship_width_unit_id.name}</Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Max Ship Draft</Text>
                                        {dock.max_ship_draft !== null ? (
                                            <Text color="main" variant='subtitle' bold> {dock.max_ship_draft} {dock.max_ship_width_unit_id.name}</Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'> Crane</Text>
                                        {dock.cranes !== null ? (
                                            <Text color="main" variant='subtitle' bold> {dock.cranes} </Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'> Crane capacity</Text>
                                        {dock.crane_capacity !== null ? (
                                            <Text color="main" variant='subtitle' bold> {dock.crane_capacity} </Text>
                                        ) : (
                                            <Text color="main" variant='subtitle' bold> - </Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='12'>
                                <Card title="Company Information">
                                    <Row className='p-o m-0'>
                                        <Col sm='12' className='p-0 m-0'>
                                            {dock.dockCompany === null ? (
                                                <>
                                                    <div>
                                                        <Text color='main' bold center variant='subtitle'> Not Associated with any Company </Text>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <Attributes className='view_Page'>
                                                        <div>
                                                            <Text color='main' variant='subtitle'> Associated Company </Text>
                                                            <Text color="main" variant='subtitle' bold> {dock.dockCompany.name} </Text>
                                                        </div>
                                                        <div>
                                                            <Text color='main' variant='subtitle'> Verified Date</Text>
                                                            {dock.dockCompany.verified_at === null ? (
                                                                <Text variant='subtitle' color="danger" bold> Not Verified Yet</Text>
                                                            ) : (
                                                                <Text color="main" variant='subtitle' bold>
                                                                    {dock.dockCompany.verified_at !== null && (
                                                                        format(parseISO(dock.verified_at), 'dd/MM/yyyy, hh:mm')
                                                                    )}
                                                                    {dock.dockCompany.verified_at === null && (
                                                                        '--'
                                                                    )}
                                                                </Text>
                                                            )}
                                                        </div>
                                                    </Attributes>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm='12' md='12' lg='4' xl='4' className='m-0 p-0 align-top'>
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Card title="Dock Image">
                            {role === 'VISITOR' ? (
                                <div className='user-mainBlock'>
                                    {dock.dock_image === null && (
                                        <div className='userCompany-block'>
                                            <img src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {dock.dock_image === '' && (
                                        <div className='userCompany-block'>
                                            <img src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {dock.dock_image !== null && dock.dock_image !== '' && (
                                        <div className='userCompany-block'>
                                            <img src={dock.dock_image} alt='logo' />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='user-mainBlock'>
                                    {dock.dock_image === null && (
                                        <div className='userCompany-block'>
                                            <img src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {dock.dock_image === '' && (
                                        <div className='userCompany-block'>
                                            <img src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {dock.dock_image !== null && dock.dock_image !== '' && (
                                        <div className='userCompany-block'>
                                            <img src={dock.dock_image} alt='logo' />
                                        </div>
                                    )}
                                </div>
                            )}
                            {((dock.dock_image === null)||(dock.dock_image === '')) && role === 'VISITOR' && (
                                <div className='imageEditBlock pt-0'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={editicon} alt='logo' />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                </div>
                            )}
                            {dock.dock_image !== null && dock.dock_image !== '' && role === 'VISITOR' && (
                                <div className='imageEditBlock'>
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={editicon} alt='logo' className="editIconRemove" />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={removeicon} alt='logo' onClick={() => _removeDockLogo(dock.id)} />
                                        </Flex>
                                    </label>
                                </div>
                            )}
                        </Card>
                    </div>
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Space divheight={15} />
                    </div>
                    <div className='ml-xl-3  mt-sm-4 mt-md-4 mt-lg-4 mt-xl-0 view_page_xs_margin'>
                        <Card title="Owner Detail">
                            <Row className='m-0 p-0'>
                                <Col sm='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                    <Attributes className='view_Page'>
                                        <div>
                                            <Text color='main' variant='subtitle'>DryDock Owner</Text>
                                            {dock.dockCompany !== null && (
                                                <>
                                                    {dock.dockCompany.user !== null ? (
                                                        <Text variant='subtitle' color="main" bold>{dock.dockCompany.user.name}</Text>
                                                    ) : (
                                                        <Text variant='subtitle' color="main" bold>No Owner Associated</Text>
                                                    )}
                                                </>
                                            )}
                                            {dock.dockCompany === null && (
                                                <Text variant='subtitle' color="main" bold>No Company Or Owner Associated</Text>
                                            )}
                                        </div>
                                        <div>
                                            <Text color='main' variant='subtitle'>Address</Text>
                                            {dock.dockCompany !== null && (
                                                <>
                                                    {dock.dockCompany.user !== null ? (
                                                        <Text variant='subtitle' color="main" bold>{dock.dockCompany.user.address}</Text>
                                                    ) : (
                                                        <Text variant='subtitle' color="main" bold> - </Text>
                                                    )}
                                                </>
                                            )}
                                            {dock.dockCompany === null && (
                                                <Text variant='subtitle' color="main" bold> - </Text>
                                            )}
                                        </div>
                                    </Attributes>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='8' xl='8'>
                    <Actions>
                        {/* <Link to='/docks/list'>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link> */}
                        <Button type="button" className='bttn bttn-transparent' onClick={() => _goback()}>
                            <Flex jc='jc-around'>
                                <Icons icon="arrow_back" />
                                BACK
                            </Flex>
                        </Button>
                        <div>
                            {role === 'VISITOR' ? (
                                //               <Link to={`/docks/edit/${dock.id}`} >
                                <Button variant='transparent' disabled>
                                    <Flex>
                                        <Icons color="#ffffff" icon="edit" />
                                        EDIT DATA
                                    </Flex>
                                </Button>
                                //             </Link>
                            ) : (
                                <Link to={`/docks/edit/${dock.id}/${pgnum}`} >
                                    <Button variant='transparent' >
                                        <Flex>
                                            <Icons color="#ffffff" icon="edit" />
                                            EDIT DATA
                                        </Flex>
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </Actions>
                </Col>
            </Row>
        </Main>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}
export interface CompanyProps {
    children?: React.ReactNode
    className?: string;
}
export const Company = ({ children, className, ...props }: CompanyProps) => {
    const attribute = classNames('company-container', className);

    return (
        <div {...props} className={attribute} >
            {children}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    dock: state.dock.selectedDock
})

export default connect(
    mapStateToProps,
    {
        dockSetSelectedAction
    }
)(DockDetail)
