import{ANODE_LIST_GET,
    ANODE_LIST_GET_F,
    ANODE_LIST_GET_R,
    ANODE_SELECTED_SET
} from '../actionType'

export const anodeListGetAction = (params : any) => ({
type: ANODE_LIST_GET,
payload: params
})

export const anodeListGetFAction = (params : any) => ({
type: ANODE_LIST_GET_F,
payload: params
})

export const anodeListGetRAction = (params : any) => ({
type: ANODE_LIST_GET_R,
payload: params
})

export const anodeSetSelectedAction = (type : any) => ({
type: ANODE_SELECTED_SET,
payload: type
})