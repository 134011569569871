import {
    DOCK_COMPANY_LIST_GET, DOCK_COMPANY_LIST_GET_F, DOCK_COMPANY_LIST_GET_R, DOCK_COMPANY_SELECTED_SET,
    DOCK_LIST_GET,
    DOCK_LIST_GET_F,
    DOCK_LIST_GET_R, DOCK_OWNER_LIST_GET, DOCK_OWNER_LIST_GET_F, DOCK_OWNER_LIST_GET_R, DOCK_OWNER_SELECTED_SET,
    DOCK_SELECTED_SET
  } from '../actionType'

const initialState = {
    docks: [],
    companies: [],
    owners: [],
    isLoading: false,
    error: null,
    totalPage: 0,
    totalElement: 0,
    selectedDock: null,
    selectedCompany: null,
    selectedOwner: null,
}
 
export default function dockReducer  (state= initialState, action:any){
    switch(action.type) {
        case DOCK_LIST_GET:
            return{
                ...state,
                isLoading: true,
                error: null
            }
        case DOCK_LIST_GET_F:
            var totalcount = 0
            if(action.payload.content === undefined || '')
            {
                totalcount = 0
            }
            else{
                action.payload.content.map((data:any) => {
                    totalcount = data.totalrow
                })
            }
            
            return{
                ...state,
                isLoading: false,
                docks: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: totalcount
            }
        case DOCK_LIST_GET_R:
            return{
                ...state,
                isLoading: false,
                error: action.payload
            }
        case DOCK_SELECTED_SET:
            return{
                ...state,
                selectedDock: action.payload
            }
        case DOCK_COMPANY_LIST_GET:
            return{
                ...state,
                isLoading: true,
                error: null
            }
        case DOCK_COMPANY_LIST_GET_F:
            var totalcmpcount = 0
           
            if(action.payload.content === undefined || '')
            {
                totalcmpcount = 0
            }
            else{
                action.payload.content.map((data:any) => {
                    totalcmpcount = data.totalrow
                })
            }
            return{
                ...state,
                isLoading: false,
                companies: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: totalcmpcount
            }
        case DOCK_COMPANY_LIST_GET_R:
            return{
                ...state,
                isLoading: false,
                error: action.payload
            }
        case DOCK_COMPANY_SELECTED_SET:
            return{
                ...state,
                selectedCompany: action.payload
            }
        case DOCK_OWNER_LIST_GET:
            return{
                ...state,
                isLoading: true,
                error: null
            }
        case DOCK_OWNER_LIST_GET_F:
            // console.log(action.payload,'in dockownerlist reducer')
            var totalowncount = 0
            if(action.payload.content === undefined || '')
            {
                totalowncount = 0
            }
            else{
                action.payload.content.map((data:any) => {
                    totalowncount = data.totalrow
                })
            }
            
            return{
                ...state,
                isLoading: false,
                owners: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: totalowncount
            }
        case DOCK_OWNER_LIST_GET_R:
            return{
                ...state,
                isLoading: false,
                error: action.payload
            }
        case DOCK_OWNER_SELECTED_SET:
            return{
                ...state,
                selectedOwner: action.payload
            }
        default:
            return state
    }
}