import { ofType } from 'redux-observable'
import {
  SHIP_CLS_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {
    shipClassificationListGetFAction,  
    shipClassificationListGetRAction

} from '../action/shipClassificationAction'
import shipClassificationService from '../../services/shipClassificationService'

export const getShipClassificationEpic = (action$ : any) => {
  return action$.pipe(
    ofType(SHIP_CLS_LIST_GET),
    switchMap((action : any) => {
      return shipClassificationService.getShipClassification(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in shipTypeEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return shipClassificationListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(shipClassificationListGetRAction(err)))
      )
    })
  )
}