import {
    CITY_LIST_GET,
    CITY_LIST_GET_F,
    CITY_LIST_GET_R,
    CITY_SELECTED_SET
}  from '../actionType'

export const cityListGetAction = ( params : any) => ({
    type: CITY_LIST_GET,
    payload: params
})
  
export const cityListGetFAction = ( data : any) => ({
    type: CITY_LIST_GET_F,
    payload: data
})
  
export const cityListGetRAction = ( error : any) => ({
    type: CITY_LIST_GET_R,
    payload: error
})
export const citySetSelectedAction = (city : any) => ({
    type: CITY_SELECTED_SET,
    payload: city
})