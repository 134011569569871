import {
    COUNTRY_LIST_GET,
    COUNTRY_LIST_GET_F,
    COUNTRY_LIST_GET_R,
    COUNTRY_SELECTED_SET
}  from '../actionType'

const initialState = {
    countries: [],
    isLoading: false,
    error: null,
    selectedCountries: null,
    totalPage: 0,
    totalElement: 0,
}

export default function countryReducer (state = initialState, action:any)  {
    switch(action.type) {
        case COUNTRY_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            } 
        case COUNTRY_LIST_GET_F:
            return {
                ...state,
                isLoading: false,
                countries: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
            }
        case COUNTRY_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case COUNTRY_SELECTED_SET:
            return {
                ...state,
                selectedCountries: action.payload
            }
        default:
            return state
    }
}