import React, {useState } from 'react'
import Modal from '../../../common/components/modal/Modal'
import { Text } from '../../../common/components/text/Text'
import { Input } from '../../../common/components/control/Control'
import { Button } from '../../../common/components/button/Button'
import { Flex } from '../../../common/components/flex/Flex'
import {Attributes} from '../../../common/components/attribute/Attribute'
import Icons from '../../../common/components/icon/Icon'
import { Space } from '../../../common/components/space/Space'
import classNames from 'classnames'
import { Form } from 'react-bootstrap'
import { JsxChild } from 'typescript'
import { history } from '../../../store'

export const ForgotPassword = (props: any) => {
    const { onClose } = props
    const [email, setEmail] = useState('')

    const _didSubmit = (e: any) => {
        history.push('/login')
    }

    return (
        <Modal
            title ='RECOVER YOUR PASSWORD'
            onClose={() => onClose()}
        >
            <ModalBody>
                <StyledForm onSubmit={(e: any) => _didSubmit(e)}
                >
                    <Attributes className ='forgotPassword_modal'>
                        <div>
                            <Text variant='subtitle'>Email ID</Text>
                            <Input
                                name="email"
                                value={email}
                                required
                                placeholder="Email ID"
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                            />
                        </div>
                    </Attributes>
                    <Space divheight={20} />
                    <Flex jc='jc-center'>
                        <Button
                            block
                            type="button"
                            onClick={() => onClose()}
                            variant='transparent'
                            className='bttn-model-discount'>
                                <Flex>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                        </Button>
                        <Button block type="submit" variant ='transparent' className='bttn-model-discount'>
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SUBMIT
                            </Flex>
                        </Button>
                    </Flex>
                </StyledForm>
            </ModalBody>
        </Modal>
    )
}

export interface ModalbodyProps {
    children?: React.ReactNode
}

export const ModalBody = ({ children, ...props }: ModalbodyProps) => {

    const modal = classNames('modalbody-container')

    return (
        <div className={modal}>
            {children}
        </div>
    )

}
export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-discount');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}
// export interface AttributeProps {
//     children?: React.ReactNode
// }
// export const Attributes = ({ children, ...props }: AttributeProps) => {
//     const attribute = classNames('attribute-container');

//     return (
//         <div {...props} className={attribute} >
//             {children}
//         </div>
//     )
// }
