import React from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import noimg from '../../../../assets/images/NoImg.jpg';
import { Flex } from '../../../../common/components/flex/Flex'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import { dockService } from '../../../../services/dockService'
import editicon from '../../../../assets/images/editone.png';
import removeicon from '../../../../assets/images/Remove.png'
import swal from 'sweetalert';
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { Label } from "../../../../common/components/label/Label";
import { dockCompanySetSelectedAction } from '../../../../store/action/dockActions';
import { history } from '../../../../store';
import { connect } from 'react-redux';
import { Col, Row, Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel'
import '../DryDockCompanies.scss'
import Loader from 'react-loader-spinner';
import { useRef } from 'react';
import { Console } from 'console';
import { FilterState } from '../../MutiImageState/MultipleImageFilterState'

interface ViewProps {
    dock: any[]
}
//export type CarouselVariant = 'dark';
const DockCompanyDetail = (props: any) => {
    const { company, match } = props
    const [isLoading, setLoading] = React.useState(true)
    const [form, setForm] = React.useState<ViewProps>({
        dock: []
    })
    const role = localStorage.getItem("role")
    //const [isDeleting, setDelete] = React.useState(false)
    var data: any
    var length: any
    const upload = useRef(null);
    var base64: string | any
    const { pgnum } = match.params
    const [bvalue, setBValue] = React.useState<any>({
        string_image: ''
    })

    var example = ["https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1711.jpg",
        "https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1749.jpg",
        "https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1369.jpg"]


    var certify = ["https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1711.jpg",
        "https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1749.jpg",
        "https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1369.jpg"]
    const getBase64 = (event: any) => {
        var pic: any[] = []
        //        pic.map((items) => {)
        //  localStorage.removeItem("image")
        for (let i = 0; i < event.target.files.length; i++) {
            let file = event.target.files[i];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            console.log(event.target.files[i])
            reader.onload = function () {
                base64 = reader.result
                //   alert("Hi")
                //   console.log("base 64", base64)
                if (i == 0) {
                    //    localStorage.setItem("image", JSON.stringify(base64))
                    //                    FilterState.imageFilter.MultipleImage = base64
                    FilterState.imageFilter.MultipleImages.push(base64)
                    //    console.log(FilterState.imageFilter.MultipleImage)
                    //    console.log(FilterState.imageFilter.MultipleImages)
                } else {
                    //                   FilterState.imageFilter.MultipleImage = FilterState.imageFilter.MultipleImage + base64
                    //    localStorage.setItem("image", localStorage.getItem("image") + "&&" + base64)
                    FilterState.imageFilter.MultipleImages.push(base64)
                    //    console.log(FilterState.imageFilter.MultipleImage)
                    //   console.log(FilterState.imageFilter.MultipleImages)
                }
                if (i + 1 == event.target.files.length) {
                    ImageUserAPI(FilterState.imageFilter.MultipleImages);
                }
            }
        }
        //        var newimage = localStorage.getItem("image")
        //       alert(newimage)
        //     console.log("new", newimage)

    }
    const _removeDockCompanyLogo = (id: any) => {
        return dockService.removeDockCompanyLogo(id).subscribe({
            next: (r) => {
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
                // history.push(`/ships/${id}/`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
            }
        })
    }

    const ImageUserAPI = (imageurl: any) => {
        const { id } = match.params
        const { pgnum } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        dockService.updateDockCompanyImage(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                // setLoading(false)
                window.location.reload()
                //                history.push(`/comapanies/company-type/${pgnum}`)
                //                history.push(`/dock-companies/${company.companyid}/${pgnum}`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }

    React.useEffect(() => {
        const { id } = match.params
        _getCompanyDetail(id)
        _getdockDetail(id)
    }, [])

    const _getCompanyDetail = (id: any) => {
        setLoading(true)
        return dockService.getDockCompanyDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.dockCompanySetSelectedAction(data)
                setLoading(false)
            },
            error: err => {
                console.error(err)
            }
        })
    }

    const _goback = () => {
        const { key } = match.params
        console.log('go back called')
        console.log(pgnum, 'page')
                // dockCompanyListGetAction({ page:pgnum, size: 10,
        //     companyname: FilterState.cmpFilter.cmp || key, 
        //     city: FilterState.cmpFilter.city, 
        //     country: FilterState.cmpFilter.country,
        //     fromDate:String(FilterState.cmpFilter.fromDate),
        //     toDate: String(FilterState.cmpFilter.toDate), 
        //     status: FilterState.cmpFilter.status, 
        //     claimedstatus:FilterState.cmpFilter.claimedStatus,
        // })
        history.push(`/comapanies/company-type/${pgnum}`)
    }

    const _getdockDetail = ((id: string) => {
        return dockService.getDockViewDetail(Number(id)).subscribe({
            next: r => {
                setForm({ ...form, dock: r.data })
                r.data.map((data: any, i: any) => {
                    return form.dock.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }
    length = 0
    if (company.company_logos !== null) {
        data = [company.company_logos.map((data: any, i: any) => {
            length = length + 1
            return String(data.name)

        })];
        console.log("Check", data);
    } else {
        data = null;
    }
    console.log(length)
    if (company.company_logos !== null) {
        data.map((value: any) => (
            example = value
            //       console.log("inside carosel",value)
        ))
    }

    // if (company.certificate !== null) {
    //     data = [company.certificate.map((data: any, i: any) => {
    //         length = length + 1
    //         return String(data.name)

    //     })];
    //     console.log("Check", data);
    // } else {
    //     data = null;
    // }
    // console.log(length)
    // if (company.certificate !== null) {
    //     data.map((value: any) => (
    //         certify = value
    //         //       console.log("inside carosel",value)
    //     ))
    // }

    return (
        <Main>
            {company === null && (
                <div id={`cmpnull${company.id}`}>
                    <Space divheight={15} />
                    <Text color='main' variant='subtitle' center bold> There is No data for Requested id</Text>
                    <Space divheight={15} />
                </div>
            )}
            {company !== null && (
                <div id={`cmpnotnull${company.id}`}>
                    {console.log(company, 'company detail')}
                    <div className='title-name-block'>
                        <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                            {company.company_logo === null && (
                                <Image src={noimg} alt='logo' roundedCircle />
                            )}
                            {company.company_logo === '' && (
                                <Image src={noimg} alt='logo' roundedCircle />
                            )}
                            {company.company_logo !== null && company.company_logo !== '' && (
                                <Image src={company.company_logo} alt='logo' roundedCircle />
                            )}
                        </div>
                        {role === 'VISITOR' && (
                            <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                                <label className="custom-file-upload">
                                    <Image src={editicon} alt='logo' />
                                    <input
                                        ref={upload}
                                        type="file"
                                        onChange={getBase64}
                                    />
                                </label>
                            </div>
                        )}
                        <Text variant='title' color='main' className='txt-capitalize'>
                            {/* {String(company.email).substring(0,String(company.email).indexOf('@'))} */}
                            {company.name}
                        </Text>
                        <Label className={company.status === 'UNVERIFIED' ? 'danger' : 'warning'} >
                            {company.status === 'UNVERIFIED' ? 'Not Verified' : 'Verified'}
                        </Label>
                    </div>
                    <Space divheight={15} />
                    <Row className='m-0 p-0'>
                        <Col sm='12' md='12' lg='8' xl='8' className='m-0 p-0 align-top'>
                            <Card title="DryDock Company Detail">
                                <Row className='m-0 p-0'>
                                    <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='singleFrame'>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Name</Text>
                                                <Text color='main' variant='subtitle' bold>{company.name}</Text>
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Email</Text>
                                                {company.email === null ? (
                                                    <Text color='main' variant='subtitle' className='txt-email' bold> - </Text>
                                                ) : (
                                                        <Text color='main' variant='subtitle' className='txt-email' bold>{company.email}</Text>
                                                    )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Website</Text>
                                                {company.website === null ? (
                                                    <Text color='main' variant='subtitle' className='txt-email' bold> - </Text>
                                                ) : (
                                                        <Text color='main' variant='subtitle' bold>{company.website}</Text>
                                                    )}
                                            </div>
                                            {/* <div>
                                                <Text color='main' variant='subtitle'>Company Contact Number</Text>
                                                <Text color='main' variant='subtitle' bold>{company.phone}</Text>
                                            </div> */}
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Company Contact Number</Text>
                                                {company.phone_code === '' || null ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                        <>
                                                            {company.phone === '' || null ? (
                                                                <Text color='main' bold variant='subtitle'>{company.phone_code}
                                                                    <span>
                                                                        <Text color='main' bold variant='subtitle'>{company.phone}</Text>
                                                                    </span>
                                                                </Text>
                                                            ) : (
                                                                    <>
                                                                        <Text color='main' bold variant='subtitle'>{company.phone_code} &nbsp;
                                                                    <span>
                                                                                <Text color='main' bold variant='subtitle'>{company.phone}</Text>
                                                                            </span>
                                                                        </Text>
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Address</Text>
                                                {company.address === null ? (
                                                    <Text color='main' variant='subtitle' className='txt-email' bold> - </Text>
                                                ) : (
                                                        <Text color='main' variant='subtitle' bold>{company.address}</Text>
                                                    )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Description</Text>
                                                {company.company_description === null ? (
                                                    <Text color='main' variant='subtitle' className='txt-email' bold> - </Text>
                                                ) : (
                                                        <Text color='main' variant='subtitle' bold>{company.company_description}</Text>
                                                    )}
                                            </div>
                                        </Attributes>
                                    </Col>
                                    <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='singleFrame'>
                                            <div>
                                                <Text color='main' variant='subtitle'>Country</Text>
                                                {company.companyCity === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                        company.companyCity.country === null ? (
                                                            <Text color='main' variant='subtitle' bold>
                                                                -
                                                    </Text>
                                                        ) : (
                                                                company.companyCity.country.name === null ? (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        -
                                                        </Text>
                                                                ) : (
                                                                        <Text color='main' variant='subtitle' bold>
                                                                            {company.companyCity.country.name}
                                                                        </Text>
                                                                    )
                                                            )
                                                    )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>City</Text>
                                                {company.companyCity === null ? (
                                                    <Text color='main' variant='subtitle' bold>
                                                        -
                                                    </Text>
                                                ) : (
                                                        company.companyCity.name === null ? (
                                                            <Text color='main' variant='subtitle' bold>
                                                                -
                                                    </Text>
                                                        ) : (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    {company.companyCity.name}
                                                                </Text>
                                                            )
                                                    )}
                                            </div>
                                            {company.user === null ? (
                                                
                                                <Text color='danger' variant='subtitle' className="no_user" bold>
                                                    No user is mapped to this company
                                                </Text>
                                            ) : (
                                                    
                                                <>
                                                <div>
                                                    <Text color='main' variant='subtitle'>CEO</Text>
                                                    {company.user === null ? (
                                                        <Text color='main' variant='subtitle' bold>
                                                            -
                                                    </Text>
                                                    ) : (
                                                            company.user.ceo === null ? (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    -
                                                    </Text>
                                                            ) : (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        {company.user.ceo}
                                                                    </Text>
                                                                )
                                                        )}
                                                </div>
                                                <div>
                                                    <Text color='main' variant='subtitle'>Chairman</Text>
                                                    {company.user === null ? (
                                                        <Text color='main' variant='subtitle' bold>
                                                            -
                                                    </Text>
                                                    ) : (
                                                            company.user.chairman === null ? (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    -
                                                    </Text>
                                                            ) : (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        {company.user.chairman}
                                                                    </Text>
                                                                )
                                                        )}
                                                </div>
                                                <div>
                                                    <Text color='main' variant='subtitle'>Director</Text>
                                                    {company.user === null ? (
                                                        <Text color='main' variant='subtitle' bold>
                                                            -
                                                    </Text>
                                                    ) : (
                                                            company.user.directors === null ? (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    -
                                                    </Text>
                                                            ) : (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        {company.user.directors}
                                                                    </Text>
                                                                )
                                                        )}
                                                </div>
                                                <div>
                                                    <Text color='main' variant='subtitle'>Year of Establishment</Text>
                                                    {company.user === null ? (
                                                        <Text color='main' variant='subtitle' bold>
                                                            -
                                                    </Text>
                                                    ) : (
                                                            company.user.year_of_establishment === null ? (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    -
                                                    </Text>
                                                            ) : (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        {company.user.year_of_establishment}
                                                                    </Text>
                                                                )
                                                        )}
                                                </div>
                                                <div>
                                                    <Text color='main' variant='subtitle'>Services</Text>
                                                    {company.user === null ? (
                                                        <Text color='main' variant='subtitle' bold>
                                                            -
                                                    </Text>
                                                    ) : (
                                                            company.user.services === null ? (
                                                                <Text color='main' variant='subtitle' bold>
                                                                    -
                                                    </Text>
                                                            ) : (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        {company.user.services}
                                                                    </Text>
                                                                )
                                                        )}

                                                </div>
                                            </>
                                                )}

                                            {/* <div>
                                                <Text color='main' variant='subtitle'>Company Contact Number</Text>
                                                <Text color='main' variant='subtitle' bold>{company.phone}</Text>
                                            </div> */}

                                        </Attributes>
                                    </Col>
                                    {/* <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='view_Page'>
                                            
                                        </Attributes>
                                    </Col> */}
                                </Row>
                                <Row>
                                    <Col sm='12'>
                                        <Card title="Dock Detail">
                                            <div className='dock-add'>
                                                {role === 'VISITOR' ? (
                                                    <Link to={`/dock-companies/dockadd/${company.id}`} >
                                                        <Button variant='transparent' disabled>
                                                            <Flex>
                                                                <Icons color="#ffffff" icon="add" />
                                                                ADD DOCK
                                                            </Flex>
                                                        </Button>
                                                    </Link>
                                                ) : (
                                                        <Link to={`/dock-companies/dockadd/${company.id}`} >
                                                            <Button variant='transparent'>
                                                                <Flex>
                                                                    <Icons color="#ffffff" icon="add" />
                                                                    ADD DOCK
                                                            </Flex>
                                                            </Button>
                                                        </Link>
                                                    )}
                                            </div>
                                            {form.dock.length === 0 && (
                                                <>
                                                    <Space divheight={20} />
                                                    <Text variant='subtitle' center color="main" bold>No Dock Associated with this company</Text>
                                                    <Space divheight={20} />
                                                </>
                                            )}
                                            <Row className='m-0 p-0'>
                                                <Col sm='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                    {form.dock.map((dock: any, i: any) => [
                                                        <Attributes id={i} className='view_Page d-block d-md-block d-sm-block d-lg-inline-block d-xl-inline-block w-50'>
                                                            <div id={`name_${i}`}>
                                                                <Text id={`docktitle_${i}`} color='main' variant='subtitle'>Dock Name</Text>
                                                                <Text id={`dockvalue_${i}`} variant='subtitle' color="main" bold>{dock.name}</Text>
                                                            </div>
                                                            <div id={`city_${i}`}>
                                                                <Text id={`citytitle_${i}`} color='main' variant='subtitle'>City and Country </Text>
                                                                <Text id={`cityvalue_${i}`} color="main" variant='subtitle' bold> {dock.port.city.name} ({dock.port.city.country.name}) </Text>
                                                            </div>
                                                        </Attributes>
                                                    ])}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col sm='12' md='12' lg='4' xl='4' className='p-0 m-0'>
                            <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                                <Card title="Company Logo">
                                    {/* <div className='user-mainBlock'>
                                        {company.company_logo === null && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {company.company_logo === '' && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {company.company_logo !== null && company.company_logo !== '' && (
                                            <div className='userCompany-block'>
                                                <img src={company.company_logo} alt='logo' />
                                            </div>
                                        )}
                                    </div> */}
                                    <div className='user-mainBlock'>
                                        {company.company_logo === null && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {company.company_logo === '' && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {company.company_logo !== null && company.company_logo !== '' && length === 1 && (
                                            <div className='userCompany-block'>
                                                <img src={company.company_logo} alt='logo' />
                                            </div>
                                        )}
                                        {company.company_logo !== null && company.company_logo !== '' && length > 1 && (
                                            <Carousel slide={true} touch={true}>
                                                {example.map((value: any) => (
                                                    <Carousel.Item interval={5000}>
                                                        <div className='userCompany-block'>
                                                            <img
                                                                className="d-block w-100 slide"
                                                                src={value}
                                                                alt="First slide"
                                                            />
                                                        </div>
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        )}
                                    </div>
                                    {/* <div >
                                    <Carousel>
                                        {example.map((value) => (
                                            <Carousel.Item>
                                                <div className='userCompany-block'>
                                                    <img
                                                        className="d-block w-100"
                                                        src={value}
                                                        alt="First slide"
                                                    />
                                                </div>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                    </div> */}

                                    {((company.company_logo === null) || (company.company_logo === '')) && role === 'VISITOR' && (
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    <Image src={editicon} alt='logo' />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    multiple accept="image/*"
                                                    onChange={getBase64}
                                                />
                                            </label>
                                        </div>
                                    )}

                                    {company.company_logo !== null && company.company_logo !== '' && role === 'VISITOR' && (
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    <Image src={editicon} alt='logo' className="editIconRemove" />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    multiple accept="image/*"
                                                    onChange={getBase64}
                                                />
                                            </label>

                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    {/* <Icons color="#ffffff" icon="edit" /> */}
                                                    <Image src={removeicon} alt='logo' onClick={() => _removeDockCompanyLogo(company.id)} />
                                                </Flex>
                                            </label>
                                        </div>
                                    )}
                                </Card>
                                <Card title="Certificattions">
                                    {/* <div className='user-mainBlock'>
                                        {company.company_logo === null && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {company.company_logo === '' && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {company.company_logo !== null && company.company_logo !== '' && (
                                            <div className='userCompany-block'>
                                                <img src={company.company_logo} alt='logo' />
                                            </div>
                                        )}
                                    </div> */}
                                    <div className='user-mainBlock'>
                                        {company.certifications === null && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {company.certifications === '' && (
                                            <div className='userCompany-block'>
                                                <img src={noimg} alt='logo' />
                                            </div>
                                        )}
                                        {company.certifications !== null && company.certifications !== '' && length === 1 && (
                                            <div className='userCompany-block'>
                                                <img src={company.Certificattions} alt='logo' />
                                            </div>
                                        )}
                                        {company.certifications !== null && company.certifications !== '' && length > 1 && (
                                            <Carousel slide={true} touch={true}>
                                                {certify.map((value: any) => (
                                                    <Carousel.Item interval={5000}>
                                                        <div className='userCompany-block'>
                                                            <img
                                                                className="d-block w-100 slide"
                                                                src={value}
                                                                alt="First slide"
                                                            />
                                                        </div>
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        )}
                                    </div>
                                    {/* <div >
                                    <Carousel>
                                        {example.map((value) => (
                                            <Carousel.Item>
                                                <div className='userCompany-block'>
                                                    <img
                                                        className="d-block w-100"
                                                        src={value}
                                                        alt="First slide"
                                                    />
                                                </div>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                    </div> */}

                                    {((company.company_logo === null) || (company.company_logo === '')) && role === 'VISITOR' && (
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    <Image src={editicon} alt='logo' />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    multiple accept="image/*"
                                                    onChange={getBase64}
                                                />
                                            </label>
                                        </div>
                                    )}

                                    {company.company_logo !== null && company.company_logo !== '' && role === 'VISITOR' && (
                                        <div className='imageEditBlock'>
                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    <Image src={editicon} alt='logo' className="editIconRemove" />
                                                </Flex>
                                                <input
                                                    ref={upload}
                                                    type="file"
                                                    multiple accept="image/*"
                                                    onChange={getBase64}
                                                />
                                            </label>

                                            {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                            <label className="custom-file-upload">
                                                <Flex>
                                                    {/* <Icons color="#ffffff" icon="edit" /> */}
                                                    <Image src={removeicon} alt='logo' onClick={() => _removeDockCompanyLogo(company.id)} />
                                                </Flex>
                                            </label>
                                        </div>
                                    )}
                                </Card>
                            </div>
                            <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                                <Space divheight={15} />
                            </div>
                            <div className='ml-xl-3  mt-sm-4 mt-md-4 mt-lg-4 mt-xl-0 view_page_xs_margin'  >
                                <Card title="Owner Detail">
                                    <Row className='p-o m-0'>
                                        <Col sm='12' className='p-0 m-0'>
                                            {company.user === null &&
                                                <div id={'cmpusernull'}>
                                                    <Space divheight={20} />
                                                    <Text variant='subtitle' center color="main" bold>No Owner Associated with this Company</Text>
                                                    <Space divheight={20} />
                                                </div>
                                            }
                                            {company.user !== null &&
                                                <Attributes id={company.user.id} className='view_Page'>
                                                    <div>
                                                        <Text color='main' variant='subtitle'> Owner</Text>
                                                        <Text color='main' variant='subtitle' bold> {company.user.name} </Text>
                                                    </div>
                                                    <div>
                                                        <Text color='main' variant='subtitle'> Status </Text>
                                                        {company.user.status === 'VERIFIED' && (
                                                            <Text color='main' variant='subtitle' bold> Verified </Text>
                                                        )}
                                                        {company.user.status === 'UNVERIFIED' && (
                                                            <Text color='main' variant='subtitle' bold>
                                                                Not Verified
                                                            </Text>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <Text color='main' variant='subtitle'>Verified by</Text>
                                                        <Text color='main' variant='subtitle' bold>Admin </Text>
                                                    </div>
                                                </Attributes>
                                            }
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='12' md='12' lg='12' xl='8'>
                            <Actions>
                                {/* <Link to={`/comapanies/company-type`}>
                                    <Button type="button" variant='transparent'>
                                        <Flex jc='jc-around'>
                                            <Icons icon="arrow_back" />
                                            BACK
                                        </Flex>
                                    </Button>
                                </Link> */}
                                <Button type="button" className='bttn bttn-transparent' onClick={() => _goback()}>
                                    <Flex jc='jc-around'>
                                        <Icons icon="arrow_back" />
                                        BACK
                                    </Flex>
                                </Button>
                                <div>
                                    {role === 'VISITOR' ? (
                                        //                                    <Link to={`/dock-companies/edit/${company.id}`} >
                                        <Button variant='transparent' disabled>
                                            <Flex>
                                                <Icons color="#ffffff" icon="edit" />
                                                EDIT DATA
                                            </Flex>
                                        </Button>
                                        //                                    </Link>
                                    ) : (
                                            <Link to={`/dock-companies/edit/${company.id}/${pgnum}`} >
                                                <Button variant='transparent'>
                                                    <Flex>
                                                        <Icons color="#ffffff" icon="edit" />
                                                        EDIT DATA
                                                </Flex>
                                                </Button>
                                            </Link>
                                        )}
                                </div>
                            </Actions>
                        </Col>
                    </Row>
                </div>
            )}
        </Main>
    )

}

export interface CompanyProps {
    children?: React.ReactNode
    className?: string;
}
export const Company = ({ children, className, ...props }: CompanyProps) => {
    const attribute = classNames('company-container', className);

    return (
        <div {...props} className={attribute} >
            {children}
        </div>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

export interface LinkProps {
    children?: React.ReactNode
    color?: string
}
const CompanyLink = ({ color, ...props }: LinkProps) => {
    const actions = classNames('company-link-container', color);
    return (
        <div className={actions}>
            {props.children}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    company: state.dock.selectedCompany
})

export default connect(
    mapStateToProps,
    {
        dockCompanySetSelectedAction
    }
)(DockCompanyDetail)


// <div>
//                                                     <Text color='main' variant='subtitle'>Verified Drydocks</Text>
//                                                     <Text color='main' variant='subtitle'> {company.verified_docks && company.verified_docks.length},{' '}</Text>
//                                                     {company.verified_docks && company.verified_docks.length > 0 &&
//                                                         company.verified_docks.map((dock: any) => (
//                                                             <Link key={dock.id} to={`/docks/${dock.id}`}>
//                                                                 <CompanyLink color="warning">
//                                                                     {dock.name}
//                                                                 </CompanyLink>
//                                                             </Link>
//                                                         ))}
//                                                 </div>
//                                                 <div>
//                                                     <Text color='main' variant='subtitle'>Un Verified Drydocks</Text>
//                                                     <Text color='main' variant='subtitle'> {company.unverified_docks && company.unverified_docks.length},{' '}</Text>
//                                                     {company.unverified_docks && company.unverified_docks.length > 0 &&
//                                                         company.unverified_docks.map((dock: any) => (
//                                                             <Link key={dock.id} to={`/docks/${dock.id}`}>
//                                                                 <CompanyLink color="danger">
//                                                                     {dock.name}
//                                                                 </CompanyLink>
//                                                             </Link>
//                                                         ))}
//                                                 </div>