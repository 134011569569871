import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import accountReducer from './accountReducer'
import dockReducer from './dockReducer'
import shipReducer from './shipReducer'
import userReducer from './userReducer'
import systemUserReducer from './systemUserReducer'
import shipTypeReducer from './ShipTypeReducer'
import roleReducer from './roleReducer'
import countryReducer from './countryReducer'
import shipClassificationReducer from './shipClassificationReducer'
import dockTypeReducer from './dockTypeReducer'
import ShipMaterialReducer from './materialReducer'
import AnodeReducer from './anodeReducer'
import cityReducer from './cityReducer'
import cmpTypeReducer from './cmpTypeReducer'
import PortReducer from './portReducer'
import ThicknessReducer from './thicknessReducer'
import UnitsReducer from './unitReducer'
import paintReducer from './paintReducer'
import provincesReducer from './provincesReducer'
import subscriptionReducer from './subscriptionReducer'
import manufacturerReducer from './manufacturerReducer'
import productTypeReducer from './productTypeReducer'
import slotReducer from './slotReducer'
import adsReducer from './adReducer'
import shipSubTypeReducer from './shipSubTypeReducer'
import dataShipReducer from './dataShipReducer'
import dataShipClassReducer from './dataShipClassReducer'
import reportReducer from './reportReducer'
import dataShipEngReducer from './dataEngReducer'
import propulsionReducer from './propulsionReducer'
import dataShipDemolitionReducer from './dataDemoReducer'

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    anode: AnodeReducer,
    material: ShipMaterialReducer,
    ship: shipReducer,
    dock: dockReducer,
    user: userReducer,
    account: accountReducer,
    shipType: shipTypeReducer,
    dockType: dockTypeReducer,
    roles: roleReducer,
    countries: countryReducer,
    classification: shipClassificationReducer,
    cities: cityReducer,
    cmpTypes: cmpTypeReducer,
    port: PortReducer,
    thickness: ThicknessReducer,
    units: UnitsReducer,
    provinces: provincesReducer,
    paint: paintReducer,
    subscription: subscriptionReducer,
    manufacturer: manufacturerReducer,
    productType: productTypeReducer,
    slot: slotReducer,
    ads: adsReducer,
    subType: shipSubTypeReducer,
    systemUser: systemUserReducer,
    datalasticShipCmp: dataShipReducer,
    report:reportReducer,
    datalasticShipEng: dataShipEngReducer,
    propulsions: propulsionReducer,
    dataship:dataShipClassReducer,
    demoShip:dataShipDemolitionReducer
  })


