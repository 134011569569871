import React, { useRef, useState } from 'react';
import { Main } from '../../../../common/components/main/Main'
import {Col, Form, Row,Button} from 'react-bootstrap'
// import {Button} from '../../../../common/components/button/Button'
import {Text} from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import {Grid} from '../../../../common/components/grid/Grid'
import {Flex} from '../../../../common/components/flex/Flex'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import {Input, Select, Textarea} from '../../../../common/components/control/Control'
import {Space} from '../../../../common/components/space/Space'
import {Attributes} from '../../../../common/components/attribute/Attribute'
// import codeService from '../../../services/codeService'
import {dockService} from '../../../../services/dockService'
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { JsxChild } from 'typescript'
import codeService from '../../../../services/codeService'
import { history } from '../../../../store';
import swal from 'sweetalert'
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';


interface companyAddProps {
    name:string;
    company_phone:string;
    contact_name:string;
    address:string;
    ownerName:any[];
    cities:any[];
    mblCode:any[];
    city:string;
    email:string;
    phone:string;
    website:string;
    status:string;
    mobileCode:string;
    company_description:string;
}

interface FormData{
    txt_dockcmpName:string;
    txt_dockcmpPhn:string;
    txt_dockcmpAddress:string;
    txt_dockcmpcity:string;
    txt_doccmpemail:string;
    txt_dockcmpwebstite:string;
    txt_dockcmpstatus:string;
    txt_dockcompanydescription:string;
}

interface bvalueProps{
    string_image : string | ArrayBuffer | null
}

export const DockCompanyAdd = (props:any) => {
    //const [isLoading, _setLoading] = React.useState(false)
    const [isSubmitting, setSubmitting] = React.useState(false)
    const { register, handleSubmit, errors } = useForm<FormData>(
        {
            mode: 'onSubmit',
            reValidateMode: 'onBlur',
          }
    );

    const {match} = props
    const {type} = match.params
    const {userid} = match.params
    const [dockcmpaddflag, setDockCmpAddFlag] = React.useState(false)
    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<bvalueProps>({
      string_image : ''
    })
    const getBase64 = (event:any) => {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        // console.log(reader.result);
        base64 = reader.result
        setBValue(
          {
            string_image: reader.result
          })
        // console.log(base64,'base64')
        // console.log(bvalue.string_image,'base 64 value')
        
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };
    };
    
    // var dockcmpaddflag = false 
    const [company, setCompany] = useState<companyAddProps> ({
        name: '',
        company_phone: '',
        contact_name: '',
        address: '',
        email: '',
        ownerName:[],
        cities:[],
        mblCode:[],
        city:'',
        phone: '',
        website: '',
        status: '',
        mobileCode:'',
        company_description:'',
    })

    const _getOwnerName = (() => {
        return codeService.getOwners({ type:'DOCK'}).subscribe({
          next: r => {
            // console.log(r.data,'owners in Dock add page')
            setCompany({ ...company, ownerName: r.data })
            r.data.map((data: any, i: any) => {
              return company.ownerName.push(r.data)
            })
          },
          error: err => {
            console.error(err)
          }
        })
      })
    const _getMobileCode = (() => {
        return codeService.getMobileCode().subscribe({
            next: r => {
                // console.log(r.data, "Role")
                setCompany({ ...company, mblCode: r.data })
                // console.log(company.mblCode, 'mblCode')
                r.data.map((data: any, i: any) => {
                    return company.mblCode.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    const _getCity = (() => {
        return codeService.getCities().subscribe({
          next: r => {
            // console.log(r.data,'owners in Dock add page')
            setCompany({ ...company, cities: r.data })
            r.data.map((data: any, i: any) => {
              return company.cities.push(r.data)
            })
          },
          error: err => {
            console.error(err)
          }
        })
      })
    React.useEffect(() => {
        // _getOwnerName()
        _getCity()
        _getMobileCode()
    }, []) 

    const _goback = () => {
        if(type){
            // console.log(userid,'user id')
            history.push(`/mapCompany/${userid}/${type}`)
        }
        else{
            history.push('/comapanies/company-type')
        }
    }

    const _didDockCmpAdd = () => {
        // e.preventDefault()
        // console.log(bvalue.string_image,'base 64')
        var phonecmp : any;
        if(company.phone === null||company.phone===''||company.phone===' '||company.mobileCode==null||company.mobileCode==''){
                        phonecmp = '';
        }
        else{
            phonecmp =  String(company.mobileCode).concat(" ",company.phone);
        }
        const updatedForm = {
            name:company.name,
            status:company.status,
            email:company.email,
            website:company.website,
            phone:phonecmp,
            // phone:company.phone,
            address:company.address,
            type:'2',
            // ownerUser:company.contact_name,
            ownerUser:'',
            city:company.city,
            image:bvalue.string_image,
            company_description:company.company_description
        }
        // console.log(updatedForm,"updatedform")
        if (dockcmpaddflag === true) {
            setSubmitting(true)

            dockService.addDockCompany(updatedForm).subscribe({
                next: (r) => {
                    var addcmpid = r.data.name
                    swal("Great", "Dock Company added Successfully", "success")
                    if (type) {
                        history.push(`/mapCompany/${userid}/${type}/${addcmpid}`)
                    }
                    else {
                        history.push('/comapanies/company-type')
                    }
                },
                error: err => {
                    setSubmitting(false)
                    swal("OOPS", String(err), "error")
                }
            })
        }
        else{
            console.log(dockcmpaddflag)
        }
    }
    
    onsubmit = (e: any) =>{
        // e.preventDefault()
        // console.log(Object.keys(errors).length , 'error length')
        if(Object.keys(errors).length === 0){
            _didDockCmpAdd()
            setDockCmpAddFlag(true)
        }
        else{
            setDockCmpAddFlag(false)
        }
    }

    if (isSubmitting) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
      }

    return (
        <Main>
            <Text variant="title"  color='black'>Add Dock Company Information</Text>
            <Space divheight={25} />
            <Grid templateCols='75% auto' className='add_page'>
            <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='dockcmpAddForms'>
                    <Card title="Fill All the Information Below">
                        <Row className='m-0 p-0'>
                            <Col xs= '12' md = '12' lg ='6' xl ='6' className='p-0 m-0'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Company Name</Text>
                                        <Form.Control className='basecontrol custome-input' 
                                        type="text"
                                        name="txt_dockcmpName"
                                        value={company.name}
                                        onChange={(e)=> setCompany({...company, name: e.target.value})}
                                        ref={register({ required: true, })}
                                        placeholder='Company Name'  
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                        <span className='err-msg text-capitalize'>{errors.txt_dockcmpName?.type === 'required' && 'Please enter Company name'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Company Email</Text>
                                        <Form.Control className='basecontrol custome-input' 
                                        type="email"
                                        name="txt_doccmpemail"
                                        value={company.email}
                                        onChange={(e) => setCompany({...company, email: e.target.value})}
                                        ref={register({ required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/  })}
                                        placeholder='Company email'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                        <span className='err-msg text-capitalize'>{errors.txt_doccmpemail?.type === 'required' && 'Please enter Company Email'}
                                        {errors.txt_doccmpemail?.type === 'pattern' && 'Please enter Valid Email'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Company Website </Text>
                                        <Form.Control className='basecontrol custome-input' 
                                        type="text"
                                        name="txt_dockcmpwebstite"
                                        value={company.website}
                                        onChange={(e) => setCompany({...company, website: e.target.value})}
                                        ref={register({required:true ,pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/})}                                        placeholder='Company Website'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>
                                                {errors.txt_dockcmpwebstite?.type === 'pattern' && 'Please enter valid Company Website'}
                                                {errors.txt_dockcmpwebstite?.type === 'required' && 'Please enter Company Website'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Company Description</Text>
                                        <Form.Control as="textarea" 
                                        className='basecontrol custome-textarea'
                                        rows={3}
                                        placeholder='Company Description'
                                        cols={50}
                                        name="txt_dockcompanydescription"
                                        value={company.company_description}
                                        onChange={(e) => setCompany({...company, company_description: e.target.value})}
                                        />
                                    </div>
                                </Attributes>
                                
                            </Col>
                            <Col xs= '12' md = '12' lg ='6' xl ='6' className='p-0 m-0'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Company Contact Number</Text>
                                        <div className='phnnum-container'>
                                            <Form.Control as="select" value={company.mobileCode} 
                                                name='txt_addCmpPhnCode'
                                                className='basecontrol cutome-selectInputnxt phn-dp'
                                                onChange={(e) => setCompany({ ...company, mobileCode: e.target.value })}
                                            >
                                                <option value="">Code</option>
                                                {company.mblCode.map((data: any, i: any) => {
                                                    return <option key={i} value={data.mobile_code}>{data.country_name}</option>
                                                })}
                                            </Form.Control>
                                            <Form.Control className='basecontrol custome-input' 
                                                type="text"
                                                name='txt_dockcmpPhn'
                                                value={company.phone}
                                                onChange={(e) => setCompany({...company, phone: e.target.value})}
//                                                ref={register({ required: true})}
                                                placeholder='Company Contact Number'
                                            />
                                        </div>
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                        <span className='err-msg text-capitalize'>
                                            {errors.txt_dockcmpPhn?.type === 'required' && 'Please enter Company Contact Number'}
                                            {/* {errors.txt_dockcmpPhn?.type === 'pattern' && 'Please enter valid Company Contact Nmber'} */}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Company Address</Text>
                                        <Form.Control as="textarea" 
                                        className='basecontrol custome-textarea'
                                        rows={3}
                                        placeholder='Company Address'
                                        cols={50}
                                        name="txt_dockcmpAddress"
                                        value={company.address}
                                        onChange={(e) => setCompany({...company, address: e.target.value})}
                           //             ref={register({ required: true, })}
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                        <span className='err-msg text-capitalize'>{errors.txt_dockcmpAddress?.type === 'required' && 'Please enter Company Address'}
                                            </span>
                                        </Col>
                                    </Row>
                                    {/* <div>
                                        <Text color='tablehead' variant='subtitle'>Owner Name</Text>
                                        <Select values={company.contact_name} name='Company' onChange={e => setCompany({ ...company, contact_name: e.target.value })} required>
                                            <option value="">-- Select --</option>
                                            Map the data's from company array and used as a option value. By Dharani on 04-Mar-2021
                                            {company.ownerName.map((data, i) => {
                                            return <option key={i} value={data.id}>{data.name}</option>
                                            })}
                                        </Select>
                                    </div> */}
                                </Attributes>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='12' md='8' xl='6' lg='6'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Status</Text>
                                        <Form.Control as="select" className='basecontrol cutome-selectInputnxt' 
                                        value={company.status} name="txt_dockcmpstatus"
                                         onChange={(e) => setCompany({...company, status:e.target.value})}
                                         ref={register({ required: true, })}>
                                            <option value="">-- select --</option>
                                            <option value="VERIFIED"> Verified</option>
                                            <option value="UNVERIFIED"> Not verified</option>
                                        </Form.Control>
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                        <span className='err-msg text-capitalize'>{errors.txt_dockcmpstatus?.type === 'required' && 'Please Select Status'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>City Name</Text>
                                        <Form.Control as="select" className='basecontrol cutome-selectInputnxt' 
                                        value={company.city} name='txt_dockcmpcity'
                                         onChange={e => setCompany({ ...company, city: e.target.value })}
                                          ref={register({ required: true, })}>
                                            <option value="">-- Select --</option>
                                             Map the data's from company array and used as a option value. By Dharani on 04-Mar-2021 
                                             {company.cities.map((data, i) => {
                                             return <option key={i} value={data.id}>{data.name}</option> 
                                             })} 
                                         </Form.Control> 
                                         {console.log('city',company.city)} 
                                     </div> 
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                        <span className='err-msg text-capitalize'>{errors.txt_dockcmpcity?.type === 'required' && 'Please Select City'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Dock company Logo</Text>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />                    
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                        
                    </Card>
                    <Actions>
                        {/* <Link  to={`/comapanies/company-type`}> */}
                        <Button type="button"  className='bttn bttn-transparent'  onClick={() => _goback()}>
                            <Flex jc='jc-around'>
                                <Icons icon="arrow_back" />
                                BACK
                            </Flex>
                        </Button>
                        {/* </Link> */}
                        <Button className='bttn bttn-transparent'  type="submit" 
                         name='dockcmpadd' id='dockcmpadd'>
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
        </Main>
    )

}

export interface FormProps{
    children ? : any | JsxChild; 
    onSubmit ? : (e : React.ChangeEvent<HTMLFormElement>) => void;
}

const StyledForm = ({...props}:FormProps) => {

    const frm = classNames('Form-container');
    return(
        <Form className = {frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps{
    children ? : React.ReactNode
}
const Actions = ({...props}:ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

// React.useEffect(() => {
//     _addDock()
// })

// const _addDock = () => {
//     const newCompany = {
//         type: '',
//         length: '',
//         draft: '',
//         dwt_capacity: '',
//         cranes: '',
//         crane_capacity: '',
//         open: false
//     }

//     return setCompany({...company,, docks: newCompany})
// }