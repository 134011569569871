import { map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getCountry = (params:object) => {
    // console.log('get country called')
    // console.log(params ,'param')
    return http({
    method: 'GET',
    url: '/countries/search',
    params
    }).pipe(
    map(res => {
        if (res.success) {
        // console.log(res, 'res')
        return res
        } else {
        // console.log(res ,'res')
        throw new Error(res.msg)
        }
    })
    )
}

const addCountry =( body : object) => {
    return http({
    method: 'POST',
    url: '/countries',
    body
    }).pipe(
    map(res => {
        if (res.success) {
        return res
        } else {
        throw new Error(res.msg)
        }
    })
    )
}

const deleteCountries = (id : any) => {
    return httpDelete({
      method: 'DELETE',
      url: `/countries/${id}`,
    }).pipe(
      map(res => {
        // console.log(res,'delete response')
        if (res) {
          return res
        } else {
          // console.log(res,'error message')
          throw new Error(res)
        }
      })
    )
  }

const updateCountry =(body:object) => {
    return http({
    method: 'PUT',
    url: `/countries`,
    body
    }).pipe(
    map(res => {
        if (res.success) {
        // console.log('update user response', res)
        return res
        } else {
        throw new Error(res.msg)
        }
    })
    )
}

const getCountryDetail = (id:any) => {
    // TODO: change with real http req
    //return Observable.of(mockData).pipe(map(res => res.find(d => d.id === id)))
}
const countryService = {
    addCountry,
    getCountry,
    getCountryDetail,
    updateCountry,
    deleteCountries
}

export default countryService