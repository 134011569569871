import * as Observable from 'rxjs'
import { timeout, map } from 'rxjs/operators'
import http from '../helpers/http';
import httpDelete from '../helpers/httpDelete';


const mockOwners = [
    {
        id: 112,
        name: 'Buana Jaya',
        join_date: new Date(),
        position: 'CEO',
        companies: 1,
        docks: 12
    },
    {
        id: 231,
        name: 'Bangka Pte',
        join_date: new Date(),
        position: 'CEO',
        companies: 2,
        docks: 9
    }
]

const getStatusList = [
    null,
    {
        label: 'New Added',
        color: 'color'
    },
    {
        label: 'Verified',
        color: 'color'
    },
    {
        label: 'Not verified',
        color: 'color'
    }
]

const getStringStatus = (status: any) => {
    switch (status) {
        case 'UNVERIFIED':
            return getStatusList[3]
        case 'VERIFIED':
            return getStatusList[2]
    }
}

const getDocks = (params: any) => {
    return http({
        method: 'GET',
        url: `/docks/list`,
        params
    }).pipe(
        map(res => {
            // console.log(res,'response for dock list')
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const getDockDetail = (id: number) => {
    console.log('called')
    return http({
        method: 'GET',
        url: `/docks/${id}`
    }).pipe(
        map(res => {
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const deleteDock = (id: any) => {
    return httpDelete({
        method: 'DELETE',
        url: `/docks/${id}`, 
    }).pipe(
        map(res => {
            // console.log(res, 'delete response')
            if (res) {
                return res
            } else {
                // console.log(res, 'error message')
                throw new Error(res)
            }
        })
    )
}

const updateDock = (body: any) => {
    return http({
        method: 'PUT',
        url: '/docks',
        body
    }).pipe(
        map(res => {
            if (res.success) {
                return res
            } else {
                throw new Error(`${res.status} ${res.error}`)
            }
        })
    )
}

const updateDockImage = (body: any) => {
    return http({
        method: 'POST',
        url: '/docks/docklogo',
        body
    }).pipe(
        map(res => {
            if (res.success) {
                return res
            } else {
                throw new Error(`${res.status} ${res.error}`)
            }
        })
    )
}
const addDock = (body: any) => {
    return http({
        method: 'POST',
        url: '/docks',
        body
    }).pipe(
        map(res => {
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const addDockExcel = (body: any,params:any,overWrite:any) => {
    return http({
        method: 'POST',
        url: `/docks/docktestCheck/${params}/${overWrite}`,
        body
    }).pipe(
        map(res => {
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const getDockCompanies = (params: any) => {
    return http({
        method: 'GET',
        url: '/docks/dockCompanies/search',
        params: { ...params }
    }).pipe(
        map(res => {
            if (res.success) {
                // console.log(res, 'response for dock companies')
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const getDockCompanyDetail = (id: number) => {
    return http({
        method: 'GET',
        url: `/companies/${id}`
    }).pipe(
        map(res => {
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const getDockViewDetail = (id: number) => {
    // console.log('get dock view called')
    return http({
        method: 'GET',
        url: `/companies/dockCompanies/${id}`
    }).pipe(
        map(res => {
            // console.log(res,'res')
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const getClaimedUserDetail = (id: number) => {
    // console.log('get claimed called')
    return http({
        method: 'GET',
        url: `/claim/companyClaimed/${id}`
    }).pipe(
        map(res => {
            // console.log(res,'res')
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const deleteDockCompany = (id: any) => {
    return httpDelete({
        method: 'DELETE',
        url: `/companies/${id}`,
    }).pipe(
        map(res => {
            // console.log(res, 'delete response')
            if (res) {
                return res
            } else {
                // console.log(res, 'error message')
                throw new Error(res)
            }
        })
    )
}

const updateDockCompany = (body: any) => {
    return http({
        method: 'PUT',
        url: '/companies',
        body
    }).pipe(
        map(res => {
            if (res.success) {
                // console.log(res, 'edit company response')
                return res
            } else {
                // console.log(res, 'edit company response')
                return new Error(res.msg)
            }
        })
    )
}

const updateDockCompanyImage = (body: any) => {
    return http({
        method: 'POST',
        url: '/companies/Company_image',
        body
    }).pipe(
        map(res => {
            if (res.success) {
                // console.log(res, 'edit company response')
                return res
            } else {
                // console.log(res, 'edit company response')
                return new Error(res.msg)
            }
        })
    )
}

const addDockCompanyImage = (body: any) => {
    return http({
        method: 'POST',
        url: '/companies/add_company_image',
        body
    }).pipe(
        map(res => {
            if (res.success) {
                // console.log(res, 'edit company response')
                return res
            } else {
                // console.log(res, 'edit company response')
                return new Error(res.msg)
            }
        })
    )
}

const addDockCompany = (body: any) => {
    return http({
        method: 'POST',
        url: '/companies',
        body
    }).pipe(
        map(res => {
            if (res.success) {
                // console.log(res, 'add dock company response')
                return res
            }
            else {
                // console.log(res, 'add compny dock')
                return new Error(res.msg)
            }
        })
    )
}

const getDockOwners = (params: any) => {
    return http({
        method: 'GET',
        url: `/docks/dockOwner/search`,
        params: { ...params }
    }).pipe(
        map(res => {
            // console.log(res,'response for dock list')
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const getDockOwnersDashboard = (params: any) => {
    return http({
        method: 'GET',
        url: `/docks/dockOwner/search?${params}`,
    }).pipe(
        map(res => {
            // console.log(res,'response for dock list')
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const getDockOwnerDetail = (id: number) => {
    // console.log('method called')
    return http({
        method: 'GET',
        url: `/docks/dockOwner/view/${id}`
    }).pipe(
        map(res => {
            // console.log(res,'res in dock owner')
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const giveDockCompanyAcess = (body: any) => {
    return http({
        method: 'POST',
        url: `/claim/approved`,
        body
    }).pipe(
        map(res => {
            // console.log(res, 'response')
            if (res.success) {
                return res
            } else {
                // console.log(res, 'error message')
                throw new Error(res)
            }
        })
    )
}

const updateDockOwner = (body: any) => {
    return Observable.of(body)
}

const deleteDockOwner = (id:string | number) => {
    return httpDelete({
        method: 'DELETE',
        url: `/users/${id}`,
    }).pipe(
        map(res => {
        // console.log(res, 'delete response')
        if (res) {
            return res
        } else {
            // console.log(res, 'error message')
            throw new Error(res)
        }
    })
)}

const deleteDockAllImage = (id:string | number) => {
    return httpDelete({
        method: 'DELETE',
        url: `/docks/dockLogoAll/${id}`,
    }).pipe(
        map(res => {
        // console.log(res, 'delete response')
        if (res) {
            return res
        } else {
            // console.log(res, 'error message')
            throw new Error(res)
        }
    })
)}

const removeDockCompanyLogo = (id : any) => {
    return httpDelete({
      method: 'DELETE',
      url: `/companies/CompanyLogoAll/${id}`,
    }).pipe(
      map(res => {
        // console.log(res,'delete response')
        if (res) {
          return res
        } else {
          // console.log(res,'error message')
          throw new Error(res)
        }
      })
    )
  }

const addDockOwner = (body: any) => {
    return Observable.of(body)
}

export const dockService = {
    getDocks,
    getStatusList,
    getDockDetail,
    deleteDock,
    updateDock,
    addDock,
    getDockCompanies,
    getDockCompanyDetail,
    getDockViewDetail,
    deleteDockCompany,
    updateDockCompany,
    addDockCompany,
    getDockOwners,
    getDockOwnersDashboard,
    getDockOwnerDetail,
    updateDockOwner,
    deleteDockOwner,
    addDockOwner,
    getStringStatus,
    giveDockCompanyAcess,
    getClaimedUserDetail,
    updateDockCompanyImage,
    addDockCompanyImage,
    updateDockImage, 
    addDockExcel,
    deleteDockAllImage,
    removeDockCompanyLogo
}