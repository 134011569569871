import { connect } from "react-redux";
import { paintSetSelectedAction } from '../../../../store/action/paintAction'
import React from "react";
import { Col, Form, Row } from 'react-bootstrap'
import { Text } from '../../../../common/components/text/Text'
import { Main } from "../../../../common/components/main/Main";
import { Space } from "../../../../common/components/space/Space";
import Card from "../../../../common/components/card/Card";
import { Attributes } from "../../../../common/components/attribute/Attribute";
import classNames from "classnames";
import { Flex } from "../../../../common/components/flex/Flex";
import { Link } from "react-router-dom";
import { Button } from "../../../../common/components/button/Button";
import Icons from "../../../../common/components/icon/Icon";
import paintService from '../../../../services/paintService'
import Loader from "react-loader-spinner";
import codeService from "../../../../services/codeService";
import NumberFormat from 'react-number-format';

const PaintDetail = (props: any) => {
    const { match } = props
    const [isLoading, setLoading] = React.useState(true)
    const { id } = match.params
    const { cmpid } = match.params;
    const { supid } = match.params;
    const [display, setDisplay] = React.useState<any>({
        primer: false,
        mid_coat: false,
        finish_coat: false,
        paint_name: '',
        generic_type: '',
        vs: '',
        price_litre: '',
        description: '',
        type: '',
        side: '',
        dft: '',
    })
    const [displayCmp, setDisplayCmp] = React.useState<any>([])
    const [displayOwner, setDisplayOwner] = React.useState<any>({
        ownerName: '',
        phone: '',
        phoneCode: '',
        status: '',
        regDate: '',
    })
    //Call perticular paint detail function.  
    React.useEffect(() => {
        _getPaintDetail(id, cmpid, supid)
        _getpaintCompanyDetail(supid);
        _getpaintSupplierDetail(supid);
    }, [])

    const role = localStorage.getItem("role")

    //Get perticular paint detail using match.params. 
    const _getPaintDetail = (id: string | number, paintcmp: string | number, paintsup: string | number) => {
        // console.log(id, 'id')
        setLoading(true)
        return paintService.getPaintDetail({ company_id: paintcmp, paint_id: id, supplier_id: paintsup }).subscribe({
            next: r => {
                const { data } = r.data[0]
                props.paintSetSelectedAction(r.data[0])
                // console.log(r.data, 'r.data')
                setDisplay({
                    primer: r.data[0].primer,
                    mid_coat: r.data[0].midcoat,
                    finish_coat: r.data[0].finishcoat,
                    paint_name: r.data[0].paintname,
                    generic_type: r.data[0].generictype,
                    vs: r.data[0].vs,
                    price_litre: r.data[0].pricelitre,
                    description: r.data[0].description,
                    type: r.data[0].type,
                    side: r.data[0].side,
                    dft: r.data[0].dft,
                })
                // r.map((data: any, i: any) => {
                //     return display.push(data.data)
                // })
                // console.log(display, 'selected paint list page in ship detail using display')
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }
    const _getpaintCompanyDetail = (id: any) => {
        setLoading(true)
        return codeService.getAllCompanyDetail(id).subscribe({
            next: r => {
                if (r.data && r.data.length > 0) {
                    setDisplayCmp(() => {
                        return r.data.map((s: any) => ({ ...s, checked: false }))
                    })
                }
                if (r.data && r.data.length === 0) {
                    setDisplayCmp([])
                }
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    const _getpaintSupplierDetail = (id: any) => {
        setLoading(true)
        return codeService.getsupplierDetail(id).subscribe({
            next: r => {
                // console.log(r.data, 'supplier detail');
                setDisplayOwner({
                    ownerName: r.data.username,
                    phone: r.data.phone,
                    phoneCode: r.data.phone_code,
                    status: r.data.status,
                    regDate: r.data.register_date,
                })
                setLoading(false)

            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }


    // If isloading is true print a text as loading. 
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    // If isloading is false print the details of perticular ship. 
    return (
        <Main>
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                <Col sm='12' md='12' lg='7' xl='7' className='m-0 p-0 align-top'>
                    <Card title="Paint Detail">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Paint Name</Text>
                                        {display.paint_name === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >{display.paint_name}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Paint Type</Text>
                                        {display.type === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.type}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Generic Type</Text>
                                        {display.generic_type === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.generic_type}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Price Per Litre (Rp.)</Text>
                                        {display.price_litre === null ? (
                                            <Text color='main' variant='subtitle' bold >-</Text>
                                        ) : (
                                            <>
                                                {!String(display.price_litre).includes('.') && (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {/* Rp. {display.price_litre} */}
                                                        <NumberFormat value={String(display.price_litre)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />.00
                                                    </Text>
                                                )}
                                                {String(display.price_litre).includes('.') && (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {/* Rp. {display.price_litre} */}
                                                        <NumberFormat value={display.price_litre} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                                                    </Text>
                                                )
                                                }
                                            </>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Description</Text>
                                        {display.description === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.description}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>VS %</Text>
                                        {display.vs === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >{display.vs}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Side</Text>
                                        {display.side === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.side}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>DFT</Text>
                                        {display.dft === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.dft}</Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Primary Coat</Text>
                                        {display.primer === false && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                No</Text>
                                        )}
                                        {display.primer === true && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                Yes</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Middle Coat</Text>
                                        {display.mid_coat === false && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                No</Text>
                                        )}
                                        {display.mid_coat === true && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                Yes</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Finish Coat</Text>
                                        {display.finish_coat === false && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                No</Text>
                                        )}
                                        {display.finish_coat === true && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                Yes</Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm='12' md='12' lg='5' xl='5' className='m-0 p-0 align-top'>
                    {/* <div className='ml-xl-3  mt-sm-4 mt-md-4 mt-lg-4 mt-xl-0 view_page_xs_margin'>

                    </div> */}
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Card title="Supporting Owner Detail">
                            <Attributes className='singleFrame'>
                                <div>
                                    <Text color='main' variant='subtitle'>Owner Name</Text>
                                    {displayOwner.ownerName !== null ? (
                                        <Text color='main' variant='subtitle' bold >{displayOwner.ownerName}</Text>
                                    ) : (
                                        <Text color='main' variant='subtitle' bold > - </Text>
                                    )}
                                </div>
                                <div>
                                    <Text color='main' variant='subtitle'>Register Date</Text>
                                    {displayOwner.regDate !== null ? (
                                        <Text color='main' variant='subtitle' bold>
                                            {/* {format(parseISO(displayOwner.regDate), 'dd/MM/yyyy')} */}
                                            {displayOwner.regDate}
                                        </Text>
                                    ) : (
                                        <Text color='main' variant='subtitle' bold > - </Text>
                                    )}
                                </div>
                                <div>
                                    <Text color='main' variant='subtitle'>Mobile</Text>
                                    {displayOwner.phone === '' || null ? (
                                        <Text color='main' bold variant='subtitle'> - </Text>
                                    ) : (
                                        <>
                                            {displayOwner.phoneCode === '' ? (
                                                <Text color='main' bold variant='subtitle'>{displayOwner.phoneCode}
                                                    <span>
                                                        <Text color='main' bold variant='subtitle'>{displayOwner.phone}</Text>
                                                    </span>
                                                </Text>
                                            ) : (
                                                <>
                                                    <Text color='main' bold variant='subtitle'>{displayOwner.phoneCode} &nbsp;
                                                        <span>
                                                            <Text color='main' bold variant='subtitle'>{displayOwner.phone}</Text>
                                                        </span>
                                                    </Text>
                                                </>
                                            )
                                            }
                                        </>
                                    )}
                                </div>
                                <div>
                                    <Text color='main' variant='subtitle'>Status</Text>
                                    {displayOwner.status !== 'VERIFIED' ? (
                                        <Text color='danger' variant='subtitle' bold>Not Verified</Text>
                                    ) : (
                                        <Text color='success' variant='subtitle' bold >Verified</Text>
                                    )}
                                </div>
                            </Attributes>
                        </Card>
                    </div>
                </Col>
            </Row>
            <Row className='m-0 p-0 mt-2 mt-sm-2 mt-md-2 mt-lg-1 mt-xl-1'>
                <Col sm='12' md='12' lg='7' xl='7' className='m-0 p-0 align-top'>
                    <Card title="Industries Information">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                {displayCmp.length === 0 && (
                                    <div>
                                        <Space divheight={20} />
                                        <Text center>  Not Associated with any Company Yet  </Text>
                                        <Space divheight={20} />
                                    </div>
                                )
                                }
                                {displayCmp.map((data: any, i: any) => (
                                    data.length !== 0 && (
                                        <Attributes id={data.company_id} className='singleFrame'>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Name</Text>
                                                {data.company_name !== null ? (
                                                    <Text color='main' variant='subtitle' bold >{data.company_name}</Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold > - </Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>City Name </Text>
                                                {data.city_name !== null ? (
                                                    <Text color="main" variant='subtitle' bold>{data.city_name}</Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold > - </Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Country Name </Text>
                                                {data.country_name !== null ? (
                                                    <Text color="main" variant='subtitle' bold>{data.country_name}</Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold > - </Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Status</Text>
                                                {data.status !== 'VERIFIED' ? (
                                                    <Text color="danger" variant='subtitle' bold>Not Verified</Text>
                                                ) : (
                                                    <Text color='success' variant='subtitle' bold > Verified </Text>
                                                )}
                                            </div>
                                        </Attributes>
                                    )
                                ))}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='7' xl='7'>
                    <Actions>
                        {/* If you click the back button its is redirect to ships list page.  */}
                        <Link to='/paint/list'>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the edit button its is redirect to ships edit page.  */}
                        {role === 'VISITOR' ? (
                            //                        <Link to={`/paint/edit/${id}/${cmpid}/${supid}/${displayOwner.ownerName}`}>
                            <Button variant='transparent' type="submit" disabled>
                                <Flex>
                                    <Icons color="#ffffff" icon="edit" />
                                    EDIT DATA
                                </Flex>
                            </Button>
                            //                      </Link>
                        ) : (
                            //                            <Link to={`/paint/edit/${id}/${cmpid}/${supid}/${displayOwner.ownerName}`}>
                                <Button variant='transparent' type="submit" disabled>
                                    <Flex>
                                        <Icons color="#ffffff" icon="edit" />
                                        EDIT DATA
                                    </Flex>
                                </Button>
                            //                            </Link>
                        )}
                    </Actions>
                </Col>
            </Row>
        </Main>
    )
}
export interface ActionProps {
    children?: React.ReactNode
}

const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}


const mapStateToProps = (state: any) => ({
    paint: state.paint.selectedPaint
})

// connect to paintSetSelectedAction and get the one perticular ship data. 
export default connect(
    mapStateToProps,
    {
        paintSetSelectedAction
    }
)(PaintDetail)