import {
    MANUFACTURER_LIST_GET,
    MANUFACTURER_LIST_GET_F,
    MANUFACTURER_LIST_GET_R,
    MANUFACTURER_SELECTED_SET,
    MANUFACTURER_CMP_LIST_GET,
    MANUFACTURER_CMP_LIST_GET_F,
    MANUFACTURER_CMP_LIST_GET_R,
    MANUFACTURER_CMP_SELECTED_SET,
    PAYPERVIEW_LIST_GET,
    PAYPERVIEW_LIST_GET_F,
    PAYPERVIEW_LIST_GET_R,
    PAYPERVIEW_SELECTED_SET,
    TRANSACTION_LIST_GET,
    TRANSACTION_LIST_GET_F,
    TRANSACTION_LIST_GET_R,
    TRANSACTION_SELECTED_SET,
    QUOTATION_LIST_GET,
    QUOTATION_LIST_GET_F,
    QUOTATION_LIST_GET_R,
    QUOTATION_SELECTED_SET
} from '../actionType'

export const manufacturerListGetAction = (params: any) => ({
    type: MANUFACTURER_LIST_GET,
    payload: params
})

export const manufacturerListGetFAction = (params: any) => ({
    type: MANUFACTURER_LIST_GET_F,
    payload: params
})

export const manufacturerListGetRAction = (params: any) => ({
    type: MANUFACTURER_LIST_GET_R,
    payload: params
})

export const manufacturerSetSelectedAction = (manufacturers: any) => ({
    type: MANUFACTURER_SELECTED_SET,
    payload: manufacturers
})

//Manufacturer Company Action

export const manufacturerCmpListGetAction = (params: any) => ({
    type: MANUFACTURER_CMP_LIST_GET,
    payload: params
})

export const manufacturerCmpListGetFAction = (params: any) => ({
    type: MANUFACTURER_CMP_LIST_GET_F,
    payload: params
})

export const manufacturerCmpListGetRAction = (params: any) => ({
    type: MANUFACTURER_CMP_LIST_GET_R,
    payload: params
})

export const manufacturerCmpSetSelectedAction = (manufacturerscmp: any) => ({
    type: MANUFACTURER_CMP_SELECTED_SET,
    payload: manufacturerscmp
})

//Price per view Action
export const payPerViewListGetAction = (params: any) => ({
    type: PAYPERVIEW_LIST_GET,
    payload: params
})

export const payPerViewListGetFAction = (params: any) => ({
    type: PAYPERVIEW_LIST_GET_F,
    payload: params
})

export const payPerViewListGetRAction = (params: any) => ({
    type: PAYPERVIEW_LIST_GET_R,
    payload: params
})
export const payPerViewSetSelectedAction = (perView: any) => ({
    type: PAYPERVIEW_SELECTED_SET,
    payload: perView
})
// Transaction history action
export const transactionListGetAction = (params: any) => ({
    type: TRANSACTION_LIST_GET,
    payload: params
})

export const transactionListGetFAction = (params: any) => ({
    type: TRANSACTION_LIST_GET_F,
    payload: params
})

export const transactionListGetRAction = (params: any) => ({
    type: TRANSACTION_LIST_GET_R,
    payload: params
})
export const transactionSetSelectedAction = (transaction: any) => ({
    type: TRANSACTION_SELECTED_SET,
    payload: transaction
})

//Quotation Action

export const quotationListGetAction = (params: any) => ({
    type: QUOTATION_LIST_GET,
    payload: params
})

export const quotationListGetFAction = (params: any) => ({
    type: QUOTATION_LIST_GET_F,
    payload: params
})

export const quotationListGetRAction = (params: any) => ({
    type: QUOTATION_LIST_GET_R,
    payload: params
})
export const quotationSetSelectedAction = (quotation: any) => ({
    type: QUOTATION_SELECTED_SET,
    payload: quotation
})