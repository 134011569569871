import React, { useRef, useState } from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button, Image, Carousel } from 'react-bootstrap'
import noImg from '../../../../assets/images/NoImg.jpg';
import editicon from '../../../../assets/images/editone.png'
import addicon from '../../../../assets/images/User.png'
import removeicon from '../../../../assets/images/Remove.png'
import '../../../../common/components/button/Button.scss'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Flex } from '../../../../common/components/flex/Flex'
// import {Input, Select, Textarea} from '../../../../common/components/control/Control'
import '../../../../common/components/control/Control.scss'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
// import codeService from '../../../services/codeService'
import { dockService } from '../../../../services/dockService'
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
// import { Link } from 'react-router-dom'
// import { JsxChild } from 'typescript'
import { connect } from 'react-redux';
import { dockCompanyListGetAction, dockCompanySetSelectedAction } from '../../../../store/action/dockActions';
import { history } from '../../../../store';
import swal from 'sweetalert'
// import { FilterState } from '../../filter/filterState'
import Loader from 'react-loader-spinner';
import { useForm } from 'react-hook-form';
import codeService from '../../../../services/codeService';
import { Label } from '../../../../common/components/label/Label';
import { FilterState } from '../../MutiImageState/MultipleImageFilterState'

interface FormData {
    txt_dockeditcmpName: string;
    txt_dockeditcmpPhn: string;
    txt_dockeditcmpAddress: string;
    txt_dockeditcmpcity: string;
    txt_dockeditmpemail: string;
    txt_dockeditcmpwebstite: string;
    txt_dockeditcmpstatus: string;
    txt_dockceo: string;
    txt_dockchairman: string;
    txt_dockdirector: string;
    txt_dockyear: string;
    txt_dockservices: string;
}
interface bvalueProps {
    string_image: string | ArrayBuffer | null
}
const DockCompanyEdit = (props: any) => {
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const { match } = props
    const { pages } = match.params
    const [showDelete, setShow] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false)
    const [dockcmpeditflag, setDockCmpeditFlag] = React.useState(false)
    const [dockCmpEditForm, setDockCmpEditform] = useState<any>({
        mobileCode: []
    })

    const [company, setCompany] = useState<any>({
        isLoading: false,
        isSubmitting: false,
        shipName: '',
        shipType: [],
        editShip: [{}],
        shipTypes: '',
        countryFlag: [],
        countryFlags: '',
        classification: [],
        material: [],
        materials: '',
        IMONumber: '',
        Company: [],
        Companys: '',
        Weight: '',
        Length: '',
        Beam: '',
        Draft: '',
        status: 'UNVERIFIED',
        cities: []
    },
    )

    const [display, setDisplay] = useState<any>({
        name: '',
        address: '',
        email: '',
        phone: '',
        website: '',
        status: 'UNVERIFIED',
        owner: '',
        type: '',
        id: '',
        city: '',
        phone_code: '',
        company_logo: '',
        company_logos: [],
        ceo: '',
        chairman: '',
        director: '',
        year: '',
        services: '',
        user: ''
    })

    const _removeDockCompanyLogo = (id: any) => {
        return dockService.removeDockCompanyLogo(id).subscribe({
            next: (r) => {
                // console.log(r, 'response')
                setShow(false);
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
                // history.push(`/ships/${id}/`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
            }
        })
    }

    const upload = useRef(null);
    var base64: string | any
    const [bvalue, setBValue] = React.useState<bvalueProps>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        var pic: any[] = []
        //        pic.map((items) => {)
        //  localStorage.removeItem("image")
        for (let i = 0; i < event.target.files.length; i++) {
            let file = event.target.files[i];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            console.log(event.target.files[i])
            reader.onload = function () {
                base64 = reader.result
                //   alert("Hi")
                //   console.log("base 64", base64)
                if (i == 0) {
                    FilterState.imageFilter.MultipleImages.push(base64)
                } else {
                    FilterState.imageFilter.MultipleImages.push(base64)
                }
                if (i + 1 == event.target.files.length) {
                    ImageAPI(FilterState.imageFilter.MultipleImages);
                }
            }
        }
    }
    const _setFromDate = (val:any) => {
        if(val !== null)
        {
            setDisplay({...display, year: val})
        }
        else(
            setDisplay({...display, year:''})
        )
      }
    const getAddBase64 = (event: any) => {
        var pic: any[] = []
        //        pic.map((items) => {)
        //  localStorage.removeItem("image")
        for (let i = 0; i < event.target.files.length; i++) {
            let file = event.target.files[i];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            console.log(event.target.files[i])
            reader.onload = function () {
                base64 = reader.result
                //   alert("Hi")
                //   console.log("base 64", base64)
                if (i == 0) {
                    FilterState.imageFilter.MultipleImages.push(base64)
                } else {
                    FilterState.imageFilter.MultipleImages.push(base64)
                }
                if (i + 1 == event.target.files.length) {
                    AddImageAPI(FilterState.imageFilter.MultipleImages);
                }
            }
        }
    }

    var example = ["https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1711.jpg",
        "https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1749.jpg",
        "https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1369.jpg"]

    const ImageAPI = (imageurl: any) => {
        const { id } = match.params
        const { pgnum } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        dockService.updateDockCompanyImage(imagePayload).subscribe({
            next: () => {
                FilterState.imageFilter.MultipleImages = []
                window.location.reload()
                //                history.push(`/dock-companies/edit/${id}/${pgnum}`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }

    const _getCity = (() => {
        // console.log(codeService.getCities())
        return codeService.getCities().subscribe({
            next: r => {
                console.log(r.data, 'owners in Dock add page')

                setCompany({ ...company, cities: r.data })
                r.data.map((data: any, i: any) => {
                    return company.cities.push(r.data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    React.useEffect(() => {
        // _getOwnerName()
        _getCity()
        _getMobileCode()
    }, [])

    const AddImageAPI = (imageurl: any) => {
        const { id } = match.params
        const { pgnum } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        dockService.addDockCompanyImage(imagePayload).subscribe({
            next: () => {
                //  alert("hi")
                //               localStorage.setItem("retain",)
                // swal("Great", "Dock Company Image Updated", "success")
                //           history.push(`/dock-companies/edit/${id}/${pgnum}`)
                FilterState.imageFilter.MultipleImages = []
                window.location.reload()
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }

    const _getMobileCode = () => {
        return codeService.getMobileCode().subscribe({
            next: r => {
                // console.log(r.data, "Role")
                setDockCmpEditform({ ...dockCmpEditForm, mobileCode: r.data })
                // console.log(dockCmpEditForm.mobileCode, 'mobileCode')
                r.data.map((data: any, i: any) => {
                    if (data !== null) {
                        return dockCmpEditForm.mobileCode.push(data)
                    } else {
                        return dockCmpEditForm.mobileCode.push('')
                    }
                })
            },
            error: err => {
                console.error(err)
            }
        })
    }
    React.useEffect(() => {
        const { id } = match.params
        _getCompanyInfo(id)
        _getMobileCode()
    }, [])

    const _getCompanyInfo = (id: any) => {
        setLoading(true)
        return dockService.getDockCompanyDetail(id).subscribe({
            next: r => {
                setDisplay(r.data)
                setDisplay({
                    ...display,
                    city: r.data.companyCity.id,
                    name: r.data.name,
                    address: r.data.address,
                    email: r.data.email,
                    phone: r.data.phone,
                    website: r.data.website,
                    status: r.data.status,
                    owner: r.data.user,
                    type: r.data.companyTypes.id,
                    id: r.data.id,
                    phone_code: r.data.phone_code,
                    company_logo: r.data.company_logo,
                    company_logos: r.data.company_logos,
                    user: r.data.user,
                    ceo: (r.data.user === null) ? '' : r.data.user.ceo,
                    chairman: (r.data.user === null) ? '' : r.data.user.chairman,
                    director: (r.data.user === null) ? '' : r.data.user.directors,
                    services: (r.data.user === null) ? '' : r.data.user.services,
                    year: (r.data.user === null) ? '' : r.data.user.year_of_establishment, 
                    company_description:r.data.company_description
                })
                console.log(r.data, 'display edit')
                props.dockCompanySetSelectedAction(r.data)
                // {r.data.map((cmp: any, i: any) => [
                //     setDisplay({
                //         ...display, city: cmp.companyCity.id
                //     })
                // ])}
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }
    const _goback = () => {
        const { pgnum } = match.params
        const { key } = match.params
        console.log('go back called')
        console.log(pgnum, 'page')
        // dockCompanyListGetAction({ page:pgnum, size: 10,
        //     companyname: FilterState.cmpFilter.cmp || key, 
        //     city: FilterState.cmpFilter.city, 
        //     country: FilterState.cmpFilter.country,
        //     fromDate:String(FilterState.cmpFilter.fromDate),
        //     toDate: String(FilterState.cmpFilter.toDate), 
        //     status: FilterState.cmpFilter.status, 
        //     claimedstatus:FilterState.cmpFilter.claimedStatus,
        // })
        history.push(`/comapanies/company-type/${pgnum}`)
    }
    const _didDockCmpEdit = () => {
        const { pgnum } = match.params
        const { key } = match.params
        // e.preventDefault()
        var dockEditPhonecmp: any;
        if(display.phone === null||display.phone===''||display.phone===' '||display.mobileCode==null||display.mobileCode==''){
            dockEditPhonecmp = '';
        }
        else{
            dockEditPhonecmp =  String(company.mobileCode).concat(" ",company.phone);
        }
        const updatedForm = {
            id: Number(display.id),
            name: display.name,
            phone:dockEditPhonecmp,
            address: display.address,
            email: display.email,
            website: display.website,
            status: display.status,
            ownerUser: '',
            type: String(display.type),
            city: String(display.city),
            year_of_establishment: display.year,
            ceo: display.ceo,
            chairman: display.chairman,
            directors: display.director,
            service: display.services,
            company_description:display.company_description
        }
        // console.log(updatedForm,'dock company edit updated form')
        if (dockcmpeditflag === true) {
            setLoading(true)
            dockService.updateDockCompany(updatedForm).subscribe({
                next: () => {
                    swal("Great", "Dock Company Updated Successfully", "success")
                    // props.dockCompanyListGetAction({page:pages, size:10})
                    // dockCompanyListGetAction({ page:pgnum, size: 10,
                    //     companyname: FilterState.cmpFilter.cmp || key, 
                    //     city: FilterState.cmpFilter.city, 
                    //     country: FilterState.cmpFilter.country,
                    //     fromDate:String(FilterState.cmpFilter.fromDate),
                    //     toDate: String(FilterState.cmpFilter.toDate), 
                    //     status: FilterState.cmpFilter.status, 
                    //     claimedstatus:FilterState.cmpFilter.claimedStatus,
                    // })
                    history.push(`/comapanies/company-type/${pgnum}`)
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    setLoading(false)
                    console.error(err)
                }
            })
        }
        else {
            console.log(dockcmpeditflag)
        }
    }

    onsubmit = () => {
        // e.preventDefault()
        console.log(Object.keys(errors).length, 'error length')
        if (Object.keys(errors).length === 0) {
            setDockCmpeditFlag(true)
            _didDockCmpEdit()
        }
        else {
            setDockCmpeditFlag(false)
        }
    }
    var data: any
    var length: any
    length = 0
    if (display.company_logos !== null) {
        data = [display.company_logos.map((data: any, i: any) => {
            length = length + 1
            return String(data.name)
        })];
        console.log("Check", data);
    } else {
        data = null;
    }
    console.log(length)
    if (display.company_logos !== null) {
        data.map((value: any) => (
            example = value
            //       console.log("inside carosel",value)
        ))
    }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }


    return (
        <Main>
            {display === null && (
                <>
                    <Space divheight={15} />
                    <Text color='main' variant='subtitle' center bold> There is No data for requested id</Text>
                    <Space divheight={15} />
                </>
            )}
            {display !== null && (
                <>
                    <div className='title-name-block'>
                        <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                            {display.company_logo === null && (
                                <Image src={noImg} alt='logo' roundedCircle />
                            )}
                            {display.company_logo === '' && (
                                <Image src={noImg} alt='logo' roundedCircle />
                            )}
                            {display.company_logo !== null && display.company_logo !== '' && length === 1 && (
                             //   <div className='userCompany-block'>
                                    <img src={display.company_logo} alt='logo' />
                              //  </div>
                            )}
                            {display.company_logo !== null && display.company_logo !== '' && length > 1 && (
                                <Carousel slide={true} touch={true}>
                                    {example.map((value: any) => (
                                        <Carousel.Item interval={4000}>
                                            <div className='userCompany-block'>
                                                <img
                                                    className="d-block w-100 slide"
                                                    src={value}
                                                    alt="First slide"
                                                />
                                            </div>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            )}

                        </div>
                        <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                            {display.company_logo === null && (
                                <label className="custom-file-upload">
                                    <Image src={editicon} alt='logo' />
                                    <input
                                        ref={upload}
                                        type="file"
                                        multiple accept="image/*"
                                        onChange={getBase64}
                                    />
                                </label>
                            )}
                            {display.company_logo !== null && display.company_logo !== '' && (
                                <div className='imageEditBlock'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Image src={editicon} alt='logo' />
                                        <input
                                            ref={upload}
                                            type="file"
                                            multiple accept="image/*"
                                            onChange={getBase64}
                                        />
                                    </label>

                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={removeicon} alt='logo' onClick={() => _removeDockCompanyLogo(display.id)} />
                                        </Flex>
                                    </label>
                                </div>
                            )}
                        </div>
                        <Text variant='title' color='main' className='txt-capitalize'>
                            {/* {String(display.email).substring(0,String(display.email).indexOf('@'))} */}
                            {display.name}
                        </Text>
                        <Label className={display.status === 'UNVERIFIED' ? 'danger' : 'warning'} >
                            {display.status === 'UNVERIFIED' ? 'Not Verified' : 'Verified'}
                        </Label>
                    </div>
                    <Space divheight={15} />
                    <Row className='p-0 m-0'>
                        <Col xs='12' sm='12' md='12' lg='9' xl='9' className='p-0 m-0'>
                            <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='dockcmpAddForms'>
                                <Card title="Edit Dock Company Information">
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                            <Attributes>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Name</Text>
                                                    <Form.Control className='basecontrol custome-input'
                                                        type="text"
                                                        name="txt_dockeditcmpName"
                                                        value={display.name}
                                                        onChange={(e) => setDisplay({ ...display, name: e.target.value })}
                                                        ref={register({ required: true, })}
                                                        placeholder='Company Name'
                                                    />
                                                </div>
                                                <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>{errors.txt_dockeditcmpName?.type === 'required' && 'Please enter Dock Company name'}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Email</Text>
                                                    <Form.Control className='basecontrol custome-input'
                                                        type="text"
                                                        name="txt_dockeditmpemail"
                                                        value={display.email}
                                                        onChange={(e) => setDisplay({ ...display, email: e.target.value })}
                                                        ref={register({ pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                        placeholder='Company email'
                                                    />
                                                </div>
                                                <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>
                                                            {/* {errors.txt_dockeditmpemail?.type === 'required' && 'Please enter Company Email'} */}
                                                            {errors.txt_dockeditmpemail?.type === 'pattern' && 'Please enter Valid Company Email'}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Website </Text>
                                                    <Form.Control className='basecontrol custome-input'
                                                        type="text"
                                                        name="txt_dockeditcmpwebstite"
                                                        value={display.website}
                                                        onChange={(e) => setDisplay({ ...display, website: e.target.value })}
                                                        ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                        placeholder='Company Website'
                                                    />
                                                </div>
                                                <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>
                                                            {/* {errors.txt_dockeditcmpwebstite?.type === 'required' && 'Please enter Company Website'} */}
                                                            {errors.txt_dockeditcmpwebstite?.type === 'pattern' && 'Please enter Valid Company Website'}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Description</Text>
                                                    <Form.Control as="textarea"
                                                        className='basecontrol custome-textarea'
                                                        placeholder='Company Description'
                                                        rows={3}
                                                        cols={50}
                                                        name="txt_dockeditcmpdescription"
                                                        value={display.company_description}
                                                        onChange={(e) => setDisplay({ ...display, company_description: e.target.value })}
                                                    // ref={register({ required: true, })}

                                                    />
                                                </div>
                                                {display.user === null ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        
                                                       
                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>Services </Text>
                                                            <Form.Control className='basecontrol custome-input'
                                                                type="text"
                                                                name="txt_dockservices"
                                                                value={display.services}
                                                                onChange={(e) => setDisplay({ ...display, services: e.target.value })}
                                                                //ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                                placeholder='Services'
                                                            />
                                                        </div>
                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>Year of Establishment </Text>
                                                            {/* <Form.Control className='basecontrol custome-input'
                                                                type="text"
                                                                name="txt_dockyear"
                                                                value={display.year}
                                                                onChange={(e) => setDisplay({ ...display, year: e.target.value })}
                                                                //ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                                placeholder='Year of Establishment'
                                                            /> */}
                                                            <input type="date" id="fromtime" name="fromtime" value={display.year}
                                                                onChange={(e) => _setFromDate(e.target.value) }>
                                                            </input>
                                                        </div>
                                                        
                                                    </>
                                                    
                                                )}
                                            </Attributes>
                                        </Col>
                                        <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                            <Attributes>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Contact Number</Text>
                                                    <div className='phnnum-container'>
                                                        <Form.Control as="select" value={display.phone_code}
                                                            name='txt_ediCmpPhnCode'
                                                            className='basecontrol cutome-selectInputnxt phn-dp'
                                                            onChange={(e) => setDisplay({ ...display, phone_code: e.target.value })}
                                                        // ref={register({ required: true, })}
                                                        >
                                                            <option value="">Code</option>
                                                            {dockCmpEditForm.mobileCode.map((data: any, i: any) => {
                                                                return <option key={i} value={data.mobile_code}>{data.country_name}</option>
                                                            })}
                                                        </Form.Control>
                                                        <Form.Control className='basecontrol custome-input'
                                                            type="text"
                                                            name="txt_dockeditcmpPhn"
                                                            value={display.phone}
                                                            onChange={(e) => setDisplay({ ...display, phone: e.target.value })}
                                                            // ref={register({ required: true, pattern: /^\d{10}$/ })}
                                                            placeholder='Company Contact Number'
                                                        />
                                                    </div>
                                                </div>
                                                {/* <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>
                                                            {errors.txt_dockeditcmpPhn?.type === 'required' && 'Please enter Comapny Contact Number'}
                                                            {errors.txt_dockeditcmpPhn?.type === 'pattern' && 'Please enter valid Company Contact Number'}
                                                        </span>
                                                    </Col>
                                                </Row> */}
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Address</Text>
                                                    <Form.Control as="textarea"
                                                        className='basecontrol custome-textarea'
                                                        placeholder='Company Address'
                                                        rows={3}
                                                        cols={50}
                                                        name="txt_dockeditcmpAddress"
                                                        value={display.address}
                                                        onChange={(e) => setDisplay({ ...display, address: e.target.value })}
                                                    // ref={register({ required: true, })}

                                                    />
                                                </div>
                                                <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>{errors.txt_dockeditcmpAddress?.type === 'required' && 'Please enter Company Address'}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                {display.user === null ? (
                                                     <Text color='danger' variant='subtitle' className="no_user" bold>
                                                     No user is mapped to this company
                                                 </Text>
                                                ) : (
                                                    <>
                                                     <div>
                                                            <Text color='tablehead' variant='subtitle'>Director </Text>
                                                            <Form.Control className='basecontrol custome-input'
                                                                type="text"
                                                                name="txt_dockdirector"
                                                                value={display.director}
                                                                onChange={(e) => setDisplay({ ...display, director: e.target.value })}
                                                                //ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                                placeholder='Director'
                                                            />
                                                        </div>
                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>CEO </Text>
                                                            <Form.Control className='basecontrol custome-input'
                                                                type="text"
                                                                name="txt_dockceo"
                                                                value={display.ceo}
                                                                onChange={(e) => setDisplay({ ...display, ceo: e.target.value })}
                                                                //ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                                placeholder='CEO'
                                                            />
                                                        </div>
                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>Chairman </Text>
                                                            <Form.Control className='basecontrol custome-input'
                                                                type="text"
                                                                name="txt_dockchairman"
                                                                value={display.chairman}
                                                                onChange={(e) => setDisplay({ ...display, chairman: e.target.value })}
                                                                //ref={register({ pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ })}
                                                                placeholder='Chairman'
                                                            />
                                                        </div>
                                                        
                                                    </>
                                                    
                                                )}
                                            </Attributes>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm='12' md='8' xl='6' lg='6'>
                                            <Attributes>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>City Name</Text>
                                                    <Form.Control as="select" className='basecontrol cutome-selectInputnxt'
                                                        value={display.city} name='txt_dockcmpcity'
                                                        onChange={e => setDisplay({ ...display, city: e.target.value })}
                                                        ref={register({ required: true, })}>
                                                        {/* {console.log(display.cities)} */}
                                                        <option value="">-- Select --</option>
                                                        {/* Map the data's from company array and used as a option value. By Dharani on 04-Mar-2021 */}
                                                        {/* {console.log(display.cities)} */}
                                                        {company.cities.map((data: any, i: any) => {
                                                            return <option key={i} value={data.id}>{data.name}</option>
                                                        })}
                                                    </Form.Control>
                                                    {console.log('city', display.city)}
                                                </div>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Status</Text>
                                                    <Form.Control as="select" className='basecontrol cutome-selectInputnxt'
                                                        value={display.status} name="txt_dockeditcmpstatus"
                                                        onChange={(e) => setDisplay({ ...display, status: e.target.value })}
                                                    // ref={register({ required: true, })}
                                                    >
                                                        <option className='option-placeholder'>-- Select --</option>
                                                        <option value="VERIFIED"> Verified</option>
                                                        <option value="UNVERIFIED"> Not verified</option>
                                                    </Form.Control>
                                                </div>
                                                {/* <Row className='m-0 p-0'>
                                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                        <span className='err-msg text-capitalize'>{errors.txt_dockeditcmpstatus?.type === 'required' && 'Please Select Status'}
                                                        </span>
                                                    </Col>
                                                </Row> */}
                                            </Attributes>
                                        </Col>
                                    </Row>
                                </Card>
                                <Actions>
                                    <Button type="button" className='bttn bttn-transparent' onClick={() => _goback()}>
                                        <Flex jc='jc-around'>
                                            <Icons icon="arrow_back" />
                                            BACK
                                        </Flex>
                                    </Button>
                                    <Button className='bttn bttn-transparent' type="submit">
                                        <Flex>
                                            <Icons color="#ffffff" icon="check" />
                                            SAVE DATA
                                        </Flex>
                                    </Button>
                                </Actions>
                            </Form>
                        </Col>
                        <Col xs='12' sm='12' md='12' lg='3' xl='3' className='p-0 m-0'>
                            <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                                <Card title="Profile Picture" className='profile-title'>
                                    <div className='user-mainBlock'>
                                        <div className='user-mainBlock'>
                                            {display.company_logo === null && (
                                                <div className='userCompany-block'>
                                                    <img src={noImg} alt='logo' />
                                                </div>
                                            )}
                                            {display.company_logo === '' && (
                                                <div className='userCompany-block'>
                                                    <img src={noImg} alt='logo' />
                                                </div>
                                            )}
                                            {display.company_logo !== null && display.company_logo !== '' && length === 1 && (
                                                <div className='userCompany-block'>
                                                    <img src={display.company_logo} alt='logo' />
                                                </div>
                                            )}
                                            {display.company_logo !== null && display.company_logo !== '' && length > 1 && (
                                                <Carousel slide={true} touch={true}>
                                                    {example.map((value: any) => (
                                                        <Carousel.Item interval={4000}>
                                                            <div className='userCompany-block'>
                                                                <img
                                                                    className="d-block w-100 slide"
                                                                    src={value}
                                                                    alt="First slide"
                                                                />
                                                            </div>
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                            )}
                                        </div>
                                    </div>
                                    <div className='imageEditBlock'>
                                        {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                        {/* {length > 1 && (
                                        <label className="custom-file-upload">
                                            <Flex>
                                                <Image src={editicon} alt='logo' />
                                            </Flex>
                                            <input
                                                ref={upload}
                                                type="file"
                                                multiple accept="image/*"
                                                onChange={getAddBase64}
                                            />
                                        </label>
                                        )}
                                        {length === 1 && ( */}
                                        {((display.company_logo === null)||(display.company_logo === '')) && (
                                            <div className='imageEditBlock'>
                                                {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                                <label className="custom-file-upload">
                                                    <Flex>
                                                        <Image src={editicon} alt='logo' />
                                                    </Flex>
                                                    <input
                                                        ref={upload}
                                                        type="file"
                                                        multiple accept="image/*"
                                                        onChange={getBase64}
                                                    />
                                                </label>
                                            </div>
                                        )}
                                        {display.company_logo !== null && display.company_logo !== '' && (
                                            <div className='imageEditBlock'>
                                                {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                                <label className="custom-file-upload">
                                                    <Flex>
                                                        <Image src={editicon} alt='logo' className="editIconRemove" />
                                                    </Flex>
                                                    <input
                                                        ref={upload}
                                                        type="file"
                                                        multiple accept="image/*"
                                                        onChange={getBase64}
                                                    />
                                                </label>

                                                {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                                <label className="custom-file-upload">
                                                    <Flex>
                                                        {/* <Icons color="#ffffff" icon="edit" /> */}
                                                        <Image src={removeicon} alt='logo' onClick={() => _removeDockCompanyLogo(display.id)} />
                                                    </Flex>
                                                </label>
                                            </div>
                                        )}
                                        {/* )} */}
                                        {/* {length > 1 && (
                                        <label className="custom-file-upload">
                                            <Flex>
                                                <Image src={removeicon} alt='logo' onClick={}/>
                                            </Flex>
                                            {/* <input
                                             //   ref={upload}
                                                type="f"
                                             //   multiple accept="image/*"
                                                onChange={getBase64}
                                            /> 
                                        </label>
                                       )}*/}
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </Main>
    )

}

// export interface FormProps{
//     children ? : any | JsxChild; 
//     onSubmit ? : (e : React.ChangeEvent<HTMLFormElement>) => void;
// }

// const StyledForm = ({...props}:FormProps) => {

//     const frm = classNames('Form-container');
//     return(
//         <Form className = {frm} onSubmit={props.onSubmit} autoComplete="off">
//             {props.children}
//         </Form>
//     )
// }

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    company: state.dock.selectedCompany
})

export default connect(
    mapStateToProps,
    {
        dockCompanySetSelectedAction,
        dockCompanyListGetAction
    }
)(DockCompanyEdit)