import { connect } from "react-redux";
import { shipSetSelectedAction } from '../../../../store/action/shipActions';
import React from "react";
import { Col, Form, Row } from 'react-bootstrap'
import { Text } from '../../../../common/components/text/Text'
import { Main } from "../../../../common/components/main/Main";
import { Space } from "../../../../common/components/space/Space";
import Card from "../../../../common/components/card/Card";
import { Grid } from "../../../../common/components/grid/Grid";
import { Attributes } from "../../../../common/components/attribute/Attribute";
import classNames from "classnames";
import { Flex } from "../../../../common/components/flex/Flex";
import { Link } from "react-router-dom";
import { Button } from "../../../../common/components/button/Button";
import Icons from "../../../../common/components/icon/Icon";
import shipClassificationService from '../../../../services/shipClassificationService'
import { Label } from "../../../../common/components/label/Label";
import { format, parseISO } from 'date-fns'
import Loader from "react-loader-spinner";

const ClassificationDetail = (props: any) => {
    const { match } = props
    const [isLoading, setLoading] = React.useState(true)
    const [display, setDisplay] = React.useState<any>({
        id: '',
        name: '',
        description: '',
        name_english: '',
        name_description: ''
    })

    //Call perticular ship detail function.  
    React.useEffect(() => {
        const { id } = match.params
        _ClassificationDetail(id)
    }, [])

    //Get perticular ship detail using match.params. 
    const _ClassificationDetail = (id: any) => {
        setLoading(true)

        return shipClassificationService.getShipClassificationDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.shipSetSelectedAction(r.data)
                // console.log(r.data,'r.data')
                setDisplay(r.data)
                // r.map((data: any, i: any) => {
                //     return display.push(data.data)
                // })
                // console.log(display,'selected ship list page in ship detail using display')
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    // If isloading is true print a text as loading. 
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    // If isloading is false print the details of perticular ship. 
    return (
        <Main>
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                <Col sm='12' md='12' lg='12' xl='8' className='m-0 p-0 align-top'>
                    <Card title="Ship Classification Detail">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Classification</Text>
                                        {display.name === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >{display.name}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Description</Text>
                                        {display.description === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.description}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Classification in English</Text>
                                        {display.name_english === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.name_english}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Decription in English</Text>
                                        {display.name_description === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.name_description}</Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='12' xl='8'>
                    <Actions>
                        {/* If you click the back button its is redirect to ships list page.  */}
                        <Link to='/shipClassification/list'>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the edit button its is redirect to ships edit page.  */}
                        <Link to={`/shipClassification/edit/${display.id}`}>
                            <Button variant='transparent' type="submit">
                                <Flex>
                                    <Icons color="#ffffff" icon="edit" />
                                    EDIT DATA
                                </Flex>
                            </Button>
                        </Link>
                    </Actions>
                </Col>
            </Row>
        </Main>
    )
}
export interface ActionProps {
    children?: React.ReactNode
}

const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}


const mapStateToProps = (state: any) => ({
    ship: state.ship.selectedShip
})

// connect to shipSetSelectedaction and get the one perticular ship data. 
export default connect(
    mapStateToProps,
    {
        shipSetSelectedAction
    }
)(ClassificationDetail)