import { ofType } from 'redux-observable'
import {
  PAINT_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  paintListGetFAction,
  paintListGetRAction

} from '../action/paintAction'
import paintService from '../../services/paintService'

export const getPaintEpic = (action$ : any) => {
  return action$.pipe(
    ofType(PAINT_LIST_GET),
    switchMap((action : any) => {
      return paintService.getPaint(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in paintEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content paint')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return paintListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(paintListGetRAction(err)))
      )
    })
  )
}