import {
    SLOT_LIST_GET,
}  from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import {
    slotListGetFAction,
    slotListGetRAction,
} from '../action/slotAction'
import slotService from '../../services/slotService'

export const getSlotEpic = (action : any) => {
  // console.log('get role epic called')
  return action.pipe(
    ofType(SLOT_LIST_GET),
    switchMap((action : any) => {
      return slotService.getSlot(action.payload).pipe(
        map((res :any) => {
        // console.log(res,'response for product type epics')
        const { data} = res
        // console.log(data,'product type content')
        return slotListGetFAction({ data})
        }),
        catchError((err : any) => of(slotListGetRAction(err)))
      )
    })
  )
}