import React, { useState } from 'react'
import { Text } from '../../../../common/components/text/Text'
import { Main } from '../../../../common/components/main/Main'
import { Grid } from '../../../../common/components/grid/Grid'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import Card from '../../../../common/components/card/Card'
import { JsxChild } from 'typescript'
import { Row, Form, Col, Button } from 'react-bootstrap'
// import { Input, Radio, Select, Textarea } from '../../../../common/components/control/Control'
import '../../../../common/components/control/Control.scss'
import '../../../../common/components/button/Button.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import { connect } from 'react-redux'
import classNames from 'classnames'
import userService from '../../../../services/userService'
import { useForm } from 'react-hook-form';
import '../Role.scss'
import { history } from '../../../../store';
import { useEffect } from 'react'
import swal from 'sweetalert'
import codeService from '../../../../services/codeService'
import RoleService from '../../../../services/roleService'
import {
    roleListGetAction,
    roleSetSelectedAction
} from '../../../../store/action/roleAction'
import { Link } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import Select from 'react-select';


interface UserEditProps {
    id: any;
    editUserName: string;
    editUserCode: string;
    editUserPage: any;
}
interface FormData {
    txt_username: string;
    txt_usercode: string;
    txt_userpage: string;
};

export const UserRoleEdit = (props: any) => {
    const { match } = props
    const { id } = match.params
    const { user } = props
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    var txt_usernameerr: any;
    var usererror: boolean = false;
    const [isLoading, setLoading] = React.useState(false)
    const [editForm, setForm] = useState<any>({
        name: '',
        code: '',
        pages: [],
        page: '',
        isSaving: false
    })
    const [roleEditflag, roleEditFlag] = React.useState(false)
    const [editUser, setEditUser] = useState<UserEditProps>({
        id: '',
        editUserName: '',
        editUserCode: '',
        editUserPage: ''
    })


    const get_Page = (() => {
        return codeService.getAdminPage().subscribe({
            next: r => {

                console.log(r.data, "Role")
                setForm({ ...editForm, pages: r.data })
                console.log(editForm.pages, 'Form Role')
                // r.data.map((data: any, i: any) => {
                //     return addForm.pages.push(data)
                // })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    const [ids, setIds] = useState<Array<any>>([]);
    React.useEffect(() => {
        get_Page();
        _getUserInfoForView(id)
    }, [])

    const _getUserInfoForView = (id: any) => {
        setLoading(true)
        const { user } = props
        return RoleService.viewRoles(id).subscribe({
            next: r => {
                console.log(r.data, 'data in map company')
                var userRoles = ''
                var createdDate = ''
                // if (r.data.role.length === 0 || r.data.role === null) {
                //     userRoles = ''
                // }
                // else {
                //     userRoles = r.data.role[0].name
                // }
                if (r.data.created_at === null || '') {
                    createdDate = ''
                }
                else {
                    //createdDate = format(parseISO(r.data.created_at), 'dd/MM/yyyy')
                }
                var pages = ''
                if (r.data.admin_pages === null || '') {
                    setIds([''])
                } else {
                    const newIds = [...ids]
                    r.data.admin_pages.map((s: any) => {
                        newIds.push(s.name);
                        //console.log(newIds)
                    })
                    //newIds.push(selectedId);
                    setIds(newIds);

                }

                setEditUser({
                    id: r.data.id,
                    editUserName: r.data.name,
                    editUserCode: r.data.code,
                    editUserPage: ids,

                })
                console.log(ids)
                setLoading(false)
            },
            error: err => {
                console.error(err)
            }
        })
    }
    const selectUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedId = event.target.value;
        console.log(selectedId)
        // Check if "ids" contains "selectedIds"
        // If true, this checkbox is already checked
        // Otherwise, it is not selected yet
        if (ids.includes(selectedId)) {
            const newIds = ids.filter((id) => id !== selectedId);
            setIds(newIds);
        } else {
            const newIds = [...ids];
            newIds.push(selectedId);
            setIds(newIds);
        }

    };

    const _didRoleEdit = () => {
        var pageValue = ['']
        console.log(ids)
        if (ids) {
            pageValue = ids
        } else {
            pageValue = [""]
        }
        const userEditForm = {
            id: Number(editUser.id),
            name: editUser.editUserName,
            code: editUser.editUserCode,
            admin_pages: pageValue
            // addUserCmpType:addUser.addUserCmpType
        }

        // console.log('updated Form',userAddForm)
        console.log(errors, 'errores')
        if (roleEditflag === true) {
            RoleService.updateRole(userEditForm).subscribe({
                next: () => {
                    setLoading(true)
                    swal("Great", "Role Updated Successfully", "success")
                    history.push('/roles')
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    console.error(err)
                    setLoading(false)
                }
            })
        }

    }

    onsubmit = () => {
        // e.preventDefault()
        if (Object.keys(errors).length === 0) {
            _didRoleEdit()
            roleEditFlag(true)
        }
        else {
            roleEditFlag(false)
        }

    }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }
    return (
        <Main>
            <Text variant="title" color='black'>Edit Role Information</Text>
            <Space divheight={25} />
            <Grid templateCols='75% auto' className='add_page'>
                <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='userAddForm'>
                    <Card title="Fill All the Information Below" >
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Role</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_username"
                                            id='txt_username'
                                            required
                                            value={editUser.editUserName}
                                            onChange={(e) => setEditUser({ ...editUser, editUserName: e.target.value })}
                                            placeholder='User Name'
                                        // ref = {register({required:true,pattern:/^[A-Za-z]+$/i, })}
                                        />
                                        {/* <span className='err-msg text-capitalize'>{errors.txt_username?.type === 'required' && 'UserName is Required'}
                                        {errors.txt_username?.type === 'pattern' && 'Invalid userName'}</span> */}
                                    </div>





                                </Attributes>

                            </Col>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>

                                    {/* <div>
                                        <Text color='tablehead' variant='subtitle'>Phone</Text>
                                        <Form.Control
                                            name="txt_phnUser"
                                            id='txt_phnUser'
                                            className='basecontrol custome-input'
                                            value={addUser.addUserPhone}
                                            placeholder='Phone'
                                            // required
                                            onChange={(e) => setAddUser({ ...addUser, addUserPhone: e.target.value })}
                                            type="text"
                                        />
                                    </div> */}

                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Code</Text>
                                        <Form.Control
                                            name="txt_usercode"
                                            className='basecontrol custome-input'
                                            value={editUser.editUserCode}
                                            placeholder='code'
                                            required
                                            onChange={(e) => setEditUser({ ...editUser, editUserCode: e.target.value })}
                                            type="text"
                                        />
                                    </div>

                                    {/* <div>
                                        <Text color='tablehead' variant='subtitle'>Company Address</Text>
                                        <Form.Control as="textarea"
                                            className='basecontrol custome-textarea'
                                            placeholder='Company Address'
                                            rows={3}
                                            cols={30}
                                            name="txt_usercmpaddress"
                                            value={addUser.addUserCmpAddress}
                                            onChange={(e) => setAddUser({ ...addUser, addUserCmpAddress: e.target.value })}
                                        // required
                                        />
                                    </div> */}


                                </Attributes>

                            </Col>

                        </Row>
                        <Row className='m-3 p-1'>
                            <Col xs='12' md='12' lg='2' xl='2' className='p-0 m-0'>


                                <>
                                    <Text color='tablehead' variant='subtitle'>Pages</Text>
                                    {/* <Form.Control
                                       name="txt_usercode"
                                       className='basecontrol custome-input'
                                       value={addUser.addUserPage}
                                       placeholder='position'
                                       // required
                                       onChange={(e) => setAddUser({ ...addUser, addUserPage: e.target.value })}
                                       type="checkbox"
                                   /> */}
                                    {/* {addForm.pages.map((page: any) => 
                                         <input
                                         onChange={() => _toggleSelect(page.id)}
                                         checked={page.checked}
                                         type="checkbox"
                                         value={page.name}
                                     />
                                   )} */}


                                </>


                            </Col>
                            <Col xs='12' md='12' lg='9' xl='9' className='pl-1 ml-3'>


                                <>

                                    {/* <Form.Control
                                       name="txt_usercode"
                                       className='basecontrol custome-input'
                                       value={addUser.addUserPage}
                                       placeholder='position'
                                       // required
                                       onChange={(e) => setAddUser({ ...addUser, addUserPage: e.target.value })}
                                       type="checkbox"
                                   /> */}
                                    {/* {addForm.pages.map((page: any) => 
                                         <input
                                         onChange={() => _toggleSelect(page.id)}
                                         checked={page.checked}
                                         type="checkbox"
                                         value={page.name}
                                     />
                                   )} */}
                                    {editForm.pages.map((data: any, i: number) => (
                                        <Form.Check type='checkbox' id={`data-${i}`} key={i}
                                            name='chk_userpage'
                                            value={data.name}
                                            onChange={selectUser}
                                            checked={ids.includes(data.name) ? true : false}
                                            className='RadioButton'
                                            //onChange={(e) => _toggleSelect(e)}
                                            //onChange={(e) => setAddUser({ ...addUser, addUserPage: e.target.value })}
                                            label={String(data.name)}>
                                        </Form.Check>
                                    ))}

                                </>


                            </Col>

                        </Row>
                    </Card>
                    <Actions>
                        <Link to="/roles">
                            <Button type="button" className='bttn bttn-transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                            </Flex>
                            </Button>
                        </Link>
                        <Button className='bttn bttn-transparent' name='btnAddUser' id='btnAddUser' type="submit">
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
        </Main>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.roles.selectedRoles,
})

export default connect(
    mapStateToProps,
    {
        roleListGetAction,
        roleSetSelectedAction
    }
)(UserRoleEdit)
