import {
    PROVINCES_LIST_GET,
    PROVINCES_LIST_GET_F,
    PROVINCES_LIST_GET_R,
    PROVINCES_SELECTED_SET
} from '../actionType'

const initialState = {
    provinces: [],
    isLoading: false,
    error: null,
    totalPage: 0,
    totalElement: 0,
    selectedProvinces: null,
}

export default function provincesReducer(state = initialState, action: any) {
    switch (action.type) {
        case PROVINCES_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case PROVINCES_LIST_GET_F:
            // console.log('action called')
            // console.log(action.payload.content, 'content')
            return {
                ...state,
                isLoading: false,
                provinces: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
            }
        case PROVINCES_LIST_GET_R:

            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case PROVINCES_SELECTED_SET:
            return {
                ...state,
                selectedProvinces: action.payload
            }
        default:
            return state
    }
}