import {
    USER_LIST_GET_All,
    USER_LIST_GET,
    USER_LIST_GET_F,
    USER_LIST_GET_R,
    USER_SELECTED_SET,
    USER_LIST_GET_ALL_F,
    USER_LIST_GET_ALL_R
  } from '../actionType'

const initialState = {
    allusers:[],
    users: [],
    isLoading: false,
    error: null,
    selectedUser: null,
    totalPage: 0,
    totalElement: 0,
}

export default function userReducer (state = initialState, action:any)  {
    switch(action.type) {
        case USER_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case USER_LIST_GET_All:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case USER_LIST_GET_F:
            var totalrow = 0
           
            if(action.payload.content === undefined || '')
            {
                totalrow = 0
            }
            else{
                action.payload.content.map((data:any) => {
                    totalrow = data.totalrow
                })
            }
            return {
                ...state,
                isLoading: false,
                users: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: totalrow
            }
        case USER_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case USER_LIST_GET_ALL_F:
            return {
                ...state,
                isLoading: false,
                allusers:action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
            }
        case USER_LIST_GET_ALL_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case USER_SELECTED_SET:
            return {
                ...state,
                selectedUser: action.payload
            }
        default:
            return state
    }
}
  