import React, { useState } from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row } from 'react-bootstrap'
import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Flex } from '../../../../common/components/flex/Flex'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import manufacturerService from '../../../../services/manufacturerService'
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import { JsxChild } from 'typescript'
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns'
import { quotationListGetAction, quotationSetSelectedAction } from '../../../../store/action/manufacturerAction';
import { history } from '../../../../store';
// import swal from 'sweetalert'
import '../../../listOfUsers/adduser/userList.scss'
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';

const QuotationDetail = (props: any) => {
    const { match } = props
    const { id } = match.params
    const { traverse } = match.params
    const [isLoading, setLoading] = useState(false)
    const [noUser, setNoUser] = useState(false)
    const [quoteDetail, setQuoteDetail] = useState<any>({
        inqury_id: '',
        company_name: '',
        user_name: '',
        user_email: '',
        user_phone: '',
        user_message: '',
        user_country: '',
        ship_class: '',
        ship_name: '',
        status: '',
        estimated_cost: '',
        owner_id: '',
        ship_length: '',
        ship_draft: '',
        ship_beam: '',
        imo_number: '',
        created_at: '',
    })
    const _getQuotationInformation = (id: any) => {
        setLoading(true)
        return manufacturerService.getQuoteDetail(id).subscribe({
            next: r => {
                setQuoteDetail({
                    company_name: r.data.company_name,
                    user_name: r.data.user_name,
                    user_email: r.data.user_email,
                    user_phone: r.data.user_phone,
                    user_message: r.data.user_message,
                    user_country: r.data.user_country,
                    ship_class: r.data.ship_class,
                    ship_name: r.data.ship_name,
                    status: r.data.status,
                    estimated_cost: r.data.estimated_cost,
                    owner_id: r.data.owner_id,
                    ship_length: r.data.ship_length,
                    ship_draft: r.data.ship_draft,
                    ship_beam: r.data.ship_beam,
                    imo_number: r.data.imo_number,
                    created_at: format(parseISO(r.data.created_at), 'dd/MM/yyyy hh:mm'),
                })
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setNoUser(true)
            }
        })
    }
    React.useEffect(() => {
        const { id } = match.params
        _getQuotationInformation(id)
    }, [])
    const _goback = () => {
        history.push(`/Quotation/list/${traverse}`)
    }
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    return (
        <Main>
            {/* {console.log(quoteDetail, 'quoteDetail')} */}
            {noUser && (
                <>
                    <Space divheight={20} />
                    <Text center> No user Exists or Unable to view the profile </Text>
                    <Space divheight={20} />
                    <Row className='m-0 p-0 text-center'>
                        <Col className='m-0 p-0'>
                            <Link to={`/perViewList/list/${traverse}`}>
                                <Button type="button" variant='transparent'>
                                    <Flex jc='jc-around'>
                                        <Icons icon="arrow_back" />
                                        BACK
                                    </Flex>
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </>
            )}
            {!noUser && (
                <>
                    <div className='title-name-block'>
                        <Text variant='title' color='main' className='txt-capitalize'>
                            Quotation Information
                        </Text>
                    </div>
                    <Space divheight={10} />
                    <Row className='p-0 m-0'>
                        <Col xs='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                            <Card title="user Information">
                                <Row className='m-0 p-0'>
                                    <Col xs='12' md='12' lg='10' xl='10' className='p-0 m-0'>
                                        <Attributes className='single-frame'>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Name</Text>
                                                {quoteDetail.user_name === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{quoteDetail.user_name}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Company Name</Text>
                                                {quoteDetail.company_name === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{quoteDetail.company_name}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Mobile</Text>
                                                {quoteDetail.user_phone === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <>
                                                        {quoteDetail.userPhoneCode === '' || null ? (
                                                            <Text color='main' bold variant='subtitle'>{quoteDetail.userPhoneCode}
                                                                <span>
                                                                    <Text color='main' bold variant='subtitle'>{quoteDetail.user_phone}</Text>
                                                                </span>
                                                            </Text>
                                                        ) : (
                                                            <>
                                                                <Text color='main' bold variant='subtitle'>{quoteDetail.userPhoneCode} &nbsp;
                                                                    <span>
                                                                        <Text color='main' bold variant='subtitle'>{quoteDetail.user_phone}</Text>
                                                                    </span>
                                                                </Text>
                                                            </>
                                                        )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Email</Text>
                                                {quoteDetail.user_email === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle' className='txt-email'>{quoteDetail.user_email}</Text>
                                                )}
                                            </div>

                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Country</Text>
                                                {quoteDetail.user_country === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{quoteDetail.user_country}</Text>
                                                )}
                                            </div>
                                        </Attributes>
                                    </Col>
                                </Row>
                            </Card>
                            <Space divheight={15} />
                            <Card title="Quote Detail">
                                <Row className='m-0 p-0'>
                                    <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='view_Page'>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Estimated Cost</Text>
                                                {quoteDetail.estimated_cost === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <>
                                                        {!String(quoteDetail.estimated_cost).includes('.') && (
                                                            <Text color='main' variant='subtitle' bold>
                                                                <NumberFormat value={String(quoteDetail.estimated_cost)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />.00
                                                            </Text>
                                                        )}
                                                        {String(quoteDetail.estimated_cost).includes('.') && (
                                                            <Text color='main' variant='subtitle' bold>
                                                                <NumberFormat value={quoteDetail.estimated_cost} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                                                            </Text>
                                                        )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Quote For</Text>
                                                {quoteDetail.inquiry_type === 'ANODE_QUOTE_REQUEST' ? (
                                                    <Text color='main' bold variant='subtitle'>Anode</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>Paint</Text>
                                                )}
                                            </div>

                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Ship Name</Text>
                                                {quoteDetail.ship_name === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{quoteDetail.ship_name}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>IMO Number</Text>
                                                {quoteDetail.imo_number === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{quoteDetail.imo_number}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Ship Classification</Text>
                                                {quoteDetail.ship_class === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{quoteDetail.ship_class}</Text>
                                                )}
                                            </div>
                                        </Attributes>

                                    </Col>
                                    <Col>
                                        <Attributes className='view_Page'>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Ship Length</Text>
                                                {quoteDetail.ship_length === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{quoteDetail.ship_length}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Ship Beam</Text>
                                                {quoteDetail.ship_beam === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{quoteDetail.ship_beam}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Ship Draft</Text>
                                                {quoteDetail.ship_draft === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{quoteDetail.ship_draft}</Text>
                                                )}
                                            </div>
                                        </Attributes>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='p-0 m-0'>
                        <Col xs='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                            <Actions>
                                <Button type="button" variant='transparent' onClick={() => _goback()}>
                                    <Flex jc='jc-around'>
                                        <Icons icon="arrow_back" />
                                        BACK
                                    </Flex>
                                </Button>
                            </Actions>
                        </Col>
                    </Row>

                </>
            )}
        </Main>
    )

}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-center' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.manufacturer.selectedQuotation
})

export default connect(
    mapStateToProps,
    {
        quotationSetSelectedAction,
        quotationListGetAction
    }
)(QuotationDetail)