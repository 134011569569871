import classNames from 'classnames';
import React from 'react';
import './label.scss';

//set type for justify content for label
export type JustifyContent = 'jc-start' | 'jc-end' | 'jc-center' | 'jc-between' | 'jc-around';

//set type for align item for label
export type AlignItem = 'ai-start' | 'ai-end' | 'ai-center' | 'ai-baseline' | 'ai-stretch';

//set interface for labelprops
export interface LabelProps {
    values?: string | React.ReactNode;
    children?: React.ReactNode;
    color?: string;
    flex?: JustifyContent;
    className?: string;
}

//Label component 
export const Label = ({ values, color, flex,className, children, ...props }: LabelProps) => {
    const lblClassNames = classNames('label-basic', className);
    const labelcolor = {
        background: color,
    };

    return (
        <label className={lblClassNames} style={labelcolor}>
            {values} {children}
        </label>
    );
};
