import { ofType } from 'redux-observable'
import {
  THICKNESS_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  thicknessListGetFAction,
  thicknessListGetRAction

} from '../action/thicknessAction'
import thicknessService from '../../services/thicknessService'

export const getThicknessEpic = (action$ : any) => {
  return action$.pipe(
    ofType(THICKNESS_LIST_GET),
    switchMap((action : any) => {
      return thicknessService.getThickness(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in thicknessEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return thicknessListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(thicknessListGetRAction(err)))
      )
    })
  )
}