import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import { Radio } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import { connect } from 'react-redux'
import swal from 'sweetalert';
import { paintSetSelectedAction } from '../../../../store/action/paintAction'
import paintService from '../../../../services/paintService'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner'

// Blue print of the class component.  
interface paintEditProps {
    id: string | number;
    Primer: boolean;
    Mid_coat: boolean;
    Finish_coat: boolean;
    Paint_name: string;
    Generic_type: string;
    vs: string | number;
    Price_litre: string | number;
    Description: string;
    Type: string;
    dft: string | number,
    side: string,
    company: '',
    supplier: '',
}
interface FormData {
    radio_editPrimer: string;
    radio_editMid_coat: string;
    radio_editFinish_coat: string;
    txt_editPaint_name: string;
    txt_editGeneric_type: string;
    txt_editvs: string;
    txt_editPrice_litre: string;
    txt_editpaintDescription: string;
    txt_editType: string;
}
const PaintEdit = (props: any) => {
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const [painteditflag, setPainteditFlag] = React.useState(false)
    const [loading, setLoading] = useState(false)
    const { match } = props;
    const { id } = match.params;
    const { cmpid } = match.params;
    const { supid } = match.params;
    const [display, setDisplay] = React.useState<paintEditProps>({
        id: '',
        Primer: false,
        Mid_coat: false,
        Finish_coat: false,
        Paint_name: '',
        Generic_type: '',
        vs: '',
        Price_litre: '',
        Description: '',
        Type: '',
        dft: '',
        side: '',
        company: '',
        supplier: '',
    })
    const [paints, setPaints] = useState<any>({
        isLoading: false,
        isSubmitting: false,
        id: '',
        Primer: false,
        Mid_coat: false,
        Finish_coat: false,
        Paint_name: '',
        Generic_type: '',
        vs: '',
        Price_litre: '',
        Description: '',
        Type: '',
    },
    )


    // Used to get perticular ship detail and store to variable.   
    const _getPaintDetail = ((id: string | number, compId: string | number, suppId: string | number) => {
        setLoading(true)
        return paintService.getPaintDetail({ company_id: compId, paint_id: id, supplier_id: suppId }).subscribe({
            next: r => {
                // console.log(r, 'response')
                // console.log(r.data, 'data within response')
                const value = r.data
                // console.log(value, 'data within response using value')
                setDisplay({
                    id: Number(r.data.paint_id),
                    Primer: r.data[0].primer,
                    Mid_coat: r.data[0].midcoat,
                    Finish_coat: r.data[0].finishcoat,
                    Paint_name: r.data[0].paintname,
                    Generic_type: r.data[0].generictype,
                    vs: r.data[0].vs,
                    Price_litre: r.data[0].pricelitre,
                    Description: r.data[0].description,
                    Type: r.data[0].type,
                    side: r.data[0].side,
                    dft: r.data[0].dft,
                    company: r.data[0].companyname,
                    supplier: r.data[0].suppliername,
                })
                props.paintSetSelectedAction(value)
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    })
    React.useEffect(() => {
        _getPaintDetail(id, cmpid, supid)
    }, [])

    // After update all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call.  
    const _didPaintEdit = () => {
        // e.preventDefault()
        const updatedForm = {
            supplier_id: supid,
            company_id: cmpid,
            primer: display.Primer,
            mid_coat: display.Mid_coat,
            generic_type: display.Generic_type,
            finish_coat: display.Finish_coat,
            paint_name: display.Paint_name,
            vs: display.vs,
            price_litre: display.Price_litre,
            description: display.Description,
            paint_type: display.Type,
            side: [display.side],
            dft: display.dft,
            paint_id: id
        }

        // API call for updateship with data, updated into list page.  
        if (painteditflag === true) {
            setLoading(true)
            paintService.updatePaint(updatedForm).subscribe({
                next: () => {
                    swal("Great", "Paint Updated Successfully", "success")
                    history.push('/paint/list')
                    setLoading(false)
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    console.error(err)
                    setLoading(false)
                }
            })
        }
        else {
            console.log(painteditflag, 'flag')
        }
    }

    onsubmit = (e: any) => {
        // e.preventDefault()
        console.log(Object.keys(errors).length, 'error length')
        if (Object.keys(errors).length === 0) {
            _didPaintEdit()
            setPainteditFlag(true)
        }
        else {
            setPainteditFlag(false)
        }
    }

    if (loading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }


    return (
        <Main>
            <Text color='black' variant="title">Edit paint Information</Text>
            <Space divheight={25} />
            {/* Edit form, having input box, select box.   */}
            <Grid templateCols='75% auto' className='add_page'>
                <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='paintEditForms'>
                    <Card title="Fill All the Information Below">
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Paint Name</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_editPaint_name"
                                            value={display.Paint_name}
                                            onChange={(e) => setDisplay({ ...display, Paint_name: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="paint Name"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_editPaint_name?.type === 'required' && 'Please enter paint name'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Paint Type</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_editType"
                                            value={display.Type}
                                            onChange={(e) => setDisplay({ ...display, Type: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="paint Type"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_editType?.type === 'required' && 'Please enter paint Type'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Generic Type</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_editGeneric_type"
                                            value={display.Generic_type}
                                            onChange={(e) => setDisplay({ ...display, Generic_type: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="Generic Type"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_editGeneric_type?.type === 'required' && 'Please enter Generic Type'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Price Per Litre (Rp.)</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="number"
                                            name="txt_editPrice_litre"
                                            value={display.Price_litre}
                                            onChange={(e) => setDisplay({ ...display, Price_litre: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="Price"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_editPrice_litre?.type === 'required' && 'Please enter Price'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes >
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >VS %</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="number"
                                            name="txt_editvs"
                                            value={display.vs}
                                            onChange={(e) => setDisplay({ ...display, vs: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="VS %"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_editvs?.type === 'required' && 'Please enter vs'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Description</Text>
                                        <Form.Control as="textarea"
                                            className='basecontrol custome-textarea'
                                            placeholder='Description'
                                            rows={3}
                                            cols={50}
                                            name="txt_editpaintDescription"
                                            value={display.Description}
                                            onChange={(e) => setDisplay({ ...display, Description: e.target.value })}
                                            ref={register({ required: true, })}
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_editpaintDescription?.type === 'required' && 'Please enter Description'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Coating Type</Text>
                                        <Form.Control as="select" className='basecontrol cutome-selectInputnxt'
                                            value={display.side} name="txt_supeditcmpstatus"
                                            onChange={(e) => setDisplay({ ...display, side: e.target.value })}
                                            ref={register({ required: true, })}>
                                            <option className='option-placeholder' value=''>-- Select --</option>
                                            <option value="Top Side"> Top Side</option>
                                            <option value="Bottom Side"> Bottom Side</option>
                                            <option value="Top,Bottom">Both</option>
                                        </Form.Control>
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                <Attributes>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle'>Primer</Text>
                                        <div>
                                            <Radio type='radio' id='primer' name='primer' value={display.Primer}
                                                onChange={(e) => setDisplay({ ...display, Primer: true })}
                                                checked={display.Primer === true}
                                                label='Yes'></Radio>
                                            <Radio type='radio' id='nonPrimer' name='primer' value={display.Primer}
                                                onChange={(e) => setDisplay({ ...display, Primer: false })}
                                                checked={display.Primer === false}
                                                label='No'></Radio>
                                        </div>
                                    </div>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Mid Coat</Text>
                                        <div>
                                            <Radio type='radio' id='midCoat' name='midCoat' value={display.Mid_coat}
                                                onChange={(e) => setDisplay({ ...display, Mid_coat: true })}
                                                checked={display.Mid_coat === true}
                                                label='Yes'></Radio>
                                            <Radio type='radio' id='nonMidCoat' name='midCoat' value={display.Mid_coat}
                                                onChange={(e) => setDisplay({ ...display, Mid_coat: false })}
                                                checked={display.Mid_coat === false}
                                                label='No'></Radio>
                                        </div>
                                    </div>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Finishing Coat</Text>
                                        <div>
                                            <Radio type='radio' id='finish' name='finishCoat' value={display.Finish_coat}
                                                onChange={(e) => setDisplay({ ...display, Finish_coat: true })}
                                                checked={display.Finish_coat === true}
                                                label='Yes'></Radio>
                                            <Radio type='radio' id='nonfinish' name='finishCoat' value={display.Finish_coat}
                                                onChange={(e) => setDisplay({ ...display, Finish_coat: false })}
                                                checked={display.Finish_coat === false}
                                                label='No'></Radio>
                                        </div>
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                        <Row className='m-0 p-0'>
                            <Col className='m-0 p-0' xs='12' md='12' lg='12' xl='12'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Supporting Owner</Text>
                                        {display.supplier !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.supplier}</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold > - </Text>
                                        )}
                                        {/* <input className='basecontrol cutome-selectInputnxt'
                                            value={anodes.supplier} id="txt_Editsupplier"
                                            list='anodesupplier'
                                            placeholder='-- Select Supplier --'
                                            name="txt_Editsupplier" ref={register({ required: true, })}
                                            onChange={(e) => _getCompanyDetail(e.target.value)}>
                                        </input>
                                        <datalist id="anodesupplier">
                                            {anodes.suppliers.map((data: any, i: any) => {
                                                return <option key={i} value={data.name}></option>
                                            })}
                                        </datalist> */}
                                    </div>
                                    {/* <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_Editsupplier?.type === 'required' && 'Please Select Supporting Owner'}
                                            </span>
                                        </Col>
                                    </Row> */}
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Company Name</Text>
                                        {display.company !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.company}</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold > - </Text>
                                        )}
                                        {/* <input className='basecontrol cutome-selectInputnxt'
                                            ref={register({ required: true, })}
                                            value={anodes.company}
                                            id='txt_Editcompany' list='company-details'
                                            placeholder='-- Select Supplier First --'
                                            name="txt_Editcompany" onChange={(e) => setAnode({ ...anodes, company: e.target.value })} >
                                        </input>
                                        <datalist id="company-details">
                                            {anodes.companies.length === 0 && (
                                                <option className='option-placeholder'> --NO Company for selected Supplier-- </option>
                                            )}
                                            {anodes.companies.map((data: any, i: any) => {
                                                return <option key={i} value={data.name}></option>
                                            })}
                                        </datalist> */}
                                    </div>
                                    {/* <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_Editcompany?.type === 'required' && 'Please select Company'}
                                            </span>
                                        </Col>
                                    </Row> */}
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                    <Actions>
                        {/* If you click the back button its is redirect to paints list page.   */}
                        <Link to='/paint/list'>
                            <Button type="button" className='bttn bttn-transparent' >
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the save button its is call the function, in that data updated to list and redirect to list page.   */}
                        <Button className='bttn bttn-transparent' type="submit">
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
        </Main>
    )
}


export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style.  
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-ship-add');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}

// Button style.  
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    paint: state.paint.selectedPaint
})
//Return the selected ship details.  
export default connect(
    mapStateToProps,
    {
        paintSetSelectedAction
    }
)(PaintEdit)
