import classNames from 'classnames'
import React from 'react'
import './Colapse.scss'

//Interface for collaps component. declare what are the props are going to use inside collapse component.
export interface collapsProps{
    className ? :string
    children ? : any
    open ? : any
}

//Collapse component
const Collapse = (props : collapsProps) => {
  const [isOpen, setOpen] = React.useState(false)
  const [toggle, collapsible] = props.children
  const gridClassNames = classNames(props.className);
  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
    <div className={gridClassNames}>
      <div>{toggle}
        
      </div>
      {/* list of item shows inside the ships and dock menus in sidebar  */}
      <CollapsePanel open={isOpen}>{collapsible}</CollapsePanel>
    </div>
  )
}

export interface MainProps {

}

//collapse Panel component & list of item shows inside the ships and dock menus in sidebar
export const CollapsePanel = (props: collapsProps) => {
    var Height = props.open
    var collapseHeight : any;
    if(Height){
      collapseHeight = {
          height : 'auto',
          overflow : 'hidden'
      }
    }
    else {
      collapseHeight = {
        height : '0px',
        overflow : 'hidden'
      }
    }
    
    return (
        <div className ='sub_list' style={collapseHeight}>
            {props.children}
        </div>
    )
}

export default Collapse
