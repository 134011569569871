import { timeout, map } from 'rxjs/operators'
import http from '../helpers/http';

const getDashboardDetails = (params : any) => {
    return http({
      method: 'GET',
      url: `/manufacturer/dashBoard/${params}`,
    }).pipe(
      map(res => {
          console.log(res);
        if (res.success) {
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }

  const dashboardService = {
    getDashboardDetails,
  }
  
  export default dashboardService
  