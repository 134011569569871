import React, { useState } from 'react'

const imageFilter = {
    MultipleImage:'',
    MultipleImages:[""]
}



export const FilterState = {
    imageFilter
}