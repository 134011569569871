import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import { format, parseISO } from 'date-fns'
import { Input, Radio, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import { connect } from 'react-redux'
import swal from 'sweetalert';
import { subscriptionSetSelectedAction } from '../../../../store/action/subscriptionAction'
import subscriptionService from '../../../../services/subscriptionService'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner'

// Blue print of the class component.  
interface subscriptionEditProps {
    isactive: boolean;
    planName: string;
    description: string;
    amount: string;
    duration: string | number;
}
interface FormData {
    txt_editsubname: string;
    txt_editsubdescription: string,
    txt_editduration_in_month: string,
    txt_editsubprice: string,
    radio_subisactive: boolean
}

const SubscriptionEdit = (props: any) => {
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const [status, setStatus] = React.useState<subscriptionEditProps>({
        isactive: false,
        planName: '',
        description: '',
        amount: '',
        duration: '',
    })
    const { subscription, match } = props
    const [isLoading, setLoading] = React.useState(false)
    const [subsEditflag, setSubEditFlag] = React.useState(false)
    // Used to get perticular ship detail and store to variable.   
    const _getSubscriptionDetail = ((id: any) => {
        setLoading(true)
        return subscriptionService.getSubscriptionDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.subscriptionSetSelectedAction(data)
                console.log(r.data)
                setStatus({
                    planName: r.data.name,
                    description: r.data.description,
                    amount: r.data.price,
                    isactive: r.data.isactive,
                    duration: r.data.durationInMonth
                })
                console.log(status.isactive, 'is active')
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    })
    React.useEffect(() => {
        const { id } = match.params
        _getSubscriptionDetail(id)
    }, [])

    // After update all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call.  
    const _didsubscriptionEdit = () => {
        const { id } = match.params
        const updatedForm = {
            id: id,
            name: status.planName,
            description: status.description,
            duration_in_month: status.duration,
            price: status.amount,
            isactive: status.isactive
        }

        // API call for updateship with data, updated into list page.  
        if (subsEditflag === true) {
            setLoading(true)
            subscriptionService.updateSubscription(updatedForm).subscribe({
                next: () => {
                    swal("Great", "Subscription Updated Successfully", "success")
                    history.push('/subscription/list')
                    setLoading(false)
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    setLoading(false)
                    console.error(err)
                }
            })
        }
        else {
            console.log(subsEditflag, 'edit flag')
        }
    }

    onsubmit = (e: any) => {
        // e.preventDefault()
        console.log(Object.keys(errors).length, 'error length')
        if (Object.keys(errors).length === 0) {
            _didsubscriptionEdit()
            setSubEditFlag(true)
        }
        else {
            setSubEditFlag(false)
        }
    }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }
    return (
        <Main>
            <Text color='black' variant="title">Edit Subscription Information</Text>
            <Space divheight={25} />
            <Grid templateCols='75% auto' className='view_Page'>
                <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='subsEditForm'>
                    <Card title="Fill All the Information Below">
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div>
                                        <Text color='main' variant='subtitle'>Plan Name</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_editsubname"
                                            value={status.planName}
                                            onChange={(e) => setStatus({ ...status, planName: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="Plan Name"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_editsubname?.type === 'required' && 'Please enter Plan name'}
                                            </span>
                                        </Col>
                                    </Row>
                                    {/* <div>
                                    <Text color='main' variant='subtitle'>Created Date</Text>
                                    {display.created_at === null|| '' ? (
                                    <Text color='main' variant='subtitle' bold>--</Text>
                                    ) : (
                                    <Text color='main' variant='subtitle' bold>{format(parseISO(display.created_at), 'dd/MM/yyyy')}</Text>
                                    )}
                                </div> */}
                                    <div>
                                        <Text color='main' variant='subtitle'>Description</Text>
                                        <Form.Control as="textarea"
                                            className='basecontrol custome-textarea'
                                            placeholder='Description'
                                            name="txt_editsubdescription"
                                            rows={3}
                                            cols={50}
                                            value={status.description}
                                            onChange={(e) => setStatus({ ...status, description: e.target.value })}
                                            ref={register({ required: true, })}
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_editsubdescription?.type === 'required' && 'Please enter Description'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='main' variant='subtitle'>Amount</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            name="txt_editsubprice"
                                            value={status.amount}
                                            ref={register({ required: true, })}
                                            type='number'
                                            placeholder="Amount"
                                            onChange={e => setStatus({ ...status, amount: e.target.value })}
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_editsubprice?.type === 'required' && 'Please enter Price'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='main' variant='subtitle'>Duration in Days</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            name="txt_editduration_in_month"
                                            value={status.duration}
                                            ref={register({ required: true,})}
                                            type='number'
                                            placeholder="Duration in Days"
                                            onChange={e => setStatus({ ...status, duration: e.target.value })}
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_editduration_in_month?.type === 'required' && 'Please enter Price'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div className='sm-mt-3'>
                                        <Text color='main' variant='subtitle'>System Access</Text>
                                        <div>
                                            <Radio type='radio' id='active' name='status' value={status.isactive}
                                                checked={status.isactive === true}
                                                onChange={(e) => setStatus({ ...status, isactive: true })}
                                                label='ACTIVE'>
                                            </Radio>
                                            <Radio type='radio' id='inactive' name='status' value={status.isactive}
                                                checked={status.isactive === false}
                                                onChange={(e) => setStatus({ ...status, isactive: false })}
                                                label='IN ACTIVE'>
                                            </Radio>
                                        </div>
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                    <Actions>
                        {/* If you click the back button its is redirect to ships list page.   */}
                        <Link to='/subscription/list'>
                            <Button className='bttn bttn-transparent'>
                                <Flex>
                                    <Icons color="#ffffff" icon="arrow_back" />
                                    Back
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the save button its is call the function, in that data updated to list and redirect to list page.   */}
                        <Button className='bttn bttn-transparent' type="submit">
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
            {/* Edit display, having input box, select box.   */}
        </Main >
    )
}


export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style.  
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-ship-add');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}

// Button style.  
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    subscripton: state.subscription.selectedSubscription
})
//Return the selected ship details.  
export default connect(
    mapStateToProps,
    {
        subscriptionSetSelectedAction
    }
)(SubscriptionEdit)
