import { defer, from } from 'rxjs';
import * as config from '../config';
import authService from '../services/authService';

const encodeQueryParams = (params: any) => {
    // console.log('param called')
    return Object.entries(params)
        .filter(([, value]) => {
            if (value === '') {
                return false;
            }
            if (Array.isArray(value) && value.length === 0) {
                return false;
            }
            return true;
        })
        .map((kv) =>
            kv
                .map((data) => {
                    return encodeURIComponent(String(data));
                })
                .join('=')
        )
        .join('&');
};

const httpDelete = (props: any) => {
    // console.log('http called')
    return defer(() => {
        const baseUrl = config.API_URL;
        let url = baseUrl + props.url;

        const options: RequestInit = {
            method: props.method || 'GET',
            headers: props.headers || {
                'Content-type': 'application/json; charset=UTF-8',
                Accept: 'application/json',
                Authorization: 'Bearer ' + authService.getToken() || null,
            },
        };
        if (props.body) {
            if (props.isMultipart) {
                options['body'] = props.body;
            } else {
                options['body'] = JSON.stringify(props.body);
            }
        }
        if (props.params) {
            url += `?${encodeQueryParams(props.params)}`;
        }
        return from(fetch(url, options).then((res) => res.text));
    });
};

export default httpDelete;
