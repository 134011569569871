import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import swal from 'sweetalert';
import propulsionService from '../../../../services/propulsionSevice'
import '../../../../styles/variables/Common.scss'
import { useForm } from 'react-hook-form'


interface PropulsionProps {
  name: string;
  code: string;
}
interface FormData {
  txt_name: string;
  txt_code: string;
}
export const PropulsionAdd = (props: PropulsionProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const [propulsionAddflag, setpropulsionAddFlag] = React.useState(false)
  const [propulsion, setPropulsion] = useState<PropulsionProps>({
    name: '',
    code: '',
  },
  )

  // After enter all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call. 
  const _didPropulsionAdd = () => {
    // e.preventDefault()
    const updatedForm = {
      name: propulsion.name,
      code: propulsion.code,
    }

    // API call for addship with data, added to list. 
    // console.log(updatedForm,"updatedform")
    if (propulsionAddflag === true) {
      propulsionService.addpropulsion(updatedForm).subscribe({
        next: (r) => {
          // console.log(r,'response')
          swal("Great", "Propulsion type Updated Successfully", "success")
          history.push('/propulsion/list')
        },
        error: err => {
          swal("OOPS", String(err), "error")
        }
      })
    }
    else {
      console.log(propulsionAddflag)
    }
  }

  onsubmit = (e: any) => {
    // e.preventDefault()
    console.log(Object.keys(errors).length, 'error length')
    if (Object.keys(errors).length === 0) {
      _didPropulsionAdd()
      setpropulsionAddFlag(true)
    }
    else {
      setpropulsionAddFlag(false)
    }
  }

  return (
    <Main>
      <Text variant="title" color='black'>Add Propulsion Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='propulsionAddForms'>
          <Card title="Fill All the Information Below">
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >name</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_name"
                      value={propulsion.name}
                      onChange={(e) => setPropulsion({ ...propulsion, name: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="name"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_name?.type === 'required' && 'Please enter Name'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Code</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_code"
                      value={propulsion.code}
                      onChange={(e) => setPropulsion({ ...propulsion, code: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="code"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_code?.type === 'required' && 'Please enter Code'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
            </Row>
          </Card>
          <Actions>
            {/* If you click the back button its is redirect to ships list page.  */}
            <Link to='/propulsion/list'>
              <Button className='bttn bttn-transparent'>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page.  */}
            <Button className='bttn bttn-transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </Form>
      </Grid>
      {/* Add form, having input box, select box.  */}
    </Main>
  )
}


export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-ship-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. 
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}
