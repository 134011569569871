import React from 'react';
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap';
import './Paint.scss';
import '../../../common/components/pagination/Pagination.scss'
import { Text } from '../../../common/components/text/Text'
import { Space } from '../../../common/components/space/Space';
import { Main } from '../../../common/components/main/Main';
import { Button } from '../../../common/components/button/Button';
import Card from '../../../common/components/card/Card';
import { Table } from '../../../common/components/table/Table';
import Icons from '../../../common/components/icon/Icon';
// import { format, parseISO } from 'date-fns'
import * as CurrencyFormat from 'react-currency-format';
import NumberFormat from 'react-number-format';
import { paintListGetAction, paintSetSelectedAction } from "../../../store/action/paintAction";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { history } from '../../../store';
import { Flex } from '../../../common/components/flex/Flex';
import { ReactNode } from 'react'
import swal from 'sweetalert'
import paintService from '../../../services/paintService';
import Loader from 'react-loader-spinner';
import { InputWithIcon, Select } from '../../../common/components/control/Control';
import { PaintfilterState } from './filter/PaintfilterState';

const PaintList = (props: any) => {
  const { paint, isLoading, totalElement } = props
  const [displayPaint, setPaint] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [searchQuery, setQuery] = React.useState('')
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  const [showDelete, setShow] = React.useState(false);
  const [deletePaint, setDeletePaint] = React.useState<any>([])
  const [findBy, setFindBy] = React.useState(PaintfilterState.Paintfilter.findbyIdname)
  const [searchPaintCmp, setSearchPaintCmp] = React.useState(PaintfilterState.Paintfilter.searchBoxInputCmp)
  const [searchPaintSupplier, setSearchPaintSupplier] = React.useState(PaintfilterState.Paintfilter.searchBoxInputOwner)
  const [searchInput, setSearchInput] = React.useState(PaintfilterState.Paintfilter.searchBoxInputName)

  //local storage
  const role = localStorage.getItem("role")

  // Used to get type and payload, call action and get list.   
  React.useEffect(() => {
    props.paintListGetAction({ size: 10, page, name: searchInput||PaintfilterState.Paintfilter.searchBoxInputName, company_name: searchPaintCmp||PaintfilterState.Paintfilter.searchBoxInputCmp, supplier_name: searchPaintSupplier||PaintfilterState.Paintfilter.searchBoxInputOwner })
  }, [page])

  // Check ships(list) length, add checked false in that array.  
  React.useEffect(() => {
    // console.log(paint, 'ship type in ship type lisst page')
    if (paint && paint.length > 0) {
      setPaint(() => {
        return paint.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (paint && paint.length === 0) {
      setPaint([])
    }
  }, [paint])

  // Get totalPage in props and push into the pagination variable.   
  React.useEffect(() => {
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  // Redirect to ship detail page.  
  const _willViewDetail = (paint: any) => {
    paintSetSelectedAction(paint)
    var paintCmpId: string | number;
    var paintSupId: string | number;
    if (paint.company !== null) {
      paintCmpId = paint.company.id
    }
    else {
      paintCmpId = ''
    }
    if (paint.supplier !== null) {
      paintSupId = paint.supplier.id
    }
    else {
      paintSupId = ''
    }
    history.push(`/paint/${paint.id}/${paintCmpId}/${paintSupId}`)
  }

  // Redirect to ship edit page.  
  const _willViewEdit = (paint: any) => {
    props.paintSetSelectedAction(paint)
    var paintCmpId: string | number;
    var paintSupId: string | number;
    var paintSupName: string;
    if (paint.company !== null) {
      paintCmpId = paint.company.id
    }
    else {
      paintCmpId = ''
    }
    if (paint.supplier !== null) {
      paintSupId = paint.supplier.id
      paintSupName = paint.supplier.name
    }
    else {
      paintSupId = '';
      paintSupName = '';
    }
    history.push(`/paint/edit/${paint.id}/${paintCmpId}/${paintSupId}/${paintSupName}`)
  }

  // Delete the pericular list.  
  const handleClose = () => setShow(false);
  const handleShow = (paint: any) => {
    setShow(true);
    setDeletePaint(paint)
    // console.log(deleteAnode, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deletePaint(deletePaint)
  }

  const _deletePaint = (paintDel: any) => {
    var paintCmpId: string | number;
    var paintSupId: string | number;
    if (paintDel.company !== null) {
      paintCmpId = paintDel.company.id
    }
    else {
      paintCmpId = ''
    }
    if (paintDel.supplier !== null) {
      paintSupId = paintDel.supplier.id
    }
    else {
      paintSupId = ''
    }
    return paintService.deletePaint({ supplier_id: paintSupId, paint_id: paintDel.id, company_id: paintCmpId }).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.paintListGetAction({ size: 10, page })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }


  //Search Function

  const _getSearchValue = (val: string) => {
    setFindBy(val)
    PaintfilterState.Paintfilter.findbyIdname=val;
  }

  const _searchFuntion = (value: string) => {
    setSearchInput(value)
    PaintfilterState.Paintfilter.searchBoxInputName=value;
    props.paintListGetAction({ size: 10, page, name: value, company_name: searchPaintCmp, supplier_name: searchPaintSupplier })

    setPage(0)
  }

  const _SearchPaintCmpFunc = (value: string) => {
    setSearchPaintCmp(value)
    PaintfilterState.Paintfilter.searchBoxInputCmp=value;
    props.paintListGetAction({ size: 10, page, name: searchInput, company_name: value, supplier_name: searchPaintSupplier })

  }
  const _SerachPaintSupFunc = (value: string) => {
    setSearchPaintSupplier(value)
    PaintfilterState.Paintfilter.searchBoxInputOwner=value;
    props.paintListGetAction({ size: 10, page, name: searchInput, company_name: searchPaintCmp, supplier_name: value })

  }

  const _showAll = () => {
    setSearchInput('')
    setSearchPaintCmp('')
    setSearchPaintSupplier('')
    PaintfilterState.Paintfilter.searchBoxInputCmp='';
    PaintfilterState.Paintfilter.searchBoxInputOwner='';
    PaintfilterState.Paintfilter.searchBoxInputName='';
    PaintfilterState.Paintfilter.findbyIdname='';
    props.paintListGetAction({ size: 10, page, name: '', company_name: '', supplier_name: '' })

  }

  // Prints first page pagination.  
  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  // While click the last page its move to last page.  
  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 9)
  }

  // While click the first button its move to first page.  
  const _goToFirstPage = () => {
    setPage(0)
    setHigh(9)
    setLow(1)
  }

  // Prints last page pagination button.  
  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  // Prints prev button.  
  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  // While click prev button go to the previous page of current page.  
  const _goPrevPage = () => {
    setPage(prev => {
      const newValue = prev - 1
      return newValue
    })
  }

  // Print next button.  
  const nextBtn = () => {
    if (page < totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  // While click next page button go to the next page of the current page.  
  const _goNextPage = () => {
    setPage(prev => {
      const newValue = prev + 1
      return newValue
    })
  }

  // While click one page number its move to that page.  
  const _goToPage = (page: any) => {
    setPage(page)
  }

  // Return the page number
  const ListItem = (page: any) => {
    return page.value + 1
  }

  // Prints the totalpages between prev, next button.  
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })

  return (
    <Main>
      <Text variant="title" color='black'>Paint List</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='3' className='m-0 p-0'>
              <div className='mr-2 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    {findBy === 'name' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Product.."
                        icon="search"
                        id="search-inputanode"
                      />
                    )}
                    {findBy === '' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Product.."
                        icon="search"
                        id="search-inputanode"
                      />
                    )}
                    {findBy === 'company' && (
                      <InputWithIcon
                        type="text"
                        value={searchPaintCmp}
                        onChange={(e) => _SearchPaintCmpFunc(e.target.value)}
                        placeholder="Search by Company..."
                        icon="search"
                        id="search-inputAnodeCmp"
                      />
                    )}
                    {findBy === 'sup' && (
                      <InputWithIcon
                        type="text"
                        value={searchPaintSupplier}
                        onChange={(e) => _SerachPaintSupFunc(e.target.value)}
                        placeholder="Search by Owner..."
                        icon="search"
                        id="search-inputAnodeSup"
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <Row className='m-0 p-0'>
                <Col xs='6' sm='4' md='4' lg='5' xl='4' className='m-0 p-0 mr-2'>
                  <Select values={findBy} name="" onChange={(e) => _getSearchValue(e.target.value)}>
                    <option value=''>-- Search by --</option>
                    <option value="name"> By Product Name </option>
                    <option value="company"> By Company </option>
                    <option value="sup"> By Owner </option>
                  </Select>
                </Col>
                <Col xs='5' sm='4' md='4' lg='7' xl='7' className='m-0 p-0 '>
                  <div>
                    {role === 'VISITOR' ? (
                      //                    <Link to='/paint/add'>   {/* Button add new ship, if you click the button it shows modal box.   */}
                      <Button variant='transparent' block={true} disabled>
                        <Icons color="#ffffff" icon="add" />
                        ADD NEW PAINT
                      </Button>
                      //                    </Link>
                    ) : (
                      //Temproray disabled
// <Link to='/paint/add'>   {/* Button add new ship, if you click the button it shows modal box.   */}
                        <Button variant='transparent' block={true} disabled>
                          <Icons color="#ffffff" icon="add" />
                          ADD NEW PAINT
                        </Button>
  // </Link>
                    )}
                    <Button className='ml-2' variant='transparent' block={true} onClick={() => _showAll()}>
                      Show All
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SearchForm>
        <Space divheight={30} />
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>} {/* Check the list is loading, isloading is true its shows loading text.  */}
        {!isLoading && displayPaint.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No Paint Detail found</Text>
            <Space divheight={20} />
          </>
        )} {/* If isloading is false and also the list array length is 0 its shows no discount fount text.   */}
        {!isLoading && displayPaint.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Paint Name</th>
                  {/* <th>Generic Type</th> */}
                  <th>Type</th>
                  <th>Side</th>
                  <th>Price</th>
                  <th>Company Name</th>
                  <th>Owner Name</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Map the list array and display the datas in a table.   */}

                {/* {console.log(displayPaint,'display paint')} */}
                {displayPaint.map((data: any, i: any) => (
                  <tr key={data.id}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='Paint Name'>
                      {data.name === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{data.name}</Text>
                      )}
                    </td>
                    {/* <td data-label='Generic Type'>
                      {data.generic_type === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>

                      ):(
                        <Text className='txt-table-date' color='tabledata'>{data.generic_type}</Text>
                      )}
                    </td> */}
                    <td data-label='Paint Type'>
                      {data.type === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>

                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{data.type}</Text>

                      )}
                    </td>
                    <td data-label='Side'>
                      {data.side === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{data.side}</Text>
                      )}
                    </td>
                    <td data-label='Price'>
                      {data.price_litre === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <>
                          {!String(data.price_litre).includes('.') && (
                            <Text className='txt-table-date' color='tabledata'>
                              {/* Rp. {data.price_litre} */}
                              <NumberFormat value={String(data.price_litre)} displayType={'text'} thousandSeparator={true} prefix={String(data.currency_code)+' '} />.00
                            </Text>
                          )}
                          {String(data.price_litre).includes('.') && (
                            <Text className='txt-table-date' color='tabledata'>
                              {/* Rp. {data.price_litre} */}
                              <NumberFormat value={data.price_litre} displayType={'text'} thousandSeparator={true} prefix={String(data.currency_code)+' '} />
                            </Text>
                          )
                          }
                        </>
                      )}
                    </td>
                    <td data-label='Company Name'>
                      {data.company === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{data.company.name}</Text>
                      )}
                    </td>
                    <td data-label='Owner Name'>
                      {data.supplier === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <>
                          {String(data.supplier.name).includes('@') && (
                            <Text className='txt-table-date' color='tabledata'>
                              {String(data.supplier.name).substring(0, String(data.supplier.name).indexOf('@'))}
                            </Text>
                          )}
                          {!String(data.supplier.name).includes('@') && (
                            <Text className='txt-table-date' color='tabledata'>{data.supplier.name}</Text>
                          )
                          }
                        </>
                      )}
                    </td>
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="paint-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(data)}> {/* While onclick open view page.   */}
                            <Icons color="#52575a" icon="visibility" /> {/* View icon.  */}
                          </Button>
                        </OverlayTrigger>
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="paint-edit-visitor"> Edit option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(data)} disabled> {/* While onclick open edit page.   */}
                              <Icons color="#52575a" icon="edit" /> {/* Edit icon.  */}
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="paint-edit"> Edit option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(data)} disabled> {/* While onclick open edit page.   */}
                              <Icons color="#52575a" icon="edit" /> {/* Edit icon.  */}
                            </Button>
                          </OverlayTrigger>
                        )}
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="paint-delete-visitor"> Delete option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(data)} disabled>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="paint-delete"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(data)}>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </TableActions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text> {/* Display the total elements.  */}
                {displayPaint.length === 1 && (
                  <>
                    <b>
                      {displayPaint.length}
                      {/* {((page) * 10) + 1} - {(displayPaint.length + (page) * 10)} */}
                    </b> of {totalElement} Paint
                  </>
                )}
                {displayPaint.length > 1 && (
                  <>
                    <b>
                      {displayPaint.length}
                      {/* {((page) * 10) + 1} - {(displayPaint.length + (page) * 10)} */}
                    </b> of {totalElement} Paints
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()} {/* First page button.   */}
                {prevBtn()} {/* Previous button.   */}
                {PageNumber} {/* Totalpages.   */}
                {nextBtn()} {/* Next button.   */}
                {lastPage()} {/* Last page button.   */}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {/* If modal is true goto the delete page with the props of onclose function */}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form Paint list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Main>
  )
}
export interface TablefooterProps {
  children?: React.ReactNode
}

// Table footer style.  
const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

// Table body style.  
const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

//Search Form Component  for displaying serarch box, and add ship button
const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

// Declare the variable.  
const mapStateToProps = (state: any) => ({
  paint: state.paint.paint,
  isLoading: state.paint.isLoading,
  error: state.paint.error,
  totalElement: state.paint.totalElement,
  totalPage: state.paint.totalPage
})

// Connect to the action and get the list, totalpage and total elements.  
export default connect(
  mapStateToProps,
  {
    paintListGetAction,
    paintSetSelectedAction,
  }

)(PaintList)