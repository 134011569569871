import React, { ReactNode, useCallback } from 'react'
import { Text } from '../../../common/components/text/Text'
import { Main } from '../../../common/components/main/Main'
import { InputWithIcon } from '../../../common/components/control/Control'
import { Button } from '../../../common/components/button/Button'
import Card from '../../../common/components/card/Card'
import Icons from '../../../common/components/icon/Icon'
import { Flex } from '../../../common/components/flex/Flex'
import { Space } from '../../../common/components/space/Space'
import { Table } from '../../../common/components/table/Table'
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap'
import '../../../common/components/pagination/Pagination.scss'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import {
  roleListGetAction,
  roleSetSelectedAction
} from '../../../store/action/roleAction'
import RolesAdd from './pageContainer/RolesAdd'
import { useState } from 'react'
import swal from 'sweetalert'
import RoleService from '../../../services/roleService'
import Loader from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import { history } from '../../../store'

const RoleList = (props: any) => {
  const { roles, isLoading, totalElement } = props
  const [displayRoles, setRoles] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [showModal, setModal] = React.useState(false)
  const [pagination, setPagination] = React.useState([{}])
  const [isEdit, setIsEdit] = useState(false)
  const [isview, setIsView] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [high, setHigh] = useState(9)
  const [low, setLow] = useState(1)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  // const headerName = 'ROLE DETAIL'
  // const filename ="RoleDetail__" + new Date()
  const [searchInput, setSearchInput] = React.useState('')
  // const [searchQuery , setQuery] = useState('')
  const [showDelete, setShow] = React.useState(false);
  const [showRoleDelete, setRoleShow] = React.useState(false);
  const [deleteRoles, setDeleteRoles] = React.useState(0)

  React.useEffect(() => {
    //const { totalPage } = props
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  React.useEffect(() => {
    props.roleListGetAction({ size: 10, page, name: searchInput })
  }, [totalElement, page])

  React.useEffect(() => {
    // console.log(roles, 'roles')
    if (roles && roles.length > 0) {
      // console.log(roles, 'roles')
      setRoles(() => {
        return roles.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (roles && roles.length === 0) {
      setRoles([])
    }
  }, [roles, page])


  const _willViewEdit = (detail: string | number | any) => {
    history.push(`/roles/edit/${detail.id}`)
  }

  const _willViewDetail = (detail: string | number | any) => {
    //props.roleSetSelectedAction(detail)
    history.push(`/roles/${detail.id}`)
  }

  // Delete the pericular list.
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setRoleShow(false)
    setShow(true);
    //setDeleteRoles(roles.id)
    // console.log(deleteAnode, 'deleted anode id')
  }

  const handleRoleClose = () => setRoleShow(false);
  const handleRoleShow = (roles: any) => {
    setDeleteRoles(roles.id)
    if(roles.isused){
      setRoleShow(true);
    } else {
      setShow(true);
    }
    
    
    // console.log(deleteAnode, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deleteRoles(deleteRoles)
  }

  const _deleteRoles = (id: any) => {
    return RoleService.deleteRoles(id).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.roleListGetAction({ size: 10, page, name: searchInput })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }

  const _willRolesAdd = () => {
    props.roleSetSelectedAction(null)
    setModal(true)
    setIsAdd(true)
    setIsEdit(false)
    setIsView(false)
  }
  const _willCloseModal = () => {
    props.roleListGetAction({ size: 10, page: 0, name: searchInput })
    setModal(false)
    setPage(0)
  }

  const _showAll = () => {
    setSearchInput('')
    props.roleListGetAction({ size: 10, page: 0, name: '' })
  }

  const _searchFuntion = (value: string) => {
    setSearchInput(value)
    props.roleListGetAction({ size: 10, page, name: value })
    setPage(0)
  }

  const _goPrevPage = () => {
    setPage(prev => {
      return (prev -= 1)
    })
  }

  const _goNextPage = () => {
    setPage(prev => {
      return (prev += 1)
    })
  }

  const _goToPage = (page: any) => {
    setPage(page)
  }

  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 10)
  }

  const _goToFirstPage = () => {
    setPage(0)
    setHigh(10)
    setLow(1)
  }

  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  const nextBtn = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  const ListItem = (page: any) => {
    return page.value + 1
  }
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0) || (p === 1 && page === 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })


  return (
    <Main>
      <Text variant="title">Role List</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-2 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    <InputWithIcon
                      type="text"
                      value={searchInput}
                      onChange={(e) => _searchFuntion(e.target.value)}
                      placeholder="Search by Role.."
                      icon="search"
                      id="search-inputrole"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='7' xl='7' className='m-0 p-0'>
              <Row className='m-0 p-0'>
                <Col xs='6' sm='4' md='4' lg='3' xl='3' className='m-0 p-0 mr-2'>
                <Link to='/roles/add'> 
                  <Button variant='transparent'>
                    <Flex>
                      <Icons icon="add" color="#ffffff" />
                      {/* <Text variant="subtitle"/> */}
                      ADD NEW ROLE
                    </Flex>
                  </Button>
                </Link>
                </Col>
                <Col xs='5' sm='4' md='4' lg='3' xl='3' className='m-0 p-0 '>
                  <div>
                    <Button variant='transparent' block={true} onClick={() => _showAll()}>
                      Show All
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SearchForm>
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>}
        {!isLoading && displayRoles.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No Roles found</Text>
            <Space divheight={20} />
          </>
        )}
        {!isLoading && displayRoles.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Role</th>
                  <th>Code</th>
                  <th colSpan={2} className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {displayRoles.map((user: any, i: any) => (
                  <tr key={user.id}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='Role'>
                      {user.name}
                    </td>
                    <td data-label='Code'>
                      {user.code}
                    </td>
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="roles-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(user)}>
                            <Icons color="#52575a" icon="remove_red_eye" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={
                          <Tooltip id="roles-edit"> Edit
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewEdit(user)}>
                            <Icons color="#52575a" icon="edit" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={
                          <Tooltip id="roles-delete"> Delete
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => handleRoleShow(user)}>
                            <Icons color="#52575a" icon="delete" />
                          </Button>
                        </OverlayTrigger>
                      </TableActions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text>
                {displayRoles.length === 1 && (
                  <>
                    <b>
                      {displayRoles.length}
                      {/* {((page)*10)+1} - {(displayRoles.length+(page)*10)} */}
                    </b> of {totalElement} Role                  </>
                )}
                {displayRoles.length > 1 && (
                  <>
                    <b>
                      {displayRoles.length}
                      {/* {((page)*10)+1} - {(displayRoles.length+(page)*10)} */}
                    </b> of {totalElement} Roles
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()}
                {prevBtn()}
                {PageNumber}
                {nextBtn()}
                {lastPage()}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {/* {console.log(showModal, 'showmodal')} */}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form Roles list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showRoleDelete} onHide={handleRoleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Role is mapped with Users. Are you sure to delete this Role?' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleRoleClose}>
            <Icons icon="close" />
            Cancel
          </Button>
          <Button variant='transparent' onClick={() => handleShow()}>
            <Icons icon="delete_forever" />
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      {showModal && <RolesAdd onClose={() => _willCloseModal()} edit={isEdit} view={isview} user_id={displayRoles.id} />}
    </Main>
  )
}

export interface TablefooterProps {
  children?: React.ReactNode
}

const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

const mapStateToProps = (state: any) => ({
  roles: state.roles.roles,
  isLoading: state.roles.isLoading,
  error: state.roles.error,
  totalElement: state.roles.totalElement,
  totalPage: state.roles.totalPage
})

export default connect(
  mapStateToProps,
  {
    roleListGetAction,
    roleSetSelectedAction
  }
)(RoleList)