import * as Observable from 'rxjs'
import { timeout, map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getStatusList = [
  null,
  {
    label: 'New Added',
    color: 'main'
  },
  {
    label: 'Verified',
    color: 'Warning'
  },
  {
    label: 'Not verified',
    color: 'Danger'
  }
]

const getStringStatus = (status : any) => {
  switch (status) {
    case 'UNVERIFIED':
      return getStatusList[3]
    case 'VERIFIED':
      return getStatusList[2]
  }
}

const getShips = (params : object) => {
  return http({
    method: 'GET',
    url: '/ships/list',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in ship list page')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getShipDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/ships/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteShip = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/ships/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const updateShip = (body : any) => {
  return http({
    method: 'PUT',
    url: '/ships',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const updateShipLogo = (body : any) => {
  return http({
    method: 'POST',
    url: '/ships/shiplogo',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const addShip = (body : any) => {
  return http({
    method: 'POST',
    url: '/ships',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getShipCompanies = (params : any) => {
  return http({
    method: 'GET',
    url: '/ships/shipCompanies',
    params: { ...params}
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res,'ship company')
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getShipViewDetail = (id: number) => {
  // console.log('get dock view called')
  return http({
      method: 'GET',
      url: `/ships/CompanyShip/${id}`
  }).pipe(
      map(res => {
          // console.log(res,'res')
          if (res.success) {
              return res
          } else {
              throw new Error(res.msg)
          }
      })
  )
}

const getShipCompanyDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/companies/shipCompanies/${id}`
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res, 'company view data')
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteShipCompany = (id: any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/companies/shipCompany/${id}`,
  }).pipe(
    map(res => {
      // console.log(res, 'delete response')
      if (res) {
        return res
      } else {
        // console.log(res, 'error message')
        throw new Error(res)
      }
    })
  )
}

const updateShipCompany = (body : any) => {
  return http({
    method: 'PUT',
    url: '/companies/shipCompanies',
    body: body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const updateShipCompanyImage = (body : any) => {
  return http({
    method: 'POST',
    url: '/companies/shipcompanylogo',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addShipCompany = (body : any) => {
  return http({
    method: 'POST',
    url: '/companies/shipCompanies',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getShipOwners = (params:any) => {
  return http({
    method: 'GET',
    url: '/ships/shipOwner',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in ship list page')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getShipOwnerDetail = (id : any) => {
  // console.log('method called')
  return http({
    method: 'GET',
    url: `/ships/shipOwnerView/${id}`
    }).pipe(
        map(res => {
            // console.log(res,'res in dock owner')
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const getOwnedCmpDetail = (id : any) => {
  // console.log('method called')
  return http({
    method: 'GET',
    url: `/ships/shipOwnerViewCompany/${id}`
    }).pipe(
        map(res => {
            // console.log(res,'res in dock owner')
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const updateShipOwner = (body : any) => {
  return Observable.of(body)
}

const removeShipLogo = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/ships/shipLogoAll/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const removeShipCompanyLogo = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/companies/ShipCompanyLogoAll/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const deleteShipOwner = (id:any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/users/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const getDatalasticShip = (params : any) => {
  return http({
    method: 'GET',
    url: '/ships/shipClassificationList',
    params: { ...params}
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res,'ship company')
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addShipOwner = (body : any) => {
  return Observable.of(body)
}

const shipService = {
  getShips,
  getStatusList,
  getStringStatus,
  getShipDetail,
  deleteShip,
  updateShip,
  addShip,
  getShipCompanies,
  getShipCompanyDetail,
  deleteShipCompany,
  updateShipCompany,
  addShipCompany,
  getShipOwners,
  getShipOwnerDetail,
  updateShipOwner,
  deleteShipOwner,
  addShipOwner,
  getShipViewDetail,
  getOwnedCmpDetail,
  updateShipCompanyImage,
  updateShipLogo,
  removeShipLogo,
  removeShipCompanyLogo,
  getDatalasticShip

}

export default shipService
