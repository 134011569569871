import React, { useRef, useState } from 'react'
import Modal from '../../../../common/components/modal/Modal'
import { Text } from '../../../../common/components/text/Text'
// import { Input, Radio } from '../../../../common/components/control/Control'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import '../../../../common/components/button/Button.scss'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import { connect } from 'react-redux'
import classNames from 'classnames'
import countryService from '../../../../services/countryService'
import { useForm } from 'react-hook-form'
import '../countries.scss'
import { useEffect } from 'react'
import swal from 'sweetalert'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import Loader from 'react-loader-spinner';


interface roleProps {
  id: string;
  name: string;
  code: string;
  isActive: string;
  mblCode: string;
}
interface FormData {
  txt_countryName: string;
  txt_countryCode: string;
  radio_countryActive: string;
  txt_mblCode: string;
  txt_Currency_code: string;
}
const CountriesAdd = (props: any) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const { countries } = props
  const { edit } = props
  const { view } = props
  const { id } = props
  //const { add } = props
  const { onClose } = props
  const [form, setForm] = useState<roleProps>({
    id: '',
    name: '',
    code: '',
    mblCode: '',
    isActive: 'false'
  })
  const [editForm, setEditForm] = useState({
    id: '',
    name: '',
    code: '',
    mblCode: '',
    Currency_code: '',
    isactive: 'false'
  })
  const [countryAddFlag, setCountryAddFlag] = React.useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [isLoading, setLoading] = React.useState(false)
  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevUser = usePrevious(countries);

  useEffect(() => {
    if (countries && prevUser !== countries) {
      _getSelectedUser()
    }

    if (edit) {
      setModalTitle('Edit Country Detail')
    }

    else {
      setModalTitle('Country Detail')
    }
  }, [countries])

  const _getSelectedUser = () => {
    const { countries } = props
    console.log(countries, 'countries')
    var country_activeStatus: string = 'false'
    var country_mbl_code: string = ''
    var country_currency: string = ''
    if (countries.isactive === true) {
      country_activeStatus = 'true'
    }
    else {
      country_activeStatus = 'false'
    }
    if (countries.mobile_code === null) {
      country_mbl_code = ''
    }
    else {
      country_mbl_code = countries.mobile_code
    }
    if (countries.currency_code === null) {
      country_currency = ''
    }
    else {
      country_currency = countries.currency_code
    }
    setEditForm({
      id: countries.id,
      name: countries.name,
      code: countries.code,
      isactive: country_activeStatus,
      mblCode: country_mbl_code,
      Currency_code: country_currency
    })
    // console.log(countries,'props user')
    // setForm({ 
    //   name: user.name,
    //   email: user.email,
    //   password: user.password,
    //   roles: [],
    //   role:user.roles.id,
    //   status: user.status
    //  })
    //  console.log(form,'userForm')
  }

  const CountryAdd = () => {
    // e.preventDefault()
    const payload = {
      name: editForm.name,
      code: editForm.code,
      isactive: editForm.isactive,
      mobile_code: editForm.mblCode,
      currency_code: editForm.Currency_code
    }
    const updatepayload = {
      id: editForm.id,
      name: editForm.name,
      code: editForm.code,
      isactive: editForm.isactive,
      mobile_code: editForm.mblCode,
      currency_code: editForm.Currency_code
    }
    const _willUpdate = () => {
      setLoading(true)
      const { onClose } = props
      const { user_id } = props
      return countryService.updateCountry(updatepayload).subscribe({
        next: () => {
          setLoading(false)
          swal("Great", "Country Updated Successfully", "success")
          onClose()
        },
        error: err => {
          setLoading(false)
          swal("OOPS", String(err), "error")
          console.error(err)
        }
      })
    }
    if (countryAddFlag === true) {
      const { onClose } = props

      if (edit) {
        _willUpdate()
        return
      }
      else {
        setLoading(true)
        countryService.addCountry(payload).subscribe({
          next: (r) => {
            setLoading(false)
            swal("Great", "New Country Added Successfully", "success")
            onClose()
          },
          error: err => {
            setLoading(false)
            console.error(err)
            swal("OOPS", String(err), "error")
          }
        })
      }
    }
    else {
      console.log(countryAddFlag)
    }
  }

  onsubmit = () => {
    // e.preventDefault()
    console.log(Object.keys(errors).length, 'error length')
    if (Object.keys(errors).length === 0) {
      setCountryAddFlag(true)
      CountryAdd()

    }
    else {
      setCountryAddFlag(false)
    }
  }

  if (isLoading) {
    return <Text center>
        <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
        />
    </Text>
}

  return (
    <Modal
      onClose={() => onClose()}
      title={!countries ? 'Add New Country' : modalTitle}>
      <ModalBody>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='countryAddForms'>
          {/* {console.log(editForm,'edit form')} */}
          <Attributes className='singleFrame'>
            {/* {view && (
              <div>
                <Text color='black' variant='subtitle'>ID</Text>
                <Text color='main' bold variant='subtitle'>{editForm.id}</Text>
              </div>
            )} */}
            <div>
              <Text color='black' variant='subtitle'>Country</Text>
              {view && (
                <Text color='main' bold variant='subtitle'>{editForm.name}</Text>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_countryName"
                  id="txt_countryName"
                  placeholder='Country'
                  value={editForm.name}
                  ref={register({ required: true, pattern: /^[a-zA-Z\s]*$/ })}
                  onChange={(e) => setEditForm({ ...editForm, name: e.target.value })}
                  type="text"
                />
              )
              }
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_countryName?.type === 'required' && 'Please enter Country Name'}
                  {errors.txt_countryName?.type === 'pattern' && 'Please enter Valid Country Name'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Country Abbreviation</Text>
              {view && (
                <>
                  {editForm.code === '' ? (
                    <Text color='main' bold variant='subtitle'> - </Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.code}</Text>
                  )}
                </>
              )}
              {!view && edit && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_countryCode"
                  id="txt_countryCode"
                  value={editForm.code}
                  placeholder='Country Abbreviation'
                  //ref={register({ required: true })}
                  // ref={register({ required: true, pattern:/^[0-9\s]*$/,minLength:2, maxLength:6 })}
                  onChange={(e) => setEditForm({ ...editForm, code: e.target.value })}
                  type="text"
                />
              )}
              {!view && !edit && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_countryCode"
                  id="txt_countryCode"
                  value={editForm.code}
                  placeholder='Country Abbreviation'
                   ref={register({ required: true })}
                  // ref={register({ required: true, pattern:/^[0-9\s]*$/,minLength:2, maxLength:6 })}
                  onChange={(e) => setEditForm({ ...editForm, code: e.target.value })}
                  type="text"
                />
              )}
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_countryCode?.type === 'required' && 'Please enter Country Code'}
                  {errors.txt_countryCode?.type === 'pattern' && 'Please enter Valid Country Code'}
                  {errors.txt_countryCode?.type === 'minLength' && 'Code should be above 2 digits only'}
                  {errors.txt_countryCode?.type === 'maxLength' && 'Code should be below 6 digits only'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Mobile Code</Text>
              {view && (
                <>
                  {editForm.mblCode === '' ? (
                    <Text color='main' bold variant='subtitle'> - </Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.mblCode}</Text>
                  )}
                </>
                // <Text color='main' bold variant='subtitle'>{editForm.mblCode}</Text>
              )}
              {!view && !edit && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_mblCode"
                  id="txt_mblCode"
                  value={editForm.mblCode}
                  placeholder='Mobile Code'
                  ref={register({ required: true })}
                  // ref={register({ required: true, pattern:/^[0-9\s]*$/,minLength:2, maxLength:4 })}
                  onChange={(e) => setEditForm({ ...editForm, mblCode: e.target.value })}
                  type="text"
                />
              )}
              {!view && edit && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_mblCode"
                  id="txt_mblCode"
                  value={editForm.mblCode}
                  placeholder='Mobile Code'
                  // ref={register({ required: true, pattern:/^[0-9\s]*$/,minLength:2, maxLength:4 })}
                  onChange={(e) => setEditForm({ ...editForm, mblCode: e.target.value })}
                  type="text"
                />
              )}
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_mblCode?.type === 'required' && 'Please enter Country`s Mobile Code'}
                  {errors.txt_mblCode?.type === 'pattern' && 'Please enter Valid Code'}
                  {errors.txt_mblCode?.type === 'minLength' && 'Code should be above 2 digits only'}
                  {errors.txt_mblCode?.type === 'maxLength' && 'Code should be below 6 digits only'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Currency Format</Text>
              {view && (
                <>
                  {editForm.Currency_code === '' ? (
                    <Text color='main' bold variant='subtitle'> - </Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.Currency_code}</Text>
                  )}
                </>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_Currency_code"
                  id="txt_Currency_code"
                  value={editForm.Currency_code}
                  placeholder='Currency Format'
                  // ref={register({ required: true,})}
                  onChange={(e) => setEditForm({ ...editForm, Currency_code: e.target.value })}
                  type="text"
                />
              )}
            </div>
            {/* <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
              <span className='err-msg text-capitalize'>
                {errors.txt_Currency_code?.type === 'required' && 'Please enter Country`s Mobile Code'}
              </span>
              </Col>
            </Row> */}
            <div>
              <Text color='black' variant='subtitle'>Status</Text>
              {view && (
                editForm.isactive === 'true' ? (
                  <Text color='success' bold variant='subtitle'>Active</Text>
                ) : (
                  <Text color='danger' bold variant='subtitle'>In Active</Text>
                )
              )}
              {/* {user.status === 'VERIFIED' ? (
                        <Text color='warning' variant='subtitle'>Verified</Text>
                      ) : (
                        <Text color='danger' variant='subtitle'>Not Verified</Text>
                      )} */}
              {!view && (
                <div>
                  <Form.Check type='radio' id='active' name='status' value={editForm.isactive}
                    className='RadioButton'
                    onChange={(e) => setEditForm({ ...editForm, isactive: 'true' })}
                    checked={editForm.isactive === 'true'}
                    label='ACTIVE'></Form.Check>
                  <Form.Check type='radio' id='inactive' name='status' value={editForm.isactive}
                    className='RadioButton'
                    onChange={(e) => setEditForm({ ...editForm, isactive: 'false' })}
                    checked={editForm.isactive === 'false'}
                    label='IN ACTIVE'></Form.Check>
                </div>
              )}
            </div>
          </Attributes>
          <Space divheight={20} />
          <Flex jc='jc-center'>
            <Button
              type="button"
              onClick={() => onClose()}
              className='bttn bttn-transparent'>
              <Flex>
                <Icons icon="arrow_back" />
                BACK
              </Flex>
            </Button>
            {!view && (
              <Button className='bttn bttn-transparent' type="submit" name='btn_countryAdd' id='btn_countryAdd'>
                <Flex>
                  <Icons color="#ffffff" icon="check" />
                  SAVE DATA
                </Flex>
              </Button>
            )}
          </Flex>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export interface ModalbodyProps {
  children?: React.ReactNode
}

export const ModalBody = ({ children, ...props }: ModalbodyProps) => {

  const modal = classNames('modalbody-container')

  return (
    <div className={modal}>
      {children}
    </div>
  )

}
// export interface AttributeProps {
//   children?: React.ReactNode
// }
// export const Attributes = ({ children, ...props }: AttributeProps) => {
//   const attribute = classNames('attribute-container');

//   return (
//     <div {...props} className={attribute} >
//       {children}
//     </div>
//   )
// }

const mapStateToProps = (state: any) => ({
  countries: state.countries.selectedCountries
})
export default connect(
  mapStateToProps)(CountriesAdd)
