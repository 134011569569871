import {
    DATA_SHIP_ENGINE_LIST_GET,
    DATA_SHIP_ENGINE_LIST_GET_F,
    DATA_SHIP_ENGINE_LIST_GET_R,
    DATA_SHIP_ENGINE_SELECTED_SET,
  } from '../actionType'
  
  export const datashipEngineListGetAction = (params : any) => ({
    type: DATA_SHIP_ENGINE_LIST_GET,
    payload: params
  })
  
  export const datashipEngineListGetFAction = (data : any) => ({
    type: DATA_SHIP_ENGINE_LIST_GET_F,
    payload: data
  })
  
  export const datashipEngineListGetRAction = ( error : any) => ({
    type: DATA_SHIP_ENGINE_LIST_GET_R,
    payload: error
  })
  
  export const datashipEngineSetSelectedAction = (engine : any) => ({
    type: DATA_SHIP_ENGINE_SELECTED_SET,
    payload: engine
  })