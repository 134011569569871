import {
    COUNTRY_LIST_GET,
}  from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import {
    countryListGetFAction,
    countryListGetRAction,
} from '../action/countryAction'
import countryService from '../../services/countryService'

export const getCountryEpic = (action : any) => {
  // console.log('get role epic called')
  return action.pipe(
    ofType(COUNTRY_LIST_GET),
    switchMap((action : any) => {
      return countryService.getCountry(action.payload).pipe(
        map((res :any) => {
        // console.log(res,'response for country epics')
        const { content, total_page, total_element } = res.data
        // console.log(content,'country content')
        return countryListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(countryListGetRAction(err)))
      )
    })
  )
}