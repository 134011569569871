import { ofType } from 'redux-observable'
import {
  PROVINCES_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  provincesListGetFAction,
  provincesListGetRAction

} from '../action/provincesAction'
import provincesService from '../../services/provincesService'

export const getProvincesEpic = (action$: any) => {
  return action$.pipe(
    ofType(PROVINCES_LIST_GET),
    switchMap((action: any) => {
      return provincesService.getProvinces(action.payload).pipe(
        map((res: any) => {
          // console.log(res, 'response in provicesEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content, 'content in ship type')
          // console.log(total_page, 'total page in ship type')
          // console.log(total_element, 'total element in ship type')
          return provincesListGetFAction({ content, total_page, total_element })
        }),
        catchError((err: any) => of(provincesListGetRAction(err)))
      )
    })
  )
}