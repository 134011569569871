import React from 'react'
import classNames from 'classnames'
import './Attribute.scss'

//Interface for AttributeProps
export interface AttributeProps{
    children ? : React.ReactNode
    className ? :string;
    id ? : any
}

//Attribute component used for Grid seperation in forms
export const Attributes = ({children,className,id, ...props}:AttributeProps) => {
    const attribute = classNames('attribute-container', className);

    return (
        <div className={attribute} id={id}>
            {children}
        </div>
    )
}
