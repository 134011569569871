import { ofType } from 'redux-observable'
import { ACCOUNT_GET, ACCOUNT_SET } from '../actionType'
import { switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import authService from '../../services/authService'
import  {history}  from '../index';
import {
  accountGetFAction,
  accountSetFAction
} from '../action/accountActions';

export const setAccountEpic = (action$ : any) => {
  return action$.pipe(
    ofType(ACCOUNT_SET),
    switchMap((data:any) => {
      console.log(data)
      authService.persistAccount(data.payload)
      history.push('/')
      return of(accountSetFAction())
    })
  )
}

export const getAccountEpic = (action$ :any)  => {
  return action$.pipe(
    ofType(ACCOUNT_GET),
    switchMap(() => {
      const account = authService.getAccount()
      return of(accountGetFAction(account))
    })
  )
}
