import { timeout, map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getShipClassification = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/shipClassification/list',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addShipClassification = (body : any) => {
  return http({
    method: 'POST',
    url: '/shipClassification',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getShipClassificationDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/shipClassification/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteClassification = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/shipClassification/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const updateShipClassification = (body : any) => {
  return http({
    method: 'PUT',
    url: '/shipClassification',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const shipClassificationService = {
  getShipClassification,
  addShipClassification,
  getShipClassificationDetail,
  updateShipClassification,
  deleteClassification
}
  
export default shipClassificationService
  

