import React, { useRef, useState } from 'react'
import Modal from '../../../../common/components/modal/Modal'
import { Text } from '../../../../common/components/text/Text'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Input, Select, Textarea } from '../../../../common/components/control/Control'
// import { Button } from '../../../../common/components/button/Button'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import '../ShipSubType.scss'
import { useEffect } from 'react'
import swal from 'sweetalert'
import typeService from '../../../../services/typeService'
import codeService from '../../../../services/codeService'

interface ShipSubTypeProps {
  id: string;
  subType: string;
  shipType: string;
  systemAccess: string;
  shipTypesArray: any[]
}

interface FormData {
  txt_subType: string;
  txt_shipType: string;
  radio_systemAccess: string;
}

const AddShipSubType = (props: any) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const [subTypeaddflag, setsubTypeAddFlag] = React.useState(false)
  const { subType } = props
  const { edit } = props
  const { view } = props
  const { id } = props
  //const { add } = props
  const { onClose } = props
  const [form, setForm] = useState<ShipSubTypeProps>({
    id: '',
    subType: '',
    shipType: '',
    systemAccess: '',
    shipTypesArray: []

  })
  const [editForm, setEditForm] = useState({
    id: '',
    subType: '',
    shipType: '',
    shipTypeName: '',
    systemAccess: 'true',
  })

  const [modalTitle, setModalTitle] = useState('')
  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevUser = usePrevious(subType);

  useEffect(() => {
    if (subType && prevUser !== subType) {
      _getSelectedShipSubType()
    }

    if (edit) {
      setModalTitle('Edit Ship Sub Type Detail')
    }

    else {
      setModalTitle('Ship Sub Type Detail')
    }
  }, [subType])

  const _getShipType = (() => {
    return codeService.getShipTypes().subscribe({
      next: r => {

        // console.log(r.data,"ship type")
        setForm({ ...form, shipTypesArray: r.data })
        // console.log(ship.shipType ,'ship type data in ship add page')
        r.data.map((data: any, i: any) => {
          return form.shipTypesArray.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  React.useEffect(() => {
    _getShipType()
  }, [])

  const _getSelectedShipSubType = () => {
    const { subType } = props
    var systemacc
    if (subType.isactive === true) {
      systemacc = 'true'
    }
    else {
      systemacc = 'false'
    }
    setEditForm({
      id: subType.id,
      subType: subType.shipsubtype_name,
      shipType: subType.shiptype_id,
      shipTypeName: subType.shiptype_name,
      systemAccess: systemacc
    })
  }

  const _didSubTypeAdd = () => {
    // e.preventDefault()
    const { onClose } = props

    const payload = {
      name: editForm.subType,
      ship_type_id: editForm.shipType,
      isactive: editForm.systemAccess
    }
    if (subTypeaddflag === true) {
      if (edit) {
        _willUpdate()

      }
      else {
        typeService.addShipSubType(payload).subscribe({
          next: (r) => {
            swal("Great", " Ship Sub Type Added Successfully", "success")
            onClose()
          },
          error: err => {
            console.error(err)
            swal("OOPS", String(err), "error")
          }
        })
      }
    }
    else {
      console.log(subTypeaddflag)
    }

  }
  const _willUpdate = () => {
    const { onClose } = props
    const { user_id } = props
    const updatepayload = {
      id: Number(editForm.id),
      name: editForm.subType,
      ship_type_id: editForm.shipType,
      isactive: editForm.systemAccess
    }
    return typeService.updateShipSubType(updatepayload).subscribe({
      next: () => {
        swal("Great", "Ship Sub Type Updated Successfully", "success")
        onClose()
      },
      error: err => {
        swal("OOPS", String(err), "error")
        console.error(err)
      }
    })
  }

  onsubmit = (e: any) => {
    // e.preventDefault()
    // console.log(Object.keys(errors).length , 'error length')
    if (Object.keys(errors).length === 0) {
      _didSubTypeAdd()
      setsubTypeAddFlag(true)
    }
    else {
      setsubTypeAddFlag(false)
    }
  }

  return (
    <Modal
      onClose={() => onClose()}
      title={!subType ? 'Add New Company' : modalTitle}>
      <ModalBody>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='subTypeForm'>
          <Attributes>
            {/* {view && (
              <div>
                <Text color='black' variant='subtitle'>ID</Text>
                <Text color='main' bold variant='subtitle'>{editForm.id}</Text>
              </div>
            )} */}
            <div>
              <Text color='black' variant='subtitle'>Ship Sub Type</Text>
              {view && (
                <Text color='main' bold variant='subtitle'>{editForm.subType}</Text>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_subType"
                  placeholder='Ship Sub Type'
                  value={editForm.subType}
                  ref={register({ required: true, })}
                  onChange={(e) => setEditForm({ ...editForm, subType: e.target.value })}
                  type="text"
                />
              )
              }
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>{errors.txt_subType?.type === 'required' && 'Please enter Sub Type'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Ship Type</Text>
              {view && (
                <Text color='main' bold variant='subtitle'><p>{editForm.shipTypeName}</p></Text>
              )}
              {!view && (
                <div>
                  <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                    value={editForm.shipType} name='txt_shipType'
                    onChange={(e) => setEditForm({ ...editForm, shipType: e.target.value })}
                    ref={register({ required: true, })}>
                    <option value="">-- Select --</option>
                    {/* Map the data's from shiptype array and used as a option value. By Dharani on 04-Mar-2021 */}
                    {form.shipTypesArray.map((data: any, i: any) => {
                      return <option key={i} value={data.id}>{data.name}</option>
                    })}
                  </Form.Control>
                </div>
              )}
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>{errors.txt_shipType?.type === 'required' && 'Please Select Ship Type'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>System Access</Text>
              {view && (
                editForm.systemAccess === 'true' ? (
                  <Text color='success' bold variant='subtitle'>Active</Text>
                ) : (
                  <Text color='danger' bold variant='subtitle'>In Active</Text>
                )
              )}
              {!view && (
                <div>
                  <Form.Check type='radio' id='active' name='radio_systemAccess' value={editForm.systemAccess}
                    className='RadioButton'
                    onChange={(e) => setEditForm({ ...editForm, systemAccess: 'true' })}
                    checked={editForm.systemAccess === 'true'}
                    label='ACTIVE'></Form.Check>
                  <Form.Check type='radio' id='inactive' name='radio_systemAccess' value={editForm.systemAccess}
                    className='RadioButton'
                    onChange={(e) => setEditForm({ ...editForm, systemAccess: 'false' })}
                    checked={editForm.systemAccess === 'false'}
                    label='IN ACTIVE'></Form.Check>
                </div>
              )}
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>{errors.radio_systemAccess?.type === 'required' && 'Please Select Ship Type'}
                </span>
              </Col>
            </Row>
          </Attributes>
          <Space divheight={20} />
          <Flex jc='jc-center'>
            <Button
              type="button"
              onClick={() => onClose()}
              className='bttn bttn-transparent'>
              <Flex>
                <Icons icon="arrow_back" />
                BACK
              </Flex>
            </Button>
            {!view && (
              <Button className='bttn bttn-transparent' type="submit">
                <Flex>
                  <Icons color="#ffffff" icon="check" />
                  SAVE DATA
                </Flex>
              </Button>
            )}
          </Flex>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export interface ModalbodyProps {
  children?: React.ReactNode
}

export const ModalBody = ({ children, ...props }: ModalbodyProps) => {

  const modal = classNames('modalbody-container')

  return (
    <div className={modal}>
      {children}
    </div>
  )

}
export interface AttributeProps {
  children?: React.ReactNode
}
export const Attributes = ({ children, ...props }: AttributeProps) => {
  const attribute = classNames('attribute-container');

  return (
    <div {...props} className={attribute} >
      {children}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  subType: state.subType.selectedShipSubType
})
export default connect(
  mapStateToProps)(AddShipSubType)
