import React, { useState } from 'react'

 const shipCmpFilter = {
    shipcmpstatus: '',
    shipcmpcountry: '',
    shipcmpcountryNames:'',
    city: '',
    cmpName: '',
    page:0,
    size:10,
    serachBoxInputCompany:'',
    type:'',
    direction:''
}

const datalasticshipCmpFilter = {
    shipcmpstatus: '',
    shipcmpcountry: '',
    shipcmpcountryNames:'',
    city: '',
    cmpName: '',
    page:0,
    size:10,
    serachBoxInputCompany:'',
    type:'',
    direction:''
}

const shipFilter = {
    size: 10, page: 0,
    companyName: '',
    shipName:'',
    shipcountryName: '',
    shipcountryNames:'',
    shipcityName:'',
    shipstatus: '',
    serachBoxInputShip:'',
    type:'',
    direction:''
}

const shipOwnerFilter = {
    size: 10, page: 0, 
    name: '', 
    email:'',
    fromDate:'',
    toDate:'', 
    shipownstatus:'', 
    searchBoxInputName:'',
    searchBoxInputEmail:'',
    findbyIdname:'',
    type:'',
    direction:''
}

const shipMaterialfilter={
    searchBoxInputShipmaterial:''
}

export const ShipFilterState = {
    shipCmpFilter,
    shipFilter,
    shipOwnerFilter,
    shipMaterialfilter,
    datalasticshipCmpFilter
}
