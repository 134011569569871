import { ofType } from 'redux-observable'
import {
  SHIP_SUBTYPE_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  shipSubTypeListGetFAction,
  shipSubTypeListGetRAction

} from '../action/shipSubTypeAction'
import typeService from '../../services/typeService'

export const getShipSubTypesEpic = (action$ : any) => {
  return action$.pipe(
    ofType(SHIP_SUBTYPE_LIST_GET),
    switchMap((action : any) => {
      return typeService.getShipSubType(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in shipTypeEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return shipSubTypeListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(shipSubTypeListGetRAction(err)))
      )
    })
  )
}