import React from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import { dockService } from '../../../../services/dockService'
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { Label } from "../../../../common/components/label/Label";
import { dockCompanySetSelectedAction } from '../../../../store/action/dockActions';
// import { history } from '../../../store';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import swal from 'sweetalert';
import Loader from 'react-loader-spinner';

interface ViewProps{
    dock: any[]
}

const DockRequest = (props: any) => {
    const { company, match } = props
    const {status} = match.params
    const [isLoading, setLoading] = React.useState(true)
    const [form, setForm] = React.useState<ViewProps>({
        dock: []
    })
    //const [isDeleting, setDelete] = React.useState(false)

    React.useEffect(() => {
        const { id } = match.params
        _getCompanyDetail(id)
        _getdockDetail(id)
    }, [])

    const _getdockDetail = ((id:number) => {
        return dockService.getDockViewDetail(id).subscribe({
            next: r => {
                setForm({ ...form, dock: r.data })
                r.data.map((data: any, i: any) => {
                    return form.dock.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getCompanyDetail = (id: any) => {
        setLoading(true)
        return dockService.getDockCompanyDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.dockCompanySetSelectedAction(data)
                setLoading(false)
            },
            error: err => {
                console.error(err)
            }
        })
    }
    
    const _willGrantAccess = () => {
        const { cid } = match.params
       const updatedForm={
           id:cid,
           approved_status:'claimed',
           remarks:''
       } 
       return dockService.giveDockCompanyAcess(updatedForm).subscribe({
           next: () =>{
            swal("Great","Permit Access","success")
           },
           error: err =>{
            swal("OOPS",String(err),"error")
            console.error(err)
        }
       })
    }
    if (isLoading) {
        return <Text center>
            <Loader
              type="Rings"
              color="#233269"
              height={100}
              width={100}
              timeout={30000} 
            />
        </Text>
    }

    return (
        <Main>
            <Text variant='title'>{company.name}</Text>
            <Label className={company.status === "VERIFIED" ? "warning" : "danger "}>Not Verified
                {/* {company.status} */}
            </Label>
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                <Col sm='12' md='12' lg='12' xl='8' className='m-0 p-0 align-top'>
                    <Card title="DryDock Company Detail">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Company Name</Text>
                                        <Text color='main' variant='subtitle' bold>{company.name}</Text>
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Company Email</Text>
                                        <Text color='main' variant='subtitle' className='Detail-page-text'>{company.email}</Text>
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Company Website</Text>
                                        <Text color='main' variant='subtitle' bold>{company.website}</Text>
                                    </div>
                                </Attributes>
                            </Col>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Company Phone</Text>
                                        <Text color='main' variant='subtitle' bold>{company.phone}</Text>
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Company Address</Text>
                                        <Text color='main' variant='subtitle' bold>{company.address}</Text>
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='12'>
                                <Card title="Dock Detail">
                                    <Row className='m-0 p-0'>
                                        <Col sm='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                        {form.dock.map((dock: any, i: any) => [
                                            <Attributes  className ='view_Page'>
                                                <div>
                                                    <Text color='main' variant='subtitle'>Dock Name</Text>
                                                    <Text variant='subtitle' color="main" bold>{dock.name}</Text>
                                                </div>
                                                <div>
                                                    <Text color='main' variant='subtitle'>City and Country </Text>
                                                    <Text color="main" variant='subtitle' bold> {dock.port.city.name} ({dock.port.city.country.name}) </Text>
                                                </div>
                                            </Attributes>
                                        ])}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm='12' md='12' lg='12' xl='4' className='p-0 m-0'>
                    <div className='ml-xl-3  mt-sm-4 mt-md-4 mt-lg-4 mt-xl-0 view_page_xs_margin'  >
                        <Card title="Owner Detail">
                            <Row className='p-o m-0'>
                                <Col sm='12' className='p-0 m-0'>
                                    <Attributes className='view_Page'>
                                        <div>
                                            <Text color='main' variant='subtitle'> Owner</Text>
                                            {company.user !== null && (
                                                <Text variant='subtitle' color="main" bold>{company.user.name}</Text>
                                            )}
                                            {company.user === null && (
                                                <Text variant='subtitle' color="main" bold>not owned by any one</Text>
                                            )}
                                            {/* <Text color='main' variant='subtitle' bold> {company.user.name} </Text> */}
                                        </div>
                                        <div>
                                            <Text color='main' variant='subtitle'> Join Date</Text>
                                            <Text color='main' variant='subtitle' bold> 
                                                {/* {company.created_at} */}
                                                {format(parseISO(company.created_at), 'dd/MM/yyyy, hh:mm')}
                                            </Text>
                                        </div>
                                        <div>
                                            <Text color='main' variant='subtitle'>Verified by</Text>
                                            <Text color='main' variant='subtitle' bold> </Text>
                                        </div>
                                    </Attributes>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='12' xl='8'>
                    <Actions>
                        <Link to={`/comapanies/company-type`}>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        <div>
                        {status === 'pending' && (
                            <Button variant='transparent' onClick={() => _willGrantAccess()} >
                                <Flex>
                                    <Icons color="#ffffff" icon="check" />
                                    Grant Access
                                </Flex>
                            </Button>
                        )}
                        {status === 'claim' && (
                            <Button variant='transparent' disabled>
                                <Flex>
                                    <Icons color="#ffffff" icon="edit" />
                                    Access Provided
                                </Flex>
                            </Button>
                        )}
                        </div>
                    </Actions>
                </Col>
            </Row>
        </Main>
    )

}

export interface CompanyProps {
    children?: React.ReactNode
    className?: string;
}
export const Company = ({ children, className, ...props }: CompanyProps) => {
    const attribute = classNames('company-container', className);

    return (
        <div {...props} className={attribute} >
            {children}
        </div>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

export interface LinkProps {
    children?: React.ReactNode
    color?: string
}
const CompanyLink = ({ color, ...props }: LinkProps) => {
    const actions = classNames('company-link-container', color);
    return (
        <div className={actions}>
            {props.children}
        </div> 
    )
}

const mapStateToProps = (state: any) => ({
    company: state.dock.selectedCompany
})

export default connect(
    mapStateToProps,
    {
        dockCompanySetSelectedAction
    }
)(DockRequest)
