import{DOCK_TYPE_LIST_GET,
    DOCK_TYPE_LIST_GET_F,
    DOCK_TYPE_LIST_GET_R,
    DOCK_TYPE_SELECTED_SET
} from '../actionType'

export const dockTypeListGetAction = (params : any) => ({
    type: DOCK_TYPE_LIST_GET,
    payload: params
})

export const dockTypeListGetFAction = (params : any) => ({
    type: DOCK_TYPE_LIST_GET_F,
    payload: params
})

export const dockTypeListGetRAction = (params : any) => ({
    type: DOCK_TYPE_LIST_GET_R,
    payload: params
})

export const dockTypeSetSelectedAction = (docktype : any) => ({
    type: DOCK_TYPE_SELECTED_SET,
    payload: docktype
})