import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import shipClassificationService from '../../../../services/shipClassificationService'
import swal from 'sweetalert';
import '../../../../styles/variables/Common.scss'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner';      



interface ClassificationAddProps {
  name: string;
  description: string;
  name_english : string;
  name_description : string;
}

interface FormData{
  txt_clsname: string;
  txt_clsdescription: string;
  txt_clsname_english : string;
  txt_clsname_description : string;
}
export const ClassificationAdd = (props: ClassificationAddProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
  });
  const [classAddFlag, setclassAddFlag] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const [classification, setClassification] = useState<ClassificationAddProps>({
    name: '',
    description: '',
    name_english : '',
    name_description : ''
  },
  )
  
// After enter all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call. 
  const _didClasAdd = () => {
    // e.preventDefault()
    const updatedForm = {
        name: classification.name,
        description: classification.description,
        name_english : classification.name_english,
        name_description : classification.name_description
    } 
    
    // API call for addship with data, added to list. 
    // console.log(updatedForm,"updatedform")
    if(classAddFlag === true){
      setLoading(true)
      shipClassificationService.addShipClassification(updatedForm).subscribe({
        next:  (r) => {
          setLoading(false)
          // console.log(r,'response')
          swal("Great","Classification Added Successfully" ,"success")
          history.push('/shipClassification/list')
        },
        error: err => {
          setLoading(false)
          swal("OOPS",String(err),"error")
        }
      })
    }
    else{
      console.log(classAddFlag,'flag')
    }  
  }

  onsubmit = (e: any) =>{
    // e.preventDefault()
    console.log(Object.keys(errors).length , 'error length')
    if(Object.keys(errors).length === 0){
        _didClasAdd()
        setclassAddFlag(true)
    }
    else{
        setclassAddFlag(false)
    }
}

if (isLoading) {
  return <Text center>
      <Loader
          type="Rings"
          color="#233269"
          height={100}
          width={100}
          timeout={30000}
      />
  </Text>
}

  return (
    <Main>
      <Text variant="title" color='black'>Add Ship Classification Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='paintAddForms'>
            <Card title="Fill All the Information Below">
                <Row className='m-0 p-0'>
                <Col xs= '12' md = '12' lg ='6' xl ='6' className='p-0 m-0'>
                    <Attributes>
                        <div className='sm-mt-3'>
                            <Text color='tablehead' variant='subtitle' >Classification</Text>
                            <Form.Control className='basecontrol custome-input'
                            type="text"
                            name="txt_clsname"
                            value={classification.name}
                            onChange={(e) => setClassification({ ...classification, name: e.target.value })}
                            ref={register({ required: true,})}
                            placeholder="Classification Type"
                            />
                        </div>
                        <Row className='m-0 p-0'>
                          <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                          </Col>
                          <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                          <span className='err-msg text-capitalize'>{errors.txt_clsname?.type === 'required' && 'Please enter Classification'}
                              </span>
                          </Col>
                        </Row>
                        <div>
                            <Text color='tablehead' variant='subtitle'>Description</Text>
                            <Form.Control as='textarea'
                            className='basecontrol custome-textarea'
                            name="txt_clsdescription"
                            placeholder='Description'
                            rows={3}
                            cols={50}
                            value={classification.description}
                            onChange={(e) => setClassification({...classification, description: e.target.value})}
                            ref={register({ required: true,})}
                            />
                        </div>
                        <Row className='m-0 p-0'>
                          <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                          </Col>
                          <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                          <span className='err-msg text-capitalize'>{errors.txt_clsdescription?.type === 'required' && 'Please enter description'}
                              </span>
                          </Col>
                        </Row>
                    </Attributes>
                </Col>
                <Col xs= '12' md = '12' lg ='6' xl ='6' className='p-0 m-0'>
                    <Attributes >
                        <div className='sm-mt-3'>
                            <Text color='tablehead' variant='subtitle' >Classification in English</Text>
                            <Form.Control className='basecontrol custome-input'
                            type="text"
                            name="txt_clsname_english"
                            value={classification.name_english}
                            onChange={(e) => setClassification({ ...classification, name_english: e.target.value })}
                            ref={register({ required: true,})}
                            placeholder="Classification Type English"
                            />
                        </div>
                        <Row className='m-0 p-0'>
                          <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                          </Col>
                          <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                          <span className='err-msg text-capitalize'>{errors.txt_clsname_english?.type === 'required' && 'Please enter Name in english'}
                              </span>
                          </Col>
                        </Row>
                        <div>
                            <Text color='tablehead' variant='subtitle'>Description English</Text>
                            <Form.Control as='textarea'
                            className='basecontrol custome-textarea'
                            name="txt_clsname_description"
                            placeholder='Description in English' 
                            rows={3}
                            cols={50}
                            value={classification.name_description}
                            onChange={(e) => setClassification({...classification, name_description: e.target.value})}
                            ref={register({ required: true,})}
                            />
                        </div>
                        <Row className='m-0 p-0'>
                          <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                          </Col>
                          <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                          <span className='err-msg text-capitalize'>{errors.txt_clsname_description?.type === 'required' && 'Please enter Description in english'}
                              </span>
                          </Col>
                        </Row>
                    </Attributes>
                </Col>
                </Row>
            </Card>
          <Actions>
            {/* If you click the back button its is redirect to ships list page.  */}
            <Link to='/shipClassification/list'>
              <Button className='bttn bttn-transparent'>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page.  */}
            <Button className='bttn bttn-transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                  SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </Form>
      </Grid>
      {/* Add form, having input box, select box.  */}
    </Main>
  )
}


export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-ship-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. 
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}
