import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import swal from 'sweetalert';
import materialService from '../../../../services/materialService'
import '../../../../styles/variables/Common.scss'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner';


interface MaterialAddProps {
  name: string;
  description: string;
  name_english: string;
  name_description: string;
}
interface FormData {
  txt_name: string;
  txt_escription: string;
  txt_name_english: string;
  txt_name_description: string;
}

export const ShipMaterialAdd = (props: MaterialAddProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
});
  const [materialaddflag, setmaterialAddFlag] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [ship, setShip] = useState<MaterialAddProps>({
    name: '',
    description: '',
    name_english: '',
    name_description: ''
  },
  )

  // After enter all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call. 
  const _didmaterialAdd = () => {
    // e.preventDefault()
    const updatedForm = {
      name: ship.name,
      description: ship.description,
      name_english: ship.name_english,
      name_description: ship.name_description
    }

    // API call for addship with data, added to list. 
    // console.log(updatedForm,"updatedform")
    if (materialaddflag === true) {
      setLoading(true)
      materialService.addShipMaterial(updatedForm).subscribe({
        next: (r) => {
          setLoading(false)
          // console.log(r,'response')
          swal("Great", "Ship Material Added Successfully", "success")
          history.push('/shipMaterial/list')
        },
        error: err => {
          setLoading(false)
          swal("OOPS", String(err), "error")
        }
      })
    }
    else {
      console.log(materialaddflag, 'flag')
    }

  }
  onsubmit = (e: any) => {
    // e.preventDefault()
    console.log(Object.keys(errors).length, 'error length')
    if (Object.keys(errors).length === 0) {
      _didmaterialAdd()
      setmaterialAddFlag(true)
    }
    else {
      setmaterialAddFlag(false)
    }
  }
  return (
    <Main>
      <Text variant="title" color='black'>Add Ship Material Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='materialAddForms'>
          <Card title="Fill All the Information Below">
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Ship Material</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_name"
                      value={ship.name}
                      onChange={(e) => setShip({ ...ship, name: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Ship Material"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_name?.type === 'required' && 'Please enter Material name'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Description</Text>
                    <Form.Control as="textarea"
                      name="txt_escription"
                      placeholder='Description'
                      className='basecontrol custome-textarea'
                      rows={3}
                      cols={50}
                      value={ship.description}
                      onChange={(e) => setShip({ ...ship, description: e.target.value })}
                      ref={register({ required: true, })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_escription?.type === 'required' && 'Please enter description'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes >
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Material in English</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_name_english"
                      value={ship.name_english}
                      onChange={(e) => setShip({ ...ship, name_english: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Ship Material in English"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_name_english?.type === 'required' && 'Please enter Name in English'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Description English</Text>
                    <Form.Control as="textarea"
                      className='basecontrol custome-textarea'
                      name="txt_name_description"
                      placeholder='Description in English'
                      rows={3}
                      cols={50}
                      value={ship.name_description}
                      onChange={(e) => setShip({ ...ship, name_description: e.target.value })}
                      ref={register({ required: true, })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_name_description?.type === 'required' && 'Please enter Description in English'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
            </Row>
          </Card>
          <Actions>
            {/* If you click the back button its is redirect to ships list page.  */}
            <Link to='/shipMaterial/list'>
              <Button className='bttn bttn-transparent'>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page.  */}
            <Button className='bttn bttn-transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </Form>
      </Grid>
      {/* Add form, having input box, select box.  */}
    </Main>
  )
}


export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-ship-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. 
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}
