import { map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getPaint = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/paints/search',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addPaint = (body : any) => {
  return http({
    method: 'POST',
    url: '/paints/setAmount',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getPaintDetail = (params : any) => {
  return http({
    method: 'GET',
    url: `/paints/setAmount`,
    params
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deletePaint = (params : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/paints/setAmount`,
    params
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const updatePaint = (body : any) => {
  return http({
    method: 'PUT',
    url: '/paints/setAmount',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const paintService = {
  getPaint,
  addPaint,
  getPaintDetail,
  deletePaint,
  updatePaint,
}
  
export default paintService
  

