import * as Observable from 'rxjs'
import { timeout, map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getAnode = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/anodes/search',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addAnode = (body : any) => {
  return http({
    method: 'POST',
    url: '/anodes/setAmount',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getAnodeDetail = (params : object) => {
  return http({
    method: 'GET',
    url: `/anodes/setAmount`,
    params
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteAnode = (params: any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/anodes/setAmount`,
    params
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const updateAnode = (body : any) => {
  return http({
    method: 'PUT',
    url: '/anodes/setAmount',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const anodeService = {
  getAnode,
  addAnode,
  getAnodeDetail,
  deleteAnode,
  updateAnode,
}
  
export default anodeService
  

