import React, { useRef } from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import { File } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'

import codeService from '../../../../services/codeService'
import Icons from '../../../../common/components/icon/Icon'
import { WEIGHT_UNIT_DEFAULT, DEPTH_UNIT_DEFAULT, LENGTH_UNIT_DEFAULT, BEAM_UNIT_DEFAULT, DRAFT_UNIT_DEFAULT } from '../../../../constants/units'
import shipService from '../../../../services/shipService'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import swal from 'sweetalert';
import '../../../../styles/variables/Common.scss'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner'


interface ShipAddProps {
  id?: number,
  shipName: string,
  shipType: any[],
  shipTypes: string,
  countryFlag: any[],
  countryFlags: string,
  classification: any[],
  classifications: string,
  material: any[],
  materials: string,
  IMONumber: string,
  shipCompanies: any[],
  shipCompany: string,
  Weight: string,
  Length: string,
  Beam: string,
  Draft: string,
  Depth: string,
  status: string,
  companyID: string,
  nrt: string,
  grt: string,
  dwt: string,
  lbp: string,
  yearBuilt: string,
  builderAddress: string,
  builderName: string,
  shipCall: string,
  auxEngine: string,
  mainEngine: string,
  picCredit: string,
  inService: string,
  shipOperator: string,
  shipOperatorAddress: string,
  shipClassificationId2: any[],
  shipClassificationId2s: string,
  specialSurveyDate: string,
  dryDockDate: string,
  sourceStatus: string,
  mmsi:string
}
interface FormData {
  txt_shipAddName: string;
  txt_shipAddType: string;
  txt_shipAddCountry: string;
  txt_shipAddClassification: string;
  txt_shipAddImo: string;
  txt_shipAddCompany: string;
  txt_shipAddWeight: string;
  txt_shipAddLength: string;
  txt_shipAddBeam: string;
  txt_shipAddDraft: string;
  txt_shipAddDepth: string;
  txt_shipAddMaterial: string;
  radio_shipAddStatus: string;
  txt_shipnrt: string;
  txt_shipgrt: string;
  txt_shipdwt: string;
  txt_shiplbp: string;
  txt_yearBuilt: string;
  txt_builderAddress: string;
  txt_builderName: string;
  txt_shipCall: string;
  txt_auxEngine: string;
  txt_mainEngine: string;
  txt_picCredit: string;
  txt_inService: string;
  txt_shipOperator: string;
  txt_shipOperatorAddress: string;
  txt_shipClassificationId2: string;
  txt_specialSurveyDate: string;
  txt_dryDockDate: string;
  txt_sourceStatus: string;
  txt_mmsi: string;
}

interface bvalueProps {
  string_image: string | ArrayBuffer | null
}
export const ShipAdd = (props: ShipAddProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const [isLoading, setLoading] = React.useState(false)
  const [shipAddflag, setShipAddFlag] = React.useState(false)
  const upload = useRef(null);
  var base64: string | ArrayBuffer | null
  const [bvalue, setBValue] = React.useState<bvalueProps>({
    string_image: ''
  })
  const getBase64 = (event: any) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result);
      base64 = reader.result
      // console.log(base64,'base 64 value')
      setBValue(
        {
          string_image: reader.result
        })
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  function formatDate(newDate: any) {
    console.log("new date"+ newDate)
    if (newDate!=null){
    type tplotOptions = {
        [key: string]: string
    }
    const  months:tplotOptions = {
      0: 'JAN',
      1: 'FEB',
      2: 'MAR',
      3: 'APR',
      4: 'MAY',
      5: 'JUN',
      6: 'JUL',
      7: 'AUG',
      8: 'SEP',
      9: 'OCT',
      10: 'NOV',
      11: 'DEC',
    }
    const d = new Date(newDate)
    const year = d.getFullYear()
    const date = d.getDate()
    const monthIndex = d.getMonth()
    const monthName = months[monthIndex]
    const formatted = `${date} ${monthName} ${year}`
    console.log(formatted.toString())
    return formatted.toString()
}else{
    console.log("empty string")
    return ''
}
  }
  const [ship, setShip] = useState<ShipAddProps>({
    shipName: '',
    shipType: [],
    shipTypes: '',
    countryFlag: [],
    countryFlags: '',
    classification: [],
    classifications: '',
    material: [],
    materials: '',
    IMONumber: '',
    shipCompanies: [],
    shipCompany: '',
    Weight: '',
    Length: '',
    Beam: '',
    Draft: '',
    status: '',
    companyID: '',
    Depth: '',
    nrt: '',
    grt: '',
    dwt: '',
    lbp: '',
    yearBuilt: '',
    builderAddress: '',
    builderName: '',
    shipCall: '',
    auxEngine: '',
    mainEngine: '',
    picCredit: '',
    inService: '',
    shipOperator: '',
    shipOperatorAddress: '',
    shipClassificationId2s: '',
    shipClassificationId2: [],
    specialSurveyDate: '',
    dryDockDate: '',
    sourceStatus: '',
    mmsi:''
  },
  )
  // Used to get ship classifications list and store to classifications variable. on 04-Mar-2021
  const _getClassification = (() => {
    return codeService.getShipClassification().subscribe({
      next: r => {

        // console.log(r.data,"ship classification")
        setShip({ ...ship, classification: r.data })
        // console.log(ship.classification ,'ship classification')
        r.data.map((data: any, i: any) => {
          return ship.classification.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  const _getClassification2 = (() => {
    return codeService.getShipClassification().subscribe({
      next: r => {

        // console.log(r.data,"ship classification")
        setShip({ ...ship, shipClassificationId2: r.data })
        // console.log(ships.classification ,'ship classification')
        r.data.map((data: any, i: any) => {
          return ship.shipClassificationId2.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  const _getMaterial = (() => {
    return codeService.getShipMaterial().subscribe({
      next: r => {

        // console.log(r.data,"ship classification")
        setShip({ ...ship, material: r.data })
        // console.log(ship.classification ,'ship classification')
        r.data.map((data: any, i: any) => {
          return ship.material.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  // Used to get ship types list and store to types variable. on 04-Mar-2021
  const _getShipType = (() => {
    return codeService.getShipTypes().subscribe({
      next: r => {

        // console.log(r.data,"ship type")
        setShip({ ...ship, shipType: r.data })
        // console.log(ship.shipType ,'ship type data in ship add page')
        r.data.map((data: any, i: any) => {
          return ship.shipType.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  const _getCountryFlag = (() => {
    return codeService.getCountries().subscribe({
      next: r => {
        setShip({ ...ship, countryFlag: r.data })
        r.data.map((data: any, i: any) => {
          return ship.countryFlag.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  // Used to get ship companies list and store to companies variable. on 04-Mar-2021
  const _getCompany = (() => {
    return codeService.getCompanies({ company_type: 'SHIP' }).subscribe({
      next: r => {
        // console.log(r.data,'companies in ship add page')
        setShip({ ...ship, shipCompanies: r.data })
        r.data.map((data: any, i: any) => {
          return ship.shipCompanies.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  // Call classification, company, shiptype and country functions at the stage of page loading. on 04-Mar-2021
  React.useEffect(() => {
    _getClassification()
    _getCompany()
    _getShipType()
    _getCountryFlag()
    _getMaterial()
    _getClassification2()
  }, [])

  // After enter all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call. on 04-Mar-2021
  const _didShipAdd = () => {
    setLoading(true)
    // e.preventDefault()
    ship.shipCompanies.map((data, i) => {
      if (ship.shipCompany === data.name) {
        setShip({ ...ship, companyID: String(data.id) })
      }
    })
    console.log(bvalue.string_image, 'base 64')
    console.log(ship.companyID)
    var specialSurveyDate: any
    if (ship.specialSurveyDate !== null) {
      specialSurveyDate = String(ship.specialSurveyDate)
    }
    else {
      specialSurveyDate = null
    }
    var dryDockDate: any
    if (ship.dryDockDate !== null) {
      dryDockDate = String(ship.dryDockDate)
    }
    else {
      dryDockDate = null
    }
    const updatedForm = {
      shipTypeCode: ship.shipTypes,
      shipWeight: Number(ship.Weight),
      weightUnitCode: WEIGHT_UNIT_DEFAULT.code,
      length: Number(ship.Length),
      lengthUnitCode: LENGTH_UNIT_DEFAULT.code,
      maximumBeam: Number(ship.Beam),
      beamUnitCode: BEAM_UNIT_DEFAULT.code,
      draft: Number(ship.Draft),
      draftUnitCode: DRAFT_UNIT_DEFAULT.code,
      depth: Number(ship.Depth),
      draftUnit: DRAFT_UNIT_DEFAULT.code,
      countryFlagCode: ship.countryFlags,
      companyCode: ship.companyID,
      status: ship.status,
      classificationCode: Number(ship.classifications),
      materialCode: ship.materials,
      imoNumber: ship.IMONumber,
      shipName: ship.shipName,
      image: bvalue.string_image,
      nrt: ship.nrt,
      grt: ship.grt,
      dwt: ship.dwt,
      lbp: ship.lbp,
      yearBuilt: ship.yearBuilt,
      builderAddress: ship.builderAddress,
      builderName: ship.builderName,
      shipCall: ship.shipCall,
      auxEngine: ship.auxEngine,
      mainEngine: ship.mainEngine,
      picCredit: ship.picCredit,
      inService: ship.inService,
      shipOperator: ship.shipOperator,
      shipOperatorAddress: ship.shipOperatorAddress,
      shipClassificationId2: ship.shipClassificationId2s,
      specialSurveyDate: specialSurveyDate,
      dryDockDate: dryDockDate,
      sourceStatus: ship.sourceStatus,
      mmsi:ship.mmsi,
    }

    // API call for addship with data, added to list. on 04-Mar-2021
    // console.log(updatedForm,"updatedform")

    if (shipAddflag === true) {
      shipService.addShip(updatedForm).subscribe({
        next: (r) => {
          // console.log(r,'response')
          setLoading(true)
          swal("Great", "Ship Added Successfully", "success")
          history.push('/ships/list')
        },
        error: err => {
          swal("OOPS", String(err), "error")
          setLoading(false)
        }
      })
    }
    else {
      console.log(shipAddflag, 'flag')
    }
  }
  onsubmit = (e: any) => {
    // e.preventDefault()
    if (Object.keys(errors).length === 0) {
      _didShipAdd()
      setShipAddFlag(true)
    }
    else {
      setShipAddFlag(false)
    }
  }

  if (isLoading) {
    return <Text center>
      <Loader
        type="Rings"
        color="#233269"
        height={100}
        width={100}
        timeout={30000}
      />
    </Text>
  }

  return (
    <Main>
      <Text variant="title" color='black'>Add Ship Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='ShipAddForms'>
          <Card title="Fill All the Information Below">
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Ship Name</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_shipAddName"
                      id='txt_shipAddName'
                      value={ship.shipName}
                      onChange={(e) => setShip({ ...ship, shipName: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Ship Name"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddName?.type === 'required' && 'Please Enter Ship Name'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>IMO Number</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipAddImo"
                      value={ship.IMONumber}
                      // ref={register({ required: true, pattern: /^IMO [1-9]{7}/ })}
                      // ref={register({ required: true })}
                      // step='0.01'
                      type='text'
                      // min={0}
                      // max={99999}
                      placeholder="IMO Number"
                      onChange={e => setShip({ ...ship, IMONumber: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>
                        {errors.txt_shipAddImo?.type === 'required' && 'Please enter IMO Number'}
                        {errors.txt_shipAddImo?.type === 'pattern' && 'IMO number should be IMO followed by 7 digits'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Ship Type</Text>
                    <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                      value={ship.shipTypes} name='txt_shipAddType' onChange={(e) => setShip({ ...ship, shipTypes: e.target.value })} ref={register({ required: true, })}>
                      <option value="">-- Select --</option>
                      {/* Map the data's from shiptype array and used as a option value. on 04-Mar-2021 */}
                      {ship.shipType.map((data: any, i: any) => {
                        return <option key={i} value={data.id}>{data.name}</option>
                      })}
                    </Form.Control>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddType?.type === 'required' && 'Please Select Ship Type'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Country Flag</Text>
                    <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                      value={ship.countryFlags} name='txt_shipAddCountry' onChange={(e) => setShip({ ...ship, countryFlags: e.target.value })} ref={register({ required: true, })}>
                      <option value="">-- Select --</option>
                      {/* Map the data's from countryflag array and used as a option value. on 04-Mar-2021 */}
                      {ship.countryFlag.map((data, i) => {
                        // console.log(data.id,'id in ship country')
                        return <option key={i} value={data.id}>{data.name}</option>
                      })}
                    </Form.Control>
                    {/* {console.log(ship.countryFlags,'country flag')} */}
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddCountry?.type === 'required' && 'Please select Country name'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Hull Materials</Text>
                    <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                      value={ship.materials} name='txt_shipAddMaterial'
                      onChange={e => setShip({ ...ship, materials: e.target.value })} 
                      // ref={register({ required: true, })}
                      >
                      <option value="">-- Select --</option>
                      {/* Map the data's from classification array and used as a option value. on 04-Mar-2021 */}
                      {ship.material.map((data: any, i: any) => {
                        return <option key={i} value={data.id}>{data.name}</option>
                      })}
                    </Form.Control>
                  </div>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Length Overall (m)</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipAddLength"
                      value={ship.Length}
                      ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="Length"
                      onChange={e => setShip({ ...ship, Length: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddLength?.type === 'required' && 'Please enter Ship Length'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>LBP</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shiplbp"
                      value={ship.lbp}
                      onChange={e => setShip({ ...ship, lbp: e.target.value })}
                      // ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="lbp"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shiplbp?.type === 'required' && 'Please enter LBP'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Maximum Beam (m)</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipAddBeam"
                      value={ship.Beam}
//                      ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="Beam"
                      onChange={e => setShip({ ...ship, Beam: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddBeam?.type === 'required' && 'Please enter Ship beam'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Depth (m)</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipAddDepth"
                      value={ship.Depth}
                      // ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="Depth"
                      onChange={e => setShip({ ...ship, Depth: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddDepth?.type === 'required' && 'Please enter Ship Depth'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Draft (m)</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipAddDraft"
                      value={ship.Draft}
                      // ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="Draft"
                      onChange={e => setShip({ ...ship, Draft: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddDraft?.type === 'required' && 'Please enter Ship Draft'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Weight (DWT)</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipAddWeight"
                      value={ship.Weight}
                      // ref={register({ required: true, })}
                      type='number'
                      // min={0.00}
                      // max={99999.00}
                      placeholder="Weight"
                      onChange={e => setShip({ ...ship, Weight: e.target.value })}
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddWeight?.type === 'required' && 'Please enter Ship weight'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>GRT</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipgrt"
                      value={ship.grt}
                      onChange={e => setShip({ ...ship, grt: e.target.value })}
                      // ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="grt"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipgrt?.type === 'required' && 'Please enter GRT'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>NRT</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipnrt"
                      value={ship.nrt}
                      onChange={e => setShip({ ...ship, nrt: e.target.value })}
                      // ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="nrt"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipnrt?.type === 'required' && 'Please enter NRT'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>DWT</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipdwt"
                      value={ship.dwt}
                      onChange={e => setShip({ ...ship, dwt: e.target.value })}
                      // ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="dwt"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipdwt?.type === 'required' && 'Please enter DWT'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes >
                  <div>
                    <Text color='tablehead' variant='subtitle'>inService</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_inService"
                      value={ship.inService}
                      onChange={e => setShip({ ...ship, inService: e.target.value })}
                      // ref={register({ required: true, })}
                      type='text'
                      // min={0}
                      // max={99999}
                      placeholder="inService"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_inService?.type === 'required' && 'Please enter inService'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Ship Call</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipCall"
                      value={ship.shipCall}
                      onChange={e => setShip({ ...ship, shipCall: e.target.value })}
                      // ref={register({ required: true, })}
                      type='text'
                      // min={0}
                      // max={99999}
                      placeholder="Ship Call"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipCall?.type === 'required' && 'Please enter Ship Call'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Year Built</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_yearBuilt"
                      value={ship.yearBuilt}
                      onChange={e => setShip({ ...ship, yearBuilt: e.target.value })}
                      // ref={register({ required: true, })}
                      type='number'
                      // min={0}
                      // max={99999}
                      placeholder="Year Built"
                    />
                  </div>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Builder Name</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_builderName"
                      value={ship.builderName}
                      onChange={e => setShip({ ...ship, builderName: e.target.value })}
                      // ref={register({ required: true, })}
                      type='text'
                      // min={0}
                      // max={99999}
                      placeholder="Builder Name"
                    />
                  </div>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Ship Operator</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipOperator"
                      value={ship.shipOperator}
                      onChange={e => setShip({ ...ship, shipOperator: e.target.value })}
                      // ref={register({ required: true, })}
                      type='text'
                      // min={0}
                      // max={99999}
                      placeholder="ship operator"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipOperator?.type === 'required' && 'Please enter Ship Operator'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Ship Operator Address</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_shipOperatorAddress"
                      value={ship.shipOperatorAddress}
                      onChange={e => setShip({ ...ship, shipOperatorAddress: e.target.value })}
                      // ref={register({ required: true, })}
                      type='text'
                      // min={0}
                      // max={99999}
                      placeholder="ship operator address"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipOperatorAddress?.type === 'required' && 'Please enter Ship Operator Address'}
                      </span>
                    </Col>
                  </Row>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_builderName?.type === 'required' && 'Please enter Builder Name'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Classification</Text>
                    <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                      value={ship.classifications} name='txt_shipAddClassification'
                      onChange={e => setShip({ ...ship, classifications: e.target.value })} 
                      // ref={register({ required: true, })}
                      >
                      <option value="">-- Select --</option>
                      {/* Map the data's from classification array and used as a option value. on 04-Mar-2021 */}
                      {ship.classification.map((data: any, i: any) => {
                        return <option key={i} value={data.id}>{data.name}</option>
                      })}
                    </Form.Control>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddClassification?.type === 'required' && 'Please select Ship Classification'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Classification 2</Text>
                    <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                      name='txt_shipEditClassificationId2'
                      value={ship.shipClassificationId2s}
                      onChange={e => setShip({ ...ship, shipClassificationId2s: e.target.value })}
                    // ref={register({ required: true, })}
                    >
                      <option value="">-- Select --</option>
                      {/* Map the data's from classification array and used as a option value. By Dharani on 04-Mar-2021 */}
                      {ship.shipClassificationId2.map((data: any, i: any) => {
                        return <option key={i} value={data.id}>{data.name}</option>
                      })}
                    </Form.Control>
                  </div>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Aux Engine</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_auxEngine"
                      value={ship.auxEngine}
                      onChange={e => setShip({ ...ship, auxEngine: e.target.value })}
                      // ref={register({ required: true, })}
                      type='text'
                      // min={0}
                      // max={99999}
                      placeholder="Aux Engine"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_auxEngine?.type === 'required' && 'Please enter Aux Engine'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Main Engine</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_mainEngine"
                      value={ship.mainEngine}
                      onChange={e => setShip({ ...ship, mainEngine: e.target.value })}
                      // ref={register({ required: true, })}
                      type='text'
                      // min={0}
                      // max={99999}
                      placeholder="Main Engine"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_mainEngine?.type === 'required' && 'Please enter Main Engine'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Pic Credit</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_picCredit"
                      value={ship.picCredit}
                      onChange={e => setShip({ ...ship, picCredit: e.target.value })}
                      // ref={register({ required: true, })}
                      type='text'
                      // min={0}
                      // max={99999}
                      placeholder="Pic Credit"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_picCredit?.type === 'required' && 'Please enter Aux Engine'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Source Data</Text>
                    <Form.Control as="select"
                      name="txt_sourceStatus"
                      className='basecontrol cutome-selectInputnxt'
                      value={ship.sourceStatus}
                      // ref={register({ required: true, })}
                      onChange={(e) => setShip({ ...ship, sourceStatus: e.target.value })} ref={register({ required: true, })}>
                      <option value="">-- Select status --</option>
                      <option value="BKI">BKI</option>
                      <option value="DATALYSTICS">DATALASTIC</option>
                    </Form.Control>

                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_sourceStatus?.type === 'required' && 'Please select Data status'}
                      </span>
                    </Col>
                  </Row>

                  {ship.sourceStatus !== 'BKI' ? (
                    <>
                      <div>
                        <Text color='black' variant='subtitle'>Dry Date</Text>
                        {ship.dryDockDate !== null && ship.dryDockDate !== '' ? (
                          <div>
                            <Text color='black' >{formatDate(ship.dryDockDate)}</Text>
                            <input className="date-picker-css" type="date" id="dryDockDate" name="dryDockDate" value={ship.dryDockDate}
                              onChange={(e) => setShip({ ...ship, dryDockDate: e.target.value })}>
                            </input>
                          </div>
                        ) : (
                          <div><Text color='black' >DD MM YYYY</Text>
                            <input className="date-picker-css" type="date" id="dryDockDate" name="dryDockDate" value={ship.dryDockDate}
                              onChange={(e) => setShip({ ...ship, dryDockDate: e.target.value })}>
                            </input>
                          </div>

                        )}
                      </div>
                      <div>
                        <Text color='black' variant='subtitle'>Special Survey Date</Text>
                        {ship.specialSurveyDate !== null && ship.specialSurveyDate !== '' ? (
                          <div>
                            <Text color='black' >{formatDate(ship.specialSurveyDate)}</Text>
                        <input className="date-picker-css" type="date" id="specialSurveyDate" name="specialSurveyDate" value={ship.specialSurveyDate}
                          onChange={(e) => setShip({ ...ship, specialSurveyDate: e.target.value })}>
                        </input>
                      </div>
                      ) : (
                        <div><Text color='black' >DD MM YYYY</Text>
                          <input className="date-picker-css" type="date" id="specialSurveyDate" name="specialSurveyDate" value={ship.specialSurveyDate}
                          onChange={(e) => setShip({ ...ship, specialSurveyDate: e.target.value })}>
                        </input>
                        </div>
                      )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <Text color='black' variant='subtitle'>Last Docking Date</Text>
                        {ship.dryDockDate !== null && ship.dryDockDate !== '' ? (
                          <div>
                            <Text color='black' >{formatDate(ship.dryDockDate)}</Text>
                            <input className="date-picker-css" type="date" id="dryDockDate" name="dryDockDate" value={ship.dryDockDate}
                              onChange={(e) => setShip({ ...ship, dryDockDate: e.target.value })}>
                            </input>
                          </div>
                        ) : (
                          <div><Text color='black' >DD MM YYYY</Text>
                            <input className="date-picker-css" type="date" id="dryDockDate" name="dryDockDate" value={ship.dryDockDate}
                              onChange={(e) => setShip({ ...ship, dryDockDate: e.target.value })}>
                            </input>
                          </div>

                        )}
                      </div>
                    </>
                  )}
                </Attributes>
              </Col>
            </Row>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Mmsi</Text>
                    <Form.Control className='basecontrol custome-input'
                      name="txt_mmsi"
                      value={ship.mmsi}
                      // ref={register({ required: true, pattern: /^IMO [1-9]{7}/ })}
                      // ref={register({ required: true })}
                      // step='0.01'
                      type='text'
                      // min={0}
                      // max={99999}
                      placeholder="Mmsi"
                      onChange={e => setShip({ ...ship, mmsi: e.target.value })}
                    />
                  </div>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Status</Text>
                    <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                      value={ship.status} name='radio_shipAddStatus' onChange={e => setShip({ ...ship, status: e.target.value })} ref={register({ required: true, })}>
                      <option value="">-- Select --</option>
                      <option value='VERIFIED'>Verified</option>
                      <option value='UNVERIFIED'>Not Verified</option>
                    </Form.Control>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.radio_shipAddStatus?.type === 'required' && 'Please select Ship status'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  {/* <div>
                      <Text color='tablehead' variant='subtitle'>Company</Text>
                      <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                       value={ship.shipCompany} name="txt_shipAddCompany"
                       ref={register({ required: true, })}
                        onChange={(e) => setShip({ ...ship, shipCompany: e.target.value })}>
                        <option value=''>-- Select Company --</option>
                        {ship.shipCompanies.map((data,i)=>{
                            console.log(data.id,'company id')
                            return <option key={i} value={data.id}>{data.name}</option>
                        })}
                      </Form.Control>
                    </div> */}
                  <div>
                    <Text color='tablehead' variant='subtitle'>Company</Text>
                    <input className='basecontrol cutome-selectInputnxt'
                      value={ship.shipCompany}
                      name="txt_shipAddCompany"
                      id='txt_shipAddCompany'
                      list='shipcompanydatalist'
                      ref={register({ required: true, })}
                      placeholder='-- Select Company --'
                      onChange={(e) => setShip({ ...ship, shipCompany: e.target.value })}>
                      {/* <option value=''></option> */}
                    </input>
                    <datalist id='shipcompanydatalist'>
                      {ship.shipCompanies.map((data, i) => {
                        // console.log(data.id,'company id')
                        return <option key={i} value={data.name}></option>
                      })}
                    </datalist>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_shipAddCompany?.type === 'required' && 'Please enter Ship Company'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
            </Row>
            <Row className='m-0 p-0'>
              <Col xs='12' sm='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                <Attributes>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Ship's Image</Text>
                    <input
                      ref={upload}
                      type="file"
                      onChange={getBase64}
                    />
                  </div>
                </Attributes>
              </Col>
            </Row>
          </Card>
          <Actions>
            {/* If you click the back button its is redirect to ships list page. on 04-Mar-2021 */}
            <Link to='/ships/list'>
              <Button className='bttn bttn-transparent' block={true}>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page. on 04-Mar-2021 */}
            <Button className='bttn bttn-transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </Form>
      </Grid>
      {/* Add form, having input box, select box. on 04-Mar-2021 */}
    </Main>
  )
}


export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. on 04-Mar-2021
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-ship-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. on 04-Mar-2021
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}
