import * as Observable from 'rxjs'
import { timeout, map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getPropulsionList = (params : any) => {
    return http({
      method: 'GET',
      url: '/ships/shipPropulsionType',
      params: { ...params}
    }).pipe(
      map(res => {
        if (res.success) {
          // console.log(res,'ship company')
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }

const getPropulsionDetail = (id : any) => {
    return http({
      method: 'GET',
      url: `/ships/shipPropulsionView/${id}`
    }).pipe(
      map(res => {
        if (res.success) {
          console.log(res, 'company view data')
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }
  
  const propulsionShipDelete = (id: any) => {
    return httpDelete({
      method: 'DELETE',
      url: `/ships/shipPropulsionDelete/${id}`,
    }).pipe(
      map(res => {
        // console.log(res, 'delete response')
        if (res) {
          return res
        } else {
          // console.log(res, 'error message')
          throw new Error("Error")
        }
      })
    )
  }
  
  const updatePropulsionType = (body : any) => {
    return http({
      method: 'PUT',
      url: '/ships/shipPropulsionEdit',
      body: body
    }).pipe(
      map(res => {
        if (res.success) {
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }
  
  const addpropulsion = (body : any) => {
    return http({
      method: 'POST',
      url: '/ships/shipPropulsionAdd',
      body
    }).pipe(
      map(res => {
        if (res.success) {
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }

  const datalasticShipCmpService = {
    getPropulsionDetail,
    propulsionShipDelete,
    updatePropulsionType,
    addpropulsion,
    getPropulsionList,
  }
  
  export default datalasticShipCmpService