import { connect } from "react-redux";
import { manufacturerSetSelectedAction } from '../../../../store/action/manufacturerAction'
import React from "react";
import { Col, Image, Row } from 'react-bootstrap'
import { Text } from '../../../../common/components/text/Text'
import { Main } from "../../../../common/components/main/Main";
import { Space } from "../../../../common/components/space/Space";
import Card from "../../../../common/components/card/Card";
import { Attributes } from "../../../../common/components/attribute/Attribute";
import classNames from "classnames";
import { Flex } from "../../../../common/components/flex/Flex";
import { Link } from "react-router-dom";
import { Button } from "../../../../common/components/button/Button";
import Icons from "../../../../common/components/icon/Icon";
import manufacturerService from '../../../../services/manufacturerService'
import { Label } from "../../../../common/components/label/Label";
import Loader from "react-loader-spinner";
import NumberFormat from 'react-number-format';


const ManufacturerAdsDetail = (props: any) => {
    const { match } = props
    const [isLoading, setLoading] = React.useState(true)
    const [noAds, setNoAds] = React.useState(false)
    const [display, setDisplay] = React.useState<any>({
        userid: '',
        name: '',
        budget: '',
        amount: '',
        adtype: '',
        min_ship_area: '',
        max_ship_area: '',
        ship_material_name: '',
        provinces_name: '',
        ship_type_name: '',
        ship_type_id: '',
        ship_sub_type_name: '',
        activeStatus: '',
    })
    const { id } = match.params

    //Call perticular ship detail function.  
    React.useEffect(() => {
        _getManufacturerDetail(id)
    }, [])

    const role=localStorage.getItem("role")

    //Get perticular ship detail using match.params. 
    const _getManufacturerDetail = (id: any) => {
        // console.log(id,'id')
        setLoading(true)
        return manufacturerService.getManufacturerAdsDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.manufacturerSetSelectedAction(r.data)
                var ActiveStatus: any;
                if (r.data.isActive === true) {
                    ActiveStatus = 'Active'
                }
                else if (r.data.isActive === false) {
                    ActiveStatus = 'In Active'
                }
                else {
                    ActiveStatus = null
                }
                setDisplay({
                    userid: r.data.id,
                    name: r.data.name,
                    amount: r.data.amount,
                    budget: r.data.budget,
                    adtype: r.data.adtype,
                    min_ship_area: r.data.min_ship_area,
                    max_ship_area: r.data.max_ship_area,
                    ship_material_name: r.data.ship_material_name,
                    provinces_name: r.data.provinces_name,
                    ship_type_name: r.data.ship_type_name,
                    ship_sub_type_name: r.data.ship_sub_type_name,
                    ship_type_id: r.data.shipTypeId,
                    activeStatus: ActiveStatus,
                })
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
                setNoAds(true)
            }
        })
    }

    // If isloading is true print a text as loading. 
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    // If isloading is false print the details of perticular ship. 
    return (
        <Main>
            {noAds && (
                <>
                    <Space divheight={20} />
                    <Text center>Unable to view your Advertisement</Text>
                    <Space divheight={20} />
                    <Row className='m-0 p-0 text-center'>
                        <Col className='m-0 p-0'>
                            <Link to='/manufacturer/list'>
                                <Button type="button" variant='transparent'>
                                    <Flex jc='jc-around'>
                                        <Icons icon="arrow_back" />
                                        BACK
                                    </Flex>
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </>
            )}
            {!noAds && (
                <>
                    <Space divheight={15} />
                    <div className='title-name-block'>
                        <Text variant='title' color='main' className='txt-capitalize'>
                            {/* {String(display.email).substring(0,String(display.email).indexOf('@'))} */}
                            {display.name}
                        </Text>
                        {/* <Label className={display.isactive === false ? 'danger' : 'warning'} >
                            {display.isactive === false ? 'In Active' : 'Active'}
                        </Label> */}
                    </div>
                    <Space divheight={15} />
                    <Row className='m-0 p-0'>
                        {/* {console.log(display,'detail')} */}
                        <Col sm='12' md='12' lg='10' xl='9' className='m-0 p-0 align-top'>
                            <Card title="Advertisement Detail">
                                <Row className='m-0 p-0'>
                                    <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='view_Page'>
                                            <div>
                                                <Text color='main' variant='subtitle'>Name</Text>
                                                {display.name === '' ? (
                                                    <Text color='main' variant='subtitle' > - </Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold >{display.name}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>balance / budget</Text>
                                                {display.amount === null && (
                                                    <Text color='main' variant='subtitle' bold> - </Text>
                                                )}
                                                {display.amount !== null && (
                                                    <>
                                                        <Text color='main' variant='subtitle' bold>

                                                            {!String(display.amount).includes('.') ? (
                                                                <>
                                                                    <NumberFormat value={String(display.amount)} displayType={'text'} thousandSeparator={true}/><span>.00 &nbsp;/&nbsp;</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <NumberFormat value={display.amount} displayType={'text'} thousandSeparator={true}/><span>&nbsp;/&nbsp;</span>
                                                                </>
                                                            )}
                                                            {!String(display.budget).includes('.') ? (
                                                                <>
                                                                    <NumberFormat value={String(display.budget)} displayType={'text'} thousandSeparator={true}/><span>.00</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <NumberFormat value={display.budget} displayType={'text'} thousandSeparator={true}/>
                                                                </>
                                                            )}

                                                        </Text>
                                                    </>
                                                )}
                                            </div>
                                            {/* <Text color='main' variant='subtitle' bold >
                                                Rp. {display.amount} / Rp. {display.budget}
                                            </Text> */}
                                            <div>
                                                <Text color='main' variant='subtitle'>Advertisement Type</Text>
                                                {display.adtype === null ? (
                                                    <Text color='main' variant='subtitle' bold> - </Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold> {display.adtype} </Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Active Status</Text>
                                                {display.activeStatus === null ? (
                                                    <Text color='main' variant='subtitle' bold> - </Text>
                                                ) : (
                                                    <>
                                                        {display.activeStatus === 'Active' ? (
                                                            <Text color='success' variant='subtitle' bold> Active </Text>
                                                        ) : (
                                                            <Text color='danger' variant='subtitle' bold> In Active </Text>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Provinces</Text>
                                                {display.provinces_name === null || '' ? (
                                                    <Text color='main' bold variant='subtitle'> - </Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'> {display.provinces_name} </Text>
                                                )}
                                            </div>
                                        </Attributes>
                                    </Col>
                                    <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes className='view_Page'>
                                            <div>
                                                <Text color='main' variant='subtitle'>Ship Material</Text>
                                                {display.ship_material_name === null ? (
                                                    <Text color='main' variant='subtitle' > - </Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold >{String(display.ship_material_name).replace('/', ' /')}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Ship Type</Text>
                                                {display.ship_type_name === null ? (
                                                    <Text color='main' variant='subtitle' bold> - </Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold>{String(display.ship_type_name).replace('/', ' /')}</Text>
                                                )}
                                            </div>
                                            {/* <div>
                                                <Text color='main' variant='subtitle'>Ship Sub Type</Text>
                                                {display.ship_sub_type_name === null || '' ? (
                                                    <Text color='main' variant='subtitle' bold > - </Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold >{String(display.ship_sub_type_name).replace('/',' /')}</Text>
                                                )}
                                            </div> */}

                                            <div>
                                                <Text color='main' variant='subtitle'>Minimum Ship Length</Text>
                                                {display.min_ship_area === null && (
                                                    <Text color='main' variant='subtitle' bold>--</Text>
                                                )}
                                                {display.min_ship_srea !== null && (
                                                    <Text color='main' variant='subtitle' bold>
                                                        {display.min_ship_area}
                                                    </Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>Maximum Ship Length</Text>
                                                {display.max_ship_area === null || '' ? (
                                                    <Text color='main' variant='subtitle' > - </Text>
                                                ) : (
                                                    <Text color='main' variant='subtitle' bold>{display.max_ship_area}</Text>
                                                )}
                                            </div>

                                        </Attributes>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                    </Row>
                    <Row className='m-0 p-0'>
                        <Col xs='12' md='12' lg='10' xl='9' className='m-0 p-0'>
                            <Actions>
                                {/* If you click the back button its is redirect to ships list page.  */}
                                <Link to='/manufacturer/list'>
                                    <Button type="button" variant='transparent'>
                                        <Flex jc='jc-around'>
                                            <Icons icon="arrow_back" />
                                            BACK
                                        </Flex>
                                    </Button>
                                </Link>
                                {/* <Link to={`/perViewList/list/${display.userid}`} className='mr-3'>
                                    <Button type="button" variant='transparent'>
                                        <Flex jc='jc-around'>
                                            View ADS Info
                                        </Flex>
                                    </Button>
                                </Link> */}
                                <LinkActions>
                                    <Link to={`/perViewList/list/${display.userid}`} className='mr-3'>
                                        <Button type="button" variant='transparent'>
                                            <Flex jc='jc-around'>
                                                View Ad's Info
                                            </Flex>
                                        </Button>
                                    </Link>
                                    {role === 'VISITOR' ? (
                        //                <Link to={`/manufacturer/ads/edit/${display.ship_type_id}/${display.userid}`}>
                                            <Button type="button" variant='transparent' disabled>
                                                <Flex jc='jc-around'>
                                                    <Icons icon="edit" />
                                                    EDIT
                                                </Flex>
                                            </Button>
                          //              </Link>
                                    ) : (
                                        <Link to={`/manufacturer/ads/edit/${display.ship_type_id}/${display.userid}`}>
                                            <Button type="button" variant='transparent'>
                                                <Flex jc='jc-around'>
                                                    <Icons icon="edit" />
                                                    EDIT
                                                </Flex>
                                            </Button>
                                        </Link>
                                    )}
                                </LinkActions>
                            </Actions>
                        </Col>
                    </Row>
                </>
            )}

        </Main>
    )
}
export interface ActionProps {
    children?: React.ReactNode
}

const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

export interface LinkActionProps {
    children?: React.ReactNode
}

const LinkActions = ({ ...props }: ActionProps) => {
    const action = classNames('LinkAction-container');
    return (
        <Flex jc='jc-flexend' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    manufacturer: state.manufacturer.selectedManufacturer
})

// connect to manufacturerSetSelectedAction and get the one perticular ship data. 
export default connect(
    mapStateToProps,
    {
        manufacturerSetSelectedAction
    }
)(ManufacturerAdsDetail)