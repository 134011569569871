import {
    UNITS_LIST_GET,
    UNITS_LIST_GET_F,
    UNITS_LIST_GET_R,
    UNITS_SELECTED_SET
} from '../actionType'

const initialState = {
    units: [],
    isLoading: false,
    error: null,
    totalPage: 0,
    totalElement: 0,
    selectedUnits: null,
}

export default function UnitsReducer (state = initialState, action : any)  {
    switch (action.type) {
        case UNITS_LIST_GET:
            return {
            ...state,
            isLoading: true,
            error: null
            }
        case UNITS_LIST_GET_F:
            // console.log('action called')
            // console.log(action.payload.content,'content')
            return {
                ...state,
                isLoading: false,
                units: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
            }
        case UNITS_LIST_GET_R:
            
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case UNITS_SELECTED_SET:
            return {
                ...state,
                selectedUnits: action.payload
            }
        default:
            return state
    }
}