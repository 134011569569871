import {
    ROLE_LIST_GET,
    ROLE_LIST_GET_F,
    ROLE_LIST_GET_R,
    ROLE_SELECTED_SET
}  from '../actionType'

export const roleListGetAction = ( params : any) => ({
    type: ROLE_LIST_GET,
    payload: params
})
  
export const roleListGetFAction = ( data : any) => ({
    type: ROLE_LIST_GET_F,
    payload: data
})
  
export const roleListGetRAction = ( error : any) => ({
    type: ROLE_LIST_GET_R,
    payload: error
})
export const roleSetSelectedAction = (roles : any) => ({
    type: ROLE_SELECTED_SET,
    payload: roles
})