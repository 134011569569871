import React, { useState, useEffect } from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Form, Row, Col, Button, Image } from 'react-bootstrap'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Flex } from '../../../../common/components/flex/Flex'
import '../../../../common/components/control/Control.scss'
import { history } from '../../../../store';
import '../../../../common/components/button/Button.scss'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import codeService from '../../../../services/codeService'
import manufacturerService from '../../../../services/manufacturerService'
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { JsxChild } from 'typescript'
import { connect } from 'react-redux';
import { manufacturerSetSelectedAction } from '../../../../store/action/manufacturerAction';
import Select from 'react-select';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form'
import Loader from "react-loader-spinner";


interface FormData {
    txt_name: string,
    txt_amount: string,
    txt_adtype: string,
    txt_min_ship_area: string,
    txt_max_ship_area: string,
    txt_ship_material_name: string,
    txt_provinces_name: string,
    txt_ship_type_name: string,
    txt_ship_sub_type_name: string,
    radio_is_active: boolean;
};

interface ManufactrurerAdsEditprops {
    userid: number | string,
    name: string,
    amount: string | number,
    adtype: string,
    minShipArea: string | number,
    maxShipArea: string | number,
    ship_material_name: string,
    provinces_name: string,
    ship_type_name: string,
    ship_sub_type_name: string,
    ship_sub_type_id: string | number,
    shipTypeId: string | number,
    shipMaterialId: string | number,
    isActive: string,
    supplier_ads_province_settings_id: string,
}

const ManufacturerAdsEdit = (props: any) => {
    //const {dock} = props
    const { match } = props
    const { pages } = match.params
    const maxOptions = 3;
    const [selectedValue, setSelectedValue] = useState<any>([]);
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const [manuAdsEdit, setManuAdsEdit] = React.useState(false)
    const [isLoading, setLoading] = React.useState(true)
    const [ddDisable, setddDisable] = React.useState(false)
    const [display, setDisplay] = useState<ManufactrurerAdsEditprops>({
        userid: '',
        name: '',
        amount: '',
        adtype: '',
        minShipArea: '',
        maxShipArea: '',
        ship_material_name: '',
        provinces_name: '',
        ship_type_name: '',
        ship_sub_type_name: '',
        ship_sub_type_id: '',
        shipTypeId: '',
        shipMaterialId: '',
        isActive: '',
        supplier_ads_province_settings_id: ''
    })
    const [AdsEdit, setAdsEdit] = useState<any>({
        name: '',
        amount: '',
        adtype: '',
        min_ship_area: '',
        max_ship_area: '',
        ship_material_name: '',
        provinces_name: '',
        ship_type_name: '',
        ship_sub_type_name: '',
        subTypesArray: [],
        shipTypesArray: [],
        proviceArray: [],
        materialArray: [],
        adsTypeArray: []
    })
    const _getManufactDetailForEdit = (id: string | number) => {
        setLoading(true)
        return manufacturerService.getManufacturerAdsDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.manufacturerSetSelectedAction(r.data)
                if (r.data.ship_sub_type_name !== '') {
                    setddDisable(true)
                }
                var ProvincesType: any
                if (r.data.province === null || '') {
                    ProvincesType = ''
                }
                else {
                    ProvincesType = r.data.province
                }
                var ActiveStatus: any;
                if (r.data.isActive === true) {
                    ActiveStatus = 'true'
                }
                else if (r.data.isActive === false) {
                    ActiveStatus = 'false'
                }
                else {
                    ActiveStatus = null
                }
                setDisplay({
                    userid: r.data.id,
                    name: r.data.name,
                    amount: r.data.amount,
                    adtype: r.data.adtype,
                    minShipArea: r.data.minShipArea,
                    maxShipArea: r.data.maxShipArea,
                    ship_material_name: r.data.ship_material_name,
                    provinces_name: ProvincesType,
                    ship_type_name: r.data.ship_type_name,
                    ship_sub_type_name: r.data.ship_sub_type_name,
                    ship_sub_type_id: r.data.ship_sub_type_id,
                    shipTypeId: r.data.shipTypeId,
                    shipMaterialId: r.data.shipMaterialId,
                    isActive: ActiveStatus,
                    supplier_ads_province_settings_id: r.data.supplier_ads_province_settings_id,
                })
                setLoading(false)
                setSelectedValue(Array.isArray(ProvincesType) ? ProvincesType.map((x: any) => String(x.value)) : []);
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    const _getProvinces = (() => {
        setLoading(true)
        codeService.getProvincesDD().subscribe({
            next: r => {

                // console.log(r.data,"ship type")
                setAdsEdit({ ...AdsEdit, proviceArray: r.data })
                // console.log(ship.shipType ,'ship type data in ship add page')
                r.data.map((data: any, i: any) => {
                    return AdsEdit.proviceArray.push(data)
                })
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            },
        })
    })

    const _getShipMaterialValue = (() => {
        setLoading(true)
        return codeService.getShipMaterial().subscribe({
            next: r => {
                setAdsEdit({ ...AdsEdit, materialArray: r.data })
                r.data.map((data: any, i: any) => {
                    return AdsEdit.materialArray.push(data)
                })
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    })


    const _getAdsTypes = (() => {
        setLoading(true)
        return codeService.getAdsTypes().subscribe({
            next: r => {
                setAdsEdit({ ...AdsEdit, adsTypeArray: r.data })
                r.data.map((data: any, i: any) => {
                    return AdsEdit.adsTypeArray.push(data)
                })
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    })

    const _getShipType = (() => {
        return codeService.getShipTypes().subscribe({
            next: r => {
                setAdsEdit({ ...AdsEdit, shipTypesArray: r.data })
                r.data.map((data: any, i: any) => {
                    return AdsEdit.shipTypesArray.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })


    const _getSubType = ((value: string | number) => {
        setLoading(true)
        setDisplay({ ...display, shipTypeId: value })
        return codeService.getShipSubTypes(value).subscribe({
            next: r => {
                setAdsEdit({ ...AdsEdit, subTypesArray: r.data })
                r.data.map((data: any, i: any) => {
                    return AdsEdit.subTypesArray.push(data)
                })
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    })

    useEffect(() => {
        const { id } = match.params
        const { type } = match.params
        _getManufactDetailForEdit(id)
        _getAdsTypes()
        _getShipType()
        _getSubType(type)
        _getShipMaterialValue()
        _getProvinces()
    }, [])


    const _didManufactureEdit = () => {
        // e.preventDefault()
        // console.log(display.id,'id in edit updated form')
        var isActive: any
        if (display.isActive === 'true') {
            isActive = true
        }
        else {
            isActive = false
        }
        const updatedDockEditForm = {
            supplier_id: display.userid,
            amount: display.amount,
            adtype: display.adtype,
            min_ship_area: display.minShipArea,
            max_ship_area: display.maxShipArea,
            ship_materials_id: Number(display.shipMaterialId),
            province_id: selectedValue,
            shiptype_id: Number(display.shipTypeId),
            shipsubtype_id: 0,
            supplier_ads_province_settings_id: display.supplier_ads_province_settings_id,
            is_active: isActive
        }
        setLoading(true)
        if (manuAdsEdit === true) {
            manufacturerService.updatManufactureAdsEdit(updatedDockEditForm).subscribe({
                next: () => {
                    // console.log(pages,'pages')
                    setLoading(false)
                    swal("Great", "Ads detail Updated Successfully", "success")
                    history.push(`/manufacturer/ads/${display.userid}`)
                },
                error: err => {
                    setLoading(false)
                    swal("OOPS", String(err), "error")
                    console.error(err)
                }
            })
        }
        else {
            console.log(manuAdsEdit)
        }

    }
    const handleChange = (e: any) => {
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
    }
    const data = AdsEdit.proviceArray.map((data: any, i: any) => {
        // console.log(data,'data within options')
        return { label: String(data.name), value: String(data.id) }
    });

    onsubmit = (e: any) => {
        // e.preventDefault()
        if (Object.keys(errors).length === 0) {
            _didManufactureEdit()
            setManuAdsEdit(true)
        }
        else {
            setManuAdsEdit(false)
        }
    }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }
    return (
        <Main>
            <Text color='black' variant="title">Edit Ads Information</Text>
            <Space divheight={20} />
            <Row className='p-0 m-0'>
                <Col xs='12' sm='12' md='12' lg='9' xl='9' className='p-0 m-0'>
                    <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='userEditForms'>
                        <Card title="Fill the Information" >
                            <Row className='m-0 p-0'>
                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes>
                                        <div>
                                            <Text color="main" variant='subtitle'>Name</Text>
                                            <Form.Control
                                                type="text" className='basecontrol custome-input'
                                                name="txt_name"
                                                value={display.name}
                                                onChange={(e) => setDisplay({ ...display, name: e.target.value })}
                                                ref={register({ required: true, })}
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_name?.type === 'required' && 'Please enter User name'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color="main" variant='subtitle'> Available Amount </Text>
                                            <Form.Control
                                                type="number" className='basecontrol custome-input'
                                                name="txt_amount"
                                                value={display.amount}
                                                onChange={(e) => setDisplay({ ...display, amount: e.target.value })}
                                                ref={register({ required: true, })}
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_amount?.type === 'required' && 'Please enter Amount'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color="main" variant='subtitle'> Minimum Ship Length </Text>
                                            <Form.Control
                                                type="number" className='basecontrol custome-input'
                                                name="txt_min_ship_area"
                                                value={display.minShipArea}
                                                onChange={(e) => setDisplay({ ...display, minShipArea: e.target.value })}
                                                ref={register({ required: true, })}
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_min_ship_area?.type === 'required' && 'Please enter Dock Width'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color="main" variant='subtitle'>Maximum Ship Length</Text>
                                            <Form.Control
                                                type="number" className='basecontrol custome-input'
                                                name="txt_max_ship_area"
                                                value={display.maxShipArea}
                                                onChange={(e) => setDisplay({ ...display, maxShipArea: e.target.value })}
                                                ref={register({ required: true, })}
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_max_ship_area?.type === 'required' && 'Please enter Draft'}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Attributes>
                                </Col>
                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes>
                                        <div>
                                            <Text color="main" variant='subtitle'>Advertisement Type</Text>
                                            <Form.Control as='select'
                                                className='basecontrol cutome-selectInputnxt'
                                                value={display.adtype} name="txt_adtype"
                                                ref={register({ required: true, })}
                                                onChange={(e) => setDisplay({ ...display, adtype: e.target.value })}>
                                                <option className='option-placeholder'> -- Select Ads Type --</option>
                                                {AdsEdit.adsTypeArray.map((data: any, i: any) => {
                                                    // return <option key={i} value={data.name}>{String(data.name).italics}</option>
                                                    return <option key={i} value={data.name}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_adtype?.type === 'required' && 'Please select Advertisement Type'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color="main" variant='subtitle'>Ship Material</Text>
                                            <Form.Control as='select'
                                                className='basecontrol cutome-selectInputnxt'
                                                value={display.shipMaterialId}
                                                name="txt_ship_material_name"
                                                ref={register({ required: true, })}
                                                onChange={(e) => setDisplay({ ...display, shipMaterialId: e.target.value })}>
                                                <option className='option-placeholder'> -- Select Ship Material --</option>
                                                {AdsEdit.materialArray.map((data: any, i: any) => {
                                                    return <option key={i} value={data.id}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_ship_material_name?.type === 'required' && 'Please select Ship Material'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle' >Ship Type</Text>
                                            <Form.Control as='select'
                                                className='basecontrol cutome-selectInputnxt'
                                                value={display.shipTypeId} name="txt_ship_type_name"
                                                id='txt_ship_type_name'
                                                ref={register({ required: true, })}
                                                onChange={(e) => _getSubType(e.target.value)}>
                                                <option value=''> -- Select Ship Type -- </option>
                                                {AdsEdit.shipTypesArray.map((data: any, i: any) => {
                                                    return <option key={i} value={data.id}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_ship_type_name?.type === 'required' && 'Please Select Ship Type from a list'}
                                                </span>
                                            </Col>
                                        </Row>
                                        {/* <div>
                                            <Text color='tablehead' variant='subtitle' >Ship Sub Type</Text>
                                            {ddDisable === false && (
                                                <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                                    disabled>
                                                </Form.Control>
                                            )}
                                            {ddDisable === true && (
                                                <div>
                                                    <Form.Control as='select'
                                                        className='basecontrol cutome-selectInputnxt'
                                                        value={display.ship_sub_type_id} name="txt_ship_sub_type_name"
                                                        id='txt_ship_sub_type_name'
                                                        ref={register({ required: true, })}
                                                        onChange={(e) => setDisplay({ ...display, ship_sub_type_id: e.target.value })} >
                                                        <option className='option-placeholder' value=''> --Select Ship Sub Type first--</option>
                                                        {AdsEdit.subTypesArray.length === 0 && (
                                                            <option className='option-placeholder'> --NO SubTypes for selected Ship type-- </option>
                                                        )}
                                                        {AdsEdit.subTypesArray.map((data: any, i: any) => {
                                                            return <option key={i} value={data.id}>{data.name}</option>
                                                        })}
                                                    </Form.Control>
                                                </div>
                                            )}

                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_ship_sub_type_name?.type === 'required' && 'Please Select Ship Sub Type from a list'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                    </Attributes>
                                </Col>
                            </Row>
                            <Row className='m-0 p-0'>
                                <Col xs='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                                    <Attributes>
                                        <div>
                                            <Text color='tablehead' variant='subtitle' > Provinces</Text>
                                            <Select
                                                className="dropdown"
                                                placeholder="Select Ship Type"
                                                value={data.filter((obj: any) => selectedValue.includes(obj.value))}  // set selected values
                                                // options={data} 
                                                options={selectedValue.length === maxOptions ? [] : data}
                                                noOptionsMessage={() => {
                                                    return selectedValue.length === maxOptions
                                                        ? 'You Can Select Upto 3 Provinces Only'
                                                        : 'No options available';
                                                }}
                                                onChange={handleChange} // assign onChange function
                                                isMulti
                                                isClearable
                                                isSearchable
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_provinces_name?.type === 'required' && 'Please Select City'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle' > Active Status</Text>
                                            <div>
                                                <Form.Check type='radio' id='active' name='status' value={display.isActive}
                                                    className='RadioButton'
                                                    onChange={(e) => setDisplay({ ...display, isActive: 'true' })}
                                                    checked={display.isActive === 'true'}
                                                    label='ACTIVE'></Form.Check>
                                                <Form.Check type='radio' id='inactive' name='status' value={display.isActive}
                                                    className='RadioButton'
                                                    onChange={(e) => setDisplay({ ...display, isActive: 'false' })}
                                                    checked={display.isActive === 'false'}
                                                    label='IN ACTIVE'></Form.Check>
                                            </div>
                                        </div>
                                    </Attributes>
                                </Col>
                            </Row>
                        </Card>
                        <Actions>
                            <Link to={`/manufacturer/ads/${display.userid}`}>
                                <Button type="button" className='bttn bttn-transparent'>
                                    <Flex jc='jc-around'>
                                        <Icons icon="arrow_back" />
                                        BACK
                                    </Flex>
                                </Button>
                            </Link>
                            <Button className='bttn bttn-transparent' type="submit" name='btn-dockEdit' id='btn-dockEdit'>
                                <Flex>
                                    <Icons color="#ffffff" icon="check" />
                                    SAVE DATA
                                </Flex>
                            </Button>
                        </Actions>
                    </Form>
                </Col>
            </Row>
        </Main>
    )
}

export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    manufacturer: state.manufacturer.selectedManufacturer
})

// connect to manufacturerSetSelectedAction and get the one perticular ship data. 
export default connect(
    mapStateToProps,
    {
        manufacturerSetSelectedAction
    }
)(ManufacturerAdsEdit)