import React, { useState } from "react";
// import { Button } from "../../common/components/button/Button";
import authService from "../../services/authService";
import { accountSetAction } from '../../store/action/accountActions';
import { Col, Button, Form, Row } from "react-bootstrap";
import '../../common/components/button/Button.scss'
import '../../common/components/control/Control.scss'
import classNames from 'classnames'
import '../login/Login.scss'
import '../../styles/variables/Common.scss'
// import banner from '../../assets/images/banner.png'
// import logo from '../../assets/images/logo.png'
import banner from '../../assets/images/test1.jpg'
import logo from '../../assets/images/navidok_txt.png';
import { Space } from "../../common/components/space/Space";
import {Flex} from '../../common/components/flex/Flex'
import { Text } from "../../common/components/text/Text";
// import { Input } from "../../common/components/control/Control";
import {Label} from '../../common/components/label/Label'
import {Grid} from '../../common/components/grid/Grid'
import Card from '../../common/components/card/Card'
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { history } from '../../store';
import { ForgotPassword } from '../login/pagecontainer/ForgotPassWord'
import { useForm } from "react-hook-form";
interface FormData {
    txt_name: string;
    txt_passWord: string;
}

const Login = (props: any) => {
    
    const [username, setUserName] = React.useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('Invalid username or password')
    const [showModal, setModal] = useState(false)
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const [loginFlag, setloginFlag] = React.useState(false)
    // Modal box open.  
    const willShowModal = () => {
        setModal(true)
    }
    // While click modal box close icon, its close the modal box.  
    const onClose = () => {
        setModal(false)
    }

    // After submit the form, authorize the username and password and login.  
    const _didLogin = () => {
        // e.preventDefault()
        if(loginFlag === true){
            return authService.login({
                username,
                password,
            }).subscribe({
                next: (res: any) => {
                    // console.log(res , 'response in login page')
                    const { data } = res
                    props.accountSetAction(data)
                    swal("Great", "Login Successfully", "success")
                    console.log(res)
                    // authService.persistAccount(String(res.data.access_token))   
                    // history.push('/')
                },
                error: err => {
                    swal("OOPS", "Invalid Username or Password", "error")
                    setError(err.message)
                }
            })
        }
        else{
            console.log(loginFlag)
        }
    }
    onsubmit = (e: any) => {
        // e.preventDefault()
        console.log(Object.keys(errors).length, 'error length')
        if (Object.keys(errors).length === 0) {
            _didLogin()
            setloginFlag(true)
        }
        else {
            setloginFlag(false)
        }
    }
    return (
        <Root>
            <Grid className='login_grid'>
                <Banner>
                    <img src={banner} alt='Home-banner' className='bannerContainer' />
                </Banner>
                <Card className ='login_card'>
                    <Form onSubmit={handleSubmit(onsubmit)} className='LoginForm' autoComplete='off' name='loginForm'>
                        <Logo>
                            <img src={logo} alt='logo' />
                        </Logo>
                        <Space divheight={35} />
                        <Text variant='title-sm' className='login_card_title' color='main' bold={true}>
                            Login to Navidok Admin Site
                        </Text>
                        <Space divheight={28} />
                        <Label className="primary">Username</Label>
                        <Form.Control className='basecontrol custome-input' type='text'
                            name='txt_name'
                            onChange={(e) => setUserName(e.target.value)}
                            value={username} 
                            ref={register({ required: true,pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/  })}

                        />
                        <span className='err-msg text-capitalize'>
                            {errors.txt_name?.type === 'required' && 'Please enter User name'}
                            {errors.txt_name?.type === 'pattern' && 'Please enter valid User name'}
                        </span>
                        <Space divheight={15} />
                        <Label className="primary">Password</Label>
                        <Form.Control className='basecontrol custome-input'
                            name='txt_passWord'
                         type='password' onChange={(e) => setPassword(e.target.value)} value={password} 
                            ref={register({ required: true, })} />
                        <span className='err-msg text-capitalize'>
                            {errors.txt_passWord?.type === 'required' && 'Please enter Password'}
                        </span>
                        <Space divheight={15} />

                        <Button type="button" className='bttn bttn-transparent-forgot ' onClick={() => { willShowModal() }}>Forgot Password?</Button>
                        
                        <Space divheight={15} />
                        <Button type="submit" className='bttn bttn-login'>Login</Button>
                    </Form>
                </Card>   
                {showModal && <ForgotPassword onClose={() => onClose()} />}
            </Grid>
        </Root>
    )
}

//Root props
export interface RootProps {
    children?: React.ReactNode;
    className ? : string;
  }
  
//Root such as main container for login page
const Root = (props: RootProps) => {

    const root = classNames('login_root',props.className)
  
    return (
      <Flex jc='jc-start' className={root}>
        {props.children}
      </Flex>
    )
  
  }

//set interface for Banner component
export interface Bannerprops{
    children ? : React.ReactNode;
    className ? : string;
}

//Banner section which contain ship image for login page
const Banner = ({children,className, ...props} : Bannerprops) => {
    
    const login_banner = classNames('login_banner',className)
    return(
        <Flex className={login_banner}>
            {children}
        </Flex>
    )
}

export interface LogoProps{
    children ? : React.ReactNode;
    className ? : string;
}

const Logo = ({children,className,...props} : LogoProps) => {
    const logo_cls = classNames(className)
    return(
        <div className={logo_cls}>
            {children}
        </div>
    )
}
export default connect(null, { accountSetAction })(Login) // connect accountSetAction.  
