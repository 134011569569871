import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row } from 'react-bootstrap'
import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import { Input, Radio, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import swal from 'sweetalert';
import manufacturerService from '../../../../services/manufacturerService'
import '../../../../styles/variables/Common.scss'
import codeService from '../../../../services/codeService'

interface ManufacturerProps {
  name: string;
  description: string,
  duration_in_month: string,
  price: string | number,
  isactive: boolean
}

export const ManufacturerAdd = (props: ManufacturerProps) => {

  const [manufacturer, setManufacturer] = useState<ManufacturerProps>({
    name: '',
    description: '',
    duration_in_month: '',
    price: '',
    isactive:false
  },
  )
  
// After enter all details by user and click submit button values assign to some variable then it is used as input parameter for addmanufacturer api call. 
  const _didSubmit = (e: any) => {
    e.preventDefault()
    const updatedForm = {
      name: manufacturer.name,
      description: manufacturer.description,
      duration_in_month: String(manufacturer.duration_in_month),
      price: Number(manufacturer.price),
      isactive: manufacturer.isactive,
    } 
    
    // API call for addmanufacturer with data, added to list. 
    // console.log(updatedForm,"updatedform")
    return manufacturerService.addManufacturer(updatedForm).subscribe({
      next:  (r) => {
        // console.log(r,'response')
        swal("Great","Industrialist Added Successfully" ,"success")
        history.push('/manufacturer/list')
      },
      error: err => {
        swal("OOPS",String(err),"error")
      }
    }) 
  }

  return (
    <Main>
      <Text variant="title" color='black'>Add Industrialist Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <StyledForm onSubmit={e => _didSubmit(e)}>
            <Card title="Fill All the Information Below">
                <Row className='m-0 p-0'>
                <Col xs= '12' md = '12' lg ='8' xl ='8' className='p-0 m-0'>
                    <Attributes>
                        <div className='sm-mt-3'>
                          <Text color='tablehead' variant='subtitle' >Plan Name</Text>
                          <Input
                            type="text"
                            name="plan_name"
                            value={manufacturer.name}
                            onChange={(e) => setManufacturer({ ...manufacturer, name: e.target.value })}
                            required
                            placeholder="Plan Name"
                          />  
                        </div>
                        <div>
                          <Text color='tablehead' variant='subtitle'>Description</Text>
                          <Textarea 
                          rows={3}
                          cols={50}
                          name="description"
                          value={manufacturer.description}
                          onChange={(e) => setManufacturer({...manufacturer, description: e.target.value})}
                          required
                          />
                        </div>
                        <div>
                          <Text color='tablehead' variant='subtitle'>Price</Text>
                            <Input
                              name="price"
                              value={manufacturer.price}
                              required
                              type='number'
                              // min={0.00}
                              // max={999999.00}
                              placeholder="Amount"
                              onChange={e => setManufacturer({ ...manufacturer, price: e.target.value })}
                            />
                        </div>
                        <div>
                          <Text color='tablehead' variant='subtitle'>Duration in Month</Text>
                          <input type="date" id="durationdate" name="durationdate" value={manufacturer.duration_in_month}
                            onChange={e => setManufacturer({ ...manufacturer, duration_in_month: e.target.value })}
                          />                              
                        </div>
                        <div>
                          <Text color='tablehead' variant='subtitle'>System Access</Text>
                          <div>
                            <Radio type='radio' id='active' name='status' value={manufacturer.isactive}
                              onChange={(e) => setManufacturer({ ...manufacturer, isactive: true })}
                              label='ENABLED'></Radio>
                            <Radio type='radio' id='inactive' name='status' value={manufacturer.isactive} 
                              onChange={(e) => setManufacturer({ ...manufacturer, isactive: false })}
                              label='DISABLED'></Radio>
                          </div>
                      </div>
                    </Attributes>
                </Col>
                </Row>
            </Card>
          <Actions>
            {/* If you click the back button its is redirect to manufacturers list page.  */}
            <Link to='/manufacturer/list'>
              <Button variant='transparent' block={true}>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page.  */}
            <Button variant='transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                  SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </StyledForm>
      </Grid>
      {/* Add form, having input box, select box.  */}
    </Main>
  )
}


export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-manufacturer-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. 
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}
