import {
    SUBSCRIPTION_LIST_GET,
    SUBSCRIPTION_LIST_GET_F,
    SUBSCRIPTION_LIST_GET_R,
    SUBSCRIPTION_SELECTED_SET
} from '../actionType'

export const subscriptionListGetAction = (params: any) => ({
    type: SUBSCRIPTION_LIST_GET,
    payload: params
})

export const subscriptionListGetFAction = (params: any) => ({
    type: SUBSCRIPTION_LIST_GET_F,
    payload: params
})

export const subscriptionListGetRAction = (params: any) => ({
    type: SUBSCRIPTION_LIST_GET_R,
    payload: params
})

export const subscriptionSetSelectedAction = (subs: any) => ({
    type: SUBSCRIPTION_SELECTED_SET,
    payload: subs
})