import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Filterimg from '../../../assets/images/Filter 2.png'
import './ManufacturerCompanies.scss'
import { Main } from '../../../common/components/main/Main'
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap'
import { Table } from '../../../common/components/table/Table'
import { Button } from '../../../common/components/button/Button'
import { Text } from '../../../common/components/text/Text'
import Card from '../../../common/components/card/Card'
import { Flex } from '../../../common/components/flex/Flex'
import { Space } from '../../../common/components/space/Space'
import Icons from '../../../common/components/icon/Icon';
import { history } from '../../../store'
import classNames from 'classnames';
import { manufacturerCmpListGetAction, manufacturerCmpSetSelectedAction } from '../../../store/action/manufacturerAction';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns'
import { ReactNode } from 'react';
import manufacturerService from '../../../services/manufacturerService';
import swal from 'sweetalert'
import { InputWithIcon } from '../../../common/components/control/Control';
import { ManufactFilterState } from '../filter/ManufactFilter'
import ManufactCmpFilter from './pageContainer/ManufacturerCmpFilter'
import Loader from 'react-loader-spinner';
import sortup from '../../../assets/images/sortup_icon.png';
import sortdown from '../../../assets/images/sortdown_icon.png';
import sortimage from '../../../assets/images/sorting.png';

const ManufacturerCompanies = (props: any) => {

 
  const { companies, isLoading, totalElement } = props
  const { form } = props
  const [displayCompanies, setCompanies] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [searchQuery, setQuery] = React.useState('')
  const [searchInput, setSearchInput] = React.useState(ManufactFilterState.ManufactCmpFilter.searchBoxInputManufactCmp);
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  const [showModal, setModal] = React.useState(false)
  const [showDelete, setShow] = React.useState(false);
  const [deleteManufactcmp, setDeleteManufactCmp] = React.useState(0)
  const [sort, setSorting]=React.useState({values:ManufactFilterState.ManufactCmpFilter.type,direction:ManufactFilterState.ManufactCmpFilter.direction})


  React.useEffect(() => {
    _getCompanies({ page, q: searchQuery })
  }, [page, searchQuery])
  // console.log(props, 'props in company list')

  React.useEffect(() => {
    if (companies && companies.length > 0) {
      setCompanies(() => {
        return companies.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (companies && companies.length === 0) {
      setCompanies([])
    }
  }, [companies])
  // console.log(props, 'props')
  // console.log(form,'company filter')
  React.useEffect(() => {
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  const _getCompanies = (params: any) => {
    // console.log(page,'page in docklist get action')
    // console.log(FilterState.cmpFilter,'cmp filter in drydock companies')
    if(localStorage.getItem("searchInput")!==null||localStorage.getItem("searchInput")!=='')
    {
      const companies = localStorage.getItem("searchInput")!==null ? localStorage.getItem("searchInput") : '';
      ManufactFilterState.ManufactCmpFilter.searchBoxInputManufactCmp=companies!==null?companies:'';
      setSearchInput(companies !== null ? companies : '')
    props.manufacturerCmpListGetAction({
      page, size: 10,
      companyname: companies,
      cityname: ManufactFilterState.ManufactCmpFilter.city,
      countryname: ManufactFilterState.ManufactCmpFilter.country,
      status: ManufactFilterState.ManufactCmpFilter.status,
      type:ManufactFilterState.ManufactCmpFilter.type,
      direction:ManufactFilterState.ManufactCmpFilter.direction
    })}else{
      props.manufacturerCmpListGetAction({
        page, size: 10,
        companyname: ManufactFilterState.ManufactCmpFilter.cmp || searchInput ||ManufactFilterState.ManufactCmpFilter.searchBoxInputManufactCmp,
        cityname: ManufactFilterState.ManufactCmpFilter.city,
        countryname: ManufactFilterState.ManufactCmpFilter.country,
        status: ManufactFilterState.ManufactCmpFilter.status,
        type:ManufactFilterState.ManufactCmpFilter.type,
        direction:ManufactFilterState.ManufactCmpFilter.direction
      })
    }
  }

  const _searchFuntion = (value: string) => {
    setSearchInput(value)
    ManufactFilterState.ManufactCmpFilter.searchBoxInputManufactCmp=value
    localStorage.setItem("searchInput",value)
    props.manufacturerCmpListGetAction({
      page: 0, size: 10,
      companyname: value,
      cityname: ManufactFilterState.ManufactCmpFilter.city,
      countryname: ManufactFilterState.ManufactCmpFilter.country,
      status: ManufactFilterState.ManufactCmpFilter.status,
      type:ManufactFilterState.ManufactCmpFilter.type,
      direction:ManufactFilterState.ManufactCmpFilter.direction
    })
    setPage(0)
    // setLow(1)
    // setHigh(10)
  }
  const _willViewDetail = (company: any) => {
    props.manufacturerCmpSetSelectedAction(company)
    history.push(`/manufacturer-companies/${company.company_id}`)
  }

  const _willViewEdit = (company: any) => {
    props.manufacturerCmpSetSelectedAction(company)
    history.push(`/manufacturer-companies/edit/${company.company_id}`)
  }

  const handleClose = () => setShow(false);
  const handleShow = (company: any) => {
    setShow(true);
    setDeleteManufactCmp(company.company_id)
    // console.log(deleteAnode, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deleteDockCmp(deleteManufactcmp)
  }

  const _willFilter = () => {
    setModal(true)
  }

  const _willCloseModal = () => {
    setModal(false)
  }

  const role = localStorage.getItem("role");

  const _deleteDockCmp = (id: any) => {
    return manufacturerService.deleteManufactCompany(id).subscribe({
      next: (r) => {
        setShow(false)
        // console.log(r, 'response')
        props.manufacturerCmpListGetAction({ page, size: 10 })
        swal("Great", " Deleted Successfully", "success")
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }

  const _showAll = () => {
    props.manufacturerCmpListGetAction({ page: 0, size: 10 })
    setSearchInput('')
    ManufactFilterState.ManufactCmpFilter.status = ''
    ManufactFilterState.ManufactCmpFilter.country = ''
    ManufactFilterState.ManufactCmpFilter.city = ''
    ManufactFilterState.ManufactCmpFilter.cmp = ''
    ManufactFilterState.ManufactCmpFilter.countryNames = ''
    ManufactFilterState.ManufactCmpFilter.page = 0
    ManufactFilterState.ManufactCmpFilter.size = 10
    ManufactFilterState.ManufactCmpFilter.searchBoxInputManufactCmp=''
    localStorage.removeItem("searchInput")
    setSorting({values:'',direction:''})
  }

  //first page
  const firstPage = () => {
    if (page >= 1) {
      // console.log(page,'page in first page')
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  // While click the last page its move to last page.  
  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 9)

  }

  // While click the first button its move to first page.  
  const _goToFirstPage = () => {
    setPage(0)
    setHigh(9)
    setLow(1)
  }

  // Prints last page pagination button.  
  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  // Prints prev button.  
  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  // While click prev button go to the previous page of current page.  
  const _goPrevPage = () => {
    setPage(prev => {
      const newValue = prev - 1
      return newValue
    })
  }

  // Print next button.  
  const nextBtn = () => {
    if (page < totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  // While click next page button go to the next page of the current page.  
  const _goNextPage = () => {
    setPage(prev => {
      const newValue = prev + 1
      return newValue
    })
  }

  // While click one page number its move to that page.  
  const _goToPage = (page: any) => {
    setPage(page)
  }

  // Return the page number
  const ListItem = (page: any) => {
    return page.value + 1
  }

  // Prints the totalpages between prev, next button.  
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })

  const sortByFieldn=(value:String,directions:String)=>{
    if(directions==='asc')
    {
      setSorting({values:String(value),direction:String(directions)});
      ManufactFilterState.ManufactCmpFilter.type=String(value);
      ManufactFilterState.ManufactCmpFilter.direction=String(directions);
      props.manufacturerCmpListGetAction({
        page: 0, size: 10,
        companyname: ManufactFilterState.ManufactCmpFilter.searchBoxInputManufactCmp,
        cityname: ManufactFilterState.ManufactCmpFilter.city,
        countryname: ManufactFilterState.ManufactCmpFilter.country,
        status: ManufactFilterState.ManufactCmpFilter.status,
        type:value,
        direction:directions,
      })
      setPage(0)
      
    }
    else if(directions==='desc')
    {
      setSorting({values:String(value),direction:String(directions)});
      ManufactFilterState.ManufactCmpFilter.type=String(value);
      ManufactFilterState.ManufactCmpFilter.direction=String(directions);
      props.manufacturerCmpListGetAction({
        page: 0, size: 10,
        companyname: ManufactFilterState.ManufactCmpFilter.searchBoxInputManufactCmp,
        cityname: ManufactFilterState.ManufactCmpFilter.city,
        countryname: ManufactFilterState.ManufactCmpFilter.country,
        status: ManufactFilterState.ManufactCmpFilter.status,
        type:value,
        direction:directions,
      })
      setPage(0)
      
    }
    else if(directions==='')
    {
      setSorting({values:String(value),direction:String(directions)});
      ManufactFilterState.ManufactCmpFilter.type=String(value);
      ManufactFilterState.ManufactCmpFilter.direction=String(directions);
      props.manufacturerCmpListGetAction({
        page: 0, size: 10,
        companyname: ManufactFilterState.ManufactCmpFilter.searchBoxInputManufactCmp,
        cityname: ManufactFilterState.ManufactCmpFilter.city,
        countryname: ManufactFilterState.ManufactCmpFilter.country,
        status: ManufactFilterState.ManufactCmpFilter.status,
        type:value,
        direction:directions,
      })
      setPage(0)
      
    }
    };

  return (
    <Main>
      <Text variant="title" color='black'>Supporting Industry List</Text>
      <Space divheight={15} />
      <Card>
        <Space divheight={25} />
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-3 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    <InputWithIcon
                      type="text"
                      value={searchInput}
                      onChange={(e) => _searchFuntion(e.target.value)}
                      placeholder="Search by Company Name.."
                      icon="search"
                      id="search-input"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <div className='search-btn-container'>
                <div className='search-btn-add'>
                  {role === 'VISITOR' ? (
                    //                    <Link to='/manufacturer-company/add'>
                    <Button variant='transparent' block={true} disabled>
                      <Icons color="#ffffff" icon="add" />
                      ADD NEW INDUSTRIES
                    </Button>
                    //                  </Link>
                  ) : (
                    <Link to='/manufacturer-company/add'>
                      <Button variant='transparent' block={true}>
                        <Icons color="#ffffff" icon="add" />
                        ADD NEW INDUSTRIES
                      </Button>
                    </Link>
                  )}
                </div>
                <div className='search-btn-filter'>
                  <Button className='link_button filter' onClick={() => _willFilter()}>
                    <img src={Filterimg} alt='filterimg' />
                  </Button>
                </div>
                <div className='search-btn-showall'>
                  <Button variant='transparent' block={true} onClick={() => _showAll()}>
                    Show All
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </SearchForm>
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>}
        {/* {console.log(displayCompanies, 'display companies')} */}
        {!isLoading && displayCompanies.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No Industries found</Text>
            <Space divheight={20} />
          </>
        )}
        {!isLoading && displayCompanies.length > 0 && (
          <>
            <Table className="table-company">
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Created Date</th>
                  <th>Company Name
                  &nbsp;
                    {sort.values==='companyname' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('companyname','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='companyname' ? (
                     <img onClick={() => sortByFieldn('companyname','asc')} src={sortimage}/>):(null)}
                     {sort.values==='companyname' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Owner
                  &nbsp;
                    {sort.values==='username' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('username','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='username' ? (
                     <img onClick={() => sortByFieldn('username','asc')} src={sortimage}/>):(null)}
                     {sort.values==='username' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>City
                  &nbsp;
                    {sort.values==='cityname' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('cityname','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='cityname' ? (
                     <img onClick={() => sortByFieldn('cityname','asc')} src={sortimage}/>):(null)}
                     {sort.values==='cityname' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Country
                  &nbsp;
                    {sort.values==='countryname' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('countryname','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='countryname' ? (
                     <img onClick={() => sortByFieldn('countryname','asc')} src={sortimage}/>):(null)}
                     {sort.values==='countryname' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Status
                  &nbsp;
                    {sort.values==='status' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('status','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='status' ? (
                     <img onClick={() => sortByFieldn('status','asc')} src={sortimage}/>):(null)}
                     {sort.values==='status' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {displayCompanies.map((cmp: any, i: any) => [
                  <tr key={cmp.company_id}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    {cmp.created_date !== null && (
                      <td data-label='Join Date'>
                        {format(parseISO(cmp.created_date), 'dd/MM/yyyy')}
                      </td>
                    )}
                    {cmp.created_date === null && (
                      <td data-label='Join Date'>
                        none
                      </td>
                    )}
                    <td data-label='Company Name'>{cmp.company_name}</td>
                    {cmp.owner_name === null ? (
                      <td data-label='owner Name'>
                        -
                      </td>
                    ) : (
                      <>
                        {String(cmp.owner_name).includes('@') && (
                          <td data-label='owner Name'>
                            {String(cmp.owner_name).substring(0, String(cmp.owner_name).indexOf('@'))}
                          </td>
                        )}
                        {!String(cmp.owner_name).includes('@') && (
                          <td data-label='owner Name'>
                            {String(cmp.owner_name)}
                          </td>
                        )}
                      </>
                    )}
                    <td data-label='City'>
                      {cmp.city === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>

                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{cmp.city}</Text>
                      )}
                    </td>
                    <td data-label='Country'>
                      {cmp.country === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{cmp.country}</Text>
                      )}
                    </td>
                    <td data-label='Status'>{cmp.status === 'VERIFIED' ? (
                      <Text className='txt-table-date' color='warning'>Verified</Text>
                    ) : (
                      <Text className='txt-table-date' color='danger'>Not Verified</Text>
                    )}
                    </td>
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="sCmp-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(cmp)}>
                            <Icons color="#52575a" icon="visibility" />
                          </Button>
                        </OverlayTrigger>
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="sCmp-edit-visitor"> Edit option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(cmp)} disabled>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="sCmp-edit"> Edit
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(cmp)}>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        )}

                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="sCmp-delete-visitor"> Delete option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(cmp)} disabled>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="sCmp-delete"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(cmp)}>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </TableActions>
                    </td>
                  </tr>
                ])}
              </tbody>
            </Table>
            <TableFooter>
              <Text>
                {displayCompanies.length === 1 && (
                  <>
                    <b>
                      {displayCompanies.length}
                      {/* {((page) * 10) + 1} - {(displayCompanies.length + (page) * 10)} */}
                    </b>  of {totalElement} Company
                  </>
                )}
                {displayCompanies.length > 1 && (
                  <>
                    <b>
                      {displayCompanies.length}
                      {/* {((page) * 10) + 1} - {(displayCompanies.length + (page) * 10)} */}
                    </b>  of {totalElement} Companies
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()}
                {prevBtn()}
                {PageNumber}
                {nextBtn()}
                {lastPage()}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form Dock Company list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {showModal && <ManufactCmpFilter onClose={() => _willCloseModal()} />}
    </Main>
  )
}

export interface TablefooterProps {
  children?: React.ReactNode
}

const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

//Search Form Component  for displaying serarch box, and add ship button
const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

const mapStateToProps = (state: any) => ({
  companies: state.manufacturer.manufacCmp,
  isLoading: state.manufacturer.isLoading,
  error: state.manufacturer.error,
  totalElement: state.manufacturer.totalElement,
  totalPage: state.manufacturer.totalPage,
})

export default connect(
  mapStateToProps,
  {
    manufacturerCmpListGetAction,
    manufacturerCmpSetSelectedAction,

  }
)(ManufacturerCompanies)