import * as Observable from 'rxjs'
import { timeout, map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getDatalasticShipCompanies = (params : any) => {
    return http({
      method: 'GET',
      url: '/ships/shipCompaniesList',
      params: { ...params}
    }).pipe(
      map(res => {
        if (res.success) {
          // console.log(res,'ship company')
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }

const getdatalasticShipCompanyDetail = (id : any) => {
    return http({
      method: 'GET',
      url: `/companies/${id}`
    }).pipe(
      map(res => {
        if (res.success) {
          console.log(res, 'company view data')
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }
  
  const datalasticdeleteShipCompany = (id: any) => {
    return httpDelete({
      method: 'DELETE',
      url: `/companies/${id}`,
    }).pipe(
      map(res => {
        // console.log(res, 'delete response')
        if (res) {
          return res
        } else {
          // console.log(res, 'error message')
          throw new Error("Error")
        }
      })
    )
  }
  
  const datalasticupdateShipCompany = (body : any) => {
    return http({
      method: 'PUT',
      url: '/companies',
      body: body
    }).pipe(
      map(res => {
        if (res.success) {
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }
  
  const datalasticupdateShipCompanyImage = (body : any) => {
    return http({
      method: 'POST',
      url: '/companies/companylogo',
      body
    }).pipe(
      map(res => {
        if (res.success) {
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }
  
  const datalasticaddShipCompany = (body : any) => {
    return http({
      method: 'POST',
      url: '/companies',
      body
    }).pipe(
      map(res => {
        if (res.success) {
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }

  const addshipCmpExcel = (body: any,params:any) => {
    let content = new FormData();
    return http({
        method: 'POST',
        url: `/companies/sampleCompanyImport/${params}`,
        body
    }).pipe(
        map(res => {
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}
  const datalasticShipCmpService = {
    getdatalasticShipCompanyDetail,
    datalasticdeleteShipCompany,
    datalasticupdateShipCompany,
    datalasticaddShipCompany,
    datalasticupdateShipCompanyImage,
    getDatalasticShipCompanies,
    addshipCmpExcel
  }
  
  export default datalasticShipCmpService