import {
    CITY_LIST_GET,
    CITY_LIST_GET_F,
    CITY_LIST_GET_R,
    CITY_SELECTED_SET
}  from '../actionType'

const initialState = {
    cities: [],
    isLoading: false,
    error: null,
    selectedcities: null,
    totalPage: 0,
    totalElement: 0,
}

export default function cityReducer (state = initialState, action:any)  {
    switch(action.type) {
        case CITY_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            } 
        case CITY_LIST_GET_F:
            return {
                ...state,
                isLoading: false,
                cities: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
            }
        case CITY_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case CITY_SELECTED_SET:
            console.log('selected city called')
            return {
                ...state,
                selectedcities: action.payload
            }
        default:
            return state
    }
}