import React from 'react';
import classNames from 'classnames';
import { Table as BootstrapTable } from 'react-bootstrap';
import './Table.scss';

//set interface for table
export interface tableProps{
    children?:React.ReactNode;
    className?: string;
}
//Table component.. bootstrap table as Table with additional styles
export const Table = ({children, className}:tableProps) => {
    const tableClassName = classNames('responsiveTable', className)
    return(
        <BootstrapTable className = {tableClassName}>
            {children}
        </BootstrapTable>
    )
}