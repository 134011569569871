import {
    SLOT_LIST_GET,
    SLOT_LIST_GET_F,
    SLOT_LIST_GET_R,
    SLOT_SELECTED_SET
}  from '../actionType'

const initialState = {
    slot: [],
    isLoading: false,
    error: null,
    selectedSlot: null,
}

export default function slotReducer (state = initialState, action:any)  {
    switch(action.type) {
        case SLOT_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            } 
        case SLOT_LIST_GET_F:
            return {
                ...state,
                isLoading: false,
                slot: action.payload.data,
            }
        case SLOT_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SLOT_SELECTED_SET:
            return {
                ...state,
                selectedSlot: action.payload
            }
        default:
            return state
    }
}