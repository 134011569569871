import React, { ReactNode } from 'react';
import './Button.scss'
import classNames from 'classnames'
import { Button as BootstrapButton } from 'react-bootstrap';

//interface for buttonVariant
export type ButtonVariant = 'blue' | 'white' | 'transparent' | 'login'| 'transparent-forgot'; 

//interface for Button. here decelared what are the  props are used by button component 
export interface ButtonProps {
    className?: string;
    disabled?: boolean;
    label?: string | React.ReactNode;
    variant?: ButtonVariant;
    onClick?: (e: React.MouseEvent) => void;
    block? : true;
    type?: 'button' | 'reset' | 'submit';
    children?: ReactNode;
}

//create button component and use bootstrap Button as base and add customized the style
export const Button = ({ variant, className, disabled, onClick, label, block, type,children, ...props } : ButtonProps) => {
    const btnClassNames = classNames('bttn', variantClassName(variant), className);

    return (
        <BootstrapButton {...props} type={type ? type : 'button'} className={btnClassNames} disabled={disabled} onClick={onClick}>
            <div>{children}{label}</div>
        </BootstrapButton>
    )
}

//function for defining classname for button using variant name.
export function variantClassName(variant: ButtonVariant | undefined): string {
    return variant ? `bttn-${variant}` : 'bttn-blue';
}
