import React, { useRef, useState } from 'react'
import Modal from '../../../../common/components/modal/Modal'
import { Text } from '../../../../common/components/text/Text'
import { Col, Form, Button, Row } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Input, Select } from '../../../../common/components/control/Control'
// import { Button } from '../../../../common/components/button/Button'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import { connect } from 'react-redux'
import classNames from 'classnames'
import codeService from '../../../../services/codeService'
import { useForm } from 'react-hook-form'
import '../Ports.scss'
import { useEffect } from 'react'
import swal from 'sweetalert'
import portService from '../../../../services/portService'
import Loader from 'react-loader-spinner';

interface PortProps {
  id: string;
  ports: string;
  lat: string | number;
  lon: string | number;
  cityid: string;
  cities: any[];
}
interface FormData {
  txt_portName: string;
  txt_portcode: string;
  txt_portlat: string;
  txt_portlon: string;
  txt_portcity: string;
}
const PortAdd = (props: any) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
});
  const [isLoading, setLoading] = React.useState(false)
  const [portaddflag, setportAddFlag] = React.useState(false)
  const { port } = props
  const { edit } = props
  const { view } = props
  const { id } = props
  //const { add } = props
  const { onClose } = props
  const [form, setForm] = useState<PortProps>({
    id: '',
    ports: '',
    lat: '',
    lon: '',
    cityid: '',
    cities: []
  })
  const [editForm, setEditForm] = useState({
    id: '',
    ports: '',
    lat: '',
    lon: '',
    cityid: '',
    cityName:'',
  })
    const get_city = (() => {
    return codeService.getCitiesDrop().subscribe({
      next: r => {

        // console.log(r.data,"countries")
        setForm({ ...form, cities: r.data })
        // console.log(form.cities ,'Form Role')
        r.data.map((data: any, i: any) => {
          return form.cities.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  React.useEffect(() => {
    get_city();
  }, [])

  const [modalTitle, setModalTitle] = useState('')
  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevUser = usePrevious(port);

  useEffect(() => {
    if (port && prevUser !== port) {
      _getSelectedCity()
    }

    if (edit) {
      setModalTitle('Edit Port Detail')
    }

    else {
      setModalTitle('Port Detail')
    }
  }, [port])

  const _getSelectedCity = () => {
    const { port } = props
    console.log(port,'ports')
    var CityName :any;
    var CityId : any;
    if(port.city === null){
      CityName = '';
      CityId = '';
    }
    else{
      CityName = port.city.name;
      CityId = port.city.id;
    }
    setEditForm({
      id: port.id,
      ports: port.name,
      lat: port.lat,
      lon: port.lon,
      // cityid : ''
      cityid: CityId,
      cityName:CityName,
    })
    // console.log(port,'props user')
    // setForm({ 
    //   name: city.name,
    //   email: user.email,
    //   password: user.password,
    //   roles: [],
    //   role:user.roles.id,
    //   status: user.status
    //  })
    //  console.log(form,'userForm')
  }

  const _didPortAdd = () => {
    // e.preventDefault()
    const { onClose } = props
    const payload = {
      name: editForm.ports,
      lat: Number(editForm.lat),
      lon: Number(editForm.lon),
      city_id: String(editForm.cityid)
    }
    const updatepayload = {
      id: String(editForm.id),
      name: editForm.ports,
      lat: Number(editForm.lat),
      lon: Number(editForm.lon),
      city_id: String(editForm.cityid)
    }
    const _willUpdate = () => {
      const { onClose } = props
      const { user_id } = props
      setLoading(true)
      portService.updatePort(updatepayload).subscribe({
        next: () => {
          setLoading(false)
          swal("Great", "Port Updated Successfully", "success")
          onClose()
        },
        error: err => {
          setLoading(false)
          swal("OOPS", String(err), "error")
          onClose()
          console.error(err)
        }
      })
    }
    if (portaddflag === true) {
      if (edit) {
        // alert("iside edit")
        _willUpdate()
        return
      }
      else {
        setLoading(true)
        // alert("iside edit else")
        portService.addPort(payload).subscribe({
          next: (r) => {
            setLoading(false)
            swal("Great", "New Port Added Successfully", "success")
            onClose()
          },
          error: err => {
            setLoading(false)
            console.error(err)
            swal("OOPS", String(err), "error")
            onClose()
          }
        })
      }
    }
    else {
      console.log(portaddflag)
    }
  }
  onsubmit = (e: any) => {
    // e.preventDefault()
    console.log(Object.keys(errors).length, 'error length')
    if (Object.keys(errors).length === 0) {
      _didPortAdd()
      setportAddFlag(true)
      onClose()
    }
    else {
      setportAddFlag(false)
      onClose()
    }
  }

  if (isLoading) {
    return <Text center>
        <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
        />
    </Text>
}

  return (
    <Modal
      onClose={() => onClose()}
      title={!port ? 'Add New Port' : modalTitle}>
      <ModalBody>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='countryAddForms'>
          <Attributes>
            <div>
              <Text color='black' variant='subtitle'>Port</Text>
              {view && (
                <>
                  {editForm.ports === null ? (
                    <Text color='main' bold variant='subtitle'> - </Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.ports}</Text>
                  )}
                </>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_portName"
                  placeholder='Port'
                  value={editForm.ports}
                  ref={register({ required: true, })}
                  onChange={(e) => setEditForm({ ...editForm, ports: e.target.value })}
                  type="text"
                />
              )
              }
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_portName?.type === 'required' && 'Please enter Port Name'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>City</Text>
              {view && (
                <>
                  {editForm.cityName === null ? (
                    <Text color='main' bold variant='subtitle'>
                      -
                    </Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>
                      {editForm.cityName}
                    </Text>
                  )}
                </>
              )}
              {!view && (
                <Form.Control as='select' className='basecontrol cutome-selectInputnxt'
                  value={editForm.cityid} name='txt_portcity'
                  onChange={(e) => setEditForm({ ...editForm, cityid: e.target.value })}
                  ref={register({ required: true, })}
                >
                  <option value="">-- Select --</option>
                  {form.cities.map((data: any, i: any) => {
                    return <option key={i} value={data.id}>{data.name}</option>
                  })}
                </Form.Control>
              )}

            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_portcity?.type === 'required' && 'Please select City Name'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Latitude</Text>
              {view && (
                <>
                  {editForm.lat === null ? (
                    <Text color='main' bold variant='subtitle'>-</Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.lat}</Text>
                  )}
                </>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_portlat"
                  value={editForm.lat}
                  placeholder='Latitude'
                  ref={register({ required: true, min: -90.00, max: +90.00 })}
                  onChange={(e) => setEditForm({ ...editForm, lat: e.target.value })}
                  type="number"
                />
              )}
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_portlat?.type === 'required' && 'Please enter Latitued'}
                  {errors.txt_portlat?.type === 'min' && 'Minimum Value should be -90'}
                  {errors.txt_portlat?.type === 'max' && 'Maximum Vlaue should be +90'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Longtitude</Text>
              {view && (
                <>
                  {editForm.lon === null ? (
                    <Text color='main' bold variant='subtitle'>-</Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.lon}</Text>
                  )}
                </>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_portlon"
                  value={editForm.lon}
                  placeholder='Longtitude'
                  ref={register({ required: true, min: -180.00, max: +180.00 })}
                  onChange={(e) => setEditForm({ ...editForm, lon: e.target.value })}
                  type="number"
                />
              )}
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_portlon?.type === 'required' && 'Please enter Longtitude'}
                  {errors.txt_portlon?.type === 'min' && 'Minimum Value should be -180'}
                  {errors.txt_portlon?.type === 'max' && 'Maximum Vlaue should be +180'}
                </span>
              </Col>
            </Row>
          </Attributes>
          <Space divheight={20} />
          <Flex jc='jc-center'>
            <Button
              type="button"
              onClick={() => onClose()}
              className='bttn bttn-transparent'>
              <Flex>
                <Icons icon="arrow_back" />
                BACK
              </Flex>
            </Button>
            {!view && (
              <Button className='bttn bttn-transparent' type="submit">
                <Flex>
                  <Icons color="#ffffff" icon="check" />
                  SAVE DATA
                </Flex>
              </Button>
            )}
          </Flex>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export interface ModalbodyProps {
  children?: React.ReactNode
}

export const ModalBody = ({ children, ...props }: ModalbodyProps) => {

  const modal = classNames('modalbody-container')

  return (
    <div className={modal}>
      {children}
    </div>
  )

}
export interface AttributeProps {
  children?: React.ReactNode
}
export const Attributes = ({ children, ...props }: AttributeProps) => {
  const attribute = classNames('attribute-container');

  return (
    <div {...props} className={attribute} >
      {children}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  port: state.port.selectedPorts
})
export default connect(
  mapStateToProps)(PortAdd)
