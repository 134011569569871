import React, { useState } from 'react'
import sortimage from '../../../assets/images/sorting.png'
 const cmpFilter = {

    fromDate:'',
    toDate:'',
    status: '',
    claimedStatus: '',
    country: '',
    countryNames:'',
    city: '',
    cmp: '',
    page:0,
    size:10,
    searchBoxInputCmp:'',
    type:'',
    direction:''
}

const dockFilter = {
    size: 10, page: 0,
    companyName: '',
    cityName: '',
    countryName: '',
    countryNames:'',
    length: '',
    width: '',
    draft: '',
    capacity: '',
    status: '',
    searchBoxInputDock:'',
    type:'',
    direction:''
}

const ownerFilter = {
    size: 10, page: 0, 
    name: '', 
    fromDate:'',
    toDate:'', 
    Subscriptionstatus:'',
    searchBoxInputOwner:'',
    searchBoxInputEmail:'',
    DockOwnerListfindbyid:'',
    DockOwnerListfindbysort:'',
    type:'',
    direction:''
}

const DockTypelistFilter={
    searchBoxInputDockname:''

}

export const FilterState = {
    cmpFilter,
    dockFilter,
    ownerFilter,
    DockTypelistFilter
}