import { createBrowserHistory } from 'history'
import { createStore, compose, applyMiddleware } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { routerMiddleware } from 'connected-react-router'
//import logger from 'redux-logger'

import createRootReducer from './reducer'
import epics from './epics'

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});
const epicMiddleware = createEpicMiddleware()

const configureStore = () => {
    const store = createStore(
        createRootReducer(history),
        compose(applyMiddleware(epicMiddleware, routerMiddleware(history)))
    )
    epicMiddleware.run(epics)
    return store
}

export default configureStore