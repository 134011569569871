import * as Observable from 'rxjs'
import { timeout, map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getShipType = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/shipTypes/list',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addShipType = (body : any) => {
  return http({
    method: 'POST',
    url: '/shipTypes',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getShipTypeDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/shipTypes/${id}`
  }).pipe(
    map(res => {
      console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteShipType = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/shipTypes/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const updateShipType = (body : any) => {
  return http({
    method: 'PUT',
    url: '/shipTypes',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

//Dock type service

const getDockType = (params : object) => {
  // console.log('get dock type called in type service')
  return http({
    method: 'GET',
    url: '/dockType/search',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addDockType = (body : any) => {
  return http({
    method: 'POST',
    url: '/dockType',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getDockTypeDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/dockType/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteDockType = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/dockType/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const updateDockType = (body : any) => {
  return http({
    method: 'PUT',
    url: '/dockType',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}
//company type
const getCmpType = (params:object) => {
  // console.log('get country called')
  // console.log(params ,'param')
  return http({
  method: 'GET',
  url: '/companyType/list',
  params
  }).pipe(
  map(res => {
      if (res.success) {
      // console.log(res, 'res')
      return res
      } else {
      // console.log(res ,'res')
      throw new Error(res.msg)
      }
  })
  )
}
 
const addCmpType =( body : object) => {
  return http({
  method: 'POST',
  url: '/companyType',
  body
  }).pipe(
  map(res => {
      if (res.success) {
      return res
      } else {
      throw new Error(res.msg)
      }
  })
  )
}

const updateCmpType =(body:object) => {
  return http({
  method: 'PUT',
  url: `/companyType`,
  body
  }).pipe(
  map(res => {
      if (res.success) {
      // console.log('update user response', res)
      return res
      } else {
      throw new Error(res.msg)
      }
  })
  )
}

const deleteCompanyType = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/companyType/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}


//Ship sub type services

const getShipSubType = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/subShipTypes',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addShipSubType = (body : any) => {
  return http({
    method: 'POST',
    url: '/subShipTypes',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

// const getShipSubTypeDetail = (id : any) => {
//   return http({
//     method: 'GET',
//     url: `/subShipTypes/${id}`
//   }).pipe(
//     map(res => {
//       console.log(res,'get ship detail')
//       if (res.success) {
//         return res
//       } else {
//         throw new Error(res.msg)
//       }
//     })
//   )
// }

const deleteShipSubType = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/subShipTypes/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const updateShipSubType = (body : any) => {
  return http({
    method: 'PUT',
    url: '/subShipTypes',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}


const typeService = {
  getShipType,
  addShipType,
  getShipTypeDetail,
  updateShipType,
  deleteShipType,
  getDockType,
  addDockType,
  deleteDockType,
  getDockTypeDetail,
  updateDockType,
  getCmpType,
  addCmpType,
  deleteCompanyType,
  updateCmpType,
  getShipSubType,
  addShipSubType,
  // getShipSubTypeDetail,
  deleteShipSubType,
  updateShipSubType
}
  
export default typeService
  

