import { map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getUnit = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/units/list',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addUnit = (body : any) => {
  return http({
    method: 'POST',
    url: '/units',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteUnit = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/units/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const getUnitDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/units/view/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const updateUnit = (body : any) => {
  return http({
    method: 'PUT',
    url: '/units',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const unitService = {
  getUnit,
  addUnit,
  getUnitDetail,
  deleteUnit,
  updateUnit,
}
  
export default unitService