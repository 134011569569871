import React, { useRef,useState } from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Image } from 'react-bootstrap'
import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import noImg from '../../../../assets/images/NoImg.jpg';
import Card from '../../../../common/components/card/Card'
import editicon from '../../../../assets/images/editone.png'
import removeicon from '../../../../assets/images/Remove.png'
import swal from 'sweetalert'
// import { Grid } from '../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Select } from '../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import userService from '../../../../services/userService'
// import codeService from '../../../services/codeService'
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import { JsxChild } from 'typescript'
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns'
import {
  roleListGetAction,
  roleSetSelectedAction
} from '../../../../store/action/roleAction'
import { history } from '../../../../store';
// import swal from 'sweetalert'
import './../Role.scss'
import Loader from 'react-loader-spinner';
import { Label } from '../../../../common/components/label/Label';
import { Link } from 'react-router-dom';
import RoleService from '../../../../services/roleService'

export const UserRoleView = (props: any) => {
    const { match } = props
    const { id } = match.params
    const [isLoading, setLoading] = React.useState(false)
    const role = localStorage.getItem("role")
    const [userDetail, setUserDetail] = React.useState<any>({
        userName: '',
        userCode: '',
        userPage: '',
        createdAt: ''
    })
   
    const { user } = props
    const _getUserInfoForView = (id: any) => {
        
        setLoading(true)
        const { user } = props
        return RoleService.viewRoles(id).subscribe({
            next: r => {
                console.log(r.data, 'data in map company')
                var userRoles = ''
                var createdDate = ''
                // if (r.data.role.length === 0 || r.data.role === null) {
                //     userRoles = ''
                // }
                // else {

                //     userRoles = r.data.role[0].name
                // }
                if (r.data.created_at === null || '') {
                    createdDate = ''
                }
                else {
                    createdDate = format(parseISO(r.data.created_at), 'dd/MM/yyyy')
                }
                setUserDetail({
                    userName: r.data.name,
                    userCode: r.data.code,
                    userPage: r.data.adminPages,
                    createdAt: createdDate
                })
                setLoading(false)
            },
            error: err => {
                console.error(err)
            }
        })
    }
    React.useEffect(() => {
        const { id } = match.params
        _getUserInfoForView(id)
    }, [])
    const _goback = () => {
        history.push('/roles')
    }
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    return (
        <Main>
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                <Col sm='12' md='12' lg='7' xl='7' className='m-0 p-0 align-top'>
                    <Card title="Role Detail">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>User Role</Text>
                                        {userDetail.userName === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >{userDetail.userName}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Code</Text>
                                        {userDetail.userCode === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{userDetail.userCode}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Access Pages</Text>
                                        {userDetail.userPage === "" ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{userDetail.userPage}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Created Date</Text>
                                        {userDetail.createdAt === '' ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{userDetail.createdAt}</Text>
                                        )}
                                    </div>
                                    
                                </Attributes>
                            </Col>
                            <Col sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    {/* <div>
                                        <Text color='main' variant='subtitle'>Description</Text>
                                        {display.description === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.description}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>VS %</Text>
                                        {display.vs === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >{display.vs}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Side</Text>
                                        {display.side === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.side}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>DFT</Text>
                                        {display.dft === null ? (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >-</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold>{display.dft}</Text>
                                        )}
                                    </div> */}
                                </Attributes>
                            </Col>
                        </Row>
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    {/* <div>
                                        <Text color='main' variant='subtitle'>Primary Coat</Text>
                                        {display.primer === false && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                No</Text>
                                        )}
                                        {display.primer === true && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                Yes</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Middle Coat</Text>
                                        {display.mid_coat === false && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                No</Text>
                                        )}
                                        {display.mid_coat === true && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                Yes</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Finish Coat</Text>
                                        {display.finish_coat === false && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                No</Text>
                                        )}
                                        {display.finish_coat === true && (
                                            <Text color='main' variant='subtitle' className='Detail-page-text' bold >
                                                Yes</Text>
                                        )}
                                    </div> */}
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm='12' md='12' lg='5' xl='5' className='m-0 p-0 align-top'>
                    {/* <div className='ml-xl-3  mt-sm-4 mt-md-4 mt-lg-4 mt-xl-0 view_page_xs_margin'>

                    </div> */}
                    
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='7' xl='7'>
                    <Actions>
                        {/* If you click the back button its is redirect to ships list page.  */}
                        <Link to='/roles'>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the edit button its is redirect to ships edit page.  */}
                        {role === 'VISITOR' ? (
                            <Link to={`/roles/edit/${id}`}>
                            <Button variant='transparent' type="submit" disabled>
                                <Flex>
                                    <Icons color="#ffffff" icon="edit" />
                                    EDIT DATA
                                </Flex>
                            </Button>
                            </Link>
                        ) : (
                            <Link to={`/roles/edit/${id}`}>
                                <Button variant='transparent' type="submit">
                                    <Flex>
                                        <Icons color="#ffffff" icon="edit" />
                                        EDIT DATA
                                    </Flex>
                                </Button>
                             </Link>
                        )}
                    </Actions>
                </Col>
            </Row>
        </Main>
    )

}

export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.roles.selectedRoles,
})

export default connect(
    mapStateToProps,
    {
        roleListGetAction,
        roleSetSelectedAction
    }
)(UserRoleView)