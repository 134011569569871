import React, { useState } from 'react'

const Anodefilter={
    searchBoxInputName:'',
    searchBoxInputCmp:'',
    searchBoxInputOwner:'',
    findbyIdname:''

}

export const AnodefilterState={
   Anodefilter 
}