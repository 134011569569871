import {
    DATA_SHIP_PROPULSION_LIST_GET,
    DATA_SHIP_PROPULSION_LIST_GET_F,
    DATA_SHIP_PROPULSION_LIST_GET_R,
    DATA_SHIP_PROPULSION_SELECTED_SET
}  from '../actionType'

const initialState = {
    propulsion: [],
    isLoading: false,
    error: null,
    selectedPropulsion: null,
    totalPage: 0,
    totalElement: 0,
}

export default function propulsionReducer (state = initialState, action:any)  {
    switch(action.type) {
        case DATA_SHIP_PROPULSION_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            } 
        case DATA_SHIP_PROPULSION_LIST_GET_F:
            return {
                ...state,
                isLoading: false,
                propulsion: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
            }
        case DATA_SHIP_PROPULSION_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case DATA_SHIP_PROPULSION_SELECTED_SET:
            return {
                ...state,
                selectedPropulsion: action.payload
            }
        default:
            return state
    }
}