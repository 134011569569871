import jwt_decode from 'jwt-decode';
import { map } from 'rxjs/operators';
import httpLogin from '../helpers/httpLogin';
import http from '../helpers/http'
import { NewReleases } from '@material-ui/icons';

const login = (body : object) => {
  return httpLogin({
    method: 'POST',
    body: body,
    url: '/users/login'
  }).pipe(
    map(res => {
      // console.log(res.data.access_token)
      // console.log(res)
      // console.log(res.success)
      // console.log(res.data)
      if (res.success) {
        // console.log('true')
        const { data } = res
        if(data.roles && data.roles[0]){
          localStorage.setItem('pages', data.roles[0].adminPages)
        }
        if (data.roles && data.roles[0] && data.roles[0].code === 'ADMIN'||'VISITOR') {
          localStorage.setItem("role",data.roles[0].code)
          console.log("the valuse stored in the local storage", data.roles[0].code)
          return res;
        } else {
          throw new Error('Permission Denied')
        }        
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const forgotPassword = (body : object) => {
  return http({
    method: 'POST',
    body: body,
    url: '/users/forgot-password'
  }).pipe(
    map(res => {
      // console.log(res,'forgot password response')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const resetPassword = (body : object) => {
  console.log('working')
  return http({
    method: 'POST',
    body: body,
    url: '/users/AdminResetPassword'
  }).pipe(
    map(res => {
      if (res.success) {
        console.log(res,'success')
        // alert(res)
        return res    
      } else {
        console.log(res,'error')
        // alert(res)
        throw new Error(res.msg)
      }
    })
  )
}

const persistAccount = (account : any) => {
  // console.log(account,'account in auth service')
  sessionStorage.setItem('NAVIDOK_ACCESS_TOKEN', JSON.stringify(account))
}
const isAuthenticated = () => {
  const token = _getToken()
  return _isTokenExpired(token) === false
}

const getAccount = () => {
  return JSON.parse(String(sessionStorage.getItem('NAVIDOK_ACCESS_TOKEN')))
}

const _getToken = () => {
  const account = JSON.parse(String(sessionStorage.getItem('NAVIDOK_ACCESS_TOKEN')))
  if (account && account.token) {
    // console.log(account.token + " get token ")    
    return account.token
  }
  return null
}

const _isTokenExpired = (token : any) => {
  if (token) {
    const decoded:any = jwt_decode(token)
    // console.log(decoded,'decoded token')
    // console.log(decoded.exp,'decoded exp')
    // console.log(new Date().getTime(),'currentTime')
    // console.log(new Date().getMilliseconds(),'milli seconds')
    const expirationTime = (decoded.exp * 1000) - 60000
    // console.log(expirationTime,'expiration time')
//     console.log(expirationTime < new Date().getTime(),'return')
    return expirationTime < new Date().getTime()
  }
  return true
}

const logout = () => {
  sessionStorage.removeItem('NAVIDOK_ACCESS_TOKEN')
  localStorage.removeItem('role')
  localStorage.clear()
}

const authService = {
  getToken: _getToken,
  persistAccount,
  login,
  forgotPassword,
  resetPassword,
  getAccount,
  logout,
  isAuthenticated,
}
export default authService
