import React from 'react';
import './Flex.scss';
import classNames from 'classnames'

//set type for justify content
export type JustifyContent = 'jc-start'| 'jc-end' | 'jc-center' | 'jc-between' | 'jc-flexend'| 'jc-around' | 'jc-strech' | 'jc-checkbox';

//set type for AlignItem
export type AlignItem = 'ai-start' | 'ai-end' |'ai-center' | 'ai-baseline' | 'ai-stretch'  ;

//set interface for Flex component
export interface FlexProps {
    jc?: JustifyContent;
    ai?:AlignItem;
    children?: React.ReactNode;
    className? : string;
}
//set classname for flex base on which type of justisy content is going to use
export function Justify(jc: JustifyContent | undefined): string {
    return jc ? `${jc}` : 'jc-center';
}

//set classname for flex base on which type of align Item is going to use
export function align(ai: AlignItem | undefined): string {
    return ai ? `${ai}` : 'ai-center';
}

//Flex Component. it retun div with display style flex
export const Flex = ({jc ,ai,children,className, ...props } : FlexProps) => {
    const flxClassNames = classNames('flx', Justify(jc),align(ai),className );

    return (
        <div {...props} className={flxClassNames} >
            {children}
        </div>
    )
}
