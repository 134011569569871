import { map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getAds = (params : object) => {
  return http({
    method: 'GET',
    url: '/advertisement/list',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in ship list page')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getAdsDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/advertisement/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deleteAds = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/ships/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error()
      }
    })
  )
}

const updateAds = (body : any) => {
  return http({
    method: 'PUT',
    url: '/advertisement/update',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const addAds = (body : any) => {
  return http({
    method: 'POST',
    url: '/advertisement/create',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const adsService = {
  getAds,
  getAdsDetail,
  deleteAds,
  updateAds,
  addAds,
}

export default adsService
