import React, { useRef } from 'react'
import { Main } from '../../../../common/components/main/Main'
import noimg from '../../../../assets/images/NoImg.jpg';
import editicon from '../../../../assets/images/editone.png'
import removeicon from '../../../../assets/images/Remove.png'
import { Col, Form, Row, Button, Image } from 'react-bootstrap'
// import { Button } from '../../../../common/components/button/Button'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Select } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import codeService from '../../../../services/codeService'
import Icons from '../../../../common/components/icon/Icon'
import { WEIGHT_UNIT_DEFAULT, DEPTH_UNIT_DEFAULT, LENGTH_UNIT_DEFAULT, BEAM_UNIT_DEFAULT, DRAFT_UNIT_DEFAULT } from '../../../../constants/units'
import shipService from '../../../../services/shipService'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import { connect } from 'react-redux'
import { shipSetSelectedAction } from '../../../../store/action/shipActions'
import swal from 'sweetalert';
import { useForm } from 'react-hook-form'
import { Label } from '../../../../common/components/label/Label';
import Loader from 'react-loader-spinner';
import Carousel from 'react-bootstrap/Carousel'
import { format, parseISO } from 'date-fns'

// Blue print of the class component. 
interface shipEditProps {
    name: string,
    type: any,
    imo_number: string,
    company: string,
    id: string | number,
    status: string,
    country_flag: any,
    classification: any,
    material: any,
    weight: string,
    length: string,
    beam: string,
    draft: string,
    depth: string,
    classification2: any,
    classificationCode: string | Number,
    MaterialCode: string | Number,
    countryCode: string,
    companyCode: string,
    shipTypeCode: string,
    created_date: any,
    verified_date: any,
    cmpID: string,
    shipImage: string,
    nrt: string,
    grt: string,
    dwt: string,
    lbp: string,
    yearBuilt: string,
    builderAddress: string,
    builderName: string,
    shipCall: string,
    auxEngine: string,
    picCredit: string
    mainEngine: string,
    inService: string,
    shipOperator: string,
    shipOperatorAddress: string,
    shipClassificationId2: string | Number,
    shipOwner: string;
    shipLogo: any;
    sourceStatus: string;
    specialSurveyDate: string;
    dryDockDate: string;
    resell: string;
    showresell: boolean;
    mmsi: string;
}
interface bvalueProps {
    string_image: string | ArrayBuffer | null
}
interface FormData {
    txt_shipEditName: string;
    txt_shipEditType: string;
    txt_shipEditCountry: string;
    txt_shipEditClassification: string;
    txt_shipEditMaterial: string;
    txt_shipEditImo: string;
    txt_shipEditCompany: string;
    txt_shipEditWeight: string;
    txt_shipEditLength: string;
    txt_shipEditBeam: string;
    txt_shipEditDraft: string;
    txt_shipEditDepth: string;
    radio_shipEditStatus: string;
    txt_shipnrt: string;
    txt_shipgrt: string;
    txt_shipdwt: string;
    txt_shiplbp: string;
    txt_yearBuilt: string;
    txt_builderAddress: string;
    txt_builderName: string;
    txt_shipCall: string;
    txt_auxEngine: string;
    txt_picCredit: string;
    txt_mainEngine: string;
    txt_inService: string;
    txt_shipOperator: string;
    txt_shipOperatorAddress: string;
    txt_shipClassificationId2: string;
    txt_specialSurveyDate: string;
    txt_dryDockDate: string;
    txt_sourceStatus: string;
    chk_resell: string;
    txt_mmsi: string;
}
function formatDate(newDate: any) {
    console.log("new date" + newDate)
    if (newDate != null) {
        type tplotOptions = {
            [key: string]: string
        }
        const months: tplotOptions = {
            0: 'JAN',
            1: 'FEB',
            2: 'MAR',
            3: 'APR',
            4: 'MAY',
            5: 'JUN',
            6: 'JUL',
            7: 'AUG',
            8: 'SEP',
            9: 'OCT',
            10: 'NOV',
            11: 'DEC',
        }
        const d = new Date(newDate)
        const year = d.getFullYear()
        const date = d.getDate()
        const monthIndex = d.getMonth()
        const monthName = months[monthIndex]
        const formatted = `${date} ${monthName} ${year}`
        console.log(formatted.toString())
        return formatted.toString()
    } else {
        console.log("empty string")
        return ''
    }
}
const ShipEdit = (props: any) => {
    const { match } = props
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const [showDelete, setShow] = React.useState(false);
    const [showCheck, setShowCheck] = React.useState(false);
    var [removeShipImage, setRemoveShipImage] = React.useState(0)
    const [shipEditflag, setShipEditFlag] = React.useState(false)
    const [display, setDisplay] = React.useState<shipEditProps>({
        name: '',
        type: '',
        imo_number: '',
        company: '',
        id: '',
        status: '',
        country_flag: '',
        classification: '',
        material: '',
        classificationCode: '',
        classification2: '',
        MaterialCode: '',
        countryCode: '',
        companyCode: '',
        shipTypeCode: '',
        weight: '',
        length: '',
        depth: '',
        beam: '',
        draft: '',
        created_date: '',
        verified_date: '',
        cmpID: '',
        shipImage: '',
        nrt: '',
        grt: '',
        dwt: '',
        lbp: '',
        yearBuilt: '',
        builderAddress: '',
        builderName: '',
        shipCall: '',
        auxEngine: '',
        picCredit: '',
        mainEngine: '',
        inService: '',
        shipOperator: '',
        shipOperatorAddress: '',
        shipClassificationId2: '',
        shipOwner: '',
        shipLogo: [],
        specialSurveyDate: '',
        dryDockDate: '',
        sourceStatus: '',
        resell: 'false',
        showresell: false,
        mmsi:'',
    })
    const [isLoading, setLoading] = React.useState(false)
    const [ships, setShip] = useState<any>({
        isLoading: false,
        isSubmitting: false,
        shipName: '',
        shipType: [],
        editShip: [{}],
        shipTypes: '',
        countryFlag: [],
        countryFlags: '',
        classification: [],
        classification2: [],
        material: [],
        materials: '',
        IMONumber: '',
        Company: [],
        Companys: '',
        Weight: '',
        Length: '',
        Beam: '',
        Draft: '',
        Depth: '',
        status: 'UNVERIFIED',
        nrt: '',
        grt: '',
        dwt: '',
        lbp: '',
        yearBuilt: '',
        builderAddress: '',
        builderName: '',
        shipCall: '',
        auxEngine: '',
        picCredit: '',
        mainEngine: '',
        inService: '',
        shipOperator: '',
        shipOperatorAddress: '',
        specialSurveyDate: '',
        dryDockDate: '',
        sourceStatus: '',
        mmsi:''
        //        shipClassificationId2:[]
    },
    )
    var data: any
    var length: any
    var example = ["https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1711.jpg",
        "https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1749.jpg",
        "https://auth-apip2-m2.navidok.com/app1/home/images/DOCK1369.jpg"]

    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<bvalueProps>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageUserAPI(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    // Delete the pericular list.  
    const handleClose = () => setShow(false);
    const handleShow = (ship: any) => {
        setShow(true);
        setRemoveShipImage(ship.ship_id);
        // console.log(deleteShip,'deleted ship id')
    }

    const _removeShipLogo = (id: any) => {
        return shipService.removeShipLogo(id).subscribe({
            next: (r) => {
                // console.log(r, 'response')
                setShow(false);
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
                // history.push(`/ships/${id}/`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
            }
        })
    }

    const ImageUserAPI = (imageurl: any) => {
        const { id } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        shipService.updateShipLogo(imagePayload).subscribe({
            next: () => {
                window.location.reload();
                // swal("Great", "Dock Company Image Updated", "success")
                //history.push('/ships/list')
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }

    const _goback = () => {
        const { pgnum } = match.params
        const { key } = match.params
        console.log('go back called')
        console.log(pgnum, 'page')
        history.push(`/ships/list/${pgnum}`)
    }

    // Used to get ship classifications list and store to classifications variable. 
    const _getClassification = (() => {
        return codeService.getShipClassification().subscribe({
            next: r => {

                // console.log(r.data,"ship classification")
                setShip({ ...ships, classification: r.data })
                // console.log(ships.classification ,'ship classification')
                r.data.map((data: any, i: any) => {
                    return ships.classification.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getClassification2 = (() => {
        return codeService.getShipClassification().subscribe({
            next: r => {

                // console.log(r.data,"ship classification")
                setShip({ ...ships, classification2: r.data })
                // console.log(ships.classification ,'ship classification')
                r.data.map((data: any, i: any) => {
                    return ships.classification2.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    // Used to get ship material list and store to materials variable. 
    const _getShipMaterial = (() => {
        return codeService.getShipMaterial().subscribe({
            next: r => {

                // console.log(r.data,"ship classification")
                setShip({ ...ships, material: r.data })
                // console.log(ships.classification ,'ship classification')
                r.data.map((data: any, i: any) => {
                    return ships.material.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    // Used to get ship types list and store to types variable. 
    const _getShipType = (() => {
        return codeService.getShipTypes().subscribe({
            next: r => {

                // console.log(r.data,"ship type")
                setShip({ ...ships, shipType: r.data })
                // console.log(ships.shipType ,'ship type data in ship add page')
                r.data.map((data: any, i: any) => {
                    return ships.shipType.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })


    // Used to get ship companies list and store to companies variable. 
    const _getCompany = (() => {
        return codeService.getCompanies({ company_type: 'SHIP' }).subscribe({
            next: r => {
                // console.log(r.data,'companies in ship add page')
                setShip({ ...ships, Company: r.data })
                r.data.map((data: any, i: any) => {
                    return ships.Company.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    // Used to get countries list and store to companies variable. 
    const _getCountryFlag = (() => {
        return codeService.getCountries().subscribe({
            next: r => {
                setShip({ ...ships, countryFlag: r.data })
                r.data.map((data: any, i: any) => {
                    return ships.countryFlag.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    //showing reselling alert message
    const setReselling = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        swal({
            title: "Are you sure?",
            text: "If you set it as marked then this ship is not resell to anyone",
            icon: "warning",
            buttons: ["Cancel", "Yes"],
            dangerMode: true,
            className: 'styleTitle'
        }).then((willDelete) => {
            if (willDelete) {
                setDisplay({ ...display, resell: 'true' })
            } else {
                setDisplay({ ...display, resell: 'false' })
            }
        })
    };
    //SetDisplay Drydock date
    const setDrydockDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setDisplay({ ...display, showresell: true });
        setShowCheck(true);
        setDisplay({ ...display, dryDockDate: event.target.value });
    }
    //setDisplay Special Drydock date
    const setSpecialDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisplay({ ...display, showresell: true });
        setShowCheck(true);
        setDisplay({ ...display, specialSurveyDate: event.target.value });
    }

    // Used to get perticular ship detail and store to variable.  
    const _getShipDetail = ((id: any) => {
        setShip({ ...ships, isLoading: true })
        setLoading(true)
        return shipService.getShipDetail(id).subscribe({
            next: r => {
                // console.log(r,'response')
                // console.log(r.data, 'data within response')
                const value = r.data
                // console.log(value, 'data within response using value')
                var shipCmpId
                var shipOwnerName
                var shipCmpName
                if (value.shipCompany !== null) {
                    shipCmpId = value.shipCompany.id
                    if (value.shipCompany.shipOwner !== null) {
                        shipOwnerName = value.shipCompany.shipOwner.name
                    }
                    else {
                        shipOwnerName = ''
                    }
                }
                else {
                    shipCmpId = ''
                }
                if (value.shipCompany !== null) {
                    shipCmpName = value.shipCompany.name
                }
                else {
                    shipCmpName = ''
                }
                var ShipClasi: any
                if (value.shipClassification !== null) {
                    ShipClasi = value.shipClassification.id
                }
                else {
                    ShipClasi = ''
                }
                var ShipClasi2: any
                if (value.shipClassification2 !== null) {
                    ShipClasi2 = value.shipClassification2.id
                }
                else {
                    ShipClasi2 = ''
                }
                var ShipMateri: any
                if (value.shipMaterial !== null) {
                    ShipMateri = value.shipMaterial.id
                }
                else {
                    ShipMateri = ''
                }
                var shipCountry: any;
                if (value.shipCountry !== null) {
                    shipCountry = value.shipCountry.id;
                }
                else {
                    shipCountry = '';
                }
                var preferedShipType
                if (value.shipTypes !== null) {
                    preferedShipType = value.shipTypes.id
                }
                else {
                    preferedShipType = ''
                }
                var shipImo
                if (value.imo_number !== null) {
                    shipImo = value.imo_number
                }
                else {
                    shipImo = ''
                }
                var specialSurveyDate: any
                if (value.specialSurveyDate !== null) {
                    specialSurveyDate = String(value.specialSurveyDate)
                }
                else {
                    specialSurveyDate = null
                }
                var dryDockDate: any
                if (value.dryDockDate !== null) {
                    dryDockDate = String(value.dryDockDate)
                }
                else {
                    dryDockDate = null
                }

                setDisplay({
                    id: Number(value.id),
                    name: value.name,
                    type: preferedShipType,
                    imo_number: shipImo,
                    company: shipCmpName,
                    cmpID: shipCmpId,
                    status: value.status,
                    country_flag: shipCountry,
                    classification: ShipClasi,
                    material: ShipMateri,
                    classificationCode: ShipClasi,
                    MaterialCode: ShipMateri,
                    countryCode: shipCountry,
                    companyCode: shipCmpId,
                    shipTypeCode: value.shipTypes.id,
                    weight: value.weight,
                    length: value.length,
                    beam: value.beam,
                    draft: value.draft,
                    depth: value.depth,
                    created_date: value.created_at,
                    verified_date: value.verified_at,
                    shipImage: value.ship_image,
                    nrt: value.nrt,
                    grt: value.grt,
                    dwt: value.dwt,
                    lbp: value.lbp,
                    yearBuilt: value.yearBuilt,
                    builderAddress: value.builderAddress,
                    builderName: value.builderName,
                    shipCall: value.shipCall,
                    auxEngine: value.auxEngine,
                    picCredit: value.picCredit,
                    mainEngine: value.mainEngine,
                    inService: value.inService,
                    shipOperator: value.shipOperator,
                    shipOperatorAddress: value.shipOperatorAddress,
                    classification2: ShipClasi2,
                    shipClassificationId2: ShipClasi2,
                    shipOwner: shipOwnerName,
                    shipLogo: value.shipLogo,
                    specialSurveyDate: specialSurveyDate,
                    dryDockDate: dryDockDate,
                    sourceStatus: value.source_data,
                    resell: "false",
                    showresell: false,
                    mmsi:value.mmsi
                })
                console.log('Source data', display.sourceStatus)
                console.log(display.dryDockDate)
                console.log(display.shipImage)
                props.shipSetSelectedAction(value)
                setShip({ ...ships, isLoading: false, })
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setShip({ ...ships, isLoading: false })
                setLoading(false)
            }
        })
    })

    // Call classification, company, shiptype and country functions at the stage of page loading. 
    React.useEffect(() => {
        const { id } = match.params
        _getShipDetail(id)
        _getClassification()
        _getShipMaterial()
        _getCompany()
        _getShipType()
        _getClassification2()
        _getCountryFlag()
    }, [])

    // After update all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call. 
    const _didShipEdit = () => {
        // e.preventDefault()
        var set_resell
        ships.Company.map((data: any, i: any) => {
            if (display.company === data.name) {
                setDisplay({ ...display, cmpID: data.id })
            }
        })
        if (display.resell == 'true') {
            set_resell = true;
        } else {
            set_resell = false;
        }
        const updatedForm = {
            id: Number(display.id),
            shipWeight: Number(display.weight),
            weightUnitCode: WEIGHT_UNIT_DEFAULT.code,
            length: Number(display.length),
            lengthUnitCode: LENGTH_UNIT_DEFAULT.code,
            depth: Number(display.depth),
            draftUnit: DRAFT_UNIT_DEFAULT.code,
            maximumBeam: Number(display.beam),
            beamUnitCode: BEAM_UNIT_DEFAULT.code,
            draft: Number(display.draft),
            draftUnitCode: DRAFT_UNIT_DEFAULT.code,
            status: display.status,
            imoNumber: display.imo_number,
            classificationCode: Number(display.classification),
            materialCode: String(display.material),
            countryFlagCode: String(display.country_flag),
            companyCode: String(display.cmpID),
            shipTypeCode: String(display.type),
            shipName: display.name,
            nrt: display.nrt,
            grt: display.grt,
            dwt: display.dwt,
            lbp: display.lbp,
            yearBuilt: display.yearBuilt,
            builderAddress: display.builderAddress,
            builderName: display.builderName,
            shipCall: display.shipCall,
            auxEngine: display.auxEngine,
            picCredit: display.picCredit,
            mainEngine: display.mainEngine,
            shipImage: display.shipImage,
            inService: display.inService,
            shipOperator: display.shipOperator,
            shipOperatorAddress: display.shipOperatorAddress,
            shipClassificationId2: String(display.classification2),
            specialSurveyDate: display.specialSurveyDate,
            dryDockDate: display.dryDockDate,
            sourceStatus: display.sourceStatus,
            resell: set_resell,
            mmsi: display.mmsi
            // image:display.shipImage
        }

        // API call for updateship with data, updated into list page. 
        if (shipEditflag === true) {
            const { pgnum } = match.params
            const { key } = match.params
            shipService.updateShip(updatedForm).subscribe({
                next: () => {
                    swal("Great", "Ship Updated Successfully", "success")
                    history.push(`/ships/list/${pgnum}`)
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    console.error(err)
                }
            })
        }
        else {
            console.log(shipEditflag, 'flag')
        }

    }

    onsubmit = (e: any) => {
        // e.preventDefault()
        if (Object.keys(errors).length === 0) {
            _didShipEdit()
            setShipEditFlag(true)
        }
        else {
            setShipEditFlag(false)
        }
    }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    length = 0
    if (display.shipLogo !== null) {
        data = [display.shipLogo.map((data: any, i: any) => {
            length = length + 1
            return String(data.name)

        })];
        console.log("Check", data);
    } else {
        data = null;
    }
    console.log(length)
    if (display.shipLogo !== null) {
        data.map((value: any) => (
            example = value
            //       console.log("inside carosel",value)
        ))
    }

    return (
        <Main>
            {/* <Text color='black' variant="title">Edit Ship Information</Text> */}
            <div className='title-name-block'>
                {console.log(display.shipImage)}
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                    {display.shipImage === null && (
                        <Image src={noimg} alt='logo' roundedCircle />
                    )}
                    {display.shipImage === '' && (
                        <Image src={noimg} alt='logo' roundedCircle />
                    )}
                    {display.shipImage !== null && display.shipImage !== '' && (
                        <Image src={display.shipImage} alt='logo' roundedCircle />
                    )}
                </div>
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                    <label className="custom-file-upload">
                        <Image src={editicon} alt='logo' />
                        <input
                            ref={upload}
                            type="file"
                            onChange={getBase64}
                        />
                    </label>
                </div>
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                    <label className="custom-file-upload" onClick={() => _removeShipLogo(display.id)}>
                        <Image src={removeicon} alt='logo' />
                    </label>
                </div>
                <Text variant='title' color='main' className='txt-capitalize'>
                    {display.name}
                </Text>
                <Label className={display.status === 'UNVERIFIED' ? 'danger' : 'warning'} >
                    {display.status === 'UNVERIFIED' ? 'Not Verified' : 'Verified'}
                </Label>
            </div>
            <Space divheight={15} />
            {/* Edit form, having input box, select box.  */}
            <Row className='p-0 m-0'>
                <Col xs='12' sm='12' md='12' lg='9' xl='9' className='p-0 m-0'>
                    <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='ShipAddForms'>
                        <Card title="Edit Ship Information">
                            <Row className='m-0 p-0'>
                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes>
                                        <div className='sm-mt-3'>
                                            <Text color='tablehead' variant='subtitle' >Ship Name</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                type="text"
                                                name="txt_shipEditName"
                                                id='txt_shipEditName'
                                                value={display.name}
                                                onChange={(e) => setDisplay({ ...display, name: e.target.value })}
                                                ref={register({ required: true, })}
                                                placeholder="Ship Name"
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipEditName?.type === 'required' && 'Please enter Ship name'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>IMO Number</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipEditImo"
                                                // ref={register({ pattern: /^IMO [1-9]{7}/ })}
                                                // step='0.01'
                                                type='text'
                                                // min={0}
                                                // max={99999}
                                                placeholder="IMO Number"
                                                value={display.imo_number}
                                                onChange={e => setDisplay({ ...display, imo_number: e.target.value })}
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>
                                                    {/* {errors.txt_shipEditImo?.type === 'required' && 'Please enter IMO Number'} */}
                                                    {errors.txt_shipEditImo?.type === 'pattern' && 'IMO number should be IMO followed by 7 digits'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Ship Type</Text>
                                            <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                                name='txt_shipEditType'
                                                value={display.type}
                                                ref={register({ required: true, })}
                                                onChange={(e) => setDisplay({ ...display, type: e.target.value })}>
                                                <option value="">-- Select --</option>
                                                {/* Map the data's from shiptype array and used as a option value. By Dharani on 04-Mar-2021 */}
                                                {ships.shipType.map((data: any, i: any) => {
                                                    return <option key={i} value={data.id}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipEditType?.type === 'required' && 'Please select Ship type'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Country Flag</Text>
                                            <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                                name='txt_shipEditCountry'
                                                value={display.country_flag}
                                                onChange={(e) => setDisplay({ ...display, country_flag: e.target.value })}
                                                ref={register({ required: true, })}>
                                                <option value="">-- Select --</option>
                                                {/* Map the data's from countryflag array and used as a option value. By Dharani on 04-Mar-2021 */}
                                                {ships.countryFlag.map((data: any, i: any) => {
                                                    // console.log(data.id,'id in ship country')
                                                    return <option key={i} value={data.id}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                            {/* {console.log(ship.countryFlags,'country flag')} */}
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipEditCountry?.type === 'required' && 'Please select Country name'}
                                                </span>
                                            </Col>
                                        </Row>

                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipEditClassification?.type === 'required' && 'Please select Ship Classification'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Hull Material</Text>
                                            <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                                name='txt_shipEditMaterial'
                                                value={display.material}
                                                onChange={e => setDisplay({ ...display, material: e.target.value })}
                                            // ref={register({ required: true, })}
                                            >
                                                <option value="">-- Select --</option>
                                                {/* Map the data's from classification array and used as a option value. By Dharani on 04-Mar-2021 */}
                                                {ships.material.map((data: any, i: any) => {
                                                    return <option key={i} value={data.id}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                        </div>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Length Overall (m)</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipEditLength"
                                                value={display.length}
                                                onChange={e => setDisplay({ ...display, length: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='number'
                                                // min={0}
                                                // max={99999}
                                                placeholder="Length"
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipEditLength?.type === 'required' && 'Please enter Ship Length'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>LBP</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shiplbp"
                                                value={display.lbp}
                                                onChange={e => setDisplay({ ...display, lbp: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='number'
                                                // min={0}
                                                // max={99999}
                                                placeholder="lbp"
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shiplbp?.type === 'required' && 'Please enter LBP'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Maximum Beam (m)</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipEditBeam"
                                                value={display.beam}
                                                onChange={e => setDisplay({ ...display, beam: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='number'
                                                // min={0}
                                                // max={99999}
                                                placeholder="Beam"
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipEditBeam?.type === 'required' && 'Please enter Ship beam'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Depth (m)</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipEditDepth"
                                                value={display.depth}
                                                onChange={e => setDisplay({ ...display, depth: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='number'
                                                // min={0}
                                                // max={99999}
                                                placeholder="Depth"
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipEditDepth?.type === 'required' && 'Please enter Ship Depth'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Draft (m)</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipEditDraft"
                                                value={display.draft}
                                                onChange={e => setDisplay({ ...display, draft: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='number'
                                                // min={0}
                                                // max={99999}
                                                placeholder="Draft"
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipEditDraft?.type === 'required' && 'Please enter Ship Draft'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Weight (DWT)</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipEditWeight"
                                                value={display.weight}
                                                onChange={e => setDisplay({ ...display, weight: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='number'
                                                // min={0.00}
                                                // max={99999.00}
                                                placeholder="Weight"
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipEditWeight?.type === 'required' && 'Please enter Ship weight'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>GRT</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipgrt"
                                                value={display.grt}
                                                onChange={e => setDisplay({ ...display, grt: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='number'
                                                // min={0}
                                                // max={99999}
                                                placeholder="grt"
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipgrt?.type === 'required' && 'Please enter GRT'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>NRT</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipnrt"
                                                value={display.nrt}
                                                onChange={e => setDisplay({ ...display, nrt: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='number'
                                                // min={0}
                                                // max={99999}
                                                placeholder="nrt"
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipnrt?.type === 'required' && 'Please enter NRT'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>DWT</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipdwt"
                                                value={display.dwt}
                                                onChange={e => setDisplay({ ...display, dwt: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='number'
                                                // min={0}
                                                // max={99999}
                                                placeholder="dwt"
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipdwt?.type === 'required' && 'Please enter DWT'}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Attributes>
                                </Col>
                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes >
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>inService</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_inService"
                                                value={display.inService}
                                                onChange={e => setDisplay({ ...display, inService: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='text'
                                                // min={0}
                                                // max={99999}
                                                placeholder="inService"
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_inService?.type === 'required' && 'Please enter inService'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Ship Call</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipCall"
                                                value={display.shipCall}
                                                onChange={e => setDisplay({ ...display, shipCall: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='text'
                                                // min={0}
                                                // max={99999}
                                                placeholder="Ship Call"
                                            />
                                        </div>
                                        <div>
                                            <Text color='main' variant='subtitle'>Ship Owner</Text>
                                            {display.shipOwner !== null ? (
                                                <>{display.shipOwner !== null ? (<Text color='main' variant='subtitle' bold>{display.shipOwner}</Text>) : (<Text variant='subtitle' color="main" bold> - </Text>)}</>
                                            ) : (
                                                <Text variant='subtitle' color="main" bold> - </Text>
                                            )}
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipCall?.type === 'required' && 'Please enter Ship Call'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Year Built</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_yearBuilt"
                                                value={display.yearBuilt}
                                                onChange={e => setDisplay({ ...display, yearBuilt: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='number'
                                                // min={0}
                                                // max={99999}
                                                placeholder="Year Built"
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_yearBuilt?.type === 'required' && 'Please enter Year Built'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Builder Name</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_builderName"
                                                value={display.builderName}
                                                onChange={e => setDisplay({ ...display, builderName: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='text'
                                                // min={0}
                                                // max={99999}
                                                placeholder="Builder Name"
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_builderName?.type === 'required' && 'Please enter Builder Name'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Ship Operator</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipOperator"
                                                value={display.shipOperator}
                                                onChange={e => setDisplay({ ...display, shipOperator: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='text'
                                                // min={0}
                                                // max={99999}
                                                placeholder="ship operator"
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipOperator?.type === 'required' && 'Please enter Ship Operator'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Ship Operator Address</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_shipOperatorAddress"
                                                value={display.shipOperatorAddress}
                                                onChange={e => setDisplay({ ...display, shipOperatorAddress: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='text'
                                                // min={0}
                                                // max={99999}
                                                placeholder="ship operator address"
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipOperatorAddress?.type === 'required' && 'Please enter Ship Operator Address'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Classification</Text>
                                            <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                                name='txt_shipEditClassification'
                                                value={display.classification}
                                                onChange={e => setDisplay({ ...display, classification: e.target.value })}
                                            // ref={register({ required: true, })}
                                            >
                                                <option value="">-- Select --</option>
                                                {/* Map the data's from classification array and used as a option value. By Dharani on 04-Mar-2021 */}
                                                {ships.classification.map((data: any, i: any) => {
                                                    return <option key={i} value={data.id}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                        </div>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Classification 2</Text>
                                            <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                                name='txt_shipEditClassificationId2'
                                                value={display.classification2}
                                                onChange={e => setDisplay({ ...display, classification2: e.target.value })}
                                            // ref={register({ required: true, })}
                                            >
                                                <option value="">-- Select --</option>
                                                {/* Map the data's from classification array and used as a option value. By Dharani on 04-Mar-2021 */}
                                                {ships.classification2.map((data: any, i: any) => {
                                                    return <option key={i} value={data.id}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                        </div>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Main Engine</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_mainEngine"
                                                value={display.mainEngine}
                                                onChange={e => setDisplay({ ...display, mainEngine: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='text'
                                                // min={0}
                                                // max={99999}
                                                placeholder="Main Engine"
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_mainEngine?.type === 'required' && 'Please enter Main Engine'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Aux Engine</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_auxEngine"
                                                value={display.auxEngine}
                                                onChange={e => setDisplay({ ...display, auxEngine: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='text'
                                                // min={0}
                                                // max={99999}
                                                placeholder="Aux Engine"
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_auxEngine?.type === 'required' && 'Please enter Aux Engine'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Pic Credit</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_picCredit"
                                                value={display.picCredit}
                                                onChange={e => setDisplay({ ...display, picCredit: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='text'
                                                // min={0}
                                                // max={99999}
                                                placeholder="Pic Credit"
                                            />
                                        </div>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Mmsi</Text>
                                            <Form.Control className='basecontrol custome-input'
                                                name="txt_mmsi"
                                                value={display.mmsi}
                                                onChange={e => setDisplay({ ...display, mmsi: e.target.value })}
                                                // ref={register({ required: true, })}
                                                type='text'
                                                // min={0}
                                                // max={99999}
                                                placeholder="Mmsi"
                                            />
                                        </div>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Source Data</Text>
                                            <Form.Control as="select"
                                                name="txt_sourceStatus"
                                                className='basecontrol cutome-selectInputnxt'
                                                value={display.sourceStatus}
                                                // ref={register({ required: true, })}
                                                onChange={(e) => setDisplay({ ...display, sourceStatus: e.target.value })}>
                                                <option value="">-- Select status --</option>
                                                <option value="BKI">BKI</option>
                                                <option value="DATALYSTICS">DATALASTIC</option>
                                            </Form.Control>

                                        </div>
                                        {display.sourceStatus !== 'BKI' ? (
                                            <>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Dry Date</Text>
                                                    {display.dryDockDate !== null ? (
                                                        <div><Text color='black' >{formatDate(display.dryDockDate)}</Text>
                                                            <input className="date-picker-css" type="date" id="dryDockDate" name="dryDockDate" value={display.dryDockDate}
                                                                // onChange={(e) => setDisplay({ ...display, dryDockDate: e.target.value })}
                                                                onChange={setDrydockDate}
                                                            >
                                                            </input>
                                                        </div>
                                                    ) : (
                                                        <div><Text color='black' >DD MM YYYY</Text>
                                                            <input className="date-picker-css" type="date" id="dryDockDate" name="dryDockDate" value={display.dryDockDate}
                                                                // onChange={(e) => setDisplay({ ...display, dryDockDate: e.target.value })}
                                                                onChange={setDrydockDate}
                                                            >
                                                            </input>
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Special Survey Date</Text>
                                                    {display.specialSurveyDate !== null ? (
                                                        <div><Text color='black' >{formatDate(display.specialSurveyDate)}</Text>
                                                            <input className="date-picker-css" type="date" id="specialSurveyDate" name="specialSurveyDate" value={display.specialSurveyDate}
                                                                // onChange={(e) => setDisplay({ ...display, specialSurveyDate: e.target.value })}
                                                                onChange={setSpecialDate}
                                                            >
                                                            </input>
                                                        </div>
                                                    ) : (
                                                        <div><Text color='black' >DD MM YYYY</Text>
                                                            <input className="date-picker-css" type="date" id="specialSurveyDate" name="specialSurveyDate" value={display.specialSurveyDate}
                                                                // onChange={(e) => setDisplay({ ...display, specialSurveyDate: e.target.value })}
                                                                onChange={setSpecialDate}
                                                            >
                                                            </input>
                                                        </div>

                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {/* <div>
                                                    <Text color='tablehead' variant='subtitle'>Special Survey Date</Text>
                                                    <input type="date" id="specialSurveyDate" name="specialSurveyDate" value={display.dryDockDate} data-date-format="DD MMMM YYYY"
                                                        onChange={(e) => setDisplay({ ...display, dryDockDate: e.target.value })}>
                                                    </input>
                                                </div> */}
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Last Docking Date</Text>
                                                    <div>
                                                        <Text color='black' >{formatDate(display.dryDockDate)}</Text>
                                                        <input className="date-picker-css" type="date" id="dryDockDate" name="dryDockDate" value={display.dryDockDate}
                                                            // onChange={(e) => setDisplay({ ...display, dryDockDate: e.target.value })}
                                                            onChange={setDrydockDate}
                                                        >
                                                        </input>
                                                    </div>
                                                </div>
                                                {/* <Button variant='transparent' block className='fileUpload singleFrame'>
                                                        <Flex>
                                                            <input type="date" id="dryDockDate" name="dryDockDate" className="new_input"
                                                            />
                                                            <Icons color="#ffffff" icon="publish" />
                                                            {formatDate(display.dryDockDate)}
                                                        </Flex>
                                                    </Button> */}
                                                {/* <div>
                                                    <Text color='black' variant='subtitle'>Last Docking Date</Text>
                                                    <input type="text" id="dryDockDate" name="dryDockDate" value={display.dryDockDate} placeholder="DD/MM/YYYY" onFocus={type='date'}  onBlur=(this.type='text')
                                                        onChange={(e) => setDisplay({ ...display, dryDockDate: e.target.value})} >
                                                    </input>
                                                </div> */}
                                            </>
                                        )}
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_auxEngine?.type === 'required' && 'Please enter Aux Engine'}
                                                </span>
                                            </Col>
                                        </Row>
                                        {showCheck == true ? (
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Reselling Status</Text>
                                                <Form.Check type='checkbox' id='chk_resell' name='chk_resell' value={display.sourceStatus}
                                                    // onChange={(e) => setDisplay({ ...display, resell: e.target.value })}
                                                    onChange={setReselling}
                                                    checked={(display.resell === 'true') ? true : false}
                                                    className='RadioButton'
                                                    label="Don't Resell">
                                                </Form.Check>
                                            </div>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </Attributes>
                                </Col>
                            </Row>

                            <Row className='m-0 p-0'>
                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes>
                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Status</Text>
                                            <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                                name='radio_shipEditStatus'
                                                value={display.status}
                                                onChange={e => setDisplay({ ...display, status: e.target.value })}
                                                ref={register({ required: true, })}>
                                                <option value="">-- Select --</option>
                                                <option value='VERIFIED'>Verified</option>
                                                <option value='UNVERIFIED'>Not Verified</option>
                                            </Form.Control>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.radio_shipEditStatus?.type === 'required' && 'Please select Ship status'}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Attributes>
                                </Col>
                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes>
                                        {/* <div>
                                        <Text color='tablehead' variant='subtitle'>Company</Text>
                                        <Form.Control className='basecontrol cutome-selectInputnxt' as='select'
                                             name="txt_shipEditCompany"
                                             value={display.company} 
                                             onChange={e => setDisplay({ ...display, company: e.target.value })}  
                                             ref={register({ required: true, })}>
                                            <option value=''>-- Select Company --</option>
                                            {ships.Company.map((data:any, i:any) => {
                                                console.log(data.id, 'company id')
                                                return <option key={i} value={data.id}>{data.name}</option>
                                            })}
                                        </Form.Control>
                                    </div> */}

                                        <div>
                                            <Text color='tablehead' variant='subtitle'>Company</Text>
                                            <input className='basecontrol cutome-selectInputnxt'
                                                name="txt_shipEditCompany"
                                                id='txt_shipEditCompany'
                                                list='shipCmpdatalist'
                                                value={display.company}
                                                placeholder='-- Select Company --'
                                                onChange={e => setDisplay({ ...display, company: e.target.value })}
                                            // ref={register({ required: true, })}
                                            >
                                            </input>
                                            <datalist id='shipCmpdatalist'>
                                                {ships.Company.map((data: any, i: any) => {
                                                    // console.log(data.id, 'company id')
                                                    return <option key={i} value={data.name}></option>
                                                })}
                                            </datalist>
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipEditCompany?.type === 'required' && 'Please enter Ship Company'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                    </Attributes>
                                </Col>
                            </Row>
                        </Card>
                        <Actions>
                            {/* If you click the back button its is redirect to ships list page. By Dharani on 04-Mar-2021 */}
                            <Button type="button" className='bttn bttn-transparent' onClick={() => _goback()}>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                            {/* If you click the save button its is call the function, in that data added to list and redirect to list page. By Dharani on 04-Mar-2021 */}
                            <Button className='bttn bttn-transparent' type="submit">
                                <Flex>
                                    <Icons color="#ffffff" icon="check" />
                                    SAVE DATA
                                </Flex>
                            </Button>
                        </Actions>
                    </Form>
                </Col>
                <Col xs='12' sm='12' md='12' lg='3' xl='3' className='p-0 m-0'>
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Card title="Ship Image" className='profile-title'>
                            {/* <div className='user-mainBlock'>
                                {display.shipImage === null && (
                                    <div className='userEdit-block'>
                                        <Image src={noimg} alt='logo' />
                                    </div>
                                )}
                                {display.shipImage === '' && (
                                    <div className='userEdit-block'>
                                        <Image src={noimg} alt='logo' />
                                    </div>
                                )}
                                {display.shipImage !== null && display.shipImage !== '' && (
                                    <div className='userEdit-block'>
                                        <Image src={display.shipImage} alt='logo' />
                                    </div>
                                )}
                            </div> */}
                            <div className='user-mainBlock'>
                                {display.shipImage === null && (
                                    <div className='userCompany-block'>
                                        <img src={noimg} alt='logo' />
                                    </div>
                                )}
                                {display.shipImage === '' && (
                                    <div className='userCompany-block'>
                                        <img src={noimg} alt='logo' />
                                    </div>
                                )}
                                {display.shipImage !== null && display.shipImage !== '' && length === 1 && (
                                    <div className='userCompany-block'>
                                        <img src={display.shipImage} alt='logo' />
                                    </div>
                                )}
                                {display.shipImage !== null && display.shipImage !== '' && length > 1 && (
                                    <Carousel slide={true} touch={true}>
                                        {example.map((value: any) => (
                                            <Carousel.Item interval={5000}>
                                                <div className='userCompany-block'>
                                                    <img
                                                        className="d-block w-100 slide"
                                                        src={value}
                                                        alt="First slide"
                                                    />
                                                </div>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                )}
                            </div>
                            {((display.shipImage === null) || (display.shipImage === '')) && (
                                <div className='imageProfilePic'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={editicon} alt='logo' />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                </div>
                            )}
                            {display.shipImage !== null && display.shipImage !== '' && (
                                <div className='imageEditBlock'>
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={editicon} alt='logo' className="editIconRemove" />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={removeicon} alt='logo' onClick={() => _removeShipLogo(display.id)} />
                                        </Flex>
                                    </label>
                                </div>
                            )}
                        </Card>
                    </div>
                </Col>
            </Row>
        </Main>
    )
}


export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-ship-add');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}

// Button style. 
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    ship: state.ship.selectedShip
})
//Return the selected ship details. 
export default connect(
    mapStateToProps,
    {
        shipSetSelectedAction
    }
)(ShipEdit)
