import React from 'react'
import classNames from 'classnames'
import './Sidebar.scss'
//Root Component
export interface RootProps{
    children ? : React.ReactNode
}

//Root Component

export const Root = ({children, ...props}:RootProps) => {
    const sidebar = classNames('sidebar-container');
    return(
        <div className={sidebar}>
            {children}
        </div>
    )

}

// SideLogo component

export interface LogoProps{
    children ? : React.ReactNode
}

export const SideLogo = ({children, ...props}:LogoProps) =>{
    return(
        <div>
            {children}
        </div>
    )
}

//MenuList Props

export interface MenulistProps{
    children ? : React.ReactNode
}

export const MenuList = ({children, ...props}:MenulistProps) => {
    return(
        <ul>
            {children}
        </ul>
    )
}

//MenuItem Props

export interface MenuItemProps{
    children ? : React.ReactNode
}

export const MenuItem = ({children, ...props}:MenuItemProps) => {
    return(
        <li>
            {children}
        </li>
    )
}

//ToggleProps

export interface ToggleProps{
    children ? : React.ReactNode
    onClick ?:  (e: React.MouseEvent) => void;
    className ? : string;
} 

export const Toggle = ({children,onClick,className, ...props}:ToggleProps) =>{

    const toggle = classNames('toggle',className);
    return(
        <div className= {toggle} onClick={onClick}>
            {children}
        </div>
    )

}
