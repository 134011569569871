import React, { useState } from 'react'
import { Text } from '../../../common/components/text/Text'
import { Main } from '../../../common/components/main/Main'
import { Grid } from '../../../common/components/grid/Grid'
import { Attributes } from '../../../common/components/attribute/Attribute'
import Card from '../../../common/components/card/Card'
import { JsxChild } from 'typescript'
import { Row, Form, Col, Button } from 'react-bootstrap'
// import { Input, Radio, Select, Textarea } from '../../../common/components/control/Control'
import '../../../common/components/control/Control.scss'
import '../../../common/components/button/Button.scss'
// import { Button } from '../../../common/components/button/Button'
import { Flex } from '../../../common/components/flex/Flex'
import Icons from '../../../common/components/icon/Icon'
import { Space } from '../../../common/components/space/Space'
import { connect } from 'react-redux'
import classNames from 'classnames'
import userService from '../../../services/userService'
import { useForm } from 'react-hook-form';
import './userList.scss'
import { history } from '../../../store';
import { useEffect } from 'react'
import swal from 'sweetalert'
import codeService from '../../../services/codeService'
import {
    userSetSelectedAction,
} from '../../../store/action/userActions'
import { Link } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import Select from 'react-select';


interface UserAddProps {
    addUserName: string;
    addUserPassword: string;
    addUserEmail: string;
    addUserPhone: string;
    addUserPhoneCode: string;
    addUserAddress: string;
    addUserRole: string;
    roles:[],
    addUserPosition: string;
    addUserExperience: string;
    addUserExpertise: string;
    addUserEducation: string;
    addUserStatus: string;
    addUserActiveStatus: string,
    addUserCompany: string;
    addUserCmpAddress: string;
    addUserCmpType: string;
}
interface FormData {
    txt_username: string;
    txt_userPassword: string;
    txt_useremail: string;
    txt_position: string;
    txt_phnUser: string;
    txt_phnCode: string;
    txt_education: string;
    txt_roles: string;
    txt_experience: string;
    txt_expertise: string;
    txt_useraddress: string;
    txt_usercmpaddress: string;
    txt_usercmpName: string;
    txt_userStatus: string;
    radio_Activestatus: string;
};

export const UserAdd = (props: any) => {

    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    var txt_usernameerr: any;
    var usererror: boolean = false;
    const [addForm, setForm] = useState<any>({
        name: '',
        email: '',
        username: '',
        password: '',
        roles: [],
        types: [],
        mbl_code: [],
        role: '',
        status: '',
        isSaving: false
    })
    const [isLoading, setLoading] = React.useState(false)
    const [userAddflag, setUserAddFlag] = React.useState(false)
    const [addUser, setAddUser] = useState<UserAddProps>({
        addUserName: '',
        addUserPassword: '',
        addUserEmail: '',
        addUserPhone: '',
        addUserPhoneCode: '',
        addUserAddress: '',
        addUserRole: '',
        roles:[],
        addUserPosition: '',
        addUserExperience: '',
        addUserExpertise: '',
        addUserEducation: '',
        addUserStatus: '',
        addUserActiveStatus: '',
        addUserCompany: '',
        addUserCmpAddress: '',
        addUserCmpType: ''
    })
    const _gotoAddPage = () => {
        // console.log(display.typeName, 'Type Name')
        var activeuser
        if (addUser.addUserActiveStatus === 'true') {
            activeuser = true
        }
        else {
            activeuser = false
        }
        var userRoleValue = selectedValue
        const userValueForm = []
        userValueForm.push({
            "name": addUser.addUserName,
            "password": addUser.addUserPassword,
            "email": addUser.addUserEmail,
            "phone": addUser.addUserPhone,
            "code": addUser.addUserPhoneCode,
            "address": addUser.addUserAddress,
            "roles": addUser.addUserRole,
            "position": addUser.addUserPosition,
            "experience": addUser.addUserExperience,
            "expertise": addUser.addUserExperience,
            "education": addUser.addUserEducation,
            "status": addUser.addUserStatus,
            "isactive": activeuser,
            "company_name": addUser.addUserCompany,
            "company_address": addUser.addUserCmpAddress,
        })
        console.log(userValueForm)
        localStorage.setItem('userAdd', JSON.stringify(userValueForm))
       
        history.push(`/roles/add/${'user'}`)

    }
    const _goBack = () => {
        // console.log(display.typeName, 'Type Name')
        localStorage.removeItem('userAdd')
        history.push(`/system-users`)

    }
    const get_Role = (() => {
        return codeService.getUserRoles('system').subscribe({
            next: r => {

                console.log(r.data, "Role")
                setForm({ ...addForm, email: r.username, roles: r.data })
                console.log(addForm.roles, 'Form Role')
                r.data.map((data: any, i: any) => {
                    return addForm.roles.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getCompanyTypeUser = (() => {
        return codeService.getCompanyTypedd().subscribe({
            next: r => {
                // console.log(r.data,'owners in Dock add page')
                setForm({ ...addForm, types: r.data })
                r.data.map((data: any, i: any) => {
                    return addForm.types.push(r.data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getMobileCode = (() => {
        return codeService.getMobileCode().subscribe({
            next: r => {
                // console.log(r.data, "Role")
                setForm({ ...addForm, mbl_code: r.data })
                // console.log(edituserForm.mbl_code, 'mbl_code')
                r.data.map((data: any, i: any) => {
                    return addForm.mbl_code.push(data)
                })
               
            },
            error: err => {
                console.error(err)
            }
        })
    })

    React.useEffect(() => {
        get_Role();
        _getCompanyTypeUser()
        getUserAdd()
        _getMobileCode()
    }, [])

    const getUserAdd = () => {
        var user: any;
         user =(localStorage.getItem('userAdd')) 
         console.log(JSON.stringify(user))
        //  var sam: any;
        //  sam = JSON.stringify(user)
         var sam2: any;
         sam2 = JSON.parse(user)
        
        if(sam2){
            var userRoles = ''
            var setActiveStatus;
            if (sam2[0].isactive === 'true' || true) {
                setActiveStatus = "true"
            }
            else {
                setActiveStatus = "false"
            }
            if (sam2[0].roles.length === 0 || sam2[0].roles === null) {
                userRoles = ''
            }
            else {

                userRoles = sam2[0].roles
                addUser.roles=sam2[0].roles
            }
            setAddUser({
                addUserName: sam2[0].name,
                addUserPassword: sam2[0].password,
                addUserEmail: sam2[0].email,
                addUserPhone: sam2[0].phone,
                addUserPhoneCode: sam2[0].code,
                addUserAddress: sam2[0].address,
                addUserRole: sam2[0].roles,
                roles:sam2[0].roles,
                addUserPosition: sam2[0].position,
                addUserExperience: sam2[0].experience,
                addUserExpertise: sam2[0].expertise,
                addUserEducation: sam2[0].educarion,
                addUserStatus: sam2[0].status,
                addUserActiveStatus: setActiveStatus,
                addUserCompany: sam2[0].company_name,
                addUserCmpAddress: sam2[0].company_address,
                addUserCmpType: ''
            })
            setSelectedValue(Array.isArray(userRoles) ? userRoles.map((x: any) => String(x)) : []);
        }
    }

    const [selectedValue, setSelectedValue] = useState<any>([]);

    // handle onChange event of the dropdown
    const handleChange = (e: any) => {
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
    }

    const data = addForm.roles.map((data: any, i: any) => {
        // console.log(data,'data within options')
        return { label: String(data.name), value: String(data.code) }
    });
    const _didUserAdd = () => {
        var activeuser
        var userAddPhone
        if (addUser.addUserActiveStatus === 'true') {
            activeuser = true
        }
        else {
            activeuser = false
        }
        if(addUser.addUserPhone === null){
            userAddPhone = ''
        }
        else{
            userAddPhone = addUser.addUserPhone
        }
        var userRoleValue = selectedValue
        const userAddForm = {
            name: addUser.addUserName,
            password: addUser.addUserPassword,
            email: addUser.addUserEmail,
            phone: (addUser.addUserPhoneCode == null) ? '' : String(addUser.addUserPhoneCode).concat(" ", addUser.addUserPhone),
            // phone:(addUser.addUserPhoneCode==null) ? '':( (addUser.addUserPhone === null)?'': String(addUser.addUserPhoneCode).concat(" ",userAddPhone)),
            address: addUser.addUserAddress,
            roles: [addUser.addUserRole],
            position: addUser.addUserPosition,
            experience: addUser.addUserExperience,
            expertise: addUser.addUserExperience,
            education: addUser.addUserEducation,
            status: addUser.addUserStatus,
            isactive: activeuser,
            company_name: addUser.addUserCompany,
            company_address: addUser.addUserCmpAddress,
            // addUserCmpType:addUser.addUserCmpType
        }

        // console.log('updated Form',userAddForm)
        console.log(errors, 'errores')
        if (userAddflag === true) {
            userService.addUser(userAddForm).subscribe({
                next: () => {
                    setLoading(true)
                    swal("Great", "User Added Successfully", "success")
                    // localStorage.remove('userValue')
                    history.push('/system-users')
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    console.error(err)
                    setLoading(false)
                }
            })
        }

    }
    onsubmit = () => {
        // e.preventDefault()
        if (Object.keys(errors).length === 0) {
            _didUserAdd()
            setUserAddFlag(true)
        }
        else {
            setUserAddFlag(false)
        }
    }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }
    return (
        <Main>
            <Text variant="title" color='black'>Add User Information</Text>
            <Space divheight={25} />
            <Grid templateCols='75% auto' className='add_page'>
                <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='userAddForm'>
                    <Card title="Fill All the Information Below" >
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>User Name</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_username"
                                            id='txt_username'
                                            value={addUser.addUserName}
                                            //required
                                            onChange={(e) => setAddUser({ ...addUser, addUserName: e.target.value })}
                                            placeholder='User Name'
                                            ref = {register({required:true})}
                                        />
                                       
                                    </div>
                                    <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_username?.type === 'required' && 'UserName is Required'}
                                        </span>
                                            </Col>
                                        </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>PassWord</Text>
                                        <Form.Control
                                            name="txt_userPassword"
                                            className='basecontrol custome-input'
                                            id='txt_userPassword'
                                            value={addUser.addUserPassword}
                                            placeholder='PassWord'
                                            ref={register({ required: true })}
                                            // required
                                            onChange={(e) => setAddUser({ ...addUser, addUserPassword: e.target.value })}
                                            type="password"
                                        />
                                       
                                    </div>
                                    <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_userPassword ?.type === 'required' && 'Password is Required'}
                                            </span>
                                            </Col>
                                        </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Email</Text>
                                        <Form.Control
                                            type="email"
                                            className='basecontrol custome-input'
                                            name="txt_useremail"
                                            id='txt_useremail'
                                            value={addUser.addUserEmail}
                                            onChange={(e) => setAddUser({ ...addUser, addUserEmail: e.target.value })}
                                            //required
                                            ref={register({ required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                            placeholder='Email ID'
                                        />
                                        
                                    </div>
                                    <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_useremail?.type === 'required' && 'Please Enter e-mail'}
                                                    {errors.txt_useremail?.type === 'pattern' && 'Please enter Valid e-mail'}</span>
                                            </Col>
                                        </Row>
                                    {/* <div>
                                        <Text color='tablehead' variant='subtitle'>Position</Text>
                                        <Form.Control
                                            name="txt_position"
                                            className='basecontrol custome-input'
                                            value={addUser.addUserPosition}
                                            placeholder='position'
                                            // required
                                            onChange={(e) => setAddUser({ ...addUser, addUserPosition: e.target.value })}
                                            type="text"
                                        />
                                    </div> */}



                                </Attributes>

                            </Col>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    {/* <div>
                                        <Text color='tablehead' variant='subtitle'>Experience</Text>
                                        <Form.Control
                                            name="txt_experience"
                                            className='basecontrol custome-input'
                                            id='txt_experience'
                                            value={addUser.addUserExperience}
                                            placeholder='Experience'
                                            // required
                                            onChange={(e) => setAddUser({ ...addUser, addUserExperience: e.target.value })}
                                            type="text"
                                        />
                                    </div> */}
                                    {/* <div>
                                        <Text color='tablehead' variant='subtitle'>Education</Text>
                                        <Form.Control
                                            className='basecontrol custome-input'
                                            name="txt_education"
                                            id='txt_education'
                                            value={addUser.addUserEducation}
                                            placeholder='Education'
                                            // required
                                            onChange={(e) => setAddUser({ ...addUser, addUserEducation: e.target.value })}
                                            type="text"
                                        />
                                    </div> */}
                                    {/* <div>
                                        <Text color='tablehead' variant='subtitle'>Expertise</Text>
                                        <Form.Control
                                            name="txt_expertise"
                                            className='basecontrol custome-input'
                                            id='txt_expertise'
                                            value={addUser.addUserExpertise}
                                            placeholder='Expertise'
                                            // required
                                            onChange={(e) => setAddUser({ ...addUser, addUserExpertise: e.target.value })}
                                            type="text"
                                        />
                                    </div> */}
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Phone</Text>
                                        <div className='phnnum-container'>
                                        <Form.Control as="select"
                                                    value={addUser.addUserPhoneCode}
                                                    // value={String(editUser.editUserMblCode).substring(0,String(editUser.editUserMblCode).indexOf(''))}
                                                    name='txt_phnCode'
                                                    className='basecontrol cutome-selectInputnxt phn-dp'
                                                    onChange={(e) => setAddUser({ ...addUser, addUserPhoneCode: e.target.value })}
                                                // ref={register({ required: true, })}
                                                >
                                                    <option value="">Code</option>
                                                    {addForm.mbl_code.map((data: any, i: any) => {
                                                        return <option key={i} value={data.mobile_code}>{data.country_name}</option>
                                                    })}
                                                </Form.Control>
                                        <Form.Control
                                            name="txt_phnUser"
                                            id='txt_phnUser'
                                            className='basecontrol custome-input'
                                            value={addUser.addUserPhone}
                                            placeholder='Phone'
                                            // required
                                            onChange={(e) => setAddUser({ ...addUser, addUserPhone: e.target.value })}
                                            type="number"
                                        />
                                        </div>
                                        
                                    </div>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>User Address</Text>
                                        <Form.Control as="textarea"
                                            className='basecontrol custome-textarea'
                                            placeholder='User Address'
                                            rows={3}
                                            cols={30}
                                            name="txt_useraddress"
                                            value={addUser.addUserAddress}
                                            onChange={(e) => setAddUser({ ...addUser, addUserAddress: e.target.value })}
                                        // required
                                        />
                                    </div>


                                    {/* <div>
                                        <Text color='tablehead' variant='subtitle'>Company Address</Text>
                                        <Form.Control as="textarea"
                                            className='basecontrol custome-textarea'
                                            placeholder='Company Address'
                                            rows={3}
                                            cols={30}
                                            name="txt_usercmpaddress"
                                            value={addUser.addUserCmpAddress}
                                            onChange={(e) => setAddUser({ ...addUser, addUserCmpAddress: e.target.value })}
                                        // required
                                        />
                                    </div> */}


                                </Attributes>

                            </Col>
                            <Col sm='12' className='m-0 p-0'>
                                <Row className='m-0 p-0'>
                                    <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Role</Text>
                                                <Form.Control as="select" value={addUser.addUserRole} name='txt_roles'
                                            className='basecontrol cutome-selectInputnxt'
                                            onChange={(e) => setAddUser({ ...addUser, addUserRole: e.target.value })}
                                        //  required
                                        >
                                            <option value="">-- Select --</option>
                                            {addForm.roles.map((data: any, i: any) => {
                                                return <option key={i} value={data.code}>{data.name}</option>
                                            })}
                                        </Form.Control>
                                                {/* <div>
                                                    <Select
                                                        className="dropdown"
                                                        placeholder="Select Roles"
                                                        // value={selectedValue}
                                                        value={data.filter((obj: any) => selectedValue.includes(obj.value) || selectedValue.includes('All'))} // set selected values
                                                        options={data}
                                                        // ref={register({ required: true })}
                                                        // options={selectedValue.length === maxOptions ? [] : [ ...data]}    noOptionsMessage={() => {
                                                        //     return selectedValue.length === maxOptions
                                                        //         ? 'You have to Select only 5 Ship Types'
                                                        //         : 'No options available';
                                                        // }}
                                                        onChange={handleChange} // assign onChange function
                                                        isMulti
                                                        isClearable
                                                        isSearchable
                                                    />
                                                </div> */}
                                            </div>

                                        </Attributes>
                                    </Col>
                                    <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes>

                                            <>
                                                <Button type="button" className='bttn bttn-transparent'
                                                    onClick={() => _gotoAddPage()}
                                                >
                                                    <Flex>
                                                        <Icons color="#ffffff" icon="add" />
                                                        ADD HERE
                                                                        </Flex>
                                                </Button>
                                                <Text className='goto_text' center variant='caption'>If, there is no Role</Text>

                                            </>
                                        </Attributes>
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm='12' className='m-0 p-0'>
                                <Row className='m-0 p-0'>
                                    <Col xs='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                                        <Attributes>
                                            {/* <div>
                                            <Text color='tablehead' variant='subtitle'>Company Type</Text>
                                            <Form.Control as="select" value={addUser.addUserCmpType} name='cmpType' 
                                                onChange={e => setAddUser({ ...addUser, addUserCmpType: e.target.value })}>
                                                <option value="">-- Select --</option>
                                                {form.types.map((data:any, i:number) => {
                                                return <option key={i} value={data.id}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                            </div> */}
                                            {/* <div>
                                                <Text color='tablehead' variant='subtitle'>Company Name</Text>
                                                <Form.Control
                                                    name="txt_usercmpName"
                                                    id='txt_usercmpName'
                                                    className='basecontrol custome-input'
                                                    value={addUser.addUserCompany}
                                                    placeholder='Company Name'
                                                    // required
                                                    onChange={(e) => setAddUser({ ...addUser, addUserCompany: e.target.value })}
                                                    type="text"
                                                />
                                            </div> */}
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Status</Text>
                                                <Form.Control as="select"
                                                    name="txt_userStatus"
                                                    className='basecontrol cutome-selectInputnxt'
                                                    value={addUser.addUserStatus}
                                                    // required
                                                     ref={register({ required: true })}
                                                    onChange={(e) => setAddUser({ ...addUser, addUserStatus: e.target.value })}
                                                >
                                                    <option value="">-- Select status --</option>
                                                    <option value="VERIFIED">Verified</option>
                                                    <option value="UNVERIFIED">Not verified</option>
                                                </Form.Control>
                                            </div>
                                            <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_userStatus ?.type === 'required' && 'Please Select Status'}
                                            </span>
                                            </Col>
                                        </Row>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>System Access</Text>
                                                <div>
                                                    <Form.Check type='radio' id='active' name='radio_Activestatus' value={addUser.addUserActiveStatus}
                                                        className='RadioButton'
                                                        onChange={(e) => setAddUser({ ...addUser, addUserActiveStatus: 'true' })}
                                                        checked={addUser.addUserActiveStatus === 'true'}
                                                        label='ACTIVE'>
                                                    </Form.Check>
                                                    <Form.Check type='radio' id='inactive' name='radio_Activestatus' value={addUser.addUserActiveStatus}
                                                        className='RadioButton'
                                                        onChange={(e) => setAddUser({ ...addUser, addUserActiveStatus: 'false' })}
                                                        checked={addUser.addUserActiveStatus === 'false'}
                                                        label='IN ACTIVE'>
                                                    </Form.Check>
                                                </div>
                                            </div>
                                        </Attributes>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Actions>
                        {/* <Link to="/users"> */}
                        <Button type="button" className='bttn bttn-transparent'
                            onClick={() => _goBack()}
                        >
                            <Flex jc='jc-around'>
                                <Icons icon="arrow_back" />
                                BACK
                            </Flex>
                        </Button>
                        {/* </Link> */}
                        <Button className='bttn bttn-transparent' name='btnAddUser' id='btnAddUser' type="submit">
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
        </Main>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}
