import React, { useEffect } from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Button, Row } from 'react-bootstrap'
// import { Button } from '../../../../common/components/button/Button'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import swal from 'sweetalert';
import anodeService from '../../../../services/anodeService'
import '../../../../styles/variables/Common.scss'
import { useForm } from 'react-hook-form'
import codeService from '../../../../services/codeService'
import Loader from 'react-loader-spinner'


interface AnodeProps {
  name: string,
  anode_weight: string | number,
  sea_chest_anode_weight: string | number,
  price_kg: string | number,
  electrochemical: string | number,
  supplier: string,
  company: string,
  companies: any[],
  suppliers: any[],
}
interface FormData {
  txt_anodename: string,
  txt_anode_weight: string,
  txt_sea_chest_anode_weight: string,
  txt_price_kg: string,
  txt_electrochemical: string,
  txt_supplier: string,
  txt_company: string,
}
export const AnodeAdd = (props: any) => {
  const { register, handleSubmit, errors } = useForm<FormData>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
  }
  );
  const [loading, setLoading] = useState(false);
  const [anodeaddflag, setAnodeAddFlag] = React.useState(false)
  const [anode, setAnode] = useState<AnodeProps>({
    name: '',
    anode_weight: '',
    sea_chest_anode_weight: '',
    price_kg: '',
    electrochemical: '',
    supplier: '',
    company: '',
    companies: [],
    suppliers: [],
  },
  )
  const _getSupplierDetail = () => {
    return codeService.getSupplierDD().subscribe({
      next: r => {

        setAnode({ ...anode, suppliers: r.data })
        r.data.map((data: any, i: any) => {
          return anode.suppliers.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  }
  const _getCompanyDetail = (value: any) => {
    // setAnode({...anode, supplier:value})
    var supplier_id: any = ''
    {
      anode.suppliers.map((data, i) => {
        if (value === data.name) {
          supplier_id = data.id
          // console.log(city_Code,'city code')
          codeService.getSupplierCompanyDD(supplier_id).subscribe({
            next: r => {
              // console.log(r.data)
              setAnode({ ...anode, companies: r.data, supplier: value })
              r.data.map((data: any, i: any) => {
                return anode.companies.push(data)
              })
            },
            error: err => {
              console.error(err)
            }
          })
        }
        else {
          setAnode({ ...anode, supplier: value, company: '', companies: [] })
        }
      })
    }
  }
  useEffect(() => {
    _getSupplierDetail();
  }, [])

  // After enter all details by user and click submit button values assign to some variable then it is used as input parameter for addanode api call. 
  const _didAnodeAdd = () => {
    // e.preventDefault()
    var supId: string = ''
    var cmp_id
    anode.companies.map((data, i) => {
      if (anode.company === data.name) {
        cmp_id = data.id;
      }
    })
    anode.suppliers.map((data: any, i: any) => {
      if (anode.supplier === data.name) {
        supId = data.id;
      }
    })
    const updatedForm = {
      anode_id: 0,
      product_name: anode.name,
      anode_weight: Number(anode.anode_weight),
      sea_chest_anode_weight: Number(anode.sea_chest_anode_weight),
      price_kg: Number(anode.price_kg),
      electro_chemical: Number(anode.electrochemical),
      supplier_id: Number(supId),
      company_id: Number(cmp_id),
    }

    // API call for addanode with data, added to list. 
    // console.log(updatedForm,"updatedform")

    if (anodeaddflag === true) {
      setLoading(true)
      anodeService.addAnode(updatedForm).subscribe({
        next: (r) => {
          // console.log(r,'response')
          swal("Great", "Anode Added Successfully", "success")
          history.push('/anode/list')
          setLoading(false)
        },
        error: err => {
          swal("OOPS", String(err), "error")
          setLoading(false)
        }
      })
    }
    else {
      console.log(anodeaddflag, 'flag')
    }

  }

  onsubmit = (e: any) => {
    // e.preventDefault()
    console.log(Object.keys(errors).length, 'error length')
    if (Object.keys(errors).length === 0) {
      _didAnodeAdd()
      setAnodeAddFlag(true)
    }
    else {
      setAnodeAddFlag(false)
    }
  }

  if (loading) {
    return <Text center>
      <Loader
        type="Rings"
        color="#233269"
        height={100}
        width={100}
        timeout={30000}
      />
    </Text>
  }

  return (
    <Main>
      <Text variant="title" color='black'>Add Anode Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='anodeAddForms'>
          <Card title="Fill All the Information Below">
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Anode Name</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_anodename"
                      value={anode.name}
                      onChange={(e) => setAnode({ ...anode, name: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Anode Name"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_anodename?.type === 'required' && 'Please enter Anode Name'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle' >Anode weight (Kg)</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_anode_weight"
                      value={anode.anode_weight}
                      onChange={(e) => setAnode({ ...anode, anode_weight: e.target.value })}
                      ref={register({ required: true, min: 0 })}
                      placeholder='Anode Weight'
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>
                        {errors.txt_anode_weight?.type === 'required' && 'Please enter Anode Weight'}
                        {errors.txt_anode_weight?.type === 'min' && 'Anode Weight should not be in Negative'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle' >Sea chest's Anode Weight (Kg)</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_sea_chest_anode_weight"
                      value={anode.sea_chest_anode_weight}
                      onChange={(e) => setAnode({ ...anode, sea_chest_anode_weight: e.target.value })}
                      ref={register({ required: true, min: 0 })}
                      placeholder='Sea chest`s Anode Weight'
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>
                        {errors.txt_sea_chest_anode_weight?.type === 'required' && 'Please enter Sea chest`s Anode Weight'}
                        {errors.txt_sea_chest_anode_weight?.type === 'min' && 'Sea chest`s Anode Weight should not be in Negative'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes >
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Price per Kg (Rp.)</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_price_kg"
                      value={anode.price_kg}
                      onChange={(e) => setAnode({ ...anode, price_kg: e.target.value })}
                      ref={register({ required: true, min: 0 })}
                      placeholder='Price per Kg'
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>
                        {errors.txt_price_kg?.type === 'required' && 'Please enter Price'}
                        {errors.txt_price_kg?.type === 'min' && 'Price should not be in Negative'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle' >Electrochemical Efficiency</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_electrochemical"
                      value={anode.electrochemical}
                      onChange={(e) => setAnode({ ...anode, electrochemical: e.target.value })}
                      ref={register({ required: true, min: 0 })}
                      placeholder='Electrochemical'
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>
                        {errors.txt_electrochemical?.type === 'required' && 'Please enter Electrochemical'}
                        {errors.txt_electrochemical?.type === 'min' && 'Electrochemical should not be in Negative'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
            </Row>
            <Row className='m-0 p-0'>
              <Col className='m-0 p-0' xs='12' md='12' lg='7' xl='7'>
                <Attributes>
                  <div>
                    <Text color='tablehead' variant='subtitle' >Supporting Owner</Text>
                    <input className='basecontrol cutome-selectInputnxt'
                      value={anode.supplier} id="txt_supplier"
                      list='anodesupplier'
                      placeholder='-- Select Owner --'
                      name="txt_supplier" ref={register({ required: true, })}
                      onChange={(e) => _getCompanyDetail(e.target.value)}>
                    </input>
                    <datalist id="anodesupplier">
                      {anode.suppliers.map((data, i) => {
                        return <option key={i} value={data.name}></option>
                      })}
                    </datalist>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_supplier?.type === 'required' && 'Please Select Owner'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle' >Company Name</Text>
                    <input className='basecontrol cutome-selectInputnxt'
                      ref={register({ required: true, })}
                      value={anode.company}
                      id='txt_company' list='company-details'
                      placeholder='-- Select Owner First --'
                      name="txt_company" onChange={(e) => setAnode({ ...anode, company: e.target.value })} >
                      {/* <option value=''> --Select port city first--</option> */}
                    </input>
                    <datalist id="company-details">
                      {anode.companies.length === 0 && (
                        <option className='option-placeholder'> --NO Company for selected Owner-- </option>
                      )}
                      {anode.companies.map((data: any, i: any) => {
                        return <option key={i} value={data.name}></option>
                      })}
                    </datalist>
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_company?.type === 'required' && 'Please select Company'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
            </Row>
          </Card>
          <Actions>
            {/* If you click the back button its is redirect to anodes list page.  */}
            <Link to='/anode/list'>
              <Button className='bttn bttn-transparent'>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page.  */}
            <Button className='bttn bttn-transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </Form>
      </Grid>
      {/* Add form, having input box, select box.  */}
    </Main >
  )
}


export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-anode-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. 
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}
