import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import swal from 'sweetalert';
import thicknessService from '../../../../services/thicknessService'
import '../../../../styles/variables/Common.scss'
import { useForm } from 'react-hook-form'


interface ThicknessProps {
  side: string;
  typeOfCoating: string;
  coat1: string;
  coat2: string;
  Coat3: string;
  coat4: string;
  coat5: string;
}
interface FormData {
  txt_side: string;
  txt_typeOfCoating: string;
  txt_coat1: string;
  txt_coat2: string;
  txt_Coat3: string;
  txt_coat4: string;
  txt_coat5: string;
}
export const ThicknessAdd = (props: ThicknessProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const [thicknessaddflag, setthicknessAddFlag] = React.useState(false)
  const [thickness, setThickness] = useState<ThicknessProps>({
    side: '',
    typeOfCoating: '',
    coat1: '',
    coat2: '',
    Coat3: '',
    coat4: '',
    coat5: '',
  },
  )

  // After enter all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call. 
  const _didThicknessAdd = () => {
    // e.preventDefault()
    const updatedForm = {
      side: thickness.side,
      type_of_coating: thickness.typeOfCoating,
      coat_1: thickness.coat1,
      coat_2: thickness.coat2,
      coat_3: thickness.Coat3,
      coat_4: thickness.coat4,
      coat_5: thickness.coat5,
    }

    // API call for addship with data, added to list. 
    // console.log(updatedForm,"updatedform")
    if (thicknessaddflag === true) {
      thicknessService.addThickness(updatedForm).subscribe({
        next: (r) => {
          // console.log(r,'response')
          swal("Great", "Thickness Updated Successfully", "success")
          history.push('/thickness/list')
        },
        error: err => {
          swal("OOPS", String(err), "error")
        }
      })
    }
    else {
      console.log(thicknessaddflag)
    }
  }

  onsubmit = (e: any) => {
    // e.preventDefault()
    console.log(Object.keys(errors).length, 'error length')
    if (Object.keys(errors).length === 0) {
      _didThicknessAdd()
      setthicknessAddFlag(true)
    }
    else {
      setthicknessAddFlag(false)
    }
  }

  return (
    <Main>
      <Text variant="title" color='black'>Add Thickness Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='thicknessAddForms'>
          <Card title="Fill All the Information Below">
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes>
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >side of Ship</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_side"
                      value={thickness.side}
                      onChange={(e) => setThickness({ ...thickness, side: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="side of Ship"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_side?.type === 'required' && 'Please enter Side'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Coating Type</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="text"
                      name="txt_typeOfCoating"
                      value={thickness.typeOfCoating}
                      onChange={(e) => setThickness({ ...thickness, typeOfCoating: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder="Coating Type"
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_typeOfCoating?.type === 'required' && 'Please enter Type'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>First Coat</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_coat1"
                      value={thickness.coat1}
                      onChange={(e) => setThickness({ ...thickness, coat1: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder='First Coat'
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_coat1?.type === 'required' && 'Please enter 1`st Coat'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Second Coat</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_coat2"
                      value={thickness.coat2}
                      onChange={(e) => setThickness({ ...thickness, coat2: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder='Second coat'
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_coat2?.type === 'required' && 'Please enter Coat2'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
              <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                <Attributes >
                  <div className='sm-mt-3'>
                    <Text color='tablehead' variant='subtitle' >Third Coat</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_Coat3"
                      value={thickness.Coat3}
                      onChange={(e) => setThickness({ ...thickness, Coat3: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder='Third Coat'
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_Coat3?.type === 'required' && 'Please enter Third Coat'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Fourth Coat</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_coat4"
                      value={thickness.coat4}
                      onChange={(e) => setThickness({ ...thickness, coat4: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder='Fourth Coat'
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_coat4?.type === 'required' && 'Please enter Coat4'}
                      </span>
                    </Col>
                  </Row>
                  <div>
                    <Text color='tablehead' variant='subtitle'>Fifth Coat</Text>
                    <Form.Control className='basecontrol custome-input'
                      type="number"
                      name="txt_coat5"
                      value={thickness.coat5}
                      onChange={(e) => setThickness({ ...thickness, coat5: e.target.value })}
                      ref={register({ required: true, })}
                      placeholder='Fifth Coat'
                    />
                  </div>
                  <Row className='m-0 p-0'>
                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                    </Col>
                    <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                      <span className='err-msg text-capitalize'>{errors.txt_coat5?.type === 'required' && 'Please enter Fifth coat'}
                      </span>
                    </Col>
                  </Row>
                </Attributes>
              </Col>
            </Row>
          </Card>
          <Actions>
            {/* If you click the back button its is redirect to ships list page.  */}
            <Link to='/thickness/list'>
              <Button className='bttn bttn-transparent'>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page.  */}
            <Button className='bttn bttn-transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </Form>
      </Grid>
      {/* Add form, having input box, select box.  */}
    </Main>
  )
}


export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-ship-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. 
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}
