import {
    DATA_SOCIAL_REPORT_LIST_GET,
    DATA_SOCIAL_REPORT_LIST_GET_F,
    DATA_SOCIAL_REPORT_LIST_GET_R,
    DATA_SOCIAL_REPORT_SELECTED_SET
}  from '../actionType'

const initialState = {
    reports: [],
    isLoading: false,
    error: null,
    selectedReports: null,
    totalPage: 0,
    totalElement: 0,
}

export default function PortReducer (state = initialState, action:any)  {
    switch(action.type) {
        case DATA_SOCIAL_REPORT_LIST_GET:
            console.log('selected report called')
            console.log('selected called', state)
            return {
                ...state,
                isLoading: true,
                error: null
            } 
        case DATA_SOCIAL_REPORT_LIST_GET_F:
            return {
                ...state,
                isLoading: false,
                reports: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: action.payload.total_element
            }
        case DATA_SOCIAL_REPORT_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case DATA_SOCIAL_REPORT_SELECTED_SET:
            return {
                ...state,
                selectedReports: action.payload
            }
        default:
            return state
    }
}