import * as Observable from 'rxjs'
import { map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getRole = (params:object) => {
    // console.log('get role called')
    // console.log(params ,'param')
    return http({
    method: 'GET',
    url: '/roles/search',
    params
    }).pipe(
    map(res => {
        if (res.success) {
        // console.log(res, 'res')
        return res
        } else {
        // console.log(res ,'res')
        throw new Error(res.msg)
        }
    })
    )
}

const addRole =( body : object) => {
    return http({
    method: 'POST',
    url: '/roles',
    body
    }).pipe(
    map(res => {
        if (res.success) {
        return res
        } else {
        throw new Error(res.msg)
        }
    })
    )
}

const updateRole =(body:object) => {
    return http({
    method: 'PUT',
    url: `/roles`,
    body
    }).pipe(
    map(res => {
        if (res.success) {
        // console.log('update user response', res)
        return res
        } else {
        throw new Error(res.msg)
        }
    })
    )
}

const deleteRoles = (id : any) => {
    return httpDelete({
      method: 'DELETE',
      url: `/roles/${id}`,
    }).pipe(
      map(res => {
        // console.log(res,'delete response')
        if (res) {
          return res
        } else {
          // console.log(res,'error message')
          throw new Error(res)
        }
      })
    )
  }

  const viewRoles = (id : any) => {
    return http({
      method: 'GET',
      url: `/roles/view/${id}`,
    }).pipe(
      map(res => {
        // console.log(res,'delete response')
        if (res) {
          return res
        } else {
          // console.log(res,'error message')
          throw new Error(res)
        }
      })
    )
  }

const getRoleDetail = (id:any) => {
    // TODO: change with real http req
    //return Observable.of(mockData).pipe(map(res => res.find(d => d.id === id)))
}
const RoleService = {
    addRole,
    getRole,
    getRoleDetail,
    deleteRoles,
    updateRole,
    viewRoles
}

export default RoleService