import React from 'react';
import { Col, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap';
import './Ship.scss';
import '../../../common/components/pagination/Pagination.scss'
import { Text } from '../../../common/components/text/Text'
import { Space } from '../../../common/components/space/Space';
import { Main } from '../../../common/components/main/Main';
import { Button } from '../../../common/components/button/Button';
import Card from '../../../common/components/card/Card';
import { Table } from '../../../common/components/table/Table';
import Icons from '../../../common/components/icon/Icon';
import { format, parseISO } from 'date-fns'
import { shipListGetAction, shipSetSelectedAction } from "../../../store/action/shipActions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { history } from '../../../store';
import { Flex } from '../../../common/components/flex/Flex';
import { ReactNode } from 'react'
import swal from 'sweetalert';
import shipService from '../../../services/shipService'
import { InputWithIcon } from '../../../common/components/control/Control';
import { ShipFilterState } from '../filter/ShipFilterState'
import ShipFilter from './pageContainer/ShipFilter'
import Loader from 'react-loader-spinner';
import Filterimg from '../../../assets/images/Filter 2.png'
import { Modal } from "react-bootstrap";
import sortup from '../../../assets/images/sortup_icon.png'
import sortdown from '../../../assets/images/sortdown_icon.png'
import sortimage from '../../../assets/images/sorting.png'


const ShipList = (props: any) => {
  const { ships, isLoading, totalElement } = props
  const [displayShips, setShips] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [searchQuery, setQuery] = React.useState('')
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const perPage = 9
  const { match } = props
  const { fromview } = match.params
  const [showModal, setModal] = React.useState(false)
  const [searchInput, setSearchInput] = React.useState(ShipFilterState.shipFilter.serachBoxInputShip)
  const [showDelete, setShow] = React.useState(false);
  var [deleteShip, setDeleteShip] = React.useState(0)
  var totalPage = Math.ceil(totalElement / 10)
  const [sort, setSorting]=React.useState({values:ShipFilterState.shipFilter.type,direction:ShipFilterState.shipFilter.direction})
  

  var { pgnum } = match.params

  // Used to get type and payload, call action and get list.  
  React.useEffect(() => {
    if (pgnum) {
      setPage(Number(pgnum))
      history.push('/ships/list')
      _goToPage(Number(pgnum))}
    if(localStorage.getItem("searchInput")!=null||localStorage.getItem("searchInput")!='')
    {
      const user = localStorage.getItem("searchInput")!=null ? localStorage.getItem("searchInput") : '';
      ShipFilterState.shipFilter.serachBoxInputShip=user!=null?user:'';
      setSearchInput(user != null ? user : '')
    props.shipListGetAction({
      size: 10, page,
      companyName: user,
      shipName: ShipFilterState.shipFilter.shipName,
      countryName: String(ShipFilterState.shipFilter.shipcountryName),
      status: ShipFilterState.shipFilter.shipstatus,
      type:ShipFilterState.shipFilter.type,
      direction:ShipFilterState.shipFilter.direction
    })
    }else{
      props.shipListGetAction({
        size: 10, page,
        companyName: ShipFilterState.shipFilter.companyName || searchInput || ShipFilterState.shipFilter.serachBoxInputShip,
        shipName: ShipFilterState.shipFilter.shipName,
        countryName: String(ShipFilterState.shipFilter.shipcountryName),
        status: ShipFilterState.shipFilter.shipstatus,
        type:ShipFilterState.shipFilter.type,
        direction:ShipFilterState.shipFilter.direction      
      })
    }
  }, [page, searchQuery])

  // Check ships(list) length, add checked false in that array. 
  React.useEffect(() => {
    if (ships && ships.length > 0) {
      setShips(() => {
        return ships.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (ships && ships.length === 0) {
      setShips([])
    }
  }, [ships])

  // Get totalPage in props and push into the pagination variable.  
  React.useEffect(() => {
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  //filter box
  const _willShipFilter = () => {
    setModal(true)
  }

  const _willCloseModal = () => {
    setModal(false)
  }

  const role = localStorage.getItem("role")

  //search function
  const _shipSearchFuntion = (value: string) => {
    setSearchInput(value)
    localStorage.setItem("searchInput",value)
    ShipFilterState.shipFilter.serachBoxInputShip=value
    props.shipListGetAction({
      size: 10, page: 0, companyName: value,
      countryName: String(ShipFilterState.shipFilter.shipcountryName),
      shipName: ShipFilterState.shipFilter.shipName,
      status: ShipFilterState.shipFilter.shipstatus,
      type:ShipFilterState.shipFilter.type,
      direction:ShipFilterState.shipFilter.direction
    })
    setPage(0)
  }

  const _showAllShip = () => {
    props.shipListGetAction({ size: 10, page: 0 })
    ShipFilterState.shipFilter.companyName = ''
    ShipFilterState.shipFilter.shipcountryName = ''
    ShipFilterState.shipFilter.shipstatus = ''
    ShipFilterState.shipFilter.shipName = ''
    ShipFilterState.shipFilter.shipcountryNames = ''
    ShipFilterState.shipFilter.page = 0
    ShipFilterState.shipFilter.size = 10
    ShipFilterState.shipFilter.serachBoxInputShip=''
    setSearchInput('')
    localStorage.removeItem("searchInput")
    setSorting({values:'',direction:''})
  }

  // Redirect to ship detail page. 
  const _willViewDetail = (ship: any, pgnum: number) => {
    shipSetSelectedAction(ship)
    history.push(`/ships/${ship.ship_id}/${pgnum}`)
  }

  // Redirect to ship edit page. 
  const _willViewEdit = (ship: any, pgnum: number) => {
    props.shipSetSelectedAction(ship)
    history.push(`/ships/edit/${ship.ship_id}/${pgnum}`)
  }

  // Delete the pericular list.  

  const handleClose = () => setShow(false);
  const handleShow = (ship: any) => {
    setShow(true);
    setDeleteShip(ship.ship_id);
    // console.log(deleteShip,'deleted ship id')
  }

  const _willDelete = () => {
    // console.log(deleteShip,'deleted ship id inside will delete function')
    _deleteShipType(deleteShip)
  }

  const _deleteShipType = (id: any) => {
    return shipService.deleteShip(id).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false);
        swal("Great", " Deleted Successfully", "success")
        props.shipListGetAction({ size: 10, page, q: searchQuery })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }


  // Prints first page pagination. 
  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  // While click the last page its move to last page. 
  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 9)
  }

  // While click the first button its move to first page. 
  const _goToFirstPage = () => {
    setPage(0)
    setHigh(9)
    setLow(1)
  }

  // Prints last page pagination button. 
  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  // Prints prev button. 
  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  // While click prev button go to the previous page of current page. 
  const _goPrevPage = () => {
    setPage(prev => {
      const newValue = prev - 1
      return newValue
    })
  }

  // Print next button. 
  const nextBtn = () => {
    if (page < totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  // While click next page button go to the next page of the current page. 
  const _goNextPage = () => {
    setPage(prev => {
      const newValue = prev + 1
      pgnum=newValue
      return newValue
    })
  }

  // While click one page number its move to that page. 
  const _goToPage = (page: any) => {
    pgnum=page
    setPage(page)
  }

  // Return the page number
  const ListItem = (page: any) => {
    return page.value + 1
  }

  // Prints the totalpages between prev, next button. 
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })

  const sortByFieldn=(value:String,directions:String)=>{
    if(directions==='asc')
    {
      setSorting({values:String(value),direction:String(directions)});
      ShipFilterState.shipFilter.type=String(value);
      ShipFilterState.shipFilter.direction=String(directions);
      props.shipListGetAction({
        size: 10, page: 0, companyName: ShipFilterState.shipFilter.serachBoxInputShip,
        countryName: String(ShipFilterState.shipFilter.shipcountryName),
        shipName: ShipFilterState.shipFilter.shipName,
        status: ShipFilterState.shipFilter.shipstatus,
        type:value,
        direction:directions
      })
      setPage(0)
    }
    else if(directions==='desc')
    {
      setSorting({values:String(value),direction:String(directions)});
      ShipFilterState.shipFilter.type=String(value);
      ShipFilterState.shipFilter.direction=String(directions);
      props.shipListGetAction({
        size: 10, page: 0, companyName: ShipFilterState.shipFilter.serachBoxInputShip,
        countryName: String(ShipFilterState.shipFilter.shipcountryName),
        shipName: ShipFilterState.shipFilter.shipName,
        status: ShipFilterState.shipFilter.shipstatus,
        type:value,
        direction:directions
      })
      setPage(0)

    }
    else if(directions==='')
    {
      setSorting({values:String(value),direction:String(directions)});
      ShipFilterState.shipFilter.type=String(value);
      ShipFilterState.shipFilter.direction=String(directions);
      props.shipListGetAction({
        size: 10, page: 0, companyName: ShipFilterState.shipFilter.serachBoxInputShip,
        countryName: String(ShipFilterState.shipFilter.shipcountryName),
        shipName: ShipFilterState.shipFilter.shipName,
        status: ShipFilterState.shipFilter.shipstatus,
        type:value,
        direction:directions
      })
      setPage(0)

    }
    };

  return (
    <Main>
      <Text variant="title" color='black'>Ship List</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='5' xl='5' className='m-0 p-0'>
              <div className='mr-3 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    <InputWithIcon
                      type="text"
                      value={searchInput}
                      onChange={(e) => _shipSearchFuntion(e.target.value)}
                      placeholder="IMO Number / Ship Name / Company Name.."
                      icon="search"
                      id="search-input"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='7' xl='7' className='m-0 p-0'>
              <div className='search-btn-container'>
                <div className='search-btn-add'>
                  {role != 'VISITOR' ? (
                    <Link to='/ships/add'>   {/* Button add new ship, if you click the button it shows modal box.  */}
                      <Button variant='transparent' block={true}>
                        <Icons color="#ffffff" icon="add" />
                        ADD NEW SHIP
                      </Button>
                    </Link>
                  ) : (
//                    <Link to='/ships/add'>   {/* Button add new ship, if you click the button it shows modal box.  */}
                      <Button variant='transparent' block={true} disabled>
                        <Icons color="#ffffff" icon="add" />
                        ADD NEW SHIP
                      </Button>
//                    </Link>
                  )}
                </div>
                <div className='search-btn-filter'>
                  <Button className='link_button filter mr-1' onClick={() => _willShipFilter()}>
                    <img src={Filterimg} alt='filterimg' />
                  </Button>
                </div>
                <div className='search-btn-showall'>
                  <Button variant='transparent' block={true} onClick={() => _showAllShip()}>
                    Show All
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </SearchForm>
        <Space divheight={30} />
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>} {/* Check the list is loading, isloading is true its shows loading text. */}
        {!isLoading && displayShips.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No ship found</Text>
            <Space divheight={20} />
          </>
        )} {/* If isloading is false and also the list array length is 0 its shows no discount fount text.  */}
        {!isLoading && displayShips.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Created Date</th>
                  <th>IMO Number
                    &nbsp;
                    {sort.values==='imonumber' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('imonumber','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='imonumber' ? (
                     <img onClick={() => sortByFieldn('imonumber','asc')} src={sortimage}/>):(null)}
                     {sort.values==='imonumber' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Ship Name
                  &nbsp;
                    {sort.values==='shipname' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('shipname','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='shipname' ? (
                     <img onClick={() => sortByFieldn('shipname','asc')} src={sortimage}/>):(null)}
                     {sort.values==='shipname' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Company
                  &nbsp;
                    {sort.values==='companyname' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('companyname','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='companyname' ? (
                     <img onClick={() => sortByFieldn('companyname','asc')} src={sortimage}/>):(null)}
                     {sort.values==='companyname' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Country
                  &nbsp;
                    {sort.values==='countryname' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('countryname','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='countryname' ? (
                     <img onClick={() => sortByFieldn('countryname','asc')} src={sortimage}/>):(null)}
                     {sort.values==='countryname' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Status
                  &nbsp;
                    {sort.values==='status' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('status','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='status' ? (
                     <img onClick={() => sortByFieldn('status','asc')} src={sortimage}/>):(null)}
                     {sort.values==='status' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {/* Map the list array and display the datas in a table.  */}
                {displayShips.map((data: any, i: any) => (
                  <tr key={data.ship_id}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='Created Date'>{data.created_date === null ? (
                      <Text variant='subtitle'> - </Text>
                    ) : (
                      <Text variant='subtitle'> {format(parseISO(data.created_date), 'dd/MM/yyyy')} </Text>
                    )}
                    </td>
                    <td data-label='IMO Number'>
                      {data.ship_imo_number === null ? (
                        <Text variant='subtitle'> - </Text>

                      ) : (
                        <Text variant='subtitle'>{data.ship_imo_number}</Text>
                      )}
                    </td>
                    {/* {console.log(data)} */}
                    <td data-label='Ship Name'>
                      {data.ship_name === null ? (
                        <Text variant='subtitle'> - </Text>

                      ) : (
                        <Text variant='subtitle'>{data.ship_name}</Text>
                      )}
                    </td>
                    <td data-label='Company'>
                      {data.company_name === null ? (
                        <Text variant='subtitle'> - </Text>

                      ) : (
                        <Text variant='subtitle'>{data.company_name}</Text>
                      )}
                    </td>
                    <td data-label='Country'>
                      {data.country_name === null ? (
                        <Text variant='subtitle'> - </Text>

                      ) : (
                        <Text variant='subtitle'>{data.country_name}</Text>
                      )}
                    </td>
                    {/* Check the status, if the status is verified that text color will be a success, else danger color.  */}
                    <td data-label='Status'>{data.status === 'VERIFIED' ? (
                      <Text color='warning' variant='subtitle'>Verified</Text>
                    ) : (
                      <Text color='danger' variant='subtitle'>Not Verified</Text>
                    )}
                    </td>
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="ship-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(data, page)}> {/* While onclick open view page.  */}
                            <Icons color="#52575a" icon="visibility" /> {/* View icon. */}
                          </Button>
                        </OverlayTrigger>
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="ship-edit"> Edit option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(data, page)} disabled> {/* While onclick open edit page.   */}
                              <Icons color="#52575a" icon="edit" /> {/* Edit icon.  */}
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="ship-edit"> Edit
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(data, page)}> {/* While onclick open edit page.   */}
                              <Icons color="#52575a" icon="edit" /> {/* Edit icon.  */}
                            </Button>
                          </OverlayTrigger>
                        )}
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="ship-delete-vistor"> Delete option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(data)} disabled>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="ship-delete"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(data)} >
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </TableActions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text> {/* Display the total elements. */}
                {displayShips.length === 1 && (
                  <>
                    <b>
                      {displayShips.length}
                      {/* {((page) * 10) + 1} - {(displayShips.length + (page) * 10)} */}
                    </b> of {totalElement} Ship
                  </>
                )}
                {displayShips.length > 1 && (
                  <>
                    <b>
                      {displayShips.length}
                      {/* {((page) * 10) + 1} - {(displayShips.length + (page) * 10)} */}
                    </b> of {totalElement} Ships
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()} {/* First page button.  */}
                {prevBtn()} {/* Previous button.  */}
                {PageNumber} {/* Totalpages.  */}
                {nextBtn()} {/* Next button.  */}
                {lastPage()} {/* Last page button.  */}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form ship list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* If modal is true goto the forgot password page with the props of onclose function */}
      {showModal && <ShipFilter onClose={() => _willCloseModal()} filterShips={displayShips} />}
    </Main>
  )
}
export interface TablefooterProps {
  children?: React.ReactNode
}

// Table footer style. 
const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

// Table body style. 
const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

//Search Form Component  for displaying serarch box, and add ship button
const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

// Declare the variable. 
const mapStateToProps = (state: any) => ({
  ships: state.ship.ships,
  isLoading: state.ship.isLoading,
  error: state.ship.error,
  totalElement: state.ship.totalElement,
  totalPage: state.ship.totalPage
})

// Connect to the action and get the list, totalpage and total elements. 
export default connect(
  mapStateToProps,
  {
    shipListGetAction,
    shipSetSelectedAction,
  }

)(ShipList)