import{PORT_LIST_GET,
    PORT_LIST_GET_F,
    PORT_LIST_GET_R,
    PORT_SELECTED_SET
} from '../actionType'

export const portListGetAction = (params : any) => ({
    type: PORT_LIST_GET,
    payload: params
})

export const portListGetFAction = (params : any) => ({
    type: PORT_LIST_GET_F,
    payload: params
})

export const portListGetRAction = (params : any) => ({
    type: PORT_LIST_GET_R,
    payload: params
})

export const portSetSelectedAction = (port : any) => ({
    type: PORT_SELECTED_SET,
    payload: port
})