import * as Observable from 'rxjs'
import { timeout, map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getDatalasticShipEngines = (params : any) => {
    return http({
      method: 'GET',
      url: '/ships/shipEngineList',
      params: { ...params}
    }).pipe(
      map(res => {
        if (res.success) {
          // console.log(res,'ship company')
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }

const getdatalasticShipCompanyDetail = (id : any) => {
    return http({
      method: 'GET',
      url: `/companies/${id}`
    }).pipe(
      map(res => {
        if (res.success) {
          console.log(res, 'company view data')
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }
  
  // const datalasticdeleteShipCompany = (id: any) => {
  //   return httpDelete({
  //     method: 'DELETE',
  //     url: `/companies/${id}`,
  //   }).pipe(
  //     map(res => {
  //       // console.log(res, 'delete response')
  //       if (res) {
  //         return res
  //       } else {
  //         // console.log(res, 'error message')
  //         throw new Error("Error")
  //       }
  //     })
  //   )
  // }
  
  const datalasticupdateShipCompany = (body : any) => {
    return http({
      method: 'PUT',
      url: '/companies',
      body: body
    }).pipe(
      map(res => {
        if (res.success) {
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }
  
  const datalasticupdateShipCompanyImage = (body : any) => {
    return http({
      method: 'POST',
      url: '/companies/companylogo',
      body
    }).pipe(
      map(res => {
        if (res.success) {
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }
  
  const datalasticaddShipCompany = (body : any) => {
    return http({
      method: 'POST',
      url: '/companies',
      body
    }).pipe(
      map(res => {
        if (res.success) {
          return res
        } else {
          throw new Error(res.msg)
        }
      })
    )
  }

  const addshipEngExcel = (body: any,params:any) => {
    let content = new FormData();
    return http({
        method: 'POST',
        url: `/ships/shipEngine/${params}`,
        body
    }).pipe(
        map(res => {
            if (res.success) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    )
}

const addshipClassExcel = (body: any,params:any) => {
  let content = new FormData();
  return http({
      method: 'POST',
      url: `/ships/importShipClassification/${params}`,
      body
  }).pipe(
      map(res => {
          if (res.success) {
              return res
          } else {
              throw new Error(res.msg)
          }
      })
  )
}

const addshipDemoExcel = (body: any,params:any) => {
  let content = new FormData();
  return http({
      method: 'POST',
      url: `/ships/importShipDemolition/${params}`,
      body
  }).pipe(
      map(res => {
          if (res.success) {
              return res
          } else {
              throw new Error(res.msg)
          }
      })
  )
}

const getDatalasticShipDemo = (params : any) => {
  return http({
    method: 'GET',
    url: '/ships/shipDemolitionList',
    params: { ...params}
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res,'ship company')
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

  const datalasticShipCmpService = {
    getdatalasticShipCompanyDetail,
    datalasticupdateShipCompany,
    datalasticaddShipCompany,
    datalasticupdateShipCompanyImage,
    getDatalasticShipEngines,
    addshipEngExcel,
    addshipClassExcel,
    addshipDemoExcel,
    getDatalasticShipDemo
  }
  
  export default datalasticShipCmpService