import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
// import { Button } from '../../../../common/components/button/Button'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import { connect } from 'react-redux'
import swal from 'sweetalert';
import { thicknessSetSelectedAction } from '../../../../store/action/thicknessAction'
import thicknessService from '../../../../services/thicknessService'
import { useForm } from 'react-hook-form'

// Blue print of the class component.  
interface thicknessEditProps {
    id: string | number;
    side: string;
    typeOfCoating: string;
    coat1: string;
    coat2: string;
    coat3: string;
    coat4: string;
    coat5: string;
}
interface FormData {
    txt_side: string;
    txt_typeOfCoating: string;
    txt_coat1: string;
    txt_coat2: string;
    txt_Coat3: string;
    txt_coat4: string;
    txt_coat5: string;
}
const ThicknessEdit = (props: any) => {
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const [thicknessaddflag, setthicknessAddFlag] = React.useState(false)
    const [display, setDisplay] = React.useState<thicknessEditProps>({
        id: '',
        side: '',
        typeOfCoating: '',
        coat1: '',
        coat2: '',
        coat3: '',
        coat4: '',
        coat5: '',
    })
    const [thickness, setThickness] = useState<any>({
        isLoading: false,
        isSubmitting: false,
        side: '',
        typeOfCoating: '',
        coat1: '',
        coat2: '',
        Coat3: '',
        coat4: '',
        coat5: '',
    },
    )


    // Used to get perticular Thickness detail and store to variable.   
    const _getThicknessDetail = ((id: any) => {
        setThickness({ ...thickness, isLoading: true })
        return thicknessService.getThicknessDetail(id).subscribe({
            next: r => {
                // console.log(r,'response')
                // console.log(r.data, 'data within response')
                const value = r.data
                // console.log(value, 'data within response using value')
                setDisplay({
                    id: Number(r.data.id),
                    side: r.data.side,
                    typeOfCoating: r.data.type_of_coating,
                    coat1: r.data.coat_1,
                    coat2: r.data.coat_2,
                    coat3: r.data.coat_3,
                    coat4: r.data.coat_4,
                    coat5: r.data.coat_5,
                })
                props.thicknessSetSelectedAction(value)
                setThickness({ ...thickness, isLoading: false, })
            },
            error: err => {
                console.error(err)
                setThickness({ ...thickness, isLoading: false })
            }
        })
    })
    React.useEffect(() => {
        const { match } = props
        const { id } = match.params
        _getThicknessDetail(id)
    }, [])

    // After update all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call.  
    const _didThicknessEdit = () => {
        // e.preventDefault()
        const updatedForm = {
            id: display.id,
            side: display.side,
            type_of_coating: display.typeOfCoating,
            coat_1: display.coat1,
            coat_2: display.coat2,
            coat_3: display.coat3,
            coat_4: display.coat4,
            coat_5: display.coat5,
        }

        // API call for updateship with data, updated into list page.  
        if (thicknessaddflag === true) {
            thicknessService.updateThickness(updatedForm).subscribe({
                next: () => {
                    swal("Great", "Thickness Updated Successfully", "success")
                    history.push('/thickness/list')
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    console.error(err)
                }
            })
        }
        else {
            console.log(thicknessaddflag)
        }

    }

    onsubmit = (e: any) => {
        // e.preventDefault()
        console.log(Object.keys(errors).length, 'error length')
        if (Object.keys(errors).length === 0) {
            _didThicknessEdit()
            setthicknessAddFlag(true)
        }
        else {
            setthicknessAddFlag(false)
        }
    }
    return (
        <Main>
            <Text color='black' variant="title">Edit Thickness Information</Text>
            <Space divheight={25} />
            {/* Edit form, having input box, select box.   */}
            <Grid templateCols='75% auto' className='add_page'>
                <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='thicknessEditForms'>
                    <Card title="Fill All the Information Below">
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >side of Ship</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_side"
                                            value={display.side}
                                            onChange={(e) => setDisplay({ ...display, side: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="side of Ship"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_side?.type === 'required' && 'Please enter Side'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Coating Type</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_typeOfCoating"
                                            value={display.typeOfCoating}
                                            onChange={(e) => setDisplay({ ...display, typeOfCoating: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="Coating Type"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_typeOfCoating?.type === 'required' && 'Please enter Type'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>First Coat</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="number"
                                            name="txt_coat1"
                                            value={display.coat1}
                                            onChange={(e) => setDisplay({ ...display, coat1: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder='First Coat'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_coat1?.type === 'required' && 'Please enter 1`st Coat'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Second Coat</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="number"
                                            name="txt_coat2"
                                            value={display.coat2}
                                            onChange={(e) => setDisplay({ ...display, coat2: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder='Second coat'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_coat2?.type === 'required' && 'Please enter Coat2'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes >
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Third Coat</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="number"
                                            name="txt_Coat3"
                                            value={display.coat3}
                                            onChange={(e) => setDisplay({ ...display, coat3: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder='Third Coat'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_Coat3?.type === 'required' && 'Please enter Third Coat'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Fourth Coat</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="number"
                                            name="txt_coat4"
                                            value={display.coat4}
                                            onChange={(e) => setDisplay({ ...display, coat4: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder='Fourth Coat'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_coat4?.type === 'required' && 'Please enter Coat4'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Fifth Coat</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="number"
                                            name="txt_coat5"
                                            value={display.coat5}
                                            onChange={(e) => setDisplay({ ...display, coat5: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder='Fifth Coat'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_coat5?.type === 'required' && 'Please enter Fifth coat'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                    <Actions>
                        {/* If you click the back button its is redirect to thickness list page.   */}
                        <Link to='/thickness/list'>
                            <Button type="button" className='bttn bttn-transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the save button its is call the function, in that data updated to list and redirect to list page.   */}
                        <Button className='bttn bttn-transparent' type="submit">
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
        </Main>
    )
}


export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style.  
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-ship-add');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}

// Button style.  
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    thickness: state.thickness.selectedThickness
})
//Return the selected ship details.  
export default connect(
    mapStateToProps,
    {
        thicknessSetSelectedAction
    }
)(ThicknessEdit)
