// SHIPS
export const SHIP_LIST_GET = 'SHIP_LIST_GET'
export const SHIP_LIST_GET_F = 'SHIP_LIST_GET_F'
export const SHIP_LIST_GET_R = 'SHIP_LIST_GET_R'
export const ALL_SHIP_LIST_GET = 'ALL_SHIP_LIST_GET'
export const ALL_SHIP_LIST_GET_F = 'ALL_SHIP_LIST_GET_F'
export const ALL_SHIP_LIST_GET_R = 'ALL_SHIP_LIST_GET_R'
export const SHIP_SELECTED_SET = 'SHIP_SELECTED_SET'
export const SHIP_COMPANY_LIST_GET = 'SHIP_COMPANY_LIST_GET'
export const SHIP_COMPANY_LIST_GET_F = 'SHIP_COMPANY_LIST_GET_F'
export const SHIP_COMPANY_LIST_GET_R = 'SHIP_COMPANY_LIST_GET_R'
export const SHIP_COMPANY_SELECTED_SET = 'SHIP_COMPANY_SELECTED_SET'
export const SHIP_OWNER_LIST_GET = 'SHIP_OWNER_LIST_GET'
export const SHIP_OWNER_LIST_GET_F = 'SHIP_OWNER_LIST_GET_F'
export const SHIP_OWNER_LIST_GET_R = 'SHIP_OWNER_LIST_GET_R'
export const SHIP_OWNER_SELECTED_SET = 'SHIP_OWNER_SELECTED_SET'

//REPORT
export const DATA_SOCIAL_REPORT_LIST_GET = 'DATA_SOCIAL_REPORT_LIST_GET'
export const DATA_SOCIAL_REPORT_LIST_GET_F = 'DATA_SOCIAL_REPORT_LIST_GET_F'
export const DATA_SOCIAL_REPORT_LIST_GET_R = 'DATA_SOCIAL_REPORT_LIST_GET_R'
export const DATA_SOCIAL_REPORT_SELECTED_SET = 'DATA_SOCIAL_REPORT_SELECTED_SET'

//DATALASTIC
export const DATA_SHIP_COMPANY_LIST_GET = 'DATA_SHIP_COMPANY_LIST_GET'
export const DATA_SHIP_COMPANY_LIST_GET_F = 'DATA_SHIP_COMPANY_LIST_GET_F'
export const DATA_SHIP_COMPANY_LIST_GET_R = 'DATA_SHIP_COMPANY_LIST_GET_R'
export const DATA_SHIP_COMPANY_SELECTED_SET = 'DATA_SHIP_COMPANY_SELECTED_SET'


//SHIP MATERIAL

export const SHIP_MATERIAL_LIST_GET = 'SHIP_MATERIAL_LIST_GET'
export const SHIP_MATERIAL_LIST_GET_F = 'SHIP_MATERIAL_LIST_GET_F'
export const SHIP_MATERIAL_LIST_GET_R = 'SHIP_MATERIAL_LIST_GET_R'
export const SHIP_MATERIAL_SELECTED_SET = 'SHIP_MATERIAL_SELECTED_SET'

//ANODE 

export const ANODE_LIST_GET = 'ANODE_LIST_GET'
export const ANODE_LIST_GET_F = 'ANODE_LIST_GET_F'
export const ANODE_LIST_GET_R = 'ANODE_LIST_GET_R'
export const ANODE_SELECTED_SET = 'ANODE_SELECTED_SET'

//PRODUCT TYPE LIST IN ADSPACE MANAGEMENT

export const PRODUCT_TYPE_LIST_GET = 'PRODUCT_TYPE_LIST_GET'
export const PRODUCT_TYPE_LIST_GET_F = 'PRODUCT_TYPE_LIST_GET_F'
export const PRODUCT_TYPE_LIST_GET_R = 'PRODUCT_TYPE_LIST_GET_R'
export const PRODUCT_TYPE_SELECTED_SET = 'PRODUCT_TYPE_SELECTED_SET'

//SLOT LIST IN ADSPACE MANAGEMENT

export const SLOT_LIST_GET = 'SLOT_LIST_GET'
export const SLOT_LIST_GET_F = 'SLOT_LIST_GET_F'
export const SLOT_LIST_GET_R = 'SLOT_LIST_GET_R'
export const SLOT_SELECTED_SET = 'SLOT_SELECTED_SET'

//ADS LIST IN ADSPACE MANAGEMENT

export const ADS_LIST_GET = 'ADS_LIST_GET'
export const ADS_LIST_GET_F = 'ADS_LIST_GET_F'
export const ADS_LIST_GET_R = 'ADS_LIST_GET_R'
export const ADS_SELECTED_SET = 'ADS_SELECTED_SET'

//SHIP TYPES

export const SHIP_TYPE_LIST_GET = 'SHIP_TYPE_LIST_GET'
export const SHIP_TYPE_LIST_GET_F = 'SHIP_TYPE_LIST_GET_F'
export const SHIP_TYPE_LIST_GET_R = 'SHIP_TYPE_LIST_GET_R'
export const SHIP_TYPE_SELECTED_SET = 'SHIP_TYPE_SELECTED_SET'

//SHIP SUB TYPES

export const SHIP_SUBTYPE_LIST_GET = 'SHIP_SUBTYPE_LIST_GET'
export const SHIP_SUBTYPE_LIST_GET_F = 'SHIP_SUBTYPE_LIST_GET_F'
export const SHIP_SUBTYPE_LIST_GET_R = 'SHIP_SUBTYPE_LIST_GET_R'
export const SHIP_SUBTYPE_SELECTED_SET = 'SHIP_SUBTYPE_SELECTED_SET'

//SUBSCRIPTION

export const SUBSCRIPTION_LIST_GET = 'SUBSCRIPTION_LIST_GET'
export const SUBSCRIPTION_LIST_GET_F = 'SUBSCRIPTION_LIST_GET_F'
export const SUBSCRIPTION_LIST_GET_R = 'SUBSCRIPTION_LIST_GET_R'
export const SUBSCRIPTION_SELECTED_SET = 'SUBSCRIPTION_SELECTED_SET'

//MANUFACTURER

export const MANUFACTURER_LIST_GET = 'MANUFACTURER_LIST_GET'
export const MANUFACTURER_LIST_GET_F = 'MANUFACTURER_LIST_GET_F'
export const MANUFACTURER_LIST_GET_R = 'MANUFACTURER_LIST_GET_R'
export const MANUFACTURER_SELECTED_SET = 'MANUFACTURER_SELECTED_SET'

//PRICEPERVIEW DETAIL
export const PAYPERVIEW_LIST_GET = 'PAYPERVIEW_LIST_GET'
export const PAYPERVIEW_LIST_GET_F = 'PAYPERVIEW_LIST_GET_F'
export const PAYPERVIEW_LIST_GET_R = 'PAYPERVIEW_LIST_GET_R'
export const PAYPERVIEW_SELECTED_SET = 'PAYPERVIEW_SELECTED_SET'

//PRICEPERVIEW DETAIL
export const TRANSACTION_LIST_GET = 'TRANSACTION_LIST_GET'
export const TRANSACTION_LIST_GET_F = 'TRANSACTION_LIST_GET_F'
export const TRANSACTION_LIST_GET_R = 'TRANSACTION_LIST_GET_R'
export const TRANSACTION_SELECTED_SET = 'TRANSACTION_SELECTED_SET'

//QUOTATION DETAIL
export const QUOTATION_LIST_GET = 'QUOTATION_LIST_GET'
export const QUOTATION_LIST_GET_F = 'QUOTATION_LIST_GET_F'
export const QUOTATION_LIST_GET_R = 'QUOTATION_LIST_GET_R'
export const QUOTATION_SELECTED_SET = 'QUOTATION_SELECTED_SET'

//MANUFACTURER COMPANY DETAIL

export const MANUFACTURER_CMP_LIST_GET = 'MANUFACTURER_CMP_LIST_GET'
export const MANUFACTURER_CMP_LIST_GET_F = 'MANUFACTURER_CMP_LIST_GET_F'
export const MANUFACTURER_CMP_LIST_GET_R = 'MANUFACTURER_CMP_LIST_GET_R'
export const MANUFACTURER_CMP_SELECTED_SET = 'MANUFACTURER_CMP_SELECTED_SET'

//PROVINCES

export const PROVINCES_LIST_GET = 'PROVINCES_LIST_GET'
export const PROVINCES_LIST_GET_F = 'PROVINCES_LIST_GET_F'
export const PROVINCES_LIST_GET_R = 'PROVINCES_LIST_GET_R'
export const PROVINCES_SELECTED_SET = 'PROVINCES_SELECTED_SET'

//COEFFICIENT 

export const COEFFICIENT_LIST_GET = 'COEFFICIENT_LIST_GET'
export const COEFFICIENT_LIST_GET_F = 'COEFFICIENT_LIST_GET_F'
export const COEFFICIENT_LIST_GET_R = 'COEFFICIENT_LIST_GET_R'
export const COEFFICIENT_SELECTED_SET = 'COEFFICIENT_SELECTED_SET'

//PAINT

export const PAINT_LIST_GET = 'PAINT_LIST_GET'
export const PAINT_LIST_GET_F = 'PAINT_LIST_GET_F'
export const PAINT_LIST_GET_R = 'PAINT_LIST_GET_R'
export const PAINT_SELECTED_SET = 'PAINT_SELECTED_SET'

//THICKNESS ACTION TYPES

export const THICKNESS_LIST_GET = 'THICKNESS_LIST_GET'
export const THICKNESS_LIST_GET_F = 'THICKNESS_LIST_GET_F'
export const THICKNESS_LIST_GET_R = 'THICKNESS_LIST_GET_R'
export const THICKNESS_SELECTED_SET = 'THICKNESS_SELECTED_SET'

//UNITS ACTION TYPES

export const UNITS_LIST_GET = 'UNITS_LIST_GET'
export const UNITS_LIST_GET_F = 'UNITS_LIST_GET_F'
export const UNITS_LIST_GET_R = 'UNITS_LIST_GET_R'
export const UNITS_SELECTED_SET = 'UNITS_SELECTED_SET'

//PORT TYPES

export const PORT_LIST_GET = 'PORT_LIST_GET'
export const PORT_LIST_GET_F = 'PORT_LIST_GET_F'
export const PORT_LIST_GET_R = 'PORT_LIST_GET_R'
export const PORT_SELECTED_SET = 'PORT_SELECTED_SET'

//DOCK TYPES

export const DOCK_TYPE_LIST_GET = 'DOCK_TYPE_LIST_GET'
export const DOCK_TYPE_LIST_GET_F = 'DOCK_TYPE_LIST_GET_F'
export const DOCK_TYPE_LIST_GET_R = 'DOCK_TYPE_LIST_GET_R'
export const DOCK_TYPE_SELECTED_SET = 'DOCK_TYPE_SELECTED_SET'

//COMPANY TYPES 

export const CMP_TYPE_LIST_GET = 'CMP_TYPE_LIST_GET'
export const CMP_TYPE_LIST_GET_F = 'CMP_TYPE_LIST_GET_F'
export const CMP_TYPE_LIST_GET_R = 'CMP_TYPE_LIST_GET_R'
export const CMP_TYPE_SELECTED_SET = 'CMP_TYPE_SELECTED_SET'

//SHIP CLASSIFICATION

export const SHIP_CLS_LIST_GET = 'SHIP_CLS_LIST_GET'
export const SHIP_CLS_LIST_GET_F = 'SHIP_CLS_LIST_GET_F'
export const SHIP_CLS_LIST_GET_R = 'SHIP_CLS_LIST_GET_R'
export const SHIP_CLS_SELECTED_SET = 'SHIP_CLS_SELECTED_SET'

//ROLE TYPES

export const ROLE_LIST_GET = 'ROLE_LIST_GET'
export const ROLE_LIST_GET_F = 'ROLE_LIST_GET_F'
export const ROLE_LIST_GET_R = 'ROLE_LIST_GET_R'
export const ROLE_SELECTED_SET = 'ROLE_SELECTED_SET'

//COUNTRY TYPES

export const COUNTRY_LIST_GET = 'COUNTRY_LIST_GET'
export const COUNTRY_LIST_GET_F = 'COUNTRY_LIST_GET_F'
export const COUNTRY_LIST_GET_R = 'COUNTRY_LIST_GET_R'
export const COUNTRY_SELECTED_SET = 'COUNTRY_SELECTED_SET'

//CITY TYPES

export const CITY_LIST_GET = 'CITY_LIST_GET'
export const CITY_LIST_GET_F = 'CITY_LIST_GET_F'
export const CITY_LIST_GET_R = 'CITY_LIST_GET_R'
export const CITY_SELECTED_SET = 'CITY_SELECTED_SET'

// DRYDOCKS
export const DOCK_LIST_GET = 'DOCK_LIST_GET'
export const DOCK_LIST_GET_F = 'DOCK_LIST_GET_F'
export const DOCK_LIST_GET_R = 'DOCK_LIST_GET_R'
export const DOCK_SELECTED_SET = 'DOCK_SELECTED_SET'
export const DOCK_COMPANY_LIST_GET = 'DOCK'
export const DOCK_COMPANY_LIST_GET_F = 'DOCK_COMPANY_LIST_GET_F'
export const DOCK_COMPANY_LIST_GET_R = 'DOCK_COMPANY_LIST_GET_R'
export const DOCK_COMPANY_SELECTED_SET = 'DOCK_COMPANY_SELECTED_SET'
export const DOCK_OWNER_LIST_GET = 'DOCK_OWNER_LIST_GET'
export const DOCK_OWNER_LIST_GET_F = 'DOCK_OWNER_LIST_GET_F'
export const DOCK_OWNER_LIST_GET_R = 'DOCK_OWNER_LIST_GET_R'
export const DOCK_OWNER_SELECTED_SET = 'DOCK_OWNER_SELECTED_SET'

// USERS
export const USER_LIST_GET = 'USER_GET_LIST'
export const USER_LIST_GET_F = 'USER_LIST_GET_F'
export const USER_LIST_GET_R = 'USER_LIST_GET_R'
export const USER_SELECTED_SET = 'USER_SELECTED_SET'
export const  USER_LIST_GET_All = 'USER_LIST_GET_All'
export const  USER_LIST_GET_ALL_F = ' USER_LIST_GET_ALL_F'
export const  USER_LIST_GET_ALL_R = ' USER_LIST_GET_ALL_R'

// SYSTEM USERS
export const SYSTEM_USER_LIST_GET = 'SYSTEM_USER_GET_LIST'
export const SYSTEM_USER_LIST_GET_F = 'SYSTEM_USER_LIST_GET_F'
export const SYSTEM_USER_LIST_GET_R = 'SYSTEM_USER_LIST_GET_R'
export const SYSTEM_USER_SELECTED_SET = 'SYSTEM_USER_SELECTED_SET'
export const  SYSTEM_USER_LIST_GET_All = 'SYSTEM_USER_LIST_GET_All'
export const  SYSTEM_USER_LIST_GET_ALL_F = ' SYSTEM_USER_LIST_GET_ALL_F'
export const  SYSTEM_USER_LIST_GET_ALL_R = ' SYSTEM_USER_LIST_GET_ALL_R'

// DISCOUNT
export const DISCOUNT_LIST_GET = 'DISCOUNT_LIST_GET'
export const DISCOUNT_LIST_GET_F = 'DISCOUNT_LIST_GET_F'
export const DISCOUNT_LIST_GET_R = 'DISCOUNT_LIST_GET_R'
export const DISCOUNT_SELECTED_SET = 'DISCOUNT_SELECTED_SET'

// ACCOUNT
export const ACCOUNT_SET = 'ACCOUNT_SET'
export const ACCOUNT_SET_F = 'ACCOUNT_SET_F'
export const ACCOUNT_GET = 'ACCOUNT_GET'
export const ACCOUNT_GET_F = 'ACCOUNT_GET_F'

// TNC
export const TNC_LIST_GET = 'TNC_LIST_GET'
export const TNC_LIST_GET_F = 'TNC_LIST_GET_F'
export const TNC_LIST_GET_R = 'TNC_LIST_GET_R'

export const TNC_SELECTED_SET = 'TNC_SELECTED_SET'

//DATALASTIC
export const DATA_SHIP_ENGINE_LIST_GET = 'DATA_SHIP_ENGINE_LIST_GET'
export const DATA_SHIP_ENGINE_LIST_GET_F = 'DATA_SHIP_ENGINE_LIST_GET_F'
export const DATA_SHIP_ENGINE_LIST_GET_R = 'DATA_SHIP_ENGINE_LIST_GET_R'
export const DATA_SHIP_ENGINE_SELECTED_SET = 'DATA_SHIP_ENGINE_SELECTED_SET'

//DATALASTIC
export const DATA_SHIP_CLASSIFICATION_LIST_GET = 'DATA_SHIP_CLASSIFICATION_LIST_GET'
export const DATA_SHIP_CLASSIFICATION_LIST_GET_F = 'DATA_SHIP_CLASSIFICATION_LIST_GET_F'
export const DATA_SHIP_CLASSIFICATION_LIST_GET_R = 'DATA_SHIP_CLASSIFICATION_LIST_GET_R'
export const DATA_SHIP_CLASSIFICATION_SELECTED_SET = 'DATA_SHIP_CLASSIFICATION_SELECTED_SET'

//DATALASTIC
export const DATA_SHIP_DEMOLITION_LIST_GET = 'DATA_SHIP_DEMOLITION_LIST_GET'
export const DATA_SHIP_DEMOLITION_LIST_GET_F = 'DATA_SHIP_DEMOLITION_LIST_GET_F'
export const DATA_SHIP_DEMOLITION_LIST_GET_R = 'DATA_SHIP_DEMOLITION_LIST_GET_R'
export const DATA_SHIP_DEMOLITION_SELECTED_SET = 'DATA_SHIP_DEMOLITION_SELECTED_SET'

//DATALASTIC
export const DATA_SHIP_PROPULSION_LIST_GET = 'DATA_SHIP_PROPULSION_LIST_GET'
export const DATA_SHIP_PROPULSION_LIST_GET_F = 'DATA_SHIP_PROPULSION_LIST_GET_F'
export const DATA_SHIP_PROPULSION_LIST_GET_R = 'DATA_SHIP_PROPULSION_LIST_GET_R'
export const DATA_SHIP_PROPULSION_SELECTED_SET = 'DATA_SHIP_PROPULSION_SELECTED_SET'