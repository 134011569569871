import {
    DATA_SHIP_COMPANY_LIST_GET,
    DATA_SHIP_COMPANY_LIST_GET_F,
    DATA_SHIP_COMPANY_LIST_GET_R,
    DATA_SHIP_COMPANY_SELECTED_SET,
  } from '../actionType'
  
  export const datashipCompanyListGetAction = (params : any) => ({
    type: DATA_SHIP_COMPANY_LIST_GET,
    payload: params
  })
  
  export const datashipCompanyListGetFAction = (data : any) => ({
    type: DATA_SHIP_COMPANY_LIST_GET_F,
    payload: data
  })
  
  export const datashipCompanyListGetRAction = ( error : any) => ({
    type: DATA_SHIP_COMPANY_LIST_GET_R,
    payload: error
  })
  
  export const datashipCompanySetSelectedAction = (company : any) => ({
    type: DATA_SHIP_COMPANY_SELECTED_SET,
    payload: company
  })