import React, { useState } from 'react';
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap';
import './Anode.scss';
import '../../../common/components/pagination/Pagination.scss'
import { Text } from '../../../common/components/text/Text'
import { Space } from '../../../common/components/space/Space';
import { Main } from '../../../common/components/main/Main';
import { Button } from '../../../common/components/button/Button';
import Card from '../../../common/components/card/Card';
import { Grid } from '../../../common/components/grid/Grid';
import { Table } from '../../../common/components/table/Table';
import Icons from '../../../common/components/icon/Icon';
import { format, parseISO } from 'date-fns'
import { anodeListGetAction, anodeSetSelectedAction } from "../../../store/action/anodeAction";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { history } from '../../../store';
import { Flex } from '../../../common/components/flex/Flex';
import { ReactNode } from 'react'
import swal from 'sweetalert'
import anodeService from '../../../services/anodeService';
import Loader from 'react-loader-spinner';
import { InputWithIcon, Select } from '../../../common/components/control/Control';
import codeService from '../../../services/codeService';
import NumberFormat from 'react-number-format';
import { AnodefilterState } from './filter/AnodefilterState';


const AnodeList = (props: any) => {
  const { anode, isLoading, totalElement } = props
  const [displayAnodes, setAnodes] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [searchQuery, setQuery] = React.useState('')
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  const [showDelete, setShow] = React.useState(false);
  const [deleteAnode, setDeleteAnode] = React.useState<any>([])
  const [findBy, setFindBy] = React.useState(AnodefilterState.Anodefilter.findbyIdname)
  const [searchCmp, setSearchCmp] = React.useState(AnodefilterState.Anodefilter.searchBoxInputCmp)
  const [searchSupplier, setSearchSupplier] = React.useState(AnodefilterState.Anodefilter.searchBoxInputOwner)
  const [searchInput, setSearchInput] = React.useState(AnodefilterState.Anodefilter.searchBoxInputName)


  // Used to get type and payload, call action and get list.   
  React.useEffect(() => {
    props.anodeListGetAction({ size: 10, page, name: searchInput||AnodefilterState.Anodefilter.searchBoxInputName, company_name:searchCmp||AnodefilterState.Anodefilter.searchBoxInputCmp, supplier_name:searchSupplier||AnodefilterState.Anodefilter.searchBoxInputOwner })
  }, [page, searchQuery])

  // Check ships(list) length, add checked false in that array.  
  React.useEffect(() => {
    // console.log(anode,'ship type in ship type lisst page')
    if (anode && anode.length > 0) {
      setAnodes(() => {
        return anode.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (anode && anode.length === 0) {
      setAnodes([])
    }
  }, [anode])

  // Get totalPage in props and push into the pagination variable.   
  React.useEffect(() => {
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  // Redirect to ship detail page.  
  const _willViewDetail = (anode: any) => {
    var AnodeCmpId: string | number;
    var AnodeSupId: string | number;
    if (anode.company !== null) {
      AnodeCmpId = anode.company.id
    }
    else {
      AnodeCmpId = ''
    }
    if (anode.supplier !== null) {
      AnodeSupId = anode.supplier.id
    }
    else {
      AnodeSupId = ''
    }
    anodeSetSelectedAction(anode)
    history.push(`/anode/${anode.id}/${AnodeCmpId}/${AnodeSupId}`)
  }

  // Redirect to ship edit page.  
  const _willViewEdit = (anode: any) => {
    var AnodeCmpId: string | number;
    var AnodeSupId: string | number;
    var AnodeSupName: string | number;
    if (anode.company !== null) {
      AnodeCmpId = anode.company.id
    }
    else {
      AnodeCmpId = ''
    }
    if (anode.supplier !== null) {
      AnodeSupId = anode.supplier.id;
      AnodeSupName = anode.supplier.name;
    }
    else {
      AnodeSupId = '';
      AnodeSupName = '';
    }
    props.anodeSetSelectedAction(anode)
    history.push(`/anode/edit/${anode.id}/${AnodeCmpId}/${AnodeSupId}/${AnodeSupName}`)
  }

  const role=localStorage.getItem("role")

  // Delete the pericular list.
  const handleClose = () => setShow(false);
  const handleShow = (anode: any) => {
    setShow(true);
    setDeleteAnode(anode)
    // console.log(deleteAnode, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deleteAnode(deleteAnode)
  }

  const _deleteAnode = (anodedel: any) => {
    var AnodeCmpId: string | number;
    var AnodeSupId: string | number;
    if (anodedel.company !== null) {
      AnodeCmpId = anodedel.company.id
    }
    else {
      AnodeCmpId = ''
    }
    if (anodedel.supplier !== null) {
      AnodeSupId = anodedel.supplier.id
    }
    else {
      AnodeSupId = ''
    }
    // console.log(anodedel.id,AnodeCmpId,AnodeSupId,'dele params') 
    return anodeService.deleteAnode({ anode_id: anodedel.id, company_id: AnodeCmpId, supplier_id: AnodeSupId }).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.anodeListGetAction({ size: 10, page, q: searchQuery })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }

  //Search Function

  const _getSearchValue = (val: string) => {
    setFindBy(val)
    AnodefilterState.Anodefilter.findbyIdname=val;
  }

  const _searchFuntion = (value: string) => {
    setSearchInput(value)
    AnodefilterState.Anodefilter.searchBoxInputName=value;
    props.anodeListGetAction({ size: 10, page, name: value, company_name: searchCmp, supplier_name: searchSupplier })
    setPage(0)
  }

  const _SearchCmpFunc = (value: string) => {
    setSearchCmp(value)
    AnodefilterState.Anodefilter.searchBoxInputCmp=value;
    props.anodeListGetAction({ size: 10, page, name: searchInput, company_name: value, supplier_name: searchSupplier })
  }
  const _SerachSupFunc = (value: string) => {
    setSearchSupplier(value)
    AnodefilterState.Anodefilter.searchBoxInputOwner=value;
    props.anodeListGetAction({ size: 10, page, name: searchInput, company_name: searchCmp, supplier_name: value })
  }

  const _showAll = () => {
    setSearchInput('')
    setSearchCmp('')
    setSearchSupplier('')
    props.anodeListGetAction({ size: 10, page, name: '', company_name: '', supplier_name: '' })
  }
  // Prints first page pagination.  
  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  // While click the last page its move to last page.  
  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 9)
  }

  // While click the first button its move to first page.  
  const _goToFirstPage = () => {
    setPage(0)
    setHigh(9)
    setLow(1)
  }

  // Prints last page pagination button.  
  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  // Prints prev button.  
  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  // While click prev button go to the previous page of current page.  
  const _goPrevPage = () => {
    setPage(prev => {
      const newValue = prev - 1
      return newValue
    })
  }

  // Print next button.  
  const nextBtn = () => {
    if (page < totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  // While click next page button go to the next page of the current page.  
  const _goNextPage = () => {
    setPage(prev => {
      const newValue = prev + 1
      return newValue
    })
  }

  // While click one page number its move to that page.  
  const _goToPage = (page: any) => {
    setPage(page)
  }

  // Return the page number
  const ListItem = (page: any) => {
    return page.value + 1
  }

  // Prints the totalpages between prev, next button.  
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })

  return (
    <Main>
      <Text variant="title" color='black'>Anode List</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='3' className='m-0 p-0'>
              <div className='mr-2 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    {findBy === 'name' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Product.."
                        icon="search"
                        id="search-inputanode"
                      />
                    )}
                    {findBy === '' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Product.."
                        icon="search"
                        id="search-inputanode"
                      />
                    )}
                    {findBy === 'company' && (
                      <InputWithIcon
                        type="text"
                        value={searchCmp}
                        onChange={(e) => _SearchCmpFunc(e.target.value)}
                        placeholder="Search by Company..."
                        icon="search"
                        id="search-inputAnodeCmp"
                      />
                    )}
                    {findBy === 'supplier' && (
                      <InputWithIcon
                        type="text"
                        value={searchSupplier}
                        onChange={(e) => _SerachSupFunc(e.target.value)}
                        placeholder="Search by Owner..."
                        icon="search"
                        id="search-inputAnodeSup"
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <Row className='m-0 p-0'>
                <Col xs='6' sm='4' md='4' lg='5' xl='4' className='m-0 p-0 mr-2'>
                  <Select values={findBy} name="" onChange={(e) => _getSearchValue(e.target.value)}>
                    <option value=''>-- Search by --</option>
                    <option value="name"> By Product Name </option>
                    <option value="company"> By Company </option>
                    <option value="supplier"> By Owner </option>
                  </Select>
                </Col>
                <Col xs='5' sm='4' md='4' lg='7' xl='7' className='m-0 p-0 '>
                  <div>
                  {role === 'VISITOR' ? (
//                    <Link to='/anode/add'>   {/* Button add new ship, if you click the button it shows modal box.   */}
                      <Button variant='transparent' block={true} disabled>
                        <Icons color="#ffffff" icon="add" />
                        ADD NEW ANODE
                      </Button>
//                    </Link>
                  ):(
                    // <Link to='/anode/add'>   {/* Button add new ship, if you click the button it shows modal box.   */}
                    <Button variant='transparent' block={true} disabled>
                      <Icons color="#ffffff" icon="add" />
                      ADD NEW ANODE
                    </Button>
                  // </Link>
                  )}
                    <Button className='ml-2' variant='transparent' block={true} onClick={() => _showAll()}>
                      Show All
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SearchForm>
        <Space divheight={30} />
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>} {/* Check the list is loading, isloading is true its shows loading text.  */}
        {!isLoading && displayAnodes.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No Anode found</Text>
            <Space divheight={20} />
          </>
        )} {/* If isloading is false and also the list array length is 0 its shows no discount fount text.   */}
        {!isLoading && displayAnodes.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Anode Weight</th>
                  {/* <th>Sea Chest's Anode Weight</th> */}
                  <th>Price per Kg</th>
                  {/* <th>Electrochemical</th> */}
                  <th>Company Name</th>
                  <th>Owner Name</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Map the list array and display the datas in a table.   */}

                {/* {console.log(displayAnodes)} */}
                {displayAnodes.map((data: any, i: any) => (
                  <tr key={data.id}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='Name'>
                      {data.name === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{data.name}</Text>
                      )}
                    </td>
                    <td data-label='Anode Weight'>
                      {data.anode_weight === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{data.anode_weight}</Text>
                      )}
                    </td>
                    {/* <td data-label='Sea chest`s Anode Weight'>
                      {data.sea_chest_anode_weight === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{data.sea_chest_anode_weight}</Text>
                      )}
                    </td> */}
                    <td data-label='Price Per kg'>
                      {data.price_kg === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <>
                          {!String(data.price_kg).includes('.') && (
                            <Text className='txt-table-date' color='tabledata'>
                              {data.currency_code!==null?(
                                <>
                                <NumberFormat value={String(data.price_kg)} displayType={'text'} thousandSeparator={true} prefix={String(data.currency_code)+' '} />.00
                                </>
                              ):(
                                <>
                                <NumberFormat value={String(data.price_kg)} displayType={'text'} thousandSeparator={true} />.00
                                </>
                              )}
                            </Text>
                          )}
                          {String(data.price_kg).includes('.') && (
                            <Text className='txt-table-date' color='tabledata'>
                               {data.currency_code!==null?(
                                <>
                              <NumberFormat value={data.price_kg} displayType={'text'} thousandSeparator={true} prefix={String(data.currency_code)+' '} />
                                </>
                              ):(
                                <>
                              <NumberFormat value={data.price_kg} displayType={'text'} thousandSeparator={true} />
                                </>
                              )}

                            </Text>
                          )
                          }
                        </>
                      )}
                    </td>
                    {/* <td data-label='Electrochemical'>
                      {data.electrochemical === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{data.electrochemical}</Text>
                      )}
                    </td> */}
                    <td data-label='Company Name'>
                      {data.company === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{data.company.name}</Text>
                      )}
                    </td>
                    <td data-label='Owner Name'>
                      {data.supplier === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <>
                          {String(data.supplier.name).includes('@') && (
                            <Text className='txt-table-date' color='tabledata'>
                              {String(data.supplier.name).substring(0, String(data.supplier.name).indexOf('@'))}
                            </Text>
                          )}
                          {!String(data.supplier.name).includes('@') && (
                            <Text className='txt-table-date' color='tabledata'>{data.supplier.name}</Text>
                          )
                          }
                        </>
                      )}
                    </td>
                    <td>

                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="anode-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(data)}> {/* While onclick open view page.   */}
                            <Icons color="#52575a" icon="visibility" /> {/* View icon.  */}
                          </Button>
                        </OverlayTrigger>
                        {role === 'VISITOR' ? (
                        <OverlayTrigger overlay={
                          <Tooltip id="anode-edit-visitor"> Edit option is not available
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewEdit(data)} disabled> {/* While onclick open edit page.   */}
                            <Icons color="#52575a" icon="edit" /> {/* Edit icon.  */}
                          </Button>
                        </OverlayTrigger>
                        ):(
                          <OverlayTrigger overlay={
                            <Tooltip id="anode-edit"> Edit
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(data)}> {/* While onclick open edit page.   */}
                              <Icons color="#52575a" icon="edit" /> {/* Edit icon.  */}
                            </Button>
                          </OverlayTrigger>
                        )}
                        {role === 'VISITOR' ? (
                        <OverlayTrigger overlay={
                          <Tooltip id="anode-delete-visitor"> Delete option is not available
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => handleShow(data)} disabled>
                            <Icons color="#52575a" icon="delete" />
                          </Button>
                        </OverlayTrigger>
                        ):(
                          <OverlayTrigger overlay={
                            <Tooltip id="anode-delete"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(data)}>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </TableActions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text> {/* Display the total elements.  */}
                {displayAnodes.length === 1 && (
                  <>
                    <b>
                      {displayAnodes.length}
                      {/* {((page)*10)+1} - {(displayAnodes.length+(page)*10)} */}
                    </b> of {totalElement} Anode
                  </>
                )}
                {displayAnodes.length > 1 && (
                  <>
                    <b>
                      {displayAnodes.length}
                      {/* {((page)*10)+1} - {(displayAnodes.length+(page)*10)} */}
                    </b> of {totalElement} Anodes
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()} {/* First page button.   */}
                {prevBtn()} {/* Previous button.   */}
                {PageNumber} {/* Totalpages.   */}
                {nextBtn()} {/* Next button.   */}
                {lastPage()} {/* Last page button.   */}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {/* If modal is true goto the delete page with the props of onclose function */}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form Anode list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Main>
  )
}
export interface TablefooterProps {
  children?: React.ReactNode
}

// Table footer style.  
const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

// Table body style.  
const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

//Search Form Component  for displaying serarch box, and add ship button
const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

// Declare the variable.  
const mapStateToProps = (state: any) => ({
  anode: state.anode.anode,
  isLoading: state.anode.isLoading,
  error: state.anode.error,
  totalElement: state.anode.totalElement,
  totalPage: state.anode.totalPage
})

// Connect to the action and get the list, totalpage and total elements.  
export default connect(
  mapStateToProps,
  {
    anodeListGetAction,
    anodeSetSelectedAction,
  }

)(AnodeList)