import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Button, Row } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import { connect } from 'react-redux'
import swal from 'sweetalert';
import { anodeSetSelectedAction } from '../../../../store/action/anodeAction'
import anodeService from '../../../../services/anodeService'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner'
import codeService from '../../../../services/codeService'

// Blue print of the class component.  
interface anodeEditProps {
    id: string | number,
    name: string | number,
    anode_weight: string | number,
    sea_chest_anode_weight: string | number,
    price: string | number,
    electro_chemical: string | number,
    supplier: string | number,
    company: string | number,
}

interface FormData {
    txt_anodename: string,
    txt_anode_weight: string,
    txt_sea_chest_anode_weight: string,
    txt_price_kg: string,
    txt_electrochemical: string,
    txt_Editsupplier: string,
    txt_Editcompany: string,
}

const AnodeEdit = (props: any) => {
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const { match } = props;
    const { id } = match.params;
    const { cmpId } = match.params;
    const { supId } = match.params;
    const {supName} = match.params;
    const [loading, setLoading] = useState(false)
    const [anodeeditflag, setAnodeEditFlag] = React.useState(false)
    const [display, setDisplay] = React.useState<anodeEditProps>({
        id: '',
        name: '',
        anode_weight: '',
        sea_chest_anode_weight: '',
        price: '',
        electro_chemical: '',
        supplier: '',
        company: '',
    })
    const [anodes, setAnode] = useState<any>({
        supplier: '',
        company: '',
        companies: [],
        suppliers: [],
    },
    )

    const _getSupplierDetail = () => {
        return codeService.getSupplierDD().subscribe({
            next: r => {

                setAnode({ ...anodes, suppliers: r.data })
                r.data.map((data: any, i: any) => {
                    return anodes.suppliers.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    }
    const _getCompanyDetail = (value: any) => {
        // setAnode({...anode, supplier:value})
        var supplier_id: any = ''
        {
            anodes.suppliers.map((data:any, i:any) => {
                if (value === data.name) {
                    supplier_id = data.id
                    // console.log(city_Code,'city code')
                    codeService.getSupplierCompanyDD(supplier_id).subscribe({
                        next: r => {
                            // console.log(r.data)
                            setAnode({ ...anodes, companies: r.data, supplier: value })
                            r.data.map((data: any, i: any) => {
                                return anodes.companies.push(data)
                            })
                        },
                        error: err => {
                            console.error(err)
                        }
                    })
                }
                else {
                    setAnode({ ...anodes, supplier: value, company: '', companies: [] })
                }
            })
        }
    }
    // Used to get perticular ship detail and store to variable.   
    const _getAnodeDetail = ((id: string | number, cmpId: string | number, suppId: string | number) => {
        setLoading(true)
        return anodeService.getAnodeDetail({ company_id: cmpId, anode_id: id, supplier_id: suppId }).subscribe({
            next: r => {
                // console.log(r,'response')
                // console.log(r.data[0], 'data within response')
                const value = r.data
                // console.log(value, 'data within response using value')
                setDisplay({
                    id: r.data[0].anode_id,
                    name: r.data[0].anode_name,
                    anode_weight: r.data[0].anode_weights,
                    sea_chest_anode_weight: r.data[0].sea_chest_anodeweight,
                    price: r.data[0].price,
                    electro_chemical: r.data[0].electro_chemical,
                    supplier: r.data[0].supplier_name,
                    company: r.data[0].company_name,
                })
                setAnode({...anodes, supplier: r.data[0].supplier_name, company: r.data[0].company_name})
                props.anodeSetSelectedAction(value)
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    })
    React.useEffect(() => {
        _getAnodeDetail(id, cmpId, supId);
        _getSupplierDetail()
        _getCompanyDetail(supName)
    }, [])

    // After update all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call.  
    const _didAnodeEdit = () => {
        // e.preventDefault()
        var PaintsupId: string = ''
        var paintCmpId
        anodes.companies.map((data:any, i:any) => {
          if (anodes.company === data.name) {
            paintCmpId = data.id;
            console.log(paintCmpId,data.id,'company id')
          }
        })
        anodes.suppliers.map((data: any, i: any) => {
          if (anodes.supplier === data.name) {
            PaintsupId = data.id;
          }
        })
        const updatedForm = {
          anode_id: display.id,
          product_name: display.name,
          anode_weight: Number(display.anode_weight),
          sea_chest_anode_weight: Number(display.sea_chest_anode_weight),
          price_kg: Number(display.price),
          electro_chemical: Number(display.electro_chemical),
          supplier_id: Number(supId),
          company_id:  Number(cmpId),
        }
        // API call for updateship with data, updated into list page.  
        if (anodeeditflag === true) {
            setLoading(true)
            anodeService.updateAnode(updatedForm).subscribe({
                next: () => {
                    swal("Great", "Anode Updated Successfully", "success")
                    history.push('/anode/list')
                    setLoading(false)
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    console.error(err)
                    setLoading(false)
                }
            })
        }
        else {
            console.log(anodeeditflag)
        }

    }
    onsubmit = (e: any) => {
        // e.preventDefault()
        console.log(Object.keys(errors).length, 'error length')
        if (Object.keys(errors).length === 0) {
            _didAnodeEdit()
            setAnodeEditFlag(true)
        }
        else {
            setAnodeEditFlag(false)
        }
    }

    if (loading === true) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    return (
        <Main>
            <Text color='black' variant="title">Edit Anode Information</Text>
            <Space divheight={25} />
            {/* Edit form, having input box, select box.   */}
            <Grid templateCols='75% auto' className='add_page'>
                {console.log(anodes,'anodes')}
                <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='dockcmpAddForms'>
                    <Card title="Fill All the Information Below">
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Anode Name</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_anodename"
                                            value={display.name}
                                            onChange={(e) => setDisplay({ ...display, name: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder="Anode Name"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_anodename?.type === 'required' && 'Please enter Anode Name'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Anode weight (Kg)</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="number"
                                            name="txt_anode_weight"
                                            value={display.anode_weight}
                                            onChange={(e) => setDisplay({ ...display, anode_weight: e.target.value })}
                                            ref={register({ required: true, min: 0 })}
                                            placeholder='Anode Weight'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>
                                                {errors.txt_anode_weight?.type === 'required' && 'Please enter Anode Weight'}
                                                {errors.txt_anode_weight?.type === 'min' && 'Anode Weight should not be in Negative'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Sea chest's Anode Weight (Kg)</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="number"
                                            name="txt_sea_chest_anode_weight"
                                            value={display.sea_chest_anode_weight}
                                            onChange={(e) => setDisplay({ ...display, sea_chest_anode_weight: e.target.value })}
                                            ref={register({ required: true, min: 0 })}
                                            placeholder='Sea chest`s Anode Weight'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>
                                                {errors.txt_sea_chest_anode_weight?.type === 'min' && 'Sea chest`s Anode Weight should not be in Negative'}
                                                {errors.txt_sea_chest_anode_weight?.type === 'required' && 'Please enter Sea chest`s Anode Weight'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes >
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Price per Kg (Rp.)</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="number"
                                            name="txt_price_kg"
                                            value={display.price}
                                            onChange={(e) => setDisplay({ ...display, price: e.target.value })}
                                            ref={register({ required: true, min: 0 })}
                                            placeholder='Price per Kg'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>
                                                {errors.txt_price_kg?.type === 'required' && 'Please enter Price'}
                                                {errors.txt_price_kg?.type === 'min' && 'Price should not be in Negative'}

                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Electrochemical Efficiency</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="number"
                                            name="txt_electrochemical"
                                            value={display.electro_chemical}
                                            onChange={(e) => setDisplay({ ...display, electro_chemical: e.target.value })}
                                            ref={register({ required: true, min: 0 })}
                                            placeholder='Electrochemical'
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>
                                                {errors.txt_electrochemical?.type === 'required' && 'Please enter Electrochemical'}
                                                {errors.txt_electrochemical?.type === 'min' && 'Electrochemical should not be in Negative'}
                                            </span>
                                        </Col>
                                    </Row>
                                </Attributes>
                            </Col>
                        </Row>
                        <Row className='m-0 p-0'>
                            <Col className='m-0 p-0' xs='12' md='12' lg='12' xl='12'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Supporting Owner</Text>
                                        {display.supplier !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.supplier}</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold > - </Text>
                                        )}
                                        {/* <input className='basecontrol cutome-selectInputnxt'
                                            value={anodes.supplier} id="txt_Editsupplier"
                                            list='anodesupplier'
                                            placeholder='-- Select Supplier --'
                                            name="txt_Editsupplier" ref={register({ required: true, })}
                                            onChange={(e) => _getCompanyDetail(e.target.value)}>
                                        </input>
                                        <datalist id="anodesupplier">
                                            {anodes.suppliers.map((data: any, i: any) => {
                                                return <option key={i} value={data.name}></option>
                                            })}
                                        </datalist> */}
                                    </div>
                                    {/* <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_Editsupplier?.type === 'required' && 'Please Select Supporting Owner'}
                                            </span>
                                        </Col>
                                    </Row> */}
                                    <div>
                                        <Text color='tablehead' variant='subtitle' >Company Name</Text>
                                        {display.company !== null ? (
                                            <Text color='main' variant='subtitle' bold>{display.company}</Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold > - </Text>
                                        )}
                                        {/* <input className='basecontrol cutome-selectInputnxt'
                                            ref={register({ required: true, })}
                                            value={anodes.company}
                                            id='txt_Editcompany' list='company-details'
                                            placeholder='-- Select Supplier First --'
                                            name="txt_Editcompany" onChange={(e) => setAnode({ ...anodes, company: e.target.value })} >
                                        </input>
                                        <datalist id="company-details">
                                            {anodes.companies.length === 0 && (
                                                <option className='option-placeholder'> --NO Company for selected Supplier-- </option>
                                            )}
                                            {anodes.companies.map((data: any, i: any) => {
                                                return <option key={i} value={data.name}></option>
                                            })}
                                        </datalist> */}
                                    </div>
                                    {/* <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_Editcompany?.type === 'required' && 'Please select Company'}
                                            </span>
                                        </Col>
                                    </Row> */}
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                    <Actions>
                        {/* If you click the back button its is redirect to anodes list page.  */}
                        <Link to='/anode/list'>
                            <Button className='bttn bttn-transparent'>
                                <Flex>
                                    <Icons color="#ffffff" icon="arrow_back" />
                                    Back
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the save button its is call the function, in that data added to list and redirect to list page.  */}
                        <Button className='bttn bttn-transparent' type="submit">
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
        </Main>
    )
}


export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style.  
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-ship-add');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}

// Button style.
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    ship: state.anode.selectedAnode
})
//Return the selected ship details.  
export default connect(
    mapStateToProps,
    {
        anodeSetSelectedAction
    }
)(AnodeEdit)
