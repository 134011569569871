import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import './DryDock.scss'
import Filterimg from '../../../assets/images/Filter 2.png'
import { Main } from '../../../common/components/main/Main'
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap'
import { Table } from '../../../common/components/table/Table'
import { Button } from '../../../common/components/button/Button'
import { Text } from '../../../common/components/text/Text'
import Card from '../../../common/components/card/Card'
import { Flex } from '../../../common/components/flex/Flex'
import { Space } from '../../../common/components/space/Space'
import Icons from '../../../common/components/icon/Icon';
import { history } from '../../../store'
import classNames from 'classnames';
import { dockListGetAction, dockSetSelectedAction } from '../../../store/action/dockActions';
import { connect } from 'react-redux';
// import { format, parseISO } from 'date-fns'
import { ReactNode } from 'react';
import swal from 'sweetalert'
import { InputWithIcon } from '../../../common/components/control/Control';
import FilterModal from './pageContainer/FilterModal'
import { FilterState } from '../filter/filterState'
import Loader from 'react-loader-spinner';
import { dockService } from '../../../services/dockService';
import ImportExcel from './pageContainer/ImportExcel'
import sortup from '../../../assets/images/sortup_icon.png'
import sortdown from '../../../assets/images/sortdown_icon.png'
import sortimage from '../../../assets/images/sorting.png'
import { type } from 'os';


const DockList = (props: any) => {

  const { docks, isLoading, totalElement } = props
  const [displayDocks, setDocks] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [searchQuery, setQuery] = React.useState('')
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const [showModal, setModal] = React.useState(false)
  const [showImportModal, setImportModal] = React.useState(false)
  const [searchInput, setSearchInput] = React.useState(FilterState.dockFilter.searchBoxInputDock)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  const { form } = props
  const { match } = props
  const { fromview } = match.params
  const [showDelete, setShow] = React.useState(false);
  const [deleteDock, setDeleteDock] = React.useState(0)
  const [sortimg, setSortimg] = React.useState(sortimage)
  const [docknamesortimg, setSorting]=React.useState({values:FilterState.dockFilter.type,direction:FilterState.dockFilter.direction})
  

  var { pgnum } = match.params

  console.log(displayDocks);
  React.useEffect(() => {
    if(pgnum){
      setPage(Number(pgnum)) 
      history.push('/docks/list')
      _goToPage(Number(pgnum))
      console.log('useeffect',FilterState.dockFilter.type,FilterState.dockFilter.direction);
      props.dockListGetAction({
        size: 10, page:pgnum,
        companyName: FilterState.dockFilter.companyName || searchInput || FilterState.dockFilter.searchBoxInputDock,
        cityName: String(FilterState.dockFilter.cityName),
        countryName: String(FilterState.dockFilter.countryName),
        length: FilterState.dockFilter.length,
        width: FilterState.dockFilter.width,
        draft: FilterState.dockFilter.draft,
        capacity: FilterState.dockFilter.capacity,
        status: FilterState.dockFilter.status,
        type: docknamesortimg.values,
        direction: docknamesortimg.direction
      })
    //  setPage(pgnum) 
    //  history.push('/docks/list')
    //  pgnum=0
    }else{
      console.log('useeffect',FilterState.dockFilter.type,FilterState.dockFilter.direction);
    props.dockListGetAction({
      size: 10, page,
      companyName: FilterState.dockFilter.companyName || searchInput || FilterState.dockFilter.searchBoxInputDock,
      cityName: String(FilterState.dockFilter.cityName),
      countryName: String(FilterState.dockFilter.countryName),
      length: FilterState.dockFilter.length,
      width: FilterState.dockFilter.width,
      draft: FilterState.dockFilter.draft,
      capacity: FilterState.dockFilter.capacity,
      status: FilterState.dockFilter.status,
      type: FilterState.dockFilter.type,
      direction: FilterState.dockFilter.direction
    })
  }
  }, [page, searchQuery])

  const filterByValue = (sortvariable: string) => {
    props.dockListGetAction({ size: 10, page, companyName: searchInput })
  }

  const _willFilter = () => {
    setModal(true)
  }
  const _willImport = () => {
    setImportModal(true)
  }
  React.useEffect(() => {
    let type;
    type = localStorage.getItem('type')
    if(type === 'success'){
      swal("Great", "DryDock Imported Successfully", "success")
      localStorage.removeItem('type')
    } else if(type === 'no_new'){
      swal("Great", "No New Records To Import Data", "success")
      localStorage.removeItem('type')
    }
    if (docks && docks.length > 0) {
      setDocks(() => {
        return docks.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (docks && docks.length === 0) {
      setDocks([])
    }
  }, [docks])

  React.useEffect(() => {
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])


  const _searchFuntion = (value: string) => {
    setSearchInput(value);
    FilterState.dockFilter.searchBoxInputDock = value;
    
    props.dockListGetAction({
      size: 10, page: 0, companyName: value,
      cityName: String(FilterState.dockFilter.cityName),
      countryName: String(FilterState.dockFilter.countryName),
      length: FilterState.dockFilter.length,
      width: FilterState.dockFilter.width,
      draft: FilterState.dockFilter.draft,
      capacity: FilterState.dockFilter.capacity,
      status: FilterState.dockFilter.status
    })
    setPage(0)
  }
  
  const _showAll = () => {
    props.dockListGetAction({ size: 10, page: 0 })
    FilterState.dockFilter.companyName = ''
    FilterState.dockFilter.searchBoxInputDock=''
    FilterState.dockFilter.cityName = ''
    FilterState.dockFilter.countryName = ''
    FilterState.dockFilter.length = ''
    FilterState.dockFilter.width = ''
    FilterState.dockFilter.draft = ''
    FilterState.dockFilter.capacity = ''
    FilterState.dockFilter.status = ''
    FilterState.dockFilter.countryNames = ''
    FilterState.dockFilter.page = 0
    FilterState.dockFilter.size = 10
    setSearchInput('')
    setSorting({values:'',direction:''})
  }

  const role = localStorage.getItem("role")

  const _willViewDetail = (dock: any, pgnum: number) => {
    dockSetSelectedAction(dock)
    history.push(`/docks/${dock.id}/${pgnum}`)
  }

  const _willViewEdit = (dock: any, pgnum: number) => {
    dockSetSelectedAction(dock)
    history.push(`/docks/edit/${dock.id}/${pgnum}`)
  }

  const _willCloseModal = () => {
    setModal(false)
  }

  const _willCloseImport = (type: any) => {
    if(type){
      localStorage.setItem('type',type)
      window.location.reload()
    }
    
    setImportModal(false)
    
  }

  //Delete dock
  // Delete the pericular list.
  const handleClose = () => setShow(false);
  const handleShow = (dock: any) => {
    setShow(true);
    setDeleteDock(dock.id)
    // console.log(deleteAnode, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deleteDock(deleteDock)
  }

  const _deleteDock = (id: any) => {
    return dockService.deleteDock(id).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.dockListGetAction({ size: 10, page })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }


  // Prints first page pagination. 
  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  // While click the last page its move to last page. 
  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 9)
  }

  // While click the first button its move to first page. 
  const _goToFirstPage = () => {
    setPage(0)
    setHigh(9)
    setLow(1)
  }

  // Prints last page pagination button. 
  const lastPage = () => {
    
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  // Prints prev button. 
  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  // While click prev button go to the previous page of current page. 
  const _goPrevPage = () => {
    history.push('/docks/list')
    setPage(prev => {
      const newValue = prev - 1
      return newValue
    })
  }

  // Print next button. 
  const nextBtn = () => {
    if (page < totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  // While click next page button go to the next page of the current page. 
  const _goNextPage = () => {
    history.push('/docks/list')
    setPage(prev => {
      const newValue = prev + 1
      return newValue
    })
  }

  // While click one page number its move to that page. 
  const _goToPage = (page: any) => {
    history.push('/docks/list')
    setPage(page)
  }

  // Return the page number
  const ListItem = (page: any) => {
    return page.value + 1
  }

  // Prints the totalpages between prev, next button. 
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })


  
  console.log(docknamesortimg);
const sortByFieldn=(value:String,directions:String)=>{
if(directions==='asc')
{
  setSorting({values:String(value),direction:String(directions)});
  FilterState.dockFilter.type=String(value);
  FilterState.dockFilter.direction=String(directions);
  props.dockListGetAction({
    size: 10, page: 0, companyName: FilterState.dockFilter.searchBoxInputDock,
    cityName: String(FilterState.dockFilter.cityName),
    countryName: String(FilterState.dockFilter.countryName),
    length: FilterState.dockFilter.length,
    width: FilterState.dockFilter.width,
    draft: FilterState.dockFilter.draft,
    capacity: FilterState.dockFilter.capacity,
    status: FilterState.dockFilter.status,
    type: value,
    direction:directions
  })  
  setPage(0); 
}
else if(directions==='desc')
{
  setSorting({values:String(value),direction:String(directions)});
  FilterState.dockFilter.type=String(value);
  FilterState.dockFilter.direction=String(directions);
  props.dockListGetAction({
    size: 10, page: 0, companyName: FilterState.dockFilter.searchBoxInputDock,
    cityName: String(FilterState.dockFilter.cityName),
    countryName: String(FilterState.dockFilter.countryName),
    length: FilterState.dockFilter.length,
    width: FilterState.dockFilter.width,
    draft: FilterState.dockFilter.draft,
    capacity: FilterState.dockFilter.capacity,
    status: FilterState.dockFilter.status,
    type: value,
    direction:directions
  })  
  setPage(0);
}
else if(directions==='')
{
  setSorting({values:String(value),direction:String(directions)});
  FilterState.dockFilter.type=String(value);
  FilterState.dockFilter.direction=String(directions);
  props.dockListGetAction({
    size: 10, page: 0, companyName: FilterState.dockFilter.searchBoxInputDock,
    cityName: String(FilterState.dockFilter.cityName),
    countryName: String(FilterState.dockFilter.countryName),
    length: FilterState.dockFilter.length,
    width: FilterState.dockFilter.width,
    draft: FilterState.dockFilter.draft,
    capacity: FilterState.dockFilter.capacity,
    status: FilterState.dockFilter.status,
    type: value,
    direction:directions
  })  
  setPage(0);
}
};

  return (
    <Main>
      <Text variant="title">DryDock List</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-3 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    <InputWithIcon
                      type="text"
                      value={searchInput}
                      onChange={(e) => _searchFuntion(e.target.value)}
                      placeholder="Search by Dock Company Name.."
                      icon="search"
                      id="search-input"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='6' xl='6' className='m-0 p-0'>
              
              <div className='search-btn-container'>
                <div className='search-btn-add'>
                  {role === 'VISITOR' ? (
                    // <Link to='/docks/add'>   {/* Button add new dock, if you click the button it shows modal box. */}
                    <Button variant='transparent' block={true} disabled>
                      <Icons color="#ffffff" icon="add" />
                      ADD NEW DOCK
                    </Button>
                    //</Link>
                  ) : (<Link to='/docks/add'>   {/* Button add new dock, if you click the button it shows modal box. */}
                    <Button variant='transparent' block={true}>
                      <Icons color="#ffffff" icon="add" />
                      ADD NEW DOCK
                    </Button>
                  </Link>)}
                  {role === 'VISITOR' ? (
                  <Button variant='transparent' className='ml-1' onClick={() => _willImport()} disabled>
                    Import
                  </Button>
                  ):(
                    <Button variant='transparent' className='ml-1' onClick={() => _willImport()}>
                    Import
                  </Button>
                  )}
                </div>
                <div className='search-btn-filter'>
                  <Button className='link_button filter' onClick={() => _willFilter()}>
                    <img src={Filterimg} alt='filterimg' />
                  </Button>
                </div>
                <div className='search-btn-showall'>
                  <Button variant='transparent' block={true} onClick={() => _showAll()}>
                    Show All
                  </Button>
                </div>
              </div>
            </Col>
            {/* <Col xs='12' sm='12' md='12' lg='2' xl='2' className='m-0 p-0'>
              <div className='mr-3 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    <select name="" id="search-input">
                      <option value="">--Sort By--</option>
                    </select>
                  </Col>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    <select name="" id="search-input">
                      <option value="">--Sort By--</option>
                    </select>
                  </Col>
                </Row>
              </div>
            </Col> */}
            
          </Row>
        </SearchForm>
        <Space divheight={30} />
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>}
        {!isLoading && displayDocks.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No dock found</Text>
            <Space divheight={20} />
          </>
        )}
        {!isLoading && displayDocks.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>
                    Drydock Name &nbsp;
                     {docknamesortimg.values==='dockname' && docknamesortimg.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('dockname','desc')} src={sortup}/>
                     ):(null)}
                     {docknamesortimg.values==='' || docknamesortimg.values!=='dockname' ? (
                     <img onClick={() => sortByFieldn('dockname','asc')} src={sortimage}/>):(null)}
                     {docknamesortimg.values==='dockname' && docknamesortimg.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                     </th>
                  {/* <th>Join Date</th> */}
                  <th>
                    Drydock Company &nbsp;
                    {docknamesortimg.values==='companyname' && docknamesortimg.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('companyname','desc')} src={sortup}/>
                     ):(null)}
                     {docknamesortimg.values==='' || docknamesortimg.values!=='companyname' ? (
                     <img onClick={() => sortByFieldn('companyname','asc')} src={sortimage}/>):(null)}
                     {docknamesortimg.values==='companyname' && docknamesortimg.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Country &nbsp;
                  {docknamesortimg.values==='countryname' && docknamesortimg.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('countryname','desc')} src={sortup}/>
                     ):(null)}
                     {docknamesortimg.values==='' || docknamesortimg.values!=='countryname' ? (
                     <img onClick={() => sortByFieldn('countryname','asc')} src={sortimage}/>):(null)}
                     {docknamesortimg.values==='countryname' && docknamesortimg.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>City
                  &nbsp;
                  {docknamesortimg.values==='cityname' && docknamesortimg.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('cityname','desc')} src={sortup}/>
                     ):(null)}
                     {docknamesortimg.values==='' || docknamesortimg.values!=='cityname' ? (
                     <img onClick={() => sortByFieldn('cityname','asc')} src={sortimage}/>):(null)}
                     {docknamesortimg.values==='cityname' && docknamesortimg.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>
                    Drydock Type
                    &nbsp;
                    {docknamesortimg.values==='docktype' && docknamesortimg.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('docktype','desc')} src={sortup}/>
                     ):(null)}
                     {docknamesortimg.values==='' || docknamesortimg.values!=='docktype' ? (
                     <img onClick={() => sortByFieldn('docktype','asc')} src={sortimage}/>):(null)}
                     {docknamesortimg.values==='docktype' && docknamesortimg.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  {/* <th>Unit</th>
                  <th className='mr-2'>Length</th>
                  <th>Width</th>
                  <th>Draft</th>
                  <th>Capacity</th> */}
                  <th className="text-center">
                    Status
                    &nbsp;
                    {docknamesortimg.values==='status' && docknamesortimg.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('status','desc')} src={sortup}/>
                     ):(null)}
                     {docknamesortimg.values==='' || docknamesortimg.values!=='status' ? (
                     <img onClick={() => sortByFieldn('status','asc')} src={sortimage}/>):(null)}
                     {docknamesortimg.values==='status' && docknamesortimg.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {displayDocks.map((dock: any, i: any) => [
                  <tr key={dock.id}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='Drydock Name'>{dock.dockname}</td>
                    {/* <td data-label='Join Date'>{format(parseISO(dock.created_at), 'dd MMM yy, hh:mm')}</td> */}
                    <td data-label='Drydock Company'>{dock.companyname}</td>
                    <td data-label='Country'>{dock.country}</td>
                    <td data-label='City'>{dock.cityname}</td>
                    <td data-label='Drydock Type'>{dock.docktype}</td>
                    {/* <td data-label='Unit'>{dock.unit}</td>
                    <td data-label='Length'>{dock.length}</td>
                    <td data-label='Width'>{dock.width}</td>
                    <td data-label='Draft'>{dock.draft}</td>
                    <td data-label='Capacity'>{dock.capacity}</td> */}
                    <td data-label='Status'>{dock.verified === 'VERIFIED' ? (
                      <Text variant='subtitle' color='warning'>Verified</Text>
                    ) : (
                      <Text variant='subtitle' color='danger'>Not Verified</Text>
                    )}
                    </td>
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="dock-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(dock,page)}>
                            <Icons color="#52575a" icon="visibility" />
                          </Button>
                        </OverlayTrigger>
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="dock-edit-visitor"> Edit option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(dock,page)} disabled>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="dock-edit"> Edit
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(dock,page)}>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="dock-delete">Delete option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(dock)} disabled>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="dock-delete"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(dock)}>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </TableActions>
                    </td>
                  </tr>
                ])}
              </tbody>
            </Table>
            <TableFooter>
              <Text>
                {displayDocks.length === 1 && (
                  <>
                    <b>{displayDocks.length}
                      {/* {((page) * 10) + 1} - {(displayDocks.length + (page) * 10)} */}
                    </b> of {totalElement} Dock
                  </>
                )}
                {displayDocks.length > 1 && (
                  <>
                    <b>{displayDocks.length}
                      {/* {((page) * 10) + 1} - {(displayDocks.length + (page) * 10)} */}
                    </b> of {totalElement} Docks
                  </>
                )}
                {/* {console.log(totalElement, 'Total Row')} */}

              </Text>
              <Pagination size="sm">
                {firstPage()}
                {prevBtn()}
                {PageNumber}
                {nextBtn()}
                {lastPage()}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {showModal && <FilterModal onClose={() => _willCloseModal()} filterDocks={displayDocks} />}
      {showImportModal && <ImportExcel onClose={(type: any) => _willCloseImport(type)} />}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form Dock list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Main>
  )
}

export interface TablefooterProps {
  children?: React.ReactNode
}

const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

//Search Form Component  for displaying serarch box, and add dock button
const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

const mapStateToProps = (state: any) => ({
  docks: state.dock.docks,
  isLoading: state.dock.isLoading,
  error: state.dock.error,
  totalElement: state.dock.totalElement,
  totalPage: state.dock.totalPage
})

export default connect(
  mapStateToProps,
  {
    dockListGetAction,
    dockSetSelectedAction
  }
)(DockList)