import http from '../helpers/http'
import { map } from 'rxjs/operators'

const getCode = ( type : any) => {
  return http({
    method: 'GET',
    url: `/codes/${type}`
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getProvincesDD = () => {
  return http({
    method: 'GET',
    url: `/provinces/list`,
  }).pipe(
    map(res => {
      // console.log(res)
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}


const getAdsTypes = () => {
  return http({
    method: 'GET',
    url: `/manufacturer/products`,
  }).pipe(
    map(res => {
      // console.log(res)
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}


const getShipTypes = () => {
  return http({
    method: 'GET',
    url: `/shipTypes`,
  }).pipe(
    map(res => {
      // console.log(res)
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getShipSubTypes = (id : any) => {
  return http({
    method: 'GET',
    url: `/subShipTypes/list/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getShipMaterial = () => {
  return http({
    method: 'GET',
    url: `/shipMaterial/drop`,
  }).pipe(
    map(res => {
      // console.log(res)
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getTolerance = () => {
  return http({
    method: 'GET',
    url: `/docks/tolerance`,
  }).pipe(
    map(res => {
      // console.log(res)
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getDockTypes = () => {
  return http({
    method: 'GET',
    url: `/dockType`,
  }).pipe(
    map(res => {
      // console.log(res)
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getShipClassification = () => {
  return http({
    method: 'GET',
    url: `/shipClassification`,
  }).pipe(
    map(res => {
      // console.log(res,'ship classification')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getCountries = () => {
  return http({
    method: 'GET',
    url: `/countries`,
  }).pipe(
    map(res => {
      // console.log(res,'contries')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getRoles = () => {
  return http({
    method: 'GET',
    url: `/roles`,
  }).pipe(
    map(res => {
      // console.log(res,'roles')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getAdminPage = () => {
  return http({
    method: 'GET',
    url: `/users/getAdminPage`,
  }).pipe(
    map(res => {
      // console.log(res,'roles')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getUserRoles = (type: any) => {
  return http({
    method: 'GET',
    url: `/roles/searchPage/${type}`,
  }).pipe(
    map(res => {
      // console.log(res,'roles')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getPorts = (params : any) => {
  return http({
    method: 'GET',
    url: `/ports`,
    params
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getCompanyTypedd = () => {
  return http({
    method: 'GET',
    url: `/companyType`,
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res,'company Type')
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getAllPorts = () => {
  return http({
    method: 'GET',
    url: `/ports`,
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res,'company Type')
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getCompanies = (params : any) => {
  return http({
    method: 'GET',
    url: `/companies/CompanyType`,
    params
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res,'company Type')
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getAvailableCompanies = (params : any) => {
  return http({
    method: 'GET',
    url: `/companies/available_company`,
    params
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res,'company Type')
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getOwners = (params : any) => {
  return http({
    method: 'GET',
    url:`/users/owners`,
    params
  }).pipe(
    map(res => {
      if(res.success){
        // console.log(res,'Owner Type')
        return res
      }
      else{
        throw new Error(res.msg)
      }
    })
  )
}

const getunits = (params :any) => {
  return http({
    method: 'GET',
    url:`/units`,
    params
  }).pipe(
    map(res => {
      if(res.success){
        // console.log(res, 'units value')
        return res
      }
      else{
        throw new Error(res.msg)
      }
    })
  )
}
const getCities = () => {
  return http({
    method: 'GET',
    url: `/cities/CityDrop`,
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getCitiesDrop = () => {
  return http({
    method: 'GET',
    url: `/cities/CityDrop`,
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getCountryCities = (params : any) => {
  // console.log(params,'params cities withiin country')
  return http({
    method: 'GET',
    url: `/cities/countries`,
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in cities in getCountries')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getSettingport = (val: string) => {
  console.log(val,'value port')
  return http({
    method: 'GET',
    url: `/ports/${val}`,
  }).pipe(
    map(res => {
      // console.log(res,'response in cities in getCountries')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getSpecifications = (params : any) => {
  return http({
    method: 'GET',
    url: `/services`,
    params
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getSlotdd = () => {
  // console.log(params,'params cities withiin country')
  return http({
    method: 'GET',
    url: `/advertisement/slot`,
  }).pipe(
    map(res => {
      // console.log(res,'response in cities in getCountries')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getProductdd = () => {
  // console.log(params,'params cities withiin country')
  return http({
    method: 'GET',
    url: `/advertisement/product_type`,
  }).pipe(
    map(res => {
      // console.log(res,'response in cities in getCountries')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}
const getMobileCode = () => {
  return http({
    method: 'GET',
    url: `/countries/mobileCode`,
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res,'company Type')
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}
const getAllCompanyDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/manufacturer/companies/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}
const getsupplierDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/manufacturer/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getSupplierDD = () => {
  return http({
    method: 'GET',
    url: `/manufacturer/getSupplier`,
  }).pipe(
    map(res => {
      // console.log(res)
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getSupplierCompanyDD = (id : any) => {
  return http({
    method: 'GET',
    url: `/manufacturer/getCompanies/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const getCountryProvinces = (id : any) => {
  return http({
    method: 'GET',
    url: `/provinces/listCountries/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const codeService = {
  getCode,
  getCountries,
  getDockTypes,
  getRoles,
  getShipClassification,
  getShipTypes,
  getOwners,
  getPorts,
  getCompanies,
  getCities,
  getCitiesDrop,
  getCountryCities,
  getSpecifications,
  getunits,
  getSlotdd,
  getProductdd,
  getCompanyTypedd,
  getAvailableCompanies,
  getSettingport,
  getMobileCode,
  getTolerance,
  getShipMaterial,
  getShipSubTypes,
  getAdsTypes,
  getProvincesDD,
  getAllCompanyDetail,
  getsupplierDetail,
  getSupplierDD,
  getAllPorts,
  getSupplierCompanyDD,
  getCountryProvinces,
  getUserRoles,
  getAdminPage
}

export default codeService
