import {
    CITY_LIST_GET,
}  from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import {
    cityListGetFAction,
    cityListGetRAction,
} from '../action/cityAction'
import cityService from '../../services/cityService'

export const getCityEpics = (action : any) => {
  // console.log('get role epic called')
  return action.pipe(
    ofType(CITY_LIST_GET),
    switchMap((action : any) => {
      return cityService.getCity(action.payload).pipe(
        map((res :any) => {
        // console.log(res,'response for country epics')
        const { content, total_page, total_element } = res.data
        // console.log(content,'country content')
        return cityListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(cityListGetRAction(err)))
      )
    })
  )
}