import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Filterimg from '../../../assets/images/Filter 2.png'
import './DryDockCompanies.scss'
import { Main } from '../../../common/components/main/Main'
import { Carousel, Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap'
import { Table } from '../../../common/components/table/Table'
import { Button } from '../../../common/components/button/Button'
import { Text } from '../../../common/components/text/Text'
import Card from '../../../common/components/card/Card'
import { Flex } from '../../../common/components/flex/Flex'
import { Space } from '../../../common/components/space/Space'
import Icons from '../../../common/components/icon/Icon';
import { history } from '../../../store'
import classNames from 'classnames';
import { dockCompanyListGetAction, dockCompanySetSelectedAction } from '../../../store/action/dockActions';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns'
import { ReactNode } from 'react';
import { dockService } from '../../../services/dockService';
import swal from 'sweetalert'
import DockView from './pageContainer/DockView'
import { InputWithIcon } from '../../../common/components/control/Control';
import DockCmpFilter from './pageContainer/DockCmpFilter'
import { FilterState } from '../filter/filterState'
import Loader from 'react-loader-spinner';
import sortup from '../../../assets/images/sortup_icon.png'
import sortdown from '../../../assets/images/sortdown_icon.png'
import sortimage from '../../../assets/images/sorting.png'
import { Filter } from '@material-ui/icons';


const DryDockCompanies = (props: any) => {

  const { companies, isLoading, totalElement } = props
  const { form } = props
  const { match } = props
  const [displayCompanies, setCompanies] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [showModal, setModal] = React.useState(false)
  const [showDock, dockModal] = React.useState(false)
  const [searchQuery, setQuery] = React.useState('')
  const [searchInput, setSearchInput] = React.useState(FilterState.cmpFilter.searchBoxInputCmp)
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  const [showDelete, setShow] = React.useState(false);
  const [deleteDockCmp, setDeleteDockCmp] = React.useState(0)
  const [sortimg, setSortimg] = React.useState(sortup)
  const [sort, setSorting]=React.useState({values:FilterState.cmpFilter.type,direction:FilterState.cmpFilter.direction})


  const role = localStorage.getItem("role");

  var { pgnum } = match.params

  
  React.useEffect(() => {
    if(pgnum){
      setPage(Number(pgnum)) 
      history.push('/comapanies/company-type')
      _goToPage(Number(pgnum))
//      console.log(pgnum, 'setpage')
 //     console.log(page, 'page')
      //setPage(pgnum)
      props.dockCompanyListGetAction({
        fromDate: FilterState.cmpFilter.fromDate,
        toDate: FilterState.cmpFilter.toDate,
        status: FilterState.cmpFilter.status,
        claimedStatus: FilterState.cmpFilter.claimedStatus,
        country: FilterState.cmpFilter.country,
        //  countryNames:FilterState.cmpFilter.countryNames,
        city: FilterState.cmpFilter.city,
        companyname: FilterState.cmpFilter.cmp || searchInput || FilterState.cmpFilter.searchBoxInputCmp,
        type:FilterState.cmpFilter.type,
        direction:FilterState.cmpFilter.direction,
        page,
        size: 10,
        //   serachBoxInputCmp: FilterState.cmpFilter.serachBoxInputCmp
      })
    }else{
    props.dockCompanyListGetAction({
      fromDate: FilterState.cmpFilter.fromDate,
      toDate: FilterState.cmpFilter.toDate,
      status: FilterState.cmpFilter.status,
      claimedStatus: FilterState.cmpFilter.claimedStatus,
      country: FilterState.cmpFilter.country,
      //  countryNames:FilterState.cmpFilter.countryNames,
      city: FilterState.cmpFilter.city,
      companyname: FilterState.cmpFilter.cmp || searchInput || FilterState.cmpFilter.searchBoxInputCmp,
      type:FilterState.cmpFilter.type,
        direction:FilterState.cmpFilter.direction,
      page,
      size: 10,
      //   serachBoxInputCmp: FilterState.cmpFilter.serachBoxInputCmp
    })
  }
  }, [page, searchQuery,sort])

  // React.useEffect(() => {
  //   _getCompanies({ page, q: searchQuery })
  // }, [page, searchQuery])
  // // console.log(props, 'props in company list')

  React.useEffect(() => {
    if (companies && companies.length > 0) {
      setCompanies(() => {
        return companies.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (companies && companies.length === 0) {
      setCompanies([])
    }
  }, [companies])
  // console.log(props, 'props')
  // console.log(form,'company filter')
  React.useEffect(() => {
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  const _getCompanies = (params: any) => {
    // console.log(page,'page in docklist get action')
    // console.log(FilterState.cmpFilter,'cmp filter in drydock companies')
    props.dockCompanyListGetAction({
      page, size: 10,
      companyname: FilterState.cmpFilter.cmp || searchInput || FilterState.cmpFilter.searchBoxInputCmp,
      city: FilterState.cmpFilter.city,
      country: FilterState.cmpFilter.country,
      fromDate: String(FilterState.cmpFilter.fromDate),
      toDate: String(FilterState.cmpFilter.toDate),
      status: FilterState.cmpFilter.status,
      claimedstatus: FilterState.cmpFilter.claimedStatus,
      //    serachBoxInputCmp: FilterState.cmpFilter.serachBoxInputCmp
    })
  }

  const _searchFuntion = (value: string) => {
    setSearchInput(value)
    FilterState.cmpFilter.searchBoxInputCmp = value
  //  localStorage.setItem("temp",value)

    props.dockCompanyListGetAction({
      page, size: 10, companyname: value,
      city: FilterState.cmpFilter.city,
      country: FilterState.cmpFilter.country,
      fromDate: String(FilterState.cmpFilter.fromDate),
      toDate: String(FilterState.cmpFilter.toDate),
      status: FilterState.cmpFilter.status,
      claimedstatus: FilterState.cmpFilter.claimedStatus,

    })
    setPage(0)
    // setLow(1)
    // setHigh(10)
  }
  const _willViewDetail = (company: any, pgnum: number) => {
    props.dockCompanySetSelectedAction(company)
    history.push(`/dock-companies/${company.companyid}/${pgnum}`)
  }

  const _willFilter = () => {
    setModal(true)
  }

  const _willviewDock = (detail: any) => {
    props.dockCompanySetSelectedAction(detail)
    dockModal(true)
  }
  const _willCloseModal = () => {
    setModal(false)
  }
  const _willCloseDock = () => {
    dockModal(false)
  }

  const _willViewEdit = (company: any, pgnum: number, key: string) => {
    // console.log(key,'key')
    if (key === '') {
      var keyval = ''
    }
    else {
      var keyval = key
    }
    props.dockCompanySetSelectedAction(company)
    history.push(`/dock-companies/edit/${company.companyid}/${pgnum}`)
//        var temp=(localStorage.getItem("temp")===null?''||localStorage.getItem("temp"))
  //      FilterState.cmpFilter.searchBoxInputCmp=temp
  }
  const _willclaimedReq = (company: any) => {
    props.dockCompanySetSelectedAction(company)
    history.push(`/docks/clim/${company.companyid}`)
  }

  const _willPendingReq = (company: any) => {
    props.dockCompanySetSelectedAction(company)
    history.push(`/docks/pending/${company.companyid}`)
  }

  const handleClose = () => setShow(false);
  const handleShow = (company: any) => {
    setShow(true);
    setDeleteDockCmp(company.companyid)
    // console.log(deleteAnode, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deleteDockCmp(deleteDockCmp)
  }

  const _deleteDockCmp = (id: any) => {
    return dockService.deleteDockCompany(id).subscribe({
      next: (r) => {
        setShow(false)
        // console.log(r, 'response')
        props.dockCompanyListGetAction({
          page, size: 10,
          companyname: FilterState.cmpFilter.cmp || searchInput || FilterState.cmpFilter.searchBoxInputCmp,
          city: FilterState.cmpFilter.city,
          country: FilterState.cmpFilter.country,
          fromDate: String(FilterState.cmpFilter.fromDate),
          toDate: String(FilterState.cmpFilter.toDate),
          status: FilterState.cmpFilter.status,
          claimedstatus: FilterState.cmpFilter.claimedStatus,
        })
        swal("Great", " Deleted Successfully", "success")
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }

  const _showAll = () => {
    props.dockCompanyListGetAction({ page: 0, size: 10 })
    setSearchInput('')
    FilterState.cmpFilter.fromDate = ''
    FilterState.cmpFilter.toDate = ''
    FilterState.cmpFilter.status = ''
    FilterState.cmpFilter.claimedStatus = ''
    FilterState.cmpFilter.country = ''
    FilterState.cmpFilter.city = ''
    FilterState.cmpFilter.cmp = ''
    FilterState.cmpFilter.countryNames = ''
    FilterState.cmpFilter.page = 0
    FilterState.cmpFilter.size = 10
    FilterState.cmpFilter.searchBoxInputCmp = ''
    localStorage.removeItem("temp")
    setSorting({values:'',direction:''})
  }

  //first page
  const firstPage = () => {
    if (page >= 1) {
      // console.log(page,'page in first page')
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  // While click the last page its move to last page.  
  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 9)

  }

  // While click the first button its move to first page.  
  const _goToFirstPage = () => {
    setPage(0)
    setHigh(9)
    setLow(1)
  }

  // Prints last page pagination button.  
  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  // Prints prev button.  
  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  // While click prev button go to the previous page of current page.  
  const _goPrevPage = () => {
    history.push('/comapanies/company-type')
    setPage(prev => {
      const newValue = prev - 1
      return newValue
    })
  }

  // Print next button.  
  const nextBtn = () => {
    if (page < totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  // While click next page button go to the next page of the current page.  
  const _goNextPage = () => {
    history.push('/comapanies/company-type')
    setPage(prev => {
      const newValue = prev + 1
      return newValue
    })
  }

  // While click one page number its move to that page.  
  const _goToPage = (page: any) => {
    history.push('/comapanies/company-type')
    setPage(page)
  }

  // Return the page number
  const ListItem = (page: any) => {
    return page.value + 1
  }

  // Prints the totalpages between prev, next button.  
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })

  const sortByFieldn=(value:String,directions:String)=>{
    if(directions==='asc')
    {
      setSorting({values:String(value),direction:String(directions)});
      FilterState.cmpFilter.type=String(value);
      FilterState.cmpFilter.direction=String(directions);
      props.dockCompanyListGetAction({
        page, size: 10, companyname: value,
        city: FilterState.cmpFilter.city,
        country: FilterState.cmpFilter.country,
        fromDate: String(FilterState.cmpFilter.fromDate),
        toDate: String(FilterState.cmpFilter.toDate),
        status: FilterState.cmpFilter.status,
        claimedstatus: FilterState.cmpFilter.claimedStatus,
        type:value,
        direction:directions,
      })
       
      setPage(0); 
    }
    else if(directions==='desc')
    {
      setSorting({values:String(value),direction:String(directions)});
      FilterState.cmpFilter.type=String(value);
      FilterState.cmpFilter.direction=String(directions);
      props.dockCompanyListGetAction({
        page, size: 10, companyname: value,
        city: FilterState.cmpFilter.city,
        country: FilterState.cmpFilter.country,
        fromDate: String(FilterState.cmpFilter.fromDate),
        toDate: String(FilterState.cmpFilter.toDate),
        status: FilterState.cmpFilter.status,
        claimedstatus: FilterState.cmpFilter.claimedStatus,
        type:value,
        direction:directions,
      })
      setPage(0);
    }
    else if(directions==='')
    {
      setSorting({values:String(value),direction:String(directions)});
      FilterState.cmpFilter.type=String(value);
      FilterState.cmpFilter.direction=String(directions);
      props.dockCompanyListGetAction({
        page, size: 10, companyname: value,
        city: FilterState.cmpFilter.city,
        country: FilterState.cmpFilter.country,
        fromDate: String(FilterState.cmpFilter.fromDate),
        toDate: String(FilterState.cmpFilter.toDate),
        status: FilterState.cmpFilter.status,
        claimedstatus: FilterState.cmpFilter.claimedStatus,
        type:value,
        direction:directions,  
      })
      setPage(0);
    }
    };
    
  return (
    <Main>
      <Text variant="title" color='black'>DryDock Company List</Text>
      <Space divheight={15} />
      <Card>
        <Space divheight={25} />
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-3 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    <InputWithIcon
                      type="text"
                      value={searchInput}
                      onChange={(e) => _searchFuntion(e.target.value)}
                      placeholder="Search by Company Name.."
                      icon="search"
                      id="search-input"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <div className='search-btn-container'>
                <div className='search-btn-add'>
                  {role === 'VISITOR' ? (
                    //                  <Link to='/docks-company/add'>
                    <Button variant='transparent' block={true} disabled>
                      <Icons color="#ffffff" icon="add" />
                      ADD NEW COMPANY
                    </Button>
                    //                  </Link>
                  ) : (
                    <Link to='/docks-company/add'>
                      <Button variant='transparent' block={true}>
                        <Icons color="#ffffff" icon="add" />
                        ADD NEW COMPANY
                      </Button>
                    </Link>
                  )}
                </div>
                <div className='search-btn-filter'>
                  <Button className='link_button filter' onClick={() => _willFilter()}>
                    <img src={Filterimg} alt='filterimg' />
                  </Button>
                </div>
                <div className='search-btn-showall'>
                  <Button variant='transparent' block={true} onClick={() => _showAll()}>
                    Show All
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </SearchForm>
        <Space divheight={5} />
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>}
        {/* {console.log(displayCompanies, 'display companies')} */}
        {!isLoading && displayCompanies.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No dock companies found</Text>
            <Space divheight={20} />
          </>
        )}
        {!isLoading && displayCompanies.length > 0 && (
          <>
            <Table className="table-company">
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Created Date</th>
                  <th>Company Name
                  &nbsp;
                  {sort.values==='company' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('company','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='company' ? (
                     <img onClick={() => sortByFieldn('company','asc')} src={sortimage}/>):(null)}
                     {sort.values==='company' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>City
                  &nbsp;
                  {sort.values==='city' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('city','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='city' ? (
                     <img onClick={() => sortByFieldn('city','asc')} src={sortimage}/>):(null)}
                     {sort.values==='city' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Country
                  &nbsp;
                  {sort.values==='country' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('country','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='country' ? (
                     <img onClick={() => sortByFieldn('country','asc')} src={sortimage}/>):(null)}
                     {sort.values==='country' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Total Docks         
                  </th>
                  <th>Status
                  &nbsp;
                  {sort.values==='verifiedstatus' && sort.direction==='asc' ? (
                     <img onClick={() => sortByFieldn('verifiedstatus','desc')} src={sortup}/>
                     ):(null)}
                     {sort.values==='' || sort.values!=='verifiedstatus' ? (
                     <img onClick={() => sortByFieldn('verifiedstatus','asc')} src={sortimage}/>):(null)}
                     {sort.values==='verifiedstatus' && sort.direction==='desc' ? (
                      <img onClick={() => sortByFieldn('','')} src={sortdown}/>
                      ):(null)}
                  </th>
                  <th>Dock Detail</th>
                  {/* <th>is claimed</th> */}
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {displayCompanies.map((cmp: any, i: any) => [
                  <tr key={i}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    {cmp.companiescreateddate !== null && (
                      <td data-label='Created Date'>
                        {format(parseISO(cmp.companiescreateddate), 'dd/MM/yy')}
                      </td>
                    )}
                    {cmp.companiescreateddate === null && (
                      <td data-label='Created Date'>
                        none
                      </td>
                    )}
                    <td data-label='Company Name'>
                      {cmp.companyname === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{cmp.companyname}</Text>
                      )}
                    </td>
                    <td data-label='City'>
                      {cmp.city === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{cmp.city}</Text>
                      )}
                    </td>
                    <td data-label='Country'>
                      {cmp.country === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{cmp.country}</Text>
                      )}
                    </td>
                    <td className='text-lg-center' data-label='Drydocks Total'>
                      {cmp.numberofdocks === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{cmp.numberofdocks}</Text>
                      )}
                    </td>
                    <td data-label='Status'>{cmp.verifiedstatus === 'VERIFIED' ? (
                      <Text className='txt-table-date' color='warning'>Verified</Text>
                    ) : (
                      <Text className='txt-table-date' color='danger'>Not Verified</Text>
                    )}
                    </td>
                    {cmp.numberofdocks === 0 && (
                      <td className='text-lg-center' data-label='View Docks'>
                        <OverlayTrigger overlay={
                          <Tooltip id="dockCmp-view"> No Docks for this Company
                          </Tooltip>
                        }>
                          <Button className='link_button'
                            disabled
                          //  onClick={() => _willviewDock(cmp)}
                          >
                            <Icons color="#52575a" icon="info" />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    )}
                    {cmp.numberofdocks > 0 && (
                      <td className='text-lg-center' data-label='View Docks'>
                        <OverlayTrigger overlay={
                          <Tooltip id="dockCmp-view"> Dock Dettail
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willviewDock(cmp)}>
                            <Icons color="#52575a" icon="info" />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    )}
                    {/* <td className='text-lg-center' data-label='claimed status'>
                      {cmp.claimedstatus === 'claimed' && (
                        <Button className='link_button'
                          //onClick={() => _willclaimedReq(cmp)}
                          >
                          <Icons color="#52575a" icon="check" />
                        </Button>
                      )}
                      {cmp.claimedstatus === 'pending' && (
                        <Button className='link_button' onClick={() => _willPendingReq(cmp)}>
                          <Icons color="#52575a" icon="restore" />
                        </Button>
                      )} 
                      {cmp.claimedstatus === 'not claimed' && (
                          <Text>-</Text>
                      )}
                    </td> */}
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="dockCmp-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(cmp,page)}>
                            <Icons color="#52575a" icon="visibility" />
                          </Button>
                        </OverlayTrigger>
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="dockCmp-edit"> Edit option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(cmp, page, searchInput)} disabled>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="dockCmp-edit"> Edit
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(cmp, page, searchInput)}>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="dockCmp-delete"> Delete option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(cmp)} disabled>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="dockCmp-delete"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(cmp)}>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </TableActions>
                    </td>
                  </tr>
                ])}
              </tbody>
            </Table>
            <TableFooter>
              <Text>
                {displayCompanies.length === 1 && (
                  <>
                    <b>
                      {displayCompanies.length}
                      {/* {((page) * 10) + 1} - {(displayCompanies.length + (page) * 10)} */}
                    </b>  of {totalElement} Company
                  </>
                )}
                {displayCompanies.length > 1 && (
                  <>
                    <b>
                      {displayCompanies.length}
                      {/* {((page) * 10) + 1} - {(displayCompanies.length + (page) * 10)} */}
                    </b>  of {totalElement} Companies
                  </>
                )}

              </Text>
              <Pagination size="sm">
                {firstPage()} {/* First page button.   */}
                {prevBtn()} {/* Previous button.   */}
                {PageNumber} {/* Totalpages.   */}
                {nextBtn()} {/* Next button.   */}
                {lastPage()} {/* Last page button.   */}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {showModal && <DockCmpFilter onClose={() => _willCloseModal()} />}
      {showDock && <DockView onClose={() => _willCloseDock()} />}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form Dock Company list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Main>
  )
}

export interface TablefooterProps {
  children?: React.ReactNode
}

const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

//Search Form Component  for displaying serarch box, and add ship button
const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

const mapStateToProps = (state: any) => ({
  companies: state.dock.companies,
  isLoading: state.dock.isLoading,
  error: state.dock.error,
  totalElement: state.dock.totalElement,
  totalPage: state.dock.totalPage,
})

export default connect(
  mapStateToProps,
  {
    dockCompanyListGetAction,
    dockCompanySetSelectedAction,

  }
)(DryDockCompanies)