import { ofType } from 'redux-observable'
import {
  ADS_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import adsService from '../../services/adService'
import {
  adsListGetFAction,
  adsListGetRAction,
} from '../action/adsAction'


export const getAdsEpic = (action : any) => {
  return action.pipe(
    ofType(ADS_LIST_GET),
    switchMap((action : any) => {
      return adsService.getAds(action.payload).pipe(
        map((res :any) => {
          const { content, total_page, total_element } = res.data
          return adsListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(adsListGetRAction(err)))
      )
    })
  )
}

