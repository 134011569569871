import { ofType } from 'redux-observable'
import {
  ALL_SHIP_LIST_GET,
  SHIP_COMPANY_LIST_GET,
  SHIP_LIST_GET,
  SHIP_OWNER_LIST_GET,
  SHIP_TYPE_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import shipService from '../../services/shipService'
import {
  allShipListGetFAction,
  allShipListGetRAction,
  shipCompanyListGetFAction,
  shipCompanyListGetRAction,
  shipListGetFAction,
  shipListGetRAction,
  shipOwnerListGetFAction,
  shipOwnerListGetRAction,

} from '../action/shipActions'
import codeService from '../../services/codeService'


export const getShipsEpic = (action : any) => {
  return action.pipe(
    ofType(SHIP_LIST_GET),
    switchMap((action : any) => {
      return shipService.getShips(action.payload).pipe(
        map((res :any) => {
          const { content, total_page, total_element } = res.data
          return shipListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(shipListGetRAction(err)))
      )
    })
  )
}

export const getAllShipsEpic = (action : any) => {
  // console.log('getAllShipEpic called')
  return action.pipe(
    ofType(ALL_SHIP_LIST_GET),
    switchMap((action : any) => {
      return shipService.getShips(action.payload).pipe(
        map((res :any) => {
          const { content, total_page, total_element } = res.data
          return allShipListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(allShipListGetRAction(err)))
      )
    })
  )
}

export const getShipCompaniesEpic = (action$ : any) => {
  return action$.pipe(
    ofType(SHIP_COMPANY_LIST_GET),
    switchMap((action : any) => {
      return shipService.getShipCompanies(action.payload).pipe(
        map((res :any) => {
          // console.log(action.payload,'action payload in ship company')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content shop epic')
          // console.log(total_page)
          // console.log(total_element)
          return shipCompanyListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(shipCompanyListGetRAction(err)))
      )
    })
  )
}

export const getShipOwnersEpic = (action$ : any) => {
  return action$.pipe(
    ofType(SHIP_OWNER_LIST_GET),
    switchMap((action : any) => {
        return shipService.getShipOwners(action.payload).pipe(
            map(res => {
                // console.log(res,'response in dock company epis')
                // console.log(action.payload,'payload for sock company')
                const {data} = res
                // console.log(res.data,'response in docepics')
                const { content, total_page, total_element } = res.data
                // console.log(content,'ccontent')
                return shipOwnerListGetFAction({content, total_page, total_element})
            }),
            catchError(err => of(shipOwnerListGetRAction(err)))
        )
    })
)
}
