import {
    PROVINCES_LIST_GET,
    PROVINCES_LIST_GET_F,
    PROVINCES_LIST_GET_R,
    PROVINCES_SELECTED_SET
} from '../actionType'

export const provincesListGetAction = (params: any) => ({
    type: PROVINCES_LIST_GET,
    payload: params
})

export const provincesListGetFAction = (params: any) => ({
    type: PROVINCES_LIST_GET_F,
    payload: params
})

export const provincesListGetRAction = (params: any) => ({
    type: PROVINCES_LIST_GET_R,
    payload: params
})

export const provincesSetSelectedAction = (provinces: any) => ({
    type: PROVINCES_SELECTED_SET,
    payload: provinces
})