import React, { ReactNode, useCallback } from 'react'
import { Text } from '../../../common/components/text/Text'
import { Main } from '../../../common/components/main/Main'
import { InputWithIcon, Select } from '../../../common/components/control/Control'
import { Button } from '../../../common/components/button/Button'
import Card from '../../../common/components/card/Card'
import Icons from '../../../common/components/icon/Icon'
import { Flex } from '../../../common/components/flex/Flex'
import { Space } from '../../../common/components/space/Space'
import { Table } from '../../../common/components/table/Table'
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap'
import '../../../common/components/pagination/Pagination.scss'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import {
  provincesListGetAction,
  provincesSetSelectedAction
} from '../../../store/action/provincesAction'
import AddProvinces from './pageContainer/AddProvinces'
import { useState } from 'react'
import swal from 'sweetalert'
import provincesService from '../../../services/provincesService'
import Loader from 'react-loader-spinner'
import NumberFormat from 'react-number-format'

const ProvincesList = (props: any) => {
  const { provinces, isLoading, totalElement } = props
  const [displayProvinces, setProvinces] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [showModal, setModal] = React.useState(false)
  const [pagination, setPagination] = React.useState([{}])
  const [isEdit, setIsEdit] = useState(false)
  const [isview, setIsView] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [high, setHigh] = useState(9)
  const [low, setLow] = useState(1)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  // const headerName = 'ROLE DETAIL'
  // const filename = "role__" + new Date()
  //const [searchInput, setSearchInput] = React.useState('')
  // const [searchQuery, setQuery] = useState('')
  const [showDelete, setShow] = React.useState(false);
  const [deleteProvinces, setDeleteProvinces] = React.useState(0);
  const [findBy, setFindBy] = React.useState('name');
  const [searchInput, setSearchInput] = React.useState('');
  const [searchPCountryValue, setSearchPCountryValue] = React.useState<any>('');

  const role = localStorage.getItem("role")

  React.useEffect(() => {
    //const { totalPage } = props
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  React.useEffect(() => {
    props.provincesListGetAction({ size: 10, page, name: searchInput, country: searchPCountryValue })
  }, [totalElement, page])


  React.useEffect(() => {
    if (provinces && provinces.length > 0) {
      // console.log(provinces, 'provinces')
      setProvinces(() => {
        return provinces.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (provinces && provinces.length === 0) {
      setProvinces([])
    }
  }, [provinces, page])


  const _willViewEdit = (detail: string | number | any) => {
    props.provincesSetSelectedAction(detail)
    setModal(true)
    setIsView(false)
    setIsEdit(true)
  }

  const _willViewDetail = (detail: string | number | any) => {
    props.provincesSetSelectedAction(detail)
    setModal(true)
    setIsView(true)
    setIsEdit(false)
  }

  // Delete the pericular list.
  const handleClose = () => setShow(false);
  const handleShow = (provinces: any) => {
    setShow(true);
    setDeleteProvinces(provinces.id)
    // console.log(deleteProvinces, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteProvinces, 'deleted anode id')
    _deleteProvinces(deleteProvinces)
  }

  const _deleteProvinces = (id: any) => {
    return provincesService.deleteProvinces(id).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.provincesListGetAction({ size: 10, page, name: searchInput, country: searchPCountryValue })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }
  const _willAddNewProvinces = () => {
    props.provincesSetSelectedAction(null)
    setModal(true)
    setIsAdd(true)
    setIsEdit(false)
    setIsView(false)
  }
  const _willCloseModal = () => {
    props.provincesListGetAction({ size: 10, page: 0 })
    setModal(false)
    setPage(0)
  }

  const _getSearchValue = (val: string) => {
    setFindBy(val)
  }
  const _searchFuntion = (value: string) => {
    setSearchInput(value)
    props.provincesListGetAction({ size: 10, page, name: value, country: searchPCountryValue })
  }
  const _searchPCountryFunction = (value: string) => {
    setSearchPCountryValue(value)
    props.provincesListGetAction({ size: 10, page, name: searchInput, country: value })
  }
  const _showAll = () => {
    setSearchInput('')
    setSearchPCountryValue('')
    props.provincesListGetAction({ size: 10, page, name: '', country: '' })
  }

  const _goPrevPage = () => {
    setPage(prev => {
      return (prev -= 1)
    })
  }

  const _goNextPage = () => {
    setPage(prev => {
      return (prev += 1)
    })
  }

  const _goToPage = (page: any) => {
    setPage(page)
  }

  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 10)
  }

  const _goToFirstPage = () => {
    setPage(0)
    setHigh(10)
    setLow(1)
  }

  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  const nextBtn = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  const ListItem = (page: any) => {
    return page.value + 1
  }
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0) || (p === 1 && page === 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })


  return (
    <Main>
      <Text variant="title">Province List</Text>
      <Space divheight={15} />
      <Card>
        {/* <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='3' className='m-0 p-0'>
              <div className='mr-2 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    {findBy === 'name' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Province.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === '' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Province.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === 'country_name' && (
                      <InputWithIcon
                        type="text"
                        value={searchPCountryValue}
                        onChange={(e) => _searchPCountryFunction(e.target.value)}
                        placeholder="Search by Country Name..."
                        icon="search"
                        id="search-inputcode"
                      />
                    )}

                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <Row className='m-0 p-0'>
                <Col xs='6' sm='4' md='4' lg='5' xl='4' className='m-0 p-0 mr-2'>
                  <Select values={findBy} name="" onChange={(e) => _getSearchValue(e.target.value)}>
                    <option value=''>-- Search by --</option>
                    <option value="name"> By Province </option>
                    <option value="country_name"> By Country </option>
                  </Select>
                </Col>
                <Col xs='5' sm='4' md='4' lg='7' xl='7' className='m-0 p-0 '>
                  <div>
                    {role === 'VISITOR' ? (
                      <Button variant='transparent' onClick={() => _willAddNewProvinces()} disabled>
                        <Flex>
                          <Icons icon="add" color="#ffffff" />
                          ADD PROVINCE
                        </Flex>
                      </Button>
                    ) : (
                      <Button variant='transparent' onClick={() => _willAddNewProvinces()}>
                        <Flex>
                          <Icons icon="add" color="#ffffff" />
                          ADD PROVINCE
                        </Flex>
                      </Button>
                    )}
                    <Button className='ml-2' variant='transparent' block={true} onClick={() => _showAll()}>
                      Show All
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SearchForm> */}
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='3' className='m-0 p-0'>
              <div className='mr-2 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    {findBy === 'name' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Province.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === '' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by Province.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === 'country' && (
                      <InputWithIcon
                        type="text"
                        value={searchPCountryValue}
                        onChange={(e) => _searchPCountryFunction(e.target.value)}
                        placeholder="Search by Country..."
                        icon="search"
                        id="search-inputPCountry"
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <Row className='m-0 p-0'>
                <Col xs='6' sm='4' md='4' lg='5' xl='4' className='m-0 p-0 mr-2'>
                  <Select values={findBy} name="" onChange={(e) => _getSearchValue(e.target.value)}>
                    <option value=''>-- Search by --</option>
                    <option value="name"> By Province </option>
                    <option value="country"> By Country </option>
                  </Select>
                </Col>
                <Col xs='5' sm='4' md='4' lg='7' xl='7' className='m-0 p-0 '>
                  <div>
                    {role === 'VISITOR' ? (
                      <Button variant='transparent' onClick={() => _willAddNewProvinces()} disabled>
                        <Flex>
                          <Icons icon="add" color="#ffffff" />
                          ADD PROVINCE
                        </Flex>
                      </Button>
                    ) : (
                      <Button variant='transparent' onClick={() => _willAddNewProvinces()}>
                        <Flex>
                          <Icons icon="add" color="#ffffff" />
                          ADD PROVINCE
                        </Flex>
                      </Button>
                    )}

                    <Button className='ml-2' variant='transparent' block={true} onClick={() => _showAll()}>
                      Show All
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SearchForm>
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>}
        {!isLoading && displayProvinces.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No Provinces found</Text>
            <Space divheight={20} />
          </>
        )}
        {!isLoading && displayProvinces.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Provinces</th>
                  <th>Country</th>
                  <th>Pay per View (coins)</th>
                  <th>Pay per Click (coins)</th>
                  <th colSpan={2} className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {displayProvinces.map((province: any, i: any) => (
                  <tr key={province.id}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='province'>
                      {province.name}
                    </td>
                    <td data-label='Country'>
                      {province.country === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{province.country.name}</Text>
                      )}
                    </td>
                    <td data-label='Pay per View'>
                      {province.pay_per_view === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <>
                          {!String(province.pay_per_view).includes('.') && (
                            <Text className='txt-table-date' color='tabledata'>
                              <NumberFormat value={String(province.pay_per_view)} displayType={'text'} thousandSeparator={true}/>.00
                            </Text>
                          )}
                          {String(province.pay_per_view).includes('.') && (
                            <Text className='txt-table-date' color='tabledata'>
                              <NumberFormat value={province.pay_per_view} displayType={'text'} thousandSeparator={true}/>
                            </Text>
                          )
                          }
                        </>
                      )}
                    </td>
                    <td data-label='Pay per Click'>
                      {province.pay_per_click === null ? (
                        <Text className='txt-table-date' color='tabledata'>-</Text>
                      ) : (
                        <>
                          {!String(province.pay_per_click).includes('.') && (
                            <Text className='txt-table-date' color='tabledata'>
                              <NumberFormat value={String(province.pay_per_click)} displayType={'text'} thousandSeparator={true}/>.00
                            </Text>
                          )}
                          {String(province.pay_per_click).includes('.') && (
                            <Text className='txt-table-date' color='tabledata'>
                              <NumberFormat value={province.pay_per_click} displayType={'text'} thousandSeparator={true}/>
                            </Text>
                          )}
                        </>
                      )}
                    </td>
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="provinces-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(province)}>
                            <Icons color="#52575a" icon="remove_red_eye" />
                          </Button>
                        </OverlayTrigger>
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="provinces-edit-visitor"> Edit option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(province)} disabled>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="provinces-edit"> Edit
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(province)}>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="provinces-delete-visitor"> Delete option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(province)} disabled>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="provinces-delete"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(province)}>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </TableActions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text>
                {displayProvinces.length === 1 && (
                  <>
                    <b>
                      {displayProvinces.length}
                      {/* {((page) * 10) + 1} - {(displayProvinces.length + (page) * 10)} */}
                    </b> of {totalElement} Province
                  </>
                )}
                {displayProvinces.length > 1 && (
                  <>
                    <b>
                      {displayProvinces.length}
                      {/* {((page) * 10) + 1} - {(displayProvinces.length + (page) * 10)} */}
                    </b> of {totalElement} Provinces
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()}
                {prevBtn()}
                {PageNumber}
                {nextBtn()}
                {lastPage()}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {/* {console.log(showModal, 'showmodal')} */}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form Provinces list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {showModal && <AddProvinces onClose={() => _willCloseModal()} edit={isEdit} view={isview} user_id={provinces.id} />}
    </Main>
  )
}

export interface TablefooterProps {
  children?: React.ReactNode
}

const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

const mapStateToProps = (state: any) => ({
  provinces: state.provinces.provinces,
  isLoading: state.provinces.isLoading,
  error: state.provinces.error,
  totalElement: state.provinces.totalElement,
  totalPage: state.provinces.totalPage,
  alluser: state.provinces.allusers

})

export default connect(
  mapStateToProps,
  {
    provincesListGetAction,
    provincesSetSelectedAction
  }
)(ProvincesList)
