import { ofType } from 'redux-observable'
import {
    DATA_SHIP_ENGINE_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import datalasticShipEngService from '../../services/datalasticShipEngService'
import {
    datashipEngineListGetFAction,
    datashipEngineListGetRAction,
} from '../action/dataShipEngAction'


export const dataShipEngEpics = (action$: any) => {
    return action$.pipe(
        ofType(DATA_SHIP_ENGINE_LIST_GET),
        switchMap((action: any) => {
            return datalasticShipEngService.getDatalasticShipEngines(action.payload).pipe(
                map((res: any) => {
                    // console.log(action.payload,'action payload in ship company')
                    const { content, total_page, total_element } = res.data
                    console.log(content,'content shop epic')
                    // console.log(total_page)
                    // console.log(total_element)
                    return datashipEngineListGetFAction({ content, total_page, total_element })
                }),
                catchError((err: any) => of(datashipEngineListGetRAction(err)))
            )
        })
    )
}

