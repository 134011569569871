import {
    DATA_SHIP_CLASSIFICATION_LIST_GET,
    DATA_SHIP_CLASSIFICATION_LIST_GET_F,
    DATA_SHIP_CLASSIFICATION_LIST_GET_R,
    DATA_SHIP_CLASSIFICATION_SELECTED_SET,
  } from '../actionType'
  
  export const datashipClassificationListGetAction = (params : any) => ({
    type: DATA_SHIP_CLASSIFICATION_LIST_GET,
    payload: params
  })
  
  export const datashipClassificationListGetFAction = (data : any) => ({
    type: DATA_SHIP_CLASSIFICATION_LIST_GET_F,
    payload: data
  })
  
  export const datashipClassificationListGetRAction = ( error : any) => ({
    type: DATA_SHIP_CLASSIFICATION_LIST_GET_R,
    payload: error
  })
  
  export const datashipClassificationSetSelectedAction = (dataLasticClassification : any) => ({
    type: DATA_SHIP_CLASSIFICATION_SELECTED_SET,
    payload: dataLasticClassification
  })

  