import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row } from 'react-bootstrap'
import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import { format, parseISO } from 'date-fns'
import { Input, Radio, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import { connect } from 'react-redux'
import swal from 'sweetalert';
import { manufacturerSetSelectedAction } from '../../../../store/action/manufacturerAction'
import manufacturerService from '../../../../services/manufacturerService'

// Blue print of the class component.  
interface ManufacturerEditProps {
    isactive: boolean
}

const ManufacturerEdit = (props: any) => {
    const [status, setStatus] = React.useState<ManufacturerEditProps>({
        isactive: false
    })
    const { manufacturer, match } = props
    const [isLoading, setLoading] = React.useState(true)
    const [display, setDisplay] = useState<any>({
        userid: '',
        address: '',
        dob: '',
        email: '',
        phone: '',
        // profile_img: ''
        // register_date: '',
        status: '',
        username: '',
        userposition: ''
    })

    // Used to get perticular ship detail and store to variable.   
    const _getManufacturerDetail = ((id: any) => {
        setLoading(true)
        return manufacturerService.getManufacturerDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.manufacturerSetSelectedAction(data)
                // console.log(r.data)
                setDisplay(r.data)
                // setDisplay({
                //     address: r.data.address,
                //     dob: r.data.dob,
                //     email: r.data.email,
                //     phone: r.data.phone,
                //     // profile_img: r.data.
                //     register_date: r.data.register_date,
                //     status: r.data.status,
                //     username: r.data.username,
                //     userposition: r.data.userposition
                // })
                setStatus({ ...status, isactive: r.data.isactive })
                // console.log(status.isactive, 'is active')
                setLoading(false)
            },
            error: err => {
                console.error(err)
            }
        })
    })
    React.useEffect(() => {
        const { id } = match.params
        _getManufacturerDetail(id)
    }, [])

    // After update all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call.  
    const _didSubmit = (e: any) => {
        e.preventDefault()
        const { id } = match.params
        const updatedForm = {
            id: Number(id),
            address: display.address,
            dob: display.dob,
            email: display.email,
            phone: display.phone,
            status: display.status,
            name: display.username,
            position: display.userposition
        }
        // API call for updateship with data, updated into list page.  
        return manufacturerService.updateManufacturer(updatedForm).subscribe({
            next: () => {
                swal("Great", "Industrialist Edited Successfully", "success")
                history.push('/manufacturer/list')
            },
            error: err => {
                swal("OOPS", String(err), "error")
                console.error(err)
            }
        })
    }
    return (
        <Main>
            {/* {console.log(manufacturer)} */}
            <Text color='black' variant="title">Edit Industrialist Information</Text>
            <Space divheight={25} />
            <Grid templateCols='75% auto' className='view_Page'>
                <StyledForm onSubmit={e => _didSubmit(e)}>
                    <Card title="Fill All The Indisplayation Below">
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Name</Text>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={display.username}
                                            onChange={(e) => setDisplay({ ...display, username: e.target.value })}
                                            required
                                            placeholder='Name'
                                        />
                                    </div>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>E-mail </Text>
                                        <Input
                                            type="email"
                                            name="email"
                                            value={display.email}
                                            onChange={(e) => setDisplay({ ...display, email: e.target.value })}
                                            required
                                            placeholder='E-mail'
                                        />
                                    </div>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>D.O.B </Text>
                                        <Input
                                            type="date"
                                            name="dateofbirth"
                                            value={display.dob}
                                            onChange={(e) => setDisplay({ ...display, dob: e.target.value })}
                                            required
                                        />
                                    </div>
                                </Attributes>
                            </Col>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Mobile</Text>
                                        <Input
                                            type="text"
                                            name="phone"
                                            value={display.phone}
                                            onChange={(e) => setDisplay({ ...display, phone: e.target.value })}
                                            required
                                            placeholder='mobile'
                                        />
                                    </div>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Position</Text>
                                        <Input
                                            type="text"
                                            name="position"
                                            value={display.userposition}
                                            onChange={(e) => setDisplay({ ...display, userposition: e.target.value })}
                                            required
                                            placeholder='Position'
                                        />
                                    </div>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Address</Text>
                                        <Textarea
                                            rows={3}
                                            cols={50}
                                            name="address"
                                            value={display.address}
                                            onChange={(e) => setDisplay({ ...display, address: e.target.value })}
                                            required
                                        />
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='12' md='8' xl='6' lg='6'>
                                <Attributes>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Status</Text>
                                        <Select values={display.status} name="status" onChange={(e) => setDisplay({ ...display, status: e.target.value })} required>
                                            <option className='option-placeholder' value =''>-- Select --</option>
                                            <option value="VERIFIED"> Verified</option>
                                            <option value="UNVERIFIED"> Not verified</option>
                                        </Select>
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                    <Actions>
                        {/* If you click the back button its is redirect to ships list page.   */}
                        <Link to='/manufacturer/list'>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the save button its is call the function, in that data updated to list and redirect to list page.   */}
                        <Button variant='transparent' type="submit">
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                                SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </StyledForm>
            </Grid>
        </Main>
    )
}


export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style.  
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-ship-add');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}

// Button style.  
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    manufacturer: state.manufacturer.selectedManufacturer,
})
//Return the selected ship details.  
export default connect(
    mapStateToProps,
    {
        manufacturerSetSelectedAction
    }
)(ManufacturerEdit)
