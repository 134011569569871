import { map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getSlot = (params:object) => {
    // console.log('get country called')
    // console.log(params ,'param')
    return http({
    method: 'GET',
    url: '/advertisement/slot',
    params
    }).pipe(
    map(res => {
        if (res.success) {
        console.log(res, 'res')
        return res
        } else {
        // console.log(res ,'res')
        throw new Error(res.msg)
        }
    })
    )
}

const addSlot =( body : object) => {
    return http({
    method: 'POST',
    url: '/countries',
    body
    }).pipe(
    map(res => {
        if (res.success) {
        return res
        } else {
        throw new Error(res.msg)
        }
    })
    )
}


const updateSlot =(body:object) => {
    return http({
    method: 'PUT',
    url: `/countries`,
    body
    }).pipe(
    map(res => {
        if (res.success) {
        // console.log('update user response', res)
        return res
        } else {
        throw new Error(res.msg)
        }
    })
    )
}

const getSlotDetail = (id:any) => {
    return http({
        method: 'GET',
        url: `/advertisement​/slot​/${id}`
      }).pipe(
        map(res => {
          // console.log(res,'get ship detail')
          if (res.success) {
            return res
          } else {
            throw new Error(res.msg)
          }
        })
    )
}
const deleteSlot = (id : any) => {
    return httpDelete({
      method: 'DELETE',
      url: `/anodes/${id}`,
    }).pipe(
      map(res => {
        // console.log(res,'delete response')
        if (res) {
          return res
        } else {
          // console.log(res,'error message')
          throw new Error(res)
        }
      })
    )
  }
const slotService = {
    addSlot,
    getSlot,
    getSlotDetail,
    updateSlot,
    deleteSlot
}

export default slotService