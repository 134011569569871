import {
    DATA_SHIP_ENGINE_LIST_GET,
    DATA_SHIP_ENGINE_LIST_GET_F,
    DATA_SHIP_ENGINE_LIST_GET_R,
    DATA_SHIP_ENGINE_SELECTED_SET,
} from '../actionType';
const initialState = {
    dataLasticEngines: [],
    isLoading: false,
    error: null,
    totalPage: 0,
    totalElement: 0,
    dataLasticSelectedEngine: null,
}
export default function dataEngReducer(state = initialState, action: any) {
    switch (action.type) {
        case DATA_SHIP_ENGINE_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case DATA_SHIP_ENGINE_LIST_GET_F:
            var totaldatashipengcount = 0

            if (action.payload.content === undefined || '') {
                totaldatashipengcount = 0
            }
            else {
                action.payload.content.map((data: any) => {
                    totaldatashipengcount = data.total_row
                })
            }
            console.log(action.payload.content)
            return {
                ...state,
                isLoading: false,
                dataLasticEngines: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: totaldatashipengcount
            }
        case DATA_SHIP_ENGINE_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case DATA_SHIP_ENGINE_SELECTED_SET:
            return {
                ...state,
                dataLasticSelectedEngine: action.payload
            }
        default:
            return state
    }
}