import { connect } from "react-redux";
import { manufacturerSetSelectedAction } from '../../../../store/action/manufacturerAction'
import React from "react";
import { Col, Image, Row } from 'react-bootstrap'
import { Text } from '../../../../common/components/text/Text'
import { Main } from "../../../../common/components/main/Main";
import { Space } from "../../../../common/components/space/Space";
import Card from "../../../../common/components/card/Card";
import noimg from '../../../../assets/images/NoImg.jpg';
import { Grid } from "../../../../common/components/grid/Grid";
import { Attributes } from "../../../../common/components/attribute/Attribute";
import classNames from "classnames";
import { Flex } from "../../../../common/components/flex/Flex";
import { Link } from "react-router-dom";
import { Button } from "../../../../common/components/button/Button";
import Icons from "../../../../common/components/icon/Icon";
import manufacturerService from '../../../../services/manufacturerService'
import { Label } from "../../../../common/components/label/Label";
import { format, parseISO } from 'date-fns'
import Loader from "react-loader-spinner";
import userService from '../../../../services/userService';
import swal from 'sweetalert';
import editicon from '../../../../assets/images/editone.png';
import removeicon from '../../../../assets/images/Remove.png'
import { useRef } from 'react';
import { history } from '../../../../store';


const ManufacturerDetail = (props: any) => {
    const { match } = props
    const [isLoading, setLoading] = React.useState(true)
    const [display, setDisplay] = React.useState<any>({
        id:'',
        address: '',
        dob: '',
        email: '',
        phone: '',
        profile_img: '',
        register_date: '',
        status: '',
        username: '',
        userposition: '',
        phoneCode: '',
        isactive:false,
    })
    const [cmpdetail, setCmpDetail] = React.useState<any>([])
    var productId = 0
    const [productDetail, setProductDetail] = React.useState<any>([])
    const { id } = match.params
    //Call perticular ship detail function.  
    React.useEffect(() => {
        _getManufacturerDetail(id)
        _getManufacturerCompany(id)
        // _getProduct(productId)      
    }, [])

    //Get perticular ship detail using match.params. 
    const _getManufacturerDetail = (id: any) => {
        // console.log(id,'id')
        setLoading(true)
        return manufacturerService.getManufacturerDetail(id).subscribe({
            next: r => {
                const { data } = r
                props.manufacturerSetSelectedAction(r.data)
                // console.log(r.data,'r.data')
                setDisplay({
                    id:r.data.userid,
                    address: r.data.address,
                    dob: r.data.dob,
                    email: r.data.email,
                    phone: r.data.phone,
                    profile_img: r.data.profile_img,
                    register_date: r.data.register_date,
                    status: r.data.status,
                    isactive: r.data.isactive,
                    username: r.data.username,
                    userposition: r.data.userposition,
                    phoneCode: r.data.phone_code
                })
                // r.map((data: any, i: any) => {
                //     return display.push(data.data)
                // })
                // console.log(display,'subscription detail')
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    const role = localStorage.getItem("role")

    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<any>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageUserAPI(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const ImageUserAPI = (imageurl: any) => {
        const { id } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        userService.UpdateUserImage(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                //window.location.reload();
                history.push("/manufacturer/list")
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }

    const _removeUserImg = (id: any) => {
        return userService.removeUserImg(id).subscribe({
            next: (r) => {
                // console.log(r, 'response')
                // alert(true)
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
                // history.push(`/ships/${id}/`)
            },
            error: err => {
                // alert(false)
                swal("OOPS", String(err), "error")
            }
        })
    }

    //getmanufacturer company

    const _getManufacturerCompany = (id: any) => {
        // console.log(id,'id')
        setLoading(true)
        return manufacturerService.getManufacturerCompany(id).subscribe({
            next: r => {
                const { data } = r
                // console.log(r,'r.data manufacturer company')
                // props.manufacturerSetSelectedAction(r.data)
                // console.log(r.data,'r.data manufacturer company')
                if (r.data && r.data.length > 0) {
                    setCmpDetail(() => {
                        return r.data.map((s: any) => ({ ...s, checked: false }))
                    })
                }
                if (r.data && r.data.length === 0) {
                    setCmpDetail([])
                }

                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    //get manufacturer Product

    const _getProduct = (id: any) => {
        // console.log('product detail called')
        // console.log(id,'id')
        setLoading(true)
        return manufacturerService.getManufacturerProduct(id).subscribe({
            next: r => {
                const { data } = r
                // console.log(r,'r.data manufacturer product')
                // props.manufacturerSetSelectedAction(r.data)
                // console.log(r.data,'r.data manufacturer product')
                if (r.data && r.data.length > 0) {
                    setProductDetail(() => {
                        return r.data.map((s: any) => ({ ...s, checked: false }))
                    })
                }
                if (r.data && r.data.length === 0) {
                    setProductDetail([])
                }
                setLoading(false)
            },
            error: err => {
                console.error(err)
                setLoading(false)
            }
        })
    }

    // If isloading is true print a text as loading. 
    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    // If isloading is false print the details of perticular ship. 
    return (
        <Main>
            <Space divheight={15} />
            {/* {console.log(display,'detail in manufacturer list')} */}
            {/* <Text variant="title" className='txt-capitalize' >{display.username}</Text> */}
            <div className='title-name-block'>
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                    {display.profile_img === null || '' &&(
                        <Image src={noimg} alt='logo' roundedCircle/>
                    )}
                    {display.profile_img === '' &&(
                        <Image src={noimg} alt='logo' roundedCircle/>
                    )}
                    {display.profile_img !== null && display.profile_img !== '' &&(
                        <Image src={display.profile_img} alt='logo' roundedCircle/>
                    )}  
                </div> 
                <Text variant='title' color='main' className='txt-capitalize'>
                    {String(display.email).substring(0,String(display.email).indexOf('@'))}
                    {/* {display.username} */}
                </Text>
                <Label className={display.isactive === false ? 'danger' : 'warning'} >
                    {display.isactive === false ? 'In Active' : 'Active'}
                </Label>
            </div>
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                {/* {console.log(display,'detail')} */}
                <Col sm='12' md='12' lg='8' xl='8' className='m-0 p-0 align-top'>
                    <Card title="Supporting Owner Detail">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='7' xl='7' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Name</Text>
                                        {display.username === '' ? (
                                            <Text color='main' variant='subtitle' > - </Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold >{display.username}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>D.O.B</Text>
                                        {display.dob === null && (
                                            <Text color='main' variant='subtitle' > - </Text>
                                        )}
                                        {display.dob !== null && (
                                            <Text color='main' variant='subtitle' bold >
                                                {display.dob}
                                                {/* {format(parseISO(display.dob), 'dd/MM/yyyy')} */}
                                            </Text>)}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Status</Text>
                                        {display.status === 'VERIFIED' ? (
                                            <Text color='warning' variant='subtitle' bold>Verified</Text>
                                        ) : (
                                            <Text color='danger' variant='subtitle' bold>Not Verified</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Address</Text>
                                        {display.address === '' || null ? (
                                            <Text color='main' variant='subtitle' > Address not found </Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold >{display.address}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Email</Text>
                                        {display.email === '' ? (
                                            <Text color='main' variant='subtitle' > - </Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold >{display.email}</Text>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                            <Col sm='12' md='12' lg='5' xl='5' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Created Date</Text>
                                        {display.register_date === null && (
                                            <Text color='main' variant='subtitle' bold>--</Text>
                                        )}
                                        {display.register_date !== null && (
                                            <Text color='main' variant='subtitle' bold>
                                                {display.register_date}
                                                {/* {format(parseISO(display.register_date), 'dd/MM/yyyy')} */}
                                            </Text>)}
                                        {/* {display.register_date === null ? (
                                    ) : (
                                    
                                    )}
                                     */}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Position</Text>
                                        {display.userposition === '' ? (
                                            <Text color='main' variant='subtitle' > - </Text>
                                        ) : (
                                            <Text color='main' variant='subtitle' bold>{display.userposition}</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'>Mobile</Text>
                                        {display.phone === '' || null ? (
                                            <Text color='main' bold variant='subtitle'> - </Text>
                                        ) : (
                                            <>
                                                {display.phoneCode === '' ? (
                                                    <Text color='main' bold variant='subtitle'>{display.phoneCode}
                                                        <span>
                                                            <Text color='main' bold variant='subtitle'>{display.phone}</Text>
                                                        </span>
                                                    </Text>
                                                ) : (
                                                    <>
                                                        <Text color='main' bold variant='subtitle'>{display.phoneCode} &nbsp;
                                                            <span>
                                                                <Text color='main' bold variant='subtitle'>{display.phone}</Text>
                                                            </span>
                                                        </Text>
                                                    </>
                                                )
                                                }
                                            </>
                                        )}
                                    </div>
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm='12' md='12' lg='4' xl='4' className='m-0 p-0 align-top'>
                    {/* <div className='ml-xl-3  mt-sm-4 mt-md-4 mt-lg-4 mt-xl-0 view_page_xs_margin'>

                    </div> */}
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Card title="Profile Picture">
                            <div className='user-mainBlock'>
                                {display.profile_img  === null &&(
                                    <div className='userProfile-block'>
                                        <Image roundedCircle src={noimg} alt='logo'/>
                                    </div>
                                )}
                                {display.profile_img  === '' &&(
                                    <div className='userProfile-block'>
                                        <Image roundedCircle src={noimg} alt='logo'/>
                                    </div>
                                )}
                                {display.profile_img  !== null && display.profile_img  !== '' &&(
                                    <div className='userProfile-block'>
                                        <Image roundedCircle src={display.profile_img } alt='logo'/>
                                    </div>
                                )}  
                            </div>
                            {((display.profile_img === null) || (display.profile_img === '')) && role === 'VISITOR' && (
                                <div className='imageEditBlock'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            <Image roundedCircle src={editicon} alt='logo' />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                </div>
                            )}
                            {console.log(display.id)}
                            {display.profile_img !== null && display.profile_img !== '' && role === 'VISITOR' && (
                                <div className='imageEditBlock'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            <Image roundedCircle src={editicon} alt='logo' className="editIconRemove" />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>

                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={removeicon} alt='logo' onClick={() => _removeUserImg(display.id)} />
                                        </Flex>
                                    </label>
                                </div>
                            )}
                        </Card>
                    </div>
                </Col>
            </Row>
            <Row className='m-0 p-0 mt-2 mt-sm-2 mt-md-2 mt-lg-1 mt-xl-1'>
                <Col sm='12' md='12' lg='8' xl='8' className='m-0 p-0 align-top'>
                    <Card title="Industries Information">
                        <Row className='m-0 p-0'>
                            <Col sm='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                {cmpdetail.length === 0 && (
                                    <div>
                                        <Space divheight={20} />
                                        <Text center>  Not Associated with any Company Yet  </Text>
                                        <Space divheight={20} />
                                    </div>
                                )
                                }
                                {cmpdetail.map((data: any, i: any) => (
                                    data.length !== 0 && (
                                        <Attributes id={data.company_id} className='view_Page'>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Name</Text>
                                                <Text variant='subtitle' color="main" bold>{data.company_name}</Text>
                                            </div>
                                            <div>
                                                <Text color='main' variant='subtitle'>City Name </Text>
                                                <Text color="main" variant='subtitle' bold>{data.city_name}</Text>
                                            </div>
                                        </Attributes>
                                    )
                                ))}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='8' xl='8'>
                    <Actions>
                        {/* If you click the back button its is redirect to ships list page.  */}
                        <Link to='/manufacturer/list'>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the edit button its is redirect to ships edit page.  */}
                        {/* <Link to={`/manufacturer/edit/${id}`}>
                            <Button variant='transparent' type="submit">
                                <Flex>
                                    <Icons color="#ffffff" icon="edit" />
                                    EDIT DATA
                                </Flex>
                            </Button>
                        </Link> */}
                    </Actions>
                </Col>
            </Row>
        </Main>
    )
}
export interface ActionProps {
    children?: React.ReactNode
}

const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-center' className={action}>
            {props.children}
        </Flex>
    )
}


const mapStateToProps = (state: any) => ({
    manufacturer: state.manufacturer.selectedManufacturer
})

// connect to manufacturerSetSelectedAction and get the one perticular ship data. 
export default connect(
    mapStateToProps,
    {
        manufacturerSetSelectedAction
    }
)(ManufacturerDetail)