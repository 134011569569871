import { ofType } from 'redux-observable'
import {
  DOCK_TYPE_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  dockTypeListGetFAction,
  dockTypeListGetRAction

} from '../action/dockTypeAction'
import typeService from '../../services/typeService'

export const getDockTypesEpic = (action$ : any) => {
  return action$.pipe(
    ofType(DOCK_TYPE_LIST_GET),
    switchMap((action : any) => {
      return typeService.getDockType(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in shipTypeEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return dockTypeListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(dockTypeListGetRAction(err)))
      )
    })
  )
}