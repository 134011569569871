import { ACCOUNT_GET_F, ACCOUNT_SET } from '../actionType'

const initialState = {
  account: null,
  authenticated: false
}

export default function accountReducer  (state = initialState, action : any)  {
  switch (action.type) {
    case ACCOUNT_SET:
      return {
        ...state,
        account: action.payload,
        authenticated: true
      }
    case ACCOUNT_GET_F:
      return {
        ...state,
        account: action.payload,
        authenticated: true
      }
    default:
      return state
  }
}
