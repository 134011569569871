import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row ,Button} from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import typeService from '../../../../services/typeService'
import { history } from '../../../../store'
import swal from 'sweetalert';
import '../../../../styles/variables/Common.scss'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner';      


interface dockTypeAddProps {
  name: string;
  description: string;
  name_english : string;
  name_description : string;
}
interface FormData {
  txt_name: string;
  txt_description: string;
  txt_name_english : string;
  txt_name_description : string;
}
export const DockTypeAdd = (props: dockTypeAddProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
});
  const [dockTypeaddflag, setdockTypeAddFlag] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const [dock, setDock] = useState<dockTypeAddProps>({
    name: '',
    description: '',
    name_english : '',
    name_description : ''
  },
  )
  
// After enter all details by user and click submit button values assign to some variable then it is used as input parameter for adddock api call. 
  const _didDockTypeAdd = () => {
    // e.preventDefault()
    const updatedDockTypeForm = {
        name: dock.name,
        description: dock.description,
        name_english : dock.name_english,
        name_description : dock.name_description
    } 
    
    // API call for addship with data, added to list. 
    // console.log(updatedDockTypeForm,"updatedDockTypeForm")
    if(dockTypeaddflag === true){
      setLoading(true)
      typeService.addDockType(updatedDockTypeForm).subscribe({
        next:  (r) => {
          setLoading(false)
          // console.log(r,'response')
          swal("Great","Dock Type Added Successfully" ,"success")
          history.push('/dockTypeList')
        },
        error: err => {
          setLoading(false)
          swal("OOPS",String(err),"error")
        }
      }) 
    }
    else{
      console.log(dockTypeaddflag)
    }
  }

  onsubmit = (e: any) =>{
    // e.preventDefault()
    console.log(Object.keys(errors).length , 'error length')
    if(Object.keys(errors).length === 0){
        _didDockTypeAdd()
        setdockTypeAddFlag(true)
    }
    else{
        setdockTypeAddFlag(false)
    }
}
if (isLoading) {
  return <Text center>
      <Loader
          type="Rings"
          color="#233269"
          height={100}
          width={100}
          timeout={30000}
      />
  </Text>
}

  return (
    <Main>
      <Text variant="title" color='black'>Add Dock Type Information</Text>
      <Space divheight={25} />
      <Grid templateCols='75% auto' className='add_page'>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='dockTypeAddForms'>
            <Card title="Fill All the Information Below">
                <Row className='m-0 p-0'>
                <Col xs= '12' md = '12' lg ='6' xl ='6' className='p-0 m-0'>
                    <Attributes>
                        <div className='sm-mt-3'>
                            <Text color='tablehead' variant='subtitle' >Dock Type</Text>
                            <Form.Control className='basecontrol custome-input'
                            type="text"
                            name="txt_name"
                            value={dock.name}
                            onChange={(e) => setDock({ ...dock, name: e.target.value })}
                            ref={register({ required: true, })}
                            placeholder=" Type"
                            />
                        </div>
                        <Row className='m-0 p-0'>
                          <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                          </Col>
                          <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                          <span className='err-msg text-capitalize'>{errors.txt_name?.type === 'required' && 'Please enter Dock Type'}
                              </span>
                          </Col>
                        </Row>
                        <div>
                            <Text color='tablehead' variant='subtitle'>Description</Text>
                            <Form.Control as="textarea"
                            className='basecontrol custome-textarea'
                            placeholder='Description'
                            rows={3}
                            cols={50}
                            name="txt_description"
                            value={dock.description}
                            onChange={(e) => setDock({...dock, description: e.target.value})}
                            ref={register({ required: true, })}
                            />
                        </div>
                        <Row className='m-0 p-0'>
                          <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                          </Col>
                          <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                          <span className='err-msg text-capitalize'>{errors.txt_description?.type === 'required' && 'Please enter Description'}
                              </span>
                          </Col>
                        </Row>
                    </Attributes>
                </Col>
                <Col xs= '12' md = '12' lg ='6' xl ='6' className='p-0 m-0'>
                    <Attributes >
                        <div className='sm-mt-3'>
                            <Text color='tablehead' variant='subtitle' >Dock Type in English</Text>
                            <Form.Control className='basecontrol custome-input'
                            type="text"
                            name="txt_name_english"
                            value={dock.name_english}
                            onChange={(e) => setDock({ ...dock, name_english: e.target.value })}
                            ref={register({ required: true, })}
                            placeholder="Dock Type English"
                            />
                        </div>
                        <Row className='m-0 p-0'>
                          <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                          </Col>
                          <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                          <span className='err-msg text-capitalize'>{errors.txt_name_english?.type === 'required' && 'Please enter Name in english'}
                              </span>
                          </Col>
                        </Row>
                        <div>
                            <Text color='tablehead' variant='subtitle'>Description in English</Text>
                            <Form.Control as="textarea"
                            className='basecontrol custome-textarea'
                            placeholder='Description in English'
                            rows={3}
                            cols={50}
                            name="txt_name_description"
                            value={dock.name_description}
                            onChange={(e) => setDock({...dock, name_description: e.target.value})}
                            ref={register({ required: true, })}
                            />
                        </div>
                        <Row className='m-0 p-0'>
                          <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                          </Col>
                          <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                          <span className='err-msg text-capitalize'>{errors.txt_name_description?.type === 'required' && 'Please enter description in english'}
                              </span>
                          </Col>
                        </Row>
                    </Attributes>
                </Col>
                </Row>
            </Card>
          <Actions>
            {/* If you click the back button its is redirect to ships list page.  */}
            <Link to='/dockTypeList'>
              <Button className='bttn bttn-transparent'>
                <Flex>
                  <Icons color="#ffffff" icon="arrow_back" />
                  Back
                </Flex>
              </Button>
            </Link>
            {/* If you click the save button its is call the function, in that data added to list and redirect to list page.  */}
            <Button className='bttn bttn-transparent' type="submit">
              <Flex>
                <Icons color="#ffffff" icon="check" />
                  SAVE DATA
              </Flex>
            </Button>
          </Actions>
        </Form>
      </Grid>
      {/* Add form, having input box, select box.  */}
    </Main>
  )
}


export interface FormProps {
  children?: any | JsxChild;
  onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style. 
const StyledForm = ({ ...props }: FormProps) => {

  const frm = classNames('Form-container-ship-add');
  return (
    <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
      {props.children}
    </Form>
  )
}

export interface ActionProps {
  children?: React.ReactNode
}
// Button style. 
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-between' className={action}>
      {props.children}
    </Flex>
  )
}
