import { ofType } from 'redux-observable'
import {
    DATA_SHIP_PROPULSION_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import datalasticPropulsionService from '../../services/propulsionSevice'
import {
    dataPropulsionListGetFAction,
    dataPropulsionListGetRAction,
} from '../action/propulsionAction'

export const dataPropulsionEpics = (action$: any) => {
    return action$.pipe(
        ofType(DATA_SHIP_PROPULSION_LIST_GET),
        switchMap((action: any) => {
            return datalasticPropulsionService.getPropulsionList(action.payload).pipe(
                map((res: any) => {
                    // console.log(action.payload,'action payload in ship company')
                    const { content, total_page, total_element } = res.data
                    // console.log(content,'content shop epic')
                    // console.log(total_page)
                    // console.log(total_element)
                    return dataPropulsionListGetFAction({ content, total_page, total_element })
                }),
                catchError((err: any) => of(dataPropulsionListGetRAction(err)))
            )
        })
    )
}

