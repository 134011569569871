import React from 'react'
import { Main } from '../../../../common/components/main/Main'
import { Col, Form, Row, Button } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
// import { Input, Select, Textarea } from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import classNames from 'classnames'
import { JsxChild } from 'typescript'
import { useState } from 'react'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { history } from '../../../../store'
import { connect } from 'react-redux'
import { dockTypeListGetAction, dockTypeSetSelectedAction } from '../../../../store/action/dockTypeAction'
import typeService from '../../../../services/typeService'
import swal from 'sweetalert';
import { useForm } from 'react-hook-form'

// Blue print of the class component.  
interface dockTypeEditProps {
    id: string | number;
    name: string;
    description: string;
    name_english: string;
    name_description: string;
}
interface FormData {
    txt_name: string;
    txt_description: string;
    txt_name_english: string;
    txt_name_description: string;
}
const DockTypeEdit = (props: any) => {
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
      });
    const [dockTypeEditflag, setdockTypeEditFlag] = React.useState(false)
    const [display, setDisplay] = React.useState<dockTypeEditProps>({
        id: '',
        name: '',
        description: '',
        name_english: '',
        name_description: ''
    })
    const [dockType, setDockType] = useState<any>({
        isLoading: false,
        isSubmitting: false,
        name: '',
        description: '',
        name_english: '',
        name_description: ''
    },
    )
    const { match } = props
    const { pages } = match.params

    // Used to get perticular ship detail and store to variable.   
    const _getDockType = ((id: any) => {
        setDockType({ ...dockType, isLoading: true })
        return typeService.getDockTypeDetail(id).subscribe({
            next: r => {
                // console.log(r,'response')
                // console.log(r.data, 'data within response')
                const value = r.data
                // console.log(value, 'data within response using value')
                setDisplay({
                    id: Number(r.data.id),
                    name: r.data.name,
                    description: r.data.description,
                    name_english: r.data.name_english,
                    name_description: r.data.name_description
                })
                props.dockTypeSetSelectedAction(value)
                setDockType({ ...dockType, isLoading: false, })
            },
            error: err => {
                console.error(err)
                setDockType({ ...dockType, isLoading: false })
            }
        })
    })
    React.useEffect(() => {
        const { id } = match.params
        _getDockType(id)
    }, [])

    // After update all details by user and click submit button values assign to some variable then it is used as input parameter for addship api call.  
    const _didDockTypeEdit = () => {
        // e.preventDefault()
        const updatedDockAddForm = {
            id: display.id,
            name: display.name,
            description: display.description,
            name_english: display.name_english,
            name_description: display.name_description
        }


        // API call for updateship with data, updated into list page.
        if (dockTypeEditflag === true) {
            typeService.updateDockType(updatedDockAddForm).subscribe({
                next: () => {
                    // dockTypeListGetAction({page:pages, size:10})
                    swal("Great", "Dock Type Updated Successfully", "success")
                    history.push('/dockTypeList')
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    console.error(err)
                }
            })
        }
        else {
            console.log(dockTypeEditflag)
        }
    }
    onsubmit = (e: any) => {
        // e.preventDefault()
        console.log(Object.keys(errors).length, 'error length')
        if (Object.keys(errors).length === 0) {
            _didDockTypeEdit()
            setdockTypeEditFlag(true)
        }
        else {
            setdockTypeEditFlag(false)
        }
    }
    return (
        <Main>
            <Text color='black' variant="title">Edit Dock Type Information</Text>
            <Space divheight={25} />
            {/* Edit form, having input box, select box.   */}
            <Grid templateCols='75% auto' className='add_page'>
                <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='dockTypeEditForms'>
                    <Card title="Fill All the Information Below">
                        <Row className='m-0 p-0'>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes>
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Dock Type</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_name"
                                            value={display.name}
                                            onChange={(e) => setDisplay({ ...display, name: e.target.value })}
                                            ref={register({ required: true, })}
                                            placeholder=" Type"
                                        />
                                    </div>
                                    <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_name?.type === 'required' && 'Please enter Dock Type'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Description</Text>
                                        <Form.Control as="textarea"
                                            className='basecontrol custome-textarea'
                                            rows={3}
                                            cols={50}
                                            name="txt_description"
                                            placeholder='Description'
                                            value={display.description}
                                            onChange={(e) => setDisplay({ ...display, description: e.target.value })}
                                            // ref={register({ required: true, })}
                                        />
                                    </div>
                                    {/* <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_description?.type === 'required' && 'Please enter Description'}
                                            </span>
                                        </Col>
                                    </Row> */}
                                </Attributes>
                            </Col>
                            <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes >
                                    <div className='sm-mt-3'>
                                        <Text color='tablehead' variant='subtitle' >Dock Type in English</Text>
                                        <Form.Control className='basecontrol custome-input'
                                            type="text"
                                            name="txt_name_english"
                                            value={display.name_english}
                                            onChange={(e) => setDisplay({ ...display, name_english: e.target.value })}
                                            // ref={register({ required: true, })}
                                            placeholder="Dock Type English"
                                        />
                                    </div>
                                    {/* <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_name_english?.type === 'required' && 'Please enter Name in english'}
                                            </span>
                                        </Col>
                                    </Row> */}
                                    <div>
                                        <Text color='tablehead' variant='subtitle'>Description in English</Text>
                                        <Form.Control as="textarea"
                                            className='basecontrol custome-textarea'
                                            placeholder='Description in English'
                                            rows={3}
                                            cols={50}
                                            name="txt_name_description"
                                            value={display.name_description}
                                            onChange={(e) => setDisplay({ ...display, name_description: e.target.value })}
                                            // ref={register({ required: true, })}
                                        />
                                    </div>
                                    {/* <Row className='m-0 p-0'>
                                        <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                        </Col>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <span className='err-msg text-capitalize'>{errors.txt_name_description?.type === 'required' && 'Please enter description in english'}
                                            </span>
                                        </Col>
                                    </Row> */}
                                </Attributes>
                            </Col>
                        </Row>
                    </Card>
                    <Actions>
                        {/* If you click the back button its is redirect to dockType list page.   */}
                        <Link to='/dockTypeList'>
                            <Button type="button" className='bttn bttn-transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                BACK
                                </Flex>
                            </Button>
                        </Link>
                        {/* If you click the save button its is call the function, in that data updated to list and redirect to list page.   */}
                        <Button className='bttn bttn-transparent' type="submit">
                            <Flex>
                                <Icons color="#ffffff" icon="check" />
                            SAVE DATA
                            </Flex>
                        </Button>
                    </Actions>
                </Form>
            </Grid>
        </Main>
    )
}


export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

//Form style.  
const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container-ship-add');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}

// Button style.  
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    ship: state.dockType.selectedDockType
})
//Return the selected dock details.  
export default connect(
    mapStateToProps,
    {
        dockTypeSetSelectedAction,
        dockTypeListGetAction
    }
)(DockTypeEdit)
