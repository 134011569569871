import React, { useRef, useState } from 'react'
import Modal from '../../../../common/components/modal/Modal'
import { Text } from '../../../../common/components/text/Text'
import { Col, Form, Button, Row } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Input, Select } from '../../../../common/components/control/Control'
// import { Button } from '../../../../common/components/button/Button'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import { connect } from 'react-redux'
import classNames from 'classnames'
import RoleService from '../../../../services/roleService'
import { useForm } from 'react-hook-form'
import '../Role.scss'
import { useEffect } from 'react'
import swal from 'sweetalert'
import codeService from '../../../../services/codeService'
import Loader from 'react-loader-spinner';      


interface roleProps {
  id: string;
  role: string;
  code: string;
}
interface FormData {
  txt_roleName: string;
  txt_rolecode: string;
}
const RolesAdd = (props: any) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const [roleaddflag, setroleAddFlag] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const { roles } = props
  const { edit } = props
  const { view } = props
  const { id } = props
  //const { add } = props
  const { onClose } = props
  const [form, setForm] = useState<roleProps>({
    id: '',
    role: '',
    code: ''
  })
  const [editForm, setEditForm] = useState({
    id: '',
    role: '',
    code: ''
  })

  const [modalTitle, setModalTitle] = useState('')
  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevUser = usePrevious(roles);

  useEffect(() => {
    if (roles && prevUser !== roles) {
      _getSelectedUser()
    }

    if (edit) {
      setModalTitle('Edit Role Detail')
    }

    else {
      setModalTitle('Role Detail')
    }
  }, [roles])

  const _getSelectedUser = () => {
    const { roles } = props
    setEditForm({
      id: roles.id,
      role: roles.name,
      code: roles.code
    })
    // console.log(roles,'props user')
    // setForm({ 
    //   name: user.name,
    //   email: user.email,
    //   password: user.password,
    //   roles: [],
    //   role:user.roles.id,
    //   status: user.status
    //  })
    //  console.log(form,'userForm')
  }

  const _didaddRole = () => {
    // e.preventDefault()
    const { onClose } = props

    const payload = {
      name: editForm.role,
      code: editForm.code,
    }
    if (roleaddflag === true) {
      if (edit) {
        _willUpdate()
      }
      else {
        setLoading(true)
        RoleService.addRole(payload).subscribe({
          next: (r) => {
            setLoading(false)
            swal("Great", "New Role Added Successfully", "success")
            onClose()
          },
          error: err => {
            setLoading(false)
            console.error(err)
            swal("OOPS", String(err), "error")
          }
        })

      }
    }
    else {
      console.log(roleaddflag, 'flag')
    }

  }
  const _willUpdate = () => {
    const { onClose } = props
    const { user_id } = props
    const updatepayload = {
      id: editForm.id,
      name: editForm.role,
      code: editForm.code,
    }
    setLoading(true)
    return RoleService.updateRole(updatepayload).subscribe({
      next: () => {
        setLoading(false)
        swal("Great", "Role Updated Successfully", "success")
        onClose()
      },
      error: err => {
        setLoading(false)
        swal("OOPS", String(err), "error")
        console.error(err)
      }
    })
  }

  onsubmit = (e: any) => {
    // e.preventDefault()
    console.log(Object.keys(errors).length, 'error length')
    if (Object.keys(errors).length === 0) {
      _didaddRole()
      setroleAddFlag(true)
    }
    else {
      setroleAddFlag(false)
    }
  }
  if (isLoading) {
    return <Text center>
        <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
        />
    </Text>
}
  return (
    <Modal
      onClose={() => onClose()}
      title={!roles ? 'Add New Role' : modalTitle}>
      <ModalBody>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='roleAddForms'>
          <Attributes>
            {view && (
              <div>
                <Text color='black' variant='subtitle'>ID</Text>
                <Text color='main' bold variant='subtitle'>{editForm.id}</Text>
              </div>
            )}
            <div>
              <Text color='black' variant='subtitle'>Role</Text>
              {view && (
                <Text color='main' bold variant='subtitle'>{editForm.role}</Text>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_roleName"
                  placeholder='Role'
                  value={editForm.role}
                  ref={register({ required: true, })}
                  onChange={(e) => setEditForm({ ...editForm, role: e.target.value })}
                  type="text"
                />
              )
              }
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_roleName?.type === 'required' && 'Please enter Role'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Code</Text>
              {view && (
                <Text color='main' bold variant='subtitle'>{editForm.code}</Text>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_rolecode"
                  value={editForm.code}
                  placeholder='Code'
                  ref={register({ required: true, })}
                  onChange={(e) => setEditForm({ ...editForm, code: e.target.value })}
                  type="text"
                />
              )}
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_rolecode?.type === 'required' && 'Please enter Code'}
                </span>
              </Col>
            </Row>
          </Attributes>
          <Space divheight={20} />
          <Flex jc='jc-center'>
            <Button
              type="button"
              onClick={() => onClose()}
              className='bttn bttn-transparent'>
              <Flex>
                <Icons icon="arrow_back" />
                BACK
              </Flex>
            </Button>
            {!view && (
              <Button className='bttn bttn-transparent' type="submit">
                <Flex>
                  <Icons color="#ffffff" icon="check" />
                  SAVE DATA
                </Flex>
              </Button>
            )}
          </Flex>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export interface ModalbodyProps {
  children?: React.ReactNode
}

export const ModalBody = ({ children, ...props }: ModalbodyProps) => {

  const modal = classNames('modalbody-container')

  return (
    <div className={modal}>
      {children}
    </div>
  )

}
export interface AttributeProps {
  children?: React.ReactNode
}
export const Attributes = ({ children, ...props }: AttributeProps) => {
  const attribute = classNames('attribute-container');

  return (
    <div {...props} className={attribute} >
      {children}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  roles: state.roles.selectedRoles
})
export default connect(
  mapStateToProps)(RolesAdd)
