import React from 'react';
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap';
import './Units.scss';
import '../../../common/components/pagination/Pagination.scss'
import { Text } from '../../../common/components/text/Text'
import { Space } from '../../../common/components/space/Space';
import { Main } from '../../../common/components/main/Main';
import { Button } from '../../../common/components/button/Button';
import Card from '../../../common/components/card/Card';
import { Table } from '../../../common/components/table/Table';
import Icons from '../../../common/components/icon/Icon';
// import { format, parseISO } from 'date-fns'
import { unitsListGetAction, unitsSetSelectedAction } from "../../../store/action/unitAction";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { history } from '../../../store';
import { Flex } from '../../../common/components/flex/Flex';
import { ReactNode } from 'react'
import unitService from '../../../services/unitService';
import swal from 'sweetalert'
import Loader from 'react-loader-spinner';
import { InputWithIcon } from '../../../common/components/control/Control';

const UnitsList = (props: any) => {
  const { units, isLoading, totalElement, totalPage } = props
  const [displayUnits, setUnits] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [searchQuery, setQuery] = React.useState('')
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const perPage = 9
  const [showDelete, setShow] = React.useState(false);
  const [deleteUnit, setDeleteUnit] = React.useState(0);
  const [searchInput, setSearchInput] = React.useState('');

  // Used to get type and payload, call action and get list.   
  React.useEffect(() => {
    props.unitsListGetAction({ size: 10, page, q: searchQuery })
  }, [page, searchQuery])

  // Check ships(list) length, add checked false in that array.  
  React.useEffect(() => {
    // console.log(units,'ship type in ship type lisst page')
    if (units && units.length > 0) {
      setUnits(() => {
        return units.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (units && units.length === 0) {
      setUnits([])
    }
  }, [units])

  // Get totalPage in props and push into the pagination variable.   
  React.useEffect(() => {
    const arrayPage = Array.from(Array(props.totalPage).keys())
    setPagination(arrayPage)
  }, [props.totalPage])

  // Redirect to ship detail page.  
  const _willViewDetail = (units: any) => {
    unitsSetSelectedAction(units)
    history.push(`/units/${units.id}`)
  }

  // Redirect to ship edit page.  
  const _willViewEdit = (units: any) => {
    props.unitsSetSelectedAction(units)
    history.push(`/units/edit/${units.id}`)
  }

  // Delete the pericular list.  
  // Delete the pericular list.
  const handleClose = () => setShow(false);
  const handleShow = (units: any) => {
    setShow(true);
    setDeleteUnit(units.id)
    // console.log(deleteAnode, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deleteUnit(deleteUnit)
  }


  const _deleteUnit = (id: any) => {
    return unitService.deleteUnit(id).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.unitsListGetAction({ size: 10, page, q: searchQuery })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }

  const _showAll = () => {
    setSearchInput('')
    props.unitsListGetAction({ size: 10, page, q: searchQuery })
  }

  const _searchFuntion = (value: string) => {
    setSearchInput(value)
    props.unitsListGetAction({ size: 10, page, q: searchQuery })
    setPage(0)
  }


  // Prints first page pagination.  
  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  // While click the last page its move to last page.  
  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 9)
  }

  // While click the first button its move to first page.  
  const _goToFirstPage = () => {
    setPage(0)
    setHigh(9)
    setLow(1)
  }

  // Prints last page pagination button.  
  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  // Prints prev button.  
  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  // While click prev button go to the previous page of current page.  
  const _goPrevPage = () => {
    setPage(prev => {
      const newValue = prev - 1
      return newValue
    })
  }

  // Print next button.  
  const nextBtn = () => {
    if (page < totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  // While click next page button go to the next page of the current page.  
  const _goNextPage = () => {
    setPage(prev => {
      const newValue = prev + 1
      return newValue
    })
  }

  // While click one page number its move to that page.  
  const _goToPage = (page: any) => {
    setPage(page)
  }

  // Return the page number
  const ListItem = (page: any) => {
    return page.value + 1
  }

  // Prints the totalpages between prev, next button.  
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })

  return (
    <Main>
      <Text variant="title" color='black'>Unit List</Text>
      <Space divheight={15} />
      <Card>
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-2 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    <Link to='/unit/add'>   {/* Button add new ship, if you click the button it shows modal box.   */}
                      <Button variant='transparent' block={true}>
                        <Icons color="#ffffff" icon="add" />
                        ADD NEW UNIT
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SearchForm>
        {/* <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-2 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    <InputWithIcon
                      type="text"
                      value={searchInput}
                      onChange={(e) => _searchFuntion(e.target.value)}
                      placeholder="Search by Unit.."
                      icon="search"
                      id="search-inputUnit"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='7' xl='7' className='m-0 p-0'>
              <Row className='m-0 p-0'>
                <Col xs='6' sm='4' md='4' lg='3' xl='3' className='m-0 p-0 mr-2'>
                  <Link to='/unit/add'>   
                    <Button variant='transparent' block={true}>
                      <Icons color="#ffffff" icon="add" />
                      ADD NEW UNIT
                    </Button>
                  </Link>
                </Col>
                <Col xs='5' sm='4' md='4' lg='3' xl='3' className='m-0 p-0 '>
                  <div>
                    <Button className='ml-2' variant='transparent' block={true} onClick={() => _showAll()}>
                      Show All
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SearchForm> */}
        <Space divheight={30} />
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>} {/* Check the list is loading, isloading is true its shows loading text.  */}
        {!isLoading && displayUnits.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No Units found</Text>
            <Space divheight={20} />
          </>
        )} {/* If isloading is false and also the list array length is 0 its shows no discount fount text.   */}
        {!isLoading && displayUnits.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Unit</th>
                  <th>Representation</th>
                  <th>Unit Type</th>
                  <th>Unit in English</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Map the list array and display the datas in a table.   */}

                {/* {console.log(displayUnits)} */}
                {displayUnits.map((data: any, i: any) => (
                  <tr key={data.id}>
                    {/* {console.log(data)} */}
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='Unit'>
                      {data.name === null ? (
                        <Text color='tabledata' variant='subtitle'> - </Text>
                      ) : (
                        <Text color='tabledata' variant='subtitle'>{data.name}</Text>
                      )}
                    </td>
                    <td data-label='Representation'>
                      {data.representation === null ? (
                        <Text color='tabledata' variant='subtitle'> - </Text>

                      ) : (
                        <Text color='tabledata' variant='subtitle'>{data.representation}</Text>
                      )}
                    </td>
                    <td data-label='Unit Type'>
                      {data.type_of_unit === null ? (
                        <Text color='tabledata' variant='subtitle'> - </Text>
                      ):(
                        <Text color='tabledata' variant='subtitle'>{data.type_of_unit}</Text> 
                      )}
                    </td>
                    <td data-label='Unit in English'>
                      {data.name_english === null ? (
                        <Text color='tabledata' variant='subtitle'> - </Text>
                      ):(
                        <Text color='tabledata' variant='subtitle'>{data.name_english}</Text>
                      )}
                    </td>
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="unit-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(data)}> {/* While onclick open view page.   */}
                            <Icons color="#52575a" icon="visibility" /> {/* View icon.  */}
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={
                          <Tooltip id="unit-edit"> Edit
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewEdit(data)}> {/* While onclick open edit page.   */}
                            <Icons color="#52575a" icon="edit" /> {/* Edit icon.  */}
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={
                          <Tooltip id="unit-delete"> Delete
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => handleShow(data)}>
                            <Icons color="#52575a" icon="delete" />
                          </Button>
                        </OverlayTrigger>
                      </TableActions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text> {/* Display the total elements.  */}
                {displayUnits.length === 1 && (
                  <>
                    <b>
                      {displayUnits.length}
                      {/* {((page)*10)+1} - {(displayUnits.length+(page)*10)} */}
                    </b> of {totalElement} unit
                  </>
                )}
                {displayUnits.length > 1 && (
                  <>
                    <b>
                      {displayUnits.length}
                      {/* {((page)*10)+1} - {(displayUnits.length+(page)*10)} */}
                    </b> of {totalElement} units
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()} {/* First page button.   */}
                {prevBtn()} {/* Previous button.   */}
                {PageNumber} {/* Totalpages.   */}
                {nextBtn()} {/* Next button.   */}
                {lastPage()} {/* Last page button.   */}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {/* If modal is true goto the delete box with the props of onclose function */}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form Units list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Main>
  )
}
export interface TablefooterProps {
  children?: React.ReactNode
}

// Table footer style.  
const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

// Table body style.  
const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

//Search Form Component  for displaying serarch box, and add ship button
const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

// Declare the variable.  
const mapStateToProps = (state: any) => ({
  units: state.units.units,
  isLoading: state.units.isLoading,
  error: state.units.error,
  totalElement: state.units.totalElement,
  totalPage: state.units.totalPage
})

// Connect to the action and get the list, totalpage and total elements.  
export default connect(
  mapStateToProps,
  {
    unitsListGetAction,
    unitsSetSelectedAction,
  }

)(UnitsList)