import{CMP_TYPE_LIST_GET,
    CMP_TYPE_LIST_GET_F,
    CMP_TYPE_LIST_GET_R,
    CMP_TYPE_SELECTED_SET
} from '../actionType'

export const cmpTypeListGetAction = (params : any) => ({
    type: CMP_TYPE_LIST_GET,
    payload: params
})

export const cmpTypeListGetFAction = (params : any) => ({
    type: CMP_TYPE_LIST_GET_F,
    payload: params
})

export const cmpTypeListGetRAction = (params : any) => ({
    type: CMP_TYPE_LIST_GET_R,
    payload: params
})

export const cmpTypeSetSelectedAction = (cmpType : any) => ({
    type: CMP_TYPE_SELECTED_SET,
    payload: cmpType
})