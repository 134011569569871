import {
  SHIP_COMPANY_LIST_GET,
  SHIP_COMPANY_LIST_GET_F,
  SHIP_COMPANY_LIST_GET_R,
  SHIP_COMPANY_SELECTED_SET,
  SHIP_LIST_GET,
  SHIP_LIST_GET_F,
  SHIP_LIST_GET_R,
  ALL_SHIP_LIST_GET,
  ALL_SHIP_LIST_GET_F,
  ALL_SHIP_LIST_GET_R,
  SHIP_OWNER_LIST_GET,
  SHIP_OWNER_LIST_GET_F,
  SHIP_OWNER_LIST_GET_R,
  SHIP_OWNER_SELECTED_SET,
  SHIP_SELECTED_SET,
} from '../actionType'

export const shipListGetAction = ( params : any) => ({
  type: SHIP_LIST_GET,
  payload: params
})

export const shipListGetFAction = ( data : any) => ({
  type: SHIP_LIST_GET_F,
  payload: data
})

export const shipListGetRAction = ( error : any) => ({
  type: SHIP_LIST_GET_R,
  payload: error
})

export const allShipListGetAction = ( params : any) => ({
  type: ALL_SHIP_LIST_GET,
  payload: params
})

export const allShipListGetFAction = ( data : any) => ({
  type: ALL_SHIP_LIST_GET_F,
  payload: data
})

export const allShipListGetRAction = ( error : any) => ({
  type: ALL_SHIP_LIST_GET_R,
  payload: error
})

export const shipSetSelectedAction = (ship : any) => ({
  type: SHIP_SELECTED_SET,
  payload: ship
})

export const shipCompanyListGetAction = (params : any) => ({
  type: SHIP_COMPANY_LIST_GET,
  payload: params
})

export const shipCompanyListGetFAction = (data : any) => ({
  type: SHIP_COMPANY_LIST_GET_F,
  payload: data
})

export const shipCompanyListGetRAction = ( error : any) => ({
  type: SHIP_COMPANY_LIST_GET_R,
  payload: error
})

export const shipCompanySetSelectedAction = (company : any) => ({
  type: SHIP_COMPANY_SELECTED_SET,
  payload: company
})

export const shipOwnerListGetAction = (params : any) => ({
  type: SHIP_OWNER_LIST_GET,
  payload: params
})

export const shipOwnerListGetFAction = (data : any) => ({
  type: SHIP_OWNER_LIST_GET_F,
  payload: data
})

export const shipOwnerListGetRAction = (error : any) => ({
  type: SHIP_OWNER_LIST_GET_R,
  payload: error
})

export const shipOwnerSetSelectedAction = (shipowner : any) => ({
  type: SHIP_OWNER_SELECTED_SET,
  payload: shipowner
})

