import {
    SHIP_SUBTYPE_LIST_GET,
    SHIP_SUBTYPE_LIST_GET_F,
    SHIP_SUBTYPE_LIST_GET_R,
    SHIP_SUBTYPE_SELECTED_SET
} from '../actionType'

const initialState = {
    ship_sub_types: [],
    isLoading: false,
    error: null,
    totalPage: 0,
    totalElement: 0,
    selectedShipSubType: null,
}

export default function shipSubTypeReducer (state = initialState, action : any)  {
    switch (action.type) {
        case SHIP_SUBTYPE_LIST_GET:
            return {
            ...state,
            isLoading: true,
            error: null
            }
        case SHIP_SUBTYPE_LIST_GET_F:
            // console.log('action called')
            // console.log(action.payload.content,'content')
            var totalsubCount = 0
           
            if(action.payload.content === undefined || '')
            {
                totalsubCount = 0
            }
            else{
                action.payload.content.map((data:any) => {
                    totalsubCount = data.total_row
                })
            }
            // console.log(totalsubCount,'sub count')
            return {
                ...state,
                isLoading: false,
                ship_sub_types: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: totalsubCount
            }
        case SHIP_SUBTYPE_LIST_GET_R:
            
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SHIP_SUBTYPE_SELECTED_SET:
            return {
                ...state,
                selectedShipSubType: action.payload
            }
        default:
            return state
    }
}