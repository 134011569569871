import React, { useState } from 'react';
import { Main } from '../../../common/components/main/Main'
import { Row, Form, Col, Button } from 'react-bootstrap'
// import {Button} from '../../../common/components/button/Button'
import '../../../common/components/control/Control.scss'
import '../../../common/components/button/Button.scss'
import { Text } from '../../../common/components/text/Text'
import Card from '../../../common/components/card/Card'
import { Grid } from '../../../common/components/grid/Grid'
import { Flex } from '../../../common/components/flex/Flex'
// import {Input, Radio, Select, Textarea} from '../../../common/components/control/Control'
import { Space } from '../../../common/components/space/Space'
import { Attributes } from '../../../common/components/attribute/Attribute'
import userService from '../../../services/userService'
import codeService from '../../../services/codeService'
import classNames from 'classnames'
import Icons from '../../../common/components/icon/Icon'
import { JsxChild } from 'typescript'
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns'
import { userListGetAction, userSetSelectedAction } from '../../../store/action/userActions';
import { history } from '../../../store';
import swal from 'sweetalert'
// import Select from 'react-select'
import './userList.scss'
import Loader from 'react-loader-spinner';
import { Label } from '../../../common/components/label/Label';
import { useForm } from 'react-hook-form'

interface FormData {
    txt_MapcmpName: string;
    radio_CompanyType: string;
};

const MapCompany = (props: any) => {
    const { match } = props
    const { id } = match.params
    const { addCmpId } = match.params
    const { type } = match.params
    const { edit } = match.params
    const [isLoading, setLoading] = React.useState(false)
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
      });
    const [userMapFlag, setUserMapFlag] = React.useState(false)
    const [display, setDisplay] = useState<any>({
        companyTypes: [],
        company_Type: '',
        companiesName: [],
        companyName: '',
        typName: '',
        MapCompanyID:'',
        MapCompanyFromOtherID:''
    })
    const [userDetail, setUserDetail] = React.useState<any>({
        userName: '',
        userEmail: '',
        userPhone: '',
        userPhoneCode: '',
        userAddress: '',
        userRole: '',
        userPosition: '',
        userExperience: '',
        userExpertise: '',
        userEducation: '',
        userStatus: '',
        userActiveStatus: false,
        userCompany: '',
        userCreated: '',
        userCmpAddress: '',
        userCmpPhone: '',
        userCmpPhnCode: '',
    })
    const { user } = props
    const _getUserInformationForMap = (id: any) => {
        setLoading(true)
        const { user } = props
        return userService.getUserDetail(id).subscribe({
            next: r => {
                // console.log(r.data, 'data in map company')
                var userRoles = ''

                if (r.data.role.length === 0 || r.data.role === null) {
                    userRoles = ''
                }
                else {

                    userRoles = r.data.role[0].code
                }
                setUserDetail({
                    userName: r.data.name,
                    userEmail: r.data.email,
                    userPhone: r.data.phone_number,
                    userPhoneCode: r.data.phone_code,
                    userAddress: r.data.address,
                    userRole: userRoles,
                    userPosition: r.data.position,
                    userExperience: r.data.experience,
                    userExpertise: r.data.expertise,
                    userEducation: r.data.education,
                    userStatus: r.data.status,
                    userActiveStatus: r.data.enabled,
                    userCompany: r.data.company_name,
                    userCreated: r.data.created_at,
                    userCmpAddress: r.data.company_address,
                    userCmpPhone: r.data.company_number,
                    userCmpPhnCode: r.data.company_code,

                })
                setLoading(false)
            },
            error: err => {
                console.error(err)
            }
        })
    }
    const _setDisplayValue = (() => {
        if (type) {
            // console.log(addCmpId, 'add company id')
            _getCmpdd(type)
            _getCmpType()
            _getUserInformationForMap(id)
            // setDisplay({ ...display, companiesName: r.data, typeName:cmpTypeName, company_Type:data })
            setDisplay({ ...display, company_Type: type, companyName: addCmpId })
        }
    })
    const _getCmpType = (() => {
        return codeService.getCompanyTypedd().subscribe({
            next: r => {
                // console.log(r.data,'owners in Dock add page')
                setDisplay({ ...display, companyTypes: r.data })
                display.companyTypes = []
                r.data.map((data: any, i: any) => {
                    return display.companyTypes.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    const _getCmpdd = ((data: any) => {
        var cmpTypeName = data;
        // console.log(cmpTypeName, 'company type name')
        setDisplay({ ...display, company_Type: data })
        return codeService.getAvailableCompanies({ company_type: cmpTypeName }).subscribe({
            next: r => {
                setDisplay({ ...display, companiesName: r.data, typeName: cmpTypeName, company_Type: data })
                r.data.map((data: any, i: any) => {
                    return display.companiesName.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    React.useEffect(() => {
        const { id } = match.params
        _getCmpType()
        _getUserInformationForMap(id)
        _setDisplayValue()
    }, [])


    const _gotoAddPage = () => {
        // console.log(display.typeName, 'Type Name')
        if (display.typeName === 'SUPPORTING_INDUSTRIES') {
            // console.log('supportin industries')
            history.push(`/manufacturer-company-add/${display.typeName}/${id}`)
        }
        else if(type === 'SUPPORTING_INDUSTRIES'){
            history.push(`/manufacturer-company-add/${display.typeName}/${id}`)
        }
        else if (display.typeName === 'DOCK') {
            history.push(`/dock-company-add/${display.typeName}/${id}`)
        }
        else if (type === 'DOCK'){
            history.push(`/dock-company-add/${display.typeName}/${id}`)
        }
        else if (display.typeName === 'SHIP') {
            history.push(`/ship-company-add/${display.typeName}/${id}`)
        }
        else if(type === 'SHIP'){
            history.push(`/ship-company-add/${display.typeName}/${id}`)
        }
        else {
            swal("select Company Type!")
        }
    }
    const _goback = () => {
        history.push('/users')
    }

    
    const _diduserMap = () => {
        // e.preventDefault()
        display.companiesName.map((data: any, i: number) => {
            if(display.companyName === data.name){
                setDisplay({...display, MapCompanyID: data.id})
            }
        })
        display.companiesName.map((data: any, i: number) => {
            if(addCmpId === data.name){
                setDisplay({...display, MapCompanyFromOtherID: data.id})
            }
        })
        // alert(display.MapCompanyID +" display map company id")
        // alert(display.MapCompanyFromOtherID +" display map company id")
        const CompanyMapForm = {
            id: Number(id),
            email: userDetail.userEmail,
            invite_company_id: String(display.MapCompanyID) || String(display.MapCompanyFromOtherID),
            type: display.typeName
        }
        if (userMapFlag === true) {
            userService.mapCompanyToUser(CompanyMapForm).subscribe({
                next: (r) => {
                    swal("Great", "Company Mapped to User", "success")
                    // swal("Great","String(r.msg)","success")
                    history.push(`/users`)
                },
                error: err => {
                    // swal("OOPS",String(err),"error")
                    console.log(err, 'error in map company')
                }
            })
        }
        else{
            console.log(userMapFlag,'flag')
        }
    }

    onsubmit = () =>{
        // e.preventDefault()
        // console.log(Object.keys(errors).length , 'error length')
        if(Object.keys(errors).length === 0){
            setUserMapFlag(true)
            _diduserMap()
        }
        else{
            setUserMapFlag(false)
        }
    }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    return (
        <Main>
            {/* {console.log(userDetail, 'userDetail')} */}
            {display === null && (
                <>
                    <Space divheight={15} />
                    <Text color='main' variant='subtitle' center bold> There is No data for requested id</Text>
                    <Space divheight={15} />
                </>
            )}
            {display !== null && (
                <>
                    <Row className='m-0 p-0'>
                        <Col sm={12}>
                            <div className='title-name-block'> 
                                <Text variant="title" color='black'>Map Company to
                                    
                                </Text>
                                <Text variant='title' color='main'  className='txt-capitalize'>
                                        {userDetail.userName}
                                    </Text>
                                <Label className={userDetail.userActiveStatus === false ? 'danger' : 'warning'} >
                                    {userDetail.userActiveStatus === false ? 'In Active' : 'Active'}
                                </Label>
                            </div>
                        </Col>
                    </Row>
                    <Space divheight={25} />
                    <Grid templateCols='75% auto' className='add_page'>
                        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='userEditForms'>
                            <Card title="User Information">
                                <Row className='m-0 p-0'>
                                    <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>User Name</Text>
                                                {userDetail.userName === '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userName}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Mobile</Text>
                                                {userDetail.userPhone === '' || null ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <>
                                                        {userDetail.userPhoneCode === '' || null ? (
                                                            <Text color='main' bold variant='subtitle'>{userDetail.userPhoneCode}
                                                                <span>
                                                                    <Text color='main' bold variant='subtitle'>{userDetail.userPhone}</Text>
                                                                </span>
                                                            </Text>
                                                        ) : (
                                                            <>
                                                                <Text color='main' bold variant='subtitle'>{userDetail.userPhoneCode} &nbsp;
                                                            <span>
                                                                        <Text color='main' bold variant='subtitle'>{userDetail.userPhone}</Text>
                                                                    </span>
                                                                </Text>
                                                            </>
                                                        )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Role</Text>
                                                {userDetail.userRole === '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userRole}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Position</Text>
                                                {userDetail.userPosition === '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userPosition}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Status</Text>
                                                {userDetail.userStatus === '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userStatus}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Email</Text>
                                                {userDetail.userEmail === '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle' className='txt-email'>{userDetail.userEmail}</Text>
                                                )}
                                            </div>

                                        </Attributes>
                                    </Col>
                                    <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                        <Attributes>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>User Address</Text>
                                                {userDetail.userAddress === '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userAddress}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Education</Text>
                                                {userDetail.userEducation === '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userEducation}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Expertise</Text>
                                                {userDetail.userExpertise === '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userExpertise}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Experience</Text>
                                                {userDetail.userExperience === '' ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'>{userDetail.userExperience}</Text>
                                                )}
                                            </div>
                                            <div>
                                                <Text color='tablehead' variant='subtitle'>Created date</Text>
                                                {userDetail.userCreated === null ? (
                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                ) : (
                                                    <Text color='main' bold variant='subtitle'> -
                                                        {/* {format(parseISO(userDetail.created_at), 'dd/MM/yyyy')} */}
                                                    </Text>
                                                )}
                                            </div>
                                            {/* {edit && (
                                                <>
                                                <div>
                                                <Text color='tablehead' variant='subtitle'>Company Name</Text>
                                                <Input
                                                    name="usercmpName"
                                                    id='usercmpName'
                                                    // value={editUser.editUserCompany}
                                                    placeholder='Company Name'
                                                    required
                                                    // onChange={(e) => setUserEdit({ ...editUser, editUserCompany: e.target.value })}
                                                    type="text"
                                                />
                                                </div>
                                                <div>
                                                    <Text color='tablehead' variant='subtitle'>Company Address</Text>
                                                    <Textarea 
                                                        rows={2}
                                                        cols={30}
                                                        name="usercmpaddress"
                                                        // value={editUser.editUserCmpAddress}
                                                        // onChange={(e) => setUserEdit({...editUser, editUserCmpAddress: e.target.value})}
                                                        required
                                                    />
                                                </div>
                                                </>
                                            )} */}
                                        </Attributes>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0'>
                                    <Col xs='12' className='p-0 m-0'>
                                        <Card title='Company Information'>
                                            <Row className='m-0 p-0'>
                                                <Col sm='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                                                    <Attributes>
                                                        {!edit && (
                                                            <div>
                                                                <Text color='tablehead' variant='subtitle'>Provided Company</Text>
                                                                {userDetail.userCompany === '' ? (
                                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                                ) : (
                                                                    <Text color='main' bold variant='subtitle'>{userDetail.userCompany}</Text>
                                                                )}
                                                            </div>
                                                        )}

                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>Company Contact Number</Text>
                                                            {userDetail.userCmpPhone=== '' || null ? (
                                                                <Text color='main' bold variant='subtitle'>-</Text>
                                                            ) : (
                                                                <>
                                                                    {userDetail.userCmpPhnCode === '' || null ? (
                                                                        <Text color='main' bold variant='subtitle'>{userDetail.userCmpPhnCode}
                                                                            <span>
                                                                                <Text color='main' bold variant='subtitle'>{userDetail.userCmpPhone}</Text>
                                                                            </span>
                                                                        </Text>
                                                                    ) : (
                                                                        <>
                                                                            <Text color='main' bold variant='subtitle'>{userDetail.userCmpPhnCode} &nbsp;
                                                                <span>
                                                                                    <Text color='main' bold variant='subtitle'>{userDetail.userCmpPhone}</Text>
                                                                                </span>
                                                                            </Text>
                                                                        </>
                                                                    )
                                                                    }
                                                                </>
                                                            )}
                                                        </div>
                                                        {!edit && (
                                                            <div>
                                                                <Text color='tablehead' variant='subtitle'>Company Address</Text>
                                                                {userDetail.userCmpAddress === '' ? (
                                                                    <Text color='main' bold variant='subtitle'>-</Text>
                                                                ) : (
                                                                    <Text color='main' bold variant='subtitle'>{userDetail.userCmpAddress}</Text>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Attributes>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='m-0 p-0'>
                                    <Col xs='12' className='p-0 m-0'>
                                        <Card title='Select Company and it`s Type'>
                                            <Row className='m-0 p-0'>
                                                <Col sm='12' md='12' lg='9' xl='9' className='p-0 m-0'>
                                                    <Attributes>
                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>Company Type</Text>
                                                            {type && (
                                                                <div>
                                                                    {display.companyTypes.map((data: any, i: number) => (
                                                                        <Form.Check type='radio' id={`data-${i}`} key={i}
                                                                            name='radio_CompanyType'
                                                                            // value={display.company_Type || type } 
                                                                            className='RadioButton'
                                                                            defaultValue={type}
                                                                            defaultChecked={data.name === type}
                                                                            onChange={e => _getCmpdd(data.name)}
                                                                            ref={register({ required: true, })}
                                                                            label={String(data.name).replace("_", "  ")}>
                                                                        </Form.Check>
                                                                    ))}
                                                                </div>
                                                            )}
                                                            {!type && (
                                                                <div>
                                                                    {display.companyTypes.map((data: any, i: number) => (
                                                                        <Form.Check type='radio' id={`data-${i}`} key={i}
                                                                            name='radio_CompanyType'
                                                                            value={display.company_Type}
                                                                            className='RadioButton'
                                                                            onChange={e => _getCmpdd(data.name)}
                                                                            ref={register({ required: true, })}
                                                                            label={String(data.name).replace("_", "  ")}>
                                                                        </Form.Check>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <Row className='m-0 p-0'>
                                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                            </Col>
                                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                                <span className='err-msg text-capitalize'>{errors.radio_CompanyType?.type === 'required' && 'Please Select Company Type'}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                        {/* <div>
                                                            <Text color='tablehead' variant='subtitle'>Company Name</Text>
                                                            <Form.Control as="select" value={display.companyName || addCmpId}
                                                                name='txt_MapcmpName'
                                                                ref={register({ required: true, })}
                                                                className='basecontrol cutome-selectInputnxt'
                                                                onChange={(e) => setDisplay({ ...display, companyName: e.target.value })} >
                                                                <option value="">-- Select --</option>
                                                                {display.companiesName.map((data: any, i: number) => {
                                                                    return <option key={i} value={data.id}>{data.name}</option>
                                                                })}
                                                            </Form.Control>
                                                        </div> */}
                                                        <div>
                                                            <Text color='tablehead' variant='subtitle'>Company Name</Text>
                                                            <input value={display.companyName || addCmpId}
                                                                name='txt_MapcmpName'
                                                                id='txt_MapcmpName'
                                                                list='companydataList'
                                                                ref={register({ required: true, })}
                                                                className='basecontrol cutome-selectInputnxt'
                                                                placeholder='-- Select Company --'
                                                                onChange={(e) => setDisplay({ ...display, companyName: e.target.value })} >
                                                                {/* <option value=""></option> */}
                                                            </input>
                                                            <datalist id='companydataList'>
                                                                <option value="">none</option>
                                                                {display.companiesName.map((data: any, i: number) => {
                                                                    return <option key={i} value={data.name}></option>
                                                                })}
                                                            </datalist>
                                                        </div>
                                                        <Row className='m-0 p-0'>
                                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                            </Col>
                                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                                <span className='err-msg text-capitalize'>{errors.txt_MapcmpName?.type === 'required' && 'Please Select Company Name from  list'}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Attributes>
                                                    {!addCmpId && (
                                                        <>
                                                            <Row className='m-0 p-0'>
                                                                <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                                                </Col>
                                                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                                                    <Button type="button" className='bttn bttn-transparent'
                                                                        onClick={() => _gotoAddPage()}>
                                                                        <Flex>
                                                                            <Icons color="#ffffff" icon="add" />
                                                                            ADD HERE
                                                                        </Flex>
                                                                    </Button>
                                                                    <Text className='goto_text' center variant='caption'>If, there is no Company</Text>
                                                                </Col>
                                                            </Row>

                                                        </>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='p-0 m-0'>
                                    <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0'>
                                    </Col>
                                    <Col xs='12' md='12' lg='5' xl='5' className='p-0 m-0'>
                                        <Space divheight={20} />
                                        {addCmpId && (
                                            <Text center
                                                variant='caption'>
                                                Company Added Successfully.
                                                <Text variant='caption'>
                                                    Click "Map and send invite code" button to send Invite code to the User
                                                </Text>
                                            </Text>
                                        )}
                                        <Space divheight={20} />
                                    </Col>
                                </Row>
                            </Card>
                            <Actions>
                                <Button type="button" className='bttn bttn-transparent' onClick={() => _goback()}>
                                    <Flex jc='jc-around'>
                                        <Icons icon="arrow_back" />
                                        BACK
                                    </Flex>
                                </Button>
                                <Button className='bttn bttn-transparent' name='btnMapCmptoUser' id='btnMapCmptoUser' type="submit">
                                    <Flex>
                                        <Icons color="#ffffff" icon="check" />
                                        Map and Send Invite Code
                                    </Flex>
                                </Button>
                            </Actions>
                        </Form>
                    </Grid>
                </>
            )}
        </Main>
    )

}

export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.user.selectedUser
})

export default connect(
    mapStateToProps,
    {
        userSetSelectedAction,
        userListGetAction
    }
)(MapCompany)

//   <div>
//   <Text color='tablehead' variant='subtitle'>Company Name</Text>
//   {addCmpId &&(
//       <input value={display.companyName} 
//           name='txt_MapcmpName' id='txt_MapcmpName'
//           list='mapcmpNames'
//           className='basecontrol cutome-selectInputnxt'
//           onChange={(e) => setDisplay({ ...display, companyName: e.target.value })} 
//           ref={register({ required: true,})}>
//       </input>
//   )}
//   {!addCmpId &&(
//       <input value={paramCmpName} 
//           name='txt_MapcmpName' id='txt_MapcmpName'
//           list='mapcmpNames'
//           className='basecontrol cutome-selectInputnxt'
//           onChange={(e) => setParamCmpName(e.target.value)} 
//           ref={register({ required: true,})}>
//       </input>
//   )}
//       <datalist id="mapcmpNames">
//           {display.companiesName.map((data:any, i:number) => {
//           return <option key={i} value={data.name}/>
//           })}
//       </datalist>                                                                 
// </div>
// <Row className='m-0 p-0'>
//   <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
//   </Col>
//   <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
//   <span className='err-msg text-capitalize'>{errors.txt_MapcmpName?.type === 'required' && 'Please Select Company Name from  list'}
//   </span>
//   </Col>
// </Row>