import { ofType } from 'redux-observable'
import {
  MANUFACTURER_LIST_GET,
  MANUFACTURER_CMP_LIST_GET,
  PAYPERVIEW_LIST_GET,
  TRANSACTION_LIST_GET,
  QUOTATION_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  manufacturerListGetFAction,
  manufacturerListGetRAction,
  manufacturerCmpListGetFAction,
  manufacturerCmpListGetRAction,
  payPerViewListGetFAction,
  payPerViewListGetRAction, 
  transactionListGetFAction,
  transactionListGetRAction,
  quotationListGetFAction,
  quotationListGetRAction
} from '../action/manufacturerAction'
import manufacturerService from '../../services/manufacturerService'

export const getManufacturerEpic = (action$ : any) => {
  return action$.pipe(
    ofType(MANUFACTURER_LIST_GET),
    switchMap((action : any) => {
      return manufacturerService.getManufacturer(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in shipTypeEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return manufacturerListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(manufacturerListGetRAction(err)))
      )
    })
  )
}

export const getManufacturerCmpEpic = (action$ : any) => {
  return action$.pipe(
    ofType(MANUFACTURER_CMP_LIST_GET),
    switchMap((action : any) => {
      return manufacturerService.getManufactCompany(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in manugactEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return manufacturerCmpListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(manufacturerCmpListGetRAction(err)))
      )
    })
  )
}

export const getPayPerViewEpics = (action$ : any) => {
  return action$.pipe(
    ofType(PAYPERVIEW_LIST_GET),
    switchMap((action : any) => {
      return manufacturerService.getPayPerViewList(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in manugactEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return payPerViewListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(payPerViewListGetRAction(err)))
      )
    })
  )
}

//Transaction epics
export const getTransactionEpics = (action$ : any) => {
  return action$.pipe(
    ofType(TRANSACTION_LIST_GET),
    switchMap((action : any) => {
      return manufacturerService.getTransactionDetail(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in manugactEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return transactionListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(transactionListGetRAction(err)))
      )
    })
  )
}

//Quotation Epics

export const getQuotationEpics = (action$ : any) => {
  return action$.pipe(
    ofType(QUOTATION_LIST_GET),
    switchMap((action : any) => {
      return manufacturerService.getQuotelist(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in manugactEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return quotationListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(quotationListGetRAction(err)))
      )
    })
  )
}