import { ofType } from 'redux-observable'
import {
  SHIP_MATERIAL_LIST_GET,
} from '../actionType'
import { catchError, switchMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  shipMaterialListGetFAction,
  shipMaterialListGetRAction

} from '../action/materialAction'
import materialService from '../../services/materialService'

export const getShipMaterialEpic = (action$ : any) => {
  return action$.pipe(
    ofType(SHIP_MATERIAL_LIST_GET),
    switchMap((action : any) => {
      return materialService.getShipMaterial(action.payload).pipe(
        map((res :any) => {
          // console.log(res,'response in shipTypeEpic')
          const { content, total_page, total_element } = res.data
          // console.log(content,'content in ship type')
          // console.log(total_page,'total page in ship type')
          // console.log(total_element,'total element in ship type')
          return shipMaterialListGetFAction({ content, total_page, total_element })
        }),
        catchError((err : any) => of(shipMaterialListGetRAction(err)))
      )
    })
  )
}