import React from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';
import './Manufacturer.scss';
import '../../../common/components/pagination/Pagination.scss'
import { Text } from '../../../common/components/text/Text'
import { Space } from '../../../common/components/space/Space';
import { Main } from '../../../common/components/main/Main';
import { Button } from '../../../common/components/button/Button';
import Card from '../../../common/components/card/Card';
import { Table } from '../../../common/components/table/Table';
import Icons from '../../../common/components/icon/Icon';
import { format, parseISO } from 'date-fns'
import { transactionListGetAction, transactionSetSelectedAction } from "../../../store/action/manufacturerAction";
import { connect } from 'react-redux';
import classNames from 'classnames';
import { history } from '../../../store';
import { Flex } from '../../../common/components/flex/Flex';
import { ReactNode } from 'react'
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';

const TransactionDetail = (props: any) => {
  const { transaction, isLoading, totalElement } = props
  const [display, setDisplay] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [pagination, setPagination] = React.useState([{}])
  const [low, setLow] = React.useState(1)
  const [high, setHigh] = React.useState(9)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  const { match } = props
  const { id } = match.params

  // Used to get type and payload, call action and get list.   
  React.useEffect(() => {
    props.transactionListGetAction({ size: 10, page, supplier_id: id })
  }, [page])

  // Check ships(list) length, add checked false in that array.  
  React.useEffect(() => {
    if (transaction && transaction.length > 0) {
      setDisplay(() => {
        return transaction.map((s: any) => ({ ...s }))
      })
    }
    if (transaction && transaction.length === 0) {
      setDisplay([])
    }
  }, [transaction])
  // Get totalPage in props and push into the pagination variable.   
  React.useEffect(() => {
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])


  // Redirect to supporting owner detail page.  
  // const _willViewDetail = (user_view: any) => {
  //   var traverse = id
  //   transactionSetSelectedAction(user_view)
  //   history.push(`/clickedUser/${'click'}/${user_view.id}/${traverse}`)
  // }

  // Prints first page pagination.  
  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  // While click the last page its move to last page.  
  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 9)
  }

  // While click the first button its move to first page.  
  const _goToFirstPage = () => {
    setPage(0)
    setHigh(9)
    setLow(1)
  }

  // Prints last page pagination button.  
  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  // Prints prev button.  
  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  // While click prev button go to the previous page of current page.  
  const _goPrevPage = () => {
    setPage(prev => {
      const newValue = prev - 1
      return newValue
    })
  }

  // Print next button.  
  const nextBtn = () => {
    if (page < totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  // While click next page button go to the next page of the current page.  
  const _goNextPage = () => {
    setPage(prev => {
      const newValue = prev + 1
      return newValue
    })
  }

  // While click one page number its move to that page.  
  const _goToPage = (page: any) => {
    setPage(page)
  }

  // Return the page number
  const ListItem = (page: any) => {
    return page.value + 1
  }

  // Prints the totalpages between prev, next button.  
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} active={p === page} key={i}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })

  return (
    <Main>
      <Text variant="title" color='black'> Transaction History </Text>
      <Space divheight={15} />
      <Card>
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>} {/* Check the list is loading, isloading is true its shows loading text.  */}
        {/* {console.log(transaction,'transaction')} */}
        {!isLoading && display.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No Transaction Detail For this Supporting Owner</Text>
            <Space divheight={20} />
          </>
        )} {/* If isloading is false and also the list array length is 0 its shows no discount fount text.   */}
        {!isLoading && display.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>Amount</th>
                  <th>payment Date</th>
                  <th>Reference Number</th>
                </tr>
              </thead>
              <tbody>
                {display.map((data: any, i: any) => (
                  <tr key={i}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='Amount'>
                      {data.amounts === null ? (
                        <Text variant='caption' color='tabledata'>-</Text>
                      ) : (
                        <>
                          {!String(data.amounts).includes('.') && (
                            <Text className='txt-table-date' color='tabledata'>
                              <NumberFormat value={String(data.amounts)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />.00
                            </Text>
                          )}
                          {String(data.amounts).includes('.') && (
                            <Text className='txt-table-date' color='tabledata'>
                              <NumberFormat value={data.amounts} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                            </Text>
                          )
                          }
                        </>
                      )}
                    </td>
                    <td data-label='Payment Date'>
                      {data.paymentdate === null || '' ? (
                        <Text variant='caption' color='tabledata'>-</Text>
                      ) : (
                        <Text variant='caption' color='tabledata'>
                          {format(parseISO(data.paymentdate), 'dd/MM/yyyy hh:mm')}
                        </Text>
                      )}
                    </td>
                    <td data-label='Reference Number'>
                      {data.reference_number === null || '' ? (
                        <Text variant='caption' color='tabledata'>null</Text>
                      ) : (
                        <Text variant='caption' color='tabledata'>
                          {data.reference_number}
                        </Text>
                      )}
                    </td>
                    {/* <td>
                      <TableActions>
                        <Button className='link_button' onClick={() => _willViewDetail(data)}>
                          <Icons color="#52575a" icon="visibility" />
                        </Button>
                      </TableActions>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text> {/* Display the total elements.  */}
                {display.length === 1 && (
                  <>
                    <b>
                      {display.length}
                      {/* {((page)*10)+1} - {(display.length+(page)*10)} */}
                    </b> of {totalElement} Transaction
                  </>
                )}
                {display.length > 1 && (
                  <>
                    <b>
                      {display.length}
                      {/* {((page)*10)+1} - {(display.length+(page)*10)} */}
                    </b> of {totalElement} Transactions
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()} {/* First page button.   */}
                {prevBtn()} {/* Previous button.   */}
                {PageNumber} {/* Totalpages.   */}
                {nextBtn()} {/* Next button.   */}
                {lastPage()} {/* Last page button.   */}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      <Row className='m-0 p-0'>
        <Col xs='12' md='12' lg='12' xl='12' className='m-0 p-0'>
          <Actions>
            <Link to={'/manufacturer/list'}>
              <Button type="button" variant='transparent'>
                <Flex jc='jc-around'>
                  <Icons icon="arrow_back" />
                  BACK
                </Flex>
              </Button>
            </Link>
          </Actions>
        </Col>
      </Row>
    </Main>
  )
}
export interface TablefooterProps {
  children?: React.ReactNode
}

// Table footer style.  
const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

// Table body style.  
const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

export interface ActionProps {
  children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
  const action = classNames('Action-container');
  return (
    <Flex jc='jc-flexend' className={action}>
      {props.children}
    </Flex>
  )
}

// Declare the variable.  
const mapStateToProps = (state: any) => ({
  transaction: state.manufacturer.transaction,
  isLoading: state.manufacturer.isLoading,
  error: state.manufacturer.error,
  totalElement: state.manufacturer.totalElement,
  totalPage: state.manufacturer.totalPage
})

// Connect to the action and get the list, totalpage and total elements.  
export default connect(
  mapStateToProps,
  {
    transactionListGetAction,
    transactionSetSelectedAction,
  }

)(TransactionDetail)