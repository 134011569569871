import React, { Component } from 'react';
import XLSX from 'xlsx';
import { Button } from '../../../common/components/button/Button';
import { Col, Form, Row } from 'react-bootstrap'
import { Text } from '../../../common/components/text/Text'
import { Flex } from '../../../common/components/flex/Flex';
import Icons from '../../../common/components/icon/Icon';
import { Space } from '../../../common/components/space/Space';
import datalasticShipCmpService from '../../../services/datalasticShipCmpService'
import { make_cols } from '../pagecontainer/MakeColumns';
import { history } from '../../../store'
import swal from 'sweetalert';
import downloadicon from '../../../assets/images/XL.png';
import { Attributes } from '../../../common/components/attribute/Attribute';
import { Table } from '../../../common/components/table/Table'
import '../datalasticShipCompanies/datalastic.scss'
import Loader from 'react-loader-spinner'
import Modal from '../../../common/components/modal/Modal';
import { ModalBody } from '../../login/pagecontainer/ForgotPassWord';
import { Main } from '../../../common/components/main/Main';
import { Grid } from '../../../common/components/grid/Grid';
import Card from '../../../common/components/card/Card';

class DatalasticExcel extends Component<any, any> {

    constructor(props: any) {
        super(props);
        const { onClose } = props

        this.state = {
            file: {},
            data: [],
            cols: [],
            shows: false,
            files: false,
            number_of_dockType: 0,
            number_of_port: 0,
            number_of_city: 0,
            name: [],
            loading: false,
            actualFile: {}
        }
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    handleChange(e: any) {
        const files = e.target.files;
        console.log(e.target.files[0]);
        // console.log(e.target.shee);
        this.setState({ actualFile: e.target.files[0] })
        if (files && files[0]) this.setState({ file: files[0], files: true });
    };

    handleShow(dock: any, res: any) {

    }

    // const _willViewDetail = (dock: any, pgnum: number) => {
    //     dockSetSelectedAction(dock)
    //     history.push(`/docks/${dock.id}/${pgnum}`)
    // }

    handleFile() {
        if (this.state.files) {
            /* Boilerplate to set up FileReader */
            const reader = new FileReader();
            this.setState({ shows: false })
            const rABS = !!reader.readAsBinaryString;

            reader.onload = (e: any) => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws);
                const data1 = XLSX.utils.sheet_to_json(ws, { header: 1 });
                this.setState({ loading: true })
                console.error(this.state.loading + "-this set before true")
                const sentdata = {
                    file: this.state.actualFile,
                    checked: false,
                }
                const data2 = ['ShortName',	'LongName','CompanyType', 'CountryCode', 'CompanyIMO', 'Website', 'CompanyStatus', 'Email',	'Phone', 'LinkedIn', 'ParentCompanyIMO',
                	'ParentCompanyName', 'DateModified', 'Address',	'city',	'province',	'country']
                 console.log(data);
                 console.log(data1[0])
                 console.log(data2)
                 console.log(data.length, 'length')
                if ((Array.isArray(data1[0]) && Array.isArray(data2) && data1[0].length === data2.length && data1[0].every((val, index) => val === data2[index])) === false) {
                    this.setState({ loading: false })
                    swal("OOPS", String("Header Name Or The Column Count Of The Imported Excel Is Mismatched With The Template"), "error")
                    // window.location.reload();
                } else {
                    if (data.length <= 689900) {
                        datalasticShipCmpService.addshipCmpExcel(data, false).subscribe({
                            next: (res) => {
                                if (res.success === false) {
                                    swal("OOPS", String(res.msg), "error")
                                } else {
                                    if (res.msg === 'validation success') {
                                        this.setState({
                                            loading: false,
                                            shows: true,
                                            data: res.data
                                        })
                                        console.error(this.state.loading + "-this set before false")

                                        if (res.data[0].new_updated_docks === 0) {
                                            //swal("Great", "No New Records To Import Data", "success")
                                        } else {
                                            //swal("Great", "DryDock Imported Successfully", "success")
                                        }
                                    } else {
                                        console.log(res.data, 'print')
                                        this.setState({
                                            loading: false,
                                            shows: true,
                                            data: res.data
                                            // name:[]
                                        })
                                        console.error(this.state.loading + "-this set before false")
                                    }
                                }
                            },
                            error: err => {
                                this.setState({ loading: false })
                                swal("OOPS", String(err), "error")
                                console.error(err)
                            }
                        })
                    } else {
                        swal("OOPS", "Total Records must be less than 200 ", "error")
                        history.push('/import/shipCompanies')
                    }
                    /* Update state */
                    this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
                    });
                }
            };
            if (rABS) {
                reader.readAsBinaryString(this.state.file);
            } else {
                reader.readAsArrayBuffer(this.state.file);
            };
        } else {
            swal("Warning", "Please Select File", "warning")
        }
    }

    reset() {
        window.location.reload();
    }

    uploadFile() {
        console.log(true)
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        //this.setState({ shows: false })
        this.setState({ loading: true })
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e: any) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            this.setState({ loading: true })

            const sentdata = {
                input: this.state.data,
                checked: true,
            }
            console.log(this.state.data.length, 'length')
            datalasticShipCmpService.addshipCmpExcel(data, true).subscribe({
                next: (res) => {
                    // console.log(pages,'pages')
                    if (res.data[0].valid_count > 0) {
                        this.setState({ loading: false })
                        swal("Great", "Ship Companies Imported Successfully", "success")
                        history.push('/datalastic/shipCmp')
                        this.props.onClose('success')
                    } else {
                        this.setState({ loading: false })
                        swal("OOPS", String("No Valid Record To Import"), "error")
                        history.push('/datalastic/shipCmp')
                        this.props.onClose()
                    }
                },
                error: err => {
                    this.setState({ loading: false })
                    swal("OOPS", String(err), "error")
                    console.error(err)
                    history.push('/datalastic/shipCmp')
                    this.props.onClose()

                }
            })
            /* Update state */
            this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
            });

        };
        if (rABS) {
            reader.readAsBinaryString(this.state.file);
        } else {
            reader.readAsArrayBuffer(this.state.file);
        };

    }
    _willDelete = () => {

    }

    render() {

        if (this.state.loading) {
            console.error(this.state.loading + "-loading started")
            console.log(true, '1')
            return <Text center className="loader">
                <Loader
                    type="Rings"
                    color="#233269"
                    height={100}
                    width={100}
                    timeout={30000}
                />
            </Text>
        }
        return (
            <div>
                <Main>
                    <Text className='import-cls' variant="title" color='black'>Import Ship Companies</Text>
                    {this.state.shows === true ? (
                        <div className="back_button_parent">
                            <Flex jc='jc-end' className='p-1 d-inline back_button'>
                                <Button
                                    block
                                    type="button"
                                    onClick={this.reset}
                                    variant='transparent'>
                                    <Flex>
                                        <Icons icon="arrow_back" />
                                        Back
                                    </Flex>
                                </Button>
                            </Flex>
                        </div>
                    ) : (
                        <>
                        </>
                    )}

                    <Space divheight={25} />
                    <Card>
                        {this.state.shows === false ? (
                            <Grid templateCols='100% auto'>
                                <div className='import-section'>
                                    <Row className='m-0 p-0 upload-btn-container'>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <Attributes className='singleFrame'>
                                                <a href="https://asset-p2.navidok.com/v2/files/datalystics/Maritime_company_template.xlsx"><img src={downloadicon}></img></a>
                                                <Text color='tablehead import-txt' variant='subtitle' bold>Ship Company Template as .xls</Text>
                                                <div className="upload-btn-wrapper">
                                                    <Button variant='transparent' block className='fileUpload'>
                                                        <Flex>
                                                            <input type="file" id="file" className="new_input" accept={SheetJSFT} onChange={this.handleChange}
                                                            />
                                                            <Icons color="#ffffff" icon="publish" />
                                                            Choose File
                                                        </Flex>
                                                    </Button>
                                                </div>
                                                {this.state.actualFile != null ? (
                                                    <Text>{this.state.actualFile.name}</Text>
                                                ) : (<Text></Text>)}
                                            </Attributes>
                                        </Col>
                                    </Row>
                                    <Row className='m-0 p-0 upload-btn-container'>
                                        <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                            <Attributes className='singleFrame'>
                                                <Flex jc='jc-center'>
                                                    {/* <Button
                                                    block
                                                    type="button"
                                                    onClick={() => this.props.onClose()}
                                                    variant='transparent'>
                                                    <Flex>
                                                        <Icons icon="clear" />
                                                        Cancel
                                                    </Flex>
                                                </Button> */}
                                                    <Button variant='transparent' block type="submit" onClick={this.handleFile}>
                                                        <Flex>
                                                            <Icons color="#ffffff" icon="publish" />
                                                            Upload File
                                                        </Flex>
                                                    </Button>
                                                </Flex>
                                            </Attributes>
                                        </Col>
                                    </Row>
                                </div>
                            </Grid>
                        ) : (
                            <>
                            </>
                        )}
                    </Card>
                    <Card className='DataShowingContainer'>

                        {this.state.shows === true ? (
                            <>
                                <Text className='DatalysticText'> {/* Display the total elements.   */}
                                    {this.state.data.length === 1 && (
                                        <>
                                            <b>
                                                Total records in the file {this.state.data.length}. Valid records are {this.state.data[0].valid_count}
                                                {/* {((page) * 10) + 1} - {(displayCompanies.length + (page) * 10)} */}
                                            </b>{/* of {totalElement} Demolition */}
                                        </>
                                    )}
                                    {this.state.data.length > 1 && (
                                        <>
                                            <b>
                                                Total records in the file {this.state.data.length}. Valid records are {this.state.data[0].valid_count}
                                                {/* {((page) * 10) + 1} - {(displayCompanies.length + (page) * 10)} */}
                                            </b> {/* of {totalElement} Demolition */}
                                        </>
                                    )}
                                </Text>
                                <Table >
                                    <thead>
                                        <tr key={0}>
                                            {/* <th>Short Name</th> */}
                                            <th>
                                                Long Name &nbsp;
                                            </th>
                                            <th>
                                                IMO &nbsp;
                                            </th>
                                            <th>
                                                Type
                                            </th>
                                            {/* <th>Address &nbsp;</th> */}
                                            <th>
                                                Country Code &nbsp;
                                            </th>
                                            {/*<th>
                                                Email 
                                            </th>
                                            <th>
                                                Phone &nbsp;
                                            </th>
                                             <th>Website</th> 
                                             <th>Linkedin </th> 
                                            <th>Parent IMO &nbsp;</th>
                                            <th>Parent Name </th> */}
                                            <th>City &nbsp;</th>
                                            <th>Province &nbsp;</th>
                                            <th>
                                                Message
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.map((dock: any, i: any) => [

                                            <tr key={dock.sno}>
                                                {/* <td data-label='Short Name'>
                                                    {dock.ShortName === null ? (
                                                        <Text className='txt-table-date' color='tabledata'>-</Text>
                                                    ) : (
                                                        <Text className='txt-table-date' color='tabledata'>{dock.ShortName}</Text>
                                                    )}
                                                </td> */}
                                                <td data-label='Long Name'>
                                                    {dock.LongName === null ? (
                                                        <Text className='txt-table-date  txt-center' color='tabledata'>-</Text>
                                                    ) : (
                                                        <Text className='txt-table-date' color='tabledata'>{dock.LongName}</Text>
                                                    )}</td>
                                                <td data-label='Company IMO'>
                                                    {dock.CompanyIMO === null ? (
                                                        <Text className='txt-table-date  txt-center' color='tabledata'>-</Text>
                                                    ) : (
                                                        <Text className='txt-table-date' color='tabledata'>{dock.CompanyIMO}</Text>
                                                    )}
                                                </td>
                                                <td data-label='Company Type'>{dock.CompanyType}</td>
                                                {/* <td data-label='Address'>{dock.Address}</td> */}
                                                <td data-label='Country Code'>{dock.CountryCode}</td>
                                                {/* <td data-label='Email'>{dock.Email}</td>
                                                <td data-label='Phone'>{dock.Phone}</td> 
                                                <td data-label='Website'>{dock.Website}</td> 
                                                <td data-label='Linkedin'>{dock.LinkedIn}</td> *
                                                <td data-label='ParentCompanyIMO'>{dock.ParentCompanyIMO}</td>
                                                <td data-label='ParentCompanyName'>{dock.ParentCompanyName}</td> */}
                                                <td data-label='city'>{dock.city}</td>
                                                <td data-label='province'>
                                                    {dock.province === null ? (
                                                        <Text className='txt-table-date txt-center' color='tabledata'>-</Text>
                                                    ) : (
                                                        <Text className='txt-table-date' color='tabledata'> {dock.province}</Text>
                                                    )}
                                                </td>
                                                <td data-label='Message'>{dock.Message === 'Valid' ? (
                                                    <Text color='success' variant='subtitle'>{dock.Message}</Text>
                                                ) : (
                                                    <Text color='danger' variant='subtitle'>{dock.Message}</Text>
                                                )}
                                                </td>
                                            </tr>
                                        ])}

                                    </tbody>

                                </Table>

                                <Flex jc='jc-center' className='p-5 m-0'>
                                    <Button
                                        block
                                        type="button"
                                        onClick={this.reset}
                                        variant='transparent'>
                                        <Flex>
                                            <Icons icon="clear" />
                                            Cancel
                                        </Flex>
                                    </Button>
                                    <Button variant='transparent' block type="submit"
                                        onClick={this.uploadFile}>
                                        <Flex>
                                            <Icons color="#ffffff" icon="publish" />
                                            Submit
                                        </Flex>
                                    </Button>
                                </Flex>
                            </>
                        ) : (
                            <>
                                <div className='nodata-txt-container'>
                                    <Text color='tablehead' variant='subtitle' bold>Kindly Import Ship companies Data</Text>
                                </div>
                            </>
                        )
                        }
                    </Card>
                </Main>
                {/* <div>
                    <Modal
                        onClose={() => this.props.onClose()}
                        title={'Import Dock Details As Excel'}
                        className='import-mode'>
                        <ModalBody>
                            <Attributes className='singleFrame'>
                                <a href="https://navidok-staging-asset.s3.ap-southeast-1.amazonaws.com/v1/quotations/15_12_2021/dock_template.xlsx"><img src={downloadicon}></img></a>
                                <input type="file" id="file" className="new_input" accept={SheetJSFT}
                                    onChange={this.handleChange} />
                            </Attributes>
                            <Text color='danger' variant='subtitle' bold>Note: Navidok will not import repeated and null records.</Text>
                            <Space divheight={20} />
                            <Flex jc='jc-center'>
                                <Button
                                    block
                                    type="button"
                                    onClick={() => this.props.onClose()}
                                    variant='transparent'>
                                    <Flex>
                                        <Icons icon="clear" />
                                        Cancel
                                    </Flex>
                                </Button>
                                <Button variant='transparent' block type="submit" onClick={this.handleFile}>
                                    <Flex>
                                        <Icons color="#ffffff" icon="publish" />
                                        Upload File
                                    </Flex>
                                </Button>
                            </Flex>
                        </ModalBody>
                    </Modal>
                </div>
                {console.log(this.state.shows, 'shows value')}
                {this.state.shows === true &&
                    <div>
                        <Modal onClose={() => this.props.onClose()} title={'Import Confirmation'} className='import-new-mode'>
                            <ModalBody>
                                <Text bold variant='confirmation' color='danger' values='Want to import the dock details ' />
                                {this.state.data[0].new_dock_type_count !== 0 &&
                                    <>
                                        <p>Total new dock Type - {this.state.data[0].new_dock_type_count}</p>
                                    </>
                                }
                                {this.state.data[0].new_port_count !== 0 &&
                                    <>
                                        <p>Total new Ports - {this.state.data[0].new_port_count}</p>
                                    </>
                                }
                                {this.state.data[0].new_city_count !== 0 &&
                                    <>
                                        <p>Total new City - {this.state.data[0].new_city_count}</p>
                                    </>
                                }
                                {this.state.data[0].new_company_count !== 0 &&
                                    <>
                                        <p>Total new Company - {this.state.data[0].new_company_count}</p>
                                    </>
                                }
                                {this.state.data[0].new_country_count !== 0 &&
                                    <>
                                        <p>Total new Country - {this.state.data[0].new_country_count}</p>
                                    </>
                                }
                                <Table>
                                    <thead>
                                        <tr key={0}>
                                            <th>Dock Name</th>
                                            <th>
                                                Company Name &nbsp;
                                            </th>
                                            <th>
                                                Port &nbsp;
                                            </th>
                                            <th>
                                                Country
                                            </th>
                                            <th>City &nbsp; </th>
                                            <th>
                                                Dock Type &nbsp;
                                            </th>
                                            <th>
                                                Latitude
                                            </th>
                                            <th>
                                                Longitude
                                            </th>
                                            <th>Draft </th>
                                            <th>
                                                Length
                                            </th>
                                            <th>
                                                Width
                                            </th>
                                            <th>
                                                Unit Name
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.map((dock: any, i: any) => [
                                            <tr key={dock.sno}>
                                                <td data-label='Dock Name'>{dock.dock_name}
                                                </td>
                                                <td data-label='Company Name'>{dock.company_name}</td>
                                                <td data-label='Port'>{dock.port_name}</td>
                                                <td data-label='Country'>{dock.country_name}</td>
                                                <td data-label='City'>{dock.city_name}</td>
                                                <td data-label='Dock Type'>{dock.dock_type}</td>
                                                <td data-label='Latitude'>{dock.lat}</td>
                                                <td data-label='Longitude'>{dock.lon}</td>
                                                <td data-label='Draft'>{dock.draft}</td>
                                                <td data-label='Length'>{dock.length}</td>
                                                <td data-label='Width'>{dock.width}</td>
                                                <td data-label='Unit Name'>{
                                                    dock.unit_name === null ? (
                                                        <>
                                                            -
                                                        </>
                                                    ) : (
                                                        <>
                                                            {dock.unit_name}
                                                        </>

                                                    )
                                                }</td>
                                            </tr>
                                        ])}
                                    </tbody>
                                </Table>

                                <Flex jc='jc-center'>
                                    <Button
                                        block
                                        type="button"
                                        onClick={() => this.props.onClose()}
                                        variant='transparent'>
                                        <Flex>
                                            <Icons icon="clear" />
                                            Cancel
                                        </Flex>
                                    </Button>
                                    <Button variant='transparent' block type="submit"
                                        onClick={this.uploadFile}>
                                        <Flex>
                                            <Icons color="#ffffff" icon="publish" />
                                            Upload File
                                        </Flex>
                                    </Button>
                                </Flex>
                            </ModalBody>
                        </Modal>
                    </div>
                } */}
            </div>
        )
    }
}


export default DatalasticExcel;

const SheetJSFT = [
    "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function (x) { return "." + x; }).join(",");
