import React, { useRef, useState } from 'react'
import Modal from '../../../../common/components/modal/Modal'
import { Text } from '../../../../common/components/text/Text'
import { Input, Radio, Select } from '../../../../common/components/control/Control'
import { Button } from '../../../../common/components/button/Button'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import swal from 'sweetalert'
import { connect } from 'react-redux';
import { shipListGetAction } from '../../../../store/action/shipActions';
import codeService from '../../../../services/codeService';
import {ShipFilterState} from '../../filter/ShipFilterState'


interface filterProps {
    status: string;
    shipName: string;
    country: string;
    countries: any[];
    city: string;
    cities: any[];
    cmp: string;
}
const FilterModal = (props: any) => {
    // const {register} = useForm();
    // const {errors} = useForm();
    const { handleSubmit } = useForm();
    const { view } = props
    const { onClose } = props
    const { ships, isLoading, totalElement, totalPage } = props
    const [display, setDocks] = React.useState<any>([])
    
    const [editForm, setEditForm] = useState({
        docks: '',
        status: ShipFilterState.shipFilter.shipstatus,
        name: ShipFilterState.shipFilter.shipName,
        country: '',
        countries:[''],
        cities:[''],
        countryId:'',
        city: ShipFilterState.shipFilter.shipcityName,
        cmpName: ShipFilterState.shipFilter.companyName,
        cityName:'',
        countryname:ShipFilterState.shipFilter.shipcountryNames,
    })
    

    const _getCountryFlag = (() => {
        return codeService.getCountries().subscribe({
            next: r => {
                setEditForm({ ...editForm, countries: r.data })
                r.data.map((data: any, i: any) => {
                    return editForm.countries.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getCities = ((data:any) => {
        var id = data.id;
        var countryName = data.name;
        // console.log(data,'data in countries')
        // console.log(data.id,'city id')
        // console.log(data.name,'country Name')
        return codeService.getCountryCities({id:id}).subscribe({
            next: r => {

                setEditForm({ ...editForm, cities: r.data, country:countryName, countryname:JSON.stringify(data)})
                r.data.map((data: any, i: any) => {
                    return editForm.cities.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    
    React.useEffect(() => {
        _getCountryFlag()
        if(ShipFilterState.shipFilter.shipcountryNames !== ''){
            _getCities(JSON.parse(ShipFilterState.shipFilter.shipcountryNames))
        }
    }, [])

    onsubmit = (e) => {
        // console.log(editForm)
        e.preventDefault()
        const { onClose } = props
        // React.useEffect(() => {
        //     if (docks && docks.length > 0) {
        //       setDocks(() => {
        //         return docks.map((s: any) => ({ ...s, checked: false }))
        //       })
        //     }
        //     if (docks && docks.length === 0) {
        //       setDocks([])
        //     }
        //   }, [docks])
        props.shipListGetAction({
            size: 10, page: 0, shipName: editForm.name,
            companyName: editForm.cmpName,
            countryName: String(editForm.country),
            status: editForm.status
        })
        // localStorage.setItem("searchInput",editForm.cmpName)
        ShipFilterState.shipFilter.companyName=editForm.cmpName
        ShipFilterState.shipFilter.shipName=editForm.name
        ShipFilterState.shipFilter.shipcountryName=String(editForm.country)
        ShipFilterState.shipFilter.shipstatus=editForm.status
        ShipFilterState.shipFilter.shipcountryNames=editForm.countryname
        ShipFilterState.shipFilter.page=0
        ShipFilterState.shipFilter.size=10
        onClose()
    }

    return (
        <Modal
            onClose={() => onClose()}
            title={'Filter'}>
            <ModalBody>
                <form {...handleSubmit(onsubmit)} autoComplete='off'>
                    <Attributes>
                        <div>
                            <Text color='black' variant='subtitle'>Ship Name</Text>
                            <Input
                                type="text"
                                name="ship_name"
                                value={editForm.name}
                                onChange={(e) => setEditForm({ ...editForm, name: e.target.value })}
                                placeholder='Ship Name'
                            />
                        </div>
                        <div>
                            <Text color='black' variant='subtitle'>Company Name</Text>
                            <Input
                                type="text"
                                name="cmp_name"
                                value={editForm.cmpName}
                                onChange={(e) => setEditForm({ ...editForm, cmpName: e.target.value })}
                                placeholder='Company Name'
                            />
                        </div>
                        <div>
                            <Text color='black' variant='subtitle'>Country</Text>
                            <Select values={editForm.countryname} name='country' 
                                onChange={(e) => _getCities(JSON.parse(e.target.value))}>
                                <option value="">-- Select --</option>
                                {editForm.countries.map((data: any, i: any) => {
                                    return <option key={i} value={JSON.stringify(data)}>{data.name}</option>
                                })}
                            </Select>
                        </div>
                        {/* <div>
                            <Text color='black' variant='subtitle'>City</Text>
                            <Select values={editForm.city} name='city' onChange={(e) => setEditForm({ ...editForm, city: e.target.value })}>
                                <option value="">-- Select Country First --</option>
                                {editForm.cities.length === 0 &&(
                                    <option value="">-- Ther is no cities --</option>
                                )}
                                {editForm.cities.map((data: any, i: any) => {
                                    
                                    return <option key={i} value={data.name}>{data.name}</option>
                                })}
                            </Select>
                        </div> */}
                        <div>
                            <Text color='black' variant='subtitle'>Status</Text>
                            <Select values={editForm.status} name='city' onChange={(e) => setEditForm({ ...editForm, status: e.target.value })}>
                                <option value="">-- Select --</option>
                                <option key={1} value='VERIFIED'>Verified</option>
                                <option key={2} value='UNVERIFIED'>Not Verified</option>
                                <option key={3} value=''>All</option>
                            </Select>
                        </div>
                    </Attributes>
                    <Space divheight={20} />
                    <Flex jc='jc-center'>
                        <Button
                            block
                            type="button"
                            onClick={() => onClose()}
                            variant='transparent'>
                            <Flex>
                                <Icons icon="arrow_back" />
                                BACK
                            </Flex>
                        </Button>
                        {!view && (
                            <Button variant='transparent' block type="submit">
                                <Flex>
                                    <Icons color="#ffffff" icon="check" />
                                    Filter Data
                                </Flex>
                            </Button>
                        )}
                    </Flex>
                </form>
            </ModalBody>
        </Modal>
    )
}

export interface ModalbodyProps {
    children?: React.ReactNode
}

const ModalBody = ({ children, ...props }: ModalbodyProps) => {

    const modal = classNames('modalbody-container')

    return (
        <div className={modal}>
            {children}
        </div>
    )

}
interface AttributeProps {
    children?: React.ReactNode
}
export const Attributes = ({ children, ...props }: AttributeProps) => {
    const attribute = classNames('attribute-container');

    return (
        <div {...props} className={attribute} >
            {children}
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    ships: state.dock.docks,
    isLoading: state.dock.isLoading,
    error: state.dock.error,
    totalElement: state.dock.totalElement,
    totalPage: state.dock.totalPage
})
export default connect(
    mapStateToProps,
    {
        shipListGetAction,
    }
)(FilterModal)
