import React from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Button } from '../../../../common/components/button/Button'
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Grid } from '../../../../common/components/grid/Grid'
import { Flex } from '../../../../common/components/flex/Flex'
import noimg from '../../../../assets/images/NoImg.jpg';
import { Space } from '../../../../common/components/space/Space'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import { dockService } from '../../../../services/dockService'
import classNames from 'classnames'
import removeicon from '../../../../assets/images/Remove.png'
import { history } from '../../../../store'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { Label } from "../../../../common/components/label/Label";
import { dockOwnerSetSelectedAction } from '../../../../store/action/dockActions';
// import { history } from '../../../store';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import editicon from '../../../../assets/images/editone.png';
import { Col, Row, Image } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { useRef } from 'react';
import userService from '../../../../services/userService';

const DockOwnerDetail = (props: any) => {
    const { match } = props
    const [isLoading, setLoading] = React.useState(true)
    
    const [dock, setDock] = React.useState<any>([{
        address: "",
        amount: '',
        city_name: '',
        company_id: '',
        company_name: '',
        created_at: '',
        dob: '',
        dock_id: '',
        dock_name: '',
        durationinmonth: "",
        email: "",
        end_date: '',
        is_active: true,
        phone: "",
        phone_code: "",
        plan_name: "",
        profile_img: '',
        start_date: '',
        status: '',
        username: ''
    }])
    const role = localStorage.getItem("role")

    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<any>({
        string_image: ''
    })
    const _removeUserImg = (id: any) => {
        return userService.removeUserImg(id).subscribe({
            next: (r) => {
                // console.log(r, 'response')
                // swal("Great", " Image Removed Successfully", "success")
                window.location.reload();
                // history.push(`/ships/${id}/`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
            }
        })
    }
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageUserAPI(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const ImageUserAPI = (imageurl: any) => {
        const { id } = match.params
        const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        userService.UpdateUserImage(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                //               history.push(`/dock-owners/list`)
                history.push(`/dock-owners/list`)
                //                history.push(`/docks-owner/${id}`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }
    //const [isDeleting, setDelete] = React.useState(false)

    React.useEffect(() => {
        const { id } = match.params
        _getDockDetail(id)
    }, [])

    const _getDockDetail = (id: any) => {
        setLoading(true)

        return dockService.getDockOwnerDetail(id).subscribe({
            next: r => {
                // console.log(r, 'r')
                // const { data } = r.data
                // console.log(r.data, 'detail view data')
                props.dockOwnerSetSelectedAction(r.data)
                setDock({
                    id:r.data.userid,
                    address: r.data.address,
                    amount: r.data.amount,
                    city_name: r.data.city_name,
                    company_id: r.data.company_id,
                    company_name: r.data.company_name,
                    created_at: r.data.created_at,
                    dob: r.data.dob,
                    dock_id: r.data.dock_id,
                    dock_name: r.data.dock_name,
                    durationinmonth: r.data.durationinmonth,
                    email: r.data.email,
                    end_date: r.data.end_date,
                    is_active: r.data.is_active,
                    phone: r.data.phone,
                    phone_code: r.data.phone_code,
                    plan_name: r.data.plan_name,
                    profile_img: r.data.profile_img,
                    start_date: r.data.start_date,
                    status: r.data.status,
                    username: r.data.username
                })
                setLoading(false)
            },
            error: err => {
                // console.error(err)
                setLoading(false)
            }
        })
    }

    // const _willDelete = (id:any) => {
    //     setDelete(true)
    //     return dockService.deleteDock().subscribe({
    //         next: () => {
    //         setDelete(false)
    //         history.goBack()
    //     },
    //     error: err => {
    //         console.error(err)
    //         setDelete(false)
    //         }
    //     })
    // }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }

    return (
        <Main>
            {/* {console.log(dock, 'dock detail')}
            {console.log(dock.username, 'User name')} */}
            <div className='title-name-block'>
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                    {dock.profile_img === null || '' && (
                        <Image src={noimg} alt='logo' roundedCircle />
                    )}
                    {dock.profile_img === '' && (
                        <Image src={noimg} alt='logo' roundedCircle />
                    )}
                    {dock.profile_img !== null && dock.profile_img !== '' && (
                        <Image src={dock.profile_img} alt='logo' roundedCircle />
                    )}
                </div>
                {role === 'VISITOR' && (
                    <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                        <label className="custom-file-upload">
                            <Image src={editicon} alt='logo' />
                            <input
                                ref={upload}
                                type="file"
                                onChange={getBase64}
                            />
                        </label>
                    </div>
                )}
                <Text variant='title' color='main' className='txt-capitalize'>
                    {String(dock.email).substring(0, String(dock.email).indexOf('@'))}
                    {/* {dock.username} */}
                </Text>
                <Label className={dock.status === false ? 'danger' : 'warning'} >
                    {dock.status === false ? 'In Active' : 'Active'}
                </Label>
            </div>
            {/* <Text variant="title" >{dock.username}</Text> */}
            <Space divheight={15} />
            <Row className='m-0 p-0'>
                <Col sm='12' md='12' lg='8' xl='8' className='m-0 p-0 align-top'>
                    <Card title="DryDock Owner Detail">
                        <Row className='m-0 p-0'>
                            <Col xs='12' sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    <div>
                                        <Text color='main' variant='subtitle'>Name</Text>
                                        <Text variant='subtitle' color="main" bold>{dock.username}</Text>
                                    </div>
                                    {dock.dob === null && (
                                        <div>
                                            <Text color='main' variant='subtitle'>D.O.B</Text>
                                            <Text color="main" variant='subtitle' bold> Nill</Text>
                                        </div>
                                    )}
                                    {dock.dob !== null && (
                                        <div>
                                            <Text color='main' variant='subtitle'> D.O.B</Text>
                                            <Text color="main" variant='subtitle' bold>
                                                {format(parseISO(dock.dob), 'dd/MM/yyyy')}
                                            </Text>
                                        </div>
                                    )}
                                    <div>
                                        <Text color='main' variant='subtitle'>Mobile</Text>
                                        {dock.phone === '' || null ? (
                                            <Text color='main' bold variant='subtitle'> - </Text>
                                        ) : (
                                            <>
                                                {dock.phone_code === '' ? (
                                                    <Text color='main' bold variant='subtitle'>{dock.phone_code}
                                                        <span>
                                                            <Text color='main' bold variant='subtitle'>{dock.phone}</Text>
                                                        </span>
                                                    </Text>
                                                ) : (
                                                    <>
                                                        <Text color='main' bold variant='subtitle'>{dock.phone_code} &nbsp;
                                                            <span>
                                                                <Text color='main' bold variant='subtitle'>{dock.phone}</Text>
                                                            </span>
                                                        </Text>
                                                    </>
                                                )
                                                }
                                            </>
                                        )}
                                    </div>

                                    <div>
                                        <Text color='main' variant='subtitle'> Email</Text>
                                        <Text color="main" variant='subtitle' bold> {dock.email}</Text>
                                    </div>

                                </Attributes>
                            </Col>
                            <Col xs='12' sm='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                <Attributes className='view_Page'>
                                    {dock.address === null && (
                                        <div>
                                            <Text color='main' variant='subtitle'> Address</Text>
                                            <Text color="main" variant='subtitle' bold>  Nill</Text>
                                        </div>
                                    )}
                                    {dock.address !== null && (
                                        <div>
                                            <Text color='main' variant='subtitle'> Address</Text>
                                            <Text color="main" variant='subtitle' bold> {dock.address}</Text>
                                        </div>
                                    )}
                                    <div>
                                        <Text color='main' variant='subtitle'>Status</Text>
                                        {dock.status === 'VERIFIED' ? (
                                            <Text color='warning' variant='subtitle' bold>Verified</Text>
                                        ) : (
                                            <Text color='danger' variant='subtitle' bold>Not Verified</Text>
                                        )}
                                    </div>
                                    <div>
                                        <Text color='main' variant='subtitle'> Created Date</Text>
                                        {dock.created_at === null && (
                                            <Text color="main" variant='subtitle' bold>
                                                Nill
                                            </Text>
                                        )}
                                        {dock.created_at !== null && (
                                            <Text color="main" variant='subtitle' bold>
                                                {format(parseISO(dock.created_at), 'dd/MM/yyyy')}
                                            </Text>
                                        )}
                                    </div>

                                </Attributes>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='12'>
                                <Card title="Subscription Detail">
                                    <Row className='p-o m-0'>
                                        <Col sm='12' className='p-0 m-0'>
                                            {dock.plan_name === null && (
                                                <>
                                                    <Space divheight={20} />
                                                    <Text color='main' bold center variant='subtitle'> NOT SUBSCRIBED USER </Text>
                                                    <Space divheight={20} />
                                                </>
                                            )}
                                            {dock.plan_name !== null && (
                                                <Attributes className='view_Page'>

                                                    <div>
                                                        <Text color='main' variant='subtitle'> Plan Name </Text>
                                                        <Text color="main" variant='subtitle' bold>
                                                            {dock.plan_name === null ? (
                                                                <Text color='main' variant='subtitle' bold >-</Text>
                                                            ) : (
                                                                <Text color='main' variant='subtitle' bold >{dock.plan_name}</Text>
                                                            )}
                                                        </Text>
                                                    </div>
                                                    <div>
                                                        <Text color='main' variant='subtitle'> Amount </Text>
                                                        {dock.amount === null ? (
                                                            <Text color='main' variant='subtitle' bold >-</Text>
                                                        ) : (
                                                            <>
                                                                {!String(dock.amount).includes('.') && (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        <NumberFormat value={String(dock.amount)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />.00
                                                                    </Text>
                                                                )}
                                                                {String(dock.amount).includes('.') && (
                                                                    <Text color='main' variant='subtitle' bold>
                                                                        <NumberFormat value={dock.amount} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                                                                    </Text>
                                                                )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <Text color='main' variant='subtitle'> Duration in Days </Text>

                                                        {dock.durationinmonth === null ? (
                                                            <Text color='main' variant='subtitle' bold >-</Text>

                                                        ) : (
                                                            <>
                                                                <Text color='main' variant='subtitle' bold >{dock.durationinmonth} <span className='text-lowercase'>Day(s)</span></Text>

                                                            </>
                                                        )}

                                                    </div>
                                                    <div>
                                                        <Text color='main' variant='subtitle'> System Access </Text>
                                                        {dock.is_active === true ? (
                                                            <Text color='success' variant='subtitle' bold > active </Text>
                                                        ) : (
                                                            <Text color='danger' variant='subtitle' bold >in Active</Text>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <Text color='main' variant='subtitle'> Start Date</Text>
                                                        {dock.start_date !== null && (
                                                            <Text color='main' variant='subtitle' bold>
                                                                {/* {dock.start_date} */}
                                                                {format(parseISO(dock.start_date), 'dd/MM/yyyy')}
                                                            </Text>
                                                        )}
                                                        {dock.start_date === null && (
                                                            <Text color='main' variant='subtitle' bold>
                                                                -
                                                            </Text>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <Text color='main' variant='subtitle'> End Date</Text>
                                                        {dock.end_date !== null && (
                                                            <Text color='main' variant='subtitle' bold>
                                                                {/* {dock.end_date} */}
                                                                {format(parseISO(dock.end_date), 'dd/MM/yyyy')}
                                                            </Text>
                                                        )}
                                                        {dock.end_date === null && (
                                                            <Text color='main' variant='subtitle' bold>
                                                                -
                                                            </Text>
                                                        )}
                                                    </div>
                                                </Attributes>
                                            )}
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm='12' md='12' lg='4' xl='4' className='m-0 p-0 align-top'>
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Card title="Profile Picture" className='profile-title'>
                            {role === 'VISITOR' ? (
                                <div className='user-mainBlock'>
                                    {dock.profile_img === null && (
                                        <div className='userProfile-block'>
                                            <Image roundedCircle src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {dock.profile_img === '' && (
                                        <div className='userProfile-block'>
                                            <Image roundedCircle src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {dock.profile_img !== null && dock.profile_img !== '' && (
                                        <div className='userProfile-block'>
                                            <Image roundedCircle src={dock.profile_img} alt='logo' />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className='user-mainBlock'>
                                    {dock.profile_img === null && (
                                        <div className='userProfile-block'>
                                            <Image roundedCircle src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {dock.profile_img === '' && (
                                        <div className='userProfile-block'>
                                            <Image roundedCircle src={noimg} alt='logo' />
                                        </div>
                                    )}
                                    {dock.profile_img !== null && dock.profile_img !== '' && (
                                        <div className='userProfile-block'>
                                            <Image roundedCircle src={dock.profile_img} alt='logo' />
                                        </div>
                                    )}
                                </div>
                            )}
                            {((dock.profile_img === null) || (dock.profile_img === '')) && role === 'VISITOR' && (
                                <div className='imageEditBlock'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            <Image roundedCircle src={editicon} alt='logo' />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                </div>
                            )}
                            {console.log(dock.id)}
                            {dock.profile_img !== null && dock.profile_img !== '' && role === 'VISITOR' && (
                                <div className='imageEditBlock'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            <Image roundedCircle src={editicon} alt='logo' className="editIconRemove" />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>

                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={removeicon} alt='logo' onClick={() => _removeUserImg(dock.id)} />
                                        </Flex>
                                    </label>
                                </div>
                            )}
                        </Card>
                    </div>
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Space divheight={15} />
                    </div>
                    <div className='ml-xl-3  mt-sm-4 mt-md-4 mt-lg-4 mt-xl-0 view_page_xs_margin'>
                        <Card title="Company Information">
                            <Row className='m-0 p-0'>
                                <Col sm='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                    {dock.company_name === null && (
                                        <>
                                            <Space divheight={20} />
                                            <Text color='main' variant='subtitle' center> Not Associated with any Company Yet </Text>
                                            <Space divheight={20} />
                                        </>
                                    )}
                                    {dock.company_name !== null && (
                                        <Attributes className='view_Page'>
                                            <div>
                                                <Text color='main' variant='subtitle'>Company Name and Location</Text>
                                                <Text variant='subtitle' color="main" bold>{dock.company_name} ({dock.city_name})</Text>
                                            </div>
                                        </Attributes>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs='12' md='12' lg='12' xl='6'>
                    <Actions>
                        <Link to='/dock-owners/list'>
                            <Button type="button" variant='transparent'>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                        </Link>
                    </Actions>
                </Col>
            </Row>
        </Main>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-center' className={action}>
            {props.children}
        </Flex>
    )
}
export interface CompanyProps {
    children?: React.ReactNode
    className?: string;
}
export const Company = ({ children, className, ...props }: CompanyProps) => {
    const attribute = classNames('company-container', className);

    return (
        <div {...props} className={attribute} >
            {children}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    dock: state.dock.selectedOwner
})

export default connect(
    mapStateToProps,
    {
        dockOwnerSetSelectedAction
    }
)(DockOwnerDetail)