import {
    DATA_SHIP_CLASSIFICATION_LIST_GET,
    DATA_SHIP_CLASSIFICATION_LIST_GET_F,
    DATA_SHIP_CLASSIFICATION_LIST_GET_R,
    DATA_SHIP_CLASSIFICATION_SELECTED_SET,
} from '../actionType';
const initialState = {
    dataLasticClassification: [],
    isLoading: false,
    error: null,
    totalPage: 0,
    totalElement: 0,
    dataLasticSelectedClassification: null,
}
export default function dataShipReducer(state = initialState, action: any) {
    switch (action.type) {
        case DATA_SHIP_CLASSIFICATION_LIST_GET:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case DATA_SHIP_CLASSIFICATION_LIST_GET_F:
            var totaldatashipclasscount = 0

            if (action.payload.content === undefined || '') {
                totaldatashipclasscount = 0
            }
            else {
                action.payload.content.map((data: any) => {
                    totaldatashipclasscount = data.total_row
                })
            }
            return {
                ...state,
                isLoading: false,
                dataLasticClassification: action.payload.content,
                totalPage: action.payload.total_page,
                totalElement: totaldatashipclasscount
            }
        case DATA_SHIP_CLASSIFICATION_LIST_GET_R:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case DATA_SHIP_CLASSIFICATION_SELECTED_SET:
            return {
                ...state,
                dataLasticSelectedClassification: action.payload
            }
        default:
            return state
    }
}