import{THICKNESS_LIST_GET,
    THICKNESS_LIST_GET_F,
    THICKNESS_LIST_GET_R,
    THICKNESS_SELECTED_SET
} from '../actionType'

export const thicknessListGetAction = (params : any) => ({
    type: THICKNESS_LIST_GET,
    payload: params
})

export const thicknessListGetFAction = (params : any) => ({
    type: THICKNESS_LIST_GET_F,
    payload: params
})

export const thicknessListGetRAction = (params : any) => ({
    type: THICKNESS_LIST_GET_R,
    payload: params
})

export const thicknessSetSelectedAction = (thickness : any) => ({
    type: THICKNESS_SELECTED_SET,
    payload: thickness
})