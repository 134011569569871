import React, { useRef, useState } from 'react'
import Modal from '../../../../common/components/modal/Modal'
import { Text } from '../../../../common/components/text/Text'
// import { Input, Select } from '../../../../common/components/control/Control'
// import { Button } from '../../../../common/components/button/Button'
import { Col, Form, Button, Row } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import { connect } from 'react-redux'
import classNames from 'classnames'
import provincesService from '../../../../services/provincesService'
import { useForm } from 'react-hook-form'
import '../Provinces.scss'
import { useEffect } from 'react'
import swal from 'sweetalert'
import codeService from '../../../../services/codeService'
import NumberFormat from 'react-number-format'
import Loader from 'react-loader-spinner';

interface provincesProps {
  id: string;
  provinces: string;
  countries: any[];
  provinPricePerView: string | number;
  provinPricePerClick: string | number;
}

interface FormData {
  txt_provinces: string;
  txt_provincesCountry: string;
  txt_provinPricePerView: string;
  txt_provinPricePerClick: string;
}
const AddProvinces = (props: any) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const [isLoading, setLoading] = React.useState(false)
  const [Provincesaddflag, setProvincesAddFlag] = React.useState(false)
  const { provinces } = props
  const { edit } = props
  const { view } = props
  const { id } = props
  //const { add } = props
  const { onClose } = props
  const [form, setForm] = useState<provincesProps>({
    id: '',
    provinces: '',
    countries: [],
    provinPricePerView: '',
    provinPricePerClick: ''
  })
  const [editForm, setEditForm] = useState({
    id: '',
    provinces: '',
    country: '',
    countryName: '',
    provinPricePerView: '',
    provinPricePerClick: ''
  })
  const get_country = (() => {
    return codeService.getCountries().subscribe({
      next: r => {

        // console.log(r.data,"countries")
        setForm({ ...form, countries: r.data })
        // console.log(form.countries ,'editForm Role')
        r.data.map((data: any, i: any) => {
          return form.countries.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })

  React.useEffect(() => {
    get_country();
  }, [])
  const [modalTitle, setModalTitle] = useState('')
  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevUser = usePrevious(provinces);

  useEffect(() => {
    if (provinces && prevUser !== provinces) {
      _getSelectedProvinces()
    }

    if (edit) {
      setModalTitle('Edit Province Detail')
    }

    else {
      setModalTitle('Province Detail')
    }
  }, [provinces])

  const _getSelectedProvinces = () => {
    const { provinces } = props
    setEditForm({
      id: provinces.id,
      provinces: provinces.name,
      country: provinces.country.id,
      countryName: provinces.country.name,
      provinPricePerView: provinces.pay_per_view,
      provinPricePerClick: provinces.pay_per_click
    })
    // console.log(provinces,'props user')
    // console.log(form,'userForm')
  }

  const _didProvincesAdd = () => {
    // e.preventDefault()
    const { onClose } = props

    const payload = {
      name: editForm.provinces,
      country_id: String(editForm.country),
      pay_per_view: Number(editForm.provinPricePerView),
      pay_per_click: Number(editForm.provinPricePerClick)
    }
    const updatepayload = {
      id: editForm.id,
      name: editForm.provinces,
      country_id: String(editForm.country),
      pay_per_view: Number(editForm.provinPricePerView),
      pay_per_click: Number(editForm.provinPricePerClick)
    }

    const _willUpdate = () => {
      const { onClose } = props
      const { user_id } = props
      setLoading(true)
      provincesService.updateProvinces(updatepayload).subscribe({
        next: () => {
          setLoading(false)
          swal("Great", "Provinces Updated Successfully", "success")
          onClose()
        },
        error: err => {
          setLoading(false)
          swal("OOPS", String(err), "error")
          console.error(err)
        }
      })
    }
    if (Provincesaddflag === true) {
      if (edit) {
        _willUpdate()
      }
      else {
        setLoading(true)
        provincesService.addProvinces(payload).subscribe({
          next: (r) => {
            setLoading(false)
            swal("Great", "New Provinces Added Successfully", "success")
            onClose()
          },
          error: err => {
            setLoading(false)
            console.error(err)
            swal("OOPS", String(err), "error")
          }
        })
      }
    }
    else {
      console.log(Provincesaddflag)
    }
  }
  onsubmit = (e: any) => {
    // e.preventDefault()
    // console.log(Object.keys(errors).length , 'error length')
    if (Object.keys(errors).length === 0) {
      _didProvincesAdd()
      setProvincesAddFlag(true)
    }
    else {
      setProvincesAddFlag(false)
    }
  }

  if (isLoading) {
    return <Text center>
        <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
        />
    </Text>
}

  return (
    <Modal
      onClose={() => onClose()}
      title={!provinces ? 'Add New province' : modalTitle}>
      <ModalBody>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='ProvinceAddForms'>
          <Attributes>
            {/* {view && (
              <div>
                <Text color='black' variant='subtitle'>ID</Text>
                <Text color='main' bold variant='subtitle'>{editForm.id}</Text>
              </div>
            )} */}
            <div>
              <Text color='black' variant='subtitle'>Provinces</Text>
              {view && (
                <Text color='main' bold variant='subtitle'>{editForm.provinces}</Text>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_provinces"
                  placeholder='Provinces'
                  value={editForm.provinces}
                  ref={register({ required: true, })}
                  onChange={(e) => setEditForm({ ...editForm, provinces: e.target.value })}
                  type="text"
                />
              )
              }
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_provinces?.type === 'required' && 'Please enter provinces Name'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Country</Text>
              {view && (
                <>
                  {editForm.countryName === null ? (
                    <Text color='main' bold variant='subtitle'>-</Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.countryName}</Text>
                  )}
                </>
              )}
              {!view && (
                <Form.Control as='select' className='basecontrol cutome-selectInputnxt'
                  value={editForm.country} name='txt_provincesCountry'
                  onChange={(e) => setEditForm({ ...editForm, country: e.target.value })}
                  ref={register({ required: true, })}>
                  <option value="">-- Select --</option>
                  {form.countries.map((data: any, i: any) => {
                    return <option key={i} value={data.id}>{data.name}</option>
                  })}
                </Form.Control>
              )}
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_provincesCountry?.type === 'required' && 'Please select country'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Pay Per View</Text>
              {view && (
                <>
                  {editForm.provinPricePerView === null ? (
                    <Text color='main' bold variant='subtitle'>-</Text>
                  ) : (
                    <>
                      {!String(editForm.provinPricePerView).includes('.') && (
                        <Text color='main' variant='subtitle' bold>
                          <NumberFormat value={String(editForm.provinPricePerView)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />.00
                        </Text>
                      )}
                      {String(editForm.provinPricePerView).includes('.') && (
                        <Text color='main' variant='subtitle' bold>
                          <NumberFormat value={editForm.provinPricePerView} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                        </Text>
                      )
                      }
                    </>
                  )}
                </>
              )}
              {!view && !edit && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_provinPricePerView"
                  placeholder='Pay per View'
                  value={editForm.provinPricePerView}
                  ref={register({ required: true, })}
                  onChange={(e) => setEditForm({ ...editForm, provinPricePerView: e.target.value })}
                  type="number"
                />
              )
              }
              {!view && edit && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_provinPricePerView"
                  placeholder='Pay per View'
                  value={editForm.provinPricePerView}
                  // ref={register({ required: true, })}
                  onChange={(e) => setEditForm({ ...editForm, provinPricePerView: e.target.value })}
                  type="number"
                />
              )
              }
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_provinPricePerView?.type === 'required' && 'Please enter Pay per view'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Pay Per Click</Text>
              {view && (
                <>
                  {editForm.provinPricePerClick === null ? (
                    <Text color='main' bold variant='subtitle'>-</Text>
                  ) : (
                    <>
                      {!String(editForm.provinPricePerClick).includes('.') && (
                        <Text color='main' variant='subtitle' bold>
                          <NumberFormat value={String(editForm.provinPricePerClick)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />.00
                        </Text>
                      )}
                      {String(editForm.provinPricePerClick).includes('.') && (
                        <Text color='main' variant='subtitle' bold>
                          <NumberFormat value={editForm.provinPricePerClick} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                        </Text>
                      )
                      }
                    </>
                  )}
                </>
                // <Text color='main' bold variant='subtitle'>Rp. {String(editForm.provinPricePerClick)}</Text>
              )}
              {!view && !edit && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_provinPricePerClick"
                  placeholder='Pay per Click'
                  value={editForm.provinPricePerClick}
                  ref={register({ required: true, })}
                  onChange={(e) => setEditForm({ ...editForm, provinPricePerClick: e.target.value })}
                  type="number"
                />
              )
              }
              {!view && edit && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_provinPricePerClick"
                  placeholder='Pay per Click'
                  value={editForm.provinPricePerClick}
                  // ref={register({ required: true, })}
                  onChange={(e) => setEditForm({ ...editForm, provinPricePerClick: e.target.value })}
                  type="number"
                />
              )
              }
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_provinPricePerClick?.type === 'required' && 'Please enter Pay per Click'}
                </span>
              </Col>
            </Row>
          </Attributes>
          <Space divheight={20} />
          <Flex jc='jc-center'>
            <Button
              className='bttn bttn-transparent'
              type="button"
              onClick={() => onClose()}
            >
              <Flex>
                <Icons icon="arrow_back" />
                BACK
              </Flex>
            </Button>
            {!view && (
              <Button className='bttn bttn-transparent' type="submit">
                <Flex>
                  <Icons color="#ffffff" icon="check" />
                  SAVE DATA
                </Flex>
              </Button>
            )}
          </Flex>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export interface ModalbodyProps {
  children?: React.ReactNode
}

export const ModalBody = ({ children, ...props }: ModalbodyProps) => {

  const modal = classNames('modalbody-container')

  return (
    <div className={modal}>
      {children}
    </div>
  )

}
export interface AttributeProps {
  children?: React.ReactNode
}
export const Attributes = ({ children, ...props }: AttributeProps) => {
  const attribute = classNames('attribute-container');

  return (
    <div {...props} className={attribute} >
      {children}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  provinces: state.provinces.selectedProvinces
})
export default connect(
  mapStateToProps)(AddProvinces)
