import React, { useRef, useState } from 'react'
import Modal from '../../../../common/components/modal/Modal'
import { Text } from '../../../../common/components/text/Text'
// import { Input, Select } from '../../../../common/components/control/Control'
import { Col, Form, Button, Row } from 'react-bootstrap'
import '../../../../common/components/button/Button.scss'
import '../../../../common/components/control/Control.scss'
// import { Button } from '../../../../common/components/button/Button'
import { Flex } from '../../../../common/components/flex/Flex'
import Icons from '../../../../common/components/icon/Icon'
import { Space } from '../../../../common/components/space/Space'
import { connect } from 'react-redux'
import classNames from 'classnames'
import cityService from '../../../../services/cityService'
import { useForm } from 'react-hook-form'
import '../cities.scss'
import { useEffect } from 'react'
import swal from 'sweetalert'
import codeService from '../../../../services/codeService'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import Loader from 'react-loader-spinner';

interface citiesProps {
  id: string;
  cities: string;
  code: string;
  // lat: string | number;
  // lon: string | number;
  countryid: string;
  country: any[];
  provinceId: string;
  province: any[];
}
interface FormData {
  txt_cityName: string;
  txt_citycode: string;
  txt_citylat: string;
  txt_citylon: string;
  txt_citycountry: string;
  txt_cityProvice: string;
}
const CitiesAdd = (props: any) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
});
  const [cityaddflag, setcityAddFlag] = React.useState(false)
  const [provinceDDShow, setProvinceDDShow] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const { city } = props
  const { edit } = props
  const { view } = props
  const { id } = props
  const { onClose } = props
  const [form, setForm] = useState<citiesProps>({
    id: '',
    cities: '',
    code: '',
    // lat: '',
    // lon: '',
    countryid: '',
    country: [],
    provinceId: '',
    province: []
  })
  const [editForm, setEditForm] = useState({
    id: '',
    cities: '',
    code: '',
    // lat: '',
    // lon: '',
    countryid: '',
    proviceId: '',
    countryName: '',
    provinceName: '',
  })
  const get_country = (() => {
    return codeService.getCountries().subscribe({
      next: r => {

        // console.log(r.data,"countries")
        setForm({ ...form, country: r.data })
        // console.log(form.country ,'Form Role')
        r.data.map((data: any, i: any) => {
          return form.country.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })
  const _getProvice = ((id :any) => {
    setEditForm({ ...editForm, countryid: id })
    return codeService.getCountryProvinces(id).subscribe({
      next: r => {
        // console.log(r.data,"countries")
        setForm({ ...form, province: r.data })
        // console.log(form.country ,'Form Role')
        r.data.map((data: any, i: any) => {
          return form.province.push(data)
        })
      },
      error: err => {
        console.error(err)
      }
    })
  })
  const _showProvince = () => {
    if (editForm.countryid === null || editForm.countryid === 'null') {
      setProvinceDDShow(false)
    }
    else {
      setProvinceDDShow(true)
    }
  }
  React.useEffect(() => {
    get_country();
    _showProvince();
  }, [])

  const [modalTitle, setModalTitle] = useState('')
  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevUser = usePrevious(city);

  useEffect(() => {
    if (city && prevUser !== city) {
      _getSelectedCity()
    }

    if (edit) {
      setModalTitle('Edit City Detail')
    }

    else {
      setModalTitle('City Detail')
    }
  }, [city])

  const _getSelectedCity = () => {
    const { city } = props
    console.log(city, 'selected city')
    var country_id: any;
    var country_name: any;
    if (city.country === null) {
      country_id = '';
      country_name = '';
    }
    else {
      country_id = city.country.id;
      country_name = city.country.name;
      _getProvice(city.country.id);
    }
    var Province_id
    var Province_name
    if (city.provinces === null) {
      Province_id = ''
      Province_name = ''
    }
    else {
      Province_id = city.provinces.id
      Province_name = city.provinces.name
    }
    setEditForm({
      id: city.id,
      cities: city.name,
      code: city.code,
      // lat: city.lat,
      // lon: city.lon,
      countryid: country_id,
      proviceId: Province_id,
      countryName: country_name,
      provinceName: Province_name,
    })
    // console.log(city,'props user')
    // setForm({ 
    //   name: city.name,
    //   email: user.email,
    //   password: user.password,
    //   roles: [],
    //   role:user.roles.id,
    //   status: user.status
    //  })
    //  console.log(form,'userForm')
  }

  const _didCityAdd = () => {
    // e.preventDefault()
    const { onClose } = props
    const cityAddForm = {
      name: editForm.cities,
      code: editForm.code,
      // lat: Number(editForm.lat),
      // lon: Number(editForm.lon),
      country_id: editForm.countryid,
      provinces_id: editForm.proviceId
    }
    const updateCityForm = {
      id: editForm.id,
      name: editForm.cities,
      code: editForm.code,
      // lat: Number(editForm.lat),
      // lon: Number(editForm.lon),
      country_id: editForm.countryid,
      provinces_id: editForm.proviceId
    }
    const _willUpdate = () => {
      const { onClose } = props
      const { user_id } = props
      setLoading(true)
      cityService.updateCity(updateCityForm).subscribe({
        next: () => {
          setLoading(false)
          swal("Great", "City Updated Successfully", "success")
          onClose()
        },
        error: err => {
          setLoading(false)
          swal("OOPS", String(err), "error")
          console.error(err)
        }
      })
    }
    if (cityaddflag === true) {
      if (edit) {
        _willUpdate()
        return
      }
      else {
        setLoading(true)
        cityService.addCity(cityAddForm).subscribe({
          next: (r) => {
            setLoading(false)
            swal("Great", "New City Added Successfully", "success")
            onClose()
          },
          error: err => {
            setLoading(false)
            console.error(err)
            swal("OOPS", String(err), "error")
          }
        })
      }
    }
    else {
      console.log(cityaddflag)
    }
  }
  onsubmit = (e: any) => {
    // e.preventDefault()
    console.log(Object.keys(errors).length, 'error length')
    if (Object.keys(errors).length === 0) {
      _didCityAdd()
      setcityAddFlag(true)
    }
    else {
      setcityAddFlag(false)
    }
  }

  if (isLoading) {
    return <Text center>
        <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
        />
    </Text>
}

  return (
    <Modal
      onClose={() => onClose()}
      title={!city ? 'Add New City' : modalTitle}>
      <ModalBody>
        <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='cityAddForms'>
          <Attributes className='singleFrame'>
            <div>
              <Text color='black' variant='subtitle'>City</Text>
              {view && (
                <>
                  {editForm.cities === null ? (
                    <Text color='main' bold variant='subtitle'> - </Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.cities}</Text>
                  )}
                </>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_cityName"
                  placeholder='City Name'
                  value={editForm.cities}
                  ref={register({ required: true, })}
                  onChange={(e) => setEditForm({ ...editForm, cities: e.target.value })}
                  type="text"
                />
              )
              }
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_cityName?.type === 'required' && 'Please enter City Name'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>City Abbreviation</Text>
              {view && (
                <>
                  {editForm.code === null ? (
                    <Text color='main' bold variant='subtitle'> - </Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.code}</Text>
                  )}
                </>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_citycode"
                  value={editForm.code}
                  placeholder='City Abbreviation'
                  // ref={register({ required: true, })}
                  onChange={(e) => setEditForm({ ...editForm, code: e.target.value })}
                  type="text"
                />
              )}
            </div>
            {/* <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_citycode?.type === 'required' && 'Please enter City Abbreviation'}
                </span>
              </Col>
            </Row> */}
            <div>
              <Text color='black' variant='subtitle'>Country</Text>
              {view && (
                <>
                  {editForm.countryName === '' ? (
                    <Text color='main' bold variant='subtitle'> - </Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.countryName}</Text>
                  )}
                </>
              )}
              {!view && !edit && (
                <Form.Control as='select' className='basecontrol cutome-selectInputnxt'
                  value={editForm.countryid} name='txt_citycountry'
                  onChange={(e) => _getProvice(e.target.value)}
                  // onChange={(e) => setEditForm({ ...editForm, countryid: e.target.value })}
                  ref={register({ required: true, })}
                >
                  <option value="">-- Select --</option>
                  {form.country.map((data: any, i: any) => {
                    return <option key={i} value={data.id}>{data.name}</option>
                  })}
                </Form.Control>
              )}
              {!view && edit && (
                <Form.Control as='select' className='basecontrol cutome-selectInputnxt'
                  value={editForm.countryid} name='txt_citycountry'
                  onChange={(e) => _getProvice(e.target.value)}
                  // onChange={(e) => setEditForm({ ...editForm, countryid: e.target.value })}
                  // ref={register({ required: true, })}
                >
                  <option value="">-- Select --</option>
                  {form.country.map((data: any, i: any) => {
                    return <option key={i} value={data.id}>{data.name}</option>
                  })}
                </Form.Control>
              )}
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_citycountry?.type === 'required' && 'Please select Country Name'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Province</Text>
              {view && (
                <>
                  {editForm.provinceName === '' ? (
                    <Text color='main' bold variant='subtitle'> - </Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.provinceName}</Text>
                  )}
                </>
              )}
              {!view && (
                <Form.Control as='select' className='basecontrol cutome-selectInputnxt'
                  value={editForm.proviceId}
                  name='txt_cityProvice'
                  onChange={(e) => setEditForm({ ...editForm, proviceId: e.target.value })}
                // ref={register({ required: true, })}
                >
                  <option value="">-- Select --</option>
                  {form.province.length === 0 ?(
                    <>
                      <option key={0} value={''}>-- No Province for This Country --</option>
                    </>
                  ):(
                    <>
                      {form.province.map((data: any, i: any) => {
                        return <option key={i} value={data.id}>{data.name}</option>
                      })}
                    </>
                  )}
                </Form.Control>
                // <>
                //   {provinceDDShow === true ?(
                //     <Form.Control as='select' className='basecontrol cutome-selectInputnxt'
                //     value={editForm.proviceId} 
                //     name='txt_cityProvice' 
                //     onChange={(e) => setEditForm({ ...editForm, proviceId: e.target.value })} 
                //     ref={register({ required: true,})}>
                //    <option value="">-- Select --</option>
                //    {form.province.map((data:any, i:any) => {
                //      return <option key={i} value={data.id}>{data.name}</option>
                //    })}

                //  </Form.Control>):(
                //     <Form.Control as='select' className='basecontrol cutome-selectInputnxt'
                //       name='txt_cityProvice' 
                //       disabled>
                //       <option value="">-- Select --</option>
                //     </Form.Control>
                //   )}
                // </>

              )}

            </div>
            {/* <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_cityProvice?.type === 'required' && 'Please select Provice Name'}
                </span>
              </Col>
            </Row> */}
            {/* <div>
              <Text color='black' variant='subtitle'>Latitude</Text>
              {view && (
                <>
                  {editForm.lat === null ? (
                    <Text color='main' bold variant='subtitle'> - </Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.lat}</Text>
                  )}
                </>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_citylat"
                  value={editForm.lat}
                  placeholder='Latitude'
                  ref={register({ required: true, min: -90.00, max: +90.00 })}
                  onChange={(e) => setEditForm({ ...editForm, lat: e.target.value })}
                  type="number"
                />
              )}
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_citylat?.type === 'required' && 'Please enter Latitude'}
                  {errors.txt_citylat?.type === 'min' && 'Minimum Value should be -90'}
                  {errors.txt_citylat?.type === 'max' && 'Maximum Vlaue should be +90'}
                </span>
              </Col>
            </Row>
            <div>
              <Text color='black' variant='subtitle'>Longtitude</Text>
              {view && (
                <>
                  {editForm.lon === null ? (
                    <Text color='main' bold variant='subtitle'> - </Text>
                  ) : (
                    <Text color='main' bold variant='subtitle'>{editForm.lon}</Text>
                  )}
                </>
              )}
              {!view && (
                <Form.Control className='basecontrol custome-input'
                  name="txt_citylon"
                  value={editForm.lon}
                  placeholder='Longtitude'
                  ref={register({ required: true, min: -180.00, max: +180.00 })}
                  onChange={(e) => setEditForm({ ...editForm, lon: e.target.value })}
                  type="number"
                />
              )}
            </div>
            <Row className='m-0 p-0'>
              <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
              </Col>
              <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                <span className='err-msg text-capitalize'>
                  {errors.txt_citylon?.type === 'required' && 'Please enter Longtitude'}
                  {errors.txt_citylon?.type === 'min' && 'Minimum Value should be -180'}
                  {errors.txt_citylon?.type === 'max' && 'Maximum Vlaue should be +180'}
                </span>
              </Col>
            </Row> */}
          </Attributes>
          <Space divheight={20} />
          <Flex jc='jc-center'>
            <Button
              className='bttn bttn-transparent'
              type="button"
              onClick={() => onClose()}
              variant='transparent'>
              <Flex>
                <Icons icon="arrow_back" />
                BACK
              </Flex>
            </Button>
            {!view && (
              <Button variant='transparent' className='bttn bttn-transparent' type="submit">
                <Flex>
                  <Icons color="#ffffff" icon="check" />
                  SAVE DATA
                </Flex>
              </Button>
            )}
          </Flex>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export interface ModalbodyProps {
  children?: React.ReactNode
}

export const ModalBody = ({ children, ...props }: ModalbodyProps) => {

  const modal = classNames('modalbody-container')

  return (
    <div className={modal}>
      {children}
    </div>
  )

}
// export interface AttributeProps {
//   children?: React.ReactNode
// }
// export const Attributes = ({ children, ...props }: AttributeProps) => {
//   const attribute = classNames('attribute-container');

//   return (
//     <div {...props} className={attribute} >
//       {children}
//     </div>
//   )
// }

const mapStateToProps = (state: any) => ({
  city: state.cities.selectedcities
})
export default connect(
  mapStateToProps)(CitiesAdd)
