import {
    USER_LIST_GET,
    USER_LIST_GET_F,
    USER_LIST_GET_R, USER_SELECTED_SET, USER_LIST_GET_All, USER_LIST_GET_ALL_F, USER_LIST_GET_ALL_R,
    SYSTEM_USER_LIST_GET,
    SYSTEM_USER_LIST_GET_F,
    SYSTEM_USER_LIST_GET_R, SYSTEM_USER_SELECTED_SET, SYSTEM_USER_LIST_GET_All, SYSTEM_USER_LIST_GET_ALL_F, SYSTEM_USER_LIST_GET_ALL_R
  } from '../actionType'

export const userListGetAction = (params:any) => ({
    type: USER_LIST_GET,
    payload: params
})

export const selectAllUserAction = (params:any) => ({
    type :  USER_LIST_GET_All,
    payload : params
})

export const userListGetFAction = (data:any) => ({
    type: USER_LIST_GET_F,
    payload: data
})

export const userListGetRAction = (error:any) => ({
    type: USER_LIST_GET_R,
    payload: error
})

export const allUserListGetFAction = (data:any) => ({
    type: USER_LIST_GET_ALL_F,
    payload: data
})

export const allUserListGetRAction = (error:any) => ({
    type: USER_LIST_GET_ALL_R,
    payload: error
})

export const userSetSelectedAction = (user:any) => ({
    type: USER_SELECTED_SET,
    payload: user
})

export const systemUserListGetAction = (params:any) => ({
    type: SYSTEM_USER_LIST_GET,
    payload: params
})

export const selectAllSystemUserAction = (params:any) => ({
    type :  SYSTEM_USER_LIST_GET_All,
    payload : params
})

export const systemUserListGetFAction = (data:any) => ({
    type: SYSTEM_USER_LIST_GET_F,
    payload: data
})

export const systemUserListGetRAction = (error:any) => ({
    type: SYSTEM_USER_LIST_GET_R,
    payload: error
})

export const allSystemUserListGetFAction = (data:any) => ({
    type: SYSTEM_USER_LIST_GET_ALL_F,
    payload: data
})

export const allSystemUserListGetRAction = (error:any) => ({
    type: SYSTEM_USER_LIST_GET_ALL_R,
    payload: error
})

export const systemUserSetSelectedAction = (user:any) => ({
    type: SYSTEM_USER_SELECTED_SET,
    payload: user
})
