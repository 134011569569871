import React, { useState, useEffect, useRef } from 'react';
import { Main } from '../../../../common/components/main/Main'
import { Form, Row, Col, Button, Image } from 'react-bootstrap'
// import {Button} from '../../../../common/components/button/Button'
import noImg from '../../../../assets/images/NoImg.jpg';
import editicon from '../../../../assets/images/editone.png';
import { Text } from '../../../../common/components/text/Text'
import Card from '../../../../common/components/card/Card'
import { Flex } from '../../../../common/components/flex/Flex'
import '../../../../common/components/control/Control.scss'
import '../../../../common/components/button/Button.scss'
// import {Input, Select} from '../../../../common/components/control/Control'
import { Space } from '../../../../common/components/space/Space'
import Select from 'react-select';
import removeicon from '../../../../assets/images/Remove.png'
import { Attributes } from '../../../../common/components/attribute/Attribute'
import codeService from '../../../../services/codeService'
import { dockService } from '../../../../services/dockService'
import classNames from 'classnames'
import Icons from '../../../../common/components/icon/Icon'
import { Link } from 'react-router-dom'
import { JsxChild } from 'typescript'
import { connect } from 'react-redux';
import { dockListGetAction, dockSetSelectedAction } from '../../../../store/action/dockActions';
import { LENGTH_UNIT_DEFAULT, DRAFT_UNIT_DEFAULT, BEAM_UNIT_DEFAULT } from '../../../../constants/units'
import { history } from '../../../../store';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form'
import { Label } from '../../../../common/components/label/Label';
import Loader from 'react-loader-spinner';

interface FormData {
    txt_editdockName: string;
    txt_editdockcity: string;
    txt_editdockType: string;
    txt_editdockport: string;
    txt_editdockCompany: string;
    txt_editdockLength: string;
    txt_editdockWidth: string;
    txt_editdockDraft: string;
    txt_editdockDwt: string;
    txt_editdockcrane: string;
    txt_editdockcraneCapacity: string;
    txt_editdocklat: string;
    txt_editdocklon: string;
    txt_editdockstatus: string;
    txt_editdockunit: string;
    txt_toleranceedit: string;
    txt_shipTypeInDockedit: string;
    txt_editShipMaxWidth: string;
};

interface DockEditProps {
    id: string | number;
    name: string,
    type: any,
    dwt_capacity: string | number,
    length: string | number,
    width: string | number,
    draft: string | number,
    ship_length: string | number,
    ship_width: string | number,
    ship_draft: string | number,
    cranes: string | number,
    crane_capacity: string | number,
    company: any,
    city: any,
    port: any,
    //services: any,
    status: string,
    latitude: string | number,
    longitude: string | number,
    unit: string,
    shiptypeDock: any,
    tolerance: string,
    cmpID: string,
    dock_image: string,
    port_id: string,

    // verified_date: string,
    // created_date: string,
}
interface bvalueProps {
    string_image: string | ArrayBuffer | null
}
const DockEdit = (props: any) => {
    //const {dock} = props
    const { match } = props
    const { pages } = match.params
    const maxOptions = 5;
    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });
    const [showDelete, setShow] = React.useState(false);
    var [removeDockImage, setRemoveDockImage] = React.useState(0)
    const [isLoading, setLoading] = React.useState(false)
    const [dockeditflag, setDockEditFlag] = React.useState(false)
    const [display, setDisplay] = useState<DockEditProps>({
        id: '',
        name: '',
        type: '',
        dwt_capacity: '',
        length: '',
        width: '',
        draft: '',
        cranes: '',
        crane_capacity: '',
        company: '',
        city: '',
        port: '',
        port_id: '',
        status: '',
        latitude: '',
        longitude: '',
        unit: '',
        shiptypeDock: '',
        tolerance: '',
        cmpID: '',
        dock_image: '',
        ship_length: '',
        ship_width: '',
        ship_draft: '',
    })
    const [docks, setDocks] = useState<any>({
        dockName: '',
        docktypes: [],
        isLoading: false,
        isSubmitting: false,
        cities: [],
        ports: [],
        companies: [],
        specifications: [],
        type: '',
        dwt_capacity: '',
        length: '',
        width: '',
        draft: '',
        cranes: '',
        crane_capacity: '',
        company: '',
        city: '',
        port: '',
        services: [],
        status: '',
        latitude: '',
        longitude: '',
        unit: '',
        shipTypes: [],
        tolerancearray: []
    })
    const upload = useRef(null);
    var base64: string | ArrayBuffer | null
    const [bvalue, setBValue] = React.useState<bvalueProps>({
        string_image: ''
    })
    const getBase64 = (event: any) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // console.log(reader.result);
            base64 = reader.result
            setBValue(
                {
                    string_image: reader.result
                })
            // console.log(base64,'base64')
            // console.log(bvalue.string_image,'base 64 value')
            ImageAPIDryDock(reader.result);
            //            history.push(`/docks/edit/${id}/${pgnum}`)
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const ImageAPIDryDock = (imageurl: any) => {
        const { id } = match.params
        const { pgnum } = match.params
       const imagePayload = {
            image: imageurl,
            id: String(id)
        }
        setLoading(true)
        dockService.updateDockImage(imagePayload).subscribe({
            next: () => {
                // swal("Great", "Dock Company Image Updated", "success")
                //                history.push(`/comapanies/company-type`)
                //                history.push(`/docks/edit/${id}/0`)
                window.location.reload()
                //                setLoading(false)  
                // history.push(`/docks/list/${pgnum}`)
                //               _getDetail(id)
                //                history.push(`/docks/edit/${id}/${pgnum}`)
            },
            error: err => {
                swal("OOPS", String(err), "error")
                setLoading(false)
                console.error(err)
            }
        })
    }
    const _getDockTypes = (() => {
        return codeService.getDockTypes().subscribe({
            next: r => {

                // console.log(r.data,"ship type")
                setDocks({ ...docks, docktypes: r.data })
                // console.log(docks.docktypes ,'ship type data in ship add page')
                r.data.map((data: any, i: any) => {
                    return docks.docktypes.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    // Used to get ship companies list and store to companies variable.
    const _getCompany = (() => {
        return codeService.getCompanies({ company_type: 'DOCK' }).subscribe({
            next: r => {
                // console.log(r.data,'companies in ship add page')
                setDocks({ ...docks, companies: r.data })
                r.data.map((data: any, i: any) => {
                    return docks.companies.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getPorts = ((cityId: any) => {
        return codeService.getPorts({ cityCode: cityId }).subscribe({
            next: r => {
                // console.log(r.data,'ports in edit')
                setDocks({ ...docks, ports: r.data, city: cityId })
                r.data.map((data: any, i: any) => {
                    return docks.ports.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })
    const _getShipType = (() => {
        return codeService.getShipTypes().subscribe({
            next: r => {

                // console.log(r.data,"ship type")
                setDocks({ ...docks, shipTypes: r.data })
                // console.log(ship.shipType ,'ship type data in ship add page')
                r.data.map((data: any, i: any) => {
                    return docks.shipTypes.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getTolerance = (() => {
        return codeService.getTolerance().subscribe({
            next: r => {

                // console.log(r.data,"ship type")
                setDocks({ ...docks, tolerancearray: r.data })
                // console.log(ship.shipType ,'ship type data in ship add page')
                r.data.map((data: any, i: any) => {
                    return docks.tolerancearray.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })

    const _getCities = (() => {
        return codeService.getCities().subscribe({
            next: r => {

                setDocks({ ...docks, cities: r.data })
                r.data.map((data: any, i: any) => {
                    return docks.cities.push(data)
                })
            },
            error: err => {
                console.error(err)
            }
        })
    })


    const _getDetail = (id: any) => {
        setDocks({ ...docks, isLoading: true })
        setLoading(true)
        return dockService.getDockDetail(id).subscribe({
            next: r => {
                const { data } = r.data
                // console.log(r,'data to be displayed in setDisplay')
                var tole: string = ''
                if (r.data.tolerance === null || '') {
                    tole = ''
                }
                else {
                    // console.log('tolerance', r.data.tolerance)
                    tole = r.data.tolerance
                }
                var SType: any
                if (r.data.shipType === null || '') {
                    SType = ''
                }
                else {
                    SType = r.data.shipType
                }
                setDisplay({
                    id: Number(r.data.id),
                    name: r.data.name,
                    type: r.data.dockType.id,
                    dwt_capacity: Number(r.data.dwt_capacity),
                    length: Number(r.data.length),
                    width: Number(r.data.width),
                    draft: Number(r.data.draft),
                    cranes: Number(r.data.cranes),
                    crane_capacity: Number(r.data.crane_capacity),
                    company: r.data.dockCompany.name,
                    city: r.data.port.city.name,
                    port: r.data.port.name,
                    port_id: r.data.port.id,
                    status: r.data.status,
                    latitude: Number(r.data.lat),
                    longitude: Number(r.data.lon),
                    unit: r.data.unit,
                    shiptypeDock: SType,
                    tolerance: tole,
                    cmpID: r.data.dockCompany.id,
                    dock_image: r.data.dock_image,
                    ship_length: Number(r.data.max_ship_length),
                    ship_width: Number(r.data.max_ship_width),
                    ship_draft: Number(r.data.max_ship_draft),
                })
                props.dockSetSelectedAction(r.data)
                setDocks({ ...docks, isLoading: false })
                setLoading(false)
                // console.log(SType, 'Stype')
                setSelectedValue(Array.isArray(SType) ? SType.map((x: any) => String(x.value)) : []);
            },
            error: err => {
                console.error(err)
                setDocks({ ...docks, isLoading: false })
                setLoading(false)
            }
        })
    }

    // var city_Code : any = ''
    // {docks.cities.map((data, i) => {
    //     if(cityId === data.name)
    //     {
    //         city_Code = data.code
    //         console.log(city_Code,'city code')
    //         codeService.getPorts({cityCode: city_Code}).subscribe({
    //             next: r => {
    //                 // console.log(r.data)
    //                 setDocks({...docks, ports : r.data, city : cityId})
    //                 r.data.map((data : any, i:any) => {
    //                     return docks.ports.push(data)
    //                 })
    //             }, 
    //             error : err => {
    //                 console.error(err)
    //             }
    //         })
    //     }
    //     else{
    //         setDocks({...docks, city : cityId})}
    // })}

    const _getPort = ((cityCode: any) => {
        // console.log('get ports called')
        // console.log('cityCode',cityCode)
        // console.log(docks.cities,'dock city')
        var city_Code: any = ''
        docks.cities.map((data: any, i: any) => {
            if (cityCode === data.name) {
                city_Code = data.id
                // console.log(city_Code, 'edit city code')
                codeService.getPorts({ cityCode: city_Code }).subscribe({
                    next: r => {
                        setDisplay({ ...display, city: cityCode })
                        setDocks({ ...docks, ports: r.data })
                        r.data.map((data: any, i: any) => {
                            return docks.ports.push(data)
                        })
                    },
                    error: err => {
                        console.error(err)
                    }
                })
            }
            else {
                setDisplay({ ...display, city: cityCode })
            }
        })

    })


    React.useEffect(() => {
        const { id } = match.params
        _getCities()
        _getDockTypes()
        _getTolerance()
        _getShipType()
        //_getSpecifications()
        _getCompany()
        _getDetail(id)
        _getPort(display.city)

    }, [])

            // Delete the pericular list.  
            const handleClose = () => setShow(false);
            const handleShow = (dock: any) => {
            setShow(true);
            setRemoveDockImage(dock.id);
            // console.log(deleteShip,'deleted ship id')
            }
        
                const _removeDockLogo = (id: any) => {
                return dockService.deleteDockAllImage(id).subscribe({
                  next: (r) => {
                    console.log(r, 'response')
                    setShow(false);
                    // swal("Great", " Image Removed Successfully", "success")
                    window.location.reload();
                    // history.push(`/ships/${id}/`)
                  },
                  error: err => {
                    swal("OOPS", String(err), "error")
                  }
                })
              }

    const _didDockEdit = () => {
        // e.preventDefault()
        // console.log(display.id,'id in edit updated form')
        var dockLen: any;
        var dockWidth: any;
        var dockDraft: any;
        var shipMaxLen: any;
        var shipMaxWidth: any;
        var shipMaxDraft: any;
        if (display.length === '') {
            dockLen = null
        }
        else {
            dockLen = Number(display.length)
        }
        if (display.width === '') {
            dockWidth = null
        }
        else {
            dockWidth = Number(display.width)
        }
        if (display.draft === '') {
            dockDraft = null
        }
        else {
            dockDraft = Number(display.draft)
        }
        if (display.ship_length === '') {
            shipMaxLen = null
        }
        else {
            shipMaxLen = Number(display.ship_length)
        }
        if (display.ship_width === '') {
            shipMaxWidth = null
        }
        else {
            shipMaxWidth = Number(display.ship_width)
        }
        if (display.ship_draft === '') {
            shipMaxDraft = null
        }
        else {
            shipMaxDraft = Number(display.ship_draft)
        }
        var port_id: string = ''
        docks.companies.map((data: any, i: any) => {
            if (display.company === data.name) {
                setDisplay({ ...display, cmpID: String(data.id) })
            }
        })
        docks.ports.map((data: any, i: any) => {
            if (display.port === data.name) {
                port_id = data.id
                // console.log(data.id,'port id')
            }
        })
        var shipTypeArray = selectedValue
        var shipTypeValue: any
        if (shipTypeArray.length === 0) {
            shipTypeValue = selectedValue
        }
        else {
            shipTypeValue = selectedValue
        }
        const updatedDockEditForm = {

            id: Number(display.id),
            name: display.name,
            company: display.cmpID,
            type: String(display.type),
            status: display.status,
            port: String(port_id) || display.port_id,
            cranes: Number(display.cranes),
            craneCapacity: Number(display.crane_capacity),
            length: dockLen,
            draft: dockDraft,
            width: dockWidth,
            dwtCapacity: Number(display.dwt_capacity),
            lengthUnit: LENGTH_UNIT_DEFAULT.code,
            draftUnit: DRAFT_UNIT_DEFAULT.code,
            widthUnit: BEAM_UNIT_DEFAULT.code,
            latitude: Number(display.latitude),
            longitude: Number(display.longitude),
            unit: display.unit,
            // tolerance: Number(display.tolerance),
            max_ship_length: shipMaxLen,
            max_ship_length_unit_id: LENGTH_UNIT_DEFAULT.code,
            max_ship_width: shipMaxWidth,
            max_ship_width_unit_id: BEAM_UNIT_DEFAULT.code,
            max_ship_draft: shipMaxDraft,
            max_ship_draft_unit_id: DRAFT_UNIT_DEFAULT.code,
            shipType: shipTypeValue,
            image: display.dock_image
        }
       if (dockeditflag === true) {
            const { pgnum } = match.params
            const { key } = match.params
            setLoading(true)
            dockService.updateDock(updatedDockEditForm).subscribe({
                next: () => {
                    // console.log(pages,'pages')
                    swal("Great", "DryDock Updated Successfully", "success")
                    setLoading(false)
                    console.log(pgnum, "the value from edit page")
                    history.push(`/docks/list/${pgnum}`)
                },
                error: err => {
                    swal("OOPS", String(err), "error")
                    setLoading(false)
                    console.error(err)
                }
            })
        }
        else {
            console.log(dockeditflag)
        }

    }
    const [selectedValue, setSelectedValue] = useState<any>([]);

    const handleChange = (e: any) => {
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
    }

    const _goback = () => {
        const { pgnum } = match.params
        const { key } = match.params
        console.log('go back called')
        console.log(pgnum, 'page')
        history.push(`/docks/list/${pgnum}`)
    }

    const data = docks.shipTypes.map((data: any, i: any) => {
        // console.log(data,'data within options')
        return { label: String(data.name), value: String(data.id) }
    });
    // console.log(selectedValue, 'selcted value')
    onsubmit = (e: any) => {
        // e.preventDefault()
        if (Object.keys(errors).length === 0) {
            // console.log(selectedValue)
            // console.log(selectedValue.length,'length')
            if (selectedValue.length <= 5 || selectedValue.length === 16) {
                //  console.log(selectedValue.length,'length')
                _didDockEdit()
                setDockEditFlag(true)
            }
            else {
                swal("Warning", 'You Can Select Upto 5 Shiptypes Only', "warning")
            }
        }
        else {
            setDockEditFlag(false)
        }
    }

    if (isLoading) {
        return <Text center>
            <Loader
                type="Rings"
                color="#233269"
                height={100}
                width={100}
                timeout={30000}
            />
        </Text>
    }
    return (
        <Main>
            {/* <Text color='black' variant="title">Edit DryDock Information</Text> */}
            <div className='title-name-block'>
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none'>
                    {display.dock_image === null && (
                        <Image src={noImg} alt='logo' roundedCircle />
                    )}
                    {display.dock_image === '' && (
                        <Image src={noImg} alt='logo' roundedCircle />
                    )}
                    {display.dock_image !== null && display.dock_image !== '' && (
                        <Image src={display.dock_image} alt='logo' roundedCircle />
                    )}
                </div>
                <div className='d-inline-block d-md-inline-block d-sm-inline-block d-lg-none d-xl-none imageHeader'>
                    <label className="custom-file-upload">
                        <Image src={editicon} alt='logo' />
                        <input
                            ref={upload}
                            type="file"
                            onChange={getBase64}
                        />
                    </label>
                </div>
                <Text variant='title' color='main' className='txt-capitalize'>
                    {/* {String(display.email).substring(0,String(display.email).indexOf('@'))} */}
                    {display.name}
                </Text>
                <Label className={display.status === 'UNVERIFIED' ? 'danger' : 'warning'} >
                    {display.status === 'UNVERIFIED' ? 'Not Verified' : 'Verified'}
                </Label>
            </div>
            <Space divheight={15} />
            <Row className='p-0 m-0'>
                <Col xs='12' sm='12' md='12' lg='9' xl='9' className='p-0 m-0'>
                    <Form onSubmit={handleSubmit(onsubmit)} autoComplete='off' name='userEditForms'>
                        <Card title="Edit DryDock Information" >
                            <Row className='m-0 p-0'>
                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes className='dock-add-edit'>
                                        <div>
                                            <Text color="main" variant='subtitle'>Name</Text>
                                            <Form.Control
                                                type="text" className='basecontrol custome-input'
                                                name="txt_editdockName"
                                                value={display.name}
                                                onChange={(e) => setDisplay({ ...display, name: e.target.value })}
                                                ref={register({ required: true, })}
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockName?.type === 'required' && 'Please enter Dock name'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color="main" variant='subtitle'>DryDock Type</Text>
                                            <Form.Control as='select'
                                                className='basecontrol cutome-selectInputnxt'
                                                value={display.type} name="txt_editdockType" ref={register({ required: true, })} onChange={(e) => setDisplay({ ...display, type: e.target.value })}>
                                                <option className='option-placeholder'> -- Select Dock Type --</option>
                                                {docks.docktypes.map((data: any, i: any) => {
                                                    return <option key={i} value={data.id}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockType?.type === 'required' && 'Please enter Dock Type'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <div></div>
                                            <Row className='m-0 p-0 mt-2'>
                                                <Col className='m-0 p-0 pr-2' xs='5'>
                                                    <Text color='tablehead' variant='subtitle' >Dock Size</Text>
                                                </Col>
                                                <Col className='m-0 p-0' xs='7'>
                                                    <Text color='tablehead' variant='subtitle' >Max Ship Dimensions</Text>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Text color="main" variant='caption'>Length ({LENGTH_UNIT_DEFAULT.name})</Text>
                                            <Row className='m-0 p-0'>
                                                <Col className='m-0 p-0 pr-2' xs='5'>
                                                    <Form.Control
                                                        type="number" className='basecontrol custome-input'
                                                        name="txt_editdockLength"
                                                        value={display.length}
                                                        placeholder='Dock Length'
                                                        onChange={(e) => setDisplay({ ...display, length: e.target.value })}
                                                        ref={register({ required: true, })}
                                                    />
                                                </Col>
                                                <Col className='m-0 p-0' xs='7'>
                                                    <Form.Control
                                                        type="number" className='basecontrol custome-input'
                                                        name="txt_editShipMaxLength"
                                                        placeholder='Max Ship Dimensions'
                                                        value={display.ship_length}
                                                        onChange={(e) => setDisplay({ ...display, ship_length: e.target.value })}
                                                    // ref={register({ required: true, })}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockLength?.type === 'required' && 'Please enter Dock Length'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color="main" variant='caption'>Width ({BEAM_UNIT_DEFAULT.name})</Text>
                                            <Row className='m-0 p-0'>
                                                <Col className='m-0 p-0 pr-2' xs='5'>
                                                    <Form.Control
                                                        type="number" className='basecontrol custome-input'
                                                        name="txt_editdockWidth"
                                                        placeholder='Dock Width'
                                                        value={display.width}
                                                        onChange={(e) => setDisplay({ ...display, width: e.target.value })}
                                                        ref={register({ required: true, })}
                                                    />
                                                </Col>
                                                <Col className='m-0 p-0' xs='7'>
                                                    <Form.Control
                                                        type="number" className='basecontrol custome-input'
                                                        name="txt_editShipMaxWidth"
                                                        placeholder='Max Ship Dimensions'
                                                        value={display.ship_width}
                                                        // ref={register({ max: display.width, })}
                                                        onChange={(e) => setDisplay({ ...display, ship_width: e.target.value })}
                                                    // ref={register({ required: true, })}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>
                                                    {/* {errors.txt_editShipMaxWidth?.type ==='max' && 'Ship dimension must be small than dock size'} */}
                                                    {errors.txt_editdockWidth?.type === 'required' && 'Please enter Dock Width'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color="main" variant='caption'>Draft ({DRAFT_UNIT_DEFAULT.name})</Text>

                                            <Row className='m-0 p-0'>
                                                <Col className='m-0 p-0 pr-2' xs='5'>
                                                    <Form.Control
                                                        type="number" className='basecontrol custome-input'
                                                        name="txt_editdockDraft"
                                                        placeholder='Dock Draft'
                                                        value={display.draft}
                                                        onChange={(e) => setDisplay({ ...display, draft: e.target.value })}
                                                        ref={register({ required: true, })}
                                                    />
                                                </Col>
                                                <Col className='m-0 p-0' xs='7'>
                                                    <Form.Control
                                                        type="number" className='basecontrol custome-input'
                                                        name="txt_editShipMaxDraft"
                                                        placeholder='Max ship Dimensions'
                                                        value={display.ship_draft}
                                                        onChange={(e) => setDisplay({ ...display, ship_draft: e.target.value })}
                                                    // ref={register({ required: true, })}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockDraft?.type === 'required' && 'Please enter Dock Draft'}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Attributes>
                                </Col>
                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes>
                                        <div>
                                            <Text color="main" variant='subtitle'>DWT Capacity</Text>
                                            <Form.Control
                                                type="number" className='basecontrol custome-input'
                                                name="txt_editdockDwt"
                                                value={display.dwt_capacity}
                                                onChange={(e) => setDisplay({ ...display, dwt_capacity: e.target.value })}
                                            // ref={register({ required: true, })}
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockDwt?.type === 'required' && 'Please enter Dock DWT Capacity'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color="main" variant='subtitle'>Crane</Text>
                                            <Form.Control
                                                type="number" className='basecontrol custome-input'
                                                name="txt_editdockcrane"
                                                value={display.cranes}
                                                onChange={(e) => setDisplay({ ...display, cranes: e.target.value })}
                                            // ref={register({ required: true, })}
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockcrane?.type === 'required' && 'Please enter Number of Crane'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color="main" variant='subtitle'>Crane Capacity</Text>
                                            <Form.Control
                                                type="number" className='basecontrol custome-input'
                                                name="txt_editdockcraneCapacity"
                                                value={display.crane_capacity}
                                                onChange={(e) => setDisplay({ ...display, crane_capacity: e.target.value })}
                                            // ref={register({ required: true, })}
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockcraneCapacity?.type === 'required' && 'Please enter Crane Capcity'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        <div>
                                            <Text color="main" variant='subtitle'>Latitude</Text>
                                            <Form.Control
                                                type="number" className='basecontrol custome-input'
                                                name="txt_editdocklat"
                                                value={display.latitude}
                                                onChange={(e) => setDisplay({ ...display, latitude: e.target.value })}
                                                ref={register({ required: true, min: -90.00, max: +90.00 })}
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>
                                                    {errors.txt_editdocklat?.type === 'required' && 'Please enter Dock Latitude'}
                                                    {errors.txt_editdocklat?.type === 'min' && 'Minimum Value should be -90'}
                                                    {errors.txt_editdocklat?.type === 'max' && 'Maximum Vlaue should be +90'}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Text color="main" variant='subtitle'>Longitude</Text>
                                            <Form.Control
                                                type="number" className='basecontrol custome-input'
                                                name="txt_editdocklon"
                                                value={display.longitude}
                                                onChange={(e) => setDisplay({ ...display, longitude: e.target.value })}
                                                ref={register({ required: true, min: -180.00, max: +180.00 })}
                                            />
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdocklon?.type === 'required' && 'Please enter Dock Longitude'}
                                                    {errors.txt_editdocklon?.type === 'min' && 'Minimum Value should be -180'}
                                                    {errors.txt_editdocklon?.type === 'max' && 'Maximum Vlaue should be +180'}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Attributes>
                                </Col>
                            </Row>
                            <Row className='m-0 p-0'>
                                <Col xs='12' md='12' lg='8' xl='8' className='p-0 m-0'>
                                    <Attributes className='dock-add-edit'>

                                        {/* <div>
                                        <Text color="main" variant='subtitle'> Port City</Text>
                                        <Form.Control as='select'
                                        className='basecontrol cutome-selectInputnxt'
                                        value={display.city} name="txt_editdockcity"
                                         ref={register({ required: true, })}
                                          onChange={(e) => _getPort(e.target.value)}>
                                            <option value=''> -- Select city -- </option>
                                            {docks.cities.map((data:any,i:any) => {
                                                return <option key={i} value={data.id}>{data.name}</option>
                                            })}
                                        </Form.Control>
                                    </div> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle' > Port City</Text>
                                            <input className='basecontrol cutome-selectInputnxt'
                                                value={display.city} id="txt_dockcity"
                                                list='protfindbycity'
                                                placeholder='-- Select city --'
                                                name="txt_dockcity"
                                                ref={register({ required: true, })}
                                                onChange={(e) => _getPort(e.target.value)}>

                                            </input>
                                            <datalist id="protfindbycity">
                                                {docks.cities.map((data: any, i: any) => {
                                                    return <option key={i} value={data.name}></option>
                                                })}
                                            </datalist>
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockcity?.type === 'required' && 'Please Select City'}
                                                </span>
                                            </Col>
                                        </Row> */}

                                        <div>
                                            <Text color="main" variant='subtitle'>Nearest Port</Text>
                                            <input list='porteditdatalist'
                                                className='basecontrol cutome-selectInputnxt'
                                                value={display.port} name="txt_editdockport"
                                                // ref={register({ required: true, })}
                                                onChange={(e) => setDisplay({ ...display, port: e.target.value })}
                                                placeholder='--Select Port City First--'>

                                            </input>
                                            <datalist id="porteditdatalist">
                                                {docks.ports.length === 0 && (
                                                    <option className='option-placeholder'> --No ports for selected city-- </option>
                                                )}
                                                {docks.ports.map((data: any, i: any) => {
                                                    return <option key={i} value={data.name}></option>
                                                })}
                                            </datalist>
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockport?.type === 'required' && 'Please Select Port'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                        {/* <div>
                                        <Text color="main" variant='subtitle'>Company</Text>
                                        <Form.Control as='select'
                                        className='basecontrol cutome-selectInputnxt'
                                        value={display.company} name="txt_editdockCompany" 
                                        onChange={(e) => setDisplay({...display, company:e.target.value})}>
                                                <option value=''> -- Select Company -- </option>
                                                {docks.companies.map((data:any,i:any)=>{
                                                    return <option key={i} value={data.id}>{data.name}</option>
                                                })}
                                        </Form.Control>
                                    </div> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle' >Company</Text>
                                            <input className='basecontrol cutome-selectInputnxt'
                                                value={display.company}
                                                list='companydatalist'
                                                ref={register({ required: true, })}
                                                placeholder='-- Select Company --'
                                                name="txt_dockCompany"
                                                id="txt_dockCompany"
                                                onChange={(e) => setDisplay({ ...display, company: e.target.value })}
                                            >

                                            </input>
                                            <datalist id="companydatalist">
                                                {docks.companies.map((data: any, i: any) => {
                                                    return <option key={i} value={data.name}></option>
                                                })}
                                            </datalist>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockCompany?.type === 'required' && 'Please Select Dock Company'}
                                                </span>
                                            </Col>
                                        </Row>
                                        {/* <div>
                                        <Text color="main" variant='subtitle'>Specification</Text>
                                        <div>
                                            <Space divheight={10}/>
                                            {docks.specifications.map((data:any,i:any)=>{
                                                return(
                                                <label className="services" key={data.id}>
                                                    <Flex jc="jc-checkbox">
                                                        <Form.Control 
                                                        type='checkbox'
                                                        value={data.id}
                                                        onChange={()=> _toggleService(data.id)}
                                                        />
                                                        <Text color="main" variant='subtitle'>{data.name}</Text>
                                                    </Flex>
                                                </label>
                                            )})}
                                        </div>
                                    </div> */}
                                        <div>
                                            <Text color='tablehead' variant='subtitle' >Preferred Ship Type</Text>
                                            <div>
                                                <Select
                                                    className="dropdown"
                                                    placeholder="Select Ship Type"
                                                    // value={data.filter((obj: any) => selectedValue.includes(obj.value))}  
                                                    value={data.filter((obj: any) => selectedValue.includes(obj.value) || selectedValue.includes('All'))}
                                                    options={selectedValue.length === maxOptions ? [] : [{ label: 'All', value: 'All' }, ...data]}
                                                    noOptionsMessage={() => {
                                                        return selectedValue.length === maxOptions
                                                            ? 'you can select upto 5 shiptypes only'
                                                            : 'No options available';
                                                    }}
                                                    onChange={handleChange} // assign onChange function
                                                    isMulti
                                                    isClearable
                                                    isSearchable
                                                />
                                            </div>
                                        </div>

                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_shipTypeInDockedit?.type === 'required' && 'Please Select Ship Type from a list'}
                                                </span>
                                            </Col>
                                        </Row>
                                        {/* <div>
                                            <Text color='tablehead' variant='subtitle' >Tolerance</Text>
                                            <Form.Control className='basecontrol cutome-selectInputnxt' as='select' 
                                                value={display.tolerance}
                                                name="txt_toleranceedit"
                                                id='txt_toleranceedit'
                                                // ref={register({ required: true, })}
                                                 onChange={(e) => setDisplay({ ...display, tolerance: e.target.value })}>
                                                <option value=''>--- Select Tolerance ---</option>
                                                {docks.tolerancearray.map((data:any, i:any) => {
                                                    return <option key={i} value={data.value}>{data.name}</option>
                                                })}
                                            </Form.Control>
                                            <Form.Control
                                                type="number" className='basecontrol custome-input'
                                                name="txt_toleranceedit"
                                                id='txt_toleranceedit'
                                                value={display.tolerance}
                                                onChange={(e) => setDisplay({ ...display, tolerance: e.target.value })}
                                                placeholder='Tolerance'
                                            />
                                        </div> */}
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_toleranceedit?.type === 'required' && 'Please Select Tolerance from a list'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                    </Attributes>
                                </Col>
                            </Row>
                            <Row className='m-0 p-0'>
                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes className='dock-add-edit'>
                                        <div>
                                            <Text color="main" variant='subtitle'>Unit</Text>
                                            <Form.Control
                                                type="text" className='basecontrol custome-input'
                                                name="txt_editdockunit"
                                                value={display.unit}
                                                onChange={(e) => setDisplay({ ...display, unit: e.target.value })}
                                            />
                                        </div>
                                        {/* <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockunit?.type === 'required' && 'Please enter Unit'}
                                                </span>
                                            </Col>
                                        </Row> */}
                                    </Attributes>
                                </Col>
                                <Col xs='12' md='12' lg='6' xl='6' className='p-0 m-0'>
                                    <Attributes className='dock-add-edit'>
                                        <div>
                                            <Text color="main" variant='subtitle'> Status</Text>
                                            <Form.Control as='select'
                                                className='basecontrol cutome-selectInputnxt'
                                                value={display.status} name="txt_editdockstatus" onChange={(e) => setDisplay({ ...display, status: e.target.value })}
                                                ref={register({ required: true, })}>
                                                <option value=''> -- Select Status -- </option>
                                                <option value="VERIFIED"> Verified</option>
                                                <option value="UNVERIFIED"> Not Verified</option>
                                            </Form.Control>
                                        </div>
                                        <Row className='m-0 p-0'>
                                            <Col xs='12' md='12' lg='3' xl='3' className='p-0 m-0 ml-3'>
                                            </Col>
                                            <Col xs='12' md='12' lg='12' xl='12' className='p-0 m-0'>
                                                <span className='err-msg text-capitalize'>{errors.txt_editdockstatus?.type === 'required' && 'Please Select Status'}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Attributes>
                                </Col>
                            </Row>
                        </Card>
                        <Actions>
                            <Button type="button" className='bttn bttn-transparent' onClick={() => _goback()}>
                                <Flex jc='jc-around'>
                                    <Icons icon="arrow_back" />
                                    BACK
                                </Flex>
                            </Button>
                            <Button className='bttn bttn-transparent' type="submit" name='btn-dockEdit' id='btn-dockEdit'>
                                <Flex>
                                    <Icons color="#ffffff" icon="check" />
                                    SAVE DATA
                                </Flex>
                            </Button>
                        </Actions>
                    </Form>
                </Col>
                <Col xs='12' sm='12' md='12' lg='3' xl='3' className='p-0 m-0'>
                    <div className='ml-xl-3 ml-lg-3 d-none d-md-none d-sm-none d-lg-block d-xl-block'>
                        <Card title="Dock Image" className='profile-title'>
                            <div className='user-mainBlock'>
                                {display.dock_image === null && (
                                    <div className='userEdit-block'>
                                        <Image src={noImg} alt='logo' />
                                    </div>
                                )}
                                {display.dock_image === '' && (
                                    <div className='userEdit-block'>
                                        <Image src={noImg} alt='logo' />
                                    </div>
                                )}
                                {display.dock_image !== null && display.dock_image !== '' && (
                                    <div className='userEdit-block'>
                                        <Image src={display.dock_image} alt='logo' />
                                    </div>
                                )}
                            </div>
                            {((display.dock_image === null)||(display.dock_image === '')) && (
                                <div className='imageEditBlock'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            <Image src={editicon} alt='logo' />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                </div>
                            )}
                            {display.dock_image !== null && display.dock_image !== '' && (
                                <div className='imageEditBlock'>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            <Image src={editicon} alt='logo' className="editIconRemove" />
                                        </Flex>
                                        <input
                                            ref={upload}
                                            type="file"
                                            onChange={getBase64}
                                        />
                                    </label>
                                    {/* <Text color='tablehead' variant='subtitle'>Change Image</Text> */}
                                    <label className="custom-file-upload">
                                        <Flex>
                                            {/* <Icons color="#ffffff" icon="edit" /> */}
                                            <Image src={removeicon} alt='logo' onClick={() => _removeDockLogo(display.id)} />
                                        </Flex>
                                    </label>
                                </div>
                            )}
                        </Card>
                    </div>
                </Col>
            </Row>
        </Main>
    )
}

export interface FormProps {
    children?: any | JsxChild;
    onSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
}

const StyledForm = ({ ...props }: FormProps) => {

    const frm = classNames('Form-container');
    return (
        <Form className={frm} onSubmit={props.onSubmit} autoComplete="off">
            {props.children}
        </Form>
    )
}

export interface ActionProps {
    children?: React.ReactNode
}
const Actions = ({ ...props }: ActionProps) => {
    const action = classNames('Action-container');
    return (
        <Flex jc='jc-between' className={action}>
            {props.children}
        </Flex>
    )
}

const mapStateToProps = (state: any) => ({
    dock: state.dock.selectedDock,
    docks: state.dock.docks,
    isLoading: state.dock.isLoading,
    error: state.dock.error,
    totalElement: state.dock.totalElement,
    totalPage: state.dock.totalPage
})

export default connect(
    mapStateToProps,
    {
        dockListGetAction,
        dockSetSelectedAction
    }
)(DockEdit)