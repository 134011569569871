import classNames from 'classnames'
import React from 'react'
import {Text} from '../text/Text'
import './Card.scss'

//interface for Card component. here decelared what are the  props are used by Card component 
export interface CardProps{
  title? : any;
  children?: React.ReactNode;
  className? :string;
}
//Card Component
const Card = ({...props}:CardProps) => {
  const { title, children, ...rest } = props
  const cardclass = classNames(rest.className)
  return (
    <Root {...rest} className = {cardclass}>
      {title && (
        <Title>
          <Text color='main' variant="title-sm" bold>{title}</Text>
        </Title>
      )}
      {children}
    </Root>
  )
}

//Interface for Root const 
export interface RootProps {
  className ? : string;
  children ? : React.ReactNode

}
//Root component. which is going to act as a parent class for card
const Root = ({...props} : RootProps) => {

    const rootcontainer = classNames('root-container',props.className)
    return(
        <div className = {rootcontainer}>
            {props.children}
        </div>
    )
}

//Interface for titile props
export interface TitleProps{
    children ? : React.ReactNode
}

//Title component. used for displaying title
const Title = ({...props}:TitleProps) => {
    const titlecontainer = classNames('title-container')

    return(
        <div className = {titlecontainer}>
          {props.children}
        </div>
    )
}

export default Card
