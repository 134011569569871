import React from 'react'
import classNames from 'classnames'
import './Icon.scss'

//set interface for Root component inside Icon.txt file
export interface RootProps{
    color? :string;
    icon ? : string;
    children? : React.ReactNode
    className ? : string
    onClick ? : (e: React.MouseEvent) => void;
}
//set interface for Icon component
export interface IconProps{
    icon : string;
    color ? : string;
    className ? : string
    onClick ? : (e: React.MouseEvent) => void;
}
//Icons component which automatically export. 
const Icons = ({icon,color, className,onClick, ...pros}: IconProps) => {
  
  return <Root className={className} onClick={onClick} color={color}>{icon}</Root>
}

//Root component Icon container
const Root = ({color,icon,className,children,onClick} : RootProps) => {
    const rootClassNames = classNames('iconcontainer material-icons',className);

    return (
        <i onClick={onClick} className={rootClassNames}>
            {children}
        </i>
    )
}
export default Icons
