import { map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getProvinces = (params: object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/provinces/list/search',
    params
  }).pipe(
    map(res => {
      // console.log(res, 'response in type service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addProvinces = (body: any) => {
  return http({
    method: 'POST',
    url: '/provinces',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

// const getProvincesDetail = (id: any) => {
//   return http({
//     method: 'GET',
//     url: `/shipTypes/${id}`
//   }).pipe(
//     map(res => {
//       console.log(res, 'get ship detail')
//       if (res.success) {
//         return res
//       } else {
//         throw new Error(res.msg)
//       }
//     })
//   )
// }

const deleteProvinces = (id: any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/provinces/${id}`,
  }).pipe(
    map(res => {
      // console.log(res, 'delete response')
      if (res) {
        return res
      } else {
        // console.log(res, 'error message')
        throw new Error(res)
      }
    })
  )
}

const updateProvinces = (body: any) => {
  return http({
    method: 'PUT',
    url: '/provinces',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res, res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const provincesService = {
  getProvinces,
  addProvinces,
  //getProvincesDetail,
  deleteProvinces,
  updateProvinces,
}

export default provincesService


