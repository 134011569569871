import { ofType } from 'redux-observable'
import { PORT_LIST_GET } from '../actionType'
import { catchError, map, switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import portService from '../../services/portService'
import {
  portListGetFAction,
  portListGetRAction,

} from '../action/portAction'

export const getPortEpic = (action:any) => {
  return action.pipe(
    ofType(PORT_LIST_GET),
    switchMap((action:any) => {
      // console.log(action.payload, 'action payload')
      return portService.getPort(action.payload).pipe(
        map(res => {
          // console.log('res get 10 user ' , res)
          const { data } = res
          const { content, total_page, total_element } = data
          // console.log(content ,'content')
          return portListGetFAction({ content, total_page, total_element })
        }),
        catchError(err => of(portListGetRAction(err)))
      )
    })
  )
}
