import React, { ReactNode } from 'react'
import { Text } from '../../../common/components/text/Text'
import { Main } from '../../../common/components/main/Main'
import { Button } from '../../../common/components/button/Button'
import Card from '../../../common/components/card/Card'
import Icons from '../../../common/components/icon/Icon'
import { Flex } from '../../../common/components/flex/Flex'
import { Space } from '../../../common/components/space/Space'
import { Table } from '../../../common/components/table/Table'
import { Col, Modal, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap'
import '../../../common/components/pagination/Pagination.scss'
import { connect } from 'react-redux'
import classNames from 'classnames'
//import { format, parseISO } from 'date-fns'
import {
  cityListGetAction,
  citySetSelectedAction,
} from '../../../store/action/cityAction'
import CitiesAdd from './pageContainer/CitiesAdd'
import { useState } from 'react'
import cityService from '../../../services/cityService'
import swal from 'sweetalert'
import Loader from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import { InputWithIcon, Select } from '../../../common/components/control/Control'

const CitiesList = (props: any) => {
  const { cities, isLoading, totalElement } = props
  const [displayCities, setCities] = React.useState<any>([])
  const [page, setPage] = React.useState(0)
  const [showModal, setModal] = React.useState(false)
  const [pagination, setPagination] = React.useState([{}])
  const [isEdit, setIsEdit] = useState(false)
  const [isview, setIsView] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [high, setHigh] = useState(9)
  const [low, setLow] = useState(1)
  const perPage = 9
  var totalPage = Math.ceil(totalElement / 10)
  const headerName = 'ROLE DETAIL'
  const filename = "role__" + new Date()
  const [showDelete, setShow] = React.useState(false);
  const [deleteCity, setDeleteCity] = React.useState(0)
  const [findBy, setFindBy] = React.useState('name')
  const [searchInput, setSearchInput] = React.useState('')
  const [searchProvince, setSearchProvince] = React.useState<any>('')
  const [searchCountry, setSearchCountry] = React.useState<any>('')

  const role = localStorage.getItem("role")

  React.useEffect(() => {
    //const { totalPage } = props
    const arrayPage = Array.from(Array(totalPage).keys())
    setPagination(arrayPage)
  }, [totalPage])

  React.useEffect(() => {
    props.cityListGetAction({ size: 10, page, name:searchInput, province:searchProvince, country:searchCountry })
  }, [totalElement, page])


  React.useEffect(() => {
    if (cities && cities.length > 0) {
      // console.log(cities, 'cities')
      setCities(() => {
        return cities.map((s: any) => ({ ...s, checked: false }))
      })
    }
    if (cities && cities.length === 0) {
      setCities([])
    }
  }, [cities, page])


  const _willViewEdit = (detail: string | number | any) => {
    props.citySetSelectedAction(detail)
    setModal(true)
    setIsView(false)
    setIsEdit(true)
  }

  const _willViewDetail = (detail: string | number | any) => {
    // console.log(detail,'serlected city in citylist page')
    props.citySetSelectedAction(detail)
    setModal(true)
    setIsView(true)
    setIsEdit(false)
  }

  // Delete the pericular list.
  const handleClose = () => setShow(false);
  const handleShow = (city: any) => {
    setShow(true);
    setDeleteCity(city.id)
    // console.log(deleteAnode, 'deleted anode id')
  }

  const _willDelete = () => {
    // console.log(deleteAnode, 'deleted anode id')
    _deleteCities(deleteCity)
  }

  const _deleteCities = (id: any) => {
    return cityService.deleteCities(id).subscribe({
      next: (r) => {
        // console.log(r, 'response')
        setShow(false)
        swal("Great", " Deleted Successfully", "success")
        props.cityListGetAction({ size: 10, page, name:searchInput, province:searchProvince, country:searchCountry })
      },
      error: err => {
        swal("OOPS", String(err), "error")
      }
    })
  }

  const _willAddCities = () => {
    props.citySetSelectedAction(null)
    setModal(true)
    setIsAdd(true)
    setIsEdit(false)
    setIsView(false)
  }
  const _willCloseModal = () => {
    props.cityListGetAction({ size: 10, page: 0 ,name:searchInput,province:searchProvince,country:searchCountry})
    setModal(false)
    setPage(0)
  }

  // Search Function

  const _getSearchValue = (val: string) => {
    setFindBy(val)
  }

  const _searchFuntion = (value: string) => {
    setSearchInput(value)
    props.cityListGetAction({ size: 10, page,name:value,province:searchProvince,country:searchCountry })

    setPage(0)
  }
  const _SearchProvincFunc = (value: string) => {
    setSearchProvince(value)
    props.cityListGetAction({ size: 10, page,name:searchInput,province:value,country:searchCountry })

  }
  const _SearchCountryFunc = (value: string) => {
    setSearchCountry(value)
    props.cityListGetAction({ size: 10, page,name:searchInput,province:searchProvince,country:value })

  }

  const _showAll = () => {
    setSearchInput('')
    setSearchProvince('')
    setSearchCountry('')
    props.cityListGetAction({ size: 10, page,name:searchInput,province:searchProvince,country:searchCountry })

  }

  const _goPrevPage = () => {
    setPage(prev => {
      return (prev -= 1)
    })
  }

  const _goNextPage = () => {
    setPage(prev => {
      return (prev += 1)
    })
  }

  const _goToPage = (page: any) => {
    setPage(page)
  }

  const firstPage = () => {
    if (page >= 1) {
      return <Pagination.First onClick={() => _goToFirstPage()}>First</Pagination.First>
    }
  }

  const _goToLastPage = () => {
    setPage(totalPage - 1)
    setHigh(totalPage - 1)
    setLow(totalPage - 10)
  }

  const _goToFirstPage = () => {
    setPage(0)
    setHigh(10)
    setLow(1)
  }

  const lastPage = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Last onClick={() => _goToLastPage()}>Last</Pagination.Last>
      }
    }
  }

  const prevBtn = () => {
    if (page >= 1) {
      if (page + 1 === low) {
        setHigh(high - perPage)
        setLow(low - perPage)
      }
      return <Pagination.Prev onClick={() => _goPrevPage()}>Prev</Pagination.Prev>
    }
  }

  const nextBtn = () => {
    if (page !== totalPage - 1) {
      if (page <= high) {
        return <Pagination.Prev onClick={() => _goNextPage()}>Next</Pagination.Prev>
      }
      else {
        setHigh(high + perPage)
        setLow(low + perPage)
      }
    }
  }

  const ListItem = (page: any) => {
    return page.value + 1
  }
  const PageNumber = pagination.map((p, i) => {
    var pagination_num: ReactNode;
    if ((p === 0 && page === 0) || (p === 1 && page === 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    else if ((p < high + 1) && (p >= low - 1)) {
      pagination_num = <Pagination.Item onClick={() => _goToPage(p)} key={i} active={p === page}><ListItem value={p} /></Pagination.Item>
    }
    return (
      pagination_num
    )
  })


  return (
    <Main>
      <Text variant="title">City List</Text>
      <Space divheight={15} />
      <Card>
        {/* <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='4' className='m-0 p-0'>
              <div className='mr-2 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    {role === 'VISITOR' ? (
                      <Button variant='transparent' onClick={() => _willAddCities()} disabled>
                        <Flex>
                          <Icons icon="add" color="#ffffff" />
                          ADD NEW CITY
                        </Flex>
                      </Button>
                    ) : (
                      <Button variant='transparent' onClick={() => _willAddCities()}>
                        <Flex>
                          <Icons icon="add" color="#ffffff" />
                          ADD NEW CITY
                        </Flex>
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </SearchForm> */}
        <SearchForm>
          <Row className='m-0 p-0'>
            <Col xs='12' sm='12' md='12' lg='4' xl='3' className='m-0 p-0'>
              <div className='mr-2 mb-2 mb-sm-2 mb-md-2 mb-lg-0 mb-xl-0'>
                <Row className='m-0 p-0'>
                  <Col xs='12' sm='10' md='9' lg='12' xl='12' className='m-0 p-0'>
                    {findBy === 'name' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by City.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === '' && (
                      <InputWithIcon
                        type="text"
                        value={searchInput}
                        onChange={(e) => _searchFuntion(e.target.value)}
                        placeholder="Search by City.."
                        icon="search"
                        id="search-input"
                      />
                    )}
                    {findBy === 'province' && (
                      <InputWithIcon
                        type="text"
                        value={searchProvince}
                        onChange={(e) => _SearchProvincFunc(e.target.value)}
                        placeholder="Search by Province..."
                        icon="search"
                        id="search-inputprovince"
                      />
                    )}
                    {findBy === 'country' && (
                      <InputWithIcon
                        type="text"
                        value={searchCountry}
                        onChange={(e) => _SearchCountryFunc(e.target.value)}
                        placeholder="Search by Country..."
                        icon="search"
                        id="search-inputCountry"
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs='12' sm='12' md='12' lg='8' xl='8' className='m-0 p-0'>
              <Row className='m-0 p-0'>
                <Col xs='6' sm='4' md='4' lg='5' xl='4' className='m-0 p-0 mr-2'>
                  <Select values={findBy} name="" onChange={(e) => _getSearchValue(e.target.value)}>
                    <option value=''>-- Search by --</option>
                    <option value="name"> By Cities </option>
                    <option value="province"> By Province </option>
                    <option value="country"> By Country </option>
                  </Select>
                </Col>
                <Col xs='5' sm='4' md='4' lg='7' xl='7' className='m-0 p-0 '>
                  <div>
                  {role === 'VISITOR' ? (
                    <Button variant='transparent' onClick={() => _willAddCities()} disabled>
                      <Flex>
                        <Icons icon="add" color="#ffffff" />
                        ADD NEW CITY
                      </Flex>
                    </Button>
                  ):(
                    <Button variant='transparent' onClick={() => _willAddCities()}>
                      <Flex>
                        <Icons icon="add" color="#ffffff" />
                        ADD NEW CITY
                      </Flex>
                    </Button>
                  )}
                    <Button className='ml-2' variant='transparent' block={true} onClick={() => _showAll()}>
                      Show All
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SearchForm>
        {isLoading && <Text center>
          <Loader
            type="Rings"
            color="#233269"
            height={100}
            width={100}
            timeout={30000}
          />
        </Text>}
        {!isLoading && displayCities.length === 0 && (
          <>
            <Space divheight={20} />
            <Text center>No Cities found</Text>
            <Space divheight={20} />
          </>
        )}
        {!isLoading && displayCities.length > 0 && (
          <>
            <Table>
              <thead>
                <tr key={0}>
                  <th>S.No</th>
                  <th>City</th>
                  <th>Abbreviation Code</th>
                  <th>Country</th>
                  <th>Province</th>
                  {/* <th>Latitude</th>
                  <th>Longtitude</th> */}
                  <th colSpan={2} className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {displayCities.map((city: any, i: any) => (
                  <tr key={city.id}>
                    <td data-label='S.No'>
                      {page === 0 && (
                        i + 1
                      )}
                      {page >= 1 && (
                        (page * 10) + (i + 1)
                      )}
                    </td>
                    <td data-label='City'>
                      {city.name === null ? (
                        <Text className='txt-table-date' color='tabledata'> - </Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{city.name}</Text>
                      )}
                    </td>
                    <td data-label='Abbreviation Code'>
                      {city.code === null ? (
                        <Text className='txt-table-date' color='tabledata'> - </Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{city.code}</Text>
                      )}
                    </td>
                    <td data-label='Country'>
                      {city.country === null ? (
                        <Text className='txt-table-date' color='tabledata'> - </Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{city.country.name}</Text>
                      )}
                    </td>
                    <td data-label='Province'>
                      {((city.provinces.name === null)||(city.provinces.name === '')) ? (
                        <Text className='txt-table-date' color='tabledata'> - </Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{city.provinces.name}</Text>
                      )}
                    </td>
                    {/* <td data-label='Latitude'>
                      {city.lat === null ? (
                        <Text className='txt-table-date' color='tabledata'> - </Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{String(city.lat)}</Text>
                      )}

                    </td>
                    <td data-label='Longtitude'>
                      {city.lon === null ? (
                        <Text className='txt-table-date' color='tabledata'> - </Text>
                      ) : (
                        <Text className='txt-table-date' color='tabledata'>{String(city.lon)}</Text>
                      )}

                    </td> */}
                    <td>
                      <TableActions>
                        <OverlayTrigger overlay={
                          <Tooltip id="cities-view"> View
                          </Tooltip>
                        }>
                          <Button className='link_button' onClick={() => _willViewDetail(city)}>
                            <Icons color="#52575a" icon="remove_red_eye" />
                          </Button>
                        </OverlayTrigger>
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="cities-edit-visitor"> Edit option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(city)} disabled>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="cities-edit"> Edit
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => _willViewEdit(city)}>
                              <Icons color="#52575a" icon="edit" />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {role === 'VISITOR' ? (
                          <OverlayTrigger overlay={
                            <Tooltip id="cities-delete-visitor"> Delete option is not available
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(city)} disabled>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger overlay={
                            <Tooltip id="cities-delete"> Delete
                            </Tooltip>
                          }>
                            <Button className='link_button' onClick={() => handleShow(city)}>
                              <Icons color="#52575a" icon="delete" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </TableActions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter>
              <Text>
                {displayCities.length === 1 && (
                  <>
                    <b>
                      {displayCities.length}
                      {/* {((page)*10)+1} - {(displayCities.length+(page)*10)} */}
                    </b> of {totalElement} City
                  </>
                )}
                {displayCities.length > 1 && (
                  <>
                    <b>
                      {displayCities.length}
                      {/* {((page)*10)+1} - {(displayCities.length+(page)*10)} */}
                    </b> of {totalElement} Cities
                  </>
                )}
              </Text>
              <Pagination size="sm">
                {firstPage()}
                {prevBtn()}
                {PageNumber}
                {nextBtn()}
                {lastPage()}
              </Pagination>
            </TableFooter>
          </>
        )}
      </Card>
      {/* {console.log(showModal, 'showmodal')} */}
      {showModal && <CitiesAdd onClose={() => _willCloseModal()} edit={isEdit} view={isview} user_id={displayCities.id} />}
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Text variant='title-sm' color='main' bold>
              Confirmation
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text bold variant='confirmation' color='danger' values='Permanently you want to delete this item form City list' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='transparent' onClick={handleClose}>
            <Icons icon="close" />
            Close
          </Button>
          <Button variant='transparent' onClick={() => _willDelete()}>
            <Icons icon="delete_forever" />
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Main>
  )
}

export interface TablefooterProps {
  children?: React.ReactNode
}

const TableFooter = (props: TablefooterProps) => {

  const tablefooter = classNames('tablefooter')

  return (
    <Flex jc='jc-between' className={tablefooter}>
      {props.children}
    </Flex>
  )

}

export interface TableActionsProps {
  children?: React.ReactNode
}

const TableActions = (props: TableActionsProps) => {

  const tableAction = classNames('tableAction')

  return (
    <Flex jc='jc-center' className={tableAction}>
      {props.children}
    </Flex>
  )

}

interface searchProps {
  children?: React.ReactNode
}

const SearchForm = (props: searchProps) => {

  const search = classNames('serachForm')
  return (
    <form autoComplete='off' className={search}>
      {props.children}
    </form>
  )
}

const mapStateToProps = (state: any) => ({
  cities: state.cities.cities,
  isLoading: state.cities.isLoading,
  error: state.cities.error,
  totalElement: state.cities.totalElement,
  totalPage: state.cities.totalPage,
})

export default connect(
  mapStateToProps,
  {
    cityListGetAction,
    citySetSelectedAction,
  }
)(CitiesList)
