import { map } from 'rxjs/operators'
import http from '../helpers/http'
import httpDelete from '../helpers/httpDelete'

const getPort = (params : object) => {
  // console.log('get ship type called in type service')
  return http({
    method: 'GET',
    url: '/ports/list/search',
    params
  }).pipe(
    map(res => {
      // console.log(res,'response in port service')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const addPort = (body : any) => {
  return http({
    method: 'POST',
    url: '/ports',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const deletePort = (id : any) => {
  return httpDelete({
    method: 'DELETE',
    url: `/ports/${id}`,
  }).pipe(
    map(res => {
      // console.log(res,'delete response')
      if (res) {
        return res
      } else {
        // console.log(res,'error message')
        throw new Error(res)
      }
    })
  )
}

const getPortDetail = (id : any) => {
  return http({
    method: 'GET',
    url: `/ports/${id}`
  }).pipe(
    map(res => {
      // console.log(res,'get ship detail')
      if (res.success) {
        return res
      } else {
        throw new Error(res.msg)
      }
    })
  )
}

const updatePort = (body : any) => {
  return http({
    method: 'PUT',
    url: '/ports',
    body
  }).pipe(
    map(res => {
      if (res.success) {
        // console.log(res)
        return res
      } else {
        // console.log(res,res.msg)
        throw new Error(res.msg)
      }
    })
  )
}

const portService = {
  getPort,
  addPort,
  getPortDetail,
  deletePort,
  updatePort, 
}
  
export default portService